import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { setNavState } from "../../redux/features/navStateSlice"

const PageWrapper = props => {
  const dispatch = useDispatch()

  useEffect(() => {
    if (props.state) {
      dispatch(setNavState(props.state))
    }
  }, [dispatch, props])

  return <>{props.children}</>
}

export default PageWrapper
