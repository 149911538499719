import React, { useEffect, useState } from "react";
import _, { find } from "lodash";
import moment from "moment";
import {
  Box,
  Button,
  IconButton,
  InputLabel,
  TextField,
  List,
  ListItem,
  ListItemText,
  Tab,
  Drawer,
  MenuItem,
  OutlinedInput,
  Select
} from "@mui/material";
import axios from "axios";
import { fetchUrl } from "Urls";
import CloseIcon from "../../assets/images/close.svg";
import PencilIcon from "../../assets/images/pencil.svg";
import SendIcon from "../../assets/images/send.svg";
import SaveIcon from "../../assets/images/save.svg";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import styled from "styled-components"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider, DesktopDatePicker } from "@mui/x-date-pickers";
import draftIcon from "assets/images/draft.svg";
import { annoIdField } from "./ShowPdf";
import ChatterComponent from "components/ChatterComponent";
import { getInternalRecordUrl,getIssueChatterBody } from "api";
import { SplitButtonAction } from "components/SplitButtonAction";
import { SaveDraftDialog, PublishIssueDialog, MakeResolution, ConfirmDialog } from "components/DrawerComponent/DisciplineSettingDialog";

const IssueDetailPanelStyles = styled.div`
  .drawer_Open {
    .markups_head {
      height: 50px;
      padding: 12px 16px 12px 16px;
      .filter_title {
        img.pencil_icon {
          height: 13px;
          width: 13px;
        }
        button.edit_button {
          &:hover {
            background: #f8f8f8 !important;
          }
          width: 80px !important;
          height: 32px !important;
          text-transform: none !important;
          border-radius: 2px !important;
          border: 1px solid #D8D8D8 !important;
          gap: 8px !important;
          background: #ffffff !important;
          color: #505050 !important;
          box-shadow: none !important;
          font-weight: 400 !important;
          font-family: var(--common-font) !important;
        }
        button.makeResolution_button {
          &:hover {
            background: #f8f8f8 !important;
          }
          width: auto;
          height: 32px;
          text-transform: none !important;
          border-radius: 2px !important;
          border: 1px solid #D8D8D8 !important;
          gap: 8px !important;
          background: #ffffff !important;
          color: #505050 !important;
          box-shadow: none !important;
          font-weight: 400 !important;
          font-family: var(--common-font) !important;
        }
      }
    }
    .markup_title {
      font-size: 15px;
      font-weight: 600;
      line-height: 21px;
      text-align: left;
      padding: 0 16px 16px 16px;
    }
    .form_group {
      padding: 0 16px 0 16px;
      border-bottom: 1px solid #eaeaea;
      min-height: 325px;
      overflow-y:auto;
      .MuiBox-root {
        gap: 24px;
        font-size: 13px;
        margin-bottom: 8px;
        .label_name {
          min-width: 110px;
        }
        .label_value {
          color: #000;
        }
      }
    }
    .form_group-edit {
      padding: 0 16px 0 16px;
      border-bottom: 1px solid #eaeaea;
      height: calc(100vh - 180px);
      overflow-y:auto;
      .MuiBox-root {
        display: block;
        gap: 24px;
        font-size: 13px;
        margin-bottom: 8px;
        .label_name {
          min-width: 110px;
        }
        .label_value {
          color: #000;
        }
      }
    }
    .MuiTabs-flexContainer {
      font-family: var(--common-font);
      button.MuiTab-root {
        text-transform: none;
        font-size: 16px;
      }
    }
    .activity_log_list {
      li {
        display: block;
        .assigned_date {
          display: flex;
          gap: 8px;
          align-items: center;
          margin-top: 6px;
          .user_details {
            align-items: center;
            img {
              margin-right: 6px;
              width: 24px;
              height: 24px;
            }
            span.username {
              font-size: 12px;
              color: #000;
            }
          }
          span.username {
            font-size: 12px;
            color: #505050;
          }
        }
        span.username {
          font-size: 12px;
          color: #505050;
        }
      }
    }
  }
  .draftHeader {
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.14px;
  }
  .textField {
    .MuiFormControl-root .MuiInputBase-input {
      height: 1px;
      width: 320px;
      &:hover {
        background: #F8F8F8;
        border-color: #D8D8D8;
      }
    }
  }
  .descriptionTextField {
    .MuiInputBase-root .MuiInputBase-input {
      height: 80px  !important;
      width: 320px !important;
      &:hover {
        background: #F8F8F8 !important;
        border-color: #D8D8D8 !important;
      }
    }
  }
  .dueDateField {
    .MuiFormControl-root {
      .MuiInputBase-root {
        height: 37px;
        width: 350px;
        &:hover {
          background: #F8F8F8;
          border-color: #D8D8D8;
        }
      }
    }
  }
  .locationTextField {
    .MuiFormControl-root .MuiInputBase-input {
      height: 1px;
      width: 320px;
      &:hover {
        background: #F8F8F8;
        border-color: #D8D8D8;
      }
    }
  }
  button.MuiButtonBase-root.Mui-disabled{
    color: white;
    background-color: #BABABA;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-right: 10px;
    text-transform: none;
    border-radius: inherit;
    border: 1px solid #BABABA;
  }
  .savePublishButton {
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-right: 10px;
    text-transform: none;
    border-radius: inherit;
  }
  .editFooter {
    text-align-last: right;
    margin-top: auto;
    padding-right: 10px;
    padding-top: 5px;
  }
`;

function IssueDetailPanelDialog({ ...props }) {
  const { credential, sfObj, saveCallback, closeCallBack, callFrom } = props;
  const [editMode, setEditMode] = useState((sfObj?.Status__c == 'Draft' && sfObj?.CreatedById == credential?.userId));
  const [tabValue, setTabValue] = useState("comments");
  const [commentValue, setCommentValue] = useState("");
  const [status, setStatus] = React.useState(sfObj?.Status__c); // initial status

  const [sfRecord, setsfRecord] = React.useState(sfObj ? JSON.parse(JSON.stringify(sfObj)) : null);
  const [defaultChatterBody, setDefaultChatterBody] = useState(null);
  //var sfRecord ;
  const [issueDueDate, setIssueDueDate] = React.useState(new Date());
  const [requiredMissing, setRequiredMissing] = React.useState(true);
  const [isOwner, setIsOwner] = React.useState(false);
  const [isBallInCourt, setIsBallInCourt] = React.useState(false);
  const [isOtherUser, setIsOtherUser] = React.useState(false);
  const [confirmDialog, setConfirmDialog] = useState(null);
  const [makeResolutionDialog, setMakeResolutionDialog] = useState(false);
  const [publishIssueDialog, setPublishIssueDialog] = useState(false);
  const [activityList, setActivityList] = useState([]);
  const confirmDialogData = {
    title: 'Confirm Resolution',
    content: 'This issue will be marked as answered and your resolution will be reviewed by the owner. Would you like to confirm?',
    action1: "Cancel",
    action2: "Yes, Confirm Resolution"
  };
  const confirmDialogDataClosed = {
    title: 'Mark Issue as Closed',
    content: 'Are you sure want to mark this issue as closed ? Once closed, this issue cannot be edited.',
    action1: "Cancel",
    action2: "Close Issue"
  };
  const confirmDialogDataAmend = {
    title: 'Amend Resolution',
    content: 'Are you sure you want to change issue status to Amend Resolution? Once changed, assigned ball in court will be notified to make changes.',
    action1: "Cancel",
    action2: "Yes, Amend Resolution"
  };
  const confirmDialogDataVoid = {
    title: 'Mark Issue as Void',
    content: 'Are you sure you want to mark this issue as void? Once voided, this issue cannot be edited.',
    action1: "Cancel",
    action2: "Void Issue"
  };
  const dropdownItems = [
    { icon: '/closed-green-1.svg', label: 'Mark as Closed' },
    { icon: '/amend.svg', label: 'Amend Resolution' },
    { icon: '/void.svg', label: 'Void Issue' },
  ];
  const makeResolution = {
    title: 'Make Resolution',
    content: 'Enter your resolution to this issue',
    action1: "Cancel",
    action2: "Make Resolution"
  };
  const updateResolution = {
    title: 'Update Resolution',
    content: 'Update your resolution to this issue',
    action1: "Cancel",
    action2: "Update Resolution"
  };
  useEffect(() => {
    //console.log('sfObj ::',JSON.stringify(sfObj));
    let dsfIssue = JSON.parse(JSON.stringify(sfObj));
    setStatus(dsfIssue?.Status__c);
    setEditMode(dsfIssue?.Status__c == 'Draft' && dsfIssue?.CreatedById == credential?.userId);
    setIssueDueDate(new Date(dsfIssue.Due_Date__c));
    setVariables(dsfIssue);
    let iOwner = false;
    let iBIC = false;
    let iOU = false;
    if (dsfIssue?.CreatedById == credential?.userId) {
      iOwner = true;
    }
    if (dsfIssue?.Ball_in_Court__c == credential?.userId) {
      iBIC = true;
    }
    iOU = (!iOwner && !iBIC);
    console.log('iOwner ::', iOwner, 'iBIC ::', iBIC, 'iOU ::', iOU);
    setIsOwner(iOwner);
    setIsBallInCourt(iBIC);
    setIsOtherUser(iOU);
    if (dsfIssue?.Id) {
      if(dsfIssue?.Numbering_Sequence__c > 0){
        let url = getInternalRecordUrl(credential , 'Issues' , dsfIssue?.Id , true);
        let chbody = getIssueChatterBody(credential?.projectName , dsfIssue?.Numbering_Sequence__c , url);
        setDefaultChatterBody(chbody);
      }else{
        setDefaultChatterBody(null);
      }
      fetchHistoryForIssue(dsfIssue?.Id);
    }
  }, [sfObj]);
  function setVariables(_sfObj) {
    if (_sfObj.Due_Date__c) {
      setIssueDueDate(new Date(_sfObj.Due_Date__c));
    } else {
      setIssueDueDate(null);
    }
    setRequiredMissing(!checkReadyToSave(_sfObj));
    let sobject = JSON.parse(JSON.stringify(_sfObj))
    setsfRecord(sobject);
  }
  const fetchHistoryForIssue = async (issueId) => {
    let rrr = [];
    await axios.get(`${fetchUrl}/get-history?parentId=${issueId}&objectName=Issue__History`)
      .then(async (res) => {
        //console.log('data :: ', res.data);
        if (res?.status == 200) {
          rrr = res.data;
        }
      }).catch((err) => {
        console.log('Issue__c History Save ::', err);
      });
    setActivityList(rrr);
  }
  function checkReadyToSave(_sfObj) {
    //console.log('checkReadyToSave');
    let reqfields = [
      "OwnerId",
      "Type__c",
      "Status__c",
      "Name",
    ];

    if (_sfObj) {
      for (let f of reqfields) {
        if (!_sfObj[f] || _sfObj[f] == "" || _sfObj[f] == null) {
          return false;
        }
      }
      /*if ( _sfObj["Status__c"] == "Answered" ||_sfObj["Status__c"] == "Closed") {
        let f = "Response__c";
        if (!_sfObj[f] || _sfObj[f] == "" || _sfObj[f] == null) {
          return false;
        }
      }*/
    }
    return true;
  }
  async function onClickSave(info) {
    if (!info) {
      info = {};
    }
    info.callFrom = 'Issue Detail Panel';
    saveCallback(sfRecord, info);
    if (sfRecord.Status__c == 'Draft') {
      closeCallBack(sfRecord[annoIdField])
    } else {
      setEditMode(!editMode)
    }
  }

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const handleSearchComment = (event) => {
    setCommentValue(event.target.value);
  };

  return (
    <IssueDetailPanelStyles style={{ width: '0px' }}>
      {sfRecord &&
        <Drawer className="drawer_Open" sx={{
          '&.drawer_Open.MuiDrawer-docked.MuiDrawer-root .MuiDrawer-paper': {
            marginTop: callFrom == "IssueTab" ? `120px` : "57px",
          },
          '&.drawer_Open .form_group-edit': {
            height: callFrom == "IssueTab" ? 'calc(100vh - 240px)' : 'calc(100vh - 180px)',
          },
        }}
          anchor="right" open={true} variant="persistent" >
          <div className="markups_head">
            <Box className="filter_title">
              {!editMode && (status == "Answered") && isOwner &&
                <SplitButtonAction
                  items={dropdownItems}
                  onAction={(label) => {
                    //console.log(label);
                    setConfirmDialog(label);
                  }}
                />
              }
              {!editMode && (status == "Pending" || status == "Amend Resolution") && isBallInCourt &&
                <Button className="makeResolution_button" onClick={() => setMakeResolutionDialog(true)}>
                  <img style={{ width: "13px" }} className="pencil_icon" src={status === "Amend Resolution" ? PencilIcon : "resolution.svg"} alt="pencil_icon" />
                  {status === "Amend Resolution" ? 'Update Resolution' : 'Make Resolution'}
                </Button>
              }
              {!editMode && (status == "Draft" || status == "Pending" || status == "Answered") && isOwner &&
                <Button className="edit_button" onClick={() => setEditMode(!editMode)}>
                  <img className="pencil_icon" src={PencilIcon} alt="pencil_icon" />
                  Edit
                </Button>
              }
              {editMode &&
                <div className="draftHeader">
                  Edit Issue {sfRecord.Numbering_Sequence__c != null ? `ID #${sfRecord.Numbering_Sequence__c}` : ''}
                </div>
              }
            </Box>
            {!editMode &&
              <Box className="filter_icons">
                <IconButton onClick={() => closeCallBack(sfRecord[annoIdField])}>
                  <img src={CloseIcon} alt="close_icon" />
                </IconButton>
              </Box>
            }
          </div>
          <div>
            {!editMode &&
              <Box className="markup_title">{sfRecord?.Name}</Box>
            }
            <div className={!editMode ? "form_group" : "form_group-edit"} style={{ marginBottom: 0 }}>
              <Box className="textField">
                {!editMode && sfRecord.Numbering_Sequence__c != null &&
                  <>
                    <InputLabel className="label_name">ID</InputLabel>
                    <InputLabel className="label_value">#{sfRecord?.Numbering_Sequence__c}</InputLabel>
                  </>
                }
                {editMode &&
                  <>
                    <InputLabel className="label_name">Issue Name</InputLabel>
                    <TextField
                      id="title"
                      style={{ color: "#505050" }}
                      required
                      value={sfRecord?.Name ? sfRecord?.Name : ''}
                      onChange={(e) => {
                        let fff = sfRecord;
                        fff.Name = e?.target?.value;
                        setVariables(fff);
                      }}
                      onKeyDown={(event) => {
                        if (event.key.toLowerCase() != "tab") {
                          event.stopPropagation();
                        }
                      }}
                    />
                  </>
                }
              </Box>
              {!editMode &&
                <Box>
                  <InputLabel className="label_name">Status</InputLabel>
                  {!editMode &&
                    <InputLabel sx={{ gap: "4px", display: "flex" }} className="label_value" >
                      <img src={sfRecord?.Status__c == "Pending" ? "/pending.svg" : sfRecord?.Status__c == "Answered" ? "/answered.svg" : sfRecord?.Status__c == "Closed" ? "/closed-green-1.svg" : sfRecord?.Status__c == "Amend Resolution" ? "/amend.svg" : sfRecord?.Status__c == "Void" ? "/void.svg" : draftIcon} alt="avatar" height={16} width={16} />
                      {sfRecord?.Status__c}
                    </InputLabel>
                  }
                  {editMode &&
                    <Select
                      id="status"
                      style={{ height: "37px", width: "350px" }}
                      value={sfRecord?.Status__c ? sfRecord?.Status__c : ''}
                      onChange={(event) => {
                        let fff = sfRecord;
                        fff.Status__c = event?.target?.value;
                        setVariables(fff);
                      }}>
                      {credential?.issueObjOptions?.Status__c?.options &&
                        credential?.issueObjOptions?.Status__c?.options.map((opt, index) => (
                          <MenuItem key={opt.value} value={opt.value}>{opt.label}</MenuItem>
                        ))
                      }
                    </Select>
                  }
                </Box>
              }
              <Box>
                <InputLabel className="label_name">Type</InputLabel>
                {!editMode &&
                  <InputLabel className="label_value">
                    {sfRecord?.Type__c}
                  </InputLabel>
                }
                {editMode &&
                  <Select
                    id="type"
                    style={{ height: "37px", width: "350px" }}
                    value={sfRecord?.Type__c ? sfRecord?.Type__c : ''}
                    onChange={(event) => {
                      let fff = sfRecord;
                      fff.Type__c = event?.target?.value;
                      setVariables(fff);
                    }}>
                    {credential?.issueObjOptions?.Type__c?.options &&
                      credential?.issueObjOptions?.Type__c?.options.map((opt, index) => (
                        <MenuItem key={opt.value} value={opt.value}>{opt.label}</MenuItem>
                      ))
                    }
                  </Select>
                }
              </Box>
              <Box className='descriptionTextField'>
                <InputLabel className="label_name">Description</InputLabel>
                {!editMode &&
                  <InputLabel className="label_value" style={{whiteSpace: 'unset'}}>
                    {sfRecord?.Description__c}
                  </InputLabel>
                }
                {editMode &&
                  <OutlinedInput
                    id="Description"
                    style={{ color: "#505050" }}
                    value={sfRecord?.Description__c ? sfRecord?.Description__c : ''}
                    rows={2}
                    maxRows={3}
                    onChange={(e) => {
                      let fff = sfRecord;
                      fff.Description__c = e?.target?.value;
                      setVariables(fff);
                    }}
                    onKeyDown={(event) => {
                      if (event.key.toLowerCase() != "tab") {
                        event.stopPropagation();
                      }
                    }}
                    multiline
                  />
                }
              </Box>
              <Box>
                <InputLabel className="label_name">Ball in Court</InputLabel>
                {!editMode &&
                  <InputLabel
                    sx={{ gap: "4px", display: "flex" }}
                    className="label_value"
                  >
                    <img src="/user.svg" alt="avatar" height={16} width={16} />
                    <span className="username">{sfRecord?.Ball_in_Court__r?.Name}</span>
                  </InputLabel>
                }
                {editMode &&
                  <Select
                    id="status"
                    style={{ height: "37px", width: "350px", color: "red" }}
                    value={sfRecord?.Ball_in_Court__c ? sfRecord?.Ball_in_Court__c : ''}
                    onChange={(event) => {
                      let fff = sfRecord;
                      fff.Ball_in_Court__c = event?.target?.value;
                      if (fff.Ball_in_Court__c) {
                        let dsfIssue = find(credential?.userList, { value: fff.Ball_in_Court__c });
                        fff.Ball_in_Court__r = {
                          Id: event?.target?.value,
                          Name: dsfIssue?.label,
                        };
                      }
                      setVariables(fff);
                    }}>
                    {credential?.userList &&
                      credential?.userList.map((opt, index) => (
                        <MenuItem key={opt.value} value={opt.value} data-label={opt.label}>{opt.label}</MenuItem>
                      ))
                    }
                  </Select>
                }
              </Box>
              <Box className="dueDateField">
                <InputLabel className="label_name">Due date</InputLabel>
                {!editMode &&
                  <InputLabel className="label_value">{sfRecord.Due_Date__c}</InputLabel>
                }
                {editMode &&
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      className="common_class"
                      disablePast={false}
                      placeholder="Due Date"
                      inputFormat="MM/dd/yyyy"
                      value={issueDueDate}
                      onChange={(newValue) => {
                        let fff = sfRecord;
                        if (newValue) {
                          fff.Due_Date__c = moment(newValue).format("YYYY-MM-DD");
                        } else {
                          fff.Due_Date__c = null;
                        }
                        setVariables(fff);
                      }}
                      renderInput={(params) => (
                        <div>
                          <TextField
                            id="dueDate"
                            {...params}
                          />
                        </div>
                      )}
                    />
                  </LocalizationProvider>
                }
              </Box>
              {/* <Box className="locationTextField">
                <InputLabel className="label_name">Location</InputLabel>
                {!editMode &&
                  <InputLabel className="label_value">{sfRecord?.Location_Details__c}</InputLabel>
                }
                {editMode &&
                  <TextField
                    id="locationDetails"
                    value={sfRecord?.Location_Details__c ? sfRecord?.Location_Details__c : ''}
                    onChange={(e) => {
                      let fff = sfRecord;
                      fff.Location_Details__c = e?.target?.value;
                      setVariables(fff);
                    }}
                    onKeyDown={(event) => {
                      if (event.key.toLowerCase() != "tab") {
                        event.stopPropagation();
                      }
                    }}
                  />
                }
              </Box> */}
              <Box>
                <InputLabel className="label_name">Root Cause</InputLabel>
                {!editMode &&
                  <InputLabel className="label_value">Design Change</InputLabel>
                }
                {editMode &&
                  <Select
                    id="rootCause"
                    style={{ height: "37px", width: "350px" }}
                    value={sfRecord?.Root_Cause__c ? sfRecord?.Root_Cause__c : ''}
                    onChange={(event) => {
                      let fff = sfRecord;
                      fff.Root_Cause__c = event?.target?.value;
                      setVariables(fff);
                    }}>
                    {credential?.issueObjOptions?.Root_Cause__c?.options &&
                      credential?.issueObjOptions?.Root_Cause__c?.options.map((opt, index) => (
                        <MenuItem key={opt.value} value={opt.value}>{opt.label}</MenuItem>
                      ))
                    }
                  </Select>
                }
              </Box>
              {!editMode && (status === 'Answered' || status === 'Amend Resolution' || status === 'Closed' || status === 'Void') &&
                <Box>
                  <InputLabel className="label_name">Resolution</InputLabel>
                  {!editMode &&
                    <InputLabel className="label_value" style={{whiteSpace: 'unset'}}>{sfRecord?.Response__c}</InputLabel>
                  }
                  {editMode &&
                    <TextField
                      id="response"
                      required
                      value={sfRecord?.Response__c ? sfRecord?.Response__c : ''}
                      onChange={(e) => {
                        let fff = sfRecord;
                        fff.Response__c = e?.target?.value;
                        setVariables(fff);
                      }}
                      onKeyDown={(event) => {
                        if (event.key.toLowerCase() != "tab") {
                          event.stopPropagation();
                        }
                      }}
                    />
                  }
                </Box>
              }
              {!editMode &&
                <Box>
                  <InputLabel className="label_name">Created By</InputLabel>
                  <InputLabel sx={{ gap: "4px", display: "flex" }} className="label_value" >
                    <img src="/user.svg" alt="avatar" height={16} width={16} />
                    <span className="username">{sfRecord?.CreatedBy?.Name}</span>
                  </InputLabel>
                </Box>
              }
            </div>
            {editMode &&
              <div className="editFooter">
                <Button className="custom_button cancel_button" onClick={() => {
                  if (sfRecord.Status__c == 'Draft') {
                    closeCallBack(sfRecord[annoIdField])
                  } else {
                    setEditMode(!editMode)
                  }
                }}>
                  Cancel
                </Button>
                {isOwner &&
                  <Button className={(status == 'Pending' || status == 'Answered') ? "custom_button apply_button" : "custom_button cancel_button"} style={{ marginLeft: "5px" }} onClick={() => {
                    onClickSave({ saveAs: status == 'Draft' ? 'Owner Draft' : 'Owner Save' });
                  }} disabled={requiredMissing}>
                    {status == 'Draft' ? 'Save as Draft' : 'Save Changes'}
                  </Button>
                }
                {isOwner && (status == 'Draft') &&
                  <Button className="custom_button apply_button" style={{ marginLeft: "5px" }} onClick={() => setPublishIssueDialog(true)} disabled={!sfRecord?.Ball_in_Court__c ? true : requiredMissing}>
                    Publish Issue
                  </Button>
                }
              </div>
            }
          </div>
          {!editMode &&
            <Box sx={{ width: "100%", typography: "body1" }}>
              <TabContext value={tabValue}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList onChange={handleTabChange} variant="fullWidth" aria-label="lab API tabs example" >
                    <Tab label="Comments" value="comments" />
                    <Tab label="Activity Log" value="activityLog" />
                  </TabList>
                </Box>
                <TabPanel style={{height: callFrom == "IssueTab" ? 'calc(100vh - 600px)' : 'calc(100vh - 530px)', overflow: 'auto', padding: '10px' }} value="comments">
                  {defaultChatterBody && 
                    <ChatterComponent parentId={sfRecord?.Id} userList={credential?.userList} chatterBody={defaultChatterBody}/>
                  }
                </TabPanel>
                <TabPanel style={{ height: '40vh', overflow: 'auto', padding: '10px' }} value="activityLog">
                  <List sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper", }} className="activity_log_list" >
                    {activityList && activityList.map((activity, index) => (
                      <ListItem key={activity.Id} alignItems="flex-start">
                        <ListItemText
                          primary={
                            <Box className="assigned_date">
                              <Box className="user_details">
                                <img alt={activity?.CreatedBy?.Name} src="user.svg" />
                                <span className="username" style={{ flexShrink: 0 }}>{activity?.CreatedBy?.Name}</span>
                              </Box>
                              <span className="username" style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', width: '10px', flexGrow: 1 }}>
                                {activity.Field != 'Created.' &&
                                  <>
                                    Changed <strong>{activity.Field}</strong>
                                  </>
                                }
                                {activity.Field == 'Created.' &&
                                  <>
                                    Created
                                  </>
                                }
                              </span>
                            </Box>
                          }
                          secondary={
                            <Box sx={{ margin: "10px 0 10px 28px" }} className="user_details">
                              {(activity.OldValue || activity.NewValue || activity.Field == 'Created.') &&
                                <span className="username">
                                  {activity.Field != 'Created.' &&
                                    <>
                                      Changed {activity.Field} to {activity.NewValue}
                                    </>
                                  }
                                  {activity.Field == 'Created.' &&
                                    <>
                                      Created an issue
                                    </>
                                  }
                                </span>
                              }
                            </Box>
                          }
                        />
                        <Box sx={{ marginLeft: "28px" }} className="user_details">
                          <span className="username">{moment(activity.CreatedDate).format('MM/DD/YYYY [at] h:mm A')}</span>
                        </Box>
                      </ListItem>
                    ))}
                  </List>
                </TabPanel>
              </TabContext>
            </Box>
          }
        </Drawer>
      }
      {publishIssueDialog &&
        <PublishIssueDialog
          onCancel={() => {
            setPublishIssueDialog(false);
          }}
          onAccept={() => {
            let fff = sfRecord;
            fff.Status__c = 'Pending';
            setVariables(fff);
            saveCallback(fff, { saveAs: 'Owner Publish' });
            setEditMode(!editMode);
            setPublishIssueDialog(false);
          }}
        />
      }
      {makeResolutionDialog &&
        <MakeResolution
          onCancel={() => {
            setMakeResolutionDialog(false);
          }}
          onAccept={(resolutonData) => {
            setMakeResolutionDialog(false);
            let fff = sfRecord;
            fff.Response__c = resolutonData;
            setVariables(fff);
            if (status == "Amend Resolution") {
              fff.Status__c = "Answered";
              fff.Ball_in_Court__c = fff.CreatedById;
              fff.Ball_in_Court__r = fff.CreatedBy;
              saveCallback(fff, { saveAs: "BIC RSubmit" ,callFrom:"Issue Detail Panel"})
            } else {
              setConfirmDialog("Mark Resoluton");
            }
          }}
          dialogData={status == "Pending" ? makeResolution : updateResolution}
          defaultValue={sfRecord?.Response__c}
        />
      }
      {confirmDialog &&
        <ConfirmDialog
          onCancel={() => {
            setConfirmDialog(null);
            if (confirmDialog == "Make Resolution") {
              setMakeResolutionDialog(true);
            }
          }}
          onAccept={() => {
            setConfirmDialog(false);
            let fff = sfRecord;
            let status = (confirmDialog == "Mark Resoluton" ? "Answered" : (confirmDialog == "Mark as Closed" ? "Closed" : (confirmDialog == "Amend Resolution" ? "Amend Resolution" : confirmDialog == 'Void Issue' && 'Void')));
            console.log('status :: ', status);
            let saveAs = (confirmDialog == "Mark Resoluton" ? "BIC RSubmit" : (confirmDialog == "Mark as Closed" ? "Owner Closed Issue" : (confirmDialog == "Amend Resolution" ? "Owner Amend Resolution" : confirmDialog == 'Void Issue' && 'Owner Void')));
            console.log('saveAs :: ', saveAs);
            fff.Status__c = status;
            if (confirmDialog === "Mark Resoluton") {
              fff.Ball_in_Court__c = fff.CreatedById;
              fff.Ball_in_Court__r = fff.CreatedBy;
            }
            if (confirmDialog === "Amend Resolution") {
              fff.Ball_in_Court__c = fff.LastModifiedById;
              fff.Ball_in_Court__r = fff.LastModifiedBy;
            }
            setVariables(fff);
            saveCallback(fff, { saveAs: saveAs ,callFrom:"Issue Detail Panel"});
          }}
          DialogData={confirmDialog == "Mark Resoluton" ? confirmDialogData : confirmDialog == "Mark as Closed" ? confirmDialogDataClosed : confirmDialog == "Amend Resolution" ? confirmDialogDataAmend : confirmDialog == "Void Issue" && confirmDialogDataVoid}
        />
      }
    </IssueDetailPanelStyles>
  );
}
export default IssueDetailPanelDialog;
