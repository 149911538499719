import styled from "styled-components";

const IssueFilterStyle = styled.div`
  .field_sections {
    .autocomplete_section {
      width: 100%;
      max-width: 330px;
      fieldset.MuiOutlinedInput-notchedOutline {
        padding: 10px;
        border-radius: 0;
      }
      .MuiAutocomplete-root {
        width: 100% !important;
      }
      .MuiInputBase-formControl {
        padding: 0;
        font-size: 14px;
      }
    }
  }
`;

export default IssueFilterStyle;
