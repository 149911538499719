import React, { useRef, useCallback, useState, useEffect } from "react";
import { Grid, TextField, Stack, Checkbox, Select, MenuItem, Button } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider, DesktopDatePicker } from "@mui/x-date-pickers";
import moment from 'moment';
import { FormControl, RadioGroup, FormControlLabel, FormHelperText, Radio } from "@mui/material";
import SignatureCanvas from "react-signature-canvas";
import SignatureRender from './SignatureRender';
import NotesAndAttachment from './NotesAndAttachment';
import { cloneDeep } from "lodash";
import { checkConditionalLogicBasedOnResponse } from ".";

const AnswersRender = (props) => {
    const { question, formId, name, responseOptions, dispatchObj, sectionInd, isFormCompleted, userValidationMatch, questionInd, ansObj, defaultValue, formObj, SetSaveChanges } = props;
    const [fValue, setFValue] = React.useState(defaultValue);
    const [selectedValues, setSelectedValues] = React.useState(defaultValue ? defaultValue.split(';') : []);
    const [errorMessage, setErrorMessage] = React.useState(false);
    const padRef = useRef(null);
    useEffect(() => {
        handleErrorValidation(defaultValue);
      }, [defaultValue, ansObj]);
      async function handleErrorValidation(value) {
        if (value) {
            const item = {ans : cloneDeep(ansObj), responseOptions: responseOptions}
            item.ans.Response__c = value;
            if(question.Conditional_Logic__c){
            let checkResult = checkConditionalLogicBasedOnResponse(item);
            if(checkResult.ifLogicFail){
                setErrorMessage(checkResult.msg);
            }
            else{
                setErrorMessage(null);  
            }
            }else {
                setErrorMessage(null); 
            }
        }
    };
    const handleChange = e => {
        SetSaveChanges(true);
        let fieldKey = e.target.name;
        let value = e.target.value;
                dispatchObj({
            type: 'HANDLE_CHANGE_ANS_FIELD',
            sectionIndex: sectionInd,
            questionIndex: questionInd,
            fieldKey: fieldKey ? fieldKey : 'Response__c',
            value: value
        });
    };
    const handleChangeForSignature = updatedObject => {
        dispatchObj({
           type: 'HANDLE_CHANGE_SIGNATUREANS_FIELD',
           sectionIndex: sectionInd,
           questionIndex: questionInd,
           updatedObject: updatedObject,
         });
   };
const handleGetCanvas_onEnd = useCallback(() => {
        const canvas = padRef?.current?.toDataURL();
        //console.log('canvas end',canvas);
        if (formObj.Status__c !== "Completed") {
            handleChange({ target: { name: 'Signature__c', value: canvas } });
        }
    }, []);
    return (
        <div className="answer-renderer-wrapper" style={{ marginTop: "5px" }}>
            {(question.Response_Type__c === "Text" || question.Response_Type__c === "Number") && (
                <TextField name={name} id={question.Id + sectionInd + questionInd} type={question.Response_Type__c === "Number" ? "number" : "text"} className="Searchbar-field"
                    inputProps={{ maxLength: question.Response_Type__c === "Text" && 255 }}
                    variant="outlined" 
                    sx={{ "& .MuiInputBase-root": {height: "37px", padding: "4px", marginBottom: "10px", width: "100%" }, }} value={fValue}
                    onChange={(e) => {
                        if (formObj.Status__c !== "Completed") {
                            setFValue(e?.target?.value);
                        }
                    }}
                    onBlur={(e) => {
                        if (formObj.Status__c !== "Completed") {
                            //console.log('e?.target?.value :: '+e?.target?.value);
                            handleChange({ target: { name: 'Response__c', value: e?.target?.value } });
                        }
                    }} required={!question.Optional__c}
                    disabled={(isFormCompleted || !userValidationMatch)}
                    error={!(defaultValue && defaultValue !== '') && !question.Optional__c}
                    helperText={(question.Optional__c || (fValue && fValue !== '')) ? undefined : "* Field is required"} />
            )}
            {question.Response_Type__c === 'Date' &&
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Stack spacing={3} className="margin-top-8">
                        <DesktopDatePicker
                            className="common_class"
                            disablePast={false}
                            placeholder="Date"
                            inputFormat="MM/dd/yyyy"
                            disabled={(isFormCompleted || !userValidationMatch)}
                            value={(defaultValue ? new Date(defaultValue) : null)}
                            onChange={(newValue) => {
                                if (formObj.Status__c !== "Completed") {
                                    let vvvv = null;
                                    if (newValue) {
                                        vvvv = moment(newValue).format("YYYY-MM-DD");
                                    }
                                    //defaultValue = vvvv;
                                    //console.log('vvvv :: '+vvvv);
                                    if (vvvv == null || vvvv == '') {
                                        handleChange({ target: { name: 'Response__c', value: vvvv } });
                                    }
                                }
                            }}
                            onAccept={(newValue) => {
                                if (formObj.Status__c !== "Completed") {
                                    let vvvv = null;
                                    if (newValue) {
                                        vvvv = moment(newValue).format("YYYY-MM-DD");
                                    }
                                    //defaultValue = vvvv;
                                    //console.log('vvvv :: '+vvvv);
                                    handleChange({ target: { name: 'Response__c', value: vvvv } });
                                }
                            }}
                            renderInput={(params) =>
                                <div>
                                    <TextField id={question.Id + "dateInput"} className="Searchbar-field" variant="outlined" label="Date" required={!question.Optional__c} {...params} sx={{ "& .MuiInputBase-root": { color: "#505050", height: "37px", padding: "4px", marginBottom: "10px", width: "38%" }, }}
                                        error={!(defaultValue && defaultValue !== '') && !question.Optional__c}
                                        helperText={(question.Optional__c || (defaultValue && defaultValue !== '')) ? undefined : "* Date is required"} 
                                        />
                                </div>
                            }
                        />
                    </Stack>
                </LocalizationProvider>
            }
            {(question.Response_Type__c === 'Dropdown') &&
                <div>
                    <Select role="presentation"
                        variant="outlined"
                        sx={{ width: "100%", height: "37px" }}
                        disableUnderline={true}
                        labelId="form_response_type"
                        id={`responseType-${question.identifier}`}
                        name="Response__c" label="Response type"
                        value={defaultValue}
                        required={!question.Optional__c}
                        onChange={handleChange}
                        disabled={(isFormCompleted || !userValidationMatch)}>
                        {responseOptions?.map((r) => (
                            <MenuItem key={r.key} value={r.value}>
                                {r.label}
                            </MenuItem>
                        ))}
                    </Select>
                </div>
            }
            {(question.Response_Type__c === 'Preconfigured' || question.Response_Type__c === 'Multiple Choice') &&
                <div>
                    <RadioGroup
                        aria-labelledby="preconfigured-answer-responses"
                        name="Response__c"
                        value={defaultValue}
                        onChange={handleChange}
                        disabled={(isFormCompleted || !userValidationMatch)}
                        required={!question.Optional__c}
                    >
                        {responseOptions.map((r) => (
                            <FormControlLabel
                                key={r.key}
                                value={r.value}
                                control={<Radio />}
                                label={r.label}
                                disabled={(isFormCompleted || !userValidationMatch)}
                            />
                        ))}
                    </RadioGroup>
                </div>
            }
            {(question.Response_Type__c === 'Checkbox') &&
                <div>
                    {responseOptions.map((r) => (
                        <div style={{ display: "flex", color: "black", gap: "10px" }} className="list_option">
                            <div>
                                <Checkbox className="checkbox-style" style={{ padding: "0px" }} checked={selectedValues?.includes(r.value)} value={r.value} onChange={(e) => {
                                    if (formObj.Status__c !== "Completed") {
                                        let fff = selectedValues;
                                        //console.log('fff 0 :: '+fff);
                                        //console.log('e.target.checked 0 :: '+e.target.checked);
                                        if (e.target.checked) {
                                            if (!fff) {
                                                fff = [];
                                            }
                                            if (fff.indexOf(r.value) < 0) {
                                                fff.push(r.value);
                                            }
                                        } else {
                                            if (fff.indexOf(r.value) > -1) {
                                                const f_i = fff.indexOf(r.value);
                                                fff.splice(f_i, 1);
                                            }
                                        }
                                        //console.log('fff :: '+fff);
                                        setSelectedValues(fff);
                                        handleChange({ target: { name: 'Response__c', value: fff.join(';') } });
                                    }
                                }} name={r.value}
                                disabled={(isFormCompleted || !userValidationMatch)} />
                            </div>
                            <div style={{ paddingTop: "2px" }}>
                                {r.label}
                            </div>
                        </div>
                    ))}
                </div>
            }
            {(question.Response_Type__c === 'Signature') &&
                <SignatureRender req={!question.Optional__c} ansObj={ansObj} 
                    handleChange={handleChangeForSignature} 
                    defaultValue={defaultValue} 
                    formObj={formObj} isFormCompleted={isFormCompleted}
                    userValidationMatch={userValidationMatch}
                    />
            }
            {question.Response_Type__c !== 'Signature' && <NotesAndAttachment formId={formId} credential={props?.credential} ansObj={ansObj} handleChange={handleChange} formObj={formObj} isFormCompleted={isFormCompleted} userValidationMatch={userValidationMatch}/>}
            {errorMessage &&
                < div style={{ color: "red", marginTop: "10px" }}> <img src="/error-red.svg" alt="" /> {errorMessage}</div>
            }
        </div>
    );
};

export default AnswersRender;
