import { Box } from "@mui/system";

export function Items(props) {
    const { sx, ...other } = props;
    return (
        <Box
            sx={{
                p: 1,
                m: 1,
                bgcolor: (theme) =>
                    theme.palette.mode === "dark" ? "#1bb3c3" : "1bb3c3",
                color: (theme) =>
                    theme.palette.mode === "dark" ? "grey.300" : "grey.800",
                border: "1px solid",
                borderColor: (theme) =>
                    theme.palette.mode === "dark" ? "grey.800" : "grey.300",
                borderRadius: 2,
                fontSize: "0.875rem",
                fontWeight: "700",
                ...sx,
            }}
            {...other}
        />
    );
}

export const NoData = () => {
    return <div>No Data Found</div>;
};