import { useEffect, useState, useRef } from "react";
import axios from "axios";
import { fetchUrl } from "Urls";
import { GrFilter } from "react-icons/gr";
import useWorkflowTemplateTabHook from "hooks/WorkflowTemplateTab.hook";
import { _sortItems } from "constant/Methods";
import WorkflowTemplateListTable from "./WorkflowTemplateListTable";
import CreateWorkflowTemplate from "./CreateWorkflowTemplate";
import Spinner from "components/spinner";
import {
  Button,
  Divider,
  Grid,
  TextField,
  IconButton,
  Autocomplete,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  MenuItem ,Menu ,Stack ,Paper
} from "@mui/material";
import { Close, EventAvailable } from "@mui/icons-material";
const WorkflowTemplateTab = (props) => {
  const {
    credential,
    tableTemplateList,
    closeAndRefresh,
    showSpinner,
    templatePickWrap,
    updateTableStateDataWithRow,
    ...hookProps
  } = useWorkflowTemplateTabHook(props);

  const [workflowId, openedWorkflowTemplate] = useState(null);
  const [viewListData, setViewListData] = useState([]);
  //const [filteredList, setFilterList] = useState([]);
  var filteredList = useRef([]);
  //const [filterObject, setFilterObject] = useState([]); // [{fieldName : 'filterField1' , values : ['value 1' , 'value 2']} , {fieldName : 'filterField2' , values : ['value 1']}]
  var filterObject = useRef([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterPanel, enableFilterPanel] = useState(false);

  const [createPopEnable, setCreatePopEnable] = useState(null);
  const [openStepSelection, setStepSelection] = useState(false);
  const [stepSelected, setStepSelected] = useState(templatePickWrap?.Workflow_Step__c?.default);
  const [stepTypeOptions, setStepTypeOptions] = useState(templatePickWrap?.Workflow_Step__c?.valueList);

  const [selectedMenuRow, setSelectedMenuRow] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);

  const columns = [ { accessor: "name", Header: "Review Workflow", flex: 1,
                    sortType: (prev, curr, columnId) => _sortItems(prev, curr, columnId)},
    { accessor: "stepCount", Header: "Number of Steps", flex: 1 },
    { accessor: "createdBy", Header: "Created By", flex: 1 },
    { accessor: "createdDate", Header: "Created Date", flex: 1 },
    { accessor: "updatedDate", Header: "Last Update", flex: 1 },
    { accessor: "status", Header: "Status", flex: 1 },
    { accessor: "menuItem", Header: ""  },
    { accessor: "msg", Header: "" ,
    Cell: (params) => {
      const [row, setCurrow] = useState(params.row.original);
      return (
        <>
          {row.reviewCreated == true &&
            <div style={{color : 'red'}}>{`This ${row.status.toLowerCase()} template is linked to review.`}</div>
          }
        </>
      )
    }}
  ];
  useEffect(() => {
    //console.log('projectId :: '+projectId);
    setStepSelected(templatePickWrap?.Workflow_Step__c?.default);
    setStepTypeOptions(templatePickWrap?.Workflow_Step__c?.valueList);
    filterView();
  }, [tableTemplateList]);

  function _handleFilterSelection(event, newValue, fieldName) {
    const valuee = newValue;
    //console.log('valuee',JSON.stringify(valuee));

    let toTemp = [];
    let staticValues = [];
    if (valuee && valuee.length > 0) {
      for (let obj of valuee) {
        staticValues.push(obj.value);
      }
    }
    if (
      filterObject &&
      filterObject.current &&
      filterObject.current.length > 0
    ) {
      toTemp = JSON.parse(JSON.stringify(filterObject.current));
    }
    const fInd = toTemp?.findIndex((data) => data.fieldName == fieldName);
    if (fInd > -1) {
      toTemp[fInd].ele_values = valuee;
      toTemp[fInd].values = staticValues;
    } else {
      toTemp.push({
        fieldName: fieldName,
        values: staticValues,
        ele_values: valuee,
      });
    }
    const fInd2 = toTemp?.findIndex((data) => data.fieldName == fieldName);
    if (fInd2 > -1) {
      if (!(toTemp[fInd2].values && toTemp[fInd2].values.length > 0)) {
        toTemp.splice(fInd2, 1);
      }
    }
    //setFilterObject(toTemp);
    filterObject.current = toTemp;
    filterView();
  }
  const handleClickMenu = (event, row) => {
    event.preventDefault();
    event.stopPropagation();
    setSelectedMenuRow(row);
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
   // console.log("handleCloseMenu handleCloseMenu");
    setAnchorEl(null);
    setSelectedMenuRow(null);
  };

  function filterView(_filterObject = filterObject.current) {
    let filterData = [];
    setSearchTerm(null);
    if (tableTemplateList.length > 0) {
      if (_filterObject && _filterObject.length > 0) {
        for (let item of tableTemplateList) {
          let filCount = 0;
          let matchCount = 0;
          for (let fil of _filterObject) {
            if (fil.values && fil.values.length > 0) {
              filCount++;
              for (let check of fil.values) {
                if (
                  item[fil.fieldName]
                    ?.toLowerCase()
                    .indexOf(check.toLowerCase()) > -1
                ) {
                  matchCount++;
                }
              }
            }
          }
          if (filCount == matchCount) {
            filterData.push(item);
          }
        }
      }
    }
    //console.log('filterData :: '+filterData?.length);
    //setFilterList(filterData);
    filteredList.current = filterData;
    filterDataFuntion("");
  }
  function filterDataFuntion( _searchTerm, _filteredList = filteredList.current ) {
    let orgData = filterObject.current && filterObject.current.length > 0 ? JSON.parse(JSON.stringify(_filteredList)) : JSON.parse(JSON.stringify(tableTemplateList));
    //console.log('_filteredList :: ' + _filteredList?.length);
    //console.log('tableTemplateList :: ' + tableTemplateList?.length);
    let filterData = orgData;
    if (orgData && _searchTerm && _searchTerm != null && _searchTerm !== "") {
      filterData = [];
      for (let el of orgData) {
        if ( (el.name && el.name.toLowerCase().includes(_searchTerm))
              || (el.title && el.title.toLowerCase().includes(_searchTerm)) ) {
          filterData.push(el);
        }
      }
    }
    if (!filterData) {
      filterData = [];
    }
    setViewListData(filterData);
  }
  function getFilterObject(fieldName) {
    const fInd = filterObject.current?.findIndex(
      (data) => data.fieldName == fieldName
    );
    if (fInd > -1) {
      if (
        filterObject.current[fInd].values &&
        filterObject.current[fInd].values.length > 0
      ) {
        return filterObject.current[fInd];
      }
    }
    return;
  }
  function getFilterValues(fieldName, asOption) {
    let values = [];
    let obj = getFilterObject(fieldName);
    if (obj) {
      values = obj.values;
      if (asOption) {
        values = obj.ele_values;
      }
    }
    return values;
  }

  // console.log("********** props?.forTabHook?.tableFormList ", viewListData);

  return (
    <>
      <div className="container">
        {!createPopEnable && (
          <>
            <div className="table_head">
              <div className="left_side">
                <div>
                  <IconButton onClick={() => enableFilterPanel(!filterPanel)}>
                    <GrFilter />
                  </IconButton>
                </div>
                <div>
                  <TextField id="outlined-basic" variant="outlined" fullWidth label="Search"
                    onChange={(e) => {
                      //convert input text to lower case
                      var lowerCase = e.target.value.toLowerCase();
                      if (!lowerCase || lowerCase == null || lowerCase === "") {
                        //console.log(lowerCase);
                        filterDataFuntion(lowerCase, filteredList.current);
                      }
                      setSearchTerm(lowerCase);
                    }}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        // Do code here
                        e.preventDefault();
                        filterDataFuntion(searchTerm, filteredList.current);
                      }
                    }}
                  />
                </div>
                {filterObject.current?.length > 0 && (
                  <div>
                    <Chip
                      color="success"
                      label="Filtering is on"
                      onDelete={() => {
                        /*setFilterObject([]);*/ filterObject.current = [];
                        filterView([]);
                        enableFilterPanel(false);
                      }}
                    />
                  </div>
                )}
              </div>
              <div className="right_side">
                <Button variant="contained" component="span" onClick={() => setStepSelection(true)} >
                  Create Workflow
                </Button>
              </div>
            </div>
            <Grid container style={{ position: "flex" }}>
              {filterPanel && (
                <div className="versions-sidebar" style={{ paddingRight: 5, paddingLeft: 5 }} >
                  <div className="versions-sidebar-header">
                    <span className="versions-sidebar-header-title">
                      Filter
                    </span>
                    <IconButton size="small" className="versions-sidebar-header-close" onClick={() => enableFilterPanel(!filterPanel)} >
                      <Close />
                    </IconButton>
                  </div>
                  <Divider variant="middle" />
                  <div className="versions-sidebar-body" style={{ height: "calc(91.7vh - 200px)" }} >
                    <Grid container rowSpacing={2} style={{ width: "100%", paddingTop: "15px" }} className="sf-detail-container" >
                      <Grid item xs={12}>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              )}
              <Grid item style={{ flexGrow: 1 }}>
                <div className="TableSection" style={{ position: "relative" }}>
                  {showSpinner && <Spinner />}
                  <WorkflowTemplateListTable
                    templateTablePorps={{
                      data: viewListData,
                      columns,
                    }}
                    openFormDetail={(row) => {
                      openedWorkflowTemplate(row.id);
                      setCreatePopEnable(true);
                    }}
                    handleClickMenu={handleClickMenu}
                  />
                </div>
              </Grid>
            </Grid>
            {selectedMenuRow && (
              <>
                <Stack direction="row" spacing={2}>
                  <Paper sx={{ maxHeight: 320, maxWidth: "100%" }}>
                    <Menu id="basic-menu" anchorEl={anchorEl} open={true} onClose={handleCloseMenu} MenuListProps={{ "aria-labelledby": "basic-button" }} >
                      {/*selectedMenuRow.reviewCreated == true &&
                        <MenuItem onClick={async (e) => { handleCloseMenu(); }}>
                          {`This ${selectedMenuRow.status} template is linked to review.`}
                        </MenuItem>
                     */ }
                      {selectedMenuRow.reviewCreated &&
                        <MenuItem
                          onClick={async (e) => {
                            const cSlec = selectedMenuRow;
                            handleCloseMenu();
                            //setSpinnerM(true);
                            const formData = new FormData();
                            let recTosave = { Id: cSlec.record.Id, Status__c: cSlec.status == 'Active' ? 'Inactive' : 'Active' };
                            formData.append("recObj", JSON.stringify(recTosave));
                            await axios.post(`${fetchUrl}/recSave/Workflow_Template__c?token=${credential?.token}&instanceUrl=${credential?.instanceUrl}`, formData)
                              .then((res) => {
                                //console.log("count:::: " + JSON.stringify(res));
                                //setSpinnerM(false);
                                let tem = JSON.parse(JSON.stringify(cSlec));
                                tem.status = recTosave.Status__c;
                                tem.record.Status__c = recTosave.Status__c;
                                updateTableStateDataWithRow(tem);
                              }).catch((err) => {
                                console.log(err);
                                //setSpinnerM(false);
                              });
                          }}
                        >
                          <EventAvailable />
                          {selectedMenuRow.status == 'Active' ? 'Inactive' : 'Active'}
                        </MenuItem>
                      }
                    </Menu>
                  </Paper>
                </Stack>
              </>
            )}
          </>
        )}
        {createPopEnable && (
          <>
            <CreateWorkflowTemplate
              workflowId={workflowId}
              stepSelected={stepSelected}
              credential={credential}
              handleClose={(refresh) => {
                openedWorkflowTemplate(null);
                setCreatePopEnable(false);
                closeAndRefresh(refresh);
              }}
              stepPickWrap={hookProps?.stepPickWrap}
              userList={hookProps?.userList}
              projectTeamRoles={hookProps?.projectTeamRoles}
            ></CreateWorkflowTemplate>
          </>
        )}
      </div>
      {openStepSelection && (
        <Dialog fullWidth open={openStepSelection} scroll="paper">
          <DialogTitle>
            <Typography>
              Workflow Number of step
            </Typography>
            <IconButton aria-label="close" className="dialog-header-close" onClick={() => { setStepSelection(false); }} >
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent style={{ height: 300, width: "100%", position: "relative" }}>
            <Stack className="template_type_selection_wrapper" spacing={2} direction="column" >
              <div className="selectedDiv d-flex">
                <Autocomplete
                  className="common_class"
                  value={stepSelected}
                  getOptionLabel={(option) => option }
                  onChange={(event, newValue) => { setStepSelected(newValue); }}
                  options={stepTypeOptions}
                  renderInput={(params) => (
                    <div>
                      <TextField id="number of step" variant="outlined" label="Select Approval Workflow Step" {...params} />
                    </div>
                  )}
                />
              </div>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={() => { setStepSelection(false); }} >
              Cancel
            </Button>
            <Button variant="contained" onClick={() => { setStepSelection(false); setCreatePopEnable(true) }} >
              Next
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
export default WorkflowTemplateTab;
