import React, { useState, useEffect, useRef, useReducer } from "react";
import { Button, Dialog, IconButton } from "@mui/material";
import moment from "moment";
import axios from "axios";
import { fetchUrl } from "Urls";
import ReactPlayer from 'react-player';
import { RecordRenameDialog } from "components/DrawerComponent/DisciplineSettingDialog";
import { cloneDeep } from "lodash";
import Spinner from "components/spinner";
import { useSwipeable } from "react-swipeable";

function ViewMediaDialog({ ...props }) {
    const { onCancel, fileForView, setFileForView, currentMediaFiles, credential, updateTableState } = props;
    //console.log("fileForView :", fileForView);
    const [scale, setScale] = useState(1);
    const [showMediaDetails, setShowMediaDetails] = useState(false);
    const [fileInd, setFileIndState] = useState(-1);
    const [isNextEnabled, setNextEnabled] = useState(false);
    const [isPreviousEnabled, setPreviousEnabled] = useState(false);
    const [renameDialogData, setRenameDialogData] = useState(null);
    const [showSpinner, setSpinner] = useState(false);
    const fileIndRef = useRef(-1);
    const setFileInd = (state) => {
        fileIndRef.current = state;
        setFileIndState(state);
    };

    useEffect(() => {
        if (currentMediaFiles?.length > 0 && setFileForView) {
            let ind = currentMediaFiles.findIndex(t => t.id === fileForView.id);
            setFileInd(ind);
        } else {
            setFileInd(-1);
        }
    }, [fileForView, currentMediaFiles]);

    useEffect(() => {
        let nextEna = false;
        let prevEna = false;
        if (fileInd > -1 && currentMediaFiles?.length > 0) {
            nextEna = fileInd < (currentMediaFiles?.length - 1);
            prevEna = fileInd > 0;
        }
        setNextEnabled(nextEna);
        setPreviousEnabled(prevEna);
    }, [fileInd]);

    const handleWheel = (e) => {
        e.preventDefault();
        e.preventDefault(); // Prevent page zooming
        if (e.deltaY > 0) {
            setScale((prevScale) => Math.max(prevScale - 0.1, 0.5)); // Zoom out
        } else {
            setScale((prevScale) => Math.min(prevScale + 0.1, 3)); // Zoom in
        }
    };

    async function onClickOpenAction(action, clickSource) {
        let dialogData;
       if (action === 'rename') {
                var rowData = fileForView;
            if (rowData) {
                dialogData = {
                    title: `Rename`,
                    content: `Name label`,
                    action1: "Cancel",
                    action2: "Save Changes",
                    errorMessage: `Duplicate Name.`,
                    rowData: rowData,
                    rowTitleChange: true

                };
                setRenameDialogData(dialogData);
            }
        } 
    }

    const handleSwipeMedia = useSwipeable({
        onSwipedLeft: () => {
            if (!isNextEnabled) {
                return;
            }
            setFileForView(currentMediaFiles[fileIndRef.current + 1])
        }, 
        onSwipedRight: () => {
            if (!isPreviousEnabled) {
                return;
            }
            setFileForView(currentMediaFiles[fileIndRef.current - 1])
        },
        preventDefaultTouchmoveEvent: true,
        trackMouse: true // Allows mouse to simulate swipe
    });

    return (
        <Dialog open={true} fullScreen>
            <div className="builder">
                <div className="builder_head" >
                    <div className="builder_head_left">
                        <div className="builder_head_left-container" onClick={() => { onCancel() }}>
                            <div>
                                <img src="/left.svg" alt="leftIcon" />
                            </div>
                            <div className="header_label">
                            Go to Media
                            </div>
                        </div>
                    </div>
                    <div className="builder_head_center">
                        <div className="header_label_dark">{fileForView?.title}</div>
                        <div className="clickable custom_button" onClick={() => {
                                    onClickOpenAction('rename');
                                }}>
                            <img src="/Pencil.svg" alt="leftIcon" />
                        </div>
                    </div>
                    <div className="builder_head_right">
                        <div className="header_icon" style={{ alignItems: 'center', paddingTop: '0px', display: 'flex' }}>
                            <IconButton style={{ borderRadius: 0 }}>
                                <img style={{ cursor: "pointer", paddingBottom: '2px' }} src="/share.svg" alt="download icon" />
                            </IconButton>
                            <IconButton style={{ borderRadius: 0 }}>
                                <img style={{ cursor: "pointer" }} src="delete.svg" alt="share icon" />
                            </IconButton>
                            {!showMediaDetails &&
                                <IconButton style={{ borderRadius: 0 }} onClick={() => {
                                    setShowMediaDetails(true);
                                }}>
                                    <img style={{ cursor: "pointer" }} src="/info_grey.svg" alt="leftIco" />
                                </IconButton>
                            }
                        </div>
                        <>
                            <div>
                                <Button className="custom_button cancel_button">Export</Button>
                            </div>
                            <div>
                                <Button className="custom_button apply_button" onClick={() => { onCancel() }}>
                                    Close
                                </Button>
                            </div>
                        </>
                    </div>
                </div>
                <div {...handleSwipeMedia} style={{ touchAction: 'none' }}>
                {fileForView?.mediaType === 'Image' && 
                    <div onWheel={handleWheel}
                        style={{
                            overflow: "hidden",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: '#EAEAEA',
                            height: 'calc(100vh - 60px)'
                        }}
                    >
                        <img
                            src={fileForView?.url}
                            alt="media_image"
                            draggable={false}
                            style={{
                                transform: `scale(${scale})`,
                                transition: "transform 0.2s ease",
                                objectFit: "cover",
                            }}
                        />
                    </div>
                }
                {fileForView?.mediaType === 'Video' && 
                    <div onWheel={handleWheel}
                        style={{
                            overflow: "hidden",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: '#EAEAEA',
                            height: 'calc(100vh - 60px)'
                        }}
                    >
                        <ReactPlayer
                            url={fileForView?.url}
                            controls
                            width="100%"
                            height="100%"
                            style={{
                                transform: `scale(${scale})`,
                                transition: "transform 0.2s ease",
                                objectFit: "cover",
                            }}
                        />
                    </div>
                }
                </div>
                {showSpinner && <Spinner />}
                {showMediaDetails &&
                    <div className="mediaDetailsDialog">
                        <div style={{ display: 'flex', borderBottom: '1px solid #EAEAEA', padding: '12px 16px 12px 16px', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div className="header_label_dark" >Details</div>
                            <IconButton style={{ borderRadius: 0 }} onClick={() => {
                                setShowMediaDetails(false);
                            }}>
                                <img src="/close.svg" alt='close icon' />
                            </IconButton>
                        </div>
                        <div style={{ padding: '16px 16px 6px 16px' }}>
                            <div className="mediaDetails">
                                <div className="mediaDetailsFeild">
                                    Type
                                </div>
                                <div className="mediaDetailsFeildValue">
                                    {fileForView?.mediaType}
                                </div>
                            </div>
                            <div className="mediaDetails">
                                <div className="mediaDetailsFeild">
                                    Date Modified
                                </div>
                                <div className="mediaDetailsFeildValue">
                                    {moment(fileForView?.LastModifiedDate).format("MM/DD/YYYY")}
                                </div>
                            </div>
                            <div className="mediaDetails">
                                <div className="mediaDetailsFeild">
                                    Date Uploaded
                                </div>
                                <div className="mediaDetailsFeildValue">
                                    {moment(fileForView?.CreatedDate).format("MM/DD/YYYY")}
                                </div>
                            </div >
                            <div className="mediaDetails">
                                <div className="mediaDetailsFeild">
                                    Uploaded By
                                </div>
                                <div className="mediaDetailsFeildValue">
                                    {fileForView?.updatedBy}
                                </div>
                            </div>
                        </div>
                    </div>
                }
                <div className="mediaNextPreviousButton" style={{ left: '16px',  opacity: !isPreviousEnabled && 0.5 }} onClick={() => {
                    if (!isPreviousEnabled) {
                        return;
                    }
                    setFileForView(currentMediaFiles[fileIndRef.current - 1])
                }}>
                    <img src="/left.svg" alt="Previous Sheet" width={"16px"} height={"16px"} />
                </div>
                <div className="mediaNextPreviousButton" style={{right: '16px', opacity: !isNextEnabled && 0.5 }} onClick={() => {
                    if (!isNextEnabled) {
                        return;
                    }
                    setFileForView(currentMediaFiles[fileIndRef.current + 1])
                }}>
                    <img src="/right.svg" alt="Next Sheet" width={"16px"} height={"16px"} />
                </div>
            </div>
            {renameDialogData &&
                <RecordRenameDialog
                    onCancel={() => {
                        setRenameDialogData(null);
                    }}
                    onAccept={(record, sobjectName, oldName, newName) => {
                        let sfObj = {
                            Id : record.Id,
                            Document_Title__c: newName
                        }
                        setSpinner(true);
                        const formData = new FormData();
                        //let issueObj = sfIssueObj;
                        formData.append("recObj", JSON.stringify(sfObj));
                        axios.post(`${fetchUrl}/recSave/${sobjectName}?token=${credential?.token}&instanceUrl=${credential?.instanceUrl}`, formData)
                            .then(async (res) => {
                                //console.log("count:::: " + JSON.stringify(res));

                                if (res.data.status === 200) {
                                    let file = cloneDeep(fileForView);
                                    file.title = newName;
                                    updateTableState(file);
                                    setFileForView(file);
                                    setSpinner(false);
                                }
                            }).catch((err) => {
                                console.log('recSave/Set__c', err);
                                setSpinner(false);
                            });
                        setRenameDialogData(null);
                    }}
                    orgData={currentMediaFiles}
                    dialogData={renameDialogData}
                />
            }
        </Dialog>
    );
}
export default ViewMediaDialog;