import React from "react";
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Switch,
  Stack,
  Typography,
  IconButton,
  Tooltip
} from "@mui/material";
import { DeleteRounded, ArrowUpwardRounded , ArrowDownwardRounded} from "@mui/icons-material";

import { RESPONSE_TYPES } from 'components/FormTemplateTabComponent/ChecklistFormTemplate/constants';
import ResponseTypeRenderer from './ResponseTypeRenderer';


const QuestionSection = ({ question, dispatchObj, sectionIndex, questionIndex ,...props }) => {
  // handle change
  const handleChange = e => {
    let fieldKey = e.target.name;
    let value = e.target.value;
    if(e.target.type == 'checkbox'){
      value = e.target.checked;
      if(fieldKey == 'Optional__c'){
        value = !e.target.checked; // only using opposite / (! value) value bcz toggel/switch button is using opposite / (! value) value to determine checked value
      }
    }
    dispatchObj({
      type: 'HANDLE_CHANGE_QUESTION_FIELD',
      sectionIndex,
      questionIndex,
      fieldKey: fieldKey,
      value: value
    });
  };
  const removeQuestion = e => {
    //dispatchObj({type: 'REM_QUESTION' , sectionIndex , questionIndex });
    dispatchObj({type: 'REM_QUESTION_WARNING' , sectionIndex , questionIndex });
  };
  const moveUpQuestion = e => {
    console.log('moveUpnQuestion');
    dispatchObj({type: 'MOVE_QUESTION' , sectionIndex , from : questionIndex , to : (questionIndex - 1)});
  };
  const moveDownQuestion = e => {
    console.log('moveDownQuestion');
    dispatchObj({type: 'MOVE_QUESTION' , sectionIndex , from : questionIndex , to : (questionIndex + 1) });
  };

  return (
    <Grid container justifyContent="space-between" className="pad-8 question_card_section_fields" >
      <Grid container xs={1} alignItems="flex-start" justifyContent="flex-end" >
        <Grid item>
          {props?.sectionSortOrder}.{question.Numbering_Sequence__c}
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <TextField id={`question-${question.Numbering_Sequence__c}`} name="Question__c" label="Question" variant="outlined" size="small" inputProps={{ className: "desc_input" }} className="question_desc" fullWidth
          value={question.Question__c} onChange={handleChange} />
        <TextField id={`questionDescription-${question.Numbering_Sequence__c}`} name="Description__c" label="Description" variant="outlined" size="small"
          inputProps={{ className: "desc_input" }} className="question_desc" fullWidth
          value={question.Description__c} onChange={handleChange} />
      </Grid>
      <Grid item xs={4}>
        <FormControl fullWidth size="small">
          <InputLabel id="form_response_type">Response Type</InputLabel>
          <Select labelId="form_response_type" id={`responseType-${question.Numbering_Sequence__c}`} name="Response_Type__c"  label="Response type"
            value={question.Response_Type__c} onChange={handleChange} >
            {RESPONSE_TYPES.map((r) => (
              <MenuItem key={r.value} value={r.value}>
                {r.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      {/* response types render */}
      <Grid item xs={12} className="response_type_renderer-wrapper">
        <Grid container justifyContent="space-between">
          <Grid item xs={1}></Grid>
          <Grid item xs={6}>
            <ResponseTypeRenderer handleChange={handleChange} responseOptions={question.responseOptions} selectedType={question.Response_Type__c}/>
          </Grid>
          <Grid item xs={4}></Grid>
        </Grid>
      </Grid>
      <Grid container xs={12} style={{borderTop : '1px solid #ddd'}}>
        <Grid item xs={1} style={{marginRight:'5rem'}}>
        </Grid>
        <Grid item>
          <Switch
            name="Optional__c"
            checked = {!question.Optional__c}
            onChange={handleChange}
            inputProps={{ 'aria-label': 'controlled' }}
          />Required
        </Grid>
        <Grid container xs={9} alignItems="flex-start" justifyContent="flex-end" style={{paddingTop:'1rem'}}>
          { (! questionIndex == 0 ) && 
            <IconButton onClick={moveUpQuestion} disabled={questionIndex == 0}
              className={questionIndex == 0 ? 'disableRippleIcon':''}>
              <ArrowUpwardRounded color={questionIndex == 0? "disabled" : "primary"} />
            </IconButton>
          }
          { (! (( props?.totalQuestions - 1) == questionIndex) ) && 
            <IconButton onClick={moveDownQuestion} disabled={(( props?.totalQuestions - 1) == questionIndex)} color="primary"
              className={(( props?.totalQuestions - 1) == questionIndex) ? 'disableRippleIcon':''}>
              <ArrowDownwardRounded color={(( props?.totalQuestions - 1) == questionIndex) ? "disabled" : "primary"}/>
            </IconButton>
          }
          <IconButton onClick={removeQuestion}>
            <DeleteRounded color="primary" />
          </IconButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default QuestionSection;
