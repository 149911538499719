import { GetToken } from "api";
import { useState, useEffect } from "react";
import _ from "lodash";
import axios from "axios";
import { fetchUrl } from "Urls";

const useProjectFormHook = (props) => {
  const { projectId } = props;
  const [showSpinner, setSpinner] = useState(false);
  const [createPopEnable, setCreatePopup] = useState(false);
  const [tableProjectFormList, setTableProjectFormList] = useState([]);
  // filter selection
  const [credential, setCredential] = useState(props?.credentials);
  const [formStatusOptions, setFormStatusOptions] = useState([]);
  const [defaultFormStatus, setDefaultFormStatus] = useState('');
  const [userList, setUserList] = useState([]);

  useEffect(() => {
    //console.log('projectId :: '+projectId);
    if(projectId){ // && credential?.projectId != projectId
      getInitData(projectId);
    }
  }, [projectId]);
  
  async function getInitData(_projectId) {
    if(projectId){
      setSpinner(true);
      //console.log('useProjectFormHook :: '+projectId);
      await fetchProjectFormLists(credential);
    }
    setSpinner(false);
  };
  function _handleOpenCreate(status) {
    setCreatePopup(status);
  }
  const closeAndRefresh= async function (refresh) {
    console.log('closeAndRefresh :: '+refresh);
    _handleOpenCreate(false);
    if(refresh == true){
      await fetchProjectFormLists(credential);
    }
  }

  async function fetchProjectFormLists(_credential = credential) {
    setSpinner(true);
    await axios.get(`${fetchUrl}/projectFormsLists/${_credential.projectId}?token=${_credential?.token}&instanceUrl=${_credential?.instanceUrl}`)
    .then((res) => {
      if (res?.status === 200) {
        const reData = res.data;
        if (reData?.status === 200) {
          setFormStatusOptions(reData?.formStatusOptions);
          setDefaultFormStatus(reData?.defaultFormStatus);
          setUserList(reData?.userList);
          const values = _.map(reData?.projectForms, (row) => {
            const tablePayload = {
              createdBy: row?.CreatedById ? row?.CreatedBy?.Name : "-",
              createdById: row?.CreatedById ? row?.CreatedById : "-",
              createdDate: row?.CreatedDate ? row?.CreatedDate : "-",
              id: row?.Id ? row?.Id : "-",key: row?.Id ? row?.Id : "-",
              numberSequence: row?.Numbering_Sequence__c ? row?.Numbering_Sequence__c : "",
              title: row?.Name ? row?.Name : "-",
              status: row?.Status__c ? row?.Status__c : "-",
              formDate : row?.Date__c ? row?.Date__c : "-",
              form: row?.Form_Template__c && row?.Form_Template__r.Name ? row?.Form_Template__r.Name : "-",
              type: row?.Form_Template__c && row?.Form_Template__r.Template_Category__c ? row?.Form_Template__r.Template_Category__c : "-",
              formType: row?.Form_Template__c && row?.Form_Template__r.Form_Type__c ? row?.Form_Template__r.Form_Type__c : "-",
              assignedTo: row?.Ball_in_Court__c && row?.Ball_in_Court__r.Name ? row?.Ball_in_Court__r.Name : "-",
              rObj : JSON.parse(JSON.stringify(row))
            };
            return tablePayload;
          });
          setTableProjectFormList(values);
        }
      }
    }).catch((err) => {
      setSpinner(false);
      console.log('formTemplateLists :: ');
      console.error(err);
    });
    setSpinner(false);
  }
  return { createPopEnable, tableProjectFormList, _handleOpenCreate,
          credential, closeAndRefresh, showSpinner, setSpinner , formStatusOptions,
          defaultFormStatus, userList};
  //return { tableProjectFormList,  credential,  /*templateTypeOptions , defaultTemplateType , */ _fetchProjectFormLists:fetchProjectFormLists };
};
export default useProjectFormHook;
