import React, { useEffect ,useState} from "react";
import { DialogContent, IconButton, Box, Tabs, Tab ,  DialogContentText ,ButtonGroup} from "@mui/material";
import DialogComponent from "components/Shared/DialogComponent";
import { Edit, Close } from "@mui/icons-material";
import { TabContext, TabPanel } from "@mui/lab";
import DetailTab from "../IssueRelativeComponent/IssueDetail";
import IssueDetailActivity from "../IssueRelativeComponent/IssueDetailActivity";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import IssueDetailPopupStyle from "./IssueDetailPopupStyle";
import IssueAttachmentSection from "../IssueRelativeComponent/IssueAttachmentSection";
import axios from "axios";
import { fetchUrl } from "Urls";
import styled from "styled-components";

function CenteredTabs({ credential, editMode , setEditMode , updateIssueTableStateDataWithRow , selectedRowData ,linkDocumentProps}) {
  const { treeFolderStructure , _onAddDocClick , loadingProgress ,getFilesApi, filesData,updateStateTableDataWithRow} = linkDocumentProps;
  const [value, setValue] = React.useState("1");
  const [attachments, setAttachments] = React.useState([]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    console.log("selectedRowData", selectedRowData);
    if(selectedRowData?.id){
      getAttachments();
    }
    //console.log("selectedRowData stringify", JSON.stringify( selectedRowData ) );
  }, [selectedRowData?.id]);
  const getAttachments = async () => {
    const reqBody = {parentIds : [selectedRowData.id] , parentField : 'Related_To_ID__c'};
    setAttachments([]);
    await axios.post(`${fetchUrl}/getRelatedAttachments?token=${credential?.token}&instanceUrl=${credential?.instanceUrl}`,reqBody)
    .then((res) => {
      if (res?.status === 200) {
        const listValue = res.data;
        if (listValue?.status === 200) {
          if(listValue.attachMap && listValue.attachMap[selectedRowData.id]){
            setAttachments(listValue.attachMap[selectedRowData.id]);
          }
        }
      }
    }).catch((err) => {
      console.error(err);
    });
  };
  return (
    <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={value} onChange={handleChange} centered>
            <Tab label="Details" value="1" />
            <Tab label="Attachments" value="2" />
            <Tab label="Activity" value="3" />
          </Tabs>
        </Box>
        <TabPanel value="1">
          <DetailTab credential={credential}
            editMode={editMode} setEditMode={setEditMode} selectedRowData={selectedRowData}
            updateIssueTableStateDataWithRow={updateIssueTableStateDataWithRow} />{" "}
        </TabPanel>
        <TabPanel value="2">
          <IssueAttachmentSection credential={credential}
            getFilesApi={getFilesApi}
            selectedRowData={selectedRowData}
            attachments={attachments}
            setAttachments={setAttachments}
            treeFolderStructure={treeFolderStructure}
            _onAddDocClick={_onAddDocClick}
            loadingProgress={loadingProgress}
            filesData={filesData}
            updateStateTableDataWithRow={updateStateTableDataWithRow}
          />
        </TabPanel>
        <TabPanel value="3">
          <IssueDetailActivity />
        </TabPanel>
      </TabContext>
    </Box>
  );
}

const ActionChildren = () => (
  <Box className="issuesAction_section">
    <Box className="checkBox_section"></Box>
    <Box className="buttons_section"></Box>
  </Box>
);
var selectedRowDataD ;

const TitleContent = () => (
  <IssueDetailPopupStyle>
    <Box className="issueDetailTitle">
      <Box className="top_head">
        {/*<p>open</p>
        <IosShare />*/}
        <ButtonGroup 
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}>
           <IconButton onClick={editModeCheck}>
            <Edit />
          </IconButton>
             <IconButton onClick={() => TitleContentClose()}>
            <Close />
          </IconButton>
        </ButtonGroup>
      </Box>
      <Box className="bottom_head">
        <h3 className="subHeading">Issue</h3>
        <h6 className="heading">{selectedRowDataD?.title}</h6>
      </Box>
    </Box>
  </IssueDetailPopupStyle>
);

var TitleContentClose = () => {
  console.log('titleClose');
};
var editModeCheck = () => {
  console.log('editMode');
};

function IssueDetailPopup(props) {
  const { credential} = props;
  const { openDetail , handleClose, updateIssueTableStateDataWithRow ,selectedRowData} = props?.issueDetailProps;
  const { treeFolderStructure , _onAddDocClick , loadingProgress , filesData,updateStateTableDataWithRow , getFilesApi } = props?.linkDocumentProps;
  selectedRowDataD = selectedRowData;
  const [editMode , setEditMode] = useState(false);
  editModeCheck = () => {setEditMode(!editMode)};
  TitleContentClose = () => {handleClose()};
  
  return (
    <DialogComponent
      dialogProp={{
        opened: openDetail,
        titleContent: <TitleContent />,
        actionDisplay: false,
        actionChildren: <ActionChildren />,
        classFor: "issueDetail_popup",
      }}
    >
      <IssueDetailPopupStyle active={true}>
        <DialogContent className="IssueDetail_class">
        <DialogContentText >
          {/*<IconButton className="right_icon">
            <ArrowBackIosNewIcon />
          </IconButton>*/}
          <CenteredTabs credential={credential} editMode={editMode}
            setEditMode={setEditMode} selectedRowData={selectedRowData}
            updateIssueTableStateDataWithRow={updateIssueTableStateDataWithRow}
            linkDocumentProps={{
              treeFolderStructure,
              getFilesApi,
              _onAddDocClick,
              loadingProgress,
              filesData,
              updateStateTableDataWithRow
            }}/>
          {/*<IconButton className="left_icon">
            <ArrowForwardIosIcon />
          </IconButton>*/}
        </DialogContentText>
        </DialogContent>
      </IssueDetailPopupStyle>
    </DialogComponent>
  );
}

export default IssueDetailPopup;
