import { Dialog, DialogTitle, DialogContent, DialogActions, InputLabel, Select, FormControl, MenuItem, Button, OutlinedInput, Stack, TextField, InputAdornment } from "@mui/material";
import { toast } from "react-toastify";
import IconButton from "@mui/material/IconButton";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Search } from "@mui/icons-material";
import _, { cloneDeep } from "lodash";
import styled from "styled-components";
import axios from "axios";
import { fetchUrl } from "Urls";
import moment from "moment";
import { GetToken } from "api";
import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect, useState } from "react";
import Spinner from "components/spinner";
import ShopDrawings from "components/DatableComponent/shopDrawings";

const Styles = styled.div`
.modal-header {
  align-items: flex-start;
  background-color: #FFFFFF; 
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #EAEAEA;
  border-radius: 2px 2px 0px 0px;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  padding: 16px 20px;
  position: relative;
  width: 400px;
  }
  .edit-set {
  color: #000000;
  font-family: var(--font-family-poppins); 
  font-size: var(--font-size-m);
  font-weight: 600;
  letter-spacing: 0.16px;
  line-height: normal;
  margin-top: -1px; 
  position: relative; 
  width: fit-content;
  }
  .buttons {
    align-items: center; 
    border-radius: 2px; display: flex;
   // height: 24px;
    justify-content: center;
    overflow: hidden;
    position: relative;
    width: 24px;
    }
    .close {
      &:hover {
        background: #F8F8F8;
      }
      cursor: pointer;
    height: 16px;
    position: relative;
    width: 16px;
    }
    .modal-body {
    
    align-items: flex-start;
    background-color: #FFFFFF; 
    display: flex;
    flex-direction: column;
    gap: 12px;
    justify-content: center;
    padding: 16px 20px;
    position: relative;
    width: 400px;
    }
    .edit-the-following-i {
    color: #505050;
    font-weight: 400;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    width: 360px;
    }
    .span1 {
    letter-spacing: 0.14px;
    }
    .textfield {
      align-items: flex-start;
      border-radius: 2px; 
      display: flex;
      flex: 0 0 auto;
      flex-direction: column;
      gap: 4px;
      position: relative;
      width: 360px;
      }
      .label, 
      .text,
      .label-1,
      .text-1 {
        cursor: pointer;
        .MuiOutlinedInput-input {
          letter-spacing: 0; 
          font-size: 14px;
          line-height: normal;
          color: #505050;
          width: 330px;
    height: 5px;
        }
        .MuiOutlinedInput-input:hover {
          border-color: #D8D8D8;
          background-color: #F8F8F8;
          color: #929292;
        }
        .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
          border-color: #D8D8D8;
        }
        .MuiOutlinedInput-notchedOutline {
          border-color: #D8D8D8;
        }
        label {
          font-family: var(--common-font);
          font-size: 14px;
          color: var(--text-color);
        }

        & > label {
          margin-bottom: 5px;
        }
        &:nth-child(1) {
          margin-bottom: 10px;
        }
        .MuiFormControl-root {
          label:not(.Mui-focused) {
            margin: -8px 0 0;
            /* top: -9px; */
          }
          label.MuiFormLabel-filled {
            margin: 0;
          }
        }
      }
      .MuiSelect-select {
        padding: 8px 12px;
        font-size: 14px;
       // background: #eaeaea;
        color: #bababa;
        border-color: #bababa;
        em {
          font-family: var(--common-font);
          font-style: normal;
        }
      }
      .select_cstm {
        border: 1px solid #bababa;
        .MuiOutlinedInput-notchedOutline {
          border: none;
        }
      }
      .Mui-focused.select_cstm {
        border: 1px solid var(--drblue);
      }
      letter-spacing: 0; 
      line-height: normal;
      margin-top: -1px; 
      position: relative; 
      width: fit-content;
      color: #505050;
      }
      .frame-4 {
        align-items: flex-end;
        align-self: stretch;
        background-color: #FFFFFF;
        border: 1px solid;
        border-color: #D8D8D8;
        border-radius: 2px;
        display: flex;
        flex: 0 0 auto;
        gap: 10px;
        padding: 8px 12px;
        position: relative;
        width: 100%;
        }
        .textfield-1 {
          align-items: flex-start;
          border-radius: 2px; 
          display: flex;
          flex: 0 0 auto;
          flex-direction: column;
          gap: 4px;
          position: relative;
          width: 360px;
          }
          .label-2,
          .text-2 {
          letter-spacing: 0; 
          line-height: normal;
          margin-top: -1px;
          position: relative;
          width: fit-content;
          color: #505050;
          }
          .content {
            &:hover {
              background: #F8F8F8;
            }
            align-items: center;
            align-self: stretch;
            background-color: #FFFFFF;
            border: 1px solid;
            border-color: #D8D8D8;
            border-radius: 2px;
            display: flex;
            flex: 0 0 auto;
            gap: 12px;
            justify-content: space-around;
            padding: 8px 12px;
            position: relative;
            cursor: pointer;
            width: 100%;
            }
            .field-and-icon{
            align-items: center;
            display: flex;
            flex: 1;
            flex-grow: 1;
            justify-content: space-between;
            position: relative;
            }
            .down {
            height: 16px;
            position: relative;
            width: 16px;
            }
            .buttons-2 {
              &:hover {
                background: #F8F8F8;
              }
              cursor: pointer;
            align-items: center;
            background-color: #FFFFFF;
            border: 1px solid;
            border-color: #505050;
            border-radius: 2px;
            display: inline-flex;
            flex: 0 0 auto;
            gap: 8px;
            justify-content: center;
            overflow: hidden;
            padding: 9px 16px;
            position: relative;
            color: #505050;
            }
            .modal-bottom {
              align-items: center;
              background-color: #FFFFFF;
              border-color: #EAEAEA; 
              border-radius: 0px 0px 2px 2px;
              border-top-style: solid;
              border-top-width: 1px; 
              display: flex; 
              gap: 12px;
              justify-content: flex-end;
              overflow: hidden;
              padding: 12px 20px;
              position: relative;
              width: 400px;
              }
              .dropdown-list {
              overFlow-y: auto;
              overFlow-x: hidden;
              height: 300px;
              align-items: flex-start;
              background-color: #FFFFFF; 
              box-shadow: 0px 4px 12px #00000040; 
              display: flex;
              padding: 12px 0px;
              position: fixed;
              width: 360px;
              margin-top: 65px;
              z-index: 1;

              }
              .dropdown-content {
              align-items: flex-start;
              display: flex;
              flex: 1;
              flex-direction: column;
              flex-grow: 1;
              gap: 16px;
              position: relative;
              }
              .content-1 {
                label +.MuiInputBase-formControl .MuiOutlinedInput-input {
                  height: 21px;
                  padding: 8px 12px !important;
                  line-height: normal;
                  //color: #D8D8D8;
              }
              .MuiFormControl-root {
                label {line-height: normal;
                    top: -7px;
                    font-family: var(--common-font);
                    font-size: 14px;
                }
                .MuiInputBase-formControl {
                    border-radius: 2px;
                }
                } 
              align-items: flex-start;
              align-self: stretch; display: flex;
              flex: 0 0 auto;
              flex-direction: column;
              gap: 16px;
              padding: 0px 12px;
              position: relative;
              width: 345px;
              }
              .selection {
              width: 325px;
              align-items: flex-start; display: inline-flex;
              flex: 0 0 auto;
              flex-direction: column;
              gap: 12px;
              position: relative;
              .create-new-set {
                &:hover {
                  background: #F8F8F8;
                }
                cursor: pointer;
              color: #000000;
              font-weight: 600; line-height: normal;
              margin-top: -1px; position: relative;
              width: fit-content;
              }
            }
              .frame {
                &:hover {
                  background: #F8F8F8;
                }
                cursor: pointer;
              align-items: flex-start;
              display: inline-flex;
              flex: 0 0 auto;
              flex-direction: column;
              gap: 2px;
              position: relative;
              width: inherit;
              }
              .labelBold {
              color: #505050;
              font-weight: 400;
              line-height: normal;
              margin-top: -1px; position: 
              relative; 
              width: fit-content;
              }
              .label-22 {
                color: #929292; 
                font-weight: 400;
                line-height: normal;
                position: relative;
                width: fit-content;
                color: #929292;
                }
                .textfielded {
                align-items: flex-start;
                align-self: stretch;
                background-color: #FFFFFF;
                border: 1px solid;
                border-color: #D8D8D8; 
                border-radius: 2px;
                display: flex;
                flex: 0 0 auto;
                flex-direction: column;
                padding: 8px 12px;
                position: relative;
                width: 100%;
                }
                .search {
                align-items: center;
                align-self: stretch;
                display: flex;
                flex: 0 0 auto;
                justify-content: space-between;
                position: relative;
                width: 100%;
                }
                .search-label { 
                  letter-spacing: 0; 
                  line-height: normal;
                  margin-top: -1px;
                  position: relative;
                  width: fit-content;
                  color: #929292;
                  }
                  .search-1 {
                  height: 16px;
                  position: relative;
                  width: 16px;
                  }
                  .references {
                    align-items: flex-start;
                    background-color: #F8F8F8; 
                    border: 1px solid;
                    border-color: #EAEAEA;
                    border-radius: 2px;
                    display: inline-flex; 
                    flex-direction: column;
                    gap: 12px;
                    padding: 12px;
                    position: relative;
                    }
                    .frame-31 {
                    cursor: pointer;
                    align-items: flex-start;
                    display: flex;
                    flex: 0 0 auto;
                    justify-content: space-between;
                    position: relative;
                    width: 344px;
                    }
                    .frame-30 {
                    align-items: flex-start;
                    display: inline-flex;
                    flex: 0 0 auto;
                    gap: 4px;
                    position: relative;
                    }
                    .sheets, 
                    .down {
                    height: 16px;
                    position: relative;
                    width: 16px;
                    }
                    .selected-sheets-4 {
                    color: #505050;
                    font-weight:
                    400;
                    line-height: normal;
                    margin-top: -1px;
                    position: relative;
                    width: fit-content;
                    }
                    .photos {
                    height: 150px;
                    overflow: auto;
                    width: 100%;
                    align-items: flex-start;
                    display: inline-flex;
                    flex: 0 0 auto;
                    flex-direction: column;
                    gap: 8px;
                    position: relative;
                    }
                    .photos-1 {
                    width: 100%;
                    align-items: flex-start;
                    display: inline-flex;
                    flex: 0 0 auto;
                    flex-direction: column;
                    gap: 8px;
                    position: relative;
                    }
                    .fa-10000 {
                    color: #000000;
                    font-weight: 400;
                    line-height: normal;
                    margin-top: -1px; 
                    position: relative; 
                    width: fit-content;
                    }
                    .fa-10 {
                    color: #000000;
                    font-weight: 400;
                    line-height: normal; 
                    position: relative; 
                    width: fit-content;
                    }
`;
function AssignmentDialog({ credential, attachRow, selectedItems, setEditSetAssignPopup, setSpinner, sourceFolder, editSetAssignPopup, updateTableState, handleClose, selected_ids, showShopDrawings, option }) {

  const selectedSetNames = selectedItems.map(item => item.set);
  const uniqueSetNames =  Array.from(new Set(selectedSetNames));
  const [showDropdown, setShowDropdown] = useState(false);
  const [fileDropdown, setFileDropdown] = useState(false);
  const [newSet, setNewSet] = useState(false);
  const [selectedSet, setsSelectedSet] = useState({ value: attachRow?.setId ? attachRow?.setId : '', name: attachRow?.set });
  const [setName, setSetName] = useState({
    value: attachRow ? attachRow.setId : (selectedItems[0]?.setId || ""),
    name: (selectedItems.length > 0 && uniqueSetNames.length === 1) ? (attachRow ? attachRow.set : selectedItems[0]?.set) : (attachRow ? attachRow.set : ""),
    createNew: ""
  });
  const [typeName, setTypeName] = useState({
    name: attachRow ? attachRow.shopDrawing : (selectedItems[0]?.shopDrawing || ""),
  });
  const [Number, setNumber] = useState(attachRow ? attachRow.Document_Number__c : selectedItems[0].Document_Number__c);
  const [Title, setTitle] = useState(attachRow ? attachRow.title : selectedItems[0].title);
  const [newSetName, setNewSetName] = useState();
  const [dateValue, setDateValue] = useState(null);

  const handleChange = (event) => {
    setNewSetName(null);
    setSetName({ createNew: "" });
    setShowDropdown(false);
    setNewSet(false);
    const fInd = option?.findIndex((data) => (showShopDrawings ? data === event : data.value === event));
    if (fInd > -1) {
      let sel = option[fInd];
      let selectedSet_ = { value: showShopDrawings ? sel : sel.value, name: showShopDrawings ? sel : sel.label };
      //console.log('selectedSet :: '+JSON.stringify(selectedSet_));
      setSetName(selectedSet_);
      setTypeName(selectedSet_);
    }
  };
  async function handleSaveSet() {
    if(setName.name){
    setSpinner(true);
    const { token, instanceUrl, projectId } = credential;
    let obj = {
      Id: attachRow ? attachRow.id : selectedItems[0].id,
      Document_Number__c: Number,
      Document_Title__c: Title
    };
    const formData = new FormData();
    //let issueObj = sfIssueObj;
    formData.append("recObj", JSON.stringify(obj));
    await axios.post(`${fetchUrl}/recSave/Amazon_S3_Attachment__c?token=${token}&instanceUrl=${instanceUrl}`, formData)
      .then(async (res) => {

      })
    
    handleSave();
    }
  }
  async function _getSetsApi() {
    //setSpinner(true);
    const { token, instanceUrl, projectId } = credential;
    await axios.get(`${fetchUrl}/sets/${projectId}?token=${token}&instanceUrl=${instanceUrl}`)
      .then((res) => {
        if (res?.status === 200) {
          let roww = [];
          res?.data?.records?.map((val, index) => {
            if (val?.Issuance_Date__c != null) {
              roww.push({ value: val?.Id, label: val?.Name, date: moment(val?.Issuance_Date__c).format("MMM DD YYYY") });
            }
          });
   
        }
      })
      .catch((err) => {
        console.log(err);
      });
    //setSpinner(false);
  }

  async function handleSave() {
    const { token, instanceUrl, projectId } = credential;
    if(!showShopDrawings){
    if (newSetName) {
      setSpinner(true);
      let obj = newSetName;
      obj.Project__c = projectId;
      const formData = new FormData();
      //let issueObj = sfIssueObj;
      formData.append("recObj", JSON.stringify(obj));
      await axios.post(`${fetchUrl}/recSave/Set__c?token=${token}&instanceUrl=${instanceUrl}`, formData)
        .then(async (res) => {
          if (res.data.status == 200) {

            if (selectedItems.length > 0) {
              let k = 0;
            
              Promise.all(selectedItems).then((values) => {
                values.forEach(async (f) => {
                  let obj  = { Id: f.id, Set__c: res.data.retObj.id };;
                  const formDatas = new FormData();
                  formDatas.append("recObj", JSON.stringify(obj));
                  await axios.post(`${fetchUrl}/recSave/Amazon_S3_Attachment__c?token=${token}&instanceUrl=${instanceUrl}`, formDatas).then((res) => {
                  }).catch((err) => {
                    console.log(err);
                    setSpinner(false);
                  });
                  //console.log('k ',k);
                  k++;
                  if (k == selectedItems.length) {
                    setSpinner(false);
                    setEditSetAssignPopup(true);
                  }
                });
              });
            } else {
              let obj  = { Id: attachRow ? attachRow?.id : selectedItems[0]?.id, Set__c: res.data.retObj.id };;
              const formData1 = new FormData();
              formData1.append("recObj", JSON.stringify(obj));
              await axios.post(`${fetchUrl}/recSave/Amazon_S3_Attachment__c?token=${token}&instanceUrl=${instanceUrl}`, formData1).then((res) => {
                  setSpinner(false);
                  setEditSetAssignPopup(true);
                }).catch(err => {
                  console.error(err);
                  toast.error("Edit Failed");
                });
              
            }
          }
        }).catch((err) => {
          console.log('recSave/Set__c', err);
        });
    } else {
      if (selectedItems.length > 0) {
        let k = 0;
        Promise.all(selectedItems).then((values) => {
          values.forEach(async (f) => {
            let obj  = { Id: f.id, Set__c: setName.value };;
            const formData2 = new FormData();
            formData2.append("recObj", JSON.stringify(obj));
            await axios.post(`${fetchUrl}/recSave/Amazon_S3_Attachment__c?token=${token}&instanceUrl=${instanceUrl}`, formData2).then((res) => {

            }).catch((err) => {
              console.log(err);
              setSpinner(false);
            });
            //console.log('k ',k);
            k++;
            if (k == selectedItems.length) {
              setSpinner(false);
              setEditSetAssignPopup(true);
            }
          });
        });
      }else{
      setSpinner(true);
      let obj  = { Id: attachRow ? attachRow?.id : selectedItems[0]?.id, Set__c: setName.value };
      const formData3 = new FormData();
      formData3.append("recObj", JSON.stringify(obj));
      await axios.post(`${fetchUrl}/recSave/Amazon_S3_Attachment__c?token=${token}&instanceUrl=${instanceUrl}`, formData3).then((res) => {
        setSpinner(false);
        setEditSetAssignPopup(true);
      }).catch(err => {
        console.error(err);
        toast.error("Edit Failed");
      });
      setSpinner(false);
    }
  }
}else {
  if (selectedItems.length > 0) {
    let k = 0;
    Promise.all(selectedItems).then((values) => {
      values.forEach(async (f) => {
        let obj  = { Id: f.id, Shop_Drawing_Type__c: typeName.name };
        const formData4 = new FormData();
        formData4.append("recObj", JSON.stringify(obj));
        await axios.post(`${fetchUrl}/recSave/Amazon_S3_Attachment__c?token=${token}&instanceUrl=${instanceUrl}`, formData4).then((res) => {
        }).catch((err) => {
          console.log(err);
          setSpinner(false);
        });
        //console.log('k ',k);
        k++;
        if (k == selectedItems.length) {
          setSpinner(false);
          setEditSetAssignPopup(true);
        }
      });
    });
  }else{
  setSpinner(true);
  let obj  = { Id: attachRow ? attachRow?.id : selectedItems[0]?.id, Shop_Drawing_Type__c: typeName.name };
  const formData5 = new FormData();
  formData5.append("recObj", JSON.stringify(obj));
  await axios.post(`${fetchUrl}/recSave/Amazon_S3_Attachment__c?token=${token}&instanceUrl=${instanceUrl}`, formData5).then((res) => {
    setSpinner(false);
    setEditSetAssignPopup(true);
  }).catch(err => {
    console.error(err);
    toast.error("Edit Failed");
  });
  setSpinner(false);
}

}
  }
  const textfieldData = {
    searchLabel: "Search Set Name",
  };
  const dropdownListData = {
    createNewSet: "Create New Set+",
    label1: "Set Name",
    label2: "Feb 02 2062 13:48",
    label3: "Set Name",
    labe14: "Aug 27 2029 19:30",
    labe15: "Set Name",
    labe16: "Jul 29 2030 09:38",
    labe17: "Set Name",
    label8: "Jul 26 2016 09:52",
    textfieldProps: textfieldData,
  };
  const handleClosed = () => {
    setEditSetAssignPopup(false);
  };

  return (
    <>
      <Dialog open={editSetAssignPopup}>
        <Styles>
          <div className="modal-header">
            <div className="edit-set">
              {showShopDrawings ? "Edit Type" : "Edit Set"}
            </div>
            <div className="buttons">
              <img className="close" src="close.svg" alt="close" onClick={handleClosed} />
            </div>
          </div>
          {selected_ids.length > 1 &&
            <div className="modal-body">
              <p className="edit-the-following-i body-regular">
                <span className="body-regular">Edit {showShopDrawings? "type" : "set"} name for selected sheets </span>
              </p>
              <div className="references">
                <div className="frame-31" onClick={() => setFileDropdown(!fileDropdown)}>
                  <div className="frame-30">
                    <img className="sheets" src="sheets.svg" alt="sheets" /> <div className="selected-sheets-4 body-small">
                      Selected Sheets ({selectedItems.length})
                    </div>
                  </div>
                  <img className="down" src="down.svg" alt="down" /> </div>
                {fileDropdown &&
                  <div className={selected_ids.length > 5 ? "photos body-small": "photos-1 body-small"}>
                    {selectedItems &&
                      selectedItems.map((item) => (
                        <div className="fa-10">
                          {item.name}
                        </div>
                      ))
                    }
                  </div>
                }
              </div>
              <div className="textfield-1">
                <div className="label-2 poppins-normal-abbey-14px"> {showShopDrawings ?  "Type Name": "Set Name"}
                </div>
                <div className="content">
                  <div className="field-and-icon" onClick={() => setShowDropdown(!showDropdown)}>
                    <div className="text-2 poppins-normal-abbey-14px"> {showShopDrawings ? typeName.name :(setName.createNew ? setName.createNew : setName.name)}
                    </div>
                    <img className="down" src="down.svg" alt="down" />
                  </div>
                </div>
                {showDropdown && (
                  <FilterDropdown
                    handleChange={handleChange}
                    setTypeName={setTypeName}
                    showShopDrawings={showShopDrawings}
                    rows={option}
                    dropdownListData={dropdownListData}
                    setName={setName}
                    setSetName={setSetName}
                    setShowDropdown={setShowDropdown}
                    setNewSet={setNewSet}
                  />
                )}
              </div>
              {newSet &&
                <>
                  <div className="textfield poppins-normal-abbey-14px">
                    <div className="label">
                      Create New Set Name
                    </div>
                    <div className="text">
                      <OutlinedInput
                        placeholder="Enter Set Name"
                        variant=""
                        value={newSetName?.Name}
                        onChange={(event) => {
                          let val = event.target.value;
                          let nset = cloneDeep(newSetName);
                          if (!nset) {
                            nset = { Name: '' };
                          }
                          nset.Name = val;
                          setNewSetName(nset);
                        }}
                      />
                    </div>
                  </div>

                  <div className="textfield-1">
                    <div className="label-2 poppins-normal-abbey-14px"> Issuance Date
                    </div>
                    <div className="text poppins-normal-abbey-14px" style={{ width: "360px" }}>

                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Stack spacing={0}>
                          <DesktopDatePicker
                            inputFormat="MM/dd/yyyy"
                            value={dateValue}
                            onChange={(newValue) => {
                              setDateValue(newValue);
                              let fff = cloneDeep(newSetName);
                              if (!fff) {
                                fff = { Issuance_Date__c: null };
                              }
                              if (newValue) {
                                fff.Issuance_Date__c = moment(newValue).format("YYYY-MM-DD");
                              } else {
                                fff.Issuance_Date__c = null;
                              }
                              setNewSetName(fff);
                            }}
                            placeholder="Select Date"
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </Stack>
                      </LocalizationProvider>

                    </div>

                  </div>
                </>
              }
            </div>
          }
          {selected_ids.length < 2 &&
            <div className="modal-body">
              <p className="edit-the-following-i body-regular">
                <span className="body-regular">Edit the following
                  information for sheet number </span>
                <br></br>
                <b> <span className="span1 poppins-bold-abbey-14px">
                  {attachRow ? attachRow.Document_Number__c : selectedItems[0].Document_Number__c}</span></b>
              </p>
              <div className="textfield poppins-normal-abbey-14px">
                <div className="label">
                  Sheet Number
                </div>
                <div className="text poppins-normal-abbey-14px">
                  <OutlinedInput
                    variant=""
                    disabled={true}
                    value={Number}
                    onChange={(event) => {
                      let val = event.target.value;
                      let nset = cloneDeep(Number);
                      if (!nset) {
                        nset = { Name: '' };
                      }
                      nset = val;
                      setNumber(nset);
                    }} />
                </div>
              </div>
              <div className="textfield poppins-normal-abbey-14px">
                <div className="label poppins-normal-abbey-14px">
                  Sheet Title
                </div>

                <div className="text">
                  <OutlinedInput
                    variant=""
                    value={Title}
                    onChange={(event) => {
                      let val = event.target.value;
                      let nset = cloneDeep(Title);
                      if (!nset) {
                        nset = { Name: '' };
                      }
                      nset = val;
                      setTitle(nset);
                    }} />
                </div>

              </div>
              <div className="textfield-1">
                <div className="label-2 poppins-normal-abbey-14px"> {showShopDrawings ?  "Type Name": "Set Name"}
                </div>
                <div className="content">
                  <div className="field-and-icon" onClick={() => setShowDropdown(!showDropdown)}>
                    <div className="text-2 poppins-normal-abbey-14px"> {showShopDrawings ? typeName.name :(setName.createNew ? setName.createNew : setName.name)}
                    </div>
                    <img className="down" src="down.svg" alt="down" />
                  </div>
                </div>
                {showDropdown && (
                  <FilterDropdown
                    handleChange={handleChange}
                    setTypeName={setTypeName}
                    showShopDrawings={showShopDrawings}
                    rows={option}
                    dropdownListData={dropdownListData}
                    setName={setName}
                    setSetName={setSetName}
                    setShowDropdown={setShowDropdown}
                    setNewSet={setNewSet}
                  />
                )}
              </div>
              {newSet &&
                <>
                  <div className="textfield poppins-normal-abbey-14px">
                    <div className="label">
                      Create New Set Name
                    </div>
                    <div className="text">
                      <OutlinedInput
                        placeholder="Enter Set Name"
                        variant=""
                        value={newSetName?.Name}
                        onChange={(event) => {
                          let val = event.target.value;
                          let nset = cloneDeep(newSetName);
                          if (!nset) {
                            nset = { Name: '' };
                          }
                          nset.Name = val;
                          setNewSetName(nset);
                        }}
                      />
                    </div>
                  </div>

                  <div className="textfield-1">
                    <div className="label-2 poppins-normal-abbey-14px"> Issuance Date
                    </div>
                    <div className="text poppins-normal-abbey-14px" style={{ width: "360px" }}>

                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Stack spacing={0}>
                          <DesktopDatePicker
                            inputFormat="MM/dd/yyyy"
                            value={dateValue}
                            onChange={(newValue) => {
                              setDateValue(newValue);
                              let fff = cloneDeep(newSetName);
                              if (!fff) {
                                fff = { Issuance_Date__c: null };
                              }
                              if (newValue) {
                                fff.Issuance_Date__c = moment(newValue).format("YYYY-MM-DD");
                              } else {
                                fff.Issuance_Date__c = null;
                              }
                              setNewSetName(fff);
                            }}
                            placeholder="Select Date"
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </Stack>
                      </LocalizationProvider>

                    </div>

                  </div>
                </>
              }
            </div>
          }
          <div className="modal-bottom">
              <Button onClick={handleClosed} className="custom_button cancel_button">
                Cancel
              </Button>
              <Button onClick={handleSaveSet} className="custom_button apply_button">
                Save Changes
              </Button>
          </div>

        </Styles>
      </Dialog>
    </>
  );
};
const FilterDropdown = ({ rows, handleChange, dropdownListData, setName, setSetName, setShowDropdown, setNewSet, showShopDrawings, setTypeName }) => {
  const [filterData, setFilterData] = useState(rows);
  const handleClose = (option) => {
    setSetName({ createNew: dropdownListData.createNewSet });
    setShowDropdown(false);
    setNewSet(true);
  }
  async function filterDataFuntion(searchTerm) {
    let orgData = cloneDeep(rows);

    let filteredData = orgData;
    if (searchTerm && searchTerm !== null && searchTerm !== "") {
      filteredData = [];
      for (let el of orgData) {
        if (showShopDrawings && el.toLowerCase().includes(searchTerm)) {
          filteredData.push(el);
        }
        if (!showShopDrawings && el.label.toLowerCase().includes(searchTerm)) {
          filteredData.push(el);
        }
      }
    }
    setFilterData(filteredData);
  }

  return (
    <div className="dropdown-list">
      <div className="dropdown-content">
        <div className="content-1">
          <TextField
            sx={{
              "& .MuiInputBase-root": {
                color: "#929292",
                width: "100%",
                height: '37px'
              },
            }}
            id="outlined-basic"
            variant="outlined"
            fullWidth
            autoComplete="off"
            className="Searchbar-field"
            placeholder={showShopDrawings ? "Search Type Name" : "Search Set Name"}
            //  onClick={handleClearAllFiltersOption}
            onChange={(e) => {
              //convert input text to lower case
              var lowerCase = e.target.value.toLowerCase();
              filterDataFuntion(lowerCase);

            }}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                // Do code here
                e.preventDefault();
                var lowerCase = e.target.value.toLowerCase();
                filterDataFuntion(lowerCase);
              }
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
          <div className="selection">
            {!showShopDrawings &&
              <div className="create-new-set body-emphasize" onClick={() => handleClose()}>
                {dropdownListData.createNewSet}
              </div>
            }
            {rows &&
              (filterData.length > 0 ? filterData : []).map((opt, index) => (
                <div className="frame" onClick={() => handleChange(showShopDrawings ? opt : opt.value)}>
                  <div className="labelBold body-regular"> {showShopDrawings ? opt : opt.label}
                  </div>
                  {!showShopDrawings &&
                    <div className="label-22 body-small"> {opt.date}
                    </div>
                  }
                </div>
              ))
            }
          </div>
        </div>
      </div>
    </div>
  );

}



export default AssignmentDialog;
