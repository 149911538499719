import Styled from "styled-components";
const FilesComponentStyle = Styled.div`
  span.MuiButtonBase-root  svg.MuiSvgIcon-root {
    color: var(--border-gray);
}
  span.MuiButtonBase-root.Mui-checked svg.MuiSvgIcon-root {
    color: var(--drblue);
}
.MuiCollapse-wrapper {
    //width: max-content;
}

.MuiTreeView-root {
    height: 75vh;
}
.MuiTreeItem-root{
    width: auto;
}
.MuiTreeItem-root::-webkit-scrollbar {
   display: none;
}
.MuiTreeItem-root .MuiTreeItem-content {
    border-radius: 0px;
}
.MuiTreeItem-root .MuiTreeItem-content.Mui-selected {
    color: #000000;
    border-radius: 0px;
    background-color: #EAEAEA;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.14px;
}
.MuiTreeItem-root .MuiTreeItem-content.Mui-selected.Mui-focused {
    color: #000000;
    border-radius: 0px;
    background-color: #EAEAEA;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.14px;
}
.collpas_expander_folders {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 113px);
    background: #EAEAEA;
    width:25px;
}
.collpas_expander_folders_image{
    max-width: 100%;
    max-height: 100%;
    /* Optional: If you want to center the image within the container without stretching it */
    object-fit: contain;
}
.Folders{
    height: calc(100vh - 113px);
    background: #F8F8F8;
    width: 18%;
}
.Folders_head {
    .img{
        cursor: pointer;
        &:hover {
            background: #F8F8F8;
          }
    }
    display: flex;
    justify-content: space-between;
    padding: 25px 25px 20px 30px;
    font-family: var(--common-font);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.14px;
    border-bottom: 1px solid #E8EFFF;
}
.Folders_body {
    padding: 0px 0px 20px 0px;
}
.MuiTreeItem-content {
height: 40px;
padding: 0px 30px;
}
.Folders_body_noFolder_head {
    padding: 25px 25px 20px 30px; 
}
.Folders_body_NoFolder_Button {
    text-transform: none;
    color: var(--neutrals-charcoal, #505050);
    font-family: var(--common-font);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border: 1px solid #D8D8D8;
    
}
.Folders_body_NoFolder {
    font-family: var(--common-font);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.14px;
    padding-left: 60px;
    padding-bottom: 10px;
}
.Folders_body_NoFolder_1 {
    color: #505050;
    text-align: center;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.12px;
    padding-bottom: 20px;
}
.Folders_body_NoFolder_2 {
    padding-left: 60px;
}
.Folders_body_NoFolder_Button-img {
    padding-right: 5px;
}
.folder_list {
    color: var(--neutrals-charcoal, #505050);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.14px;
}
.folder_list-LastSelect {
    color: var(--neutrals-black, #000);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.14px;
}
.folder_path {
    position: relative;
    color: var(--neutrals-charcoal, #505050);
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.14px;
}
.popup {
    position: absolute;
    top: 100%; /* Position the popup below the folder path */
    left: 0;
    background-color: white;
    z-index: 101; 
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
.popup ul {
    list-style: none;
    padding: 0;
}
.popup-list li {
    padding: 5px;
    padding-left: 10px;
    cursor: pointer;
    span {
        width: 180px; 
        overflow: hidden;
        text-wrap: nowrap;
        text-overflow: ellipsis;
    }
}
.popup-list li:hover {
    background-color: #f0f0f0;
}
.upload-rogress-header {
    height: 48px;
    background: #F8F8F8;
    padding: 16px;
    border-bottom: 1px solid #EAEAEA;
}
.upload-rogress-header-title {
color: #505050;
font-family: var(--common-font);
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.14px;
}
.
upload-rogress-header-actions {
    float: right;
    display: flex;
    gap: 20px;
}
.upload-rogress {
    border: none;
    box-shadow: -2px -2px 20px 10px rgba(0, 0, 0, 0.1);
}
.upload-rogress-body {
    min-height: 200px;
    max-height: 400px;
    overflow: auto;
    padding: 16px;
}
.upload-rogress-body-item {
    padding: 6px 0px;
    .file-name {
        gap: 10px;
        color: #505050;
        font-family: var(--common-font);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.14px;
        display: flex;
    }
    .processing {
        filter: opacity(0.5);
    }
    border: none;
    display: flex;
    justify-content: space-between;
}
`;
export default FilesComponentStyle;