// question response types
export const RESPONSE_TYPES = [
  {
    label: "Preconfigured",
    value: "Preconfigured",
  },
  {
    label: "Text",
    value: "Text",
  },
  {
    label: "Number",
    value: "Number",
  },
  {
    label: "Date",
    value: "Date",
  },
  {
    label: "Single Select",
    value: "Single Select",
  },
  {
    label: "Multi-Select",
    value: "Multi-Select",
  },
  {
    label: "Dropdown",
    value: "Dropdown",
  },
  {
    label: "Signature",
    value: "Signature",
  },
];
export const answersPreArr = [
  [{ label: 'Yes', value: 'Yes', key: 'Yes', },
  { label: 'No', value: 'No', key: 'No', },
  { label: 'NA', value: 'NA', key: 'NA' }],
  [{ label: 'True', value: 'True', key: 'True', },
  { label: 'False', value: 'False', key: 'False', },
  { label: 'NA', value: 'NA', key: 'NA' }],
  [{ label: 'Plus', value: 'Plus', key: 'Plus', },
  { label: 'Minus', value: 'Minus', key: 'Minus', },
  { label: 'NA', value: 'NA', key: 'NA' }],
  [{ label: 'Pass', value: 'Pass', key: 'Pass', },
  { label: 'Fail', value: 'Fail', key: 'Fail', },
  { label: 'NA', value: 'NA', key: 'NA' }]
];
export const answersPreArrOption = 
  [{ label: 'Option 1', value: 'Option 1', key: 'Option 1', },
  { label: 'Option 2', value: 'Option 2', key: 'Option 2', },
  { label: 'Option 3', value: 'Option 3', key: 'Option 3' }];
/*export const answersArr = [
  {
    label: 'Answer 1',
    value: 'Answer 1',
    key: 'Answer 1'
  },
  {
    label: 'Answer 2',
    value: 'Answer 2',
    key: 'Answer 2'
  },
  {
    label: 'Answer 3',
    value: 'Answer 3',
    key: 'Answer 3'
  },
];*/
export const answersArr = [];
