import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogTitle";
import AppRoutes from "Routing/Routes";
import CloseIcon from "assets/images/close.svg";
import {
  Typography,
} from "@mui/material";
import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { fetchUrl, webSocketListener } from "Urls";
import { makeRandomId } from "api";
import { DisciplineDialogStyles } from "components/DrawerComponent/DisciplineSettingDialog";
function App() {
  const [waitingToReconnect, setWaitingToReconnect] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const clientId = makeRandomId(15) + makeRandomId(5);
  const clientRef = useRef(null);
  const [needRelogin, openRelogin] = useState(false);
  useEffect(() => {
    if (waitingToReconnect) {
      return;
    }
    // Only set up the websocket once
    if (!clientRef.current) {
      const client = new WebSocket(webSocketListener);
      clientRef.current = client;
      window.client = client;
      client.onerror = (e) => console.error('web socket error',e);
      client.onopen = () => {
        client.send(JSON.stringify({ type: 'setClientId', clientId: clientId }));
        setIsOpen(true);
        console.log('ws opened');
        axios.get(`${fetchUrl}/register/client/${clientId}`).then((res) => {
          if (res?.status === 200) {
            console.log('register/client success');
          }
        }).catch((err) => {
          console.log('register/client',err);
        });
      };
      client.onclose = () => {
        if (clientRef.current) {
          // Connection failed
          console.log('ws closed by server');
        } else {
          // Cleanup initiated from app side, can return here, to not attempt a reconnect
          console.log('ws closed by app component unmount');
          return;
        }
        if (waitingToReconnect) {
          return;
        };
        // Parse event code and log
        setIsOpen(false);
        console.log('ws closed');
        setWaitingToReconnect(true);
        setTimeout(() => setWaitingToReconnect(false), 1000);
      };

      client.onmessage = (event) => {
        console.log("websocket call", event.data);
        if (event.data) {
          const msg = JSON.parse(event.data);
          console.log('print wss msg :: ',msg);
          if(msg?.session_fail && msg?.reloginUrl){
            openRelogin(msg?.reloginUrl);
          }
        }
      };
      return () => {
        console.log('Cleanup');
        // Dereference, so it will set up next time
        clientRef.current = null;
        client.close();
      }
    }
  }, [waitingToReconnect]);
  return (
    <>
      {/* <ToastContainer hideProgressBar={true} autoClose={2000} /> */}
      <AppRoutes />
      {needRelogin && 
    <Dialog open={true} onClose={()=>{
      openRelogin(null);
    }} maxWidth="xs">
      <DisciplineDialogStyles>
        <DialogTitle className="dialog_title">
        Session Expired
          <IconButton aria-label="close" onClick={()=>{
      openRelogin(null);
    }} sx={{ position: "absolute", right: 12, }} >
            <img src={CloseIcon} alt="icons" />
          </IconButton>
        </DialogTitle>
        <DialogContent className="dialog_content" style={{backgroundColor: 'white'}}>
          <DialogContentText style={{ padding: "16px 20px 16px 20px", background: 'white' }}>
          <Typography >
          Your session has expired, please login again and you might have to re-do some of your last changes.
            </Typography >
         
          </DialogContentText>
        </DialogContent>
        <DialogActions className="action_button">
          <Button className="custom_button apply_button" onClick={() => { 
            window.location = needRelogin;
           }}>
            Login
          </Button>
        </DialogActions>
      </DisciplineDialogStyles>
    </Dialog>
      }
    </>
  );
}

export default App;
