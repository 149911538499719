import React, { useRef, useEffect, useState } from "react";
import html2PDF from 'jspdf-html2canvas';
import axios from "axios";
import JSZip from 'jszip';
import { saveAs } from "file-saver";
import { fetchUrl } from "Urls";
import { RadioGroup, FormControlLabel, Radio, Checkbox, Card, Box, Grid } from "@mui/material";
import moment from "moment";
const ChecklistFormPreviewExport = ({ uiId,onClose, credential, formIds, processed, zipName }) => {
   const [sfObj, setSfObj] = useState();
   const [checklistFormDetails, setChecklistFormDetails] = useState([]);
   const refPdf = useRef();
   useEffect(() => {
      console.log('uiId :: ',uiId , 'formIds.length' , formIds?.length , processed);
      if (!processed && formIds) {
         const zip = new JSZip();
         getFormDetails(formIds, -1, zip);
      }
   }, []);
   async function getFormDetails(_formIds, count, zip) {
      count++;
      axios.get(`${fetchUrl}/getProjectFormDetail/${_formIds[count]}`)
      //eslint-disable-next-line no-loop-func
      .then((res) => {
         if (res.status === 200) {
            if (res.data?.formObj) {
               if (res.data.docDetail) {
                  if (res.data.formObj.Form_Template__c) {
                     setChecklistFormDetails(res.data.docDetail);
                  }
               }
               setSfObj(res.data.formObj);
               setTimeout(() => {
                  generatePDF(res.data.formObj, count, zip);
               }, 3000);
            }
         }
      })
      .catch((err) => {
         console.log(err);
      });
   }
   function truncateText(text, maxLength) {
      return text.length > maxLength ? text.slice(0, maxLength) + '...' : text;
   }
   const generateHeaderFooter = (doc, totalPageNumber, recordData) => {
      doc.setTextColor(80, 80, 80);
      for (let i = 1; i <= totalPageNumber; i++) {
         doc.setPage(i);
         if (i != 1) {
            // Left Flex Container (Project Name and Form Name)
            doc.setFontSize(12);
            const projectName = truncateText(credential?.projectName, 10);
            doc.text(`${projectName}`, 13, 10, { align: "left" });
            doc.text(`| ${recordData?.Name}`, 40, 10, { align: "left" });

            // Right Flex Container (Checklist: and Closed)
            doc.setFontSize(12);
            doc.text(`${recordData?.Form_Template__r?.Form_Type__c}:`, doc.internal.pageSize.getWidth() - 28, 10, { align: "right" });
            doc.text(`${recordData?.Status__c}`, doc.internal.pageSize.getWidth() - 13, 10, { align: "right" });

            // Add a top border below the headers
            doc.setLineWidth(0.5);
            doc.line(10, 13, doc.internal.pageSize.getWidth() - 10, 13);
         }
         // Left Text in Footer
         doc.setFontSize(10);
         doc.text( `Form Detailed Report`, 10,  doc.internal.pageSize.getHeight() - 10,  { align: "left" } );
         // Right Text in Footer
         doc.setFontSize(10);
         doc.text(`Page ${i} of ${totalPageNumber}`,doc.internal.pageSize.getWidth() - 10,doc.internal.pageSize.getHeight() - 10,{ align: "right" });
         doc.setLineWidth(0.5);
         doc.line(10, doc.internal.pageSize.getHeight() - 15, doc.internal.pageSize.getWidth() - 10, doc.internal.pageSize.getHeight() - 15);
      }
   };
   const generatePDF = async (recordData, count, zip) => {
      const pdfElement = refPdf.current;
      if (pdfElement) {
         await html2PDF(pdfElement, {
            watermark({ pdf, pageNumber, totalPageNumber }) {
               generateHeaderFooter(pdf, totalPageNumber, recordData);
            },
            html2canvas: {
               scale: 8,
               imageTimeout: 15000,
               logging: true,
               useCORS: true
            },
            margin: {
               right: 12.7,
               top: 15.7,
               bottom: 19.9,
               left: 12.7,
            },
            jsPDF: {
               unit: 'mm',
               format: 'a4',
               orientation: 'portrait',
            },
            success: function (pdf) {
               if (formIds.length == 1) {
                  pdf.save(`${recordData.Name}`);
                  onClose(uiId , true );
               } else {
                  if (zip) {
                     let blobData = pdf.output("blob")
                     zip.file(`${recordData.Name}.pdf`, blobData);
                     if (count + 1 == formIds.length) {
                        zip.generateAsync({ type: 'blob' }).then((content) => {
                           const zipName_ = zipName ? zipName : `${credential?.projectName} Forms.zip`;
                           saveAs(content, zipName_);
                           onClose(uiId , true);
                        });
                     } else {
                        getFormDetails(formIds, count, zip);
                     }
                  }
               }

            }
         });
      }
   };
   return (
      <div style={{ position: "absolute", top: "-9999px" }}>
         {sfObj && sfObj.Id &&
            <div ref={refPdf} id="test_html_data" onClick={() => generatePDF()} style={{ width: "612px" }}>
               <>
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                     <div>
                        <div style={{ color: "#505050", fontSize: "12px", fontWeight: 400 }}>
                           {credential?.projectName}
                        </div>
                        <div style={{ color: "#00000", fontSize: "20px", fontWeight: 600 }}>
                           {sfObj?.Name}
                        </div>
                        <div style={{ background: "#346EF5", width: "58px", height: "4px", marginTop: "24px" }}></div>
                     </div>
                     <div style={{ border: "1px solid #EAEAEA", color: "#505050", background: "#F8F8F8", width: "130px", height: "40px", padding: "13px" }}>
                        Company Logo
                     </div>
                  </div>
                  <div className="" style={{ marginTop: "24px", marginBottom: "291px" }}>
                     <div style={{ color: "#00000", fontSize: "12px", fontWeight: 600, paddingBottom: "8px", borderBottom: "1px solid #BABABA", marginBottom: "8px" }}>
                        <div>Form Details</div>
                     </div>
                     <div className="">
                        <div className="formDetail_export">
                           <div className="formDetail_heading">Id</div>
                           <div style={{ color: "#000000" }}>{sfObj?.Numbering_Sequence__c}</div>
                        </div>
                        <div className="formDetail_export">
                           <div className="formDetail_heading">Form Type</div>
                           <div style={{ color: "#000000" }}>{sfObj?.Form_Template__r?.Form_Type__c}</div>
                        </div>
                        <div className="formDetail_export">
                           <div className="formDetail_heading">Category</div>
                           <div style={{ color: "#000000" }}>{sfObj?.Form_Template__r?.Template_Category__c}</div>
                        </div>
                        <div className="formDetail_export">
                           <div className="formDetail_heading">Description</div>
                           <div style={{ color: "#000000" }}>{sfObj?.Description__c}</div>
                        </div>
                        <div className="formDetail_export">
                           <div className="formDetail_heading">Status</div>
                           <div style={{ color: "#000000" }}>{sfObj?.Status__c}</div>
                        </div>
                        <div className="formDetail_export">
                           <div className="formDetail_heading">Location</div>
                           <div style={{ color: "#000000" }}>-</div>
                        </div>
                        <div className="formDetail_export">
                           <div className="formDetail_heading">Ball in court</div>
                           <div style={{ color: "#000000" }}>{sfObj?.Ball_in_Court__r?.Name}</div>
                        </div>
                        <div className="formDetail_export">
                           <div className="formDetail_heading">Due date</div>
                           <div style={{ color: "#000000" }}>{sfObj?.Date__c ? moment(sfObj?.Date__c).format('MM/DD/YYYY') : ''}</div>
                        </div>
                        <div className="formDetail_export">
                           <div className="formDetail_heading">Last Updated By</div>
                           <div style={{ color: "#000000" }}>{sfObj?.LastModifiedBy?.Name}</div>
                        </div>
                        <div className="formDetail_export">
                           <div className="formDetail_heading">Last Updated</div>
                           <div style={{ color: "#000000" }}>{moment(sfObj?.LastModifiedDate).format('MM/DD/YYYY')}</div>
                        </div>
                        <div className="formDetail_export">
                           <div className="formDetail_heading">Created By</div>
                           <div style={{ color: "#000000" }}>{sfObj?.CreatedBy?.Name}</div>
                        </div>
                     </div>
                  </div>
               </>
               <div style={{ marginTop: "0px" }}>
                  <div>
                     {checklistFormDetails?.length > 0 && checklistFormDetails.map(section => (
                        section.Name == "Signature" ? (
                           <div>
                              <div style={{ borderBottom: "1px solid #BABABA", display: "flex", justifyContent: "space-between" }}>
                                 <div>
                                    Signature
                                 </div>
                                 <div>
                                    {section.items.length} Signees
                                 </div>
                              </div>
                              {section.items.map(Question => (
                                 <div style={{ marginTop: "8px", marginBottom: "8px" }}>
                                    <div style={{ display: "flex" }}>
                                       <div style={{ color: "#000000", fontSize: "12px", fontWeight: 600 }}>
                                          {Question.Question__c}
                                       </div>
                                       <div style={{ color: Question.Optional__c ? "#D8D8D8" : "red" }}>
                                          {Question.Optional__c ? "(optional)" : "*"}
                                       </div>
                                    </div>
                                    <div style={{ color: "#505050" }}>
                                       {Question.Description__c}
                                    </div>
                                    <div style={{ marginTop: "8px" }}>
                                       <img src={Question.ans.Signature__c} alt="" style={{ width: "255px", height: "101px", border: "1px solid #D8D8D8" }} />
                                    </div>
                                    <div style={{ marginTop: "8px" }}>
                                       <div style={{ display: "flex", color: "505050", fontSize: "12px", fontWeight: 400 }}>
                                          <div>Signature By:</div>
                                          <div>{Question.ans.Print_Name__c}</div>
                                       </div>
                                       <div style={{ display: "flex", color: "505050", fontSize: "12px", fontWeight: 400 }}>
                                          <div>Company</div>
                                          <div>{Question.ans.Company_Name__c}</div>
                                       </div>
                                       <div style={{ display: "flex", color: "505050", fontSize: "12px", fontWeight: 400 }}>
                                          <div>Signed on:</div>
                                          <div>May 20 2054 21:59</div>
                                       </div>
                                    </div>
                                 </div>
                              ))}
                           </div>
                        ) : (
                           <div>
                              <div style={{ borderBottom: "1px solid #BABABA", display: "flex", justifyContent: "space-between" }}>
                                 <div style={{ color: "#000000", fontSize: "12px", fontWeight: 600 }}>
                                    Section {section?.Numbering_Sequence__c} of {checklistFormDetails.length}
                                 </div>
                                 <div>
                                    {section.items.length} questions
                                 </div>
                              </div>
                              <div style={{ marginTop: "8px", marginBottom: "8px" }}>
                                 <div style={{ color: "#000000", fontSize: "12px", fontWeight: 600 }}>
                                    {section?.Name}
                                 </div>
                                 <div style={{ color: "#505050" }}>
                                    {section?.Description__c}
                                 </div>
                              </div>
                              {section.items.map(Question => (
                                 <div style={{ border: "1px solid #D8D8D8", padding: "10px", borderRadius: "4px", marginBottom: "8px" }}>
                                    <div style={{ color: "#929292", marginBottom: "8px" }}>
                                       Question {Question.Numbering_Sequence__c} of {section.items.length}
                                    </div>
                                    <div style={{ display: "flex" }}>
                                       <div style={{ color: "#000000", fontSize: "12px", fontWeight: 600 }}>
                                          {Question.Question__c}
                                       </div>
                                       <div style={{ color: Question.Optional__c ? "#D8D8D8" : "red" }}>
                                          {Question.Optional__c ? "(optional)" : "*"}
                                       </div>
                                    </div>
                                    <div style={{ color: "#505050" }}>
                                       {Question.Description__c}
                                    </div>
                                    {Question.Response_Type__c == "Preconfigured" &&
                                       <>
                                          <div style={{ color: "#000000", fontSize: "12px", fontWeight: 600, marginTop: "8px" }}>
                                             Response:
                                          </div>
                                          <div style={{ color: "#505050" }}>
                                             {Question.ans.Response__c}
                                          </div>
                                       </>
                                    }
                                    {(Question.Response_Type__c == "Text" || Question.Response_Type__c == "Number" || Question.Response_Type__c == "Date" || Question.Response_Type__c == "Dropdown") &&
                                       <div style={{ color: "#505050", border: "1px solid #D8D8D8", height: "37px", padding: "8px", marginTop: "8px" }}>
                                          {Question.ans.Response__c}
                                       </div>
                                    }
                                    {Question.Response_Type__c == "Checkbox" &&
                                       <div>
                                          {Question.responseOptions.map((r) => (
                                             <div style={{ display: "flex", color: "black", gap: "10px", padding: "2px 0px" }} className="list_option">
                                                <div>
                                                   <Checkbox className="checkbox-style" style={{ padding: "0px" }} checked={Question.ans.Response__c?.includes(r.value)} value={r.value} onChange={(e) => {

                                                   }} name={r.value} />
                                                </div>
                                                <div style={{ paddingTop: "2px" }}>
                                                   {r.label}
                                                </div>
                                             </div>
                                          ))}
                                       </div>
                                    }
                                    {(Question.Response_Type__c === 'Multiple Choice') &&
                                       <div>
                                          <RadioGroup
                                             aria-labelledby="preconfigured-answer-responses"
                                             name="Response__c"
                                             value={Question.ans.Response__c}
                                          >
                                             {Question.responseOptions.map((r) => (
                                                <FormControlLabel
                                                   key={r.key}
                                                   value={r.value}
                                                   control={<Radio />}
                                                   label={r.label}
                                                />
                                             ))}
                                          </RadioGroup>
                                       </div>
                                    }
                                    {Question.ans && Question.ans?.attachments && Question.ans?.attachments?.length > 0 &&
                                       <Box className="main_gridview" sx={{ flexGrow: 1 }} style={{ marginTop: "20px", border: "1px solid #EAEAEA", backgroundColor: "#F8F8F8", padding: "12px", width: "100%" }}>
                                          <div style={{ display: "flex", justifyContent: "space-between", color: "#505050" }}>
                                             <div>Images ({Question.ans?.attachments.length})</div>
                                          </div>
                                          <Grid container spacing={1} sx={{ marginTop: "10px", marginLeft: "0px" }}>
                                             {Question.ans?.attachments?.map((val, index) => {
                                                return (
                                                   <Card className="form_image" style={{ width: "120px", height: "120px", marginRight: "8px" }}>
                                                      <Box className="on_hover_form_image">
                                                      </Box>
                                                      <div >
                                                         <img id="demo" style={{ margin: "8px", width: "104px", height: "81px" }} src={val.fileurl} crossOrigin='anonymous' alt="thumbnail loading....." />
                                                      </div>
                                                      <div style={{ margin: "0px 8px 8px 8px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", color: "#505050" }}>
                                                         {val.File_Name__c}
                                                      </div>
                                                   </Card>
                                                )
                                             })}
                                          </Grid>
                                       </Box>
                                    }
                                    {Question.ans && Question.ans?.issues && Question.ans?.issues?.length > 0 &&
                                       <Box className="main_gridview" sx={{ flexGrow: 1 }} style={{ marginTop: "20px", border: "1px solid #EAEAEA", backgroundColor: "#F8F8F8", padding: "12px" }}>
                                          <div style={{ display: "flex", justifyContent: "space-between", color: "#505050" }}>
                                             <div>Issues ({Question.ans?.issues.length})</div>
                                          </div>

                                          <Grid container spacing={1} sx={{ marginTop: "10px", marginLeft: "0px" }}>
                                             {Question.ans?.issues?.map((val, index) => {
                                                return (
                                                   <div className="form_image" style={{ width: "100%", height: "auto", padding: "8px", marginBottom: "12px" }}>
                                                      <div style={{ colo: "#505050" }}>
                                                         {val?.Numbering_Sequence__c}
                                                      </div>
                                                      <div style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", color: "#00000", fontWeight: 400 }}>
                                                         {val?.Name}
                                                      </div>
                                                   </div>
                                                )
                                             })}
                                          </Grid>
                                       </Box>
                                    }
                                 </div>
                              ))}
                           </div>)

                     ))}
                  </div>
               </div>
            </div>
         }
      </div>
   );
};
export default ChecklistFormPreviewExport;