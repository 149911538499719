import React, { useEffect, useState, useRef } from "react";
import pspdfkit_Imp from 'pspdfkit';

import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import { Box } from "@mui/system";
import Spinner from "components/spinner";
import ViewPdfDialogstyles from "./pdfViewStyles";
import {issueStatusToColorMap , rfiStageToColorMap} from "components/SetsView/ShowPdfDialog";
// import CompareFiles from "../FolderTable/CompareFiles";

export default function IssuesPdfViewDialog({
  credential,
  pdfopenfiledata,
  annoEleInstantJson,
  pinType,
  handleDonePinDrop,
  handleCloseFile,
  open
}) {
  //let PSPDFKit = useRef(null);
  let instance = useRef(null);
  const [instanceObject, setInstanceObject] = useState(null);
  const [pspdfkitIns, setPspdfkitValue] = useState(null);
  const [showSpinner, setSpinner] = React.useState(false);
  const [currentLoadedFile, setCurrentLoadedFile] = useState(null);
  const containerRef1 = React.useRef(null);
  const [pdfLoaded, setPdfLoaded] = React.useState(false);
  const [currentCreatedAnnoId, setCurrentCreatedAnnoId] = React.useState(null);
  var currentPageIndex = 0 ;
  React.useEffect(() => {
    let fileUrl = pdfopenfiledata.url ;
    if(!fileUrl){
      fileUrl = pdfopenfiledata.fileurl ;
    }
    if(fileUrl){
      setTimeout(() => {
        loadpdf(fileUrl);
      }, 200);
    }
    //console.log('pdfopenfiledata :: '+JSON.stringify(pdfopenfiledata));
  }, [currentLoadedFile]);

  let pdfMarkup;
  if (annoEleInstantJson) {
    //console.log("pdfMarkup >>>>>>>>>>>>", pdfopenfiledata.markupJSON);
    pdfMarkup = {
      format: "https://pspdfkit.com/instant-json/v1",
      annotations: [JSON.parse(annoEleInstantJson)],
    };
  }

  React.useEffect(() => {
    if(pdfLoaded){
      
    }
  }, [pdfLoaded]);


  async function loadpdf(url) {
    //console.log("*** loading ", url);
    try{
      if(pspdfkit_Imp){
        await pspdfkit_Imp.unload('#pdfcontainer');
      }
    }catch(e){
      console.error(e);
    }
    instance = await pspdfkit_Imp.load({
      container: containerRef1.current,
      document: url,
      printMode: pspdfkit_Imp.PrintMode.DOM,
      baseUrl: `${window.location.protocol}//${window.location.host}/${process.env.PUBLIC_URL}`,
      initialViewState: new pspdfkit_Imp.ViewState({ sidebarMode: pspdfkit_Imp.SidebarMode.TEXT }),
      instantJSON: pdfMarkup
    }).catch((error) => {
      console.log("error >>>>>>>>>>>>", JSON.stringify(error));
      console.error(error);
    });
    setPspdfkitValue(pspdfkit_Imp);
    if(instance){
      setInstanceObject(instance);
      const items = instance?.toolbarItems;
      let includedButton = [ "pan", "zoom-in", "zoom-out"];
      instance?.setToolbarItems( [...items].filter( (itm) => includedButton.includes(itm.type) ) );
      instance.addEventListener("viewState.currentPageIndex.change", (pageIndex) => {
        currentPageIndex = pageIndex;
      });
      instance.addEventListener("annotations.delete", async (annotation) => {
        setCurrentCreatedAnnoId(null);
      });
      instance.addEventListener("annotationSelection.change", async (annotation) => {
        if (annotation) {
          let annObj = annotation.toJS();
          if(annObj.pageIndex != null){
            //console.log('selected Annotation :: '+JSON.stringify(annObj));
            let customData = annObj.customData ;
            if(!customData){ // will run for new annotations only
              annObj.customData = getCustomData();
              await instance.update(annotation.set("customData", annObj.customData));
            }
          }
        }
      });
      instance.addEventListener("annotations.blur", async (event) => {
        //console.log(event.annotation, event.nativeEvent.type);
        let annotation = event.annotation ;
        let annObj = annotation.toJS();
        if(annObj.pageIndex != null){
          //console.log('blur Annotation :: '+JSON.stringify(annObj));
          let customData = annObj.customData ;
          if(!customData){ // will run for new annotations only
            annObj.customData = getCustomData();
            //console.log('in update :: ');
            await instance.update(annotation.set("customData", annObj.customData));
          }
        }
      });
      instance.setOnAnnotationResizeStart( (event) => {
        return false;
      });
      if(annoEleInstantJson){
        setCurrentCreatedAnnoId(JSON.parse(annoEleInstantJson)?.id);
      }
      setInstanceObject(instance);
    }else{
      setInstanceObject(null);
    }
    setPdfLoaded(true);
  }
  function getCustomData() {
    return {'userId' : credential?.userId , 'userFullName' : credential?.userFullName , source : 'save_annotation' , access : 'public'}; // keeping it public for issue and rfi
  }
  async function insertIssueAnnotationStamp( inObj = instanceObject){
    const annotation = getIssueAnnotationConstantStamp(inObj);
    const [createdAnnotation] = await inObj.create(annotation); 
    setCurrentCreatedAnnoId(createdAnnotation.id);
    setSelectedMarkupFromIns(createdAnnotation.id ,inObj);
  }
  async function insertRFIAnnotationStamp( inObj = instanceObject){
    const annotation = getRFIAnnotationConstantStamp(inObj);
    const [createdAnnotation] = await inObj.create(annotation); 
    setCurrentCreatedAnnoId(createdAnnotation.id);
    setSelectedMarkupFromIns(createdAnnotation.id ,inObj);
  }
  async function removeSelectedAnnoFromINS ( insObj = instanceObject){
    setSelectedMarkupFromIns(null , insObj);
  }
  async function setSelectedMarkupFromIns ( annId , insObj = instanceObject){
    if(insObj){
      await insObj.setSelectedAnnotation(annId);
    }
  }
  function getIssueAnnotationConstantStamp( inObj = instanceObject){
    return new pspdfkit_Imp.Annotations.StampAnnotation({ pageIndex : currentPageIndex , stampType: "Custom", title: "I", subtitle: "Issue", color: new pspdfkit_Imp.Color(issueStatusToColorMap['Draft'].colorBox),
      boundingBox: new pspdfkit_Imp.Geometry.Rect({ left: Math.round(inObj.pageInfoForIndex(currentPageIndex).width/2), top: Math.round(inObj.pageInfoForIndex(currentPageIndex).height/2), width: 50, height: 50, })
    });
  }
  function getRFIAnnotationConstantStamp( inObj = instanceObject){
    return new pspdfkit_Imp.Annotations.StampAnnotation({ pageIndex : currentPageIndex , stampType: "Custom", title: "R", subtitle: "RFI", color: new pspdfkit_Imp.Color(rfiStageToColorMap['Draft'].colorBox),
      boundingBox: new pspdfkit_Imp.Geometry.Rect({ left: Math.round(inObj.pageInfoForIndex(currentPageIndex).width/2), top: Math.round(inObj.pageInfoForIndex(currentPageIndex).height/2), width: 50, height: 50,})
    });
  }
  async function saveAnnotations(_instance = instanceObject , pspdfins = pspdfkitIns) {
    setSpinner(true);
    // updating annotations with data
    for(let p =0 ; p < _instance.totalPageCount ; p++) {
      const annotations = await _instance.getAnnotations(p);
      for(let e =0 ; e < annotations.size ; e++) {
        let annObj = annotations.get(e).toJS();
        let updateCustomData = false ;

        let customData = annObj.customData ;
        if(!customData){ // will run for new annotations only
          customData = getCustomData();
          annObj.customData = customData;
          updateCustomData = true;
        }
        if(updateCustomData){
          await _instance.update(annotations.get(e).set("customData", annObj.customData));
        }
      }
      //console.log('actual :: '+annotations.size);
    }
    // fetching instant json
    let annotationJsonI = null;
    let instance_ = _instance;
    await instance_.exportInstantJSON().then(function (instantJSON) {
      if (instantJSON.annotations) {
        for(let ann of instantJSON.annotations){
          ann.noPrint = false;
          ann.noView = false;
          annotationJsonI = ann;
          break;
        }
      }
      //console.log("instantJSON "+ JSON.stringify(instantJSON));
    }).catch((error) => {
      console.log("error >>>>>>>>>>>>", JSON.stringify(error));
      console.error(error);
    });
    //console.log("instantJSON sendData"+ JSON.stringify(annotationJsonI));
    if(true){
      if(annotationJsonI != null){
        annotationJsonI = JSON.stringify(annotationJsonI);
      }
      handleDonePinDrop(annotationJsonI);
    }
    setSpinner(false);
  }

  return (
    <div>
      <Dialog
        open={open}
        fullScreen
        onClose={handleCloseFile}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <ViewPdfDialogstyles>
          <DialogTitle id="alert-dialog-title">
            <Box sx={{ display: "flex", justifyContent: "space-around" }}>
              <div className="main-wrap_buttons">
                <Button disabled={!currentCreatedAnnoId}  component="span" onClick={() => { saveAnnotations( instanceObject ,  pspdfkitIns); }} >
                  Done
                </Button>
                <Button component="span" onClick={() => {
                    try{
                      if(pspdfkitIns && instanceObject){
                        pspdfkitIns.unload(instanceObject);
                      }
                    }catch(e){
                      console.error(e);
                    }
                    handleCloseFile();
                  }} >
                  Close
                </Button>
              </div>
            </Box>
            {!currentCreatedAnnoId && pdfLoaded && (
              <div className="pspdf-floating-container">
                <div className="pspdf-floating-button-container">
                  <div className="pspdf-floating-button-container-item">
                    <Button variant="contained" className="pspdf-floating-button-container-item"
                      onClick={() => { 
                        if(pinType == 'issue'){
                          insertIssueAnnotationStamp();
                        }else if(pinType == 'rfi'){
                          insertRFIAnnotationStamp();
                        }
                      }} >
                      <span style={{textTransform : 'capitalize'}}>{'Add '+pinType}</span>
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </DialogTitle>

          <DialogContent style={{ position: "relative" }}>
            {showSpinner && <Spinner />}
            <Grid container>
              <Grid item style={{ flexGrow: 1 }}>
                <div id="pdfcontainer" ref={containerRef1} style={{ width: "100%", height: "calc(100vh - 80px)", marginLeft: "10px" }}></div>
              </Grid>
            </Grid>
          </DialogContent>
        </ViewPdfDialogstyles>
      </Dialog>
    </div>
  );
}
