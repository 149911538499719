import styled from "styled-components";

const ReviewStyle = styled.div`
  .MuiDialogContent-dividers {
    padding: 0;
  }
  .review_main {
    padding: 30px 50px;
  }
  .MuiDialogTitle-root button.MuiButtonBase-root {
    position: absolute;
    right: 10px;
    top: 15px;
  }
  .label_field > label {
    display: block;
  }
  .css-viou3o-MuiAutocomplete-root {
    width: 100%;
  }
  .MuiFormControl-root.MuiTextField-root.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
    width: 100%;
  }

  input#country-select-demo {
    font-size: 14px;
    padding: 0;
  }
  .review_main > div > label {
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    display: block;
    margin-bottom: 15px;
  }
  .MuiInputLabel-formControl[data-shrink="false"] {
    transform: translate(14px, 9px) scale(1);
  }

  .dropdown {
    background: #f1fdff;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 8px;
    position: relative;
    margin-top: 15px;
    > svg {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .drop_down {
    border-bottom: 1px solid #c4c4c4;
    padding-bottom: 10px;
    .Heading_de {
      font-weight: 600;
    }
    & > .drop_icons {
      margin: 20px auto;
      max-width: max-content;
      position: relative;
      svg {
        fill: #ccc;
      }
    }
    span.arrow_right {
      width: 250px;
      height: 2px;
      background: #ccc;
      display: inline-block;
      margin-left: 5px;
      margin-right: 5px;
      position: relative;
      &:after {
        content: "";
        border-top: 7px solid transparent;
        border-bottom: 7px solid transparent;
        border-left: 20px solid #ccc;
        position: absolute;
        right: 0;
        top: -6px;
      }
    }
  }
`;

export default ReviewStyle;
