import React, { useState } from "react";
import moment from 'moment';
import { Button, Checkbox, Box, TextField, Divider, InputLabel, TextareaAutosize, DialogContent,Dialog ,DialogTitle, Stack, IconButton } from "@mui/material";
import { Info , Close}  from "@mui/icons-material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider, DesktopDatePicker } from "@mui/x-date-pickers";
import { TYPES_FILTER_OPTIONS, STATUS_OPTIONS } from "../../IssuesTabs/IssueRelativeComponent/IssuesConstants";
import DialogComponent from "components/Shared/DialogComponent";
import { AutoCompleteGroupCheckbox, AutoCompleteSelect } from "components/Shared/AutoCompleteComponent";
import SelectADocumentPopUp from "./SelectADocumentpopUp";
import CreateIssuePopupstyle from "./CreateIssuePopupstyle";
import { findIndex} from 'lodash';
import axios from "axios";
import { fetchUrl } from "Urls";
//import {getMarkupBreakDownFromInstantJSON} from "components/FolderTable";
import {IssueDetail} from "components/SetsView/ShowPdfDialog";
import DialogContentText from '@mui/material/DialogContentText';
import Spinner from "components/spinner";
import styled from "styled-components";

function IssueCreated(props) {
  const [value, setValue] = useState(null);
  const [enableDocumentSelect, setDocumentSelect] = useState(false);

  const { open, closeAndRefresh, typeFilter, _onAddDocClick, credential,updateStateTableDataWithRow, ...others } = props?.issueProps;
  const [pdfopenfiledata, setFileData] = useState();
  const [linkedPDFIssueInstantJson, setCurrentPDFIssueInstantJSON] = useState(null);  
  const [sfIssueObj, setSfIssueObj] = React.useState(null);
  const [showSpinner, setSpinner] = React.useState(false);
  const [docDetail, setDocDetail] = React.useState(null);
  React.useEffect(() => {
    if(credential){
      let dsfIssue = getDefaultSFIssueObj();
      setVariables(dsfIssue);
    }
    //console.log('useEffect IssueDialog :: '+JSON.stringify(sfIssueObj));
  }, [credential]);

  function setVariables(_sfObj){
    setSfIssueObj(_sfObj);
  }
  function handleFileChange(selectedFile){
    let olFile = pdfopenfiledata ;
    let newlinkedPDFIssueInstantJson = linkedPDFIssueInstantJson;
    let annoation_id = JSON.parse(newlinkedPDFIssueInstantJson)?.id;
    if(olFile?.id != selectedFile?.id){
      setCurrentPDFIssueInstantJSON(null);
      annoation_id = null;
    }
    changeDocDetailOnIssueDetailPop(selectedFile?.id,annoation_id,selectedFile?.name);
    setFileData(selectedFile);
  }
  function getDefaultSFIssueObj(){
    //console.log('useEffect credential :: '+JSON.stringify(credential));
    return {Id : null ,
            Status__c : credential?.issueObjOptions?.Status__c?.default , Type__c : credential?.issueObjOptions?.Type__c?.default ,
            Root_Cause__c : credential?.issueObjOptions?.Root_Cause__c?.default,
            OwnerId : credential?.userId ,Owner : {Id :  credential?.userId , Name : credential?.userFullName } , Due_Date__c : moment(new Date()).format("YYYY-MM-DD"),
          };
  }
  function changeDocDetailOnIssueDetailPop(attachId , annoId , attachLabel){
    setDocDetail({attachId : attachId , annoId : annoId , attachLabel : attachLabel });
  }
  const handleIssuePinDropSelctionDone = (detail) => {
    let anno = JSON.parse(detail);
    setCurrentPDFIssueInstantJSON(detail);
    let annoation_id ;
    if(anno?.id != null){
      annoation_id = anno.id
    }else{
      annoation_id = null;
      setCurrentPDFIssueInstantJSON(null);
    }
    changeDocDetailOnIssueDetailPop(pdfopenfiledata?.id,annoation_id,pdfopenfiledata?.name);
    //console.log('anno :: '+JSON.stringify(anno));
    setDocumentSelect(false);
  };
  const saveMakrupToLinkedDocIssue = async function (){
    setSpinner(true);
    var reTurnPdfOpenFileData = await saveMakrupToLinkedDocument(pdfopenfiledata ,linkedPDFIssueInstantJson , credential).catch((error) => {
      setSpinner(false);
      console.log("error >>>>>>>>>>>>", JSON.stringify(error));
      console.error(error);
    });
    if(reTurnPdfOpenFileData){
      updateStateTableDataWithRow(reTurnPdfOpenFileData);
      closeAndRefresh(true);
    }
    setSpinner(false);
  }
  const onAddDocClick= async function () {
    setDocumentSelect(true);
    _onAddDocClick();
  }
  async function onClickSave(issueObj){
    setSpinner(true);
    issueObj["Project__c"] = credential?.projectId
    const formData = new FormData();
    //let issueObj = sfIssueObj;
    formData.append("issueObj", JSON.stringify(issueObj));
    await axios.post(`${fetchUrl}/saveIssue/${credential?.projectId}?token=${credential?.token}&instanceUrl=${credential?.instanceUrl}`,formData)
    .then(async (res) => {
      if(res.status === 200){
        issueObj.Id = res.data.id;
        setVariables(issueObj);
        await saveMakrupToLinkedDocIssue();
      }
    }).catch((error) => {
      setSpinner(false);
      console.log("error >>>>>>>>>>>>", JSON.stringify(error));
      console.error(error);
    });
    setSpinner(false);
  }
 
  return (
    <>
      <Dialog open={true} onClose={closeAndRefresh} fullWidth maxWidth="md" aria-labelledby="Create New Issue" aria-describedby="Create New Issue"
        scroll="paper" >
          <DialogTitle id="Create New Issue">
            Create New Issue
              <IconButton
                aria-label="close"
                onClick={closeAndRefresh}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
              <Close />
            </IconButton>
          </DialogTitle>
          <CreateIssuePopupstyle>
            <DialogContent className="createIssuePopup_class" style={{ position: "relative" , padding : '0px' ,paddingLeft : '0.5rem'}}>
              {showSpinner && <Spinner />}
              <DialogContentText>
                <Box sx={{ display: "block" }}>
                  {sfIssueObj && 
                    <IssueDetail sfObj={sfIssueObj} credential={credential}  saveCallback={onClickSave} closeCallBack={closeAndRefresh}
                    customStyle={{ paddingBottom : '0.5rem'  , height : '80vh' , overflow: 'auto' }} 
                    enableLinkDocSelection={true} docDetail={docDetail} openDocSelect={onAddDocClick}/>
                  }
                </Box>
              </DialogContentText>
            </DialogContent>
          </CreateIssuePopupstyle>
        {/*</DialogComponent>*/}
      </Dialog>
      { enableDocumentSelect && <SelectADocumentPopUp
          pinType="issue"
          annoEleInstantJson={linkedPDFIssueInstantJson}
          handlePinDropSelctionDone={handleIssuePinDropSelctionDone}
          treeData={{ ...others }}
          credential={credential}
          enableDocumentSelect={enableDocumentSelect}
          setDocumentSelect={setDocumentSelect}
          setFileData={handleFileChange}
          pdfopenfiledata={pdfopenfiledata}
        />
      }
    </>
  );
}
export const saveMakrupToLinkedDocument = async function (pdfopenfiledata , toLinkMarkupInstantJson , cred){
  let returnRow;
  let toLinkMarkupInstantJson_ = toLinkMarkupInstantJson;
  //console.log("toLinkMarkupInstantJson_ >>>>>>>>>>>>", toLinkMarkupInstantJson_);
  let annoation_id = JSON.parse(toLinkMarkupInstantJson_)?.id;
  //console.log("annoation_id >>>>>>>>>>>>", annoation_id);
  let preRow = JSON.parse(JSON.stringify(pdfopenfiledata));
  let annotationJsonI = {
                          format: "https://pspdfkit.com/instant-json/v1",
                          annotations: [JSON.parse(toLinkMarkupInstantJson_)]
                        } ;
  if (preRow.markupJSON) {
    annotationJsonI = JSON.parse(preRow.markupJSON);
    //console.log("pdfMarkup >>>>>>>>>>>>", pdfopenfiledata.markupJSON);
    let annotationList = annotationJsonI?.annotations;
    let foundInd ;
    if(annotationList){
      foundInd = findIndex(annotationList, { 'id' : annoation_id });
    }
    if(foundInd > -1){
      annotationJsonI.annotations[foundInd] = JSON.parse(toLinkMarkupInstantJson_);
    }else{
      if(!annotationJsonI.annotations){
        annotationJsonI.annotations = [];
      }
      annotationJsonI.annotations.push(JSON.parse(toLinkMarkupInstantJson_));
    }
  }
  //console.log("instantJSON sendData"+ JSON.stringify(annotationJsonI));

  if(annoation_id != null){
    await axios.put(`${fetchUrl}/markup/${pdfopenfiledata.id}`, annotationJsonI)
    .then((res) => {
      //console.log("resPOst", JSON.stringify(res));
      let _row = JSON.parse(JSON.stringify(pdfopenfiledata));
      if(annotationJsonI){
        _row.markupJSON = JSON.stringify(annotationJsonI) ;
      }else{
        _row.markupJSON = null ;
      }
      /*let markupBreakDown = getMarkupBreakDownFromInstantJSON(_row.markupJSON , cred) ;
      let annoList = markupBreakDown.annoList;
      let issueList = markupBreakDown.issueList;
      let rfiList = markupBreakDown.rfiList;
      //console.log("issueList", JSON.stringify(issueList));
      _row.Markup_Json__c = _row.markupJSON ;
      _row.attObj.Markup_Json__c = _row.markupJSON ;

      _row.markupCount = markupBreakDown.cUserAnnCount;
      _row.markupInstantList = annoList;
      _row.issueInstantList = issueList;
      _row.rfiInstantList = rfiList;*/
      returnRow = _row;
    });
  }
  return returnRow;
}

export default IssueCreated;
