import React, { useState,useEffect } from "react";
import {
  Grid,
  Checkbox,
  TextField,
  List,ListItem,ListItemIcon,IconButton
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { cloneDeep } from "lodash";
import { answersArr } from 'components/FormTemplateTabComponent/ChecklistFormTemplate/constants';

const MultiSelect = ({handleChange ,responseOptions}) => {
  const [answers, setAnswers] = useState(cloneDeep(answersArr));
  const [newOption, setNewOption] = useState('');
  const [isError, checkError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  useEffect(() => {
    if(responseOptions && responseOptions.length > 0){
      setAnswers(responseOptions);
    }
  },[]);
  const handleAddOption = () => {
    const tmpArr = cloneDeep(answers);
    let isError = false ;
    let eMess ;
    if(tmpArr && tmpArr.length > 0 && newOption){
      let f_index = tmpArr?.findIndex(
        (data) => data.value == newOption
      );
      if(f_index > -1){
        isError = true;
        eMess='Option already exits.';
      }
    }
    if(!newOption || newOption.trim() == ''){
      isError = true;
      eMess='Cannot add empty option.';
    }
    checkError(isError);
    setErrorMessage(eMess);
    if(!isError){
      if(!tmpArr){
        tmpArr = [];
      }
      tmpArr.push({ label: newOption, value: newOption, key: newOption });
      setAnswers(tmpArr);
      setNewOption('');
      handlePush(tmpArr);
    }
  };
  const handleRemoveOption = (index) => {
    const tmpArr = cloneDeep(answers);
    if(tmpArr && tmpArr.length > 0 ){
      tmpArr.splice(index , 1);
      setAnswers(tmpArr);
      handlePush(tmpArr);
    }
  };
  const handlePush = (_answer = answers) => {
    const tmpArr = cloneDeep(_answer);
    let jsonTosend ;
    if(tmpArr && tmpArr.length > 0 ){
      jsonTosend = tmpArr ;
    }
    handleChange({target : {name : 'responseOptions' , value : jsonTosend } });
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <List className="dropdown_response_answers" sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
          {answers.map((a, i) => (
            <>
              <ListItem key={a.value}
                secondaryAction={
                  <IconButton edge="end" aria-label="comments">
                    <DeleteIcon fontSize="small" onClick={e => (handleRemoveOption(i))} />
                  </IconButton>
                } disablePadding >
                <ListItemIcon> <Checkbox value={a.value} name="checkbox-buttons" disabled /> </ListItemIcon>
                {a.value}
              </ListItem>
            </>
          ))}
        </List>
        {/*<FormGroup>
          {answers.map((a) => (
            <FormControlLabel
              key={a.value}
              value={a.value}
              control={<Checkbox />}
              label={a.label}
            />
          ))}
        </FormGroup>*/}
      </Grid>
      <Grid item xs={12}>
        <TextField label="New Option" variant="outlined" size="small"
          inputProps={{ className: "desc_input" }} className="question_desc" fullWidth
          value={newOption} onChange={(e) => { setNewOption(e?.target?.value); }} 
          helperText={isError ? errorMessage :"Press Enter Add Value."} error={isError}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              // Do code here
              e.preventDefault();
              console.log('newOption :: '+newOption);
              handleAddOption(newOption);
            }
          }} />
          {/*<Button
            variant="text"
            startIcon={<AddIcon />}
            className="add_more_option_btn"
            onClick={handleAddOption}
          >
            Add another option
          </Button>*/}
      </Grid>
    </Grid>
  );
};

export default MultiSelect;
