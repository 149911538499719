import {
  Router,
  Routes,
  Route,
  Outlet,
  BrowserRouter,
} from "react-router-dom";
import Home from "Pages/Home";
import CanvasHome from "Pages/CanvasHome";
import Share from "Pages/Share";
import Login from "Pages/Login";
import ShowFile from "Pages/ShowFile";
import FieldTicketSignContainer from "components/FieldTicketSignContainer";
import FormRecordView from "components/ProjectFormTabComponent/FormRecordView";
import ReviewRecordView from "components/ReviewTab/ReviewRecordView";
import IssueRecordView from "components/IssuesTabs/IssueRecordView";
import Demo from "Pages/Demo";
import { routes } from "Routing/routes/index";

const AppRoutes = () => {
  //console.log('App routes :: '+window?.location?.href );
  //console.log('App routes pathname:: '+window?.location?.pathname );
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />}>
          {routes}
        </Route>
        <Route path="/canvas/callback/:orgUserkey" element={<CanvasHome />} />
        <Route path="/login" element={<Login />} />
        <Route path="/share/:shareId" element={<Share />} />
        <Route path="/preview" element={<ShowFile />} />
        <Route path="/sign" element={<FieldTicketSignContainer />} />
        <Route path="/form/:formId" element={<FormRecordView />} />
        <Route path="/issueRoute/:issueId" element={<IssueRecordView />} />
        <Route path="/reviewView/:reviewId" element={<ReviewRecordView />} />
        <Route path="/fieldticket/sign/:fieldticketId" element={<FieldTicketSignContainer />} />
        <Route path="/demo" element={<Demo />} />
      </Routes>
    </BrowserRouter>
  );
};
export default AppRoutes;
