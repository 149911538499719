import React, {useRef, useState, useEffect } from "react";
import { useNavigate,useParams } from 'react-router-dom';
import axios from "axios";
import { fetchUrl ,inAppLoginUrl } from "Urls";
import { GetToken } from "api";
import IssueDetailPopup from "./IssuesPopups/issueDetailPopup";
import IssueTab from "components/IssuesTabs";
import Redirect from "Pages/Redirect";
import {fetchFilesApi , fetchFolderApi , prepareSobjectToIssueTableRowElement} from "hooks/IssueTab.hook";
import {
  Dialog,
  DialogContent,
} from "@mui/material";
const IssueRecordView = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [issueId , setIssueId] = useState(params?.issueId);
  const [credential, setCredentials] = useState(null);
  const [issueDetails, setIssueDetails] = useState(null);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(true);

  const [filesData, setFilesData] = useState();
  const [treeFolderStructure, setTreeFolderStructure] = useState([]);
  const [loadingProgress, setLodingProgress] = useState(false);
  useEffect(() => {
    getCreditials();
  },[]);
  async function getCreditials() {
    const data = await GetToken();
    let cred = data?.data ;
    setCredentials(cred);
    const token = cred?.token ;
    const instanceUrl = cred?.instanceUrl ;
    if(token && instanceUrl){
      await axios.get( `${fetchUrl}/issueViewRecord/${issueId}?token=${token}&instanceUrl=${instanceUrl}`).then(async (res) => {
        if (res?.data?.status === 200) {
          const reData = res.data;
          setIssueDetails(reData);
          cred.projectId = reData.projectId ;
          setCredentials(cred);
          let rowData = prepareSobjectToIssueTableRowElement(reData.issueRec);
          setSelectedRowData(rowData);
          await _getFolderApi(cred);
          await getFilesApi(cred);
          return;
        }else{
          setIssueDetails({msg : 'Issue record not found'});
        }
      }).catch((err) => {
        setIssueDetails({msg : 'Issue record not found'});
        console.error(err);
      });
    }else{
      // login into salesforce
      window.location = `${inAppLoginUrl}&issueId=${issueId}&viewIssue=yes`;
    }
  }
  const updateFolderFilesTableStateDataWithRow = async (rowEle) => {
    if(rowEle.id){
      let _currentFolderFiles = JSON.parse(JSON.stringify(filesData)) ;
      if( _currentFolderFiles?.length > 0 ){
        const fInd = _currentFolderFiles?.findIndex((data) => ( data.id == rowEle.id ));
        if (fInd > -1) {
          _currentFolderFiles[fInd] = rowEle ;
          setFilesData(_currentFolderFiles); // init folder files
        }
      }
    }
  };
  async function getFilesApi(_credential = credential) {
    let additionalQuery = `Folder__r.Folder_Type__c='Plan'` ; 
    let withfile = await fetchFilesApi(_credential , additionalQuery).catch((err) => {
      console.log("err", err);
    });
    if(withfile?.length > 0 ){
      setFilesData(withfile);
    }else{
      setFilesData([]);
    }
  }
  async function _getFolderApi(_credential = credential) {
    setLodingProgress(true);
    let additionalQuery = `Folder_Type__c='Plan'` ; 
    let reData = await fetchFolderApi(_credential , additionalQuery).catch((err) => {
      console.log(err);
      console.error(err);
      setLodingProgress(false);
    });
    if(reData?.length > 0){
      setTreeFolderStructure(reData);
    }else{
      setTreeFolderStructure([]);
    }
    setLodingProgress(false);
  }
  return (
    <div>
      {issueDetails?.projectId ?
        <>
<Redirect 
 projectId={issueDetails?.projectId}
 tabNumber={1}
></Redirect>

<Dialog fullWidth open={dialogOpen} scroll="paper">
          
          <DialogContent>
          <IssueDetailPopup
            credential={credential}
            issueDetailProps={{
              openDetail :true,
              handleClose: () => {
                console.log("hello closing");
                setDialogOpen(false);
              },
              selectedRowData,
              updateIssueTableStateDataWithRow : (rowData) => {
                console.log('updateIssueTableStateDataWithRow Called');
                setSelectedRowData(rowData);
              },              
              updateStateTableDataWithRow:updateFolderFilesTableStateDataWithRow,
            }}
            linkDocumentProps={{
              treeFolderStructure,
              _onAddDocClick: ()=>{console.log('add doc called')},
              getFilesApi,
              loadingProgress,
              filesData,
              updateStateTableDataWithRow:updateFolderFilesTableStateDataWithRow
            }}
          />
          </DialogContent>
         
        </Dialog>

         
        </>
        :
        <>
          {issueDetails?.msg ?<>{issueDetails?.msg}</> : 
            <>
              Fetching Issue Data
            </>
          }
        </>
      }
    </div>
  );
};

export default IssueRecordView;
