import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import DataTable from "components/FolderTable";
import { useSelector } from "react-redux";

function DrawerComponent(props) {
  const { userData = {} } = useSelector((state) => state.userData);
  const [_projectId, setProjectId] = useState(userData?.projectId);
  const [_tokenInfo, setTokenInfo] = useState(userData);
  //console.log("DrawerComponent currentFolderName", currentFolderName);
  useEffect(() => {
    setProjectId(userData?.projectId);
    setTokenInfo(userData);
  }, [userData?.projectId]);
  useEffect(() => {
    setTokenInfo(userData);
  }, [userData?.userList]);
  return (
    <Box sx={{ display: "flex" }}>
      <Box component="main" sx={{ flexGrow: 1, width: { sm: "100%" }, height: `calc(100vh - 113px)`, overflow: "hidden", }} >
        {_projectId && (
          <DataTable projectId={_projectId} tokenInfo={_tokenInfo} isPlan={props?.isPlan} id={"DataTable"} />
        )}
      </Box>
    </Box>
  );
}
DrawerComponent.propTypes = {
  window: PropTypes.func,
};

export default DrawerComponent;
