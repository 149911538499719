import React, { useState, useEffect, useRef } from "react";
import _, { find, get, cloneDeep } from "lodash";
import axios from "axios";
import { fetchUrl } from "Urls";
import styled from 'styled-components';
import {DefineSheetNumberDialog} from "./DisciplineSettingDialog";
import Draggable from "react-draggable";
import ZoomButtons from "components/ZoomButtons";
import {Dialog, Tooltip} from "@mui/material";
import WebViewer from '@pdftron/webviewer';
import { APRYSE_L_KEY } from "api";
import SearchIcon from "../../assets/images/search.svg";
const WithoutTemplateSection = styled.div`
background-color: #F1F3F5;
    .Popup.DocumentCropPopup{
      display : none !important;
    }
  .new_taplate_text_one {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin: 10px;

    label{
      font-weight: 600;
    }
    input {
      width: 100%;
      max-width: 190px;
      padding: 10px;
      border: 2px solid #0498dc;
    }
  }

  .slds-card__footer{
    position: sticky;
    bottom: 0;
    padding: 15px;
    background: #ededed;
    text-align: center;
    display: flex;
    justify-content: center;
    gap: 20px;

    button {
      padding: 10px 15px;
      min-width: 160px;
      font-size: 16px;
      border-radius: 10px;
      border: 1px solid #0498dc;
      color: #fff;
      background: #0498dc;
      cursor: pointer;
    }
  }
  .sheet-view-navigation {
    align-items: center;
    background-color: #FFFFFF;
    border-bottom-style: solid;
    border-bottom-width:
    1px;
    border-color: #EAEAEA;
    display: flex;
    justify-content: space-between;
    padding: 8.5px 20px;
    position: relative;
    width: 100%;
  }
  .sheets-selected-and-info {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 12px;
    position: relative;
  }
  .sheets-selected-and-info-item {
    padding-right: 10px;
    border-right: 1px solid #EAEAEA;
    color: #000000;
    font-weight: 400; 
    line-height: normal; 
    position: relative; 
    width: fit-content;
  }
  .sheets-selected-and-info-item-img {
    padding-right: 10px;
    color: #000000;
    font-weight: 400; 
    line-height: normal; 
    position: relative; 
    width: fit-content;
  }
  .line-24 {
    height: 24px; object-fit: cover; position: relative;
    width: 1px;
  }
  .current-sheet-number {
    color: #929292; 
    font-weight: 400; 
    line-height: normal;
    position: relative;
    width: fit-content;
    text-transform: capitalize;
  }
  .frame-52 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 16px;
    justify-content: flex-end;
    position: relative;
  }
  .frame-44 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 32px;
    position: relative;
  }
  .define-sheet-number-area {
    color: #505050;
    font-weight: 400;
    line-height: normal;
    position: relative;
    width: fit-content;
    text-transform: capitalize;
  }
  .tools {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 16px;
    justify-content: center;
    position: relative;
  }
  .frame-42 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 12px;
    justify-content: flex-end;
    position: relative;
  }
  .frame-43 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto; position: relative;
  }
  .buttons {
    align-items: center;
    background-color: #FFFFFF;
    border: 1px solid;
    border-color: #D8D8D8;
    border-radius: 2px;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 8px;
    height: 37px;
    justify-content: center;
    overflow: hidden;
    position: relative;
  }
  .navigate {
    height: 16px;
    position: relative;
    width: 16px;
  }
  .buttons-1 {
    align-items: center; border-radius: 2px; display: flex;
    height: 24px;
    justify-content: center;
    overflow: hidden;
    position: relative;
    width: 24px;
  }
  .pan {
    &:hover {
      background: #F8F8F8;
    }
    height: 16px;
    position: relative;
    width: 16px;
  }
  .extract-toolSelect {
    &:hover {
      background: #F8F8F8;
    }
    flex-wrap: wrap; 
    height: 24px; 
    width: 24px;
    display: flex; 
    justify-content: center; 
    align-content: center;
    background-color: #E8EFFF;
  }
.extract-tool {
    &:hover {
      background: #F8F8F8;
    }
    flex-wrap: wrap; 
    height: 24px; 
    width: 24px;
    display: flex; 
    justify-content: center; 
    align-content: center;
    background-color: white;
  }
  .buttons-1.buttons-2 {
    background-color: #346EF5;
  }
  .buttons-3 {
    &:hover {
      background: #F8F8F8;
    }
    align-items: center;
    background-color: #FFFFFF; 
    border: 1px solid;
    border-color: #505050; 
    border-radius: 2px;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 8px;
    justify-content: center;
    overflow: hidden;
    padding: 9px 16px;
    position: relative;
  }
  .label {
    letter-spacing: 0; line-height: normal;
    margin-top: -1px;
    position: relative;
    width: fit-content;
  }
  .buttons-4 {
    color: white;
    align-items: center;
    background-color: #346EF5;
    border-radius: 2px;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 10px;
    justify-content: center;
    overflow: hidden;
    padding: 9px 16px;
    position: relative;
    width: 80px;
    height: 39px;
  }
  .label-1 {
    color: white;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px; position: relative;
    width: fit-content;
  }
`;
const selectedPageIndexs_ = [0,1];
const furl = `https://horsepower-dev.s3.amazonaws.com//a0DDb00000H9hzmMAB/a0DDb00000H9hzrMAB/5%20Pages%20document%20Sep%2019%20%2C2023.pdf?AWSAccessKeyId=AKIA6FO5IYHIJDDKUAVC&Expires=1695715323&Signature=u98Vbrnf9%2FfdR%2Fxu8CST9TmBxcQ%3D` ;
const s3_url = 'https://horsepower-dev.s3.amazonaws.com//a0DDb00000H9hzmMAB/a0DDb00000H9hzrMAB/5%20Pages%20document%20Sep%2019%20%2C2023.pdf'
function ExtractNumber({ ...props }) {
  const cancelTokens = useRef([]);
  const {  attachId ,section, selectedPageIndexs ,allRowCount ,fileUrl, s3Url, credential, cropLabel, setCropLabel, onExtractSave,onStartBackgroundExtracting,defineSheetNumberDialog, setDefineSheetNumberDialog } = props;
  const [numberLoading, setNumberSelecting] = useState(false);
  const [titleLoading, setTitleSelecting] = useState('');
  const [title, setTitle] = useState('');
  const [extractedText, setExtractedText] = useState();
  const [cropArea, setCropArea] = useState(null);
  const [cropRect, setCropRect] = useState(null);
  const [rectOrigin, setRectOrigin] = useState(null);
  const [instance, setInstance] = useState(null);
  const [pageSize, setPageSize] = useState();
  const [pageIndex, setPageIndex] = useState(null);
  const [pageIndexToValue, setPageToValue] = useState();
  const [hideViewer, setHideViewer] = useState(true);
  const [showZoomActions, setShowZoomAction] = useState(false);
  const [currentZoomLevel, setCurrentZoomLevel] = useState(1);
  const [showNumber, setShowNumber] = useState(false);
  const [panMode, setPanMode] = useState(false);
  const [cropMode, setCropMode] = useState(false);
  const [countPage, setCountPage] = useState();
  const [searchInPdf, setSearchInPdf] = useState(false);
  const viewer = useRef();
  const [extractForAll, setExctractForAll] = useState(false);
  let panColorUse = false;
  let cropColorUse = false;
  useEffect(() => {
    let temp = selectedPageIndexs;
    if(temp?.length > 0 ){
      _setPageIndex(temp[0]);
      if(temp?.length == allRowCount){
        setExctractForAll(true);
      }else{
        setExctractForAll(false);
      }
    }
  }, [selectedPageIndexs]);
  useEffect(() => {
    let valu;
    if(pageIndexToValue != null && pageIndex != null){
      let key = `${pageIndex}`
      if(pageIndexToValue[key]){
        valu = {value : pageIndexToValue[key].value , error : pageIndexToValue[key].error , old_value : pageIndexToValue[key].old_value };
     
      }
    }
    setExtractedText(valu);
  }, [pageIndexToValue , pageIndex]);
  useEffect(() => {
    if(pageIndex != null){
      setHideViewer(false);
      const inter = setTimeout(async () => {
        loadDocument(fileUrl);
      }, 1000);
    }
  }, [pageIndex]);
  function _setPageIndex (_ind){
    setHideViewer(true);
    setPageIndex(Number(_ind));
  }
  function navigatePage (dir){
    let cPage = Number(pageIndex);
    let selectedPageIndexs_list = selectedPageIndexs;// selectedPageIndexs;
    if(selectedPageIndexs_list?.length > 1 && selectedPageIndexs_list.indexOf(cPage) > -1){
      let ind = selectedPageIndexs_list?.indexOf(cPage);
      if(dir == 'next'){
        if((ind+1) == selectedPageIndexs_list.length){
          ind = 0;
        }else{
          ind++;
        }
      }else{
        if(ind == 0){
          ind = selectedPageIndexs_list.length - 1;
        }else{
          ind--;
        }
      }
      _setPageIndex(selectedPageIndexs_list[ind]);
    }
  }
  const loadDocument = async (fileUrl) => {
    //console.log('viewer.curre nt :: ',viewer.current);
    if (viewer.current) {
      setShowZoomAction(false);
      let toKeepPage = 1;
      if (pageIndex != null && pageIndex != undefined && pageIndex > -1) {
        //console.log('pageIndex :: ', pageIndex);
        toKeepPage = Number(Number(pageIndex) + 1);
      }
      const disElement = ['toolbarGroup-View',
                          'toolsHeader',
                          'toolbarGroup-Annotate',
                          'toolbarGroup-Shapes',
                          'toolbarGroup-Insert',
                          'toolbarGroup-Measure',
                          'toolbarGroup-Edit',
                          'toolbarGroup-FillAndSign',
                          'toolbarGroup-Forms',
                          'toolbarGroup-Redact',
                          "searchPanelResizeBar",
                        ];
      await WebViewer({ fullAPI: true, path: '/webviewer/public',
        initialDoc: fileUrl, 
        disabledElements: disElement,
        licenseKey: APRYSE_L_KEY  /* sign up to get a free trial key at https://dev.apryse.com,*/
      }, viewer.current).then(async (ins) => {
        ins.UI.disableElements(disElement);
        const iframeWindow = ins.UI.iframeWindow;
        const header = iframeWindow.document.querySelector('[data-element="header"]');
        header.style.display = 'none';
        //console.log("ins", ins);
        setInstance(ins);
        const { documentViewer,Tools } = ins.Core;
        const scrollView = documentViewer.getScrollViewElement();
        const simulateControlScroll = (event) => {
          if (documentViewer.getToolMode().name === "Pan" || documentViewer.getToolMode().name === "CropPage") {
            scrollView.style.overflow = 'hidden';
            if(!event.ctrlKey){
              // Create a new wheel event with the Control key pressed
              const newEvent = new WheelEvent('wheel', {
                deltaX: event.deltaX,
                deltaY: event.deltaY,
                deltaZ: event.deltaZ,
                deltaMode: event.deltaMode,
                clientX: event.clientX,
                clientY: event.clientY,
                screenX: event.screenX,
                screenY: event.screenY,
                pageX: event.pageX,
                pageY: event.pageY,
                ctrlKey: true,  // Simulate Control key pressed
                shiftKey: event.shiftKey,
                altKey: event.altKey,
                metaKey: event.metaKey,
                bubbles: event.bubbles,
                cancelable: event.cancelable,
                composed: event.composed
              });
          
              scrollView.dispatchEvent(newEvent);
            }
          }else {
            scrollView.style.overflow = 'auto';
          }
        };
        // Use capturing phase to ensure we catch the event early
        scrollView.addEventListener('wheel', simulateControlScroll, { capture: true, passive: false });
        const tool_selet = documentViewer.getTool(Tools.ToolNames.TEXT_SELECT);
        const tool_Pan = documentViewer.getTool(Tools.ToolNames.PAN);
        const tool_Crop = documentViewer.getTool(Tools.ToolNames.CROP);
        tool_Crop.setCropMode(Tools.CropCreateTool.CropModes.SINGLE_PAGE);
        const cusCropB = document.getElementById('custom-crop-button');
        if(cusCropB){
          cusCropB.addEventListener('click', () => {
            const pretool = documentViewer.getToolMode();
            if(cropColorUse === false){
              cropColorUse = true;
              setCropMode(true); 
              setPanMode(false);
              panColorUse = false;
              documentViewer.setToolMode(tool_Crop);
              //hideCropPopup();
              overRideCropActionButtons();
            }else{
              cropColorUse = false;
              setCropMode(false);
              panColorUse = false;
              setPanMode(false);
              documentViewer.setToolMode(tool_selet);
            }
          });
        }
        const cusPanB = document.getElementById('custom-pan-button');
        if(cusPanB){
          cusPanB.addEventListener('click', () => {
            if ( panColorUse === true ) {
              cropColorUse = false;
              setPanMode(false);
              panColorUse = false ;
              setCropMode(false);
              documentViewer.setToolMode(tool_selet);
            } else {
              panColorUse = true;
              cropColorUse = false ;
              setPanMode(true);
              setCropMode(false);
              documentViewer.setToolMode(tool_Pan);
            }
          });
        }
        documentViewer.addEventListener("zoomUpdated", (zoom) => {
           setCurrentZoomLevel(zoom);
        });
        documentViewer.addEventListener("documentLoaded", async () => {
          setCurrentZoomLevel(documentViewer.getZoomLevel());
          setShowZoomAction(true);
          const doc = documentViewer.getDocument();
          const firstPage = doc.getPageInfo(toKeepPage);
          //console.log('Width:', firstPage);
          setPageSize(firstPage)
          const pageCount = doc.getPageCount();
          setCountPage(pageCount);
          let toRemovPage = [];
          for (let p = 1; p <= pageCount; p++) {
            if (p != toKeepPage) {
              toRemovPage.push(p);
            }
          }          
          const pageRott = documentViewer.getPageRotations();
          console.log('pageRott ',pageRott);  
          await doc.removePages(toRemovPage);
          //console.log("pageCount ::", pageCount);
          const zOutEle = document.getElementById('zoom-out-button');
          if(zOutEle){
            if(!zOutEle.dataset?.clickBound || zOutEle.dataset.clickBound != 'yes'){
              zOutEle.dataset.clickBound = 'yes';
              zOutEle.addEventListener('click', () => {
                let preLevel = documentViewer.getZoomLevel();
                if(preLevel > 0.1){
                  documentViewer.zoomTo(documentViewer.getZoomLevel() - 0.25);
                }else{
                  documentViewer.zoomTo(0.1);
                }
              });
            }
          }
          const zInEle = document.getElementById('zoom-in-button');
          if(zInEle){
            if(!zInEle.dataset?.clickBound || zInEle.dataset.clickBound != 'yes'){
              zInEle.dataset.clickBound = 'yes';
              zInEle.addEventListener('click', () => {
                documentViewer.zoomTo(documentViewer.getZoomLevel() + 0.25);
              });
            }
          }
          if(cusPanB){
            cusPanB.click();
          }
        });
        function hideCropPopup(){
          const aplyButton = iframeWindow.document.querySelector('.DocumentCropPopupContainer');
          if (aplyButton) {
            aplyButton.style.display = 'none';
          }
        }
        function overRideCropActionButtons(){
          const aplyButton = iframeWindow.document.querySelector('[data-element="cropApplyButton"]');
          if (aplyButton) {
            //console.log('for crop event connecting',aplyButton.dataset?.clickBound);
            if(!aplyButton.dataset?.clickBound || aplyButton.dataset.clickBound != 'yes'){
              aplyButton.classList.add('buttons-4');
              aplyButton.style.cursor = 'pointer';
              aplyButton.dataset.clickBound = 'yes';
              aplyButton.addEventListener('click', (e) => {
                console.log('in click apply');
                e.preventDefault();
                e.stopPropagation();
                clickApplyCrop();
                return;
              });
              //console.log('crop event bounded');
            }
          }
          const cancleButton = iframeWindow.document.querySelector('[data-element="cropCancelButton"]');
          if (cancleButton) {
            //console.log('for cancel event connecting',cancleButton.dataset?.clickBound);
            if(!cancleButton.dataset?.clickBound || cancleButton.dataset.clickBound != 'yes'){
              cancleButton.dataset.clickBound = 'yes';
              cancleButton.addEventListener('click', (e) => {
                //console.log('in click');
                e.preventDefault();
                e.stopPropagation();
                clickRemoveCrop();
                documentViewer.setToolMode(tool_selet);
                return;
              });
              //console.log('crop cancel event bounded');
            }
          }
          const container = iframeWindow.document.querySelector('.DocumentCropPopupContainer');
          if(container){
            const section1 = container.querySelector('.document-crop-section');
            if (section1) {
              section1.style.display = 'none';
            }
            const section2 = container.querySelector('.crop-active');
            if (section2) {
              section2.style.display = 'none';
            }
          }
        }
      })
    }
  };
  function clickApplyCrop() {
    let reBut = document.getElementById('applyAreaButton');
    if(reBut){
      reBut.click();
    }
  }
  function handleSearchInPdf(ann, type) {
    let pdfSearchButton;
    pdfSearchButton = instance.UI.iframeWindow.document.querySelector('[data-element="searchButton"]');
    pdfSearchButton.click();
    setSearchInPdf(!searchInPdf);

  }
  function onApplyCrop() {
    const annotation = instance.Core.annotationManager.getAnnotationsList().find(annotation => annotation.ToolName === instance.Core.Tools.ToolNames.CROP);
    if (annotation) {
      let cropRect = annotation.getRect();
      const doc = instance.Core.documentViewer.getDocument();
      const originCoordinates = doc.getPDFCoordinates(1, 0, 0);
      if(originCoordinates.x !== 0){
        setRectOrigin(originCoordinates);
      }
  

      const pagNum = annotation.getPageNumber();
      if (_.isEmpty(cropRect)) {
        setCropRect(null);
        setCropArea(null);
        alert("Please crop and select" + section);
      } else {
        if (section === "title") {
          setTitleSelecting(true);
        } else {
          setNumberSelecting(true);
        }
        console.log("pagNum", pagNum , "pageSize selection", JSON.stringify(pageSize));
        console.log("cropBox selection", JSON.stringify(cropRect));
        var cropArea = { left: cropRect.x1, top: cropRect.y1, height: cropRect.y2, width:  cropRect.x2 };
        setCropLabel({ ...cropLabel, [section + 'CropBox']: cropArea });
        //console.log("selectedPageIndexs ::", selectedPageIndexs);
        setCropRect(cropRect);
        setCropArea(cropArea);
        extractText(cropArea, cropRect ,section,);
      }
    }
  }
  function clickRemoveCrop() {
    setCropMode(false);
    let reBut = document.getElementById('removeAreaButton');
    if(reBut){
      reBut.click();
    }
  }
  function removeCropSelection() {
    setCropMode(false);
    //if(instance){
      const annotation = instance.Core.annotationManager.getAnnotationsList().find(annotation => annotation.ToolName === instance.Core.Tools.ToolNames.CROP);
      if (annotation) {
        instance.Core.annotationManager.deleteAnnotation(annotation);
      }
    //}
  }
  /*async function extractAllText(cropArea , cropRect , section) {
    //console.log("s3Url ::", s3Url);
    var  reqBody = { fileUrl: s3Url, cropArea: cropArea, pageIndex: pageIndex };
    setShowNumber(false);
    //const reqBody = { fileUrl: s3_url, cropArea: cropArea, pageIndex: pageIndex };
    console.log(" send body :: " + JSON.stringify(reqBody));
    await axios.post(`${fetchUrl}/extractAllFileText?token=${credential?.token}&instanceUrl=${credential?.instanceUrl}`, reqBody).then((res) => {
      let extractText = '';
      let process = true;
      try {        
        console.log("responseFromLambda2 ::", res?.data?.responseFromLambda2);
        console.log("Payload ::", res?.data?.responseFromLambda2?.Payload);
        let payableDataString = res?.data?.responseFromLambda2?.Payload;
        const jsondata1 = JSON.parse(payableDataString);
        const jsondata2 = JSON.parse(jsondata1.body);
        extractText = jsondata2?.split[pageIndex]?.title;
        if (extractText) {
          extractText = extractText.trim();
        }
        if(!extractText || extractText == ''){
          process = false;
        }
        setPageToValue((preVal)=>{
          let tempObj = cloneDeep(preVal);
          if(!tempObj){
            tempObj = {};
          }
          let p = extractForAll ? 0 : pageIndex;
          let loopLimit = extractForAll ? (countPage - 1) : pageIndex ;
          console.log('extractForAll :: ',extractForAll,'loopStart :: ',p,'loopLimit :: ',loopLimit);
          for ( p = p; p <= loopLimit; p++) {
            process = true;
            if(!tempObj[`${p}`]){
              tempObj[`${p}`] = {}
            }
            let eText = jsondata2?.split[p].title;
            if (eText) {
              eText = eText.trim();
            }
            if(!eText || eText == ''){
              process = false;
            }
            tempObj[`${p}`].old_value = tempObj[`${p}`].value;
            tempObj[`${p}`].value = eText;
            tempObj[`${p}`].cropArea = cropArea;
            tempObj[`${p}`].cropRect = cropRect;
            tempObj[`${p}`].error = !process; 
          }
          return tempObj;
        });
        setShowNumber(true);
      } catch (e) {
        console.log('extractText err:: ', e);
        process = false;
      }
      console.log(process, 'extractText :: ' + extractText);
    })
  }*/
  const abortProcesses = () => {
    if (extractForAll) {
      if (cancelTokens.current?.length > 0) {
        cancelTokens.current.forEach((cncl) => {
          cncl();
        });
      }
      cancelTokens.current = [];
    }
  };
  async function extractText(cropArea , cropRect , section) {
    //console.log("s3Url ::", s3Url);
    setShowNumber(false);
    if(cropRect.x2 - cropRect.x1 < cropRect.y2 - cropRect.y1){
    //   console.log("cropBox cropArea", JSON.stringify(cropArea));
    //   var tempCroprect = JSON.parse(JSON.stringify(cropRect));
    //   tempCroprect.x1 = cropRect.y1 ;
    //   tempCroprect.x2 = cropRect.y2 ;
    //   tempCroprect.y1 = pageSize.height - cropRect.x2 ;
    //   tempCroprect.y2 = pageSize.height - cropRect.x1 ;
       cropRect.altered = true;
    //   console.log("cropBox selection", JSON.stringify(tempCroprect));
    //   cropArea = { left: tempCroprect.x1, top: tempCroprect.y1, height: tempCroprect.y2 - tempCroprect.y1, width: tempCroprect.x2 - tempCroprect.x1 };
    //    // for patrick 3 page binder //
    //  // cropArea = { left: pageSize.width-cropRect.y2, top: cropRect.x1 , height: (cropRect.x2-cropRect.x1), width: cropRect.y2 };
    // // cropArea = { left: 3456-400, top: 2485 , height: 50, width: 300 };
    //   setCropArea(cropArea);
     // setCropRect(cropRect);
    }
    var  reqBody = { fileUrl: s3Url, cropArea: cropArea, pageIndex: pageIndex,
                    pushForDatabase : true,
                    projectId : credential?.projectId,
                    binderId : attachId,
                    cropFor: section
                  };
    //const reqBody = { fileUrl: s3_url, cropArea: cropArea, pageIndex: pageIndex };
    console.log(" send body :: " + JSON.stringify(reqBody));
    abortProcesses();
    let startIndex = selectedPageIndexs.indexOf(pageIndex) ;
    for(let ind = startIndex ; ind < selectedPageIndexs.length ; ind++){
      const pInd = selectedPageIndexs[ind];
      reqBody.pageIndex =  Number(pInd);
      const { token, cancel } = axios.CancelToken.source();
      cancelTokens.current.push(cancel);
      axios.post(`${fetchUrl}/extractFileText`,reqBody,{cancelToken:token})
      .then((res) => {
        let extractText = '';
        //console.log("responseFromLambda2 ::", res?.data?.responseFromLambda2);
        //console.log("Payload ::", res?.data?.responseFromLambda2?.Payload);
        let payableDataString = res?.data?.responseFromLambda2?.Payload;
        let process = true;
        try {      
          const jsondata1 = JSON.parse(payableDataString);
          const jsondata2 = JSON.parse(jsondata1.body);  
          extractText = jsondata2?.split[0].title;
          if (extractText) {
            extractText = extractText.trim();
            if(section == 'number'){
              extractText = extractText.replace(/\s/g, '');
              }
          }
          if(!extractText || extractText == ''){
            process = false;
          }
        } catch (e) {
          console.log('extractText err:: ', e);
          process = false;
        }
        setPageToValue((preVal)=>{
          let tempObj = cloneDeep(preVal);
          if(!tempObj){
            tempObj = {};
          }
          if(!tempObj[`${pInd}`]){
            tempObj[`${pInd}`] = {}
          }
          tempObj[`${pInd}`].old_value = tempObj[`${pInd}`].value;
          tempObj[`${pInd}`].value = extractText;
          tempObj[`${pInd}`].cropArea = cropArea;
          tempObj[`${pInd}`].cropRect = cropRect;
          tempObj[`${pInd}`].error = !process;
          return tempObj;
        });
        setShowNumber(true);
        //console.log('pInd :: ',pInd,'process :: ',process, 'extractText :: ' + extractText);
      }).catch(error => {
        console.log('extractFileText error:: ',error);
        if (axios.isCancel(error)) {
          // Handle the cancellation
          console.log('Request canceled by user');
        }
      });
    }
  }
  async function handleChaneZoomValue(value, _instance = instance) {
    _instance.Core.documentViewer.zoomTo(value / 100);
  }
  return (
    <Dialog open={props.extractNumber} fullScreen>
      <WithoutTemplateSection className="crop_pdf_container" style={{backgrondColor: "#EAEAEA"}}>
        <div className="sheet-view-navigation">
          {instance && <div id='removeAreaButton' onClick={removeCropSelection} style={{display:'none'}}></div>}
          {instance && <div id='applyAreaButton' onClick={onApplyCrop} style={{display:'none'}}></div>}
          <div className="sheets-selected-and-info body-regular">
            <div className="frame-43">
              <article className="buttons" onClick={()=>{navigatePage('previous')}}>
                <img className="navigate" src="/navigate previouspdf.svg"
                  alt="navigate previous" />
              </article>
              <article className="buttons"  onClick={()=>{navigatePage('next')}}>
                <img className="navigate" src="/navigate nextpdf.svg"
                  alt="navigate next" />
              </article>
            </div>
            <p className="sheets-selected-and-info-item">
              {pageIndex+1} of {`${selectedPageIndexs.length}`} selected sheets
            </p>
            <div className="current-sheet-number" >
              Current Sheet {section}:
            </div>
            {extractedText && showNumber && 
            <>
              {extractedText.old_value &&
                <div className="sheets-selected-and-info-item-img">
                  {extractedText.old_value}
                </div>
              }
              {extractedText.old_value &&
                <div className="sheets-selected-and-info-item-img">
                  <img src="right.svg" alt="rigt" />
                </div>
              }
              <div className="sheets-selected-and-info-item-img" style={{ color: extractedText.error && 'red' }}>
                {extractedText.error ? `Cannot Detect ${section}` : extractedText.value}
              </div>
            </>
              }
          </div>
          <div className="frame-52">
            <div className="frame-44">
              <div className="define-sheet-number-area body-regular">
                Define sheet {section} area
              </div>
              <div className="tools">
                {showZoomActions &&
                  <div onClick={handleSearchInPdf} className={searchInPdf == true ? "extract-toolSelect" : "extract-tool"}>
                    <Tooltip title="Search" placement="bottom">
                      <img className= "pan" src={SearchIcon} alt="search_icon" />
                    </Tooltip>
                  </div>
                }
                <div id="custom-pan-button" className={panMode == true ? "extract-toolSelect" : "extract-tool"} style={{cursor: 'pointer', }}>
                  <Tooltip title="Pan" placement="bottom" PopperProps={{ className: "version-action-tooltip" }} arrow >
                    <img className= "pan" src="pan.svg" alt="pan"/>
                  </Tooltip>
                </div>
                <div style={{height: "24px", borderRight: "1px solid #EAEAEA",}}></div>
                <div id="custom-crop-button" className={cropMode == true ? "extract-toolSelect" : "extract-tool"} style={{marginLeft: "2px", cursor: 'pointer'}}>
                  <Tooltip title="Crop" placement="bottom" PopperProps={{ className: "version-action-tooltip" }} arrow>
                    <img  className= "pan" src="mark area.svg" alt="mark" />
                  </Tooltip>
                </div>
              </div>
            </div>
            <div className="frame-42">
              <article className="buttons-3" style={{cursor: "pointer"}}>
                <div className="label poppins-normal-abbey-14px" onClick={() => { props.setExtractNumber(false);
                  abortProcesses();
                }}>
                  Cancel
                </div>
              </article>
              <article className="buttons-4" style={{cursor: "pointer"}}>
                <div className="label-1 poppins-normal-white-14px"
                  onClick={() => { 
                    //console.log('cropArea ::',cropArea);
                    //console.log('cropRect ::',cropRect);
                    if(extractForAll == true){
                      if(cropArea && cropRect){
                        onStartBackgroundExtracting(cropArea, cropRect ,section, rectOrigin);
                      }
                    }else{
                      onExtractSave(pageIndexToValue, section, section == 'number', rectOrigin);
                    }
                    props.setExtractNumber(false);
                    abortProcesses();
                  }}
                >
                  Save
                </div> </article>
            </div>
          </div>
        </div>
        {!hideViewer &&<>
          <Draggable defaultPosition={{ x: 800, y: 10 }}>
            <div style={{backgroundColor: "#505050", color: "#FFFFFF", width: "340px", padding: "5px 10px", fontSize: "14px", fontWeight: 400}}>Please select an area to define sheet {section}</div>
          </Draggable>
          {showZoomActions &&
                <ZoomButtons zoomLevel={currentZoomLevel} onChangeZoom={(value)=> {handleChaneZoomValue(value, instance)}}/>
          }
          <div id="extractPDFcontainer" className="webviewer" ref={viewer} style={{ width: "100%", height: "90vh" }} />
        </>}
      </WithoutTemplateSection>
      {defineSheetNumberDialog && 
        <DefineSheetNumberDialog
        credential={credential}
        section={section}
        defineSheetNumberDialog={defineSheetNumberDialog}
        setDefineSheetNumberDialog={setDefineSheetNumberDialog}
      />}
      
    </Dialog>
  );
};
export default ExtractNumber;