import { useEffect, useState, useRef } from "react";
import { GrFilter } from "react-icons/gr";
import useProjectFormHook from "hooks/ProjectFormTab.hook";
import { _sortItems } from "constant/Methods";
import ProjectFormListTable from "./ProjectFormListTable";
import CreateProjectForm from "./CreateProjectForm";
import CreateMultipleChecklist from "./CreateMultipleChecklist";
import Spinner from "components/spinner";
import {
  Button,
  Divider,
  Grid,
  TextField,
  IconButton,
  Autocomplete,
  Chip,
  Stack,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import DialogComponent from "../Shared/DialogComponent";

import { FORM_TYPES } from "./CreateProjectForm/constants";

const ProjectFormTab = (props) => {
  const {
    createPopEnable,
    tableProjectFormList,
    _handleOpenCreate,
    credential,
    closeAndRefresh,
    showSpinner,
    setSpinner,
    formStatusOptions,
    defaultFormStatus,
    userList,
  } = useProjectFormHook(props); /* templateTypeOptions , defaultTemplateType */

  const [projectFormId, openedProjectForm] = useState(null);
  const [viewListData, setViewListData] = useState([]);
  //const [filteredList, setFilterList] = useState([]);
  var filteredList = useRef([]);
  //const [filterObject, setFilterObject] = useState([]); // [{fieldName : 'filterField1' , values : ['value 1' , 'value 2']} , {fieldName : 'filterField2' , values : ['value 1']}]
  var filterObject = useRef([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterPanel, enableFilterPanel] = useState(false);

  const [openFormTypeSelector, setOpenFormTypeSelector] = useState(false);
  const [selectedFormType, setSelectedFormType] = useState(null);

  const handleCloseFormTypeSelector = () => {
    setOpenFormTypeSelector(false);
  };

  const handleOpenFormTypeSelector = () => {
    setOpenFormTypeSelector(true);
  };

  const handleFormTypeSelection = (type) => {
    setSelectedFormType(type);
    openedProjectForm(null);
    _handleOpenCreate(true);
    handleCloseFormTypeSelector();
  };

  const columns = [
    {
      accessor: "numberSequence",
      Header: "Id",
      flex: 1,
      sortType: (prev, curr, columnId) => _sortItems(prev, curr, columnId),
    },
    {
      accessor: "title",
      Header: "Title",
      flex: 1,
      sortType: (prev, curr, columnId) => _sortItems(prev, curr, columnId),
    },
    { accessor: "form", Header: "Form Template", flex: 1 },
    {
      accessor: "formType",
      Header: "Template Type",
      flex: 1,
    } /*, disableSortBy: true */,
    { accessor: "type", Header: "Type", flex: 1 },
    { accessor: "formDate", Header: "Form Date", flex: 1 },
    { accessor: "status", Header: "Status", flex: 1 },
    { accessor: "assignedTo", Header: "Assigned To", flex: 1 },
  ];
  useEffect(() => {
    //console.log('projectId :: '+projectId);
    filterView();
  }, [tableProjectFormList]);

  function _handleFilterSelection(event, newValue, fieldName) {
    const valuee = newValue;
    //console.log('valuee',JSON.stringify(valuee));

    let toTemp = [];
    let staticValues = [];
    if (valuee && valuee.length > 0) {
      for (let obj of valuee) {
        staticValues.push(obj.value);
      }
    }
    if (
      filterObject &&
      filterObject.current &&
      filterObject.current.length > 0
    ) {
      toTemp = JSON.parse(JSON.stringify(filterObject.current));
    }
    const fInd = toTemp?.findIndex((data) => data.fieldName == fieldName);
    if (fInd > -1) {
      toTemp[fInd].ele_values = valuee;
      toTemp[fInd].values = staticValues;
    } else {
      toTemp.push({
        fieldName: fieldName,
        values: staticValues,
        ele_values: valuee,
      });
    }
    const fInd2 = toTemp?.findIndex((data) => data.fieldName == fieldName);
    if (fInd2 > -1) {
      if (!(toTemp[fInd2].values && toTemp[fInd2].values.length > 0)) {
        toTemp.splice(fInd2, 1);
      }
    }
    //setFilterObject(toTemp);
    filterObject.current = toTemp;
    filterView();
  }

  function filterView(_filterObject = filterObject.current) {
    let filterData = [];
    setSearchTerm(null);
    if (tableProjectFormList.length > 0) {
      if (_filterObject && _filterObject.length > 0) {
        for (let item of tableProjectFormList) {
          let filCount = 0;
          let matchCount = 0;
          for (let fil of _filterObject) {
            if (fil.values && fil.values.length > 0) {
              filCount++;
              for (let check of fil.values) {
                if (
                  item[fil.fieldName]
                    ?.toLowerCase()
                    .indexOf(check.toLowerCase()) > -1
                ) {
                  matchCount++;
                }
              }
            }
          }
          if (filCount == matchCount) {
            filterData.push(item);
          }
        }
      }
    }
    //console.log('filterData :: '+filterData?.length);
    //setFilterList(filterData);
    filteredList.current = filterData;
    filterDataFuntion("");
  }
  function filterDataFuntion(
    _searchTerm,
    _filteredList = filteredList.current
  ) {
    let orgData =
      filterObject.current && filterObject.current.length > 0
        ? JSON.parse(JSON.stringify(_filteredList))
        : JSON.parse(JSON.stringify(tableProjectFormList));
    //console.log('_filteredList :: ' + _filteredList?.length);
    //console.log('tableProjectFormList :: ' + tableProjectFormList?.length);
    let filterData = orgData;
    if (orgData && _searchTerm && _searchTerm != null && _searchTerm !== "") {
      filterData = [];
      for (let el of orgData) {
        if (
          (el.name && el.name.toLowerCase().includes(_searchTerm)) ||
          (el.title && el.title.toLowerCase().includes(_searchTerm))
        ) {
          filterData.push(el);
        }
      }
    }
    if (!filterData) {
      filterData = [];
    }
    setViewListData(filterData);
  }
  function getFilterObject(fieldName) {
    const fInd = filterObject.current?.findIndex(
      (data) => data.fieldName == fieldName
    );
    if (fInd > -1) {
      if (
        filterObject.current[fInd].values &&
        filterObject.current[fInd].values.length > 0
      ) {
        return filterObject.current[fInd];
      }
    }
    return;
  }
  function getFilterValues(fieldName, asOption) {
    let values = [];
    let obj = getFilterObject(fieldName);
    if (obj) {
      values = obj.values;
      if (asOption) {
        values = obj.ele_values;
      }
    }
    return values;
  }

  // console.log("********** props?.forTabHook?.tableFormList ", viewListData);

  return (
    <>
      <div className="container">
        {(!createPopEnable ||
          selectedFormType == FORM_TYPES.multipleChecklist) && (
          <>
            <div className="table_head">
              <div className="left_side">
                <div>
                  <IconButton onClick={() => enableFilterPanel(!filterPanel)}>
                    <GrFilter />
                  </IconButton>
                </div>
                <div>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    fullWidth
                    label="Search"
                    onChange={(e) => {
                      //convert input text to lower case
                      var lowerCase = e.target.value.toLowerCase();
                      if (!lowerCase || lowerCase == null || lowerCase === "") {
                        //console.log(lowerCase);
                        filterDataFuntion(lowerCase, filteredList.current);
                      }
                      setSearchTerm(lowerCase);
                    }}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        // Do code here
                        e.preventDefault();
                        filterDataFuntion(searchTerm, filteredList.current);
                      }
                    }}
                  />
                </div>
                {filterObject.current?.length > 0 && (
                  <div>
                    <Chip
                      color="success"
                      label="Filtering is on"
                      onDelete={() => {
                        /*setFilterObject([]);*/ filterObject.current = [];
                        filterView([]);
                        enableFilterPanel(false);
                      }}
                    />
                  </div>
                )}
              </div>
              <div className="right_side">
                <Button
                  variant="contained"
                  component="span"
                  onClick={() => handleFormTypeSelection(FORM_TYPES.pdfForm)}
                >
                  Create Form
                </Button>
                <Button
                  variant="contained"
                  component="span"
                  onClick={() =>
                    //handleOpenFormTypeSelector()
                    handleFormTypeSelection(FORM_TYPES.multipleChecklist)
                  }
                >
                  Create Checklist
                </Button>
              </div>
            </div>
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              {filterPanel && (
                <div style={{ width: "20%" }}>
                  <div className="versions-sidebar-header">
                    <span className="versions-sidebar-header-title">
                      Filter
                    </span>
                    <IconButton
                      size="small"
                      className="versions-sidebar-header-close"
                      onClick={() => enableFilterPanel(!filterPanel)}
                    >
                      <Close />
                    </IconButton>
                  </div>
                  <Divider variant="middle" />
                  <div
                    className="versions-sidebar-body"
                    style={{ height: "calc(91.7vh - 200px)" }}
                  >
                    <Grid
                      container
                      rowSpacing={2}
                      style={{ width: "100%", paddingTop: "15px" }}
                      className="sf-detail-container"
                    >
                      <Grid item xs={12}>
                        {userList && userList.length > 0 && (
                          <div style={{ paddingTop: "10px" }}>
                            <Autocomplete
                              multiple
                              className="inlineAutocomplete"
                              options={userList}
                              getOptionLabel={(option) => option?.label}
                              defaultValue={getFilterValues(
                                "createdById",
                                true
                              )}
                              onChange={(e, newValue) => {
                                _handleFilterSelection(
                                  e,
                                  newValue,
                                  "createdById"
                                );
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="standard"
                                  label="Created By"
                                />
                              )}
                            />
                          </div>
                        )}
                        {formStatusOptions && formStatusOptions.length > 0 && (
                          <div style={{ paddingTop: "10px" }}>
                            <Autocomplete
                              multiple
                              className="inlineAutocomplete"
                              options={formStatusOptions}
                              getOptionLabel={(option) => option?.label}
                              defaultValue={getFilterValues("status", true)}
                              onChange={(e, newValue) => {
                                _handleFilterSelection(e, newValue, "status");
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="standard"
                                  label="Status"
                                />
                              )}
                            />
                          </div>
                        )}
                        {props?.forTabHook?.templateTypeOptions &&
                          props?.forTabHook?.templateTypeOptions.length > 0 && (
                            <div style={{ paddingTop: "10px" }}>
                              <Autocomplete
                                multiple
                                className="inlineAutocomplete"
                                options={props?.forTabHook?.templateTypeOptions}
                                getOptionLabel={(option) => option?.label}
                                defaultValue={getFilterValues("type", true)}
                                onChange={(e, newValue) => {
                                  _handleFilterSelection(e, newValue, "type");
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="standard"
                                    label="Type"
                                  />
                                )}
                              />
                            </div>
                          )}
                      </Grid>
                    </Grid>
                  </div>
                </div>
              )}
              
                <div style={{ width: filterPanel ? "80%" : "100%"}}>
                  {showSpinner && <Spinner />}
                  <ProjectFormListTable
                    templateTablePorps={{
                      data: viewListData,
                      columns,
                    }}
                    openFormDetail={(row) => {
                      handleFormTypeSelection(row.formType);
                      openedProjectForm(row.id);
                      _handleOpenCreate(true);
                    }}
                  />
                </div>
            
            
            </div>
          </>
        )}
        {createPopEnable && (
          <>
            {selectedFormType == FORM_TYPES.multipleChecklist && (
              <CreateMultipleChecklist
                credential={credential}
                templateList={props?.tableFormList}
                handleClose={async (refresh, redirectId) => {
                  openedProjectForm(null);
                  setSelectedFormType(null);
                  closeAndRefresh(refresh);
                  if (redirectId && redirectId.length === 18) {
                    handleFormTypeSelection(FORM_TYPES.checklistForm);
                    openedProjectForm(redirectId);
                    _handleOpenCreate(true);
                  }
                }}
                userList={userList}
                selectedFormType={FORM_TYPES.checklistForm}
              ></CreateMultipleChecklist>
            )}
            {selectedFormType != FORM_TYPES.multipleChecklist && (
              <CreateProjectForm
                projectFormId={projectFormId}
                setFormId={openedProjectForm}
                credential={credential}
                templateList={props?.tableFormList}
                handleClose={(refresh) => {
                  openedProjectForm(null);
                  setSelectedFormType(null);
                  closeAndRefresh(refresh);
                }}
                statusOptions={formStatusOptions}
                defaultStatus={defaultFormStatus}
                userList={userList}
                selectedFormType={selectedFormType}
                viewListData={viewListData}
              ></CreateProjectForm>
            )}
          </>
        )}
        {/* form type selection popup */}
        {openFormTypeSelector && (
          <DialogComponent
            dialogProp={{
              opened: openFormTypeSelector,
              handleClose: handleCloseFormTypeSelector,
              titleContent: "Select Type",
              classFor: "template_type_selection",
              fullScreen: false,
            }}
          >
            <Stack
              className="template_type_selection_wrapper"
              spacing={2}
              direction="column"
            >
              {/*<Button
                variant="outlined"
                onClick={() =>
                  handleFormTypeSelection(FORM_TYPES.pdfForm)
                }
              >
                PDF
              </Button>*/}
              <Button
                variant="outlined"
                onClick={() =>
                  handleFormTypeSelection(FORM_TYPES.checklistForm)
                }
              >
                Checklist
              </Button>
              <Button
                variant="outlined"
                onClick={() =>
                  handleFormTypeSelection(FORM_TYPES.multipleChecklist)
                }
              >
                Multiple Checklist
              </Button>
            </Stack>
          </DialogComponent>
        )}
      </div>
    </>
  );
};
export default ProjectFormTab;
