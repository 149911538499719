import React, { useState, useEffect } from "react";
import { Button, ButtonGroup, Box, Tooltip, IconButton, TextField, Dialog } from "@mui/material";
import axios from "axios";
import moment from "moment";
import { Cancel, Help, Refresh, Search } from "@mui/icons-material";
import { Tabs, Tab, InputAdornment } from "@mui/material";
import { fetchUrl } from "Urls";
import ViewDialog from "./LogsComponents/VIewDialog";
import { GetToken } from "api";
//import UploadLogsstyles, { Styles } from "./uploadlogStyles";
import UploadLogsTable from "./LogsComponents/UploadLogsTabel";
import { Items, NoData } from "./LogsComponents/CommonMethod";
import useUploadLogsHook from "hooks/uploadLogs.hook";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { ConfirmDialog } from "components/DrawerComponent/DisciplineSettingDialog";
const UploadLogsstyles = styled.div`
.MuiDataGrid-virtualScrollerRenderZone > div:nth-child(2n) {
  background: #ecf0f3;
}
.MuiDataGrid-cell--withRenderer.MuiDataGrid-cell.MuiDataGrid-cell--textLeft
  .MuiBox-root {
  background: none;
}
.MuiDataGrid-virtualScrollerRenderZone > div > div:not(:first-child) {
  border-left: 1px solid #ccc;
}
[data-field="details"] a {
  color: #1bb3c3;
}
.pubishlog_class {
  width: 300px
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ffff;
  padding: 0 10px;
}
.pubishlog_class .MuiBox-root {
  display: flex;
  gap: 20px;
  align-items: center;
  max-width: 400px;
  width: 100%;
  justify-content: flex-end;
}

.pubishlog_class h1 {
  margin: 0;
  font-size: 20px;
  font-weight: 600;
}
.pubishlog_class .MuiButtonGroup-outlined {
  max-width: 130px;
  width: 100%;
  button {
    min-width: 100%;
    height: 45px;
    background: #fff;
  }
}
.view_Class + div {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  margin: 0;
  padding: 0;
  border: none;
}
.MuiDataGrid-virtualScrollerRenderZone > div > div > div {
  position: relative;
}
.MuiDataGrid-virtualScrollerRenderZone > div > div:empty {
  display: none;
}
.main_class .pubishlog_class {
  padding: 10px !important;
}
.textfield {
  align-items: flex-start; 
  background-color: #FFFFFF;
  border: 1px solid;
  border-color: #D8D8D8;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  padding: 8px 12px;
  position: relative;
  width: 320px;
  }
  .search {
    align-items: center;
    align-self: stretch; display: flex;
    flex: 0 0 auto;
    justify-content: space-between;
    position: relative;
    width: 100%;
  }
  .search-label {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    width: fit-content;
    color: #929292;
  }
    .search-1 { height: 16px;
      position: relative;
      width: 16px;
      }
      .table-top-area {
        label +.MuiInputBase-formControl .MuiOutlinedInput-input {
          height: 21px;
          padding: 8px 12px !important;
          line-height: normal;
          //color: #D8D8D8;
      }
      .MuiFormControl-root {
        label {line-height: normal;
            top: -7px;
            font-family: var(--common-font);
            font-size: 14px;
        }
        .MuiInputBase-formControl {
            border-radius: 2px;
        }
        } 
        align-items: center;
        background-color: #FFFFFF;
        border-radius: 2px 2px 0px 0px;
        display: flex;
        gap: 710px;
        overflow: hidden; 
        padding: 17px 0px; position: relative;
        width: 1164px;
        }
        .search-and-sort-by {
          align-items:flex-start;
          display: inline-flex;
          flex: 0 0 auto;
          gap: 12px;
          position: relative;
        }
`;

const Styles = styled.div`
${"" /* These styles are suggested for the table fill all available space in its containing element */
  }
display: block;
${"" /* These styles are required for a horizontaly scrollable table overflow */
  }
padding: 0;
overflow-x: auto;
overflow-y: auto;
/* height: 500px; */
// max-height: calc(100vh - 260px);

.table {
  border-spacing: 0;
  background: #fff;
  width: 100%;
  background-color: #fff;
  position: relative;
  box-sizing: border-box;
  z-index: 0;
  div {
    color: rgba(0, 0, 0, 0.6);
    font-size: 13px;
  }
  .thead {
    overflow-y: auto;
    overflow-x: hidden;
    /* min-width: 1570px; */
    min-width: 100%;
    position: sticky;
    top: 0;
    z-index: 999;
    background: #ffff;
  }

  .th,
  .td {
  }
  .tbody {
    background: #fff;
    height: 75vh;
    overflow-x: auto;
    overflow-y: auto;
  }

  .tr {
    width: 100% !important;
    &:hover {
    //  background: rgba(237, 240, 242, 0.75);
    }
    :last-child {
      .td {
        border-bottom: 0;
      }
    }
    border-bottom: 1px solid #ddd;
  }
  .th {
    font-weight: 300;

    &:hover {
      background: rgba(237, 240, 242, 0.75);
      .resizer {
        opacity: 1;
      }
    }
  }

  .th,
  .td {
    margin: 0;
    padding: 0.5rem;
    font-size: 13px;
    button {
      padding: 8px 20px;
      border-radius: 3px;
      text-transform: capitalize;
      letter-spacing: 1px;
      font-weight: 500;
    }
    .cancel_Class {
      background: #ededed;
    }
    .view_Class {
      background: var(--btn-blue);
      color: #fff;
      + div {
        right: 20px;
      }
      [role="cell"] .MuiBox-root {
        gap: 10px;
      }
    }
    .closeIconclass {
      color: #f00;
    }
    /* border-right: 1px solid #ddd; */
    overflow: hidden;
    .name_main {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    position: relative;

    :last-child {
      border-right: 0;
    }
    &:first-child {
      width: 220px !important;

      span.file_name {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    &:nth-child(3) {
      width: 200px !important;
    }
  }

  .resizer {
    opacity: 0;
    right: 0;
    background: #717171;
    width: 3px;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: 1;
    ${"" /* prevents from scrolling while dragging on touch devices */}
    touch-action :none;

    &.isResizing {
      background: red;
    }
  }
}
button.MuiButton-root.MuiButton-text.MuiButton-textPrimary {
  margin: 0;
  padding: 8px 10px;
}
div[title="Toggle SortBy"] {
  font-weight: 600;
}
.group-btn {
  background: none;
}
.stepper_div.MuiBox-root .stepperClass {
  max-width: 480px;
  margin: 0 auto;

  .publichButton_Container {
    position: absolute;
    right: 30px;
    top: 0;
    gap: 20px;
  }
}
.buttons {
  cursor: pointer;
  align-items: center;
  border-radius: 2px; display: flex;
 // height: 24px;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 24px;
  }
  .close {
    &:hover {
      background: #F8F8F8;
    }
  &:hover {
    background: #F8F8F8;
  }
    height: 16px; position: relative;
    width: 16px;
    }
    .modal-header {
    align-items: flex-start;
    background-color: #FFFFFF;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-color: #EAEAEA;
    border-radius: 2px 2px 0px 0px;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    padding: 16px 20px;
    position: relative;
    width: 400px;
    }
    .delete-sheet {
      color: var(--neutralsblack);
      font-family: var(--font-family-poppins);
      font-size: var(--font-size-m);
      font-weight: 600;
      letter-spacing: 0.16px;
      line-height: normal;
      margin-top: -1px;
      position: relative;
      width: fit-content;
    }
.modal-body {
align-items: flex-start;
background-color: #FFFFFF;
display: flex;
flex-direction: column;
gap: 12px;
justify-content: center; 
padding: 16px 20px;
position: relative;
width: 400px;
}
.are-you-sure-you-wan {
color: #505050;
font-weight: 400;
line-height: normal;
margin-top: -1px;
position: relative;
width: 360px;
}
.buttonsAction {
  &:hover {
    background: #F8F8F8;
  }
cursor: pointer;
align-items: center;
background-color: #FFFFFF; 
border: 1px solid #D8D8D8;
border-color: #D8D8D8; 
border-radius: 2px;
display: inline-flex;
flex: 0 0 auto;
gap: 8px;
justify-content: center;
overflow: hidden;
padding: 9px 16px;
position: relative;
}
.modal-bottom {
  align-items: center;
  background-color: #FFFFFF; 
  border-color: #EAEAEA; 
  border-radius: 0px 0px 2px 2px;
  border-top-style: solid;
  border-top-width: 1px; display: flex;
  gap: 12px;
  justify-content: flex-end;
  overflow: hidden;
  padding: 12px 20px;
  position: relative;
  width: 400px;
  }
  .label {
    letter-spacing: 0; 
    line-height: normal;
    margin-top: -1px;
    position: relative;
    width: fit-content;
    color: #505050;
    }
    .buttons-1 {
    cursor: pointer;
    align-items: center;
    background-color: #F92828;
    border-radius: 2px;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 10px;
    justify-content: center;
    overflow: hidden;
    padding: 9px 16px;
    position: relative;
    }
    .label-1 {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px; 
    position: relative; 
    width: fit-content;
    color: #FFFFFF;
    }
`;
export default function UploadLogs({ showInFolder, ...props }) {
  const navigate = useNavigate();

  const {
    tableData, loader, viewFolderFiles, credential,  getlogs, filterDataFuntion,
    setSearchText
  } = useUploadLogsHook(props);
  const [deletePopup, setDeletePopup] = useState(false);
  const [binderId, setBinderId] = useState();
  const confirmDialogData = {
    title: 'Delete Sheet/s',
    content: 'Are you sure you want to delete this sheet? This cannot be undone.',
    action1: "Cancel",
    action2: "Delete"
  };
  const handleDeleteBinder = (id) => {
    setDeletePopup(true);
    setBinderId(id);
  }
  const handleDelete = () => {
    setDeletePopup(false);
    axios.delete(
      `${fetchUrl}/delete/Amazon_S3_Attachment__c/${binderId}?token=${credential?.token}&instanceUrl=${credential?.instanceUrl}`
    ).then((res) => {
      console.log("respose");
      getlogs();
    })
    .catch((err) => {
      console.log(err);
    });
  };

  const columns = [
    {
      accessor: "file_name",
      Header: "File Name",
      width: 290,
      Cell: (params) => {
        return (
          <span dangerouslySetInnerHTML={{ __html: params?.row?.original?.name_mark ? params?.row?.original?.name_mark : params.value }} />
        )
      }
    },
    {
      accessor: "set",
      Header: "Set",
      width: 192,
    },
    {
      accessor: "uploaded_date",
      Header: "Uploaded Date",
      width: 192,
      Cell: (parms) => {
        //console.log("createddate ::", parms.value);
        // let date = moment(parms.value).format("MMM  DD YYYY");
        return (
          parms.value
        );
      }
    },
    {
      accessor: "sheet_Count",
      Header: "Sheets",
      width: 150,
      Cell: (parms) => {
        return (
          parms.value
        );
      }
    },
    {
      accessor: "Published_On",
      Header: "Published On",
      width: 192,
      Cell: (parms) => {
        //console.log("lastmodifydate", parms.value);
        // let date = moment(parms.value).format("MMM  DD YYYY");
        return (
          parms.value
        );
      }
    },
    {
      accessor: "Uploaded_by",
      Header: "Published By",
      width: 250,
    },
    {
      accessor: "status",
      Header: "Status",
      width: 250,
      Cell: (parms) => {
        return (
          <div className="content">
            {parms.value == "Published" &&
              <img style={{ marginRight: "5px" }} className="line-4"
                src="approved.svg" alt="Line 4" />
            }
            {parms.value == "For Review" &&
              <img style={{ marginRight: "5px" }} className="line-4"
                src="review yellow.svg" alt="Line 4" />
            }
            {parms.value == "Processing" &&
              <img style={{ marginRight: "5px" }} className="line-4"
                src="processing.svg" alt="Line 4" />
            }
            {parms.value}
          </div>
        );
      }
    },
    {
      accessor: "",
      Header: "Action",
      width: 155,
      Cell: (parms) => {
        const { attachObj } = parms.row.original;
        return (
          <>
            {(parms.row.original.status === "For Review" || parms.row.original.status === "Processing") &&
              <div style={{ color: "#346EF5", fontWeight: 400, lineHeight: "normal", minHeight: "21px", minWidth: "65px", position: "relative", cursor: 'pointer' }} onClick={() => {
                const states = { state: { attachId: attachObj?.Id, activeTabId: props.activeTab } }
                //console.log('publish logstates :: ',JSON.stringify(states));
                navigate("/add_sheets", states);
              }}> Continue </div>
            }
            {parms.row.original.status == "Published" &&
              <div style={{ color: "#346EF5", fontWeight: 400, lineHeight: "normal", minHeight: "21px", minWidth: "65px", position: "relative", cursor: 'pointer' }} onClick={() => {
                if (attachObj.Set__c) {
                  props.setActiveTab(0);
                } else {
                  props.setActiveTab(1);
                }
              }}> View Sheet </div>
            }
          </>
        );
      }
    },
    {
      accessor: "more",
      Header: "",
      width: 50,
      Cell: (parms) => {
        return (
          <img style={{ cursor: "pointer" }} onClick={() => handleDeleteBinder(parms.row.original.id)} src="/deleted.svg" alt="delete" />
        );
      }
    },

  ];

  return (
    <UploadLogsstyles>
      {deletePopup &&
        <ConfirmDialog
          onCancel={() => {
            setDeletePopup(false);
          }}
          onAccept={() => {
            handleDelete()
          }}
          DialogData={confirmDialogData}
        />
      }
      <Box className="main_class" sx={{ marginLeft: "16px", marginRight: "20px", position: "relative" }}>
        <div className="table-top-area">
          <TextField
            sx={{
              "& .MuiInputBase-root": {
                color: "#929292",
                width: "400px",
                height: '37px'

              },
            }}
            id="outlined-basic"
            variant="outlined"
            fullWidth
            autoComplete="off"
            className="Searchbar-field"
            placeholder="Search File Name"
            //  onClick={handleClearAllFiltersOption}
            onChange={(e) => {
              //convert input text to lower case
              var lowerCase = e.target.value.toLowerCase();
              if (!lowerCase || lowerCase == null || lowerCase === "") {
                filterDataFuntion(lowerCase);
              }
              setSearchText(lowerCase);
            }}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                // Do code here
                e.preventDefault();
                var lowerCase = e.target.value.toLowerCase();
                filterDataFuntion(lowerCase);
              }
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </div>
        <Styles>
          <UploadLogsTable
            columns={columns}
            data={viewFolderFiles ? viewFolderFiles : tableData}
            loader={loader}
            NoDataComponent={<NoData />}
          />
        </Styles>
      </Box>
    </UploadLogsstyles>
  );
}
