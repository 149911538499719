import { useState, useEffect } from "react";
import _ from "lodash";
import axios from "axios";
import { fetchUrl } from "Urls";
import moment from "moment";
import { highlightText } from "components/SheetsComponent";

const useUploadLogsHook = (props) => {
  const { credential } = props;
  const [tableData, settableData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [viewFolderFiles, setViewFolderFiles] = useState([]);
  const [searchText, setSearchText] = useState("");
  async function getlogs() {
    setLoader(true);
    const { instanceUrl, token, projectId } = credential;  //await _getTokensValues();
    await axios
      .get(
        `${fetchUrl}/binders/unpublished/${projectId}?token=${token}&instanceUrl=${instanceUrl}`
      ).then((res) => {
        if (res?.status === 200) {
          setLoader(false);
          //console.log("publish data ::", res?.data?.records);
          const rows = [];
          _.forEach(res?.data?.records, (val, index) => {
            rows.push({
              id: val?.Id,
              file_name: val?.File_Name__c,
              set: val?.Set__c ? val?.Set__r.Name : "",
              Uploaded_by: val?.CreatedBy?.Name,
              uploaded_to: val?.Folder__c ? val?.Folder__r.Folder_Path__c : "",
              uploaded_date: moment(val?.CreatedDate).format( "DD MMM YYYY HH:mm A" ),
              Published_On:  moment(val?.LastModifiedDate).format( "DD MMM YYYY HH:mm A" ),
              details: "N/A",
              status: val?.Publishing_Status__c ? val?.Publishing_Status__c : "",
              more: "N/A",
              sheet_Count: val?.Sheet_Count__c ? val?.Sheet_Count__c : "",
              folderId: val?.Folder__c ? val?.Folder__c : null,
              folderPath: val?.Folder__c ? val?.Folder__r.Folder_Path__c : "",
              amazonPath: val?.Folder__c ? val?.Folder__r.S3_Folder_Path__c : "",
              signedPath: val?.Signed_Path__c,
              fileUrl: val?.File_URL__c,
              attachObj: val,
            });
          });
          settableData(rows);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    getlogs();
  }, []);

  useEffect(() => {
    filterDataFuntion(null);
  }, [tableData]);
   async function filterDataFuntion(searchTerm) {
    let orgData = JSON.parse(JSON.stringify(tableData));
    //console.log('orgData :: '+JSON.stringify(orgData));
    let filterData = orgData;
    if (orgData && searchTerm && searchTerm != null && searchTerm !== "") {
      filterData = [];
      for (let el of orgData) {
        if ((el.file_name.toLowerCase().includes(searchTerm))) {
          el.name_mark = highlightText(el.file_name, searchTerm);
          filterData.push(el);
        }
      }
    } else {
      filterData = JSON.parse(JSON.stringify(""));
    }
    setViewFolderFiles(filterData);
  }
  return {
    tableData, loader, viewFolderFiles, credential,  getlogs, filterDataFuntion,
    setSearchText
  };
};

export default useUploadLogsHook;
