import React, { useState, useEffect, useRef } from "react";
import { Dialog , Button, Tooltip} from "@mui/material";
import styled from "styled-components";
import WebViewer from "@pdftron/webviewer";
import Draggable from "react-draggable";
import SearchIcon from "../../assets/images/search.svg";
import { ConfirmDialogCheckbox } from "components/DrawerComponent/DisciplineSettingDialog";
import ZoomButtons from "components/ZoomButtons";
import { APRYSE_L_KEY } from "api" ;
const Style = styled.div`
  .sheet-file-view {
    background-color: #ffffff;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-color: #eaeaea;
    height: 56px;
    left: 0;
    display: flex;
    position: relative;
    justify-content: space-between;
    top: 0;
    width: 100%;
  }
  .sheet-name-and {
    display: inline-flex;
    gap: 12px;
    left: 20px;
    padding: 3px 0px;
    position: relative;
    top: 19px;
  }

  .text-wrapper-2 {
    color: #000000;
    font-family: "Poppins-SemiBold", Helvetica;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.14px;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    width: fit-content;
  }

  .p {
    color: #505050;
    font-family: "Poppins-Regular", Helvetica;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.14px;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    width: fit-content;
  }
  .Buttons {
    gap: 12px;
    display: flex;
    padding: 8px 0px;
    position: relative;
    //top: 19px;
    right: 20px;
  }
  .zoom-container {
    right: 15px;
    bottom: 15px;
    float: right;
    display: inline-flex;
    align-items: flex-start;
    gap: -1px;
  }
  .zoom-button {
    max-width: none !important;
    display: flex;
    height: 37px;
    padding: 8px 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border: 1px solid var(--neutrals-light-grey-3, #d8d8d8);
    background: var(--neutrals-white, #fff);
  }
`;
function ViewDeleteSheet({ ...props }) {
  const {
    onAccept,
    setViewSheet,
    onCancel,
    fileData,
    DialogData,
    credential,
    skipRestorePopup
  } = props;
  //console.log("fileData ::", fileData);
  const [showZoomActions, setShowZoomAction] = useState(false);
  const [restorePopup, setRestorePopup] = useState(false);
  const [searchInPdf, setSearchInPdf] = useState(false);
  const [instance, setInstance] = useState();
  const [currentZoomLevel, setCurrentZoomLevel] = useState(1);
  const viewer = useRef();
  useEffect(() => {
    const inter = setTimeout(async () => {
      loadDocument();
    }, 1000);
  }, []);
  function handleSearchInPdf(ann, type) {
    let pdfSearchButton;
    pdfSearchButton = instance.UI.iframeWindow.document.querySelector('[data-element="searchButton"]');
    pdfSearchButton.click();
    setSearchInPdf(!searchInPdf);

  }
  const loadDocument = async () => {
    //console.log('viewer.curre nt :: ',viewer.current);

    const disElement = [
      "toolbarGroup-View",
      "toolsHeader",
      "toolbarGroup-Annotate",
      "toolbarGroup-Shapes",
      "toolbarGroup-Insert",
      "toolbarGroup-Measure",
      "toolbarGroup-Edit",
      "toolbarGroup-FillAndSign",
      "toolbarGroup-Forms",
      "toolbarGroup-Redact",
      "searchPanelResizeBar",
    ];
    await WebViewer(
      {
        fullAPI: true,
        path: "/webviewer/public",
        initialDoc: fileData.url,
        disabledElements: disElement,
        licenseKey: APRYSE_L_KEY, // sign up to get a free trial key at https://dev.apryse.com,
      },
      viewer.current
    ).then(async (instance) => {
      instance.UI.disableElements(disElement);
      const { documentViewer, annotationManager, Tools, PDFNet } =
        instance.Core;
        const iframeWindow = instance.UI.iframeWindow;
        const header = iframeWindow.document.querySelector('[data-element="header"]');
        header.style.display = 'none';
        setInstance(instance);
        setShowZoomAction(true);
        documentViewer.addEventListener("zoomUpdated", (zoom) => {
          setCurrentZoomLevel(zoom);
        });
      documentViewer.addEventListener("documentLoaded", async () => {
        const defaultMarkupTool = documentViewer.getTool(Tools.ToolNames.PAN);
        documentViewer.setToolMode(defaultMarkupTool);
        setCurrentZoomLevel(documentViewer.getZoomLevel());
        const scrollView = documentViewer.getScrollViewElement();
        const simulateControlScroll = (event) => {
          if (documentViewer.getToolMode().name === "Pan") {
            scrollView.style.overflow = 'hidden';
            if(!event.ctrlKey){
              // Create a new wheel event with the Control key pressed
              const newEvent = new WheelEvent('wheel', {
                deltaX: event.deltaX,
                deltaY: event.deltaY,
                deltaZ: event.deltaZ,
                deltaMode: event.deltaMode,
                clientX: event.clientX,
                clientY: event.clientY,
                screenX: event.screenX,
                screenY: event.screenY,
                pageX: event.pageX,
                pageY: event.pageY,
                ctrlKey: true,  // Simulate Control key pressed
                shiftKey: event.shiftKey,
                altKey: event.altKey,
                metaKey: event.metaKey,
                bubbles: event.bubbles,
                cancelable: event.cancelable,
                composed: event.composed
              });
              scrollView.dispatchEvent(newEvent);
            }
          }else {
            scrollView.style.overflow = 'auto';
          }
        };
        // Use capturing phase to ensure we catch the event early
        scrollView.addEventListener('wheel', simulateControlScroll, { capture: true, passive: false });
        const zOutEle = document.getElementById('zoom-out-button');
        if(zOutEle){
          if(!zOutEle.dataset?.clickBound || zOutEle.dataset.clickBound != 'yes'){
            zOutEle.dataset.clickBound = 'yes';
            zOutEle.addEventListener('click', () => {
              let preLevel = documentViewer.getZoomLevel();
              if(preLevel > 0.1){
                documentViewer.zoomTo(documentViewer.getZoomLevel() - 0.25);
              }else{
                documentViewer.zoomTo(0.1);
              }
            });
          }
        }
        const zInEle = document.getElementById('zoom-in-button');
        if(zInEle){
          if(!zInEle.dataset?.clickBound || zInEle.dataset.clickBound != 'yes'){
            zInEle.dataset.clickBound = 'yes';
            zInEle.addEventListener('click', () => {
              documentViewer.zoomTo(documentViewer.getZoomLevel() + 0.25);
            });
          }
        }
      });
    });
  };
  async function handleChaneZoomValue(value, _instance = instance) {
    _instance.Core.documentViewer.zoomTo(value / 100);
  }

  return (
    <Dialog open={true} fullScreen>
      <Style>
        <div className="sheet-file-view">
          <div className="sheet-name-and">
            <div className="text-wrapper-2">{fileData.name}</div>
            <p className="p">{fileData.set ? fileData.set : fileData.Shop_Drawing_Type__c}</p>
          </div>
          <div className="Buttons">
            {showZoomActions &&
              <div className="define-sheet-number-area body-regular" onClick={handleSearchInPdf} style={{ backgroundColor: searchInPdf && "#E8EFFF", alignSelf: "center" }}>
                <Tooltip title="Search" placement="bottom">
                  <img src={SearchIcon} alt="search_icon" />
                </Tooltip>
              </div>
            }
            <Button className="custom_button cancel_button" onClick={onCancel}>
              Cancel
            </Button>
            <Button className="custom_button apply_button" onClick={() => !skipRestorePopup ? setRestorePopup(true) : onAccept()}>
              Restore
            </Button>
          </div>
        </div>
        <div
          id="extractPDFcontainer"
          className="webviewer"
          ref={viewer}
          style={{ width: "100%", height: "94vh" }}
        />
        {showZoomActions && (
          <ZoomButtons zoomLevel={currentZoomLevel} onChangeZoom={(value)=> {handleChaneZoomValue(value, instance)}}/>
        )}
        {restorePopup &&
          <ConfirmDialogCheckbox
            credential={credential}
            nameOfDontShowProperty='recycleSkipRestoreDialog'
            onAccept={() => {
              onAccept();
            }}
            onCancel={() => {
              setRestorePopup(false);
            }}
            DialogData={DialogData}
          />
        }
      </Style>
    </Dialog>
  );
}
export default ViewDeleteSheet;
