import React from "react";

import { FormGroup, FormControlLabel, Checkbox , FormControl,FormHelperText } from "@mui/material";

const MultiSelectRender = ({ handleChange , req , ...props}) => {
  const { responseOptions,defaultValue, checklistFormobj } = props;
  const [selectedValues, setSelectedValues] = React.useState(defaultValue ? defaultValue.split(';'): []);

  return (
    <FormControl error={!(defaultValue && defaultValue !== '') && req}>
      <FormGroup required={req} >
        {responseOptions.map((r) => (
          <FormControlLabel
            key={r.key}
            value={r.value}
            control={<Checkbox checked={selectedValues?.includes(r.value)} value={r.value} onChange={(e) => {
              if(checklistFormobj.Status__c !== "Completed"){
              let fff = selectedValues;
              //console.log('fff 0 :: '+fff);
              //console.log('e.target.checked 0 :: '+e.target.checked);
              if (e.target.checked) {
                if (!fff) {
                  fff = [];
                }
                if (fff.indexOf(r.value) < 0) {
                  fff.push(r.value);
                }
              } else {
                if (fff.indexOf(r.value) > -1) {
                  const f_i = fff.indexOf(r.value);
                  fff.splice(f_i, 1);
                }
              }
              //console.log('fff :: '+fff);
              setSelectedValues(fff);
              handleChange({ target: { name: 'Response__c', value: fff.join(';') } });
            }}} name={r.value} />}
            label={r.label}
          />
        ))}
      </FormGroup>
      <FormHelperText>{( !req || (defaultValue && defaultValue !== '') ) ? undefined : "Select an option."}</FormHelperText>
    </FormControl>
  );
};

export default MultiSelectRender;
