/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef } from "react";
import WebViewer from "@pdftron/webviewer";
import moment from "moment";
import axios from "axios";
import { fetchUrl } from "Urls";
import { toast } from "react-toastify";
import { APRYSE_L_KEY } from "api";
import ZoomButtons from "components/ZoomButtons";

export default function FieldTicketSign({...params}) {
   const { token, cancel } = axios.CancelToken.source();
   const {canvasData , currentFTIndex , ftRecords , credentials ,showSpinner, setSpinner, declineButtonRef , signButtonRef, handleNext , onFieldTicketAction } = params;
   const [fieldticketId, setFtId] = useState();
   const [fileUrl, setFileUrl] = useState();
   const [mergedAttachment, setMergeAttachment] = useState();
   const [currentZoomLevel, setCurrentZoomLevel] = useState(1);
   const [ftUpdated, setFtCompleted] = useState(false);
   const docTriggerChanges = useRef(null);
   const fileTitle = useRef(null);
   const isSigned = useRef(null);
   /** Webviewer Variables Start */
   const viewer = useRef(null);
   const [instanceW, setInstanceW] = useState(null);
   const [showZoomActions, setShowZoomAction] = useState(false);
   /** Webviewer Variables End */
   React.useEffect(() => {
      isSigned.current = false;
      if(ftRecords?.length > 0){
         setFtId(ftRecords[currentFTIndex].Id);
      }
      return () => {
         cancel('Fetch canceled by user.');
      };
   }, [currentFTIndex]);
   React.useEffect(() => {
      if(fieldticketId){
         isSigned.current = ftRecords[currentFTIndex].signed;
         setFtCompleted(ftRecords[currentFTIndex].completed);
         setTimeout(() => {
            if((isSigned.current === true || ftRecords[currentFTIndex].completed === true) && ftRecords[currentFTIndex].fileUrl){
               setTimeout(() => {
                  loadDocument(ftRecords[currentFTIndex].fileUrl , 'direct');
               }, 500);
            }else{
               getSignData();
            }
         }, 200);
      }
   }, [fieldticketId]);
   async function getSignData( ) {
      setSpinner(true);
      let reqUrl = `${fetchUrl}/sign_data`;
      if (fieldticketId && fieldticketId !== '' && fieldticketId !== undefined) {
         reqUrl += '?fieldticketId=' + fieldticketId;
      }
      if(canvasData?.orgUserkey){
         reqUrl += '&orgUserkey=' + canvasData?.orgUserkey;
      }
      await axios.get(reqUrl,{cancelToken:token}).then(async (res) => {
         if (res.status === 200) {
            setFileUrl(res.data?.fileUrl);
            onFieldTicketAction(fieldticketId , 'fileUrl' , res.data?.fileUrl);
            isSigned.current = (res.data?.ftObj?.Status__c === 'Signed');
            if(isSigned.current === true){
               if(!ftRecords[currentFTIndex].signed){
                  onFieldTicketAction(fieldticketId , 'signed');
               }
            }else if(res.data?.ftObj?.Status__c === 'Declined to Sign'){
               if(!ftRecords[currentFTIndex].completed){
                  onFieldTicketAction(fieldticketId , 'completed');
                  setFtCompleted(true);
               }
            }
            setMergeAttachment(res.data?.mergedAttach);
            fileTitle.current = res.data?.mergedAttach?.File_Name__c;
            if (res.data?.fileUrl) {
               loadDocument(res.data?.fileUrl , 'fetch');
            }
         }
         if (res.data?.error) {
            toast.error(res.data?.errorMessage);
         }
      }).catch((error) => {
         setSpinner(false);
         console.log("error >>>>>>>>>>>>", JSON.stringify(error),axios.isCancel(error));
         console.error(error);
      });
      setSpinner(false);
   }
   const loadDocument = async (fileUrl , source) => {
      //console.log('viewer.curre nt :: ',viewer.current);
      //console.log(source,'viewer ',viewer);
      if (viewer.current) {
         setShowZoomAction(false);
         const disElement = [
            "header",
            "toolbarGroup-View",
            "toolsHeader",
            "toolbarGroup-Annotate",
            "toolbarGroup-Shapes",
            "toolbarGroup-Insert",
            "toolbarGroup-Measure",
            "toolbarGroup-Edit",
            "toolbarGroup-FillAndSign",
            "toolbarGroup-Forms",
            "toolbarGroup-Redact",
            "annotationGroupButton",
            "linkButton",
            "contextMenuPopup",
            "annotationCommentButton",
            "annotationStyleEditButton",
            "textSignaturePanel",
            "textSignaturePanelButton",
            "imageSignaturePanelButton",
            "textSignaturePanel",
            "imageSignaturePanel",
            "textPopup",
            "text-signature-font-dropdown"
         ];/* */
         //console.log('fileUrl ::',fileUrl);
         if(true){
            setSpinner(true);
            await WebViewer({fullAPI: true,path: "/webviewer/public",
               initialDoc: fileUrl,
               disabledElements: disElement,
               licenseKey: APRYSE_L_KEY,
            },viewer.current).then(async (instance) => {
               //console.log('instance ::',instance);
               instance.UI.disableElements(disElement);
               setInstanceW(instance);
               if(!canvasData || !canvasData?.action){
                  setShowZoomAction(true);
               }
               const { documentViewer, annotationManager , Annotations } = instance.Core;
               const iframeWindow = instance.UI.iframeWindow;
               const signatureTool = documentViewer.getTool('AnnotationCreateSignature');
               Annotations.WidgetAnnotation.getContainerCustomStyles = widget => {
                  if (widget instanceof Annotations.WidgetAnnotation) {
                     const fieldFlags = widget.fieldFlags;
                     var req = false;
                     if(fieldFlags){
                        req = fieldFlags.get('Required');
                     }
                     const rSt ={border: '1px solid black',padding: '3px'};
                     if(req){
                        rSt.border = '1px solid red';
                     }
                     return rSt;
                  }
               };
               signatureTool.addEventListener('locationSelected', () => {
                  //console.log('sign here cliked');
                  setTimeout(async () => {
                     var signatureModal;
                     var count = 0;
                     while (!signatureModal && count < 200) {
                        signatureModal = iframeWindow.document.querySelector('[data-element="signatureModal"]');
                        if (signatureModal) {
                           const container = signatureModal.querySelector(".container");
                           if (container) {
                              const signHeadContainer = container.querySelector(".header-container");
                              if (signHeadContainer) {
                                 const tab_div = signHeadContainer.querySelector(".tab-list");
                                 if (tab_div) {
                                    tab_div.style = 'display:none;'
                                 }
                                 const headerDiv = signHeadContainer.querySelector(".header");
                                 if (headerDiv) {
                                    const pDiv = headerDiv.children[0];
                                    if (pDiv) {
                                       pDiv.innerHTML = 'Signature';
                                    }
                                 }
                              }
                              const signStyleContainer = container.querySelector(".signature-style-options");
                              if (signStyleContainer) {
                                 let classToHide = ['.Dropdown__wrapper', '.divider'];
                                 for (let cls of classToHide) {
                                    var clsEle = signStyleContainer.querySelector(cls);
                                    if (clsEle) {
                                       clsEle.style = 'display:none;';
                                    }
                                 }
                              }
                              const signFooter = container.querySelector(".footer");
                              if (signFooter) {
                                 const bDiv = signFooter.children[0];
                                 if (bDiv) {
                                    bDiv.innerHTML = 'Save';
                                 }
                              }
                           }
                           break;
                        }
                        await delay(300);
                        count++;
                     }
                     async function delay(ms) {
                        return new Promise(resolve => setTimeout(()=>{
                           //console.log('delay '+count);
                           resolve();
                        }, ms));
                     }
                  }, 500);
               });
               documentViewer.addEventListener("documentLoaded", async () => {
                  setCurrentZoomLevel(documentViewer.getZoomLevel())
                  if (isSigned.current == false && ftUpdated == false) {
                     await putPrintNameField(instance);
                     await putDateField(instance);
                     await putSignCanvasField(instance);
                     await putSignCommentField(instance);
                     annotationManager.addEventListener('fieldChanged', (field, value) => {
                        //console.log(`Field changed: ${field.name}, ${value}`);
                        docTriggerChanges.current = true;
                     });
                  }
                  setTimeout(() => {
                     const zOutEle = document.getElementById('zoom-out-button');
                     if(zOutEle){
                     if(!zOutEle.dataset?.clickBound || zOutEle.dataset.clickBound != 'yes'){
                        zOutEle.dataset.clickBound = 'yes';
                        zOutEle.addEventListener('click', () => {
                           let preLevel = documentViewer.getZoomLevel();
                           if(preLevel > 0.1){
                             documentViewer.zoomTo(documentViewer.getZoomLevel() - 0.25);
                           }else{
                             documentViewer.zoomTo(0.1);
                           }
                        });
                     }
                     }
                     const zInEle = document.getElementById('zoom-in-button');
                     if(zInEle){
                     if(!zInEle.dataset?.clickBound || zInEle.dataset.clickBound != 'yes'){
                        zInEle.dataset.clickBound = 'yes';
                        zInEle.addEventListener('click', () => {
                           documentViewer.zoomTo(documentViewer.getZoomLevel() + 0.25);
                        });
                     }
                     }
                  }, 200);
               });
               documentViewer.addEventListener("zoomUpdated", (zoom) => {
                  setCurrentZoomLevel(zoom);
               });
            }).catch((error) => {
               setSpinner(false);
               console.log("error >>>>>>>>>>>>", JSON.stringify(error),axios.isCancel(error));
               console.error(error);
            });
            setSpinner(false);
         }
      }
   }; 
   const putPrintNameField = async (_instance = instanceW) => {
      const { documentViewer, annotationManager, Annotations } = _instance.Core;
      const { WidgetFlags } = Annotations;
      const doc = documentViewer.getDocument();
      const searchTerm = "\\n1\\";
      var pageNumber; // page to parse
      var quads;
      for (let pI = 1; pI <= doc.getPageCount(); pI++) {
         const pageText = await doc.loadPageText(pI);
         let startIndex = pageText.indexOf(searchTerm);
         let endIndex = 0;
         if (startIndex > -1) {
            pageNumber = pI;
            endIndex = startIndex + searchTerm.length;
            quads = await doc.getTextPosition(pageNumber, startIndex, endIndex);
            break;
         }
      }
      if (pageNumber > 0) {
         //console.log('quads :: ',quads);
         const fontOptions = {name: 'SansSerif',size: 12};
         const font = new Annotations.Font(fontOptions);
         const flags = new WidgetFlags();
         flags.set('Required', true);
         // create a form field
         const field = new Annotations.Forms.Field("print_name", {type: 'Tx', flags,font});
         const widgetAnnot = new Annotations.TextWidgetAnnotation(field);
         // set position and size
         widgetAnnot.PageNumber = pageNumber;
         widgetAnnot.X = quads[0].x1;
         widgetAnnot.Y = quads[0].y1 - 20;
         widgetAnnot.Width = 245;
         widgetAnnot.Height = 30;
         //add the form field and widget annotation
         annotationManager.getFieldManager().addField(field);
         annotationManager.addAnnotation(widgetAnnot);
         annotationManager.drawAnnotationsFromList([widgetAnnot]);
         annotationManager.updateAnnotation(widgetAnnot);
      }

   }
   const putDateField = async (_instance = instanceW) => {
      const { documentViewer, annotationManager,  Annotations } = _instance.Core;
      const { WidgetFlags } = Annotations;
      const doc = documentViewer.getDocument();
      const searchTerm = "\\d1\\";
      var pageNumber; // page to parse
      var quads;
      for (let pI = 1; pI <= doc.getPageCount(); pI++) {
         const pageText = await doc.loadPageText(pI);
         let startIndex = pageText.indexOf(searchTerm);
         let endIndex = 0;
         if (startIndex > -1) {
            pageNumber = pI;
            endIndex = startIndex + searchTerm.length;
            quads = await doc.getTextPosition(pageNumber, startIndex, endIndex);
            break;
         }
      }
      if (pageNumber > 0) {
         //console.log('quads :: ',quads);
         const fontOptions = {name: 'SansSerif',size: 12};
         const font = new Annotations.Font(fontOptions);
         const flags = new WidgetFlags();
         flags.set('Required', true);
         let dval = moment(new Date()).format("MM/DD/YY");
         // create a form field
         const field = new Annotations.Forms.Field("sign_date", {type: 'Tx', value: dval ,flags,font,
            actions: {
               F: [{name: 'JavaScript',javascript: 'AFDate_KeystrokeEx("MM/DD/YY");'}],
               K: [{name: 'JavaScript',javascript: 'AFDate_KeystrokeEx("MM/DD/YY");'}]
            }
         });
         const widgetAnnot = new Annotations.DatePickerWidgetAnnotation(field);
         // set position and size
         widgetAnnot.PageNumber = pageNumber;
         widgetAnnot.X = quads[0].x1;
         widgetAnnot.Y = quads[0].y1 - 20;
         widgetAnnot.Width = 245;
         widgetAnnot.Height = 30;
         //add the form field and widget annotation
         annotationManager.getFieldManager().addField(field);
         annotationManager.addAnnotation(widgetAnnot);
         annotationManager.drawAnnotationsFromList([widgetAnnot]);
         annotationManager.updateAnnotation(widgetAnnot);
      }
   };
   const putSignCommentField = async (_instance = instanceW) => {
      const { documentViewer, annotationManager, Annotations, } = _instance.Core;
      const { WidgetFlags } = Annotations;
      const doc = documentViewer.getDocument();
      const searchTerm = "\\c1\\";
      var pageNumber; // page to parse
      var quads;
      for (let pI = 1; pI <= doc.getPageCount(); pI++) {
         const pageText = await doc.loadPageText(pI);
         let startIndex = pageText.indexOf(searchTerm);
         let endIndex = 0;
         if (startIndex > -1) {
            pageNumber = pI;
            endIndex = startIndex + searchTerm.length;
            quads = await doc.getTextPosition(pageNumber, startIndex, endIndex);
            break;
         }
      }
      if (pageNumber > 0) {
         //console.log('quads :: ',quads);
         const fontOptions = {name: 'SansSerif',size: 12};
         const font = new Annotations.Font(fontOptions);
         const flags = new WidgetFlags();
         flags.set('Multiline', true);
         // create a form field
         const field = new Annotations.Forms.Field("signer_comment", {type: 'Tx', flags: flags,font});
         const widgetAnnot = new Annotations.TextWidgetAnnotation(field, { flags: flags });
         // set position and size
         widgetAnnot.PageNumber = pageNumber;
         widgetAnnot.X = quads[0].x1;
         widgetAnnot.Y = quads[0].y1-50;
         widgetAnnot.Width = 540;
         widgetAnnot.Height = 60;
         //add the form field and widget annotation
         annotationManager.getFieldManager().addField(field);
         annotationManager.addAnnotation(widgetAnnot);
         annotationManager.drawAnnotationsFromList([widgetAnnot]);
         annotationManager.updateAnnotation(widgetAnnot);
         //if date field have default value so we need to save pdf on amazon with date
         docTriggerChanges.current = true;
      }

   };
   const putSignCanvasField = async (_instance = instanceW) => {
      const { documentViewer, annotationManager,  Annotations } = _instance.Core;
      const { WidgetFlags } = Annotations;
      const doc = documentViewer.getDocument();
      const searchTerm = "\\s1\\";
      var pageNumber; // page to parse
      var quads;
      for (let pI = 1; pI <= doc.getPageCount(); pI++) {
         const pageText = await doc.loadPageText(pI);
         let startIndex = pageText.indexOf(searchTerm);
         let endIndex = 0;
         if (startIndex > -1) {
            pageNumber = pI;
            endIndex = startIndex + searchTerm.length;
            quads = await doc.getTextPosition(pageNumber, startIndex, endIndex);
            break;
         }
      }
      if (pageNumber > 0) {
         const flags = new WidgetFlags();
         flags.set('Required', true);
         // create a form field
         const field = new Annotations.Forms.Field("sign_canvas", { type: 'Sig', flags });
         const widgetAnnot = new Annotations.SignatureWidgetAnnotation(field, {
            appearance: '_DEFAULT',
            appearances: {
               _DEFAULT: {
                  Normal: {
                     offset: {
                        x: quads[0].x1,
                        y: quads[0].y1 - 35,
                     },
                  },
               },
            },
         });
         // set position and size
         widgetAnnot.PageNumber = pageNumber;
         widgetAnnot.X = quads[0].x1;
         widgetAnnot.Y = quads[0].y1 - 35;
         widgetAnnot.Width = 235;
         widgetAnnot.Height = 50;
         //add the form field and widget annotation
         annotationManager.getFieldManager().addField(field);
         annotationManager.addAnnotation(widgetAnnot);
         annotationManager.drawAnnotationsFromList([widgetAnnot]);
         annotationManager.updateAnnotation(widgetAnnot);
         field.addEventListener('change', async (action) => {
            console.log('sign change ', action);
         });
      }
   };
   const onClickSave = async (_instance = instanceW, signStatus) => {
      //console.log('ft :',signStatus,JSON.stringify(ftRecords[currentFTIndex]));
      /*if(signStatus === 'Signed'){
         onFieldTicketAction(fieldticketId , 'signed');
      }else{
         //Declined to Sign
         onFieldTicketAction(fieldticketId , 'completed');
      }
      //console.log('afterft :',signStatus,JSON.stringify(ftRecords[currentFTIndex]));
      return;*/
      const { documentViewer, annotationManager} = _instance.Core;
      const fieldManager = annotationManager.getFieldManager();
      const formFieldStatus = { sign_status: signStatus };
      fieldManager.forEachField(async field => {
         formFieldStatus[field.name] = field.getValue();
         if (field.name == 'sign_date') {
            //console.log('"YYYY-MM-DD".length :: '+"YYYY-MM-DD".length);
            if(field.getValue() && field.getValue() !== ''){
               formFieldStatus[field.name] = moment(field.getValue()).format("YYYY-MM-DD");
            }
         }
         if (field.name === 'sign_canvas') {
            if(field.widgets?.length > 0 ){
               const signWid = field.widgets[0];
               if(signStatus !== 'Signed'){
                  //console.log('signWid.annot :: ',signWid.annot);
                  if(signWid.annot){
                     await annotationManager.deleteAnnotation(signWid.annot);
                  }
               }
            }
         }
      });
      let reqFilled = fieldManager.areRequiredFieldsFilled();
      if (!reqFilled && signStatus === 'Signed') {
         toast.error('Singnature/Required Missing.');
         return;
      }
      setSpinner(true);
      const fName = fileTitle.current;
      const putOnAmazon = (docTriggerChanges.current === true);
      const doc = documentViewer.getDocument();
      const xfdfString = await annotationManager.exportAnnotations();
      const options = { xfdfString, flatten: true };
      const data = await doc.getFileData(options);
      const arr = new Uint8Array(data);
      const blob = new Blob([arr], { type: 'application/pdf' });
      const image = URL.createObjectURL(blob);
      //console.log('pdf :: ',image); 
      //console.log('formFieldStatus :: ',formFieldStatus);
      //console.log('putOnAmazon :: ',putOnAmazon);
      const formData = new FormData();
      formData.append("mergeDocument", blob, fName);
      formData.append("fileTitle", fName);
      formData.append("putOnAmazon", putOnAmazon); //_saveFile
      formData.append("formInputDetails", JSON.stringify(formFieldStatus));
      let reqUrl = `${fetchUrl}/save/fieldTicketSignature?attachId=${mergedAttachment.Id}&fieldticketId=${fieldticketId}`;
      if(canvasData?.orgUserkey){
         reqUrl += '&orgUserkey=' + canvasData?.orgUserkey;
      }
      await axios.post(reqUrl, formData).then((res) => {
         //console.log("count:::: " + JSON.stringify(res));
         if (res.status === 200) {
            if(signStatus === 'Signed'){
               isSigned.current = true;
               onFieldTicketAction(fieldticketId , 'signed');
            }
            setFtCompleted(true);
            onFieldTicketAction(fieldticketId , 'completed');
            toast.success(`Field Ticket has been ${signStatus}.`);
         }
      }).catch((err) => {
         console.log(err);
         toast.error('Save To Field Ticket Failed.');
         setSpinner(false);
      });
      setSpinner(false);
   };
   return (
      <>
         <div id={`${currentFTIndex}-viewcontainer-${fieldticketId}`} ref={viewer} style={{ position:'relative' ,height: '100%' }}>
            {showZoomActions && (
               <ZoomButtons zoomLevel={currentZoomLevel} />
            )}
         </div>
         <>
            <div ref={declineButtonRef } style={{ display: "none" }} onClick={() => { onClickSave(instanceW, 'Declined to Sign'); }}/>
            <div ref={signButtonRef} style={{ display: "none" }} onClick={() => { onClickSave(instanceW, 'Signed'); }}/>
         </>
      </>
   );
}