import React, { useEffect, useState } from "react";
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Button,
  Typography,
  Menu,
  MenuItem,
  Container,
  Divider,
  Avatar,
  Autocomplete,
  TextField,
  FormControl,
  Select
} from "@mui/material";
import {
  AccountCircle,
  Analytics,
  Help,
  AccountBalanceWallet,
} from "@mui/icons-material";
import axios from "axios";
import { fetchUrl } from "Urls";
import { GetToken } from "api";
import Headerstyle from "./Headerstyle";

const ResponsiveHeader = ({ current_user_data ,creditials , projectOptions , currentSite, setCurrentProjectEnvironment }) => {
  const [anchorElUser, setAnchorElUser] = useState(null);
  const handleOpenHelpMenu = (e) => {
    if(current_user_data?.username){
      setAnchorElUser(e);
    }
  };

  const handleCloseHelpMenu = async () => {
    setAnchorElUser(null);
  };
  const onSignOutClick = async () => {
    handleCloseHelpMenu();
    var req_url = `${fetchUrl}/signout?token=${creditials.token}&instanceUrl=${creditials.instanceUrl}`;
    if(creditials.refreshToken){
      req_url += `&refreshToken=${creditials.refreshToken}`;
    }
    await axios.get(req_url)
    .then(async (res) => {
      if (res?.status === 200 && res.data) {
        window.location = res.data;
      }
    }).catch((err) => {
      console.error(err);
    });
  };
  return (
    <Headerstyle>
      <AppBar position="static" sx={{ background: "#fff" }}>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Box sx={{ flexGrow: 0, marginLeft: "10px" }}>
              <IconButton sx={{ p: 0 }}>
                <AccountBalanceWallet sx={{ color: "fdsf#fff" }} />
                <Typography textAlign="center" sx={{ marginLeft: "10px", }} >
                  Document Repository
                </Typography>
              </IconButton>
            </Box>
            <Box sx={{ marginLeft: "20px" }}>
              <Autocomplete value={currentSite}
                getOptionLabel={(option) => option?.Name}
                id="combo-box-demo"
                onChange={(event, newValue) => {
                  if(newValue && newValue !== null){
                    setCurrentProjectEnvironment(newValue);
                  }
                }}
                options={projectOptions}
                sx={{ width: 300, color: "white" }}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" placeholder="Select Project" />
                )}
              /> 
            </Box>
            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }} />
            <Box sx={{ flexGrow: 0 }}>
              <IconButton sx={{ p: 0 }} onClick={(e)=>handleOpenHelpMenu(e)}>
                <AccountCircle sx={{ color: "dfsf#fff", fontSize: "xxx-large" }} />
                <Typography textAlign="center" sx={{ marginLeft: "10px", color: "fsdf#fff", }} >
                  {current_user_data?.username}
                </Typography>
              </IconButton>
              {Boolean(anchorElUser) &&
                <Menu className="helpMenu custommenu"
                  sx={{
                    mt: "45px",
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 28,
                      right: 150,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{ vertical: "top", horizontal: "right", }}
                  keepMounted
                  transformOrigin={{ vertical: "top", horizontal: "right", }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseHelpMenu}
                >
                  <MenuItem  onClick={onSignOutClick}>
                    <Typography textAlign="center">Sign Out</Typography>
                  </MenuItem>
                </Menu>
              }
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </Headerstyle>
  );
};
export default ResponsiveHeader;
