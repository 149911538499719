import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  attachmentIdToThumbnail:{},
  folderIdToData:{},
  sheetVersionSetActiveFilters:{},
  sheetTypeActiveFilters:{},
  sheetDisciplineActiveFilters:{},
  sheetReviewStatusActiveFilters:{},
  sheetUpdatedByActiveFilters:{}
}

export const appStateSlice = createSlice({
  name: "appState", 
  initialState,
  reducers: {
    setFolderIdToData:(state , action)=>{
      const {folderId , data } = action.payload;
      //console.log('setFolderIdToData ',folderId , data?.length);
      if(!state.folderIdToData){
        state.folderIdToData = {};
      }
      state.folderIdToData[folderId] = data;
    },
    setAttachmentIdToThumbnail:(state , action)=>{
      const {projectId , data } = action.payload;
      if(!state.attachmentIdToThumbnail){
        state.attachmentIdToThumbnail = {};
      }
      state.attachmentIdToThumbnail[projectId] = data ? data : {};
    },
    setSheetVersionSetActiveFilters:(state , action)=>{
      const {projectId , data } = action.payload;
      if(!state.sheetVersionSetActiveFilters){
        state.sheetVersionSetActiveFilters = {};
      }
      state.sheetVersionSetActiveFilters[projectId] = data;
    },
    setSheetTypeActiveFilters:(state , action)=>{
      const {projectId , data } = action.payload;
      if(!state.sheetTypeActiveFilters){
        state.sheetTypeActiveFilters = {};
      }
      state.sheetTypeActiveFilters[projectId] = data;
    },
    setSheetDisciplineActiveFilters:(state , action)=>{
      const {projectId , data } = action.payload;
      if(!state.sheetDisciplineActiveFilters){
        state.sheetDisciplineActiveFilters = {};
      }
      state.sheetDisciplineActiveFilters[projectId] = data;
    },
    setSheetReviewStatusActiveFilters:(state , action)=>{
      const {projectId , data } = action.payload;
      if(!state.sheetReviewStatusActiveFilters){
        state.sheetReviewStatusActiveFilters = {};
      }
      state.sheetReviewStatusActiveFilters[projectId] = data;
    },
    setSheetUpdatedByActiveFilters:(state , action)=>{
      const {projectId , data } = action.payload;
      if(!state.sheetUpdatedByActiveFilters){
        state.sheetUpdatedByActiveFilters = {};
      }
      state.sheetUpdatedByActiveFilters[projectId] = data;
    },
  }
})

export const {  setFolderIdToData,
                setAttachmentIdToThumbnail,
                setSheetVersionSetActiveFilters,
                setSheetTypeActiveFilters,
                setSheetDisciplineActiveFilters,
                setSheetReviewStatusActiveFilters,
                setSheetUpdatedByActiveFilters } = appStateSlice.actions

export default appStateSlice.reducer
