//import { headerProps, cellProps } from "../uploadlogStyles";
import { useTable, useBlockLayout, useResizeColumns, useSortBy } from "react-table";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Spinner from "components/spinner";
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
function UploadLogsTable({
    columns,
    data,
    NoDataComponent,
    loader,
}) {
    // Use the state and functions returned from useTable to build your UI

    const { getTableProps, headerGroups, rows, prepareRow, setPageSize } = useTable(
        {
            columns, data, initialState: {
            /*    sortBy: [
                    {
                        id: "name",
                        desc: false,
                    },
                ],*/
            },
        },
        useBlockLayout,
        useResizeColumns,
        useSortBy
    );
    
     const headerProps = (props, { column }) =>
    getStyles(props, column.align);
   const cellProps = (props, { cell }) =>
    getStyles(props, cell.column.align);
  
   const getStyles = (props, align = "left") => [
    props,
    {
      style: {
        // justifyContent: align === "right" ? "flex-end" : "flex-start",
        justifyContent: "space-between",
        alignItems: "center",
        display: "flex",
      },
    },
  ];
    // Render the UI for your table
    return (
        <div {...getTableProps()} className="table">
            <div className="thead">
                {headerGroups.map((headerGroup) => (
                    <div {...headerGroup.getHeaderGroupProps({
                        // style: { paddingRight: '15px' },
                    })} className="tr">
                        {headerGroup.headers.map((column) => (
                            <div {...column.getHeaderProps(headerProps)} className="th">
                                <div {...column.getHeaderProps(column.getSortByToggleProps())}>
                                    {column.render("Header")}
                                    {/* Add a sort direction indicator */}
                                    <span>
                                        {column.isSorted ? (
                                            column.isSortedDesc ? (
                                                <KeyboardArrowDownIcon />
                                            ) : (
                                                <KeyboardArrowUpIcon />
                                            )
                                        ) : (
                                            ""
                                        )}
                                    </span>
                                </div>
                                {/* Use column.getResizerProps to hook up the events correctly */}
                                {column.canResize && (
                                    <div {...column.getResizerProps()} className={`resizer ${column.isResizing ? "isResizing" : ""}`}
                                    />
                                )}
                            </div>
                        ))}
                    </div>
                ))}
            </div>
            <div className="tbody">
                {rows?.length === 0 && (
                    <div
                        className="no_data_found"
                        style={{ textAlign: "center", marginTop: "200px" }}
                    >
                        {NoDataComponent}
                    </div>
                )}
                {loader && <Spinner />}
                {rows.map((row) => {
                    prepareRow(row);
                    return (
                        <div {...row.getRowProps()} className="tr">
                            {row.cells.map((cell, index) => {
                                return (
                                    <div {...cell.getCellProps(cellProps)} className="td">
                                        {cell.render("Cell")}
                                    </div>
                                );
                            })}
                        </div>
                    );
                })}
            </div>
            <Tooltip  id={`tooltip-name`} place="top" type="dark" effect="float">
        {(tooltipData) => tooltipData} {/* Show the 1st column data in the tooltip message */}
      </Tooltip>
        </div>
    );
}
export default UploadLogsTable;