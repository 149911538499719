import React, { useState } from "react";

import { styled } from "@mui/material/styles";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Button,
  Box,
  MenuItem,
  FormControl,
  Select,
  InputBase,
  Grid,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import PublicIcon from "@mui/icons-material/Public";
import DoneIcon from "@mui/icons-material/Done";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import InfoIcon from "@mui/icons-material/Info";
import moment from "moment";

import Tabs from "../Shared/Tabs";
import { fetchUrl } from "Urls";
import { toast } from "react-toastify";

const shareOptions = [
  {
    id: "email",
    value: "email",
    label: "Share with project members",
    subtitle: "Project members with view-only access or higher.",
    icon: <PeopleAltIcon fontSize="16px" style={{ fill: "#1976d2" }} />,
  },
  {
    id: "public",
    value: "public",
    label: "Share with public",
    subtitle: "Anyone with a link has access.",
    icon: <PublicIcon fontSize="16px" style={{ fill: "#01b701" }} />,
  },
];

const ShareEmail = () => {
  const [emails, setEmails] = useState("");

  return (
    <Box height="300px" width="100%" pt={1}>
      <Grid container>
        <Grid item xs={12}>
          <BootstrapInput
            fullWidth
            placeholder="Enter names, roles, companies, or email addresses"
            value={emails}
            onChange={(e) => setEmails(e.target.value)}
            className="share-input-value"
          />
        </Grid>
        <Grid item xs={12}>
          
            <BootstrapInput
              fullWidth
              placeholder="Add note (Optional)"
              multiline
              rows={2}
            />
        </Grid>
        <Grid container item xs={12} justifyContent="flex-end">
          <Button variant="contained" disabled={!emails}>
            Send
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

const SharePublic = ({ handleClose, currentFileId }) => {
  return (
    <Box height="370px" width="100%" pt={1}>
      <Grid container>
        <Grid
          container
          item
          xs={12}
          justifyContent="space-between"
          alignItems="center"
          className="share-copy"
        >
          <span className="share-copy-meta">
            <DoneIcon style={{ fill: "#01b701", marginRight: "4px" }} />
            Generated 1 link
          </span>
          <Button variant="text" className="txt-btn">
            Copy all
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Box height={300} width="100%">
            <Grid container alignItems="center">
              <Grid item xs={3} className="share-file-name">
                <FolderOpenIcon
                  style={{ fill: "#4a4a4a", marginRight: "4px" }}
                />{" "}
                {currentFileId?.name}
              </Grid>
              <Grid container item xs={9} justifyContent="flex-end">
                <BootstrapInput
                  placeholder="Enter names, roles, companies, or email addresses"
                  className="share-input-copy"
                  value={`${fetchUrl}/share/${currentFileId?.id}`}
                />
                <Button
                  variant="contained"
                  onClick={() => {
                    navigator.clipboard.writeText(
                      `${fetchUrl}/share/${currentFileId?.id}`
                    );
                    toast.success("Copied sucessfully");
                  }}
                >
                  Copy
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid
          container
          item
          xs={12}
          justifyContent="space-between"
          alignItems="center"
        >
          <span>
            Share current version{" "}
            <select className="share-version">
              <option>Latest</option>
              <option>Previous</option>
            </select>
            <InfoIcon
              fontSize="small"
              style={{ fill: "#4a4a4a", marginLeft: "4px" }}
            />
          </span>
          <Button variant="text" className="txt-btn">
            Delete links and close
          </Button>
          <Button variant="outlined" onClick={handleClose}>
            Close
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "4px 26px 4px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}));

const ShareDialog = ({ open, handleClose, currentFileId }) => {
  const [shareVia, setShareVia] = useState("public");

  const handleChange = (e) => {
    setShareVia(e.target.value);
  };

  const tabOptions = [
    {
      label: "Email",
      component: <ShareEmail />,
    },
    {
      label: "Link",
      component: (
        <SharePublic handleClose={handleClose} currentFileId={currentFileId} />
      ),
    },
  ];

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="share-dialog-title"
      aria-describedby="share-dialog-description"
      fullWidth
      maxWidth="md"
    >
      <DialogTitle id="share-dialog-title">
        Share
        <IconButton aria-label="close" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Box p={1} width="100%" className="share-doc">
          <FormControl fullWidth variant="standard">
            <Select
              labelId="share-via-select-label"
              id="share-via-select"
              value={shareVia}
              label="Share"
              onChange={handleChange}
              input={<BootstrapInput />}
            >
              {shareOptions.map((s) => (
                <MenuItem value={s.value} key={s.id}>
                  <div className="share-menu-item">
                    <div className="share-menu-item-icon">{s.icon}</div>
                    <div className="share-menu-item-text">
                      {s.label}
                      <span>{s.subtitle}</span>
                    </div>
                  </div>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {shareVia === "public" && (
            <span className="share-doc-link-expiry">
              Link expires on{" "}
              <span className="share-doc-link-date">
                {" "}
                {moment().add(7, "days").format("LL")}{" "}
              </span>
            </span>
          )}
          <Tabs options={tabOptions} />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ShareDialog;
