import { configureStore } from "@reduxjs/toolkit"
import navStateSlice from "./features/navStateSlice"
import appStateSlice from "./features/appStateSlice"
import userDataReducer from './features/userDataSlice';
import canvasDataSlice from './features/canvasDataSlice';


export const store = configureStore({
  reducer: {
    navState: navStateSlice,
    appState: appStateSlice,
    userData: userDataReducer,
    canvasData: canvasDataSlice
  }
})
