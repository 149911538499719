import styled from 'styled-components';

const PdfContainerCss = styled.div`

.main__body_inner{
    display: flex;
}
h2.MuiTypography-root.MuiTypography-h6.MuiDialogTitle-root {
    background: #ededed;
    position: relative;
}
.stepper_div.MuiBox-root.css-8atqhb span.MuiStepLabel-label.Mui-active {
    color: #1976d2;
    font-weight: 600;
}
.MuiStepConnector-alternativeLabel span {
    height: 12px;
    background: #ededed;
    border: 1px solid #ccc;
    position: relative;
}
.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root {
    color: #fff;
    background:#ededed;
    border: 1px solid #bebebe;
    border-radius: 30px;
    padding: 4px;
}
svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiStepIcon-root text.MuiStepIcon-text {
    background: #fff;
    border: 1px solid #dfdfdf;
    padding: 10px;
    fill: #000;
}
.MuiStepIcon-root.Mui-active circle {
    fill: #fff;
}
.stepper_div.MuiBox-root {
    max-width: 980px;
    margin: 0 auto;
}
h2.MuiTypography-root.MuiTypography-h6.MuiDialogTitle-root .continueButton {
    position: absolute;
    right: 90px;
    top: 50%;
    transform: translateY(-50%);
}
.continueButton button.MuiButton-root {
    background: var(--btn-blue);
    color: #fff;
    border-radius: 0;
    padding: 8px 15px; 
}
h2.MuiTypography-root.MuiTypography-h6.MuiDialogTitle-root button.MuiButtonBase-root.MuiIconButton-root {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
}
.uploadTitle h3 {
    font-size: 30px;
    font-weight: 600;
    margin: 0 0;
}
.dialog_paper_custom.MuiDialog-paper .MuiDialogContent-root.MuiDialogContent-dividers {
    padding: 0;
}
.uploadTitle {
    padding: 15px 20px;
    box-shadow: 0 1px 6px 2px rgb(0 0 0 / 16%);
}
.folderTitle_section {
    padding: 10px 20px;
}
.icon svg {
    stroke: hsl(204deg 2% 55%);
    color: hsl(213deg 21% 90%);
}
.folder,.fileLoding_addFile {
    display: flex;gap: 20px;
}
.folderTitle_section {
    display: flex;
    justify-content: space-between;
}
.fileLoding_addFile button {
    color: var(--btn-blue);
    padding: 5px 10px;
    border: 1px solid #ddd;    background: #fff;
}
span.MuiCheckbox-root.MuiCheckbox-colorPrimary {
    padding: 10px 18px;
}

.MuiDataGrid-columnHeadersInner {
    background: #fff;
}
.MuiDataGrid-columnHeaderTitle {
    font-weight: 600;
}
svg.MuiSvgIcon-root.MuiDataGrid-iconSeparator {
    fill: hsl(195deg 7% 89%);
}

.MuiDataGrid-row {
    padding: 0 4px;
}
.main-prog {
    padding: 10px 50px 40px;
}
.MuiStepConnector-root.MuiStepConnector-horizontal.MuiStepConnector-alternativeLabel {
    margin: 0 -5px;
    z-index: 0;
}
.css-vnkopk-MuiStepLabel-iconContainer.MuiStepLabel-alternativeLabel { 
    z-index: 1;
}
.MuiStepConnector-horizontal.MuiStepConnector-alternativeLabel.Mui-completed span.MuiStepConnector-line {
    background: var(--btn-blue);
}
.Finished svg.MuiSvgIcon-root {
    fill: #008000;
    font-size: 180px;
}
.Finished h3 {
    font-size: 26px;
    text-transform: uppercase;
    letter-spacing: 14px;
    font-weight: 800;
    margin-top: 0;
}
.loaderSection {
    display: flex;
    align-items: center;
    gap:10px;
}
.progrssSection_div {
    max-width: 500px;
    margin: 0 auto;
    text-align: center;
        
    .progress_bar.MuiBox-root {
        margin: 0 auto;
    }               
}

`;

export default PdfContainerCss; 