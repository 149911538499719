import { GetToken } from "api";
import { useState, useEffect } from "react";
import _ from "lodash";
import axios from "axios";
import { fetchUrl } from "Urls";

const useFormTemplateTabHook = (props) => {
  const { projectId } = props;

  const [showSpinner, setSpinner] = useState(false);
  const [createPopEnable, setCreatePopup] = useState(false);
  // filter selection
  const [credential, setCredential] = useState(props?.credentials);

  const [forTabHook, setFromTabHook] = useState(props?.forTabHook);

  useEffect(() => {
    if(projectId){ // && credential?.projectId != projectId
      getInitData();
    }
  }, [projectId]);
  useEffect(() => {
    //console.log('projectId :: '+projectId);
    setFromTabHook(props?.forTabHook);
  }, [props?.forTabHook]);
  
  async function getInitData() {
    if(projectId){
      setSpinner(true);
      //console.log('useFormTemplateTabHook :: '+projectId);
      await fetchFormLists(credential);
    }
    setSpinner(false);
  };

  function _handleOpenCreate(status) {
    setCreatePopup(status);
  }

  async function fetchFormLists(_credential = credential) {
    setSpinner(true);
      await props?.forTabHook?._fetchFormLists(_credential);
    setSpinner(false);
  }
  const closeAndRefresh= async function (refresh) {
    console.log('closeAndRefresh :: '+refresh);
    _handleOpenCreate(false);
    if(refresh == true){
      await fetchFormLists(credential);
    }
  }
  return { createPopEnable, tableFormList : props?.tableFormList, _handleOpenCreate, credential,
          closeAndRefresh, showSpinner, setSpinner , forTabHook };
  //return { createPopEnable, _handleOpenCreate, credential, closeAndRefresh, showSpinner, setSpinner , templateTypeOptions , defaultTemplateType};
};
export default useFormTemplateTabHook;
