import React, { useState } from 'react';
import  _, {  find} from "lodash";
import DropdownSelectStyle from "./DropdownSelectStyle";
import { Checkbox, FormControlLabel,List,ListItem } from "@mui/material";

function DropdownSelect ({ keyProp , labelProp, isValueArray , multiselect,options, onChange, activeSelections }) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState(multiselect ? (activeSelections ? activeSelections : []) : (activeSelections ? [activeSelections] : []));
  //console.log('selectedItems :: ',JSON.stringify(selectedItems));
  const [valueField, setValueField] = useState(keyProp ? keyProp : 'value');
  const [labelField, setLabelField] = useState(labelProp ? labelProp : 'label');

  const onSelectionChange = (option) => {
    let filterMArkup = [];
    let toSend = [];
    const selcted = isValueArray ? selectedItems.includes(option) : find( selectedItems ,option) ? true : false ;
    //console.log('onSelectionChange :: ',selcted);
    if (selcted) {
      filterMArkup = selectedItems?.filter((item) => item !== option);
      if(!multiselect){
        filterMArkup = [];
      }
    } else {
      filterMArkup = [...selectedItems, option];
      if(!multiselect){
        filterMArkup = [option];
      }
    }
    toSend =filterMArkup;
    if(!multiselect){
      toSend = selcted ? undefined : option ;
    }
    setSelectedItems(filterMArkup);
    onChange(toSend);
    if(filterMArkup?.length == 0 || !multiselect){
      setIsOpen(false);
    }  
  };
 
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleCancelIconClick = (option) => {
    if(multiselect){
      let filterMArkup = selectedItems.filter((item) => item !== option);
      // Remove the value from selectedOptions array
      setSelectedItems(filterMArkup);
      onChange(filterMArkup);
      if(filterMArkup?.length == 0){
        setIsOpen(false);
      }
    }else{
      setSelectedItems([]);
      onChange(undefined);
      setIsOpen(false);
    }
  };

  const selectedCount = selectedItems?.length ;

  return (
    <DropdownSelectStyle>
      <div className={`select-dropdown ${isOpen ? 'open' : ''}`}>
        <div className="dropdown-box" onClick={toggleDropdown}>
          {isOpen ? (
            <>
              {selectedCount === 1 && (
                <div className="selected-values-container">
                  {selectedItems.map((option) => {
                    const vall = isValueArray ? option :option[valueField];
                    const labl = isValueArray ? option :option[labelField];
                    return (
                    <div key={vall} className="selected-value">
                      {labl}
                      <span className="cancel-icon" onClick={(event) => {event.preventDefault();event.stopPropagation(); handleCancelIconClick(option)}} >
                        <img src="/close.svg" alt="Close" />
                      </span>
                    </div>
                  )})}
                </div>
              )}
              {selectedCount > 1 && (
                <span className="selected-count">{selectedCount} Selected</span>
              )}
              {selectedCount < 1 && (
                <span className="selected-count"> Select</span>
              )}
            </>
          ) : (
            <>
              {selectedCount < 1 && (<span className="selected-count"> Select</span>)}
              <div className="selected-values-container">
                {selectedCount > 0 ? (
                  selectedItems.map((option) => {
                    const vall = isValueArray ? option :option[valueField];
                    const labl = isValueArray ? option :option[labelField];
                    return (
                    <div key={vall} className="selected-value">
                      {labl}
                      <span className="cancel-icon" onClick={(event) => {event.preventDefault();event.stopPropagation(); handleCancelIconClick(option)}} >
                        <img src="/close.svg" alt="Close" />
                      </span>
                    </div>
                  )})
                ) : (
                  <span className="placeholder"></span>
                )}
              </div>
            </>
          )}
          <span className="dropdown-icon">
            <img src="/down.svg" alt="Dropdown" />
          </span>
        </div>
        {isOpen && (
          <>
            <List className="dropdown-menu" >
              {options.map((option) => {
                const vall = isValueArray ? option : option[valueField];
                const labl = isValueArray ? option : option[labelField];
                const checkd = isValueArray ? selectedItems.includes(option) : find(selectedItems, option) ? true : false;
                return (
                  <ListItem key={vall} className={'option-item' + (checkd ? ' selected' : '')}>
                    <FormControlLabel
                      style={{ width: "100%" }}
                      value={vall}
                      control={
                        <Checkbox
                          style={{ padding: "0px", marginRight: "5px" }}
                          className={multiselect ? '' : ' check-hide'}
                          checked={checkd}
                          onClick={() => onSelectionChange(option)}
                        />
                      }
                      label={labl}
                    />
                  </ListItem>)
              })}
            </List>
            <div className="outer-div" onClick={()=>setIsOpen(false)}></div>
          </>
        )}
      </div>
    </DropdownSelectStyle>
  );
}
export default DropdownSelect;