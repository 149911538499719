import React, { useEffect, useState, useRef } from "react";
import {
  Grid, Button, TextField, Dialog, Box, Card
} from "@mui/material";
import ToastComponent from "components/ToastComponent";
import { MoreHoriz } from "@mui/icons-material";
import axios from "axios";
import { fetchUrl } from "Urls";
import SaveIssueDialog from "components/IssuesComponent/SaveIssueDialog"
import Spinner from "components/spinner";
import heic2any from "heic2any";

const NotesAndAttachment = ({ handleChange, isFormCompleted, userValidationMatch, req, ...props }) => {
  const [showSpinner, setSpinner] = useState(false);
  const { formId, ansObj, credential, checklistFormobj } = props;
  const [sobj, setSobj] = useState(ansObj);
  //const [notes , setNotes] = useState(null);
  const [showNotes, setShowNotes] = useState(false);
  const [createIssue, setCreateIssue] = useState(false);
  const [showUpload, setShowUpload] = useState(true);
  const [showIssues, setShowIssues] = useState(true);
  const [openImage, setOpenImage] = useState(false);
  const [selectedImgObj, setSelectedImgObj] = useState(null);
  var notes = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedAttachId, setSelectedAttachId] = useState(null);
  const [toastMessage, setToastMessage] = useState();
  const inputRef = useRef(null);
  const linkedDocumentOption = [
    { value: "Sheet", label: "Sheet" },
    { value: "File", label: "File" },
    { value: "None", label: "None" },
  ];

  const handleClickMenu = (event, attId) => {
    event.preventDefault();
    event.stopPropagation();
    setSelectedAttachId(attId);
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    // console.log("handleCloseMenu handleCloseMenu");
    setSelectedAttachId(null);
    setAnchorEl(null);
    //setSelectedMenuRow(null);
  };
  const handleClose = (refresh) => {
    setCreateIssue(false);
  };
  useEffect(() => {
    if (ansObj.Id != sobj?.Id && ansObj.Id && ansObj.Id != '' && (!sobj?.Id)) {
      let cloneAnsObj = JSON.parse(JSON.stringify(sobj));
      cloneAnsObj.Id = ansObj.Id;
      setSobj(cloneAnsObj);
    }
  }, [ansObj.Id]);
  useEffect(() => {
    if (sobj?.Notes__c && sobj?.Notes__c != '') {
      setShowNotes(true);
      notes.current = sobj?.Notes__c;
    }
  }, []);
  
  const uploadMedia = async (options) => {
    setSpinner(true);
    const filesArray = Array.from(options);
    let cloneAnsObj = JSON.parse(JSON.stringify(sobj));
    let imageCount = 0;
    const convertedFiles = await Promise.all(
      filesArray.map(async (file) => {
        if (file.type === "image/heic" || file.type === "image/heif") {
          try {
            const convertedBlob = await heic2any({
              blob: file,
              toType: "image/jpg",
            });
            const newFile = new File([convertedBlob], file.name.replace(/\.(heic|heif|HEIC|HEIF)$/, ".jpg"), {
              type: "image/jpeg",
              lastModified: file.lastModified,
            });
            return newFile;
          } catch (error) {
            console.error("Error converting HEIC/HEIF to JPG:", error);
            setSpinner(false);
            return null;
          }
        }
        return file;
      })
    );

    const validFiles = convertedFiles.filter(Boolean);

    if (validFiles && validFiles.length > 0) {
      for (let f of validFiles) {
        const formData = new FormData();
        formData.append("attachFile", f, f.name);
        formData.append("mimeType", f.type);
        formData.append("s3Key", `${formId}/response/${sobj?.Id}`);
        formData.append('projectId', credential?.projectId);
        formData.append('uploadInMedia', 'yes');
        imageCount++;

        await axios.post(`${fetchUrl}/upload/attachment/Form_Response__c/${sobj?.Id}`, formData)
          .then(async (res) => {
            if (res.status == 200) {
              if (!cloneAnsObj?.attachments) {
                cloneAnsObj.attachments = [];
              }
              let cloned = JSON.parse(JSON.stringify(res.data.ret));
              cloned.id = res.data.ret.Id;
              cloneAnsObj.attachments.push(cloned);
              setSobj(cloneAnsObj);
              if (imageCount === validFiles.length) {
                handleChange({ target: { name: 'attachments', value: cloneAnsObj.attachments } });
                setSpinner(false);
              }
            }
          }).catch((err) => {
            console.log("err", err);
            setSpinner(false);
          });
        
      }
    }
  };
  const handleDeleteImage = async (id) => {
    axios.delete(`${fetchUrl}/delete/Amazon_S3_Attachment__c/${id}?token=${credential?.token}&instanceUrl=${credential?.instanceUrl}`)
    .then((res) => {
      //console.log("count:::: " + JSON.stringify(res));

      let cloneAnsObj = JSON.parse(JSON.stringify(sobj));
      if (!cloneAnsObj?.attachments) {
        cloneAnsObj.attachments = [];
      }
      const fInd = cloneAnsObj?.attachments?.findIndex((res) => res.Id == id);
      if (fInd > -1) {
        cloneAnsObj.attachments.splice(fInd, 1);
        setSobj(cloneAnsObj);
        handleChange({ target: { name: 'attachments', value: cloneAnsObj.attachments } });
      }
      setSpinner(false);
    }).catch((err) => {
      console.log(err);
      setSpinner(false);
    });
  };
  async function onClickSave(issueObj, info, checkSpinner) {
    const formData = new FormData();
    //let issueObj = sfIssueObj;
    formData.append("issueObj", JSON.stringify(issueObj));
    let tM;
    await axios.post(`${fetchUrl}/saveIssue/${credential?.projectId}?token=${credential?.token}&instanceUrl=${credential?.instanceUrl}`, formData)
      .then(async (res) => {
        if (res.status === 200) {
          setCreateIssue(false);
          let cloneAnsObj = JSON.parse(JSON.stringify(sobj));
          if (!cloneAnsObj?.issues) {
            cloneAnsObj.issues = [];
          }
          let cloned = JSON.parse(JSON.stringify(issueObj));
          cloned.id = res.data.id;
          cloned.Numbering_Sequence__c = await getIssueNumberId(cloned.id);
          cloneAnsObj.issues.push(cloned);
          setSobj(cloneAnsObj);
          handleChange({ target: { name: 'issues', value: cloneAnsObj.issues } });
          tM = info?.message;
          if (!tM && info?.saveAs) {
            if (info?.saveAs == 'Owner Draft new') {
              tM = "Issue has been created";
            } else if (info?.saveAs == "Owner DraftId") {
              tM = "Issue has been saved";
            } else if (info?.saveAs == 'Owner Publish') {
              tM = `Issue has been created to Question ${sobj?.Numbering_Sequence__c}.`;
            }
          }
          if (tM) {
            setToastMessage(tM);
          }
        }
      })
  }
  async function getIssueNumberId(recId) {
    const reqBody = { fields: ['Numbering_Sequence__c'], filter: `Id='${recId}'` };
    return await axios.post(`${fetchUrl}/query/Issue__c?token=${credential?.token}&instanceUrl=${credential?.instanceUrl}`, reqBody)
      .then(async (res) => {
        if (res.status === 200) {
          const re_data = res.data;
          if (re_data.status == 200) {
            return re_data.records[0].Numbering_Sequence__c;
          }
        }
        return undefined;
      }).catch(e => {
        console.log("error >>>>>>>>>>>>", JSON.stringify(e));
        console.error(e);
        return undefined;
      });
  }
  return (
    <>
      <div>
      {showSpinner && <Spinner />}
        <Grid container rowSpacing={2}>
          {!isFormCompleted &&
            <Grid item xs={12}>
              <div style={{ display: "flex", marginTop: "10px" }} >
                <Button className="custom_button cancel_button" disabled={(isFormCompleted || !userValidationMatch)} sx={{'&.MuiButtonBase-root.Mui-disabled': {background: "white", border:"1px solid #BABABA", color: "#BABABA"}}}
             onClick={() => {
                  inputRef.current.click();
                }} >
                  Attach Image
                </Button>
                <Button sx={{'&.MuiButtonBase-root.Mui-disabled': {background: "white", border:"1px solid #BABABA", color: "#BABABA"}}} disabled={(isFormCompleted || !userValidationMatch)} className="custom_button cancel_button" onClick={() => {
                  if (!isFormCompleted) {
                    setCreateIssue(true);
                  }
                }} >
                  Create Issue
                </Button>
                <Button sx={{'&.MuiButtonBase-root.Mui-disabled': {background: "white", border:"1px solid #BABABA", color: "#BABABA"}}} className="custom_button cancel_button" disabled={(isFormCompleted || !userValidationMatch)} onClick={() => {
                  if (!isFormCompleted) {
                    if (notes.current && notes.current != '') {
                      setShowNotes(true);
                      return;
                    }
                    setShowNotes(!showNotes);
                  }
                }} >
                  Add Notes
                </Button>

              </div>
            </Grid>
          }
          {sobj && sobj?.attachments && sobj?.attachments?.length > 0 &&
            <Box className="main_gridview" sx={{ flexGrow: 1 }} style={{ marginTop: "20px", border: "1px solid #EAEAEA", backgroundColor: "#F8F8F8", padding: "12px", width: "100%" }}>
              <div style={{ display: "flex", justifyContent: "space-between", color: "#505050" }}>
                <div>Images ({sobj?.attachments.length})</div>
                <div onClick={() => setShowUpload(!showUpload)} style={{ cursor: "pointer" }}>
                  <img src={showUpload ? "/up.svg" : "/down.svg"} alt="upicon" />
                </div>
              </div>
              {showUpload &&
                <Grid container spacing={1} sx={{ marginTop: "10px", marginLeft: "0px" }}>
                  {sobj?.attachments?.map((val, index) => {
                    return (
                      <Card className="form_image" style={{ width: "120px", height: "120px", marginRight: "8px" }}>
                        {!isFormCompleted && userValidationMatch &&
                          <Box className="on_hover_form_image">
                            <div style={{ marginLeft: "12px", cursor: "pointer" }}>
                              <img src="/close.svg" alt="closeIcon" style={{ height: "16px", width: "16px", background: "white", border: "1px solid #D8D8D8" }} onClick={() => handleDeleteImage(val.id)} />
                            </div>
                          </Box>
                        }
                        <div onClick={()=> setOpenImage(val.fileurl)}>
                          <img style={{ margin: "8px", width: "104px", height: "81px" }} src={val.fileurl} alt="thumbnail loading....." onClick={()=> setOpenImage(val.fileurl)}/>
                        </div>
                        <div style={{ margin: "0px 8px 8px 8px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", color: "#505050" }} onClick={()=> setOpenImage(val.fileurl)}>
                          {val.File_Name__c}
                        </div>
                      </Card>
                    )
                  })}
                </Grid>
              }
            </Box>
          }
          {sobj && sobj?.issues && sobj?.issues?.length > 0 &&
            <Box className="main_gridview" sx={{ flexGrow: 1 }} style={{ marginTop: "20px", border: "1px solid #EAEAEA", backgroundColor: "#F8F8F8", padding: "12px" }}>
              <div style={{ display: "flex", justifyContent: "space-between", color: "#505050" }}>
                <div>Issues ({sobj?.issues.length})</div>
                <div onClick={() => setShowIssues(!showIssues)} style={{ cursor: "pointer" }}>
                  <img src={showUpload ? "/up.svg" : "/down.svg"} alt="upicon" />
                </div>
              </div>

              <Grid container spacing={1} sx={{ marginTop: "10px", marginLeft: "0px" }}>
                {sobj?.issues?.map((val, index) => {
                  return (
                    <div className="form_image" style={{ width: "100%", height: "auto", padding: "8px", marginBottom: "12px" }}>

                      <div style={{ colo: "#505050" }}>
                        {val?.Numbering_Sequence__c}
                      </div>
                      <div style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", color: "#00000", fontWeight: 400 }}>
                        {val?.Name}
                      </div>
                    </div>
                  )
                })}
              </Grid>
            </Box>
          }
          {!isFormCompleted && showNotes &&
            <div className="searchField" style={{ width: "100%", paddingTop: "16px" }}>
              <div style={{ marginBottom: "4px", color: "#505050" }}>Notes</div>
              <div>
                <TextField id="name" variant="outlined" placeholder="Notes" defaultValue={sobj?.Notes__c}
                  sx={{ "& .MuiInputBase-root": { color: "#505050", height: "37px", padding: "4px", marginBottom: "10px", width: "100%" }, }}
                  className="Searchbar-field"
                  inputProps={{ maxLength: 255 }}
                  onChange={(e) => {
                    if (!isFormCompleted) {
                      let fff = sobj; fff.Notes__c = e?.target?.value; setSobj(fff);
                    }
                  }}
                  onKeyDown={(event) => {
                    if (event.key.toLowerCase() != "tab") {
                      event.stopPropagation();
                    }
                  }}
                  onBlur={(e) => {
                    if (!isFormCompleted) {
                      //console.log('e?.target?.value :: '+e?.target?.value);
                      let val = e?.target?.value;
                      if (val === '' || !val) {
                        val = null;
                      }
                      notes.current = val;
                      handleChange({ target: { name: 'Notes__c', value: val } });
                      setShowNotes(val == null ? false : true);
                    }
                  }} />
              </div>
            </div>
          }
          {isFormCompleted && sobj?.Notes__c &&
            <div style={{ paddingTop: "20px" }}>
              <div style={{ color: "#00000", fontWeight: 600, fontSize: "14px" }}>Notes</div>
              <div style={{ color: "#505050", fontWeight: 400, fontSize: "14px" }}>{sobj?.Notes__c}</div>
            </div>
          }

        </Grid>
        {createIssue &&
          <SaveIssueDialog
            onCancel={() => {
              handleClose(false);
            }}
            responeId={sobj?.Id}
            saveCallback={onClickSave}
            credential={credential}
            linkedDocumentOption={linkedDocumentOption}
            callFrom="FormsComponent"
          />
        }
        {openImage &&
        <Dialog fullWidth open={true}>
        <div style={{ position: 'relative' }}>
          <img src={openImage} alt="openImage" style={{ height: '536px', width: '688px' }} />
          <img
            src="/closeWhiteIcon.svg"
            alt="closeIcon"
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              cursor: 'pointer',
              padding: "10px",
              borderRadius: "50px",
              backgroundColor: "#000000"
            }}
            onClick={()=> setOpenImage(null)}
          />
        </div>
      </Dialog>
        }
        <input
          style={{ display: 'none' }}
          ref={inputRef}
          type="file"
          multiple
          accept=".jpeg, .jpg, .png, .gif, .heic, .heif"
          onChange={(e) => uploadMedia(e.target.files)}
          onClick={(e) => (e.target.value = "")}
        />
        {toastMessage &&
          <ToastComponent message={toastMessage} handleClose={() => setToastMessage(null)} />
        }

      </div>

    </>
  );
};

export default NotesAndAttachment;
