import styled from "styled-components";

const ExportDialogStyle = styled.div`
  .model_content_export {
    .MuiModal-root {
      h2.MuiTypography-root {
        color: #819099;
      }
    }
    .MuiFormControl-root,
    input,
    .MuiBox-root.css-0 {
      max-width: 100%;
      width: 100%;
    }

    .MuiBox-root.css-0 {
      flex-wrap: wrap;
      max-width: 100%;
      width: 100%;
    }
    label {
      font-size: 13px;
      font-weight: 600;
      margin-bottom: 7px;
    }
  }
  textarea.textArea_class {
    background-color: #fff;
    border-radius: 2px;
    padding: 7px 11px 7px 10px;
    display: inline-block;
    overflow-y: auto;
    overflow-x: hidden;
    border: 1px solid #dae1e6;
    width: 100%;
    height: 80px !important;
    resize: none;
  }
  input#outlined-basic {
    border-radius: 0;
    height: 37px;
    padding: 0 10px;
    font-size: 22px;
  }
  .MuiBox-root.css-0 > .MuiBox-root.css-0 {
    margin-bottom: 15px;
  }
  fieldset.MuiOutlinedInput-notchedOutline {
    border-color: #dae1e6;
    border-radius: 0;
  }
  .info-cstm.MuiBox-root.css-0 {
    padding: 15px;
    background-color: #f2fafd;
    font-size: 13px;
    margin-top: 20px;
    margin-bottom: 0;
    gap: 5px;
    display: flex;
    align-items: center;
  }
  .btn-group {
    gap: 8px;
    justify-content: flex-end;
  }
  button.MuiButtonBase-root.MuiButton-root.MuiButton-text {
    margin: 0;
    background: #fff;
    color: #819099;
    border: none;
    text-transform: capitalize;
    :last-child {
      background-color: #0696d7;
      border-color: #0696d7;
      color: #fff;
    }
  }
`;
export default ExportDialogStyle;
