import * as React from "react";
import {
  Checkbox,
  TextField,
  Autocomplete,
  InputLabel,
  InputAdornment,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { Search } from "@mui/icons-material";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function SimpleAutoComplete(props) {
  const { options, label, value, placeholder, onSelected } = props;
  return (
    <div className="normal_autocomplete autocomplete_section">
      <InputLabel htmlFor="standard-adornment-amount">{label}</InputLabel>
      <Autocomplete
        id="simple"
        options={options}
        // disableCloseOnSelect
        value={value}
        onChange={(event, newValue, reason) => onSelected(newValue)}
        noOptionsText="No labels"
        getOptionLabel={(option) => option?.label}
        style={{ width: 330 }}
        renderInput={(params) => {
          return (
            <TextField
              ref={params.InputProps.ref}
              inputProps={params.inputProps}
              autoFocus
              placeholder={placeholder}
            />
          );
        }}
      />
    </div>
  );
}
