import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { fetchUrl } from "Urls";
import { APRYSE_L_KEY } from "api";
import WebViewer from "@pdftron/webviewer";
import ZoomButtons from "components/ZoomButtons";
import {prepareAmazonRecordRow} from "components/SheetsComponent";
import SearchIcon from "assets/images/search.svg";
import moment from "moment";
import { issueStatusToColorMap , issueToolName , WithoutTemplateSection ,  enableIssuePinToolForInstance , 
        exportAnnotationsCustomGlobal , getPreDefinedIssueStyle } from "components/SetsView/ShowPdf";
import {Button,Dialog,Tooltip,FormControl,Box, MenuItem, Select} from "@mui/material";
export const IssuePdfView = ({ issueRec ,callFrom, ...props }) => {
  const { onAccept, onCancel, credential, selectedSheet } = props;
  const [version, setVersion] = useState();
  const [showZoomActions, setShowZoomAction] = useState(false);
  const [currentZoomLevel, setCurrentZoomLevel] = useState(1);
  const [openfileIns, setOpenfileIns] = useState();
  const [initialIssuePinId, setInitialIssuePinId] = useState(issueRec?.Annotation_Id__c);
  const [currentIssuePinId, setCurrentIssuePinIdState] = useState(issueRec?.Annotation_Id__c);
  const currentIssuePinIdRef = useRef(currentIssuePinId);
  const [instance, setInstanceState] = useState(undefined);
  const [pagesPanel, setPagesPanelState] = useState(false);
  const [currentToolMode, setCurrentToolMode] = useState();
  const [defaultFile, setDefaultFile] = useState();
  const [versionList, setVersionList] = useState([]);
  const [searchInPdf, setSearchInPdf] = useState(false);
  const toolModeRef = useRef();
  const setCurrentToolMode_ = (value) => {
    toolModeRef.current = value;
    setCurrentToolMode(value);
  };
  const pagePanelRef = useRef(false);
  const instanceRef = useRef();
  const viewer = useRef();
  const timeOutMilsec = useRef(1000);
  const xmlTojsonXML = useRef(null);
  const runAutoSave = useRef(false);
  const markupUpdate = useRef(false);
  useEffect(() => {
    setTimeout(() => {
      getUpdatedMarkupDetail();
    }, 500);
  }, []);
  const setInstance = (state)=>{
    instanceRef.current = state;
    setInstanceState(state);
  };
  const setCurrentIssuePinId = (state)=>{
    currentIssuePinIdRef.current = state;
    setCurrentIssuePinIdState(state);
  };
  const setPagesPanel= (value) => {
    pagePanelRef.current = value;
    setPagesPanelState(value);
  };
  function handleSearchInPdf(ann, type) {
    let pdfSearchButton;
    pdfSearchButton = instance.UI.iframeWindow.document.querySelector('[data-element="searchButton"]');
    pdfSearchButton.click();
    setSearchInPdf(!searchInPdf);

  }
  const getUpdatedMarkupDetail = async () => {
    let foundAttach = false;
    await axios.get(`${fetchUrl}/attachment/${issueRec.Linked_Document__c}?token=${credential?.token}&instanceUrl=${credential?.instanceUrl}`).then(async (res) => {
      if (res?.status === 200) {
        if(res.data.attachment){
          try{
            let att = await prepareAmazonRecordRow(res.data.attachment , credential);
            await preparePdfData(att , true);
          }catch (e){
            console.log('error in prepare',e);
          }
          foundAttach= true;
        }
      }
    }).catch(err =>{
      console.log('getUpdatedMarkupDetail :: ',err);
    });
  };
  const preparePdfData = async (pdfData = openfileIns , updateTable) => {
    //console.log("pdfopenfiledata >> " , JSON.stringify(pdfopenfiledata));
    //console.log( "pdfData >> " , JSON.stringify(pdfData));
    if (pdfData) {
      let issueLists = [];
      let _row = JSON.parse(JSON.stringify(pdfData));
      if(_row?.issueList.length > 0 ){
        for(let isr of _row?.issueList){
          if(isr.Id){
            issueLists.push(isr);
          }
        }
      }
      _row.issueList = issueLists;
      _row.issue = _row.issueList.length;
      setVersion(_row.currentVersion);
      setOpenfileIns(_row);
      if (pdfData?.markupJSON) {
        xmlTojsonXML.current = pdfData?.markupJSON;
      }
      loadDocument(pdfData.url);
    }
  };
  async function setMarkupVisiblityByAnnotations(annoList, visiv, _instance = instance , stopDeselect) {
    if (annoList) {
      if (annoList?.length > 0) {
        runAutoSave.current = false;
        for (const an_ of annoList) {
          an_.NoView = visiv;
          await _instance.Core.annotationManager.updateAnnotation(an_);
        }
        if( !(stopDeselect === true) ){
          deselectAnnotation(_instance);
        }
        runAutoSave.current = true;
      }
    }
  };
  const handleToggleDrawerPanel = (section, controlled) => {
    //console.log('section , controlled  :: ', section, controlled);
    //console.log('handleToggleDrawerPanel instance ', instance);
    let leftPanelButton ;
    if (instance) { 
      leftPanelButton = instance.UI.iframeWindow.document.querySelector('[data-element="leftPanelButton"]');
    }
    if (leftPanelButton) {
      leftPanelButton.click();
      setPagesPanel(!pagePanelRef.current);
    }
  };
  async function deselectAnnotation(_instance = instance) {
    _instance.Core.annotationManager.deselectAllAnnotations();
  }
  const exportAnnotationsCustom = async ( _instance = instance )=>{
    return await exportAnnotationsCustomGlobal(_instance);
  };
  async function saveAnnotationsServer(_instance = instance) {
    //console.log("instantJSON sendData"+ JSON.stringify(annotationJsonI));
    const annotIdTokeepHide = [];
    if (true) {
      const { Annotations } = _instance.Core;
      const annots = await _instance.Core.annotationManager.getAnnotationsList();
      if (annots && annots.length > 0) {
        runAutoSave.current = false;
        for (const ann of annots) {
          if(ann.NoView === true){
            annotIdTokeepHide.push(ann.Id);
          }
          ann.NoView = false;
          if (ann.Subject === 'Issue') {
            let sfIs = issueRec;
            if (ann.Id === currentIssuePinIdRef.current && sfIs.Id) {
              let cBox = issueStatusToColorMap[sfIs?.Status__c] ? issueStatusToColorMap[sfIs?.Status__c].colorBox : issueStatusToColorMap['Draft'].colorBox;
              if (cBox) {
                ann.FillColor = new Annotations.Color(cBox.r, cBox.g, cBox.b, 0.8);
                await _instance.Core.annotationManager.updateAnnotation(ann);
              }
            }
          } else {
            await _instance.Core.annotationManager.updateAnnotation(ann);
          }
        }
        runAutoSave.current = true;
      }
    }
    if (true) {
      let xfdfString = await exportAnnotationsCustom(_instance);
      console.log('xfdfString :: ',xfdfString);
      let savedXml = xfdfString;
      const annotsToHide = await _instance.Core.annotationManager.getAnnotationsList().filter(annX =>{ let _hide = annotIdTokeepHide.includes(annX.Id);
                                                                                                      let acc = annX.getCustomData('access') ;
                                                                                                      let customUser = annX.getCustomData('userId') ;
                                                                                                      if((acc === 'private' && annX.Author !== credential?.userId && annX.NoView === false)){
                                                                                                        _hide = true;
                                                                                                      }
                                                                                                      return _hide;
                                                                                                    });
      //console.log('annotsToHide ',annotsToHide?.length);
      if(annotsToHide?.length > 0){
        runAutoSave.current = false;
        setMarkupVisiblityByAnnotations(annotsToHide, true, _instance , true);
        runAutoSave.current = true;
      }
      //console.log("savedXml >> ", savedXml);
      //console.log('xfdfString :: '+xfdfString);
      if(markupUpdate.current === true){
        let obj = { Id: openfileIns.id, Markup_Json__c: xfdfString };
        const reqBody = new FormData();
        reqBody.append("recObj", JSON.stringify(obj));
        await axios.post(`${fetchUrl}/recSave/Amazon_S3_Attachment__c?token=${credential?.token}&instanceUrl=${credential?.instanceUrl}`, reqBody)
        .then(async (res) => {
          if (res?.data?.status === 200) {
            let _row = JSON.parse(JSON.stringify(openfileIns));
            _row.markupJSON = xfdfString;
            _row.Markup_Json__c = _row.markupJSON;
            _row.attObj.Markup_Json__c = _row.markupJSON;
            setOpenfileIns(_row);
            onAccept(currentIssuePinIdRef.current);
          }
        });
      }else{
        onAccept(currentIssuePinIdRef.current);
      }
    }
  }
  const loadDocument = async (fileUrl) => {
    if (viewer.current) {
      const disElement = [
        "leftPanelTabs",
        "zoomThumbOutButton",
        "thumbnailsSizeSlider",
        "toolbarGroup-View",
        "toolsHeader",
        "toolbarGroup-Annotate",
        "toolbarGroup-Shapes",
        "toolbarGroup-Insert",
        "toolbarGroup-Measure",
        "toolbarGroup-Edit",
        "toolbarGroup-FillAndSign",
        "toolbarGroup-Forms",
        "toolbarGroup-Redact",
        "annotationGroupButton",
        "linkButton",
        "annotationCommentButton",
        "thumbnailControl",
        "documentControl",
        "leftPanelResizeBar",
        "leftPanelTabs",
        "annotationStyleEditButton",
        "annotationDeleteButton",
        "searchPanelResizeBar"
      ];
      await WebViewer({
        fullAPI: true,
        path: "/webviewer/public",
        initialDoc: fileUrl,
        disabledElements: disElement,
        licenseKey: APRYSE_L_KEY, // sign up to get a free trial key at https://dev.apryse.com,
        annotationUser: credential?.userId
      },
        viewer.current
      ).then(async (instance) => {
        setInstance(instance);
        const { documentViewer, annotationManager, Tools, Annotations } = instance.Core;
        const defaultMarkupTool = documentViewer.getTool(Tools.ToolNames.PAN);
        instance.UI.disableElements(disElement);
        //console.log("fileUrl ::", fileUrl);
        const iframeWindow = instance.UI.iframeWindow;
        const header = iframeWindow.document.querySelector('[data-element="header"]');
        header.style.display = 'none';
        const IssueAnnotation  = await enableIssuePinToolForInstance(instance);
        const issueTool = documentViewer.getTool(issueToolName);
        const preDefinedIssueStyle = await getPreDefinedIssueStyle(instance);
        setShowZoomAction(true);
        documentViewer.addEventListener("zoomUpdated", (zoom) => {
          setCurrentZoomLevel(zoom);
        });
        documentViewer.addEventListener("toolModeUpdated", (newToolObject , oldToolObject) => {
          setCurrentToolMode_(documentViewer.getToolMode().name);
        });
        documentViewer.addEventListener("documentLoaded", async () => {
          setCurrentZoomLevel(documentViewer.getZoomLevel());
        });
        const pan_toolButton = document.getElementById("pan-button");
        if (pan_toolButton) {
          pan_toolButton.addEventListener("click", () => {
            const panAnnotation = documentViewer.getTool(Tools.ToolNames.PAN);
            if(panAnnotation.name !== documentViewer.getToolMode().name){
              documentViewer.setToolMode(panAnnotation);
            }else{
              documentViewer.setToolMode(defaultMarkupTool);
            }
          });
        }
        const issue_toolButton = document.getElementById("issue-button");
        if (issue_toolButton) {
          issue_toolButton.addEventListener("click", () => {
            if(issueTool.name !== documentViewer.getToolMode().name){
              documentViewer.setToolMode(issueTool);
            }else{
              documentViewer.setToolMode(defaultMarkupTool);
            }
          });
        }
        documentViewer.getTool(issueToolName).setStyles((currentStyle) => (preDefinedIssueStyle));
        documentViewer.addEventListener("documentLoaded", async () => { 
          const docu = documentViewer.getDocument();
          const pageCount = docu.getPageCount();
          const leftPanelButton = iframeWindow.document.querySelector('[data-element="leftPanelButton"]');
          const leftPanel = iframeWindow.document.querySelector('[data-element="leftPanel"]');
          const thumbnailPanel = document.getElementById('thubmnail-panel');
          if (thumbnailPanel && leftPanel) {
            thumbnailPanel.addEventListener('click', () => {
              if(pagePanelRef.current){
                const pdfContainer = leftPanel.querySelectorAll('.container');
                pdfContainer.forEach(element => {
                  element.style.height = "100px";
                });
              }
            })
          }
          if(callFrom === "FilesComponent"){
            styleLibraryLeftPanel(leftPanel , pageCount , leftPanelButton);
          }
          async function styleLibraryLeftPanel(leftPanel , pageCount , leftPanelButton){
            const leftPanelContainer = leftPanel.querySelector('.left-panel-container');
            leftPanelContainer.style.padding = "0px";
            const leftPanelHeader = leftPanel.querySelector('.left-panel-header');
            leftPanelHeader.style.backgroundColor = "white";
            leftPanelHeader.style.margin = "0px";
            leftPanelHeader.style.padding = "0px";
            leftPanelHeader.style.borderBottom = "1px solid #EAEAEA";
            const thumPanelTitle = iframeWindow.document.createElement('div');
            thumPanelTitle.classList.add('thumb-panel-title');
            thumPanelTitle.style.width = "100%";
            const pdfContainer = iframeWindow.document.querySelectorAll('.container');
            pdfContainer.forEach(element => {element.style.height = "100px";});
            const thumPanelTitleContainer = iframeWindow.document.createElement('div');
            thumPanelTitleContainer.classList.add('thumb-panel-title-container');
            thumPanelTitleContainer.style.display = "flex";
            thumPanelTitleContainer.style.gap = "90px";
            thumPanelTitleContainer.style.padding = "12px 16px";
            thumPanelTitleContainer.style.borderBottom = "1px solid #EAEAEA";
            thumPanelTitleContainer.style.width = "100%";
            const titContainerInnerDiv = iframeWindow.document.createElement('div');
            titContainerInnerDiv.classList.add('thumb-panel-title-container-text');
            titContainerInnerDiv.style.display = "flex";
            titContainerInnerDiv.style.gap = "90px";
            titContainerInnerDiv.style.padding = "12px 16px";
            titContainerInnerDiv.style.width = "100%";
            titContainerInnerDiv.textContent =  `${pageCount} Pages`;
            titContainerInnerDiv.color = '#505050';
            const leftIconContainer = iframeWindow.document.createElement('div');
            const closeIconImg = iframeWindow.document.createElement('img');
            closeIconImg.alt = 'Image Description';
            closeIconImg.src = '/close.svg'; 
            closeIconImg.style.cursor = "pointer";
            closeIconImg.addEventListener('mouseover', function() {
              closeIconImg.style.backgroundColor = '#F8F8F8'; 
            });
            closeIconImg.addEventListener('mouseout', function() {
              closeIconImg.style.backgroundColor = 'initial'; // Reset to the default background color
            });
            closeIconImg.addEventListener('click', function() {setPagesPanel(false);
              leftPanelButton.click();
            });
            thumPanelTitleContainer.textContent = 'Pages';
            thumPanelTitleContainer.style.fontWeight = 600;
            thumPanelTitleContainer.style.fontSize = "14px";
            thumPanelTitleContainer.style.color = "black";
            leftPanelHeader.appendChild(thumPanelTitle);
            thumPanelTitle.appendChild(thumPanelTitleContainer);
            thumPanelTitle.appendChild(titContainerInnerDiv);
            thumPanelTitleContainer.appendChild(leftIconContainer);
            thumPanelTitleContainer.appendChild(closeIconImg);
            leftPanel.style.backgroundColor = '#F8F8F8';
            leftPanel.style.height = '100vh';
            leftPanel.style.borderLeft = '1px solid #D8D8D8';
            leftPanel.style.boxShadow = '2px 0px 3px #ccc';
          }
          documentViewer.setToolMode(defaultMarkupTool);
          if(currentIssuePinIdRef.current){
            documentViewer.setToolMode(defaultMarkupTool);
          }else{
            documentViewer.setToolMode(issueTool);
          }
          setCurrentZoomLevel(documentViewer.getZoomLevel());
          if (xmlTojsonXML?.current) {
            //console.log('xmlTojsonXML?.current :: ',xmlTojsonXML?.current);
            await annotationManager.importAnnotations(xmlTojsonXML.current);
          }
          const markups = annotationManager.getAnnotationsList();
          let toHide = [];
          if(currentIssuePinIdRef.current){
            toHide = markups.filter(async ann => { return ann.Id !== currentIssuePinIdRef.current});
          }else{
            toHide = markups;
          }
          if (toHide.length > 0) {
            setMarkupVisiblityByAnnotations(toHide, true, instance);
          }
          runAutoSave.current = true;
          const zOutEle = document.getElementById('zoom-out-button');
          if(zOutEle){
            if(!zOutEle.dataset?.clickBound || zOutEle.dataset.clickBound != 'yes'){
              zOutEle.dataset.clickBound = 'yes';
              zOutEle.addEventListener('click', () => {
                let preLevel = documentViewer.getZoomLevel();
                if(preLevel > 0.1){
                  documentViewer.zoomTo(documentViewer.getZoomLevel() - 0.25);
                }else{
                  documentViewer.zoomTo(0.1);
                }
              });
            }
          }
          const zInEle = document.getElementById('zoom-in-button');
          if(zInEle){
            if(!zInEle.dataset?.clickBound || zInEle.dataset.clickBound != 'yes'){
              zInEle.dataset.clickBound = 'yes';
              zInEle.addEventListener('click', () => {
                documentViewer.zoomTo(documentViewer.getZoomLevel() + 0.25);
              });
            }
          }
          annotationManager.addEventListener("annotationChanged", async (annotations, action, { imported }) => {
            console.log('annotationChanged action ::', action, imported);
            if(imported===true || annotations[0].Subject !== 'Issue'){
              runAutoSave.current = false;
              //console.log('ann.Subject :: ',annotations?.length ,annotations[0].Subject , annotations[0].isInternal(),annotations[0]);
              await annotationManager.deleteAnnotations(annotations , {imported : imported});
              runAutoSave.current = true;
              return;
            }
            if (annotations[0]) {
              const ann = annotations[0];
              let pushIfIssue = false;
              let isIssueAnn = false;
              //console.log("Annotations[0]", annotations[0]);
              if(!(imported===true)){
                //console.log("ann ::", ann);
                if (action === 'add' || action === 'modify') {
                  if(action === 'add'){
                    ann.setCustomData("userId", credential?.userId);
                    ann.setCustomData("userFullName", credential?.userFullName);
                    ann.setCustomData("source", "save_annotation");
                    ann.setCustomData("access", "public");
                    annotationManager.redrawAnnotation(ann);
                    markupUpdate.current = true;
                  }
                  if(ann.Subject === 'Issue' && !currentIssuePinIdRef.current){
                    setCurrentIssuePinId(ann.Id);
                  }
                  if(ann.Subject === 'Issue'){
                    markupUpdate.current = true;
                  }
                  annotationManager.selectAnnotation(ann);
                } else if (action === 'delete') {
                  if(ann.Id === currentIssuePinIdRef.current){
                    setCurrentIssuePinId(null);
                    documentViewer.setToolMode(issueTool);
                    markupUpdate.current = true;
                  }
                }
              }
              if(!(imported === true)){
                if (runAutoSave.current && (ann.Subject != 'Issue' || pushIfIssue)) {
                  //console.log('auto save');
                  //saveAnnotationsServer(instance);
                }
              }
            }
          });
          annotationManager.addEventListener("annotationSelected", async (annotations, action) => {
            //console.log('annotationSelected action :: ', action);
            var markupsToDeselect = [];
            if (action === "selected") {
              var customData = annotations[0];
              if (customData) {
                createCustomPopups(customData , markupsToDeselect);
              }
            } else {
              //setSelectedMarkup(undefined);
            }
          });
          async function createCustomPopups(customData , markupsToDeselect){
            setTimeout(async () => {
              const inTime = annotationManager.getSelectedAnnotations();
              const currentIds = [];
              for(const tAnn of inTime){
                const isInToDeSelect = markupsToDeselect?.find(item => item.Id === tAnn.Id);
                if(!isInToDeSelect){
                  currentIds.push(tAnn.Id);
                }
              }
              const docContainer = await getContainerElement();
              if (docContainer) {
                const styleButton = iframeWindow.document.querySelector('[data-element="annotationStyleEditButton"]');
                const deleteButton = iframeWindow.document.querySelector('[data-element="annotationDeleteButton"]');
                if (styleButton) {
                  styleButton.style.display = 'none';
                }
                if (deleteButton) {
                  deleteButton.style.display = 'none';
                }
                if(customData.Subject == 'Issue' && documentViewer.getToolMode().name === issueToolName){
                  documentViewer.setToolMode(defaultMarkupTool);
                  annotationManager.selectAnnotation(customData);
                  return;
                }
                return; // returning as we are not deleting issue pin
                //console.log("run",currentIds);
                const container = docContainer.querySelector(".container");
                if(container){
                  container.style.background = "#505050";
                  //container.style.width = "292px";
                  container.style.height = "70px";
                  const child_container = container.querySelector(".child-flex-container");
                  if (child_container) {
                    container.removeChild(child_container);
                    //console.log('removed :: child-flex-container');
                  }
  
                  // Create a container div for flex layout
                  const flexContainer = document.createElement("div");
                  flexContainer.classList.add("child-flex-container");
                  //console.log('created :: child-flex-container');
                  flexContainer.style.display = "flex";
                  flexContainer.style.padding = "2px";
  
                  // Define an array of content for the divs with different class names
                  const divContent = [];
                  divContent.push({
                    imageUrl: "/deleteMarkup.svg",
                    altText: "delete Icon",
                    text: "Delete",
                    className: "div-class-3", // Add a unique class name
                    clickHandler: (event) => {
                      const deleteButton = iframeWindow.document.querySelector('[data-element="annotationDeleteButton"]');
                      if (deleteButton) {
                        deleteButton.click();
                      }
                    },
                  });
                  if(divContent?.length){
                    // Loop through the content array and create div elements
                    divContent.forEach((content, index) => {
                      // Create a new div element
                      const newDiv = document.createElement("div");
                      newDiv.dataset.annotationId = customData.Id;
                      if(content.divId){
                        newDiv.id = content.divId;
                      }
                      newDiv.style.color = "white";
                      newDiv.style.paddingLeft = content.className == "div-class-2" ? "13px" : content.className == "div-class-3" ? "30px" : "35px";
                      newDiv.style.paddingTop = "8px";
                      newDiv.style.width = "94px";
                      newDiv.style.cursor = "pointer";
                      // Set the class name based on the className property
                      newDiv.className = content.className;
                      newDiv.classList.add("div-hover");
                      newDiv.addEventListener("click", content.clickHandler);
                      newDiv.addEventListener("mouseover", mouseOver, false);
                      newDiv.addEventListener("mouseout", mouseOut, false);
                      function mouseOver() {
                        newDiv.style.backgroundColor = "#929292";
                      }
                      function mouseOut() {
                        newDiv.style.backgroundColor = "#505050";
                      }
                      // Create an img element
                      const imgElement = document.createElement("img");
                      imgElement.dataset.annotationId = customData.Id;
                      imgElement.src = content.imageUrl;
                      imgElement.alt = content.altText;
                      imgElement.style.paddingLeft = content.className == "div-class-2" ? "25px" : content.className == "div-class-3" ? "10px" : "5px";
                      // Create a paragraph element for text
                      const textElement = document.createElement("p");
                      textElement.dataset.annotationId = customData.Id;
                      textElement.textContent = content.text;
                      // Append the image and text elements to the new div
                      newDiv.appendChild(imgElement);
                      newDiv.appendChild(textElement);
                      // Append the new div to the flex container
                      flexContainer.appendChild(newDiv);
                    });
                    // Append the flex container to the parent div
                    container.appendChild(flexContainer);
                    console.log(customData.Subject,'f markupsToDeselect :: ',markupsToDeselect?.length);
                    if (markupsToDeselect?.length > 0) {
                      annotationManager.deselectAnnotations(markupsToDeselect);
                    }
                  }
                  timeOutMilsec.current = 100;
                }
              }
              async function getContainerElement(){
                var docCon = iframeWindow.document.querySelector('[data-element="annotationPopup"]');
                var count = 0;
                while (!docCon && count < 200) {
                  docCon = iframeWindow.document.querySelector('[data-element="annotationPopup"]');
                  if (docCon) {
                    break;
                  }
                  await delay(300);
                  count++;
                }
                return docCon;
              }
              async function delay(ms) {
                return new Promise(resolve => setTimeout(()=>{
                  //console.log('delay '+count);
                  resolve();
                }, ms));
              }
            }, timeOutMilsec.current);
          }
        });
        setInstance(instance);
      })
    }
  }
  return (
    <Dialog open={true} fullScreen>
      <WithoutTemplateSection className="crop_pdf_container">
        <div className="sheet-view-navigation">
          <div className="sheets-selected-and-info body-regular">
            {openfileIns && 
              <>
                <p className="sheets-selected-and-info-item-1">{openfileIns?.name}</p>
                <div className="sheets-selected-and-info-item">
                  <FormControl sx={{ width: "auto" }}>
                  <FormControl sx={{ width: "auto" }}>
                <Select
                  className="version_select"
                  MenuProps={{ classes: { paper: "version_menu_props_class" } }}
                  value={version}
                  renderValue={() => { return 'Version ' + version + `${openfileIns.currentVersion === version ?  "(current)" : ""} `}}
                >
                  {openfileIns &&
                    openfileIns.Document_File_Versions__r?.records.map((item) => (
                      <MenuItem key={item.Numbering_Sequence__c} value={item.Numbering_Sequence__c} >
                        <Box sx={{ display: "block" }}>
                          <div className="version_head">Version {item.Numbering_Sequence__c}{item.Numbering_Sequence__c === openfileIns.currentVersion && "(current)"}</div>
                          <div className="version_name">{item.Set__r?.Name}</div>
                          <div className="version_date">{moment(item.CreatedDate).format("MMM DD, YYYY")}</div>
                        </Box>
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
                  </FormControl>
                </div>
              </>
            }
          </div>
          <div className="frame-52">
            <div className="frame-44">
            <div className="define-sheet-number-area body-regular" onClick={handleSearchInPdf} style={{backgroundColor: searchInPdf && "#E8EFFF"}}>
                <Tooltip title="Search" placement="bottom">
                  <img src={SearchIcon} alt="search_icon" />
                </Tooltip>
              </div>
              <div id="pan-button" className="define-sheet-number-area body-regular"
                style={{ backgroundColor: currentToolMode === 'Pan' && "#E8EFFF"}}>
                <Tooltip title="Pan" placement="bottom">
                  <img className="img" alt="Highlighter" src="pan.svg"/>
                </Tooltip>
              </div>
              {!currentIssuePinId &&
                <div id="issue-button" className="define-sheet-number-area body-regular" 
                  style={{ backgroundColor: currentToolMode === issueToolName && "#E8EFFF"}}>
                  <Tooltip title="Add Issue" placement="bottom">
                      <img className="img" alt="Highlighter"
                        src="https://anima-uploads.s3.amazonaws.com/projects/64ad0840ca8a2bb608c26088/releases/64e33ae27d1705404f2bd212/img/add-issue.svg"
                      />
                  </Tooltip>
                </div>
              }
            </div>
            <div className="action_button" style={{ border: "none", margin: "0px", padding: "0px" }}>
              <Button className="custom_button cancel_button" onClick={onCancel}>
                Cancel
              </Button>
              <Button className="custom_button apply_button" disabled={!currentIssuePinId} onClick={()=>{
                saveAnnotationsServer();
              }}>
                Done
              </Button>
            </div>
          </div>
        </div>
        <Box sx={{ display: "flex" }}>
          { callFrom == "FilesComponent" && 
            <div className="siderbar_drawer_sheets">
                <Button id="thubmnail-panel-custom" className="markup_button_style" style = {{borderBottom : "1px solid #EAEAEA"}} onClick={() => handleToggleDrawerPanel("pages")} >
                  <img style={{width: !pagesPanel && "15px"}} src={pagesPanel ? "/pages.svg" : "/pages-grey.svg"} alt="markup_icon" />
                  <span style={{ color: !pagesPanel ? "rgba(80, 80, 80, 1)" : "rgba(52, 110, 245, 1)",}} >
                    Pages
                  </span>
                </Button>
            </div>
          }
          {showZoomActions && (
            <ZoomButtons zoomLevel={currentZoomLevel} />
          )}
          <div
            id="extractPDFcontainer"
            className="webviewer"
            ref={viewer}
            style={{ width: "100%", height: "94vh" }}
          />
        </Box>
        {!currentIssuePinId &&
          <div style={{ position: 'absolute' , top:'7%',left: '40%', backgroundColor: "#505050", color: "#FFFFFF", width: "fit-content", padding: "5px 10px", fontSize: "14px", fontWeight: 400 }}>
            Please click an area to mark where the issue is
          </div>
        }
      </WithoutTemplateSection>
    </Dialog>
  );}
export default IssuePdfView;