import styled from "styled-components";

const SetpopUpStyle = styled.div`
  .dialog_body {
    [class*="dialog_body_radio_btns"] {
      margin: 10px 0;
      h5 {
        margin-top: 0;
        margin-bottom: 0;
        font-size: 16px;
      }
    }

    .dialog_body_radiomain {
      .input-main {
        display: flex;
        flex-wrap: wrap;
        gap: 40px;
        padding: 0 35px;
        .left-cls {
          max-width: calc(100% - 200px);
          flex: 1;
          width: 100%;
        }
        .right-cls {
          max-width: 350px;
          width: 100%;
          flex: 100%;
        }
        input {
          padding: 10px;
        }
        label.MuiInputLabel-root {
          margin-bottom: 5px;
          font-size: 16px;
        }
      }
    }

    .dialog_body_radiomain
      .input-main
      > div
      .MuiFormControl-root.MuiTextField-root {
      width: 100%;
    }

    .radio-ex {
      margin: 5px 0px 20px;
    }

    .table-sx {
      max-width: calc(100% - 30px);
      width: 100%;
      margin-right: 0;
      margin-left: auto;
      .MuiDataGrid-columnHeaders {
        display: none;
      }
      .disable_class_datagrid {
        border: 0;
        .MuiBox-root {
          padding: 10px;
          border: 1px solid #ddd;
          margin-bottom: 20px;
          .MuiInput-underline:before {
            display: none;
          }
        }
      }
      .MuiFormControl-root {
        max-width: 100%;
        width: 100%;
      }
    }

    .table-sx .disable_class_datagrid input {
      width: 100%;
      max-width: 100%;
      .MuiBox-root > div {
        width: 100%;
        max-width: 100%;
      }
    }
    .MuiDataGrid-main .MuiDataGrid-cell--withRenderer {
      border: none;
      &:first-child {
        width: 50px !important;
        min-width: 50px !important;
      }
    }
  }

  .dialog_set_cls {
    .MuiDialog-paperWidthLg {
      min-width: auto;
      max-width: calc(100% - 65px);
      width: 100%;
    }
    .dialog_body {
      padding: 10px 40px;
      h5.dialog_heading {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 0;
      }
      .dialog_body_radio_btns_upload h5 {
        margin-top: 0;
        margin-bottom: 0;
      }
      .MuiDataGrid-cell:empty {
        display: none !important;
      }
    }
  }

  .MuiDataGrid-main
    .MuiDataGrid-virtualScroller
    .MuiDataGrid-virtualScrollerContent
    .MuiDataGrid-virtualScrollerRenderZone {
    width: 100%;
  }
  .MuiDataGrid-cell:nth-child(2) {
    width: 100% !important;
    max-width: calc(100% - 60px) !important;
    border: none;
  }
`;
export default SetpopUpStyle;
