import * as React from "react";
import { Box, Stepper, Step, StepLabel } from "@mui/material";

const steps = ["Upload", "Extract", "Publish"];

export default function DialogStepper(props) {
  const { children, activeStep, handleNext } = props;
  //console.log("DialogStepper activeStep", activeStep);

  return (
    <Box sx={{ width: "100%" }} className="stepper_div">
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        className="stepperClass"
      >
        {steps.map((label, index) => {
          return (
            <Step className="steps_name" key={label}>
              <StepLabel className="steps_label">{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {children}
    </Box>
  );
}
