import React, { useState } from "react";
import Delete from "@mui/icons-material/Delete";
import { IconButton, Tooltip, Box } from "@mui/material";
import { _sortItems } from "constant/Methods";
import TableIssue from "./TableIssue";
import { NoData } from "components/upload-logs/LogsComponents/CommonMethod";
import { TableCheckboxFilter } from "../IssueRelativeComponent";
const dummyData = [
  {
    id: 1,
    type: "Quality",
    subrype: "Quality",
    title: "Update Drawings",
    assign: "Tamer Tosson",
    company: "HPE - Hi-Rise",
    due_date: "Sep 26, 2020",
    location: "chd",
    attachment: 1,
    comment: 1,
    linkdocument: "",
  },
];

function IssueTable(props) {
  const [tableColumnValue, setTableColumnValue] = useState([]);
  const [open, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState();
  const { viewListData } = props?.issueTablePorps;
  const columns = [
    {
      accessor: "id",
      Header: "Id",
      Cell: (params) => (
        <div className="table_id">
          <Tooltip
            componentsProps={{
              tooltip: {
                sx: {
                  position: "absolute",
                  marginLeft: "6px",
                  willChange: "transform",
                  top: "0px",
                  left: "0px",
                  transform: "translate3d(11px, 116px, 0px)",
                  backgroundColor: "#ffa600",
                  borderColor: "#ffa600",
                  padding: "5px 10px",
                  color: "#fff",
                },
              },
            }}
            className="tooltip"
            title="Open"
            placement="right-start"
          >
            <div className="icon_left"></div>
          </Tooltip>
          <Box className="id_content">{params.value}</Box>
        </div>
      ),
      sortType: (prev, curr, columnId) => _sortItems(prev, curr, columnId),
    },
    {
      accessor: "numberSequence",
      Header: "Id",
      Cell: (params) => (
        <div className="table_id">
          <Tooltip
            componentsProps={{
              tooltip: {
                sx: {
                  position: "absolute",
                  marginLeft: "6px",
                  willChange: "transform",
                  top: "0px",
                  left: "0px",
                  transform: "translate3d(11px, 116px, 0px)",
                  backgroundColor: "#ffa600",
                  borderColor: "#ffa600",
                  padding: "5px 10px",
                  color: "#fff",
                },
              },
            }}
            className="tooltip"
            title="Open"
            placement="right-start"
          >
            <div className="icon_left"></div>
          </Tooltip>
          <Box className="id_content">{params.value}</Box>
        </div>
      ),
      sortType: (prev, curr, columnId) => _sortItems(prev, curr, columnId),
    },
    { accessor: "type", Header: "Type", flex: 1, disableSortBy: true },
    { accessor: "status", Header: "sub-type", flex: 1, disableSortBy: true },
    {
      accessor: "title",
      Header: "Title",
      flex: 1,
      sortType: (prev, curr, columnId) => _sortItems(prev, curr, columnId),
    },
    { accessor: "location", Header: "Location", flex: 1, disableSortBy: true },
    {
      accessor: "assigned_to",
      Header: "Assigned to",
      flex: 1,
      disableSortBy: true,
    },
    { accessor: "company", Header: "Company", flex: 1, disableSortBy: true },
    { accessor: "due_date", Header: "Due date", flex: 1 },
    {
      accessor: "linkdocument",
      Header: " Linked document",
      flex: 1,
      disableSortBy: true,
    },
    {
      accessor: "attachment",
      Header: <Delete />,
      flex: 1,
      disableSortBy: true,
      Cell: (params) => <IconButton>{params.value}</IconButton>,
    },
    {
      accessor: "comment",
      Header: "comment",
      sticky: "left",
      flex: 1,
      disableSortBy: true,
      Cell: (params) => <IconButton>{params.value}</IconButton>,
    },
    {
      accessor: "setting",

      disableSortBy: true,
      Header: (params) => (
        <TableCheckboxFilter
          {...params}
          tableColumnValue={tableColumnValue}
          setTableColumnValue={setTableColumnValue}
          open={open}
          setOpen={setOpen}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
        />
      ),
      flex: 1,
    },
  ];
  return (
    <Box component={"div"} sx={{ width: "100%" }}>
      <TableIssue
        columns={columns}
        data={viewListData}
        handleOpen={props.handleOpen}
        setSelectedRowData={props?.setSelectedRowData}
        NoDataComponent={<NoData />}
      />
    </Box>
  );
}

export default IssueTable;
