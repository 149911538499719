export const _sortItems = (prev, curr, columnId) => {
  if (columnId !== "numberSequence") {
    if (
      prev.original[columnId] &&
      curr.original[columnId] &&
      prev.original[columnId]?.toLowerCase() >
        curr.original[columnId]?.toLowerCase()
    ) {
      return 1;
    } else if (
      prev.original[columnId] &&
      curr.original[columnId] &&
      prev.original[columnId]?.toLowerCase() <
        curr.original[columnId]?.toLowerCase()
    ) {
      return -1;
    } else {
      return 0;
    }
  } else {
    if (
      prev.original[columnId] &&
      curr.original[columnId] &&
      prev.original[columnId] > curr.original[columnId]
    ) {
      return 1;
    } else if (
      prev.original[columnId] &&
      curr.original[columnId] &&
      prev.original[columnId] < curr.original[columnId]
    ) {
      return -1;
    } else {
      return 0;
    }
  }
};

export function printCurrentTime(msg) {
  var currentdate = new Date();
  var datetime =
    "Last Sync: " +
    currentdate.getDate() +
    "/" +
    (currentdate.getMonth() + 1) +
    "/" +
    currentdate.getFullYear() +
    " @ " +
    currentdate.getHours() +
    ":" +
    currentdate.getMinutes() +
    ":" +
    currentdate.getSeconds();

  console.log(msg, datetime);
}
