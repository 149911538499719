import React from "react";
import { Grid, Typography, IconButton, TextField, Button ,Stack ,Paper ,Menu ,MenuItem} from "@mui/material";
import { DeleteRounded, ArrowUpwardRounded , ArrowDownwardRounded } from "@mui/icons-material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddIcon from '@mui/icons-material/Add';

import QuestionSection from './QuestionSection';

const QuestionCard = ({ sectionData, index, dispatchObj, ...props }) => {
  const [secAnchorEl, setSecAnchorEl] = React.useState(null);
  const openSecMenu = Boolean(secAnchorEl);
  // add new question in a section
  const handleAddNewQuestion = (_index) => {
    // reducer dispatch coming from parent
    dispatchObj({type: 'ADD_QUESTION', sectionIndex : _index});
  };
  const handleRemoveSection = (_index) => {
    //dispatchObj({type: 'REM_SECTION' , sectionIndex : _index});
    dispatchObj({type: 'REM_SECTION_WARNING' , sectionIndex : _index});
  };
  const moveUpSection = e => {
    //console.log('moveUpSection');
    dispatchObj({type: 'MOVE_SECTION' , from : index , to : (index - 1)});
    closeSecMenuClick();
  };
  const moveDownSection = e => {
    //console.log('moveDownSection');
    dispatchObj({type: 'MOVE_SECTION' , from : index , to : (index + 1) });
    closeSecMenuClick();
  };
  const handleSectionValueChange = (_index , _dataObj, fieldKey, value) => {
    sectionData = JSON.parse(JSON.stringify(_dataObj));
    dispatchObj({type: 'SECTION_VAL_CHANGE' , fieldKey : fieldKey , value : value , sectionIndex : _index});
  };
  const openSecMenuClick = (event) => {
    setSecAnchorEl(event.currentTarget);
  };
  const closeSecMenuClick = () => {
    setSecAnchorEl(null);
  };

  return (
    <>
    <Grid container className="question_card">
      <Grid container item xs={12} className="pad-8 question_card_header" justifyContent="space-between" >
        <Typography variant="body1" style={{minWidth:'70%' , display : 'flex'}}>
          <span style={{paddingTop : '0.5rem'}}>{sectionData.Numbering_Sequence__c}.&nbsp;</span>
          <TextField id="question_title" variant="outlined" size="small" inputProps={{ className: "desc_input" }} fullWidth 
            value={sectionData.Name} onChange={(e) => {
              let fff = JSON.parse(JSON.stringify(sectionData)); fff.Name = e?.target?.value; handleSectionValueChange(index , fff , 'Name' , fff.Name );
            }}
            onKeyDown={(event) => {
              if (event.key.toLowerCase() != "tab") {
                event.stopPropagation();
              }
          }} />
        </Typography>
        <IconButton aria-label="settings">
          <MoreVertIcon 
            onClick={(e) => {
              e.stopPropagation();
              openSecMenuClick(e);
            }}
          />
        </IconButton>
      </Grid>
      <Grid item xs={12} className="pad-8 question_card_desc">
        <TextField id="question_description" label="Placeholder Description" variant="outlined" size="small" inputProps={{ className: "desc_input" }} fullWidth  
          value={sectionData.Description__c} onChange={(e) => {
            let fff = JSON.parse(JSON.stringify(sectionData)); fff.Description__c = e?.target?.value; handleSectionValueChange(index , fff , 'Description__c' , fff.Description__c );
          }}
          onKeyDown={(event) => {
            if (event.key.toLowerCase() != "tab") {
              event.stopPropagation();
            }
          }} />
      </Grid>
      <Grid item xs={12} className="question_card_section">
        {/* question section. iterate on {{sectionData.items}} */}
        {sectionData.items.map((p, questionIndex) => (
          <QuestionSection
            question={p}
            key={p.Numbering_Sequence__c}
            dispatchObj={dispatchObj}
            sectionIndex={index}
            sectionSortOrder={sectionData.Numbering_Sequence__c}
            totalQuestions={sectionData.items.length}
            questionIndex={questionIndex}
            responseOptions={props?.responseOptions} defaultResponse={props?.defaultResponse}
          />
        ))}
      </Grid>
      <Grid item xs={12} className="pad-8 question_card_section_add_new">
        <Button variant="contained" startIcon={<AddIcon />} onClick={() => handleAddNewQuestion(index)} >
          Add question
        </Button>
      </Grid>
    </Grid>
    {openSecMenu && (
      <>
        <Stack direction="row" spacing={2}>
          <Paper sx={{ maxHeight: 320, maxWidth: "100%" }}>
            <Menu id="basic-menu" anchorEl={secAnchorEl} open={openSecMenu} onClose={closeSecMenuClick} MenuListProps={{ "aria-labelledby": "basic-button" }} >
              { (! index == 0 ) && 
                <MenuItem onClick={moveUpSection}>
                  <ArrowUpwardRounded />
                  Move Up
                </MenuItem>
              }
              { (! (( props?.totalSections - 1) == index) ) && 
                <MenuItem onClick={moveDownSection}>
                  <ArrowDownwardRounded />
                  Move Down
                </MenuItem>
              }
              <MenuItem onClick={(e) => {handleRemoveSection(index);}}>
                <DeleteRounded />
                Delete
              </MenuItem>
            </Menu>
          </Paper>
        </Stack>
      </>
    )}
    </>
  );
};

export default QuestionCard;
