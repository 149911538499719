import React, { useEffect, useState } from "react";
import { Box ,Dialog, DialogTitle, DialogContent, DialogActions, Button, CircularProgress } from "@mui/material";
import { Folder, FolderShared, ArrowDropDown, ArrowRight } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import TreeView from "@mui/lab/TreeView";
import _ from "lodash";
import axios from "axios";
import { fetchUrl } from "Urls";
import { toast } from "react-toastify";
import StyledTreeItem from "components/FolderSection/StyledTreeItem";
function MoveDialog({projectId ,movePopup, handleSave , handleClose , currentFolderName}) {
    const [data, setData] = useState([]);
    const [moveFolderId, setMoveFolder] = useState(null);
    const [moveFolderName, setMoveFolderName] = useState(null);
    useEffect(() => {
        _getFolderApi();
    }, [movePopup]);

    async function _getFolderApi() {
        let additionalQuery = `Folder_Type__c='${currentFolderName.type}'` ; 
        await axios
            .get(`${fetchUrl}/folder?projectId=${projectId}&filterQuery=${additionalQuery}`)
            .then((res) => {
                if (res?.status === 200) {
                    const { tree } = res?.data;
                    const Data = [];
                    _.forEach(tree?.children, (row) => {
                        Data.push(row);
                    });
                    setData(Data);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const handleMove = () => {
        //console.log("Moving File :: "+moveFolderId);
        if(moveFolderId){
            handleSave(moveFolderId);
        }else{
            toast.error("Select folder to move");
        }
    };

    const renderTree = (nodes, section = "") => (
        <>
            <div className="main-wrapp-sidebar">
                <StyledTreeItem
                    key={nodes.value.Id}
                    nodeId={nodes.value.Id}
                    className={section}
                    labelText={nodes?.value?.Name}
                    color={currentFolderName?.id == nodes.value.Id ? "red" : nodes?.color ? nodes?.color : "#1a73e8"}
                    bgColor="#e8f0fe"
                    labelIcon={nodes?.labelIcon ? nodes?.labelIcon : nodes?.level === 1 && nodes?.value?.Folder_Type__c == "Project File" ? FolderShared : Folder}
                >
                    {Array.isArray(nodes.children) ? nodes.children.map((node) => renderTree(node, "children")) : null}
                </StyledTreeItem>
            </div>
        </>
    );

    return (
        <Dialog fullWidth open={movePopup} scroll="body">
            <DialogTitle>
                Select target folder
                <IconButton aria-label="close" onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{height: 400, paddingRight : 0 , overflowY: 'hidden'}}>
                <TreeView
                    defaultCollapseIcon={<ArrowDropDown />}
                    defaultExpandIcon={<ArrowRight />}
                    defaultExpanded={[currentFolderName?.id]}
                    defaultSelected={[currentFolderName?.id]}
                    onNodeSelect={(e, val) => {
                        if(currentFolderName?.id != val){
                            setMoveFolder(val);
                            setMoveFolderName(e.currentTarget.textContent);
                        }else{
                            //toast.error("Select different folder to move");
                        }
                    }}
                    sx={{ maxHeight: 400, overflowY: 'auto' }}
                >
                    {data.length > 0 ? (
                        data?.map((val) => {
                            return renderTree(val);
                        })
                    ) : (
                        <CircularProgress sx={{ marginLeft: "250px", marginTop: "250px" }} />
                    )}
                </TreeView>
            </DialogContent>
            <DialogActions>
                <Box style={{marginRight : 10}}><><b>{moveFolderName}</b></></Box>
                <Button variant="outlined" onClick={handleClose}>Cancel</Button>
                <Button variant="contained" onClick={handleMove}>Move</Button>
            </DialogActions>
        </Dialog>
    );
};
export default MoveDialog;