import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Tooltip, Button, IconButton, TextField, InputAdornment, Checkbox, Stack, Paper, Menu, Grid, MenuItem, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { Search , PlayCircle } from "@mui/icons-material";
import axios from "axios";
import { fetchUrl } from "Urls";
import { ConfirmDialog } from "components/DrawerComponent/DisciplineSettingDialog";
import { IMG_FORMAT, VIDEO_FORMAT, Uploader, makeRandomId } from "api";
import { prepareAmazonRecordRow, highlightText } from "components/SheetsComponent";
import _,{ cloneDeep } from "lodash";
import moment from "moment";
import Spinner from "components/spinner";
import CloseIcon from "../../assets/images/close.svg";
import ViewMediaDialog from "./ViewMediaDialog";
import UploadIcon from "../../assets/images/upload.svg";
import { DisciplineDialogStyles } from "components/DrawerComponent/DisciplineSettingDialog";
import Design from "components/SheetsComponent/Design";
import { useDropzone } from "react-dropzone";
import DropdownFilter from "components/DropdownFilter";
import ItemInQueueComponent from "components/ItemInQueueComponent";
import { RecordRenameDialog } from "components/DrawerComponent/DisciplineSettingDialog";
import ToastComponent from "components/ToastComponent";
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import heic2any from "heic2any";

const MediaComponent = (props) => {
    const [openDetail, setOpenDetail] = useState(false);
    const [selectedIds, setSelectedIds] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [viewListData, setViewList] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [searchBoxFilter, setSearchBoxFilter] = useState(false);
    const [filterOptionsVisible, showFilterOptions] = useState(false);
    const [totalFilterApplied, setTotalFilterApplied] = useState(0);
    const [fileForView, setFileForView] = useState({});
    const [viewMedia, setViewMedia] = useState(false);
    const [credential, setCredential] = useState();
    const [sortFilterValue, setSortFilterValue] = useState(1);
    const [showSpinner, setSpinner] = useState(false);
    const [showUploadMedia, setUploadMedia] = useState(false);
    const [sourceFolderDetail, setSourceFolder] = useState({});
    const sortedMediaList = useRef(null);
    const cancelAction0 = useRef();
    const cancelAction = useRef();
    const processTofilter_ = useRef(null);
    const { userData = {} } = useSelector((state) => state.userData);
    const [userList, setUserList] = useState(userData?.userList);
    const [selectedMenuRow, setSelectedMenuRow] = useState(null);
    const [showBgProcess , setShowBgProcess] = useState(false);
    const [bgProcessItems, setBgProcessItems] = useState([]);
    const [renameDialogData, setRenameDialogData] = useState(null);
    const [deleteDialogData, setDeleteDialogData] = useState(null);
    const [error, setError] = useState(false);
    const [typeOption, setTypeOption] = useState(['Image', 'Video']);
    const [activeFilterDropdown, setActiveFilterDropdown] = useState();
    const [uploadedByActiveFilters, setUploadedByActiveFilters] = useState([]);
    const [typeActiveFilters, setTypeActiveFilters] = useState([]);
    const [referenceActiveFilters, setReferenceActiveFilters] = useState([]);
    const [uploadedDateActiveFilters, setUploadedDateActiveFilters] = useState([]);
    const [filteredTableData, setFilteredTableDataState] = useState([]);
    const [toastMessage, setToastMessage] = useState();
    const filteredTableDataRef = useRef([]);
    const setFilteredTableData = (values)=>{
        filteredTableDataRef.current = values;
        setFilteredTableDataState(values);
    };

    const [filesForSearch, setFilesForSearch] = useState([]);
    const [allDataFetched, setAllDataFetchedState] = useState(false);
    const allDataFetchedRef = useRef(allDataFetched);
    const setAllDataFetched = (state) => {
        allDataFetchedRef.current = state;
        setAllDataFetchedState(state);
    };
    const [currentFolderFileList, setMediaFileState] = useState([]);
    const currentFolderFileListRef = useRef(currentFolderFileList);
    const setMediaFiles = (values)=>{
        currentFolderFileListRef.current = values;
        setMediaFileState(values);
    };
    const [objectPrefixMap, setObjectPrefixMapState] = useState();
    const objectPrefixMapRef = useRef(objectPrefixMap);
    const setObjectPrefixMap = (value)=>{
        objectPrefixMapRef.current = value;
        setObjectPrefixMapState(value);
    };


    const sortByOptions = [
        { id: 1, label: 'Date Added (new to old)' },
        { id: 2, label: 'Date Added (old to new)' },
        { id: 3, label: 'File Name (ascending)' },
        { id: 4, label: 'File Name (descending)'}
    ];

    const [bgUploadItems, setBgUploadItems] = useState([]);
    const [showBgUpload , setShowUploadProcess] = useState(false);
    const setItemToBgUpload=(queue_obj)=>{
        setBgUploadItems(pv=>{
          let t = cloneDeep(pv);
            if(!t){
              t = [];
            }
            let fi = t.findIndex(k=>k.id === queue_obj.id);
            if(fi > -1){
              t[fi] = queue_obj;
            }else{
              t.push(queue_obj);
            }
          return t;
        });
    };

    const setItemToBgProcess=(queue_obj)=>{
        setBgProcessItems(pv=>{
          let t = cloneDeep(pv);
            if(!t){
              t = [];
            }
            let fi = t.findIndex(k=>k.id === queue_obj.id);
            if(fi > -1){
              t[fi] = queue_obj;
            }else{
              t.push(queue_obj);
            }
          return t;
        });
    }

    useEffect(() => {
        if(userData?.projectId && userData?.projectId != credential?.projectId){
            getObjectPrefix();
            setCredential(userData);
        }
    }, [userData?.projectId]);

    useEffect(() => {
        if(credential?.projectId){
            getSourceFolder(credential);
        }
    }, [credential?.projectId]);

    useEffect(() => {
        let cc = 0;
        if (uploadedDateActiveFilters?.length > 0) {
          cc += uploadedDateActiveFilters.length;
        }
        if (typeActiveFilters?.length > 0) {
          cc += typeActiveFilters.length;
        }
        if (referenceActiveFilters?.length > 0) {
            cc += referenceActiveFilters.length;
          }
          if (uploadedByActiveFilters?.length > 0) {
            cc += uploadedByActiveFilters.length;
          }
        setTotalFilterApplied(cc);
        if (processTofilter_.current) {
          onHandleFilterData(cc);
        }
        processTofilter_.current = false;
    
    }, [currentFolderFileList ,uploadedDateActiveFilters, typeActiveFilters, referenceActiveFilters, uploadedByActiveFilters]);

    async function getObjectPrefix() {
        if (!objectPrefixMapRef.current) {
            const reqBody = {objectNames : ['Project__c','Issue__c','Form_Response__c']};
            await axios.post(`${fetchUrl}/sobject/prefix`,reqBody)
            .then((res) => {
                if (res?.data) {
                    const result = res?.data;
                    setObjectPrefixMap(result);
                }
            }).catch((err) => {
                console.log("/sobject/prefix", err);
            });
        }
    };
    async function getSourceFolder(_cred = credential) {
        setSpinner(true);
        if (_cred?.projectId) {
            await axios.get(`${fetchUrl}/sourceFolder/Media/${_cred?.projectId}`)
            .then((res) => {
                if (res?.data?.status === 200) {
                    const { tree } = res?.data;
                    const folderId = tree?.sourceFolder?.Id ;
                    setSourceFolder(tree?.sourceFolder);
                    refreshFolderFiles(folderId, false);
                }
            }).catch((err) => {
                console.log("/sourceFolder_/", err);
                setSpinner(false);
            });
        }
    };

    const updateStateTableDataWithRow = async (rowEle) => {
        setSpinner(true);
        if (rowEle.id) {
          toReplaceElementbyId(rowEle, viewListData, setViewList , ['in_content_found' , 'name_mark' , 'title_mark']);
          toReplaceElementbyId(rowEle, filteredTableData, setFilteredTableDataState);
          toReplaceElementbyId(rowEle, filesForSearch, setFilesForSearch);
    
          function toReplaceElementbyId(_ele, _list, setToList ,oldPropToKeep) {
            let ele = cloneDeep(_ele);
            let _tempList = cloneDeep(_list);
            if (_tempList?.length > 0) {
              const fInd = _tempList?.findIndex(
                (data) => data.id === ele.id
              );
              if (fInd > -1) {
                if(oldPropToKeep?.length > 0){
                  for(let prop of oldPropToKeep){
                    ele[prop] = _tempList[fInd][prop];
                  }
                }
                _tempList[fInd] = ele;
                if (setToList) {
                  setToList(_tempList);
                } // init folder files
              }
            }
            return _tempList;
          }
        }
        setSpinner(false);
      };

    async function refreshFolderFiles(id, clearFilter = true) {
        if (clearFilter == true) {
            resetCurrentFolderTableState(clearFilter);
        }
        getFolderData(id);
        await getFolderAttachments(id);
    };

    async function getFolderAttachments(folderId, searchQuery, stopFilter = false) {
        setSpinner(true);
        let reqUrl = `${fetchUrl}/get_folder_attachments/${folderId}`;
        let reqBody = {};
        if (searchQuery) {
            reqBody.searchQuery = searchQuery;
        }
        if (!searchQuery) {
            reqBody.limit = 'LIMIT 300';
        }
        let usersList = credential?.userList?.length > 0 ? cloneDeep(credential?.userList) : [];
        let withfile = [];
        const { token, cancel } = axios.CancelToken.source();
        cancelAction0.current = cancel;
        processTofilter_.current = false;
        await axios.post(reqUrl, reqBody, { cancelToken: token })
            .then(async (res) => {
                if (res?.status === 200) {
                    const { files } = res?.data;
                    if (files?.length > 0) {
                        for (let file of files) {
                            let fObj = await prepareMediaRecordRow(file, credential , objectPrefixMapRef.current);
                            if (searchQuery) {
                                if (fObj.title && fObj.title.toLowerCase().includes(searchQuery)) {
                                    fObj.title_mark = highlightText(fObj.title, searchQuery);
                                }
                            }
                            withfile.push(fObj);
                        }
                    }
                    handleSortFilter(sortFilterValue, withfile);
                }
            }).catch((err) => {
                console.log(err);
                setSpinner(false);
                if (axios.isCancel(err)) {
                }
            });
        setUserList(usersList);
        setSpinner(false);
    };

    async function getFolderData(folderId) {
        setMediaFiles([]);
        setFilesForSearch([]);
        setAllDataFetched(false);
        let reqUrl = `${fetchUrl}/folderfiles/${folderId}`;
        let withfile = [];
        const { token, cancel } = axios.CancelToken.source();
        cancelAction.current = cancel;
        processTofilter_.current = true;
        await axios.get(reqUrl, { cancelToken: token })
        .then(async (res) => {
            if (res?.status === 200) {
                const { files } = res?.data;
                if (files?.length > 0) {
                    for (let file of files) {
                        let fObj = await prepareMediaRecordRow(file, credential , objectPrefixMapRef.current);
                        withfile.push(fObj);
                    }
                }
                setMediaFiles(withfile);
                setFilesForSearch(withfile);
                setAllDataFetched(true);
                setSpinner(false);
            }
        }).catch((err) => {
            console.log(err);
            setSpinner(false);
            if (axios.isCancel(err)) {
                // Handle the cancellation
                console.log('Request canceled by user');
            }
        });
    };
    
    async function onHandleFilterData(_totalFilterApplied = totalFilterApplied) {
        let orgData0 = cloneDeep(currentFolderFileListRef.current);
        if(allDataFetchedRef.current){
            let toSetData = orgData0;
            if (_totalFilterApplied > 0) {
                toSetData = [];
                if (orgData0?.length > 0) {
                    for (const currRow of orgData0) {
                        let matched = true;
                        if (uploadedByActiveFilters?.length > 0 && matched === true) {
                            matched = false;
                            const valueFound = uploadedByActiveFilters.find(usss => usss.value === currRow.updatedById);
                            if (valueFound) {
                                matched = true;
                            }
                        }
                        if (typeActiveFilters?.length > 0 && matched === true) {
                            matched = false;
                            if(currRow.mediaType){
                                matched = typeActiveFilters.includes(currRow.mediaType);
                            }
                        }
                        if (referenceActiveFilters?.length > 0 && matched === true) {
                            matched = false;
                            if(currRow.relatedTo){
                                matched = referenceActiveFilters.includes(currRow.relatedTo);
                            }
                        }
                        if (uploadedDateActiveFilters?.length > 0 && matched === true) {
                            matched = false;
                            if (currRow.CreatedDate) {
                                const createdDate = moment(currRow.CreatedDate).format("MM/DD/YYYY")
                                const startDate = moment(uploadedDateActiveFilters[0].startDate).format("MM/DD/YYYY");
                                const endDate = moment(uploadedDateActiveFilters[0].endDate).format("MM/DD/YYYY");
                                if ((!startDate && !endDate) || (createdDate >= startDate && endDate >= createdDate)) {
                                    matched = true;
                                }
                            }
                        }
                        if (matched) {
                            toSetData.push(currRow);
                        }
                    }
                }
                setFilteredTableData(toSetData);
            }else{
                setFilteredTableData(toSetData);
            }
            handleSortFilter(sortFilterValue, toSetData);
        }else{
            if(sourceFolderDetail?.Id){
            if(cancelAction0.current){
                cancelAction0.current();
            }
            await getFolderAttachments(sourceFolderDetail?.Id);
            }
        }
        return;
    };

    const handleDeselectItemIds = () => {
        setSelectedIds([])
        setSelectedRows([]);
    };

    const handleClearAllFilters = () => {
        handleSortFilter(sortFilterValue, currentFolderFileList);
        setReferenceActiveFilters([]);
        setTypeActiveFilters([]);
        setUploadedByActiveFilters([]);
        setUploadedDateActiveFilters([]);
    };

    const handleSortFilter = (filterId = sortFilterValue, ListData) => {
        let sortedLists = ListData;
        if (ListData.length > 0) {
            if (filterId == 1) {
                sortedLists = ListData.sort((a, b) => new Date(b.CreatedDate) - new Date(a.CreatedDate));
                setViewList(sortedLists);
            } else if (filterId == 2) {
                sortedLists = ListData.sort((a, b) => new Date(a.CreatedDate) - new Date(b.CreatedDate));
            } else if (filterId == 3) {
                sortedLists = ListData.sort((a, b) => b.title.localeCompare(a.title));
            }
            else if (filterId == 4) {
                sortedLists = ListData.sort((a, b) => a.title.localeCompare(b.title));
            }
           
        }
        sortedMediaList.current = sortedLists;
        setViewList(sortedLists);
    };

    const handleCheckboxChange = (index, file) => {
        setSelectedIds((prevSelected) =>
            prevSelected.includes(index)
                ? prevSelected.filter((i) => i !== index) // Uncheck: remove index
                : [...prevSelected, index] // Check: add index
        );
    
        setSelectedRows((prevSelectedRows) => {
            const fileExists = prevSelectedRows.some((row) => row.id === file.id);
    
            if (fileExists) {
                // File exists: remove it
                return prevSelectedRows.filter((row) => row.id !== file.id);
            } else {
                // File does not exist: add it
                return [...prevSelectedRows, file];
            }
        });
    };

    async function resetCurrentFolderTableState(clearFilter = true) {
        if (clearFilter == true) {
            handleClearAllFilters();
        }
        setSelectedIds([]);
    };

    async function searchListViewFunction(searchTerm) {
        setSearchBoxFilter(true);
        setSearchText(searchTerm);
        let isFiltered = (filteredTableData?.length > 0 || totalFilterApplied > 0);
        let serLocal = isFiltered || allDataFetchedRef.current ;
        let toSearchData = isFiltered ? cloneDeep(filteredTableData) : cloneDeep(filesForSearch);
        if (searchTerm && searchTerm !== null && searchTerm !== "") {
            if (toSearchData.length > 0) {
                let searchFoundList = [];
                for (let el of toSearchData) {
                    if ((el.title && el.title.toLowerCase().includes(searchTerm))) {
                        if (el.title && el.title.toLowerCase().includes(searchTerm)) {
                            el.title_mark = highlightText(el.title, searchTerm);
                        }
                        searchFoundList.push(el);
                    }
                }
                handleSortFilter(sortFilterValue, searchFoundList);
            }
        } else {
            handleSortFilter(sortFilterValue, toSearchData);
            setSearchBoxFilter(false);
        }
    };
    const uploadMedia = async (options) => {
        if (options?.length > 0 && options?.length <= 1000) {
            const handleFileUploadProgress = (type, file) => {
                setBgUploadItems(pv=>{
                  let t = cloneDeep(pv);
                    if(!t){
                      t = [];
                    }
                    let fi = t.findIndex(k=>k.id === file.id);
                    if(fi > -1){
                        if (type === "failed") {
                            t[fi]["failed"] = true;
                        } else if (type === "canceled") {
                            t[fi]["cancelled"] = true;
                        } else if (type === "success") {
                            t[fi]["completed"] = true;
                        }
                        t[fi]["processed"] = true;
                        let filtered = t.filter((file) => file.processed === true);
                        if (filtered?.length === t?.length && t?.length > 0) {
                            if (sourceFolderDetail?.Id) {
                                refreshFolderFiles(sourceFolderDetail?.Id);
                            }
                        }
                    }
                  return t;
                });
            };
            if (options?.length > 0) {
                for (let f of options) {
                    let identifier = makeRandomId(9);
                    const fileData = { id: identifier, label: f?.name, fileName: f?.name, file: f, started: false, addtimeStamp : true };
                    if (fileData.started === false) {
                        const uploader = new Uploader(credential.projectId, sourceFolderDetail?.Id, fileData);
                        uploader.start(handleFileUploadProgress);
                        fileData.uploader = uploader;
                        fileData.started = true;
                        setItemToBgUpload(fileData);
                        setShowUploadProcess(true);
                    }
                }
            }
        }
    };

    const [anchorEl, setAnchorEl] = useState(null);
    const openMenu = Boolean(anchorEl);
    const handleMenuClick = (event, row) => {
        setAnchorEl(event.currentTarget);
        setSelectedMenuRow(row);  // Track the file ID for context
    };
      
    const handleClose = () => {
        setAnchorEl(null);
        setSelectedMenuRow(null);
    };

      const generateDownloadFileName = (filesForDownload)=>{
        let fileName;
        if(filesForDownload?.length > 0){
            if(filesForDownload?.length > 1){
                fileName = 'Media_Files.zip' ;
            }else{
                fileName = filesForDownload[0].File_Name__c ;
            }
        }
        return fileName;
    };

    const handleExportMediaFiles = (selectedFiles) => {
        let fileName = generateDownloadFileName(selectedFiles);
        let que_obj = {
            id: makeRandomId(3) + fileName + '_' + makeRandomId(5),
            name: fileName, label: fileName, started: true
        };
        setItemToBgProcess(que_obj);
        setShowBgProcess(true)
        handleDownloadFiles(selectedFiles, fileName).then(failed=>{
            let succ = !failed;
            que_obj.processed =true;
            que_obj.failed = failed;
            que_obj.completed = !que_obj.failed;
            setItemToBgProcess(que_obj);
        }).catch(qe=>{
            que_obj.processed =true;
            que_obj.failed = true;
            que_obj.completed = !que_obj.failed;
            setItemToBgProcess(que_obj);
        });

    };
    const handleDownloadFiles = async (selectedFiles, fileName) => {
        let failed = true;
    
        if (selectedFiles?.length === 1) {
            const file = selectedFiles[0];
            try {
                const response = await fetch(file.url);
                const blob = await response.blob();
                saveAs(blob, fileName);
                failed = false;
            } catch (error) {
                console.error(`Error downloading file ${file.url}:`, error);
            }
        } else if (selectedFiles?.length > 1) {
            const zip = new JSZip();
            let saveZip = false;
            // Add files to the zip
            for (let i = 0; i < selectedFiles.length; i++) {
                const url = selectedFiles[i].url;
                const fileName = selectedFiles[i].File_Name__c;
                try {
                    const response = await fetch(url);
                    const blob = await response.blob();
                    zip.file(fileName, blob); 
                    saveZip = true;
                } catch (error) {
                    console.error(`Error downloading file ${url}:`, error);
                }
            }
            if(saveZip){
                // Generate the ZIP file
                await zip.generateAsync({ type: 'blob' }).then((content) => {
                    failed = false;
                    //console.log('zip boom',4);
                    saveAs(content, fileName); // Save the ZIP file
                    //console.log('zip boom',5);
                });
            }
        }
        
        return failed;
    };

    async function handleDeleteItems(items) {
        setDeleteDialogData(null);
        if(items.length > 0){
            let r = 0;
            for(let itm of items){
                r++;
                setSpinner(true);
                axios.delete(`${fetchUrl}/delete/Amazon_S3_Attachment__c/${itm.id}`)
                .then((res) => {
                    if(r === items.length){
                        let mess = `${items.length > 1 ? 'Media Files' : items[0].title} ${items.length > 1 ? 'have' : 'has'} been deleted`;
                        setToastMessage(mess);
                        if (sourceFolderDetail?.Id) {
                            refreshFolderFiles(sourceFolderDetail?.Id);
                        }
                    }
                }).catch((err) => {
                    console.log(err);
                    setSpinner(false);
                });
            }
        }
    }
    
    async function onClickOpenAction(action, clickSource) {
        let dialogData;
        if (clickSource === 'header') {
            setAnchorEl(false);
        }
       if (action === 'rename') {
            var rowData = selectedMenuRow;
            if (clickSource === 'menu') {
                rowData = selectedMenuRow;
            } else if (clickSource === 'header') {
                rowData = selectedRows[0];
            }
            if (rowData) {
                dialogData = {
                    title: `Rename`,
                    content: `Name label`,
                    action1: "Cancel",
                    action2: "Save Changes",
                    errorMessage: `Duplicate Name.`,
                    rowData: rowData,
                    rowTitleChange: true

                };
                setRenameDialogData(dialogData);
            }
        } else if(action === 'delete'){
            dialogData = {
                title: `Delete Media`,
                content: `Are you sure you want to delete the selected media?`,
                action1: "Cancel",
                action2: "Delete"
            };
            setDeleteDialogData(dialogData);
        }
        setAnchorEl(false);
    }

    return (
        <div className="wrapper_main">
            <div className="table_head">
                <div className="header_heading">
                    <h1>Media</h1>
                </div>
                {sourceFolderDetail?.Id &&
                    <div className="right_side">
                        <Button component="button" className="custom_button apply_button" onClick={() => { setUploadMedia(true) }} >
                            Upload
                        </Button>
                    </div>
                }
            </div>
            <div className="page_body" style={{ width: openDetail && "calc(100% - 400px)" }}>
                {showSpinner && <Spinner />}
                <div style={{ width: "100%" }}>
                    <div className="page_body_right_head">
                        <div className="page_body_right_head_left_side">
                            <>
                                <div style={{ display: "flex", alignSelf: "center", marginRight: "10px", borderRight: "1px solid #EAEAEA" }} >
                                    {selectedIds.length > 0 && (
                                        <div className="buttons" style={{ paddingBottom: "4px", cursor: "pointer", }} onClick={() => {
                                            handleDeselectItemIds();
                                        }} >
                                            <img className="close" src="close.svg" alt="close" style={{ cursor: "pointer" }} />
                                        </div>
                                    )}
                                    <div style={{ marginRight: "10px" }}>
                                        <h2>{selectedIds.length} Selected</h2>
                                    </div>
                                </div>
                                {selectedIds.length > 0 && (
                                    <div style={{ borderRight: "1px solid #EAEAEA", borderTop: "1px solid #EAEAEA", borderBottom: "1px solid #EAEAEA", borderLeft: "1px solid #EAEAEA", display: "flex", }} >
                                        <div style={{ borderRight: "1px solid #EAEAEA" }} onClick={()=>{
                                            handleExportMediaFiles(selectedRows);
                                        }}>
                                            <Tooltip PopperProps={{ className: "version-action-tooltip" }} title="Export" placement="top" arrow>
                                                <IconButton sx={{ borderRadius: 0 }}>
                                                    <img src="/download.svg" alt="share" />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                        <div style={{ borderRight: "1px solid #EAEAEA" }}>
                                            <Tooltip title="Share" placement="top" PopperProps={{ className: "version-action-tooltip" }} arrow >
                                                <IconButton sx={{ borderRadius: 0 }} >
                                                    <img src="/share.svg" alt="share" />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                        <div style={{ borderRight: "1px solid #EAEAEA" }}>
                                            <Tooltip title="Delete" placement="top" PopperProps={{ className: "version-action-tooltip" }} arrow>
                                                <IconButton sx={{ borderRadius: 0 }} 
                                                 onClick={(e) => {
                                                    onClickOpenAction('delete', 'header');
                                                }}>
                                                    <img src="/delete.svg" alt="download" />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                        {selectedRows.length == 1 && (
                                            <div style={{ borderRight: "1px solid #EAEAEA" }}>
                                                <Tooltip
                                                    title={"Rename"}
                                                    placement="top"
                                                    PopperProps={{ className: "version-action-tooltip" }}
                                                    arrow
                                                >
                                                    <IconButton
                                                        sx={{ borderRadius: 0 }}
                                                        onClick={(e) => {
                                                            onClickOpenAction('rename', 'header');
                                                        }}
                                                    >
                                                        <img src="/Pencil.svg" alt="download" />
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </>
                        </div>

                        <div className="page_body_right_head_right_side">
                            <div>
                                <TextField
                                    id="outlined-basic"
                                    sx={{
                                        "& .MuiInputBase-root": {
                                            color: "#505050",
                                            height: "37px",
                                            padding: "4px",
                                            width: "300px",
                                        },
                                    }}
                                    variant="outlined"
                                    value={searchText}
                                    fullWidth
                                    className="Searchbar-field"
                                    autoComplete="off"
                                    placeholder="Search Photo"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="start">
                                                {searchBoxFilter ? (
                                                    <img
                                                        style={{ width: "25px", cursor: "pointer" }}
                                                        src="/cancel.svg"
                                                        alt="close icon"
                                                        onClick={() => {
                                                            setSearchBoxFilter(false);
                                                            searchListViewFunction("");
                                                            setSearchText("");
                                                        }}
                                                    />
                                                ) : (
                                                    <Search />
                                                )}
                                            </InputAdornment>
                                        ),
                                    }}
                                    onChange={(e) => {
                                        //convert input text to lower case
                                        var lowerCase = e.target.value.toLowerCase();
                                        if (!lowerCase || lowerCase === null || lowerCase === "") {
                                            searchListViewFunction(lowerCase);
                                        }
                                        setSearchText(lowerCase);
                                    }}
                                    onKeyPress={(e) => {
                                        if (e.key === "Enter") {
                                            // Do code here
                                            e.preventDefault();
                                            var lowerCase = e.target.value.toLowerCase();
                                            //console.log('searchText ',searchText , 'lowerCase',lowerCase);
                                            searchListViewFunction(searchText);
                                        }
                                    }}
                                />
                            </div>
                            <SortByOptionComponent
                                items={sortByOptions}
                                value={sortFilterValue}
                                onAccept={(value) => {
                                    //console.log(id)
                                    setSortFilterValue(value);
                                    handleSortFilter(value, viewListData);
                                }}
                            />
                            <div className={totalFilterApplied > 0 ? filterOptionsVisible ? "grid-11" : "grid-111"
                                                                    : filterOptionsVisible ? "grid-1" : "grid"}
                                onClick={() => { showFilterOptions(!filterOptionsVisible); }}
                            >
                                <img className="filter2" src="/filter2 (1).svg" alt="filter2" />
                                <div className="label-11 poppins-normal-abbey-14px">
                                    Filters{" "} {totalFilterApplied > 0 && (<>{" (" + totalFilterApplied + ")"}</>)}
                                </div>
                                {!filterOptionsVisible && totalFilterApplied > 0 && (
                                    <div className={filterOptionsVisible ? "grid-1" : "grid-2"} onClick={handleClearAllFilters}>
                                        <div className="clear1">
                                            <img className="clear" src="close.svg" alt="close" />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    {filterOptionsVisible && (
                        <Design>
                            <div className="bottom_area" style={{ paddingBottom: "16px" }} >
                                <div className="filters">
                                    <DropdownFilter
                                        dropLabel="Date Uploaded"
                                        options={[]}
                                        dateFilter={true}
                                        activeFilterDropdown={activeFilterDropdown}
                                        setActiveFilterDropdown={setActiveFilterDropdown}
                                        activeFilters={uploadedDateActiveFilters}
                                        onFilterChange={async (newValues) => {
                                            processTofilter_.current = true;
                                            setUploadedDateActiveFilters(newValues);
                                        }}
                                    />
                                    <DropdownFilter
                                        dropLabel="Reference"
                                        options={['Project','Issue','Form Response']}
                                        isValueArray={true}
                                        activeFilterDropdown={activeFilterDropdown}
                                        setActiveFilterDropdown={setActiveFilterDropdown}
                                        activeFilters={referenceActiveFilters}
                                        onFilterChange={async (newValues) => {
                                            processTofilter_.current = true;
                                            setReferenceActiveFilters(newValues);
                                        }}
                                    />
                                    <DropdownFilter
                                        dropLabel="Type"
                                        options={typeOption}
                                        activeFilterDropdown={activeFilterDropdown}
                                        setActiveFilterDropdown={setActiveFilterDropdown}
                                        isValueArray={true}
                                        activeFilters={typeActiveFilters}
                                        onFilterChange={async (newValues) => {
                                            processTofilter_.current = true;
                                            setTypeActiveFilters(newValues);
                                        }}
                                    />
                                    <DropdownFilter
                                        dropLabel="Uploaded By"
                                        options={userList}
                                        activeFilterDropdown={activeFilterDropdown}
                                        setActiveFilterDropdown={setActiveFilterDropdown}
                                        activeFilters={uploadedByActiveFilters}
                                        onFilterChange={async (newValues) => {
                                            processTofilter_.current = true;
                                            setUploadedByActiveFilters(newValues);
                                        }}
                                    />
                                    {totalFilterApplied > 0 && (
                                        <div style={{ cursor: "pointer" }} className="tertiary" onClick={handleClearAllFilters} >
                                            <img className="reset" src="reset.svg" alt="reset" />
                                            <div className="filters">Clear All</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </Design>
                    )}
                    {(searchBoxFilter) && (
                        <div className="bottom_area" style={{ paddingLeft: "16px", fontSize: "14px", fontWeight: 400, }} >
                            {(searchBoxFilter) && (
                                <>
                                    {" "}
                                    {viewListData.length} Search Results for{" "}
                                    <b>"{searchText}"</b>
                                </>
                            )}
                        </div>
                    )}
                    {viewListData.length < 1 ? (
                        <div style={{paddingLeft: '20px', paddingRight: '20px', overflowX: 'auto', height: searchBoxFilter ? 'calc(100vh - 180px)' : 'calc(100vh - 150px)', alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                            {searchBoxFilter &&
                                <div>None of your photos or videos matched this search</div>
                            }
                            {!searchBoxFilter && 
                                <>
                                    {totalFilterApplied > 0 &&
                                        <div>None of your photos or videos matched this filter</div>
                                    }
                                    {!(totalFilterApplied > 0) && 
                                        <div>Project doest not have any media uploaded</div>
                                    }
                                </>
                            }
                        </div>
                    ) : (
                        <div style={{ marginTop: '16px', paddingLeft: '20px', paddingRight: '20px', overflowX: 'auto', height: filterOptionsVisible && searchBoxFilter ? 'calc(100vh - 220px)' : filterOptionsVisible ? 'calc(100vh - 195px)': 'calc(100vh - 145px)'}}>
                            <Grid container spacing={2}>
                                {viewListData.length > 0 && viewListData.map((file, index) => (
                                    <Grid item xs={2.4} key={index}>
                                        <div className="gridContainer"
                                            style={{
                                                position: 'relative',
                                                backgroundColor: selectedIds.includes(file.id) && '#E8EFFF', // Change bg color if selected
                                                borderColor: selectedIds.includes(file.id) && '#346EF5',
                                            }}
                                        >
                                            <div className={selectedIds.includes(file.id) ? "checkboxContainer" : 'checkboxContainerDisable'}
                                                style={{ position: 'absolute', top: '24px', left: '26px' }}
                                                onClick={(e) => e.stopPropagation()} >
                                                <Checkbox
                                                    style={{ padding: '0px', backgroundColor: 'white', cursor: 'pointer', height: '18px', width: '18px' }}
                                                    checked={selectedIds.includes(file.id)} // Check state if index is selected
                                                    onChange={(e) => {
                                                        e.stopPropagation(); // Prevent click event propagation to Grid item
                                                        handleCheckboxChange(file.id, file);
                                                    }}
                                                />
                                            </div>
                                            {file?.mediaType === 'Image' &&
                                                <img src={file?.url} alt="file thumbnail" width="100%" style={{ height: '196px' , cursor: 'pointer'}}
                                                    onClick={() => {
                                                        setFileForView(file);
                                                        setViewMedia(true);
                                                    }}
                                                crossOrigin='anonymous'/>
                                            }
                                            {file?.mediaType === 'Video' &&
                                                <div style={{ height: '196px', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}
                                                    onClick={() => {
                                                        setFileForView(file);
                                                        setViewMedia(true);
                                                    }}>
                                                    <PlayCircle />
                                                </div>
                                            }
                                            <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: '8px' }}>
                                                <div style={{ display: 'flex', flexDirection: 'column', gap: '4px', width: '85%' }}>
                                                    <div style={{ fontWeight: 400, color: 'black', overflow: 'hidden', width: '100%', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                                                        <span dangerouslySetInnerHTML={{ __html: file.title_mark ? file.title_mark : file.title }} />
                                                    </div>
                                                    <div style={{ fontWeight: 400, color: '#505050' }}>{moment(file?.CreatedDate).format("MM/DD/YYYY")}</div>
                                                </div>
                                                <div style={{ display: 'flex', flexDirection: 'column', gap: '6px' }}>
                                                    <div style={{ display: 'flex', justifyContent: 'flex-end', cursor: 'pointer' }} onClick={(e) => handleMenuClick(e, file)}>
                                                        <img src="/more sub nav.svg" alt="more options" />
                                                    </div>
                                                    <div style={{ display: 'flex', gap: '8px' }}>
                                                        <img src="/issues.svg" alt="issues" className={file?.relatedTo !== 'Issue' ? 'disable_ele' : ''}/>
                                                        <img src="/form.svg" alt="form" className={file?.relatedTo !== 'Form Response' ? 'disable_ele' : ''}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Grid>
                                ))}
                            </Grid>
                            {openMenu &&
                                <Stack direction="row" spacing={2}>
                                    <Paper sx={{ height: 320, maxWidth: "100%" }}>
                                        <Menu
                                            id="basic-menu"
                                            anchorEl={anchorEl}
                                            open={openMenu}
                                            onClose={handleClose}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'right',
                                                }}
                                                transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                                }}
                                            MenuListProps={{ "aria-labelledby": "basic-button" }}
                                        >
                                            <div className="dropdown-list">
                                                <div className="text-with-icon-1" onClick={()=> {
                                                    setAnchorEl(false);
                                                    handleExportMediaFiles([selectedMenuRow]);
                                                }}>
                                                    <img className="share" src="/download.svg" alt="export" />{" "}
                                                    <div className="label-1 poppins-normal-abbey-14px">
                                                        {" "}
                                                        Export
                                                    </div>
                                                </div>
                                                <div
                                                    className="text-with-icon-1"
                                                    
                                                >
                                                    <img className="share" src="/link.svg" alt="export" />{" "}
                                                    <div className="label-1 poppins-normal-abbey-14px">
                                                        {" "}
                                                        Generate Shareable Link
                                                    </div>
                                                </div>
                                                <div
                                                    className="text-with-icon-1"
                                                    onClick={(e) => {
                                                        onClickOpenAction('delete', 'menu');
                                                    }}
                                                >
                                                    <img className="share" src="/delete.svg" alt="export" />{" "}
                                                    <div className="label-1 poppins-normal-abbey-14px">
                                                        {" "}
                                                        Delete
                                                    </div>
                                                </div>
                                                <div
                                                    className="text-with-icon-1"
                                                    onClick={()=>{
                                                        onClickOpenAction('rename', 'menu')
                                                    }}
                                                >
                                                    <img className="share" src="/Pencil.svg" alt="export" />{" "}
                                                    <div className="label-1 poppins-normal-abbey-14px">
                                                        {" "}
                                                        Rename
                                                    </div>
                                                </div>
                                            </div>
                                        </Menu>
                                    </Paper>
                                </Stack>
                            }
                        </div>
                    )}
                </div>
            </div>
            {showUploadMedia &&
                <UploadMediaDialog
                    onCancel={() => {
                        setUploadMedia(false);
                    }}
                    onAccept={(files) => {
                        uploadMedia(files);
                        setUploadMedia(false);
                    }}
                />
            }
            {viewMedia && fileForView &&
                <ViewMediaDialog
                    currentMediaFiles = {viewListData}
                    fileForView= {fileForView}
                    credential={credential}
                    updateTableState={updateStateTableDataWithRow}
                    setFileForView ={setFileForView}
                    onCancel = {()=>{
                        setViewMedia(false);
                    }}
                />
            }
            {renameDialogData&&
                <RecordRenameDialog
                    onCancel={() => {
                        setRenameDialogData(null);
                        setError(false);
                    }}
                    onAccept={(record, sobjectName, oldName, newName) => {
                        let sfObj = {
                            Id : record.Id,
                            Document_Title__c: newName
                        }
                        setSpinner(true);
                        const formData = new FormData();
                        //let issueObj = sfIssueObj;
                        formData.append("recObj", JSON.stringify(sfObj));
                        axios.post(`${fetchUrl}/recSave/${sobjectName}?token=${credential?.token}&instanceUrl=${credential?.instanceUrl}`, formData)
                            .then(async (res) => {
                                //console.log("count:::: " + JSON.stringify(res));
                                if (res.data.status === 200) {
                                    if (sourceFolderDetail?.Id) {
                                        let tmsg = `${oldName} has been renamed to ${newName}`;
                                        setToastMessage(tmsg);
                                        refreshFolderFiles(sourceFolderDetail?.Id);
                                    }
                                }
                            }).catch((err) => {
                                console.log('recSave/Set__c', err);
                                setSpinner(false);
                            });
                        setRenameDialogData(null);
                    }}
                    orgData={viewListData}
                    dialogData={renameDialogData}
                />
            }
             {toastMessage &&
                <ToastComponent message={toastMessage} handleClose={() => { setToastMessage(null) }} />
            }
            {deleteDialogData &&
                <ConfirmDialog
                    onCancel={() => { setDeleteDialogData(null); }}
                    onAccept={() => {
                        handleDeleteItems(selectedMenuRow ? [selectedMenuRow] : selectedRows);
                    }}
                    DialogData={deleteDialogData}
                />
            }
            <div style={{ position: 'fixed', bottom: 0, right: 0}} >
                <div style={{ position: 'relative', display: 'flex', flexDirection: 'row', gap: '10px' ,alignItems : 'end'}}>
                    {showBgUpload && (
                        <ItemInQueueComponent
                            containerStyle={{ position: 'relative'}}
                            heading='Uploading'
                            queueItems={bgUploadItems}
                            onClose={() => {
                                setBgUploadItems([]);
                                setShowUploadProcess(false);
                            }}
                        />
                    )}
                    {showBgProcess && (
                        <ItemInQueueComponent
                            containerStyle={{ position: 'relative'}}
                            heading='Exporting'
                            queueItems={bgProcessItems}
                            onClose={() => {
                                setBgProcessItems([]);
                                setShowBgProcess(false);
                            }}
                        />
                    )}
                </div>
            </div>
        </div>
    )
};
export default MediaComponent;

export const SortByOptionComponent = ({ items, onAccept, value }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const openMenu = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const onSelect = (value) => {
        onAccept(value);
        setAnchorEl(null);
    };
    return (
        <Stack direction="row" spacing={2}>
            <Paper sx={{ maxWidth: "100%", boxShadow: 'none', borderRadius: 0 }}>
                <div className={openMenu ? "grid-1" : 'grid'} style={{ width: "114px", cursor: "pointer" }} onClick={handleClick} >
                    <img className="filter2" src="/sort.svg" alt="filter2" />
                    <div className="label-11 poppins-normal-abbey-14px">
                        Sort By
                    </div>
                </div>
                <Menu id="basic-menu" anchorEl={anchorEl} open={openMenu} onClose={() => { setAnchorEl(null); }} MenuListProps={{ "aria-labelledby": "basic-button" }}>
                    {items.map((item, index) => (
                        <MenuItem key={item.id} sx={{ color: '#505050', backgroundColor: value == item.id && '#E8EFFF', fontSize: '14px', fontWeight: 400 }} onClick={() => { onSelect(item.id) }}>{item.label}</MenuItem>
                    ))}
                </Menu>
            </Paper>
        </Stack>
    );
};
export const UploadMediaDialog = ({ onCancel, onAccept }) => {
    const [showSpinnerUpload, setSpinnerUpload] = useState(false);
    const { getRootProps, getInputProps } = useDropzone({
        onDrop, multiple: true, accept: {
            'image/*': [...IMG_FORMAT],
            'video/*': [...VIDEO_FORMAT]
        }
    });
    async function onDrop(acceptedFiles) {
        setSpinnerUpload(true);
        const convertedFiles = await Promise.all(
          acceptedFiles.map(async (file) => {
            if (file.type === "image/heic" || file.type === "image/heif") {
              try {
                const convertedBlob = await heic2any({
                  blob: file,
                  toType: "image/jpg",
                });
                // Create a new file with the converted blob
                const newFile = new File([convertedBlob], file.name.replace(/\.(heic|heif|HEIC|HEIF)$/, ".jpg"), {
                  type: "image/jpg",
                  lastModified: file.lastModified,
                });
                return newFile;
              } catch (error) {
                console.error("Error converting HEIC/HEIF to JPG:", error);
                return null;
              }
            }
            return file;
          })
        );
      
        const validFiles = convertedFiles.filter(Boolean); // Filter out null values
      
        if (validFiles.length > 0) {
            setSpinnerUpload(false);
          onAccept(validFiles);
        }
      }
    return (
        <Dialog open={true} onClose={onCancel} fullWidth>
            <DisciplineDialogStyles>
                <DialogTitle className="dialog_title">
                    Upload Media
                    <IconButton aria-label="close" onClick={onCancel} sx={{ position: "absolute", right: 12 }} >
                        <img src={CloseIcon} alt="icons" />
                    </IconButton>
                </DialogTitle>
                <DialogContent className="dialog_content">
                    <div className="upload_holder" style={{ paddingRight: "20px", paddingLeft: "20px" }} >
                        <section className="dropZone_container">
                            <div {...getRootProps({ className: "dropzone" })}>
                                <div className="upload_holder">
                                    {/* Prevent dialog from closing when file explorer opens */}
                                    <input {...getInputProps()} onClick={(e) => e.stopPropagation()} />
                                    <img src={UploadIcon} alt="upload_icon" />
                                    <p className="drag_drop">
                                        Drag and Drop or{" "}
                                        <span className="blue_upload">Choose File</span> to Upload
                                    </p>
                                    <p className="upload_p">
                                        Files supported: jpeg, jpg, png, mp4, mov
                                    </p>
                                </div>
                            </div>
                        </section>
                    </div>
                </DialogContent>
                <DialogActions className="action_button">
                    <div style={{ marginLeft: "auto" }}>
                        <Button className="custom_button cancel_button" style={{ marginRight: "0.8rem" }}
                            onClick={() => {
                                onCancel(false);
                            }}
                        >
                            Cancel
                        </Button>
                    </div>
                </DialogActions>
            </DisciplineDialogStyles>
            {showSpinnerUpload && <Spinner />}
        </Dialog>
    );
};
export const prepareMediaRecordRow = async (file, cred, prefixMap)=>{
    let fObj = await prepareAmazonRecordRow(file, cred, 'Media');
    if(fObj?.fileExt){
        if(IMG_FORMAT.includes(`.${fObj.fileExt}`) || IMG_FORMAT.includes(`${fObj.fileExt}`)){
            fObj.mediaType = 'Image';
        }else if(VIDEO_FORMAT.includes(`.${fObj.fileExt}`) || VIDEO_FORMAT.includes(`${fObj.fileExt}`)){
            fObj.mediaType = 'Video';
        }
    }
    //console.log('fObj.relatedToId :: ',fObj.relatedToId);
    //console.log('prefixMap :: ',prefixMap);
    if(prefixMap && fObj.relatedToId){
        if(Object.keys(prefixMap).length > 0 ){
            for (let objApi in prefixMap) {
                if (prefixMap.hasOwnProperty(objApi)) {
                    let prefix = prefixMap[objApi]?.prefix;
                    if(prefix && fObj.relatedToId.startsWith(prefix)){
                        fObj.relatedTo = prefixMap[objApi]?.label;
                    }
                }
            }
        }
    }
    return fObj;
};