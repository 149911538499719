import styled from "styled-components";

const IsseMainCss = styled.div`
  .container {
    position: relative;
    margin: 0 15px;
    .mainFilter_tableSection {
      display: flex;
      .filterSection {
        max-width: 300px;
      }
      /* .TableSection {
        min-width: ${(props) => (props?.filter ? "70%" : "100%")};
      } */
    }
    .MuiGrid-root.MuiGrid-container {
      overflow-x: auto;
    //  max-height: calc(500px);
    }
    .mainFilter_tableSection {
      max-height: calc(100vh - 250px);
    }
  }
  ul.export_dropdown_list {
    position: absolute;
    top: 100%;
    z-index: 99;
    background: #fff;
    width: 100%;
    left: 0;
    transform: scaleY(0);
    transition: 0.3s ease all;
    transform-origin: top;
    padding: 5px 0;
    border-color: 1px solid #bcc9d1;
    box-shadow: 0 2px 4px rgb(32 60 94 / 30%);

    li {
      padding: 8px 10px;
      width: 100%;
      font-size: 14px;
      transition: 0.3s ease all;
      &:hover {
        background: #edf0f2;
      }
    }
  }
  .Dropdown {
    position: relative;
    max-width: 150px;
    width: 100%;
  }

  .Dropdown.open ul.export_dropdown_list {
    transform: scaleY(1);
  }
  .secondSection {
    gap: 10px;
  }

  .issueHead_section {
    padding: 0 4px;
  }

  button.MuiButton-root.MuiButton-text.MuiButton-textPrimary.export_button {
    background: #fff;
    color: #1976d2;
    width: 100%;
    border: 1px solid #f1f1f1;
    justify-content: space-between;
    border-radius: 0;
    display: flex;
  }
  .searchField {
    input {
      padding: 10px;
      background: #fff;
      border: none;
    }
    .MuiInputBase-formControl {
      border-radius: 0;
    }
    border: 1px solid #ddd;
    button.MuiButtonBase-root.MuiIconButton-root {
      background: #fff;
      border-radius: 0;
    }
    fieldset.MuiOutlinedInput-notchedOutline {
      border: none;
    }
  }
  .filter_header {
    padding: 15px 10px 15px;
    background: #f7f9fa;
    justify-content: space-between;
    align-items: center;
    display: flex;
    h3 {
      font-size: 14px;
    }
  }

  .autocomplete_section {
    margin-bottom: 10px;
    width: 100%;
    max-width: 330px;
  }
  label.MuiFormLabel-root {
    font-size: 14px;
    color: #000;
    margin-bottom: 10px;
  }
  .actions {
    display: flex;
    gap: 10px;
    align-items: center;
    font-weight: 700;
  }
  .field_sections {
    padding: 10px;
    background: #fff;
  }
  button.MuiButton-root.MuiButton-text.MuiButton-textPrimary {
    height: 40px;
  }
  .secondSection {
    max-width: 500px;
    width: 100%;
    justify-content: right;
  }
  .filter_button {
    background: #fff;
    border: 1px solid #ddd;
    svg {
      fill: #ddd;
    }
    &:hover {
      border: 1px solid var(--btn-blue);
    }
    &:hover svg {
      fill: var(--btn-blue);
    }
  }
  .firstSection {
    gap: 10px;
  }
  .mainFilter_tableSection {
    gap: 20px;
    overflow-x: auto;
  }
  .location_filter {
    flex-wrap: wrap;
    margin-bottom: 10px;
  }
  .checkbox_section {
    display: flex;
    align-items: center;
    span.MuiButtonBase-root.MuiCheckbox-root.MuiCheckbox-colorPrimary {
      padding: 0;
    }
  }
  .attribute_filter {
    flex-wrap: wrap;
    h3 {
      font-size: 16px;
    }
  }
  .MuiFormControl-root {
    width: 100%;
  }
  fieldset.MuiOutlinedInput-notchedOutline {
    border-color: #f1f1f1;
  }
  div[role="columnheader"] {
    font-weight: 700;
    text-transform: capitalize;
  }
  .pagination {
    position: sticky;
    bottom: 0;
    background: #eef4ff;
    padding-top: 10px;
    display: flex;
    justify-content: space-between;
  }
  .right_section_pagination button {
    background: none;
    border: none;
    color: #bcc9d1;
  }
  .TableSection button.MuiButtonBase-root {
    font-size: 14px;
  }
  .table .th:nth-last-child(3),
  .table .td:nth-last-child(3) {
    min-width: 30px;
    max-width: 60px;
    text-align: center;
  }
  .table .th:last-child div[role="columnheader"],
  .table .td:last-child div[role="columnheader"] {
    position: sticky !important;
    right: 0;
  }
  .table .td:first-child {
    box-shadow: none;
  }
`;

export default IsseMainCss;
