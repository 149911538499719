import React from "react";
import moment from "moment";
import axios from "axios";
import { fetchUrl } from "Urls";
import {  Typography, Grid, Box,TextField,Autocomplete , DialogActions , Button} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider, DesktopDatePicker } from "@mui/x-date-pickers";
import {prepareSobjectToIssueTableRowElement} from "hooks/IssueTab.hook";
import Spinner from "components/spinner";
export default function DetailTab({ credential, editMode , setEditMode , updateStateTableDataWithRow ,selectedRowData }) {

  const [sfIssueObj, setSfIssueObj] = React.useState(selectedRowData.record);
  const [assignTo, setAssignTo] = React.useState(null);
  const [owner, setOwner] = React.useState(null);
  const [rootCause, setRootCause] = React.useState(null);
  const [type, setType] = React.useState(null);
  const [status, setStatus] = React.useState(null);
  const [location, setLocation] = React.useState(null);
  //var sfIssueObj ;
  const [issueDueDate, setIssueDueDate] = React.useState(new Date());
  const [requiredMissing, setRequiredMissing] = React.useState(true);
  const [showSpinner, setSpinner] = React.useState(false);
  React.useEffect(() => {
    let dsfIssue = selectedRowData.record;
    setIssueDueDate(new Date(dsfIssue.Due_Date__c));
    setVariables(dsfIssue);
  }, []);
  function setVariables(_sfObj , push) {
    setAssignTo({
      label: _sfObj?.Ball_in_Court__c ? _sfObj?.Ball_in_Court__r?.Name : "",
      value: _sfObj?.Ball_in_Court__c,
    });
    setOwner({
      label: _sfObj?.OwnerId ? _sfObj?.Owner.Name : "",
      value: _sfObj?.OwnerId,
    });
    setRootCause({
      label: _sfObj?.Root_Cause__c,
      value: _sfObj?.Root_Cause__c,
    });
    setType({ label: _sfObj?.Type__c, value: _sfObj?.Type__c });
    setStatus({ label: _sfObj?.Status__c, value: _sfObj?.Status__c });
    if (_sfObj.Due_Date__c) {
      setIssueDueDate(new Date(_sfObj.Due_Date__c));
    } else {
      setIssueDueDate(null);
    }
    setRequiredMissing(!checkReadyToSave(_sfObj));
    setSfIssueObj(_sfObj);
    if(push){
      let _selectedRowData = JSON.parse(JSON.stringify(selectedRowData));
      _selectedRowData = prepareSobjectToIssueTableRowElement(_sfObj) ;
      updateStateTableDataWithRow(_selectedRowData);
    }
  }
  function checkReadyToSave(_sfObj) {
    //console.log('checkReadyToSave');
    let reqfields = [
      "OwnerId",
      "Type__c",
      "Status__c",
      "Name",
      "Linked_Document__c"
    ];
    if (_sfObj) {
      for (let f of reqfields) {
        if (!_sfObj[f] || _sfObj[f] == "" || _sfObj[f] == null) {
          return false;
        }
      }
      if (
        _sfObj["Status__c"] == "Answered" ||
        _sfObj["Status__c"] == "Closed"
      ) {
        let f = "Response__c";
        if (!_sfObj[f] || _sfObj[f] == "" || _sfObj[f] == null) {
          return false;
        }
      }
    }
    return true;
  }
  async function onClickSave() {
    saveCallback(sfIssueObj);
  }
  async function saveCallback(issueObj) {
    setSpinner(true);
    issueObj["Project__c"] = credential?.projectId;
    console.log('issueObj ',JSON.stringify(issueObj));
    const formData = new FormData();
    //let issueObj = sfIssueObj;
    formData.append("issueObj", JSON.stringify(issueObj));
    await axios
      .post(
        `${fetchUrl}/saveIssue/${credential?.projectId}?token=${credential?.token}&instanceUrl=${credential?.instanceUrl}`,
        formData
      )
      .then(async (res) => {
        if (res.status === 200) {
          issueObj.Id = res.data.id;
          setVariables(issueObj , true);
          setEditMode(false);
        }
      })
      .catch((error) => {
        setSpinner(false);
        console.log("error >>>>>>>>>>>>", JSON.stringify(error));
        console.error(error);
      });
    setSpinner(false);
  }
  return (
    <>
      <Grid style={{ position: "relative" }}>
        {showSpinner && <Spinner />}
        <Box className="box_1">
          <Typography gutterBottom variant="h5" component="div">
            {!editMode ? (<>
              {selectedRowData?.title}
            </>) : (<>
              <div className="selectedDiv d-flex">
                <TextField
                  className="common_class"
                  id="title"
                  variant="outlined"
                  required
                  value={sfIssueObj?.Name ? sfIssueObj?.Name : undefined}
                  onChange={(e) => {
                    let fff = sfIssueObj;
                    fff.Name = e?.target?.value;
                    setVariables(fff);
                  }}
                  onKeyDown={(event) => {
                    if (event.key.toLowerCase() != "tab") {
                      event.stopPropagation();
                    }
                  }}
                />
              </div>
            </>)}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Created by {selectedRowData?.createdBy} on
            {' ' + moment(selectedRowData?.createdDate).format("MMMM Do, YYYY")}
          </Typography>
        </Box>
        <Box className="box_2">
          <Grid className="left_side_grid">
            <Box className="inner_content_box">
              <Typography gutterBottom variant="h5" component="div">
                Assigned to
              </Typography>
              {!editMode ? (<>
                <Typography variant="body2" color="text.secondary">
                  {selectedRowData?.assigned_to}
                </Typography>
              </>) : (<>
                <div className="selectedDiv d-flex">
                  <Autocomplete
                    className="common_class"
                    disablePortal
                    value={assignTo}
                    getOptionLabel={(option) =>
                      option?.label ? option?.label : ""
                    }
                    onChange={(event, newValue) => {
                      let fff = sfIssueObj;
                      fff.Ball_in_Court__c = newValue?.value;
                      fff.Ball_in_Court__r = {
                        Id: newValue?.value,
                        Name: newValue?.label,
                      };
                      setVariables(fff);
                    }}
                    options={[
                      ...[{ label: "None..", value: null }],
                      ...credential?.userList,
                    ]}
                    renderInput={(params) => (
                      <div>
                        <TextField
                          id="assignedTo"
                          variant="outlined"
                          {...params}
                        />
                      </div>
                    )}
                  />
                </div>
              </>)}
            </Box>
            <Box className="inner_content_box">
              <Typography gutterBottom variant="h5" component="div">
                Due Date
              </Typography>
              {!editMode ? (<>
                <Typography variant="body2" color="text.secondary">
                  {moment(selectedRowData?.due_date).format("MMMM Do, YYYY")}
                </Typography>
              </>) : (<>
                <div className="selectedDiv due-date d-flex">
                  {/* dateinput / also need to formate date accordingly <InputLabel>Due Date</InputLabel>*/}
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      className="common_class"
                      disablePast={false}
                      placeholder="Due Date"
                      inputFormat="MM/dd/yyyy"
                      value={issueDueDate}
                      onChange={(newValue) => {
                        let fff = sfIssueObj;
                        if (newValue) {
                          fff.Due_Date__c = moment(newValue).format("YYYY-MM-DD");
                        } else {
                          fff.Due_Date__c = null;
                        }
                        setVariables(fff);
                      }}
                      renderInput={(params) => (
                        <div>
                          <TextField
                            id="dueDate"
                            variant="outlined"
                            {...params}
                          />
                        </div>
                      )}
                    />
                  </LocalizationProvider>
                </div>
              </>)}
            </Box>
            <Box className="inner_content_box">
              <Typography gutterBottom variant="h5" component="div">
                Location
              </Typography>
              {!editMode ? (<>
                <Typography variant="body2" color="text.secondary">
                  {selectedRowData?.location}
                </Typography>
              </>) : (<>
                <div className="selectedDiv d-flex">
                  <TextField
                    className="common_class"
                    id="title"
                    variant="outlined"
                    required
                    value={sfIssueObj?.Name ? sfIssueObj?.Name : undefined}
                    onChange={(e) => {
                      let fff = sfIssueObj;
                      fff.Location_Details__c = e?.target?.value;
                      setVariables(fff);
                    }}
                    onKeyDown={(event) => {
                      if (event.key.toLowerCase() != "tab") {
                        event.stopPropagation();
                      }
                    }}
                  />
                </div>
              </>)}
            </Box>
          </Grid>
          <Grid className="right_side_grid">
            <Box className="inner_content_box">
              <Typography gutterBottom variant="h5" component="div">
                Type
              </Typography>
              {!editMode ? (<>
                <Typography variant="body2" color="text.secondary">
                  {selectedRowData?.type}
                </Typography>
              </>) : (<>
                <div className="selectedDiv d-flex">
                  <Autocomplete
                    className="common_class"
                    disablePortal
                    value={type}
                    getOptionLabel={(option) =>
                      option?.label ? option?.label : ""
                    }
                    onChange={(event, newValue) => {
                      let fff = sfIssueObj;
                      fff.Type__c = newValue?.value;
                      setVariables(fff);
                    }}
                    options={credential?.issueObjOptions?.Type__c?.options}
                    renderInput={(params) => (
                      <div>
                        <TextField
                          id="type"
                          variant="outlined"
                          required
                          {...params}
                        />
                      </div>
                    )}
                  />
                </div>
              </>)}
            </Box>
            <Box className="inner_content_box">
              <Typography gutterBottom variant="h5" component="div">
                Owner
              </Typography>
              {!editMode ? (<>
                <Typography variant="body2" color="text.secondary">
                  {selectedRowData?.owner}
                </Typography>
              </>) : (<>
                <div className="selectedDiv d-flex">
                  <Autocomplete
                    className="common_class"
                    disablePortal
                    value={owner}
                    getOptionLabel={(option) =>
                      option?.label ? option?.label : ""
                    }
                    onChange={(event, newValue) => {
                      let fff = sfIssueObj;
                      fff.OwnerId = newValue?.value;
                      fff.Owner = { Id: newValue?.value, Name: newValue?.label };
                      setVariables(fff);
                    }}
                    options={credential?.userList}
                    renderInput={(params) => (
                      <div>
                        <TextField
                          id="owner"
                          variant="outlined"
                          disabled
                          {...params}
                        />
                      </div>
                    )}
                    disabled
                  />
                </div>
              </>)}
            </Box>
            <Box className="inner_content_box">
              <Typography gutterBottom variant="h5" component="div">
                Root Cause
              </Typography>
              {!editMode ? (<>
                <Typography variant="body2" color="text.secondary">
                  {selectedRowData?.rootCause}
                </Typography>
              </>) : (<>
                <div className="selectedDiv d-flex">
                  <Autocomplete
                    className="common_class"
                    disablePortal
                    value={rootCause}
                    getOptionLabel={(option) =>
                      option?.label ? option?.label : ""
                    }
                    onChange={(event, newValue) => {
                      let fff = sfIssueObj;
                      fff.Root_Cause__c = newValue?.value;
                      setVariables(fff);
                    }}
                    options={credential?.issueObjOptions?.Root_Cause__c?.options}
                    renderInput={(params) => (
                      <div>
                        <TextField
                          id="rootCause"
                          variant="outlined"
                          {...params}
                        />
                      </div>
                    )}
                  />
                </div>
              </>)}
            </Box>
          </Grid>
        </Box>
        <Box className="box_3">
          <Typography gutterBottom variant="h5" component="div">
            Linked document
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {selectedRowData?.linkdocument}
          </Typography>
        </Box>
        <Box className="box_4">
          <Grid className="left_side_grid">
            <Box className="inner_content_box">
              <Typography gutterBottom variant="h5" component="div">
                Description
              </Typography>
              {!editMode ? (<>
                <Typography variant="body2" color="text.secondary">
                  {selectedRowData?.description}
                </Typography>
              </>) : (<>
                <div className="textarea d-flex">
                  <TextField
                    className="common_class"
                    id="Description"
                    variant="outlined"
                    value={
                      sfIssueObj?.Description__c
                        ? sfIssueObj?.Description__c
                        : undefined
                    }
                    multiline
                    rows={2}
                    maxRows={3}
                    onChange={(e) => {
                      let fff = sfIssueObj;
                      fff.Description__c = e?.target?.value;
                      setVariables(fff);
                    }}
                    onKeyDown={(event) => {
                      if (event.key.toLowerCase() != "tab") {
                        event.stopPropagation();
                      }
                    }}
                  />
                </div>
              </>)}
            </Box>
            <Box className="inner_content_box">
              <Typography gutterBottom variant="h5" component="div">
                Response
              </Typography>
              {!editMode ? (<>
                <Typography variant="body2" color="text.secondary">
                  {selectedRowData?.response}
                </Typography>
              </>) : (<>
                <div className="textarea d-flex">
                  <TextField
                    className="common_class"
                    id="response"
                    variant="outlined"
                    value={
                      sfIssueObj?.Response__c ? sfIssueObj?.Response__c : undefined
                    }
                    multiline
                    rows={2}
                    maxRows={3}
                    required={
                      sfIssueObj?.Status__c == "Closed" ||
                      sfIssueObj?.Status__c == "Answered"
                    }
                    onChange={(e) => {
                      let fff = sfIssueObj;
                      fff.Response__c = e?.target?.value;
                      setVariables(fff);
                    }}
                    onKeyDown={(event) => {
                      if (event.key.toLowerCase() != "tab") {
                        event.stopPropagation();
                      }
                    }}
                  />
                </div>
              </>)}
            </Box>
          </Grid>
        </Box>
        <Box className="box_5">
          <Typography gutterBottom variant="h5" component="div">
            Linked references
          </Typography>
          <Typography variant="body2" color="text.secondary">
            No linked references
          </Typography>
        </Box>
      </Grid>
      <DialogActions>
        <Button onClick={() => setEditMode(false)} variant="outlined">
          Cancel
        </Button>
        <Button onClick={() => onClickSave()} variant="contained"
          disabled={requiredMissing || showSpinner} style={{ marginLeft: "5px" }} >
          Save
        </Button>
      </DialogActions>
    </>
  );
}
