import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useTheme } from "@mui/material/styles";
import axios from "axios";
import { fetchUrl } from "Urls";
import { Drawer, List, ListSubheader, Stack, InputAdornment } from "@mui/material";
import assets from "assets";
import colorConfigs from "../../configs/colorConfigs";
import sizeConfigs from "../../configs/sizeConfigs";
import appRoutes from "Routing/routes/appRoutes";
import SidebarItem from "components/common/SidebarItem";
import diamond from "assets/images/logo.svg";
import leftClose from "assets/images/left_close.svg";

import { IconButton, Typography, Menu, MenuItem, TextField, Select } from "@mui/material";
import { AccountCircle } from "@mui/icons-material";
import "./Navbar.css";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
}));

const LeftComponentStyles = styled.div`
  .navigation {
    // align-items: flex-start;
    display: flex;
    height: 1024px;
    justify-content: center;
    min-width: 240px;
  }

  .overlay-group {
    align-items: center;
    border-color: var(--neutralslight-grey-2);
    display: flex;
    background-color: white;
    border: 1px solid;
    flex-direction: column;
    margin-top: -1px;
    min-height: 1026px;
    padding: 22px 0;
    width: 242px;
  }
  .logo-and-collapsed-icon {
    display: flex;
    margin-right: 2px;
    margin-top: 2px;
    margin-bottom: 20px;
    position: relative;
    justify-content: space-between;
    margin-left: 10px;
  }
  .logo {
    align-items: flex-start;
    background-color: var(--brandblue2);
    border-radius: 8px;
    display: flex;
    gap: 8px;
    overflow: hidden;
    padding: 4px;
    position: relative;
    width: fit-content;
  }
  .diamond {
    height: 32px;
    min-width: 32px;
    position: relative;
  }
  .left-close,
  .down,
.down-1 {
height: 16px;
position: relative;
width: 16px;
}
.project-name { 
  align-items: center; 
  background-color: #FFFF;
  display: flex;
  gap: 8px;
  margin-right: 2px; 
  margin-top: 20px;
  position: relative;
  width: 240px;
  }
  .frame-1 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 8px;
    position: relative;
    width: "50%";
  }
  .overlap-group-item {
    align-items: flex-start; 
    display: inline-flex; 
    flex-direction: column;
    margin-right: 2px;
    margin-top: 12px;
    position: relative;
  }
  .closed_sidebar {
    .MuiDrawer-paperAnchorDockedLeft {
      transform: none !important;
      visibility: visible !important;
      max-width: 86px !important;
      transition: all 0.2s ease;

      .drawer_header img {
        width: 100%;
        max-width: 32px;
        margin-left: 24px;
        margin-top: 12px;
      }
      ul > li {
      margin-left: 10px;
      }
      ul > a img {
        width: 32px;
        height: 20px;
      }

      & > ul > a span {
        display: none;
      }

      & > ul > li > a .MuiButtonBase-root.MuiListItemButton-root {
        justify-content: center;
      }
      ul.MuiList-root.MuiList-padding.sideBarMenus {
        padding: 0 20px;
      }
    }
  }
  .open_sidebar {
    ul > li {
      margin-left: 10px;
      }
    a {
      font-size: 14px;
      line-height: 21px;
      padding: 10px;
      margin-right: 2px;
          &:hover {
      font-weight: 600;
      img {
              filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(324deg) brightness(96%) contrast(104%);
          }
        }
        &:focus {
          font-weight: 600;
          color: #000000;
          border: 1px solid #000000;
          background-color: white;
          text-decoration: none;
          img {
                  filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(324deg) brightness(96%) contrast(104%);
              }
            }
     }
  }
  .left_menu_image_selected {
    filter: invert(48%) sepia(59%) saturate(6305%) hue-rotate(213deg) brightness(96%) contrast(99%);
  }
  
`;

const LeftComponent = ({
  current_user_data,
  creditials,
  projectOptions,
  currentSite,
  setCurrentProjectEnvironment,
  sidebarOpen,
  setSidebarOpen,
  selectedOption,
  setSelectedOption
}) => {

  const [anchorElUser, setAnchorElUser] = useState(null);
  const [filterData, setFilterData] = useState([]);
  const [isSelectEnabled, setIsSelectEnabled] = useState(true);
 const selectStyles = {
    width: '230px',
  };
  const menuItemStyles = {
    paddingBottom: "0px",
    color: "#505050"
  };
  const handleOpenHelpMenu = (e) => {
    if (current_user_data?.username) {
      setAnchorElUser(e);
    }
  };
  const handleCloseHelpMenu = async () => {
    setAnchorElUser(null);
  };
  const onSignOutClick = async () => {
    handleCloseHelpMenu();
    var req_url = `${fetchUrl}/signout?token=${creditials.token}&instanceUrl=${creditials.instanceUrl}`;
    if(creditials.refreshToken){
      req_url += `&refreshToken=${creditials.refreshToken}`;
    }
    await axios.get(req_url)
    .then(async (res) => {
      if (res?.status === 200 && res.data) {
        window.location = res.data;
      }
    }).catch((err) => {
      console.error(err);
    });
  };
  const handleDrawer = () => {
    setSidebarOpen(!sidebarOpen);
  };
  
  async function filterDataFuntion(searchTerm) {
    let orgData = JSON.parse(JSON.stringify(projectOptions));
    let filterData = orgData;
    if (orgData && searchTerm && searchTerm != null && searchTerm !== "") {
      filterData = [];
      for (let el of orgData) {
        if ((el.Name.toLowerCase().includes(searchTerm))) {
          filterData.push(el);
        }
      }
    } else {
      filterData = JSON.parse(JSON.stringify(""));
    }
    //console.log("filterData :::", filterData);
    setFilterData(filterData);
  }
  return (
    <LeftComponentStyles>
      <Drawer
        className={sidebarOpen ? "open_sidebar" : "closed_sidebar"}
        open={sidebarOpen}
        variant="persistent"
        anchor="left"
        sx={{
          width: sizeConfigs.sidebar.width,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: sizeConfigs.sidebar.width,
            boxSizing: "border-box",
            borderRight: "1px solid #EAEAEA",
            backgroundColor: colorConfigs.sidebar.bg,
            color: colorConfigs.sidebar.color,
          },
        }}
      >
        <List disablePadding>
          <div className={sidebarOpen ? "logo-and-collapsed-icon" : ""}>
            <div className="logo" style={{ marginLeft: !sidebarOpen ? "19px" : "" }}>
              <img className="diamond" src={'/clogo192.png'} alt="Construcverse-app-logo" />
            </div>
            <DrawerHeader className={sidebarOpen ? "drawer_header" : ""}>
              {sidebarOpen &&
                <IconButton onClick={handleDrawer} style={{borderRadius: "0px"}}>
                  <img className={sidebarOpen ? "left-close" : ""} src={leftClose} alt="left-close-logo" />
                </IconButton>
              }
            </DrawerHeader>
            {!sidebarOpen &&
              <IconButton style={{ marginLeft: !sidebarOpen ? "23px" : "" , borderRadius: "0px"}} onClick={handleDrawer} >
                <img className={sidebarOpen ? "left-close" : ""} src="/left open.svg" alt="left-close-logo" />
              </IconButton>
            }
          </div>
          {sidebarOpen && (
            <div className="project-name">
              <Select
                variant="standard"
                style={{ width: "200px", fontWeight: 600, paddingLeft: "18px"}}
                sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: "none" } }}
                MenuProps={{ autoFocus: false, PaperProps: {
                  style: selectStyles,className:"project-name"
                }, }}
                disableUnderline={true}
                labelId="search-select-label"
                id="search-select"
                value={selectedOption}
                label="Options"
                className="project-name"
                onChange={(e) => setSelectedOption(e.target.value)}
              >
                <ListSubheader style={menuItemStyles}>
                  <TextField
                    size="small"
                    sx={{"& .MuiInputBase-root": {
                      color: "#505050"
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#d8d8d8',
                      // backgroundColor: '#f8f8f8',
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: '#505050',
                      },
                    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#d8d8d8',
                    },
                  }}
                    placeholder="Search Project"
                    fullWidth
                    autoFocus
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <img style={{width: "20px"}} src="search.svg" alt= "search"/>
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => {
                      e.preventDefault();
                      //convert input text to lower case
                      var lowerCase = e.target.value.toLowerCase();
                      filterDataFuntion(lowerCase);
                    }}
                    onKeyDown={(e) => {
                      if (e.key !== "Escape") {
                        // Prevents autoselecting item while typing (default Select behaviour)
                        e.stopPropagation();
                      }
                    }}
                    onBlur={() => setIsSelectEnabled(true)} // Re-enable Select on blur
                    onFocus={() => setIsSelectEnabled(false)} // Disable Select on focus
                  />
                </ListSubheader>
                {projectOptions &&
                  (filterData.length > 0 ? filterData : projectOptions).map((option, i) => (
                    <MenuItem style={menuItemStyles} key={i} value={option.Id} onClick={() => setCurrentProjectEnvironment({ Id: option.Id , Name: option.Name})}>
                      <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                        {option.Name}
                      </div>
                    </MenuItem>
                  ))
                }
              </Select>

            </div>
          )}

          {creditials && appRoutes(creditials, 'left component').map((route, index) =>
            route.sidebarProps ? (
              <SidebarItem item={route} key={index + '~' + route.state} sidebarOpen={sidebarOpen} />
            ) : (
              <ListSubheader key={index + '~' + route.state}>
                <b style={{ color: "#929292" }}>
                  {route.subheading}
                </b>
              </ListSubheader>
            )
          )}

          <div style={{ bottom: "0px", padding: 0, position: "fixed" }}>
            <IconButton sx={{
              marginLeft: !sidebarOpen ? "18px" : "5px", color: "fsdf#fff", "& .MuiButtonBase-root-MuiIconButton-root": {
                paddingLeft: "0px",
              }, paddingLeft: "0px"
            }} onClick={(e) => handleOpenHelpMenu(e)}>

              <AccountCircle sx={{ color: "dfsf#fff", fontSize: "xxx-large" }} />
              {sidebarOpen && (
                <Typography textAlign="center"
                  sx={{
                    marginLeft: !sidebarOpen ? "10px" : "", color: "fsdf#fff", "& .css-78trlr-MuiButtonBase-root-MuiIconButton-root": {
                      paddingLeft: "0px",
                    }, paddingLeft: "0px"
                  }}
                >
                  {current_user_data?.username}
                </Typography>
              )}
            </IconButton>
            {Boolean(anchorElUser) && (
              <Menu
                className="helpMenu custommenu"
                sx={{
                  mt: "45px",
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 28,
                    right: 150,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                    color: "black"
                  },
                }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                keepMounted
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseHelpMenu}
              >
                <MenuItem onClick={onSignOutClick}>
                  <Typography textAlign="center">Sign Out</Typography>
                </MenuItem>
              </Menu>
            )}
          </div>
        </List>
      </Drawer>
    </LeftComponentStyles>
  );
};
export default LeftComponent;
