import React from "react";

import PreConfigured from './ResponseTypeComponents/PreConfigured';
import TextBox from './ResponseTypeComponents/TextBox';
import MultiSelect from './ResponseTypeComponents/MultiSelect';
import SingleSelect from './ResponseTypeComponents/SingleSelect';
import DropDown from './ResponseTypeComponents/DropDown';

const ResponseTypeRenderer = ({ handleChange , responseOptions , selectedType }) => {
  switch (selectedType) {
    case "Preconfigured":
      return <PreConfigured handleChange={handleChange} responseOptions={responseOptions}/>;
    case "Single Select":
      return <SingleSelect handleChange={handleChange} responseOptions={responseOptions}/>;
    case "Dropdown":
      return <DropDown handleChange={handleChange} responseOptions={responseOptions}/>;
    case "Multi-Select":
      return <MultiSelect handleChange={handleChange} responseOptions={responseOptions}/>;

    /*case "Text":
      return <TextBox type="text" label="Text Response" />;

    case "Number":
      return <TextBox type="number" label="Number Response" />;

    case "Date":
      return <TextBox type="text" label="Date Response" />;*/
    default:
      return null;
  }
};

export default ResponseTypeRenderer;
