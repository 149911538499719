import { useState, useEffect,useRef } from "react";
import useIssueTabHook from "hooks/IssueTab.hook";
import { IssueHeadSection } from "./IssueRelativeComponent";
import { CreateIssuePopup, IssueDetailPopup } from "./IssuesPopups";
import IssueTable from "./IssueTable";
import IssueMainCss from "./IssueMainCss";
import Spinner from "components/spinner";
import styled from "styled-components";
import {
  Divider,
  Grid,
  TextField,
  IconButton,
  Autocomplete,
} from "@mui/material";
import { Close } from "@mui/icons-material";

const IssueTab = (props) => {
  console.log("propsissue ::", props);
  const {
    open,
    _handleOpen,
    typeFilter,
    statusFilter,
    credential,
    showFilter,
    _handleFilterDisplay,
    closeAndRefresh,
    tableIssueList,
    loadingProgress,
    getFilesApi,
    _onAddDocClick,
    updateIssueTableStateDataWithRow,
    treeFolderStructure,
    filesData,
    updateFolderFilesTableStateDataWithRow,
    showSpinner,
  } = useIssueTabHook(props);
  const [openDetail, setOpenDetail] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [viewListData, setViewListData] = useState(tableIssueList);
  var filteredList = useRef([]);
  const [searchTerm, setSearchTerm] = useState("");
  //const [filterObject, setFilterObject] = useState([]); // [{fieldName : 'filterField1' , values : ['value 1' , 'value 2']} , {fieldName : 'filterField2' , values : ['value 1']}]
  var filterObject = useRef([]);
  useEffect(() => {
    //console.log('projectId :: '+projectId);
    filterView();
    setViewListData(tableIssueList);
  }, [tableIssueList]);

  function _handleFilterSelection(event, newValue, fieldName) {
    const valuee = newValue;
    //console.log('valuee',JSON.stringify(valuee));

    let toTemp = [];
    let staticValues = [];
    if (valuee && valuee.length > 0) {
      for (let obj of valuee) {
        staticValues.push(obj.value);
      }
    }
    if (
      filterObject &&
      filterObject.current &&
      filterObject.current.length > 0
    ) {
      toTemp = JSON.parse(JSON.stringify(filterObject.current));
    }
    const fInd = toTemp?.findIndex((data) => data.fieldName == fieldName);
    if (fInd > -1) {
      toTemp[fInd].ele_values = valuee;
      toTemp[fInd].values = staticValues;
    } else {
      toTemp.push({
        fieldName: fieldName,
        values: staticValues,
        ele_values: valuee,
      });
    }
    const fInd2 = toTemp?.findIndex((data) => data.fieldName == fieldName);
    if (fInd2 > -1) {
      if (!(toTemp[fInd2].values && toTemp[fInd2].values.length > 0)) {
        toTemp.splice(fInd2, 1);
      }
    }
    //setFilterObject(toTemp);
    filterObject.current = toTemp;
    filterView();
  }
  function filterView(_filterObject = filterObject.current) {
    let filterData = [];
    setSearchTerm(null);
    if (tableIssueList.length > 0) {
      if (_filterObject && _filterObject.length > 0) {
        for (let item of tableIssueList) {
          let filCount = 0;
          let matchCount = 0;
          for (let fil of _filterObject) {
            if (fil.values && fil.values.length > 0) {
              filCount++;
              for (let check of fil.values) {
                if (
                  item[fil.fieldName]
                    ?.toLowerCase()
                    .indexOf(check.toLowerCase()) > -1
                ) {
                  matchCount++;
                }
              }
            }
          }
          if (filCount == matchCount) {
            filterData.push(item);
          }
        }
      }
    }
    //console.log('filterData :: '+filterData?.length);
    //setFilterList(filterData);
    filteredList.current = filterData;
    filterDataFuntion("");
  }
  function filterDataFuntion(
    _searchTerm,
    _filteredList = filteredList.current
  ) {
    let orgData =
      filterObject.current && filterObject.current.length > 0
        ? JSON.parse(JSON.stringify(_filteredList))
        : JSON.parse(JSON.stringify(tableIssueList));
    //console.log('_filteredList :: ' + _filteredList?.length);
    //console.log('tableIssueList :: ' + tableIssueList?.length);
    let filterData = orgData;
    if (orgData && _searchTerm && _searchTerm != null && _searchTerm !== "") {
      filterData = [];
      for (let el of orgData) {
        if (
          (el.name && el.name.toLowerCase().includes(_searchTerm)) ||
          (el.title && el.title.toLowerCase().includes(_searchTerm))
        ) {
          filterData.push(el);
        }
      }
    }
    if (!filterData) {
      filterData = [];
    }
    setViewListData(filterData);
  }
  function getFilterValues(fieldName, asOption) {
    let values = [];
    let obj = getFilterObject(fieldName);
    if (obj) {
      values = obj.values;
      if (asOption) {
        values = obj.ele_values;
      }
    }
    return values;
  }
  function getFilterObject(fieldName) {
    const fInd = filterObject.current?.findIndex(
      (data) => data.fieldName == fieldName
    );
    if (fInd > -1) {
      if (
        filterObject.current[fInd].values &&
        filterObject.current[fInd].values.length > 0
      ) {
        return filterObject.current[fInd];
      }
    }
    return;
  }

  function filterData(filterDatas) {
    //console.log("filterData11 ::",filterDatas);
    //console.log("tableIssueList ::", tableIssueList);
    setViewListData(filterDatas);
    //console.log("tableIssueList ::", tableIssueList);
  }
  function _handleOpenDetail(status) {
    setOpenDetail(status);
  }



  return (
    <IssueMainCss filter={showFilter ? true : false}>
      <div className="container">
        <IssueHeadSection
          headProp={{
            _handleFilterDisplay,
            _handleOpen,
            filterDataFuntion,
            searchTerm,
            setSearchTerm,
            filterView,
            onClearFilter: () => {
              /*setFilterObject([]);*/ filterObject.current = [];
              filterView([]);
              _handleFilterDisplay();
            },
            _filteredList: filteredList.current,
            _filterObject: filterObject.current,
          }}
          _filterList={filterData}
        />
       <div style={{ display: "flex", flexWrap: "wrap" }}>
          {showFilter && (
            <div style={{ width: "20%" }}>
              <div className="versions-sidebar-header">
                <span className="versions-sidebar-header-title">Filter</span>
                <IconButton
                  size="small"
                  className="versions-sidebar-header-close"
                  onClick={() => _handleFilterDisplay()}
                >
                  <Close />
                </IconButton>
              </div>
              <Divider variant="middle" />
              <div
                className="versions-sidebar-body"
                style={{ height: "calc(91.7vh - 200px)" }}
              >
                <Grid
                  container
                  rowSpacing={2}
                  style={{ width: "100%", paddingTop: "15px" }}
                  className="sf-detail-container"
                >
                  <Grid item xs={12}>
                    {credential?.issueObjOptions?.Type__c?.options &&
                      credential?.issueObjOptions?.Type__c?.options.length >
                        0 && (
                        <div style={{ paddingTop: "10px" }}>
                          <Autocomplete
                            multiple
                            className="inlineAutocomplete"
                            options={
                              credential?.issueObjOptions?.Type__c?.options
                            }
                            getOptionLabel={(option) => option?.label}
                            defaultValue={getFilterValues("type", true)}
                            onChange={(e, newValue) => {
                              _handleFilterSelection(e, newValue, "type");
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="standard"
                                label="Type"
                              />
                            )}
                          />
                        </div>
                      )}
                    {credential?.issueObjOptions?.Status__c?.options &&
                      credential?.issueObjOptions?.Status__c?.options.length >
                        0 && (
                        <div style={{ paddingTop: "10px" }}>
                          <Autocomplete
                            multiple
                            className="inlineAutocomplete"
                            options={
                              credential?.issueObjOptions?.Status__c?.options
                            }
                            getOptionLabel={(option) => option?.label}
                            defaultValue={getFilterValues("status", true)}
                            onChange={(e, newValue) => {
                              _handleFilterSelection(e, newValue, "status");
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="standard"
                                label="Status"
                              />
                            )}
                          />
                        </div>
                      )}
                    {credential?.userList &&
                      credential?.userList.length > 0 && (
                        <div style={{ paddingTop: "10px" }}>
                          <Autocomplete
                            multiple
                            className="inlineAutocomplete"
                            options={credential?.userList}
                            getOptionLabel={(option) => option?.label}
                            defaultValue={getFilterValues("createdById", true)}
                            onChange={(e, newValue) => {
                              _handleFilterSelection(
                                e,
                                newValue,
                                "createdById"
                              );
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="standard"
                                label="Created By"
                              />
                            )}
                          />
                        </div>
                      )}
                    {credential?.userList &&
                      credential?.userList.length > 0 && (
                        <div style={{ paddingTop: "10px" }}>
                          <Autocomplete
                            multiple
                            className="inlineAutocomplete"
                            options={credential?.userList}
                            getOptionLabel={(option) => option?.label}
                            defaultValue={getFilterValues(
                              "assigned_toId",
                              true
                            )}
                            onChange={(e, newValue) => {
                              _handleFilterSelection(
                                e,
                                newValue,
                                "assigned_toId"
                              );
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="standard"
                                label="Assigned To"
                              />
                            )}
                          />
                        </div>
                      )}
                    {credential?.issueObjOptions?.Root_Cause__c?.options &&
                      credential?.issueObjOptions?.Root_Cause__c?.options
                        .length > 0 && (
                        <div style={{ paddingTop: "10px" }}>
                          <Autocomplete
                            multiple
                            className="inlineAutocomplete"
                            options={
                              credential?.issueObjOptions?.Root_Cause__c
                                ?.options
                            }
                            getOptionLabel={(option) => option?.label}
                            defaultValue={getFilterValues("rootCause", true)}
                            onChange={(e, newValue) => {
                              _handleFilterSelection(e, newValue, "rootCause");
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="standard"
                                label="Root Cause"
                              />
                            )}
                          />
                        </div>
                      )}
                  </Grid>
                </Grid>
              </div>
            </div>
          )}
         
            <div style={{ width: showFilter ? "80%" : "100%" }}>
              {showSpinner && <Spinner />}
              <IssueTable
                issueTablePorps={{
                  viewListData,
                }}
                setSelectedRowData={setSelectedRowData}
                handleOpen={_handleOpenDetail}
              />
            </div>
          
        </div>        
          {open && 
          <CreateIssuePopup
            issueProps={{
              credential,
              closeAndRefresh,
              open,
              treeFolderStructure,
              _onAddDocClick,
              loadingProgress,
              filesData,
              updateStateTableDataWithRow:updateFolderFilesTableStateDataWithRow
            }}
          />
        }
        {openDetail && selectedRowData && 
          <IssueDetailPopup
            credential={credential}
            issueDetailProps={{
              openDetail,
              handleClose: () => _handleOpenDetail(false),
              selectedRowData,
              updateIssueTableStateDataWithRow,
              updateStateTableDataWithRow:updateFolderFilesTableStateDataWithRow,
            }}
            linkDocumentProps={{
              treeFolderStructure,
              _onAddDocClick,
              getFilesApi,
              loadingProgress,
              filesData,
              updateStateTableDataWithRow:updateFolderFilesTableStateDataWithRow
            }}
          />
        }
      </div>
    </IssueMainCss>
  );
};
export default IssueTab;
