import { useState, useEffect, useRef, useReducer } from "react";
import AnswersRender from './AnswersRender';
import { checkConditionalLogicBasedOnResponse } from ".";
import { cloneDeep } from "lodash";
const FormChecklistSectionCard = ({ checklistFormDetails, dispatchObj, formObj, isSignature, SetSaveChanges, isFormCompleted, userValidationMatch, setSelectedSection, selectedSection, setSectionClicked, sectionClicked, ...props }) => {
    const [answeredClicked, setAnsweredClicked] = useState(false);
    const containerRef = useRef(null); 
  
    const rowRefs = useRef({});
    const observer = useRef(null);
    useEffect(() => {
        if (selectedSection && !answeredClicked) {
            const firstSelectedId = selectedSection;
            const elementToScroll = rowRefs.current[firstSelectedId];
            if (elementToScroll) {
              elementToScroll.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        }
      }, [selectedSection]);
      

      useEffect(() => {
        const updateVisibleSection = () => {
          let minDistance = Infinity;
          let closestSection = null;
    
          Object.values(rowRefs.current).forEach((element) => {
            if (element) {
              const rect = element.getBoundingClientRect();
              const distance = Math.abs(rect.top + rect.height / 2 - window.innerHeight / 2);
    
              if (distance < minDistance) {
                minDistance = distance;
                closestSection = element.getAttribute('data-id');
              }
            }
          });
          if (closestSection && closestSection != selectedSection && !sectionClicked) {
            console.log("section set");
            setSelectedSection(closestSection);
          }else if(closestSection == selectedSection && sectionClicked){
            console.log("section not set");
            setSectionClicked(false);
          }
        };
    
        const scrollHandler = () => {
          console.log('Scrolling...');
          if (!answeredClicked) {
            updateVisibleSection();
          }else {
            setAnsweredClicked(false);
          }
        };
    
        const currentContainer = containerRef.current;
        if (currentContainer) {
          currentContainer.addEventListener('scroll', scrollHandler);
          console.log('Scroll event listener added.');
        }
    
        return () => {
          if (currentContainer) {
            currentContainer.removeEventListener('scroll', scrollHandler);
            console.log('Scroll event listener removed.');
          }
        };
      }, [sectionClicked, checklistFormDetails]);

        const handleErrorValidation = (value, question, ansObj, responseOptions) => {
        if (value) {
            const item = {ans : cloneDeep(ansObj), responseOptions: responseOptions}
            item.ans.Response__c = value;
            if(question.Conditional_Logic__c){
            let checkResult = checkConditionalLogicBasedOnResponse(item);
            if(checkResult.ifLogicFail){
               return "1px solid red";
            }
            else{
                return "";
            }
            }else {
                return "";
            }
        }
    };
    return (
        <div style={{ maxHeight: '100%', overflowY: 'auto' }} ref={containerRef}>
            {checklistFormDetails.map((section, sIndex) => {
                return (
                    <div key={section.identifier} data-id={section.identifier}  ref={(el) => (rowRefs.current[section.identifier] = el)} className="section" onClick={()=> {setSelectedSection(section.identifier);
                        if(setSelectedSection != section.identifier){
                        setAnsweredClicked(true);
                        }
                    }}>
                        <div className="section_head">
                            <div>
                                Section {section.Numbering_Sequence__c} of {checklistFormDetails.length}
                            </div>
                            <div className="section_head_right">
                                <div>
                                    {`${section.items?.length} `}
                                    {section.Name === 'Signature' ? 'Signature' : 'Question'}
                                    {section.items?.length > 1 ? 's' : ''}
                                </div>
                                <div className="action" style={{ cursor: "pointer" }}>
                                    <img src="/collapse_all.svg" alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="section_body">
                            <div className="header_label_dark" >
                                {section.Name && section.Name.trim() != '' ? section.Name : 'Untitled Section'}
                            </div>
                            <div style={{ padding: "0px", marginTop: "5px" }} className="template_steps_body_text" >
                                {section.Description__c && section.Description__c.trim() != '' ? section.Description__c : ''}
                            </div>
                            {section.items.map((question, index) => (
                                <div className="Question_card_Non_edit" style={{paddingTop: "16px", background: question.Response_Type__c === 'Signature' && "#F8F8F8", borderBottom: question.Response_Type__c === 'Signature' && "1px solid #EAEAEA", borderRadius: "10px", border: (question.ans.Response__c == null || question.ans.Response__c == "") && question.Response_Type__c != 'Signature' && !userValidationMatch ? "1px solid red" : (question.ans.Response__c == null || question.ans.Response__c == "") && question.Response_Type__c != 'Signature' && userValidationMatch && !question.Optional__c ? "1px solid red" :  handleErrorValidation(question.ans.Response__c, question, question.ans, question.responseOptions )}}>
                                    <div className="template_steps_count" style={{ paddingBottom: "10px" }}>
                                        {question.Response_Type__c === 'Signature' ? 'Signature' : 'Question'} {question.Numbering_Sequence__c} of {section.items.length}
                                    </div>
                                    <div className="header_label_dark builder_body_left_Outline" >
                                        {question.Question__c && question.Question__c.trim() != '' ? question.Question__c : (question.Response_Type__c === 'Signature' ? 'Signature By' : 'Question')}
                                        {question.Optional__c === false ? (
                                            <span style={{ color: 'red' }}>*</span>
                                        ) : (
                                            <span style={{ color: '#505050' }}> (optional)</span>
                                        )}
                                    </div>
                                    <div style={{ padding: "0px" }} className="template_steps_body_text" >
                                        {question.Description__c && question.Description__c.trim() != '' ? question.Description__c : ''}
                                    </div>
                                    <AnswersRender formId={props?.formId} credential={props?.credential} question={question} name={question.Name} responseOptions={question.responseOptions || []}
                                        sectionInd={sIndex} questionInd={index} dispatchObj={dispatchObj} ansObj={question.ans} defaultValue={question.ans.Response__c} formObj={formObj}
                                        isFormCompleted={isFormCompleted} SetSaveChanges={SetSaveChanges} userValidationMatch={userValidationMatch}/>
                                </div>
                            ))}
                        </div>
                    </div>
                )
            })}
        </div>
    );
}
export default FormChecklistSectionCard;
