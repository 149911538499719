import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import downIcon from "assets/images/arrow_drop_down.svg";
import upIcon from "assets/images/up.svg";
import loaderIcon from "assets/images/loader.svg";
import pdfRed from "assets/images/pdfRed.svg";
import folderIcon from "assets/images/folder.svg";
import checkIcon from "assets/images/check.svg";
import errorIcon from "assets/images/error-red.svg";

import {
    IconButton,
    Box,
    Grid,
    Typography,
    CircularProgress,
    Collapse,
    Tooltip
} from "@mui/material";
import { ExpandMore, ExpandLess, Close , RunningWithErrors} from "@mui/icons-material";
import { red } from "@mui/material/colors";


const FileUploadProgress = ({ uploadQueueFolders ,uploadQueueFiles,containerStyle, _handleCloseProcess, contentChanged, isTemplatesImported, dialogData }) => {
    console.log("dialogData :", dialogData);
    const [conStyle, setConStyle] = useState(containerStyle? containerStyle : {});
    const [open, setOpen] = useState(true);
    const [status, setStatus] = useState(true);
    useEffect(() => {
        //setUploadFile(uploadQueueFiles);
        //console.log("useEffect uploadQueueFiles 111", uploadQueueFiles);
        const objIndex = uploadQueueFiles.length > 0 && uploadQueueFiles?.findIndex((data) => ( data.process === true && data.failed !== true ));
        if (objIndex && objIndex > -1) {
            setStatus(true);
        } else {
            setStatus(false);
        }
    }, [uploadQueueFiles])
    const RenderListItem = ({ file }) => {
        return (
            <div key={file.id} className="upload-rogress-body-item" style={{border: "none"}}>
                <span className={ "file-name" + (!file?.processed ?  " processing" : "")}> 
                    <img src={file.isFolder ? folderIcon :pdfRed} alt="icon"/>{file.label}
                </span>
                <span className="file-process">
                    {file?.started && <>
                        {file?.processed && <>
                            {file?.completed && <>
                                <img src={checkIcon} alt="checkIcon"/>
                            </>}
                            {file?.failed && <>
                                <Tooltip title="Failed upload" placement="left" PopperProps={{ className: "version-action-tooltip" }} >
                                <img src={errorIcon} alt="errorIcon"/>
                                </Tooltip>
                            </>}
                            {file?.cancled && <>
                                <RunningWithErrors color="error" fontSize="small" /> Upload Cancled
                            </>}
                        </>}
                        {!file?.processed && <>
                            <img src={loaderIcon} alt="loaderIcon" style={{animation : 'rotate360 0.5s linear infinite'}}/>
                        </>}
                    </>}
                    {!file?.started &&<>
                        <CircularProgress size={16} /> Processing
                    </>}
                </span>
            </div>
        )
    }
    const RenderListItemTemplates = ({ file }) => {
        return (
            <div key={file?.id} className="upload-rogress-body-item" style={{border: "none", display: "flex", justifyContent: "space-between"}}>
                <span className={ "file-name" + (isTemplatesImported != 'success'?  " processing" : "")}> 
                    <img src="/template.svg" alt="icon" style={{marginRight: "5px"}}/>{file.Form_Template__r ? file.Form_Template__r.Name : file.Name}
                </span>
                <span className="file-process">
                            {isTemplatesImported == 'success' && <>
                                <img src={checkIcon} alt="checkIcon"/>
                            </>}
                        {isTemplatesImported == 'processing' && <>
                            <img src={loaderIcon} alt="loaderIcon" style={{animation : 'rotate360 0.5s linear infinite'}}/>
                        </>}
                        {isTemplatesImported == 'failed' && <>
                            <img src='/close_red.svg' alt="checkIcon"/>
                        </>}
                </span>
            </div>
        )
    }
    return (
        <Box className="upload-rogress" m={0} style={conStyle}>
            <Grid container>
                <Grid item xs={12} className="upload-rogress-header">
                    <Typography variant="subtitle2" component="div" className="upload-rogress-header-title" >
                        {contentChanged ? dialogData.header : `Uploading ${(uploadQueueFiles?.length + uploadQueueFolders?.length)} item${(uploadQueueFiles?.length + uploadQueueFolders?.length) > 1 ?'s':''}`}
                        <span className="upload-rogress-header-actions">
                            <IconButton className="btn" onClick={() => {setOpen(!open);}}>
                                {open ? <img src={downIcon} alt="downIcon"/> : <img src={upIcon} alt="downIcon"/>}
                            </IconButton>
                            <IconButton disabled={status} className="btn" onClick={()=>{contentChanged ? _handleCloseProcess() : _handleCloseProcess(open ,setOpen)}}>
                              <img src="/close.svg" alt="closeIcon" />
                            </IconButton>
                        </span>
                    </Typography>
                </Grid>
                <Collapse orientation="vertical" in={open} style={{ width: "100%" }}>
                    {contentChanged ? (
                        <Grid item xs={12} className="upload-rogress-body">
                        {uploadQueueFiles.length > 0  && uploadQueueFiles?.map((file) => {
                            return <RenderListItemTemplates key={file.id}  file={file}/>
                        })}
                    </Grid>
                    ):(
                        <Grid item xs={12} className="upload-rogress-body">
                        {uploadQueueFolders?.map((file) => {
                            return <RenderListItem key={file?.id}  file={file}/>
                        })}
                        {uploadQueueFiles?.map((file) => {
                            return <RenderListItem key={file.id}  file={file}/>
                        })}
                    </Grid>
                    )}
                    
                </Collapse>
            </Grid >
        </Box >
    );
};

FileUploadProgress.propTypes = {};

export default FileUploadProgress;