import React, { useState } from "react";
import PropTypes from "prop-types";

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Spinner from "components/spinner";

import VersionData from './VersionData';
import CompareFiles from './CompareFiles';

import { cloneDeep, filter, find, findIndex } from 'lodash';
import axios from "axios";
import { fetchUrl } from "Urls";

const VersionsDialog = (props) => {
  const { version, fileInfo, currentFolderName } = props;


  const [open, setOpen] = React.useState(false);
  const [compareActive, setCompareActive] = useState(false);
  const [openCompare, setOpenCompare] = useState(false);
  const [openFileVisibility, setOpenFileVisibility] = useState(true);

  const [currentVersionFiles, setCurrentVersionFiles] = useState();
  const [showSpinner, setSpinner] = useState(false);

  const handleFileVisibilityDialogClose = () => {
    setOpenFileVisibility(false);
  };

  const handleClickOpen = (e) => {
    console.log('***** ', JSON.stringify(fileInfo) );
    e.preventDefault();
    e.stopPropagation();
    setCurrentVersionFiles([]);
    _getVersionFilesFromAWS();
    setOpen(true);
  };

  const handleClose = () => {
    setCompareActive(false);
    setOpen(false);
  };
  async function _getVersionFilesFromAWS() {
    setSpinner(true);
    await axios
      .get(`${fetchUrl}/version/list/${fileInfo.folderId}/${fileInfo.id}`)
      .then((res) => {
        if (res?.status === 200) {
          //let currentVersion = res.data.currentVersion;
          //console.log("res?.status :: "+res?.status);
          let versionList = res.data.versionList;
          let withfile = [];
          //console.log("versionList :: "+JSON.stringify(versionList));
          if(versionList &&  versionList.length > 0 ){
            let totLength = versionList.length ;
            for(let i = 0 ; i < totLength ; i++ ){
              let e = versionList[i];
              let obj = {
                version: e.SF_Display_Version_Num,
                versionLabel: e.SF_Display_Version,
                isLatest: e.IsLatest,
                ownerName: e.SF_CreatedBy,
                lastUpdated: e.SF_CreatedDate,
                folderId: fileInfo.folderId,
                awsAttId: fileInfo.id,
                name: fileInfo.name,
                markupJSON: e.markupJSON,
                fileExt: e.fileExt,
                selected: (i === 0 || i === 1 ? true : false)
              };
              let clone_obj = { ...obj, ...e };
              withfile.push(clone_obj);
              /*withfile.push({ key: e.Key, versionLabel: 'V'+(totLength-i), versionId: e.VersionId, isLatest: e.IsLatest,
                            ownerName: e.Owner.DisplayName, lastUpdated: e.LastModified });*/
            }
          }
          setCurrentVersionFiles(withfile);
        }
      })
    .catch((err) => {
      console.log(err);
    });
    setSpinner(false);
  }

  const openCompareFiles = () => {
    // TODO: get selected files and call method to compare
    setOpenCompare(true);
    setOpenFileVisibility(true);
    handleClose();
  };

  const closeCompareDialog = () => {
    setOpenCompare(false);
  };

  const handleFileSelection = (e, VersionId) => {
    const temp = cloneDeep(currentVersionFiles);
    const itemIndex = findIndex(temp, { versionId : VersionId });
    temp[itemIndex].selected = e.target.checked;
    setCurrentVersionFiles(temp);
  };

  const filesToCompare = filter(currentVersionFiles, { selected: true });

  React.useEffect(() => {
    if (open) {
      console.log('dialog is open');
    }
  }, [open]);

  if (fileInfo && fileInfo.type === 'file') {
    return (
      <div className="version-num-dialog">
        <Button
        style={{color: "#346EF5", backgroundColor: "#346EF514"}}

          variant="text"
          className="version-num"
        >
          {version}
        </Button>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          fullWidth
          maxWidth="md"
        >
          <DialogTitle id="scroll-dialog-title">
            {fileInfo?.name} Version History
            <IconButton aria-label="close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
              <span className="ver-dg-filename">{fileInfo?.name}</span>
              <ul className="versions-list" style={{position :'relative'}}>
                {showSpinner && ( <Spinner /> ) }
                {currentVersionFiles &&
                  currentVersionFiles.map((item) => (
                    <li key={item.id}>
                      <VersionData
                        versionInfo={item}
                        compareActive={compareActive}
                        refreshList={_getVersionFilesFromAWS}
                        handleFileSelection={handleFileSelection}
                        setSpinner={setSpinner}
                        currentFolderName={currentFolderName}
                      />
                    </li>
                  ))}
              </ul>
            </DialogContentText>
          </DialogContent>
          {!compareActive && (
            <DialogActions className="version-dialog-actions">
              <Button onClick={() => setCompareActive(true)} variant="outlined">
                Compare versions
              </Button>
              <Button onClick={handleClose} variant="contained">
                Close
              </Button>
            </DialogActions>
          )}
          {compareActive && (
            <DialogActions>
              <Button onClick={() => setCompareActive(false)} variant="text">
                Cancel
              </Button>
              <Button onClick={openCompareFiles} variant="contained" disabled={filesToCompare.length !== 2} >
                Compare
              </Button>
            </DialogActions>
          )}
        </Dialog>
        {openCompare && (
          <CompareFiles
            open={openCompare}
            filesToCompare={filesToCompare}
            handleClose={closeCompareDialog}
            openFileVisibility={openFileVisibility}
            handleFileVisibilityDialogClose={handleFileVisibilityDialogClose}
          />
        )}
      </div>
    );
  }

  return <div className="version-dash">{version}</div>;
};

VersionsDialog.propTypes = {};

export default VersionsDialog;
