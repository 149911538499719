import { createGlobalStyle } from "styled-components";

const FormTemplateStyled = createGlobalStyle`
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
 
:root{
    --btn-blue:#1976d2;
    --disable: #a5a5a5;
    --common-font: 'Poppins', sans-serif; 
    --drblue: #346EF5;
    --drgray: #929292;
    --border-gray: #D8D8D8; 
    --text-color: #505050;
    --body-emphasize-font-family: "Poppins-SemiBold", Helvetica;
    --body-emphasize-font-size: 14px;
    --body-emphasize-font-style: normal;
    --body-emphasize-font-weight: 600;
    --body-emphasize-letter-spacing: 0.14px;
    --body-emphasize-line-height: normal;
    --body-regular-font-family: "Poppins-Regular", Helvetica;
    --body-regular-font-size: 14px;
    --body-regular-font-style: normal;
    --body-regular-font-weight: 400;
    --body-regular-letter-spacing: 0.14px;
    --body-regular-line-height: normal;
    --body-small-font-family: "Poppins-Regular", Helvetica;
    --body-small-font-size: 12px;
    --body-small-font-style: normal;
    --body-small-font-weight: 400;
    --body-small-letter-spacing: 0.12px;
    --body-small-line-height: normal;
    --brandblue: rgba(52, 110, 245, 1);
    --brandlight-blue: rgba(232, 239, 255, 1);
    --dropdown: 0px 4px 12px 0px rgba(0, 0, 0, 0.25);
    --neutralsblack: rgba(0, 0, 0, 1);
    --neutralsdark-grey: rgba(80, 80, 80, 1);
    --neutralslight-grey-2: rgba(234, 234, 234, 1);
    --neutralslight-grey-3: rgba(216, 216, 216, 1);
    --neutralswhite: rgba(255, 255, 255, 1);
    --on-hover: 0px 4px 12px 0px rgba(0, 0, 0, 0.25);
    --statusred: rgba(249, 40, 40, 1);
    --body-emphasize-font-family: "Poppins-SemiBold", Helvetica;
    --body-emphasize-font-size: 14px;
    --body-emphasize-font-style: normal;
    --body-emphasize-font-weight: 600;
    --body-emphasize-letter-spacing: 0.14px;
    --body-emphasize-line-height: normal;
    --body-regular-font-family: "Poppins-Regular", Helvetica;
    --body-regular-font-size: 14px;
    --body-regular-font-style: normal;
    --body-regular-font-weight: 400;
    --body-regular-letter-spacing: 0.14px;
    --body-regular-line-height: normal;
    --body-small-font-family: "Poppins-Regular", Helvetica;
    --body-small-font-size: 12px;
    --body-small-font-style: normal;
    --body-small-font-weight: 400;
    --body-small-letter-spacing: 0.12px;
    --body-small-line-height: normal;
    --brandblue: rgba(52, 110, 245, 1);
    --brandlight-blue: rgba(232, 239, 255, 1);
    --neutralsblack: rgba(0, 0, 0, 1);
    --neutralsdark-grey: rgba(80, 80, 80, 1);
    --neutralsgrey: rgba(146, 146, 146, 1);
    --neutralslight-grey-1: rgba(248, 248, 248, 1);
    --neutralslight-grey-2: rgba(234, 234, 234, 1);
    --neutralslight-grey-3: rgba(216, 216, 216, 1);
    --neutralswhite: rgba(255, 255, 255, 1);
    --on-hover: 0px 4px 12px 0px rgba(0, 0, 0, 0.25);
    --statusgreen: rgba(10, 161, 16, 1);
    --statusred: rgba(249, 40, 40, 1);
    --statusstatus: rgba(255, 170, 43, 1);
    --neutralslight-grey-4: rgba(186, 186, 186, 1);
  }
}
*{
    font-family: var(--common-font);
}
html {
  overflow: auto;
}
.children {
    padding-left: 16px;
}
.css-ahj2mt-MuiTypography-root {
    font-family: var(--common-font);
}
.default_text {
    font-family: var(--common-font);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
}
.builder_head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 9px 20px;
  border-bottom: 1px solid #D8D8D8;
}
.builder_head_left {
    width:25%;
    &-container{
        display: flex;
        gap: 8px;
        div{ 
            cursor: pointer;
            &:hover {
                background: #F8F8F8;
            }
        }
    }
}
@media only screen and (max-width: 1023px) {
    .builder {
      width: 200%;
    }
  }
  @media only screen and (max-width: 511px) {
    .builder {
      width: 300%;
    }
  }
  @media only screen and (max-width: 319px) {
    .builder {
      width: 400%;
    }
  }
.builder_head_center {
    width:50%;
    display: flex;
    justify-content: center;
    gap: 8px;
    div.clickable{ 
        cursor: pointer;
        &:hover {
            background: #F8F8F8;
        }
    }
}
.builder_head_right {
    width:25%;
    justify-content: end;
    gap: 8px;
    display: flex;
    img{ 
        cursor: pointer;
        &:hover {
            background: #F8F8F8;
        }
    }
}
.header_label {
    color: #505050;
    font-family: var(--common-font);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-top: 2px;
}
.header_label_dark {
    color: var(--neutrals-black, #000);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.14px;
}
.header_icon {
    border-right: 1px solid #D8D8D8;
    height: 25px;
    margin-top: 8px;
    padding-right: 20px;
    margin-right: 10px;
    padding-top: 3px;
}
.template_steps_count {
    color: var(--neutrals-dark-grey, #929292);
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.12px;
}
.template_steps_head {
    padding: 16px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #D8D8D8;
}
.template_steps_right_title {
    color: var(--neutrals-black, #000);
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.16px;
}
.template_steps_left {
    cursor: pointer;
    &:hover {
        background: #F8F8F8;
      }
}
.template_steps_body {
    border-bottom: 1px solid #D8D8D8;
}
.template_steps_body_text {
    color: var(--neutrals-charcoal, #505050);
    padding: 16px 20px;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.14px;
}
.template_steps_body_radio_1 {
    padding-top: 5px;
    padding-bottom: 16px;
    padding-right: 20px;
    padding-left: 20px;
}
.template_steps_body_radio_text {
    color: var(--neutrals-charcoal, #505050);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.14px;
}
.template_steps_footer {
    padding: 16px 20px;
    display: flex;
  justify-content: space-between;
  align-items: center;

}
.template_steps_footer_button {
    display: flex;
    gap: 8px;
    margin-left: auto;
}
.builder_body {
    position : relative;
    justify-content: space-between;
    display: flex;
    gap: 20px;
    padding-top: 20px;
}
.section_head {
    padding: 8px 24px;
    display: flex;
    background-color: #346EF5;
    height: 37px;
    border-radius: 8px 8px 0px 0px
    justify-content: space-between;
    align-items: center;
    color: white;
}
.section_head_right {
    display: flex;
    gap: 8px;
}
.action {
    width: 22px;
    text-align: center;
    border: 1px solid #F8F8F802;
    &:hover {
        border: 1px solid #F8F8F830;
        background: #F8F8F850;
        border-radius: 2px;
    }
}
.builder_body_right {
    width: 25%;
}
.builder_body_left {
    width: 25%;
}
.builder_body_left-container {
    float: right;
    width: 60%;
    &_list {
        margin-top:10px;
        height:87vh;
        overflow:auto;
        &-item {
            margin-left: 5%;
            padding: 8px 8px;
            cursor: pointer;
            letter-spacing: 0.14px;
            display: flex;
        }
        &-item:hover .over_hover {
            visibility: visible;   
        }
    }
}
.section_list_selected {
    border: 1px solid #346EF580;
    background-color: #E8EFFF;
    color: #346EF5;
    border-radius: 8px;
    &:hover {
        border: 1px solid #346EF580;
        background: white;
        color: #000000;
    }
}
.builder_body_right_tool {
  border-radius: 2px;
  box-shadow: 0px 4px 8px #00000014;
  width: 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  margin-right: auto;
  transition: transform 0.1s ease-in-out;
}
.builder_body_right_tool_inside {
    padding: 8px 12px;
    cursor: pointer;
    width: 100%;
    &:hover {
        background: #F8F8F8;
      }
}
.builder_body_center {
    width: 50%;
    height:91vh;
    overflow:auto;
}
.section_body {
    background-color: #F8F8F8;
    padding: 16px 24px;
}
.Question_card {
    background-color: white;
    margin: 32px 1px;
    border-left: 6px solid #346EF5;
    border-radius: 10px;
}
.Question_card_head {
    border-bottom: 1px solid #D8D8D8;
    margin-left: 20px;
    margin-right: 20px;
    padding-bottom: 10px
}
.Question_card_head_body {
    padding-top: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid #D8D8D8;
}
.Question_card_head_body_top {
    margin-left: 20px;
    margin-right: 20px;
}
.Question_card_head_footer {
    margin-left: 20px;
    margin-right: 20px;
    justify-content: space-between;
    align-items: center;
    display: flex;
}
.Question_card_head_footer__button {
    cursor: pointer;
    &:hover {
        background: #F8F8F8;
      }
}
.Question_card_head_footer_left {
    display: flex;
}
}
.Question_card_head_footer_right {
    display: flex;
    gap: 8px;
    padding-top: 16px;
    padding-bottom: 16px;
}
.Question_right_title {
    color: var(--neutrals-charcoal, #505050);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-top: 8px;
}
.Question_card_Non_edit {
    background-color: white;
    margin: 16px 0px;
    border-radius: 10px;
    padding: 0px 24px 16px 24px;
}
.Question_card_Non_edit:hover .over_hover {
    visibility: visible;   
  }
.section {
    margin-bottom: 20px;
}
.over_hover {
    visibility: hidden;
    margin-left: auto;
    color: #346EF5;
    font-weight: 400;
    &:hover {
        background: #F8F8F8;
    }
  }
.list_option {
    color: var(--neutrals-charcoal, #505050);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.14px;
    padding: 12px 0px;
    cursor: pointer;
    display: flex;
 }
 .list_option:hover .over_hover {
    visibility: visible;   
  }
 button.add_button {
    color: #346EF5;
    text-transform: none;
    margin-left: 15px;
 }
 .template_data_grid .MuiDataGrid-root {
    border: none !important;
 }
 .template_data_grid .MuiDataGrid-main {
    .MuiDataGrid-cellCheckbox {
        padding-left: 11px;
    } 
    border: none;
    .MuiDataGrid-columnHeaders {
      border-bottom: 1px solid #eaeaea;
    }
    .MuiDataGrid-cell:focus-within{
      outline:none
    }
    .MuiDataGrid-columnSeparator.MuiDataGrid-columnSeparator--sideRight {
      display: none;
    }
    .MuiDataGrid-columnHeader {
    }
    .MuiDataGrid-columnHeaderTitle {
      color: var(--text-color);
      line-height: normal;
    }
    .MuiDataGrid-cell:focus-within{
      outline: none
    }
  }
.MuiTextField-root {
    width: 100%;
}
.upload_holder {
    width: 100%;
    h4 {
      font-size: 14px;
    }
    .upload_p {
      color: var(--text-color);
    }
    .drag_drop {
      color: #000000;
    }
  }
.blue_upload {
    color: var(--drblue);
  }
  .dropZone_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 16px;
    
  }
  .dropzone_2 {
    border: 2px dashed #cccccc;
    border-radius: 4px;
    padding: 20px;
    text-align: center;
    cursor: pointer;
    transition: border 0.3s ease;
    width: 92%;
    min-height: 154px;
    display: grid;
    place-content: center;
    border: 1px dashed var(--drgray);
    border-radius: 4px;
  }
  .dropzone_2:hover {
    border-color: #1890ff;
  }
  .siderbar_drawer_sheets {
    gap: 4px;
    .markup_button_style {
      padding: 10px;
      display: grid;
      text-transform: capitalize;
      font-size: 10px;
      place-items: center;
      height: 70px;
    }
  }
  .sheet-file-view-3 {
    right: 70px;
    height: 432px;
    position: absolute;
    top: 76px;
    width: 112px;
  }
  .frame-wrapper {
    align-items: center;
    background-color: #ffffff;
    border: 1px solid;
    border-color: #eaeaea;
    border-radius: 2px;
    box-shadow: 0px 4px 8px #00000014;
    display: flex;
    flex-direction: column;
    gap: 8px;
    height: 432px;
    left: 64px;
    overflow: hidden;
    padding: 12px;
    position: absolute;
    top: 0;
    width: 48px;
    margin-left: 50px;
  }
  .tool-button {
    &:hover {
      background: #f8f8f8;
    }
    cursor: pointer;
    height: 24px;
    position: relative;
    width: 24px;
  }
  .overlap-group-2 {
    border-radius: 2px;
    height: 24px;
    position: relative;
  }
  .img-wrapper {
    align-items: center;
    border-radius: 2px;
    display: flex;
    height: 24px;
    justify-content: center;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 24px;
  }
  .arrow-drop-down {
    height: 4px;
    left: 18px;
    position: absolute;
    top: 18px;
    width: 4px;
  }

  .line {
    height: 1px;
    object-fit: cover;
    position: relative;
    width: 24px;
  }
  .buttons-an {
    &:hover {
      background: #f8f8f8;
    }
    cursor: pointer;
    align-items: center;
    border-radius: 2px;
    display: flex;
    height: 24px;
    justify-content: center;
    overflow: hidden;
    position: relative;
    width: 24px;
  }
  .img {
    height: 16px;
    position: relative;
    width: 16px;
  }
  .frame-3 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 8px;
    position: relative;
  }
  .formDetail {
    display: flex;
    gap: 48px;
    padding-bottom: 10px;
  }
  .formDetail_export {
    display: flex;
    gap: 48px;
    padding-bottom: 10px;
    padding-top: 10px;
    border-bottom: 1px solid #EAEAEA;
  }
  .formDetail_heading {
    width: 110px;
    color: #505050;
  }
  .drawer-pan {
    align-items: flex-start;
    border: 1px solid;
    border-color: #eaeaea;
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 32px;
    left: 40px;
    position: absolute;
    top: 0px;
    width: 60px;
  }
  .rectangle {
    background-color: #ffffff;
    border-radius: 2px;
    box-shadow: 0px 4px 8px #00000014;
    height: 32px;
    position: relative;
    width: 60px;
  }
  .buttons-4a {
    &:hover {
      background: #f8f8f8;
    }
    cursor: pointer;
    align-items: center;
    border-radius: 2px;
    display: flex;
    height: 24px;
    justify-content: center;
    left: 4px;
    overflow: hidden;
    position: absolute;
    top: 4px;
    width: 24px;
  }
  .highlighter-wrapper {
    &:hover {
      background: #f8f8f8;
    }
    cursor: pointer;
    align-items: center;
    border-radius: 2px;
    display: flex;
    height: 24px;
    justify-content: center;
    left: 32px;
    overflow: hidden;
    position: absolute;
    top: 4px;
    width: 24px;
  }
.form_image {
  background-color: white;
  border: 1px solid #D8D8D8;
}  
.on_hover_form_image {
  position: absolute;
  font-size: 26px;
  color: #fff;
  display: flex;
  visibility: hidden;
  &:hover {
    visibility: visible;
}
  svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
    top: 12px;
    left: 12px;
  }
}
.form_image {
  &:hover {
    .on_hover_form_image {
      visibility: visible;
    }
  }
}
.page_body_settings {
  margin-top: 16px;
  margin: 16px 16px 0px 16px;
  display: flex;
}
.page_body_Settings_left {
  margin-right: 16px;
}
.page_body_Settings_left_menu {
  padding: 8px 16px;
  width: 216px;
  height: 37px;
  color: #505050;
  font-family: var(--common-font);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.14px;
  cursor: pointer;
  &:hover {
    background: #D8D8D8;
   }
}
.page_body_Settings_center_head {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  margin-top: 8px;
}
.page_body_Settings_center {
  width: 100%;
}
.page_body_Settings_center_left {
  color: #505050;
  font-family: var(--common-font);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.16px;
  margin-left: 18px;
}
.page_body_Settings_left_menu_selected {
  padding: 8px 16px;
  width: 216px;
  height: 37px;
  font-family: var(--common-font);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.14px;
  background: #F8F8F8;
  color: #505050;
  cursor: pointer;
  &:hover {
    background: #D8D8D8;
   }
}
.define-sheet-number-area {
  &:hover {
    background: #f8f8f8;
  }
  color: #505050;
  font-weight: 400;
  line-height: normal;
  position: relative;
  width: fit-content;
}
`;

export default FormTemplateStyled;
