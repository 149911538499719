import { Box } from "@mui/material";
import React from "react";

export default function IssueDetailActivity() {
  return (
    <Box className="user_details">
      <Box
        className="profile_image"
        sx={{
          width: "40px",
          height: "40px",
          cursor: "auto",
          flex: "0 0 auto",
          position: "relative",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          background: "#dae1e6",
          borderRadius: "50%",
          alignSelf: "center",
          fontWeight: 700,
          color: "#819099",
        }}
      >
        <img src="/avatar.png" />
      </Box>
      <Box className="comments"></Box>
      <Box className="date">Jun 3, 2021</Box>
    </Box>
  );
}
