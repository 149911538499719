import styled from 'styled-components';


const ImagesCss = styled.div`
&
    .split_images {
        display: flex;
        flex-direction: row;
        padding: 0 20px;
        gap:20px;

        .image {
            img {
                width: 120px;
            }
        }
    }
`;

const WithoutTemplateSection = styled.div`
.new_taplate_text_one {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin: 10px;

    label{
        font-weight: 600;
    }
    input {
        width: 100%;
        max-width: 190px;
        padding: 10px;
        border: 2px solid #0498dc;
    }
}

.slds-card__footer{
    position: sticky;
    bottom: 0;
    padding: 15px;
    background: #ededed;
    text-align: center;
    display: flex;
    justify-content: center;
    gap: 20px;

    button {
        padding: 10px 15px;
        min-width: 160px;
        font-size: 16px;
        border-radius: 10px;
        border: 1px solid #0498dc;
        color: #fff;
        background: #0498dc;
        cursor: pointer;
    }
}

`;

export { ImagesCss, WithoutTemplateSection };


