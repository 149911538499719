import styled from "styled-components";

const ImagesCss = styled.div`
  & .split_images {
    display: flex;
    flex-direction: row;
    padding: 0 20px;
    gap: 20px;

    .image {
      img {
        width: 120px;
      }
    }
  }
`;

const WithoutTemplateSection = styled.div`
  .new_taplate_text_one {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin: 10px;

    label {
      font-weight: 600;
    }
    input {
      width: 100%;
      max-width: 190px;
      padding: 10px;
      border: 2px solid #0498dc;
    }
  }

  .slds-card__footer {
    position: sticky;
    bottom: 0;
    padding: 20px;
    background: #ededed;
    text-align: center;
    display: flex;
    justify-content: center;
    gap: 30px;

    button {
      padding: 13px 25px;
      min-width: 160px;
      font-size: 16px;
      border-radius: 25px;
      border: 1px solid #0498dc;
      color: #fff;
      background: #0498dc;
      cursor: pointer;
    }
  }
`;
const GridViewStyle = styled.div`
    display: block;
    overflow-x: auto;
    overflow-y: auto;
    height: calc(98vh - 200px);
  .main_gridview {
    .MuiGrid-root.MuiGrid-container {
      grid-gap: 14px 14px
     // margin-top: 20px;
      padding: 10px 16px;
    /* }
    .MuiGrid-root.MuiGrid-container { */
      .MuiGrid-root {
        max-width: calc(100% / 4 - 10px);
       // width: 100%;
        flex: calc(100% / 4 - 10px);
        padding: 30px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        .MuiBox-root {
          justify-content: center;
          align-items: center;
          height: 100%;
          /* padding-bottom: 40px; */
          svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeLarge {
            opacity: 0.3;
          }
        }
        .MuiCardActions-root.MuiCardActions-spacing {
          position: absolute;
          bottom: 0;
          z-index: 9;
          height: 50px;
        //  width: 100%;
          background: #f7f9fa;
          display: flex;
         // justify-content: space-between;
        //  padding: 10px 20px;
        }
      }
      .MuiCardContent-root{
        padding: 0;
      }
      .MuiCardActions-root {
        padding: 0;
      }
      .MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1{
       // max-height: 220px;
      // height: 236px;
      }
      .MuiPaper-elevation.MuiPaper-rounded {
       // height: 255px;
       // background-color: #f7f9fa;
       // border: 1px solid #dae1e6;
       // box-shadow: 0 2px 4px 0 rgb(32 60 94 / 30%);
        position: relative;
        justify-content: space-between;
        & > img {
          margin: auto;
          height: 55%;
          /* object-fit: contain; */
		  object-fit: cover;
          width: 92%;
         // padding: 45px 15px 50px;
        }
        span.version {
          padding: 2px 6px;
          background-color: #edf0f2;
          border-radius: 2px;
          max-width: fit-content;
        }
        &:hover {
          .on_hover_Class {
            visibility: visible;
          }
          .on_hover_Class-2 {
            visibility: visible;
          }
        }
        .on_hover_Class {
          position: absolute;
          top: 0;
          left: 0;
          background-image: linear-gradient(#00000029, #00000000);
          font-size: 26px;
          color: #fff;
          display: flex;
          visibility: hidden;
          padding-bottom: 50px;
          padding-right: 300px;
          svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
            top: 12px;
            left: 12px;
          }
        }
        .on_hover_Class-2 {
          position: absolute;
          top: 0;
          left: 0;
          font-size: 26px;
          color: #fff;
          display: flex;
          visibility: hidden;
          padding-bottom: 50px;
          padding-right: 300px;
          svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
            top: 12px;
            left: 12px;
          }
        }
        span.MuiCheckbox-root.MuiCheckbox-colorPrimary {
          padding: 10px;
          position: absolute;
          z-index: 99;
          display: flex;
          justify-content: center;
          left: 3px;
          top: 5px;
          svg {
           // background: #fff;
          }
        }
      }
      p.value {
        text-align: left;
        margin-top: 10px;
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    & > span.MuiCheckbox-root.MuiCheckbox-colorPrimary {
      padding: 10px;
    }
    .leftflex {
      padding-bottom: 0;
      svg {
        font-size: 20px;
        color: #bcc9d1;
      }
    }
 
  }
  .version {
    align-items: center;
background-color: #E8EFFF;
border: 1px solid;
border-color: #346EF5;
border-radius: 12px;
display: flex;
justify-content: center;
overflow: hidden;
//padding: 2px 8px;
}
position: relative;
width: fit-content;
  }
  .version-1 {
    color: #346EF5;
    font-weight: 400;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    text-align: center;
    width: 70px;
    }
    .cardColor {
      background-color: #E8EFFF;
        border: 1px solid #346EF5;
      }
      .cardColor-1 {
          border: 1px solid #D8D8D8;
        }
        .cardColor-2 {
          &:hover { 
            background-color: #EAEAEA;
            }
          background-color: #F8F8F8;
          border: 1px solid #D8D8D8;
        }
        .tr2 {
          position: absolute;
        }
        .checking {
          transform: scale(1.5);
        }
.folder-list {
  border-radius: 2px;
  display: flex;
  width: 215px;
  padding: 10px 12px; 
  background-color: #F8F8F8;
  border: 1px solid #D8D8D8; 
  height: 45px; 
  justify-content: space-between;
  &:hover { 
    background-color: #EAEAEA;
    }
}
.folder-list-select {
  display: flex;
  width: 215px;
  padding: 12px 12px; 
  background: linear-gradient(0deg, #E8EFFF, #E8EFFF);
  border: 1px solid #D8D8D8; 
  height: 45px; 
  justify-content: space-between;
}
  span.MuiCheckbox-root.MuiCheckbox-colorPrimary {
    background: white;
    border-radius: 0px;
    width: 18px;
    height: 18px;
}
span.MuiCheckbox-root.MuiCheckbox-colorPrimary:hover {
  background: #F8F8F8;
}
`;

const FolderTabledesign = styled.div``;

export { ImagesCss, WithoutTemplateSection, FolderTabledesign, GridViewStyle };