import React, { useState , useEffect} from "react";
import { Button } from "@mui/material";
import { cloneDeep } from "lodash";
import { answersPreArr } from 'components/FormTemplateTabComponent/ChecklistFormTemplate/constants';

const PreConfigured = ({handleChange ,responseOptions}) => {
  const [answers, setAnswers] = useState([]);
  const [selectedPairInd, setSelectedPairInd] = useState(-1);
  useEffect(() => {
    try {
      if(responseOptions && responseOptions.length > 0){
        setAnswers(responseOptions);
        for(let i = 0 ; i < answersPreArr.length ; i++){
          if(JSON.stringify(responseOptions) == JSON.stringify(answersPreArr[i])){
            setSelectedPairInd(i);
            break;
          }
        }
      }
    } catch (e) {
      console.log('responseOptions parse error :: '+e.message);
    }
  },[responseOptions]);
  const handleSelectOption = (index) => {
    setSelectedPairInd(index);
    setAnswers(answersPreArr[index]);
    handlePush(answersPreArr[index]);
  };
  const handlePush = (_answer = answers) => {
    const tmpArr = cloneDeep(_answer);
    let jsonTosend ;
    if(tmpArr && tmpArr.length > 0 ){
      jsonTosend = tmpArr ;
    }
    handleChange({target : {name : 'responseOptions' , value : jsonTosend } });
  };
  return (
    <div>
      {answersPreArr.map((combo , ind) => (
        <Button key={ind+1} variant={(selectedPairInd == ind ? 'contained' : 'outlined')} name={ind} className="preconfigured_btns"
         onClick={ e => handleSelectOption(ind)}>
          {combo.map((opti , oind) => (
            opti.label + (oind < (combo.length-1) ? " / " : '')
          ))}
        </Button>
      ))}
    </div>
  );
};

export default PreConfigured;
