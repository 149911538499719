import styled from "styled-components";

const CreateIssuePopupstyle = styled.div`
  .createIssuePopup_class {
    .MuiAutocomplete-root,
    .MuiFormControl-root,
    .grouped_autocomplete.autocomplete_section,
    .normal_autocomplete.autocomplete_section {
      width: 100% !important;
      max-width: 100%;
    }
    .d-flex {
      max-width: 600px;
      margin: 0 auto;
      .MuiBox-root {
        margin-bottom: 20px;
      }
    }
    .selectedDiv {
      margin-bottom: 20px;
    }
    hr.MuiDivider-root {
      margin: 0 0 20px 0;
    }
    label.MuiFormLabel-root {
      font-size: 14px;
      margin-bottom: 10px;
      font-weight: 600;
      color: #000;
    }
    textarea.common_class {
      width: 100% !important;
      flex: 100%;
      border: 1px solid #c4c4c4;
      resize: none;
      padding: 10px;
      border-radius: 4px;
    }
    .textarea.d-flex {
      flex-wrap: wrap;
    }
    .linked-document_main {
      margin-bottom: 0 !important;
    }

    .MuiAutocomplete-input {
      padding: 0 !important;
      font-size: 14px;
      min-height: 30px;
    }
  }

  .due-date {
    flex-wrap: wrap;
    .MuiInputBase-colorPrimary.MuiInputBase-formControl .MuiInputBase-input {
      padding: 0;
      height: 45px;
    }
    label.MuiFormLabel-root {
      margin-bottom: 8px;
    }
    .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary {
      margin-top: 3px;
      padding: 0 15px;
    }
  }
  .linked-document {
    align-items: center;
    button.MuiButtonBase-root.MuiButton-root.MuiButton-text {
      padding: 0;
      border: none;
      background: none;
      margin: 0;
      font-weight: 600;
      margin-left: 7px;
      color: #0176d3;
      text-transform: capitalize;
    }
  }
`;

export default CreateIssuePopupstyle;
