import React, { useState} from "react";
import IconButton from "@mui/material/IconButton";
import DownloadIcon from "@mui/icons-material/Download";
import Tooltip from "@mui/material/Tooltip";
import styled from "styled-components";

import {
  Button,
  Dialog,
  Checkbox,
} from "@mui/material";

const Styles = styled.div`
.buttons {
  cursor: pointer;
  align-items: center;
  border-radius: 2px; display: flex;
 // height: 24px;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 24px;
  }
.close {
  &:hover {
    background: #F8F8F8;
  }
  height: 16px;
  position: relative;
  width: 16px;
  }
.modal-header {
    align-items: flex-start;
    background-color: #FFFFFF;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-color: #EAEAEA;
    border-radius: 2px 2px 0px 0px;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    padding: 16px 20px;
    position: relative;
    width: 400px;
    }
.place {
    color: #000000;
    font-family: var(--font-family-poppins); 
    font-size: var(--font-size-m);
    font-weight: 600;
    letter-spacing: 0.16px;
    line-height: normal;
    margin-top: -1px;
    position: relative; 
    width: fit-content;
  }
.checkbox {
  align-items: flex-start; 
  background-color: #FFFFFF;
  border-radius: 2px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  position: relative;
}
.label {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
  color: #505050;
}
.checkbox-1 {
  //background-color: #346EF5;
  border-radius: 2px;
  height: 20px;
  position: relative;
  width: 20px;
  }
.check {
  &:hover {
    background: #F8F8F8;
  }
    color: #D8D8D8;  
    height: 16px; 
    left: 2px;
    position: absolute;
    top: 2px;
    width: 16px;
    }
    .modal-body {
      align-items: flex-start;
      background-color: #FFFFFF; 
      display: flex;
      flex-direction: column;
      gap: 12px;
      justify-content: center;
      padding: 16px 20px;
      position: relative;
      width: 400px;
      }
.file-format-pdf-file {
    color: #505050;
    font-weight: 400;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    width: 360px;
    } 
.file-format {
      align-items: flex-start;
      background-color: #FFFFFF; 
      border: 1px solid;
      border-color: #EAEAEA; 
      display: inline-flex;
      flex: 0 0 auto;
      flex-direction: column;
      gap: 10px;
      margin-right: -8px;
      position: relative;
      }
      .references {
        align-items: flex-start;
        background-color: #F8F8F8; 
        border: 1px solid;
        border-color: #EAEAEA;
        border-radius: 2px;
        display: inline-flex; 
        flex-direction: column;
        gap: 12px;
        padding: 12px;
        position: relative;
        }
.rectangle-36 {
        background-color: #F8F8F8;
        border: 1px solid;
        border-color: #EAEAEA;
        border-radius: 2px;
        height: 42px;
        position: relative;
        width: 368px;
}
.frame-31 {
  cursor: pointer;
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 344px;
  }
  .frame-30 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 4px;
    position: relative;
    }
.sheets, 
.down {
        height: 16px; 
        position: relative;
        width: 16px;
}
.sheets-for-export-1 {
        color: #505050;
        font-weight: 400;
        line-height: normal; 
        margin-top: -1px; position: 
        relative; width: 
        fit-content;
}
.buttons-3 {
  &:hover {
    background: #F8F8F8;
  }
  cursor: pointer;
  align-items: center;
  background-color: #FFFFFF; 
  border: 1px solid;
  border-color: #505050;
   border-radius: 2px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  justify-content: center;
  overflow: hidden;
  padding: 9px 16px;
  position: relative;
  }
.label-3 {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px; 
  position: relative;
  width: fit-content;
}
.buttons-1 {
  cursor: pointer;
  align-items: center;
  background-color: #346EF5;
  border-radius: 2px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  overflow: hidden;
  padding: 9px 16px;
  position: relative;
  }
.label-1 {
  color: #FFFFFF;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative; width: 
  fit-content;
  }
.modal-bottom {
  align-items: center;
  background-color: #FFFFFF;
  border-color: #EAEAEA;
  border-radius: 0px 0px 2px 2px;
  border-top-style: solid;
  border-top-width: 1px;
  display: flex;
  gap: 12px;
  justify-content: flex-end;
  overflow: hidden;
  padding: 12px 20px;
  position: relative;
  width: 400px;
  }
.photos {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 8px;
    position: relative;
    }
.fa-10000 {
    color: #000000;
    font-weight: 400;
    line-height: normal;
    margin-top: -1px; 
    position: relative; 
    width: fit-content;
    }
.fa-10 {
    color: #000000;
    font-weight: 400;
    line-height: normal; 
    position: relative; 
    width: fit-content;
    }
`;
export const ExportFileDialog = ({
  open,
  markupDownload,
  handleClose,
  selectedFiles,
  onAccept,
  callFrom
}) => {
    const [showDropdown, setShowDropdown] = useState(false);
  const [exportWithMarkup, setExportWithMarkup] = useState(false);
  //console.log("selectedFiles ::", selectedFiles);
  const exportPdf = async (fileUrl) => {
    onAccept(selectedFiles, exportWithMarkup);
  }
  return (
    <Dialog
      open={open}
      onClose={handleClose}
    >
      <Styles>
        <div className="modal-header">
          <div className="place">
            Export
          </div>
          <div className="buttons">
            <img onClick={handleClose} className="close" src="close.svg" alt="close" />
          </div>
        </div>
        <div className="modal-body">
          <div
            className="file-format-pdf-file body-regular">
            File format:  {callFrom == "FilesComponent" ? selectedFiles.length > 1 || selectedFiles[0].type == "folder" ? "Zip File" : "PDF File" : "PDF File"}
          </div>
          <div className="references">
            <div className="frame-31" onClick={() => setShowDropdown(!showDropdown)}>
              <div className="frame-30">
                <img className="sheets" src="sheets.svg" alt="sheets" /> <div className="sheets-for-export-1 body-small">
                  {callFrom == "FilesComponent" ? "Items" : "Sheets"} for export ({selectedFiles.length})
                </div>
              </div>
              <img className="down" src="down.svg" alt="down" /> </div>
            {showDropdown &&
              <div className="photos body-small">
                {selectedFiles &&
                  selectedFiles.map((item) => (
                    <div className="fa-10000">
                      {callFrom == "FilesComponent" ? item.title : item.Document_Number__c}{item.type == "folder" && " (folder)"}
                    </div>
                  ))
                }

              </div>
            }
          </div>

          <div className="checkbox">
            <div className="checkbox-1">
              <Checkbox style={{ padding: 0 }} className="check" checked={markupDownload} onChange={(e) => {
                console.log("e.target.checked", e.target.checked);
                setExportWithMarkup(e.target.checked);
              }} />
            </div>
            <div className="label poppins-normal-abbey-14px">
              With Markup
            </div>
          </div>
        </div>
        <div className="modal-bottom">
          <Button onClick={handleClose} className="custom_button cancel_button">
            Cancel
          </Button>
          <Button className="custom_button apply_button" onClick={exportPdf}>
            Export
          </Button>
        </div>
      </Styles>
    </Dialog>
  );
};

// TODO:: variable for dont ask me again checkbox
const browserWarningCheck = true;

const ExportFile = (props) => {
  const { versionData, currentFolderName } = props;
  const [open, setOpen] = React.useState(false);
  const [exportWithMarkup, setExportWithMarkup] = useState(true);
  // active step 1 for browser warning, 2 for export files
  const [activeStep, setActiveStep] = useState(1);

  const handleClickOpen = (e) => {
    if (browserWarningCheck) {
      setActiveStep(1);
    } else {
      setActiveStep(2);
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const gotoStepTwo = () => {
    setActiveStep(2);
  };

  const downloadFiles = async () => {
    //console.log('download files :: '+JSON.stringify(versionData));
    let url = versionData.verAUrl;
    let fileName = versionData.name;
    let fileExt = versionData.fileExt;
    if (url) {
      await downloadFile(url, fileName, fileExt, exportWithMarkup, versionData.markupJSON);
    }
    handleClose();
  };

  const downloadFile = async (url, fileName, fileExt, includeMarkup, instantJSONMarkup) => {
    if (currentFolderName?.type?.toLowerCase() === 'plan' && (!fileExt || fileExt === null)) {
      fileExt = 'pdf';
    }
    if (!fileName.toLowerCase().endsWith('.' + fileExt.toLowerCase())) {
      fileName += '.' + fileExt.toLowerCase();
    }
    if (fileName.toLowerCase().endsWith('.pdf') || currentFolderName?.type?.toLowerCase() === 'plan') {
      let confi = {
        baseUrl: `${window.location.protocol}//${window.location.host}/${process.env.PUBLIC_URL}`,
        document: url,
        headless: true
      };
      if (includeMarkup && instantJSONMarkup) {
        let listAA = JSON.parse(instantJSONMarkup).annotations;
        for (let ann of listAA) {
          if (ann.customData) {
            ann.customData.source = 'export';
          }
        }
        let annotationList = listAA;
        confi.instantJSON = {
          format: "https://pspdfkit.com/instant-json/v1",
          annotations: annotationList,
        };
      }
      let PSPDFKit = await import("pspdfkit");
      let instance = await PSPDFKit.load(confi);
      await instance.exportPDF().then((buffer) => {
        const blob = new Blob([buffer], { type: "application/pdf" });
        const fName = fileName;
        if (window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob, fName);
        } else {
          const objectUrl = URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = objectUrl;
          a.style = "display: none";
          a.download = fName;
          document.body.appendChild(a);
          a.click();
          URL.revokeObjectURL(objectUrl);
          document.body.removeChild(a);
        }
      });
    } else {
      downloadMedia(url, fileName);
    }
  };
  const downloadMedia = (url, fileName) => {
    const a = document.createElement("a");
    a.href = url;
    a.style.display = "none";
    a.download = `${fileName}`;
    a.setAttribute("download", fileName);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <>
      <Tooltip
        title="Export"
        placement="top"
        PopperProps={{ className: "version-action-tooltip" }}
        arrow
      >
        <IconButton
          aria-label="download"
          size="small"
          className="file-icon-btn"
          onClick={handleClickOpen}
        >
          <DownloadIcon />
        </IconButton>
      </Tooltip>
      {open && (
        <ExportFileDialog
          open={open}
          activeStep={activeStep}
          markupDownload={exportWithMarkup}
          handleClose={handleClose}
          gotoStepTwo={gotoStepTwo}
          setMarkupDownload={setExportWithMarkup}
          downloadFiles={downloadFiles}
        />
      )}
    </>
  );
};

ExportFile.propTypes = {};

export default ExportFile;