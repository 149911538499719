import { useState, useEffect } from "react";
import { TextField, Select, MenuItem, Switch, Radio, Checkbox, Tooltip } from "@mui/material";
import ResponseTypeBuilder from './ResponseTypeBuilder';
const ChecklistQuestionCard = ({ section ,question, dispatchObj, responseOptions, sectionIndex, questionIndex, ...props }) => {
    const {setSelectedSection ,isSignature, 
                currentEditableElement , setEditableElement , 
                clickAwayPressed , templatePublished ,drag_source } = props
    const [editMode, setEditModeState] = useState((currentEditableElement === question.identifier && !templatePublished));
    const setEditMode = (value)=>{
      if(!templatePublished){
        setEditModeState(value);
      }else{
        setEditModeState(false);
      }
    };
    useEffect(() => {
        setEditMode(currentEditableElement === question.identifier);
    }, [currentEditableElement]);
    useEffect(() => {
        setEditMode(false);
    }, [clickAwayPressed]);
    const handleChange = e => {
        let fieldKey = e.target.name;
        let value = e.target.value;
        if (e.target.type === 'checkbox') {
            value = e.target.checked;
            if (fieldKey === 'Optional__c' || fieldKey === 'Conditional_Logic__c') {
                value = e.target.checked; // only using opposite / (! value) value bcz toggel/switch button is using opposite / (! value) value to determine checked value
            }
        }
        dispatchObj({
            type: 'HANDLE_CHANGE_QUESTION_FIELD',
            sectionIndex,
            questionIndex,
            fieldKey: fieldKey,
            value: value
        });
    };
    const removeQuestion = e => {
        dispatchObj({ type: 'REM_QUESTION_WARNING', sectionIndex, questionIndex });
    };
    const onDuplicateQuestion = e => {
        dispatchObj({ type: 'DUPLICATE_QUESTION', sectionIndex, questionIndex });
    };
    const moveUpQuestion = e => {
      dispatchObj({type: 'MOVE_QUESTION' , sectionIndex , from : questionIndex , to : (questionIndex - 1)});
    };
    const moveDownQuestion = e => {
      dispatchObj({type: 'MOVE_QUESTION' , sectionIndex , from : questionIndex , to : (questionIndex + 1) });
    };
    return (
        <div>
            {editMode == true &&
                <div className="Question_card">
                    <div className="Question_card_head">
                        <div style={{ width: "38px", paddingLeft: "10px", margin: "0 auto", cursor: "pointer" }}>
                            <span className="">
                                <img src="/drag.svg" alt="dragIcon" {...drag_source.dragHandleProps}/>
                            </span>
                        </div>
                        <Select role="presentation"
                            variant="standard"
                            disableUnderline={true}
                            labelId="form_response_type"
                            id={`responseType-${question.identifier}`}
                            name="Response_Type__c" label="Response type"
                            value={question.Response_Type__c}
                            onChange={handleChange}
                            disabled={question.Response_Type__c === 'Signature'}>
                            {responseOptions?.filter((op) => op?.value !== 'Signature' || question.Response_Type__c === 'Signature')?.map((r) => (
                                <MenuItem key={r.value} value={r.value}>
                                    {r.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                    <div className="Question_card_head_body">
                        <div className="Question_card_head_body_top">
                            <TextField id={`question-${question.identifier}`} name="Question__c" sx={{ "& .MuiInputBase-root": { color: "#505050", height: "37px", padding: "4px", marginBottom: "10px" }, }}
                                inputProps={{ maxLength: 255 }}
                                variant="outlined"
                                className="Searchbar-field"
                                autoComplete="off"
                                placeholder={isSignature ? 'Signature By' : "Question"}
                                required
                                value={question.Question__c} onChange={handleChange} fullWidth />
                            <TextField id={`questionDescription-${question.identifier}`} name="Description__c" sx={{ "& .MuiInputBase-root": { color: "#505050", height: "37px", padding: "4px", marginBottom: "10px" }, }}
                                inputProps={{ maxLength: 255 }}
                                variant="outlined"
                                className="Searchbar-field"
                                autoComplete="off"
                                placeholder="Description (optional)"
                                value={question.Description__c} onChange={handleChange} fullWidth />
                        </div>
                        <ResponseTypeBuilder conditionLogic={question.Conditional_Logic__c} currentEditableElement={currentEditableElement} handleChange={handleChange} responseOptions={question.responseOptions} selectedType={question.Response_Type__c} />
                    </div>
                    <div className="Question_card_head_footer">
                        <div className="Question_card_head_footer_left">
                            {!isSignature &&
                                <>
                                    <div className="Question_right_title">
                                        Optional
                                    </div>
                                    <div>
                                        <Switch name="Optional__c" checked={question.Optional__c} onChange={handleChange} inputProps={{ 'aria-label': 'controlled' }} />
                                    </div>
                                    {(question.Response_Type__c === "Preconfigured" || question.Response_Type__c === "Multiple Choice" || question.Response_Type__c === "Dropdown") /*&& question.Optional__c*/ &&
                                        <div style={{ display: "flex" }}>
                                            <div className="Question_right_title">
                                                Conditional Logic
                                            </div>
                                            <div>
                                                <Switch name="Conditional_Logic__c" checked={question.Conditional_Logic__c} onChange={handleChange} inputProps={{ 'aria-label': 'controlled' }} />
                                            </div>
                                        </div>
                                    }
                                </>
                            }
                        </div>
                        <div className="Question_card_head_footer_right">
                            {questionIndex != 0 &&
                                <div className="Question_card_head_footer__button" onClick={moveUpQuestion}>
                                    <img src="/up.svg" alt="moveUp" />
                                </div>
                            }
                            {questionIndex !== (props?.totalQuestions - 1) &&
                                <div className="Question_card_head_footer__button" onClick={moveDownQuestion}>
                                    <img src="/down.svg" alt="moveDown" />
                                </div>
                            }
                         <Tooltip title="Duplicate" placement="bottom" >
                            <div className="Question_card_head_footer__button" onClick={onDuplicateQuestion}>
                                <img src="/copy.svg" alt="copyQue" />
                            </div>
                            </Tooltip>
                            <Tooltip title="Delete" placement="bottom" >
                            <div onClick={removeQuestion} className="Question_card_head_footer__button">
                                <img src="/delete.svg" alt="deleteQue" />
                            </div>
                            </Tooltip>
                        </div>
                    </div>
                </div >
            }
            {editMode == false &&
                <div className="Question_card_Non_edit">
                    <div style={{ width: "38px", margin: "0 auto" , height :'16px'}}>
                        {!templatePublished &&
                            <span className="over_hover">
                                <img src="/drag.svg" alt="dragIcon" {...drag_source.dragHandleProps}/>
                            </span>
                        }
                    </div>
                    <div className="template_steps_count" style={{ paddingBottom: "10px" }}>
                        {isSignature ? 'Signature' : 'Question'} {question.Numbering_Sequence__c} of {props?.totalQuestions}
                    </div>
                    <div className="header_label_dark builder_body_left_Outline" onClick={() => {
                        setSelectedSection(section.identifier);
                        setTimeout(()=>{setEditableElement(question.identifier);setEditMode(true);},100);
                    }}>
                        {question.Question__c && question.Question__c.trim() != '' ? question.Question__c : (isSignature ? 'Signature By' : 'Question')}
                        {question.Optional__c === false ? (
                            <span style={{ color: 'red' }}>*</span>
                        ) : (
                            <span style={{ color: '#505050' }}> (optional)</span>
                        )}
                    </div>
                    {!isSignature && question.Description__c && question.Description__c.trim() != '' &&
                        <div style={{ padding: "0px" }} className="template_steps_body_text" onClick={() => {
                            setSelectedSection(section.identifier);
                            setTimeout(()=>{setEditableElement(question.identifier);setEditMode(true);},100);
                        }}>
                            {question.Description__c && question.Description__c.trim() != '' ? question.Description__c : 'Description (optional)'}
                        </div>
                    }
                    <div style={{ padding: "0px" }} className="template_steps_body_text">
                        {question.Response_Type__c == "Preconfigured" && question.responseOptions?.length > 0 &&
                            <>
                                {question.responseOptions.map((opti, oind) => (
                                    opti.label + (oind < (question.responseOptions.length - 1) ? " / " : '')
                                ))}
                                {question.Conditional_Logic__c && question.responseOptions.filter((opt) => opt.action && opt.action != 'na' ).length > 0 &&
                                    <>
                                        &nbsp;(with Conditional Logic)
                                    </>
                                }
                            </>
                        }
                        {(question.Response_Type__c == "Text" || question.Response_Type__c == "Number" || question.Response_Type__c == "Date") && question.Response_Type__c + " Response"}
                        {(question.Response_Type__c == "Multiple Choice" || question.Response_Type__c == "Dropdown" || question.Response_Type__c == "Checkbox") && question.responseOptions?.map((opt, ind_) => (
                            <>
                                <div key={opt.value + '-' + ind_} className="list_option" style={{padding: "6px 0px"}}>
                                    <div style={{ display: "flex", width: "100%" }} >
                                        <div style={{ width: "25%", maxWidth: '30px' }}>
                                            {question.Response_Type__c === 'Multiple Choice' && <Radio className="checkbox-style" style={{ padding: "0px" }} disabled />}
                                            {question.Response_Type__c === 'Dropdown' && ind_ + 1 + "."}
                                            {question.Response_Type__c === 'Checkbox' && <Checkbox className="checkbox-style" style={{ padding: "0px" }} disabled />}
                                        </div>
                                        <div style={{ width: "70%" }}>
                                            {opt.value}
                                            {question.Conditional_Logic__c && opt.action && opt.action != 'na' &&
                                                <>
                                                    &nbsp;(with Conditional Logic)
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </>
                        ))}
                    </div>
                </div>
            }
        </div>
    );
}
export default ChecklistQuestionCard;