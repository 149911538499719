import React, { useEffect ,useState } from "react";
import axios from "axios";
import { fetchUrl } from "Urls";
import { IconButton, Box, Button ,TextField ,Divider} from "@mui/material";
import { CloseRounded } from "@mui/icons-material";
import Spinner from "components/spinner";
import moment from "moment";

const DocumentComments = (props) => {
  const { credential ,documentReviewId, comments , canEditComments ,handleClose} = props;
  const [cmt, setCmt] = useState("");
  const [showSpinner, setSpinner] = useState(false);
  useEffect(() => {
  }, [comments]);
  return (
    <>
      <div className="containert" style={props?.containerStyle ? props?.containerStyle : {}}>
        <header className="header">
          <div className="versions-sidebar-header">
            <span className="versions-sidebar-header-title">
              {comments && comments?.length}{" "}
              Comments
            </span>
            <IconButton size="small" className="versions-sidebar-header-close" onClick={handleClose} >
              <CloseRounded />
            </IconButton>
          </div>
          <Divider variant="full"/>
        </header>
        <main className="bodyt" style={!canEditComments ? {maxHeight: 'calc(100vh - 200px)'} : {} }>
          {comments && comments?.map((f, index) => (
            <div key={f.Id} className={`versions-sidebar-filecard`} role="button" onClick={() => { console.log('cmt selected',f.Id); }}>
              <span className="versions-sidebar-filecard-version">
                <span className="title"></span>{" "}
                <span className="status" style={{ color: 'black' }} >
                </span>
              </span>
              <span className="versions-sidebar-filecard-detail">
                <span className="versions-sidebar-filecard-detail-row">
                  Comment: {f?.Comment__c}
                  <br />
                </span>
                <span className="versions-sidebar-filecard-detail-row">
                  Owner: {f?.CreatedBy?.Name}
                  <br />
                </span>
                <span className="versions-sidebar-filecard-detail-row">
                  Date: {moment(f?.CreatedDate).format("DD MMM YYYY HH:mm")}
                  <br />
                </span>
              </span>
            </div>
          ))}
        </main>
        <Divider variant="full"/>
        {canEditComments && 
          <footer className="footert">
            <Box>
              <TextField
                id="commentInput"
                variant="standard"
                label="Comment"
                placeholder="Type Comment.."
                value={cmt}
                multiline
                rows={2}
                maxRows={3}
                required
                onChange={(e) => {
                  setCmt(e?.target?.value);
                }}
                fullWidth
                onKeyDown={(event) => {
                  if (event.key.toLowerCase() != "tab") {
                    event.stopPropagation();
                  }
                }}
              />
            </Box>
            <div className="versions-sidebar-footer" role="button" onClick={async() => {
                if(!cmt || cmt == '' || showSpinner ) return;
                setSpinner(true);
                const formData2 = new FormData();
                let sRec = {Document_Review__c : documentReviewId , Comment__c : cmt}
                formData2.append("recObj", JSON.stringify(sRec));
                await axios.post(`${fetchUrl}/recSave/Document_Review_Comment__c?token=${credential?.token}&instanceUrl=${credential?.instanceUrl}`,formData2)
                .then((res) => {
                  //console.log("count:::: " + JSON.stringify(res));
                  if(res.status === 200){
                    handleClose(true);
                  }
                }).catch((err) => {
                  console.log(err);
                });
                setSpinner(false);
              }} >
              Add Comment
            </div>
          </footer>
        }
      </div>
    </>
  );
};

export default DocumentComments;
