import { ListItemButton, Tooltip } from "@mui/material"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import colorConfigs from "../../configs/colorConfigs"

const SidebarItem = ({ item, sidebarOpen }) => {
  const { navState } = useSelector(state => state.navState)

  return item.sidebarProps && item.path ? (
    <ListItemButton style={{display: item.sidebarProps.display && item.sidebarProps.display}}
      component={Link}
      to={item.path}
      replace={true}
      state={{}}
      sx={{
        "&: hover": {
          backgroundColor: "#F8F8F8",
          color: "#000000"
        },
        backgroundColor: navState === item.state ? colorConfigs.sidebar.activeBg : "unset",
        color: navState === item.state ? "#346EF5" : "#808080",
        paddingY: "12px",
        paddingX: "24px",
        marginLeft: sidebarOpen ? "5px" : "",
        fontWeight: navState === item.state && "bold",
      }}
    >
      <Tooltip title={!sidebarOpen && item.sidebarProps.displayText} placement="right-end">
        <img className={navState === item.state ? "left_menu_image_selected" : "left_menu_image"}width="20" height="20" src={item.sidebarProps.icon} alt="www"
          style={{
            marginRight: "10px",
          }}
        />
      </Tooltip>
      <span>{item.sidebarProps.displayText}</span>
    </ListItemButton>
  ) : null
}

export default SidebarItem
