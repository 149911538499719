import React, {useRef, useCallback, useState, useEffect } from "react";
import SignatureCanvas from "react-signature-canvas";
import { Select ,FormControl ,MenuItem, Grid, Button, TextField,ButtonGroup } from "@mui/material";

const SignatureRender = ({ handleChange , req , ...props}) => {
  const { responseOptions,defaultValue , ansObj, checklistFormobj } = props;
  const [sobj , setSobj] = useState(ansObj);
  const padRef = useRef(null);
  //console.log('ansObj :: ',ansObj);

  const clearSignatureCanvas = useCallback(() => {
    padRef?.current?.clear();
    handleChange({target : {name: 'Signature__c' , value: null}});
  }, []);
  useEffect(() => {
    if(sobj?.Signature__c && sobj?.Signature__c != ''){
      padRef?.current?.fromDataURL(sobj?.Signature__c);
    }
  },[]);
  const handleGetCanvas = useCallback(() => {
    const canvas = padRef?.current?.toDataURL();
    //console.log('canvas',canvas);
    handleChange({target : {name: 'Signature__c' , value: canvas}});
  }, []);
  const handleGetCanvas_onEnd = useCallback(() => {
    const canvas = padRef?.current?.toDataURL();
    //console.log('canvas end',canvas);
    if(checklistFormobj.Status__c !== "Completed"){
    handleChange({target : {name: 'Signature__c' , value: canvas}});
    }
  }, []);
  return (
    <>
      <Grid container rowSpacing={2}>
        <Grid item xs={4}>
          <TextField id="name" variant="outlined" label="Name" defautValue={sobj?.Print_Name__c}
            onChange={(e) => {
              if(checklistFormobj.Status__c !== "Completed"){
              let fff = sobj; fff.Print_Name__c = e?.target?.value; setSobj(fff);
              }
            }}
            onKeyDown={(event) => {
              if (event.key.toLowerCase() != "tab") {
                event.stopPropagation();
              }
            }}
            onBlur={(e) => {
              if(checklistFormobj.Status__c !== "Completed"){
              //console.log('e?.target?.value :: '+e?.target?.value);
              handleChange({target : {name: 'Print_Name__c' , value: e?.target?.value}});
              }
            }} />
        </Grid>
        <Grid item xs={4}>
          <TextField id="company" variant="outlined" label="Company" defautValue={sobj?.Company_Name__c}
            onChange={(e) => {
              if(checklistFormobj.Status__c !== "Completed"){
              let fff = sobj; fff.Company_Name__c = e?.target?.value; setSobj(fff);
              }
            }}
            onKeyDown={(event) => {
              if (event.key.toLowerCase() != "tab") {
                event.stopPropagation();
              }
            }}
            onBlur={(e) => {
              if(checklistFormobj.Status__c !== "Completed"){
              //console.log('e?.target?.value :: '+e?.target?.value);
              handleChange({target : {name: 'Company_Name__c' , value: e?.target?.value}});
              }
            }} />
        </Grid>
        <Grid item>
          <Grid container rowSpacing={2}>
            <Grid item>
              <div style={{ backgroundColor: "white" }}>
                <SignatureCanvas
                  ref={padRef}
                  onEnd ={handleGetCanvas_onEnd}
                  canvasProps={{
                    width: 400,
                    height: 125
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <ButtonGroup variant="outlined" className="table-btn-group" >
                <Button variant="text" component="span" onClick={clearSignatureCanvas}>
                  Clear
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default SignatureRender;
