import { useState, useEffect } from "react";
import CloseIcon from "assets/images/close.svg";
import { Button, Dialog, TextField, MenuItem, Select } from "@mui/material";
import Radio from '@mui/joy/Radio';
import Paper from '@mui/material/Paper';
import errorRed from "assets/images/error-red.svg";
import { DataGrid } from '@mui/x-data-grid';
import axios from "axios";
import { fetchUrl } from "Urls";
import RadioGroup from '@mui/joy/RadioGroup';
const ImportTemplates = (props) => {
    const { onAccept, onCancel, currentStep, setCurrentStep, credential } = props;
    const [selectedProjectId, setSelectedProjectId] = useState();
    const [projectTemplateList, setProjectTemplateList] = useState([]);
    const [sourceProjectTemplateList, setSelectedProjectTemplateList] = useState([]);
    const [existingTemplates, setExistingTemplates] = useState([]);
    const [selectedRowIds, setSetSelectedRowIds] = useState([]);
    const [selectedRows, setSetSelectedRows] = useState([]);

    const labelStyle = {
        '&:hover': { backgroundColor: '#EAEAEA' }, height: "40px", paddingLeft: "20px",
        borderBottom: "1px solid #D8D8D8", margin: "0px", paddingTop: "10px"
    };
    const columns = [
        {
            field: 'Name',
            headerName: 'Name',
            width: 260,
            renderCell: (param) => {
                return (
                    <>
                        <div style={{ display: "flex", gap: "10px" }} className="file_area">
                            {param.row.Form_Template__r.Name}
                        </div>
                    </>
                );
            },

        },
        {
            field: 'Type', headerName: 'Type', width: 210,
            renderCell: (param) => {
                return (
                    <>
                        <div style={{ display: "flex", gap: "10px" }} className="file_area">
                            {param.row.Form_Template__r.Form_Type__c}
                        </div>
                    </>
                );
            },
        },
    ];
    useEffect(() => {
        const preImported = [];
        const reqBody = { fields: ['Project__c', 'Project__r.Name', 'Form_Template__c', 'Form_Template__r.Name', 'Form_Template__r.Form_Type__c'], filter: `Status__c='Active'` };
        axios.post(`${fetchUrl}/query/Project_Form_Template__c?token=${credential?.token}&instanceUrl=${credential?.instanceUrl}`, reqBody)
            .then(async (res) => {
                if (res.status === 200) {
                    const re_data = res.data;
                    if (re_data.status == 200) {
                        setProjectTemplateList(re_data.records);
                        if(re_data.records?.length > 0){
                            for(let tem of re_data.records){
                                if(tem.Project__c === credential?.projectId){
                                    if(preImported.indexOf(tem.Form_Template__c) === -1){
                                        preImported.push(tem.Form_Template__c);
                                    }
                                }
                            }
                        }
                    }
                }
                //console.log('preImported',preImported);
                setExistingTemplates(preImported);
            }).catch(e => {
                console.log("error >>>>>>>>>>>>", JSON.stringify(e));
                console.error(e);
                setExistingTemplates(preImported);
                return undefined;
            });
    }, []);
    const handleChange = (event) => {
        setSelectedProjectId(event.target.value);
        let filterTemplate = projectTemplateList.filter((tem) => tem.Project__c == event.target.value);
        //console.log("filterTemplate ::", filterTemplate);
        setSelectedProjectTemplateList(filterTemplate);
    };
    const handleNextStep = (event) => {
        setCurrentStep((prev) => (prev + 1));
    };
    const handleBackStep = (event) => {
        setCurrentStep((prev) => (prev - 1));
    };
    const uniqueProjectIds = {};

    // Use filter to create a new array with unique project names and IDs
    const uniqueProjects = projectTemplateList.filter((tem) => {
        // If the project ID is not in uniqueProjectIds, add it and return true (keep the project)
        if (!uniqueProjectIds[tem.Project__c] && tem.Project__c != credential.projectId) {
            uniqueProjectIds[tem.Project__c] = true;
            return true;
        }
        // If the project ID is already in uniqueProjectIds, return false (filter out the project)
        return false;
    });
    function getRowId(row) {
        return row.Form_Template__c;
    }
    function checkIfRowSelectableId(param) {
        let isSelectable = true;
        if(existingTemplates.indexOf(param.row.Form_Template__c) > -1){
            isSelectable = false;
        }
        //console.log("row ::",existingTemplates, isSelectable,param);
        return isSelectable;
    }
    //console.log('templateOptions :: ', templateOptions);
    return (
        <Dialog open={true}>
            <Paper sx={{ width: "548px" }}>
                <div className="template_steps">
                    <div className="template_steps_head">
                        <div className="template_steps_right">
                            <div className="template_steps_count">
                                step {currentStep} of 2
                            </div>
                            <div className="template_steps_right_title">
                                Import Template
                            </div>
                        </div>
                        <div className="template_steps_left" onClick={onCancel}>
                            <img src={CloseIcon} alt="icons" />
                        </div>
                    </div>
                    <div className="template_steps_body" style={{ height: currentStep === 1 && "400px" }}>
                        {currentStep === 1 &&
                            <>
                                <div className="template_steps_body_text">
                                    Select a project with the template you wish to import
                                </div>
                                <div className="template_steps_body_radio" style={{ height: "325px", position: "relative",'overflowX': 'hidden'}}>
                                    {projectTemplateList.length > 0 &&
                                        <RadioGroup defaultValue="outlined" name="radio-buttons-group" value={selectedProjectId} onChange={handleChange}>
                                            {uniqueProjects.map(templates => (
                                                <Radio sx={{ backgroundColor: selectedProjectId == templates.Project__c && "#E8EFFF", ...labelStyle }} value={templates.Project__c} label={<span className="template_steps_body_radio_text" style={{ paddingLeft: '24px' }}>{templates.Project__r.Name}</span>} variant="outlined" />
                                            ))}
                                        </RadioGroup>
                                    }
                                {projectTemplateList.length < 1 &&
                                    <div style={{ height: "100%", display: "flex", alignItems: "center", justifyContent: "center", color: "#505050", fontWeight: 600, fontSize: '14px' }}>No Projects to Show</div>
                                }
                                </div>
                            </>
                        }
                        {currentStep === 2 &&
                            <>
                                <div className="template_steps_body_text">
                                    Choose templates for importing. You can select a maximum of 50 templates. The imported templates will be added into this project under their current names.
                                </div>
                                {sourceProjectTemplateList.length > 0 &&
                                    <div className="template_data_grid">
                                        <DataGrid
                                            style={{ maxHeight: "500px" }}
                                            getRowId={getRowId}
                                            className="add-sheet_extract-table"
                                            rows={sourceProjectTemplateList}
                                            isRowSelectable={checkIfRowSelectableId}
                                            columns={columns}
                                            hideFooter
                                            disableColumnMenu
                                            sortingMode
                                            checkboxSelection 
                                            rowSelectionModel={selectedRowIds}
                                            onRowSelectionModelChange={(newSelectionIds) => {
                                                const newSelectedRows = newSelectionIds.map(id => sourceProjectTemplateList.find(row => getRowId(row) === id));
                                                console.log("newSelectedRows  ::", newSelectedRows);
                                                setSetSelectedRows(newSelectedRows);
                                                setSetSelectedRowIds(newSelectionIds);
                                            }}
                                        />
                                    </div>
                                }
                            </>
                        }
                    </div>
                    <div className="template_steps_footer">
                        {currentStep === 1 &&
                            <div className="template_steps_footer_button">
                                <Button className="custom_button cancel_button" onClick={onCancel}>Cancel</Button>
                                <Button className="custom_button apply_button" disabled={projectTemplateList < 1} onClick={handleNextStep}>Next</Button>
                            </div>
                        }
                        {currentStep === 2 &&
                            <>
                            <div style={{display: "flex", gap: "12px"}}>
                                <div>{selectedRowIds.length} Selected</div>
                                {selectedRowIds.length > 0 &&
                                    <div style={{ color: "#346EF5", cursor: "pointer" }} onClick={() => setSetSelectedRowIds([])}>Clear</div>
                                }
                            </div>
                                <div className="template_steps_footer_button">
                                    <Button className="custom_button cancel_button" onClick={handleBackStep}>Back</Button>
                                    <Button className="custom_button apply_button" disabled={selectedRowIds.length < 1} onClick={() => { onAccept(selectedRowIds, selectedRows) }}>
                                        Import
                                    </Button>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </Paper>
        </Dialog>
    );
}
export default ImportTemplates;