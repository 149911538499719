import React from "react";
import PropTypes from "prop-types";
import moment from 'moment';

import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ContentPasteGoIcon from '@mui/icons-material/ContentPasteGo';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import Checkbox from '@mui/material/Checkbox';

import SetCurrentVersion from './SetCurrentVersion';
import ExportFile from './ExportFile';

import axios from "axios";
import { fetchUrl } from "Urls";

const VersionData = (props) => {
  const { compareActive , versionInfo , refreshList, handleFileSelection , setSpinner , currentFolderName} = props;

  const handleFileChecked = e => {
    handleFileSelection(e, versionInfo.versionId);
  };
  const handleSourceDownload = async (e) => {
    setSpinner(true);
    await axios
      .get(`${fetchUrl}/source/download/${versionInfo.awsAttId}/${versionInfo.version}`)
      .then(async (res) => {
        //console.log("source :: "+JSON.stringify(res));
        if (res?.status === 200) {
          let url = res.data.url;
          let fileName = res.data.fileName;
          let fileExt = res.data.fileExt;
          if(url && fileName){
            await downloadFile(url, fileName, fileExt,false, null);
          }
        }
      })
    .catch((err) => {
      console.log(err);
      setSpinner(false);
    });
    setSpinner(false);
  };
  const downloadFile = async (url, fileName, fileExt,includeMarkup ,instantJSONMarkup) => {
    if(currentFolderName?.type?.toLowerCase() === 'plan' && (!fileExt || fileExt=== null)){
      fileExt = 'pdf' ;
    }
    if(!fileName.toLowerCase().endsWith('.'+fileExt.toLowerCase())){
      fileName += '.'+fileExt.toLowerCase();
    }
    if(fileName.toLowerCase().endsWith('.pdf') || currentFolderName?.type?.toLowerCase() === 'plan'){
      let confi = {baseUrl: `${window.location.protocol}//${window.location.host}/${process.env.PUBLIC_URL}`,
                    document: url,
                    headless: true } ;
      if(includeMarkup && instantJSONMarkup){
        let listAA = JSON.parse(instantJSONMarkup).annotations;
        for(let ann of listAA){
          if(ann.customData){
            ann.customData.source = 'export';
          }
        }
        let annotationList = listAA;
        confi.instantJSON = {
          format: "https://pspdfkit.com/instant-json/v1",
          annotations: annotationList,
        };
      }
      let PSPDFKit = await import("pspdfkit");
      let instance = await PSPDFKit.load(confi);
      await instance.exportPDF().then((buffer) => {
        const blob = new Blob([buffer], { type: "application/pdf" });
        const fName = fileName;
        if (window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob, fName);
        } else {
          const objectUrl = URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = objectUrl;
          a.style = "display: none";
          a.download = fName;
          document.body.appendChild(a);
          a.click();
          URL.revokeObjectURL(objectUrl);
          document.body.removeChild(a);
        }
      });
    }else{
      downloadMedia(url , fileName);
    }
  };
  const downloadMedia = (url, fileName) => {
    const a = document.createElement("a");
    a.href = url;
    a.style.display = "none";
    a.download = `${fileName}`;
    a.setAttribute("download", fileName);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };
  //console.log(' VersionData versionInfo :: '+JSON.stringify(versionInfo));
  return (
    <Grid container>
      {compareActive && (
        <Grid item xs={1} className="file-data file-version">
          <Checkbox value={versionInfo.selected} checked={versionInfo.selected} onChange={handleFileChecked} />
        </Grid>
      )}
      <Grid item xs={1} className="file-data file-version">
        {versionInfo.versionLabel}
      </Grid>
      <Grid item xs={compareActive ? 8 : 9} className="file-data file-desc">
        Uploaded by <span>{versionInfo.ownerName} </span> at{" "}
        <span>{moment(versionInfo.lastUpdated).format('LL')}{/*Sep 21, 2020 6:19 AM*/}</span>
        <div className="file-actions">
          <ExportFile versionData={versionInfo} currentFolderName={currentFolderName}/>
          {currentFolderName?.type?.toLowerCase() == "plan" && 
            <IconButton aria-label="source-download" size="small" className="file-icon-btn"  onClick={handleSourceDownload}>
              <DownloadForOfflineIcon />
            </IconButton>
          }          
          <IconButton aria-label="copy" size="small" className="file-icon-btn">
            <ContentCopyIcon />
          </IconButton>
          <IconButton aria-label="file" size="small" className="file-icon-btn">
            <ContentPasteGoIcon />
          </IconButton>
        {versionInfo.isLatest == false && ( 
          <SetCurrentVersion currentVersion={versionInfo} refreshList={refreshList}/>
        )}
        </div>
      </Grid>
      {versionInfo.isLatest && ( 
        <Grid item xs={2} className="file-data old-version">
          {/* <span>DOB Submission Submission</span> */}
          <span className="current-version">Current</span>
        </Grid>
      )}
    </Grid>
  );
};

VersionData.propTypes = {};

export default VersionData;
