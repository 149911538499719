import styled from "styled-components";

const ReviewStyle = styled.div`
  .table {
    .thead {
      background: #f7f9fa;
      .tr {
        div {
          font-weight: 600;
          box-shadow: none;
          text-align: left;
        }
      }
    }
    .tbody > .tr {
      background: #fff;
    }
  }
  .container {
    .heading {
      font-weight: 600;
      background: #f1f1f1;
      padding: 10px 20px;
      font-size: 18px;
      font-family: "circular";
      display: flex;
      gap: 10px;
      .btn.btn-ornage {
        background: #ffa600;
        color: #fff;
        padding: 3px 5px;
        border-radius: 30px;
        font-size: 12px;
        font-weight: 600;
        height: 30px;
      }
    }
    .heading + div {
      max-height: calc(100vh - 250px);
    }
  }
  .table.tabreview .td {
    font-size: 12px;
    box-shadow: none;
  }

  .table_id {
    .id_content.MuiBox-root {
      width: 120px;
      margin-left: 0;
      transform: translateX(0);
      display: block;
    }
  }
  .container {
    padding: 0 10px;
  }
`;

export default ReviewStyle;
