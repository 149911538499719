import { Clear } from "@mui/icons-material";
import React from "react";
import {
  AutoCompleteCheckbox,
  AutoCompleteGroupCheckbox,
  AutoCompleteSelect,
} from "components/Shared/AutoCompleteComponent";
import { TYPES_FILTER_OPTIONS, STATUS_OPTIONS } from "../IssuesConstants";
import IssueFilterStyle from "./IssuesFilter.style";
import DatePickerComponent from "components/Shared/DatePickerComponent";
import { Box, Checkbox } from "@mui/material";

const IssuesFilter = (props) => {
  const { _handleFilterSelection, typeFilter, statusFilter } =
    props?.issueFilterProps;
  return (
    <IssueFilterStyle>
      <div className="main_filter_section">
        <div className="filter_header">
          <div className="filter_Title">
            <h3>Filter</h3>
          </div>
          <div className="actions">
            <div>
              <a>Clear All</a>
            </div>
            <div>
              <Clear />
            </div>
          </div>
        </div>
        <div className="field_sections">
          <Box component={"div"} className="type_filter">
            <AutoCompleteGroupCheckbox
              open={true}
              options={TYPES_FILTER_OPTIONS}
              label="Types"
              placeholder={
                typeFilter?.length > 0
                  ? `${typeFilter?.length} Selected`
                  : "Select Type"
              }
              onSelected={(value) => _handleFilterSelection("type", value)}
            />
          </Box>
          <Box component={"div"} className="status_filter">
            <AutoCompleteCheckbox
              open={true}
              options={STATUS_OPTIONS}
              label="Status"
              placeholder={
                statusFilter?.length > 0
                  ? `${statusFilter?.length} Selected`
                  : "Select Type"
              }
              onSelected={(value) => _handleFilterSelection("status", value)}
            />
          </Box>
          <Box component={"div"} className="location_filter">
            <AutoCompleteSelect
              open={true}
              options={STATUS_OPTIONS}
              label="Location"
              placeholder={"Select a location"}
              onSelected={(value) => _handleFilterSelection("status", value)}
            />
            <Box component={"div"} className="checkbox_section">
              <div>
                <Checkbox />
              </div>{" "}
              <span>Include sub-locations</span>
            </Box>
          </Box>
          <Box component={"div"} className="owner_filter">
            <AutoCompleteCheckbox
              open={true}
              options={STATUS_OPTIONS}
              label="Owner"
              placeholder={
                statusFilter?.length > 0
                  ? `${statusFilter?.length} Selected`
                  : "Select Owner"
              }
              onSelected={(value) => _handleFilterSelection("status", value)}
            />
          </Box>
          <Box component={"div"} className="root_cause_filter">
            <AutoCompleteGroupCheckbox
              open={true}
              options={TYPES_FILTER_OPTIONS}
              label="Root cause"
              placeholder={
                typeFilter?.length > 0
                  ? `${typeFilter?.length} Selected`
                  : "Select Root cause"
              }
              onSelected={(value) => _handleFilterSelection("type", value)}
            />
          </Box>
          <Box component={"div"} className="createdBy_filter">
            <AutoCompleteCheckbox
              open={true}
              options={STATUS_OPTIONS}
              label="Created by"
              placeholder={
                statusFilter?.length > 0
                  ? `${statusFilter?.length} Selected`
                  : "Select Created by"
              }
              onSelected={(value) =>
                _handleFilterSelection("Created by", value)
              }
            />
          </Box>
          <Box component={"div"} className="attribute_filter">
            <h3>Custom attributes filters</h3>
            <AutoCompleteCheckbox
              open={true}
              options={STATUS_OPTIONS}
              label="Select custom attribute filters to use"
              placeholder={
                statusFilter?.length > 0
                  ? `${statusFilter?.length} Selected`
                  : "Select Created by"
              }
              onSelected={(value) => _handleFilterSelection("attribute", value)}
            />
          </Box>
          {/* date pick has 4 type desktop, mobile, time, date_and_time */}
          {/* <DatePickerComponent
                        type="desktop"
                        dateValue={dueDate}
                        label="Due Date"
                        onDateChange={(value) => _handleFilterSelection("due_date", value)}
                        format="MM/DD/YYYY"

                    /> */}
        </div>
      </div>
    </IssueFilterStyle>
  );
};

export default IssuesFilter;
