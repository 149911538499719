import React, { useState, useRef } from "react";
import { useParams } from 'react-router-dom';
import { useSelector } from "react-redux";
import axios from "axios";
import { fetchUrl } from "Urls";
import { ToastContainer, toast } from "react-toastify";
import { Button } from "@mui/material";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "components/spinner";
import FieldTicketSign from "./FieldTicketSign"
import left_icon from "assets/images/left.svg";
import right_icon from "assets/images/right.svg";
//import sampleDoc from "./Sample Doc/1PageNonsigned.pdf";
//import style from "./style";
export default function FieldTicketSignContainer() {
   const { canvasData = {} } = useSelector((state) => state.canvasData);
   const params = useParams();
   const [credentials, setCredentials] = useState();
   const [showSpinner, setSpinner] = React.useState(false);
   const [fieldticketIds, setfieldticketIds] = useState(params?.fieldticketId ? params?.fieldticketId : ''); // a0UOz0000003fWTMAY,a0UOz0000002OpRMAU,a0UDb000002hMvJMAU
   const [ftRecords, setFtRecords] = useState([]);
   const [currentFTIndex, setCurrentFTIndexState] = useState(-1);
   const setCurrentFTIndexRef = useRef(currentFTIndex);
   const declineButtonRef = useRef(null);
   const signButtonRef = useRef(null);
   const [showPdfContainer, setPdfContainer] = React.useState(false);
   const [isCurrentFtSigned, setIsCurrentFtSigned] = React.useState(false);
   const [ftCurrentCompleted, setCurrentFtCompleted] = React.useState(false);
   /** Webviewer Variables End */
   React.useEffect(() => {
      getFieldTicketToSign();
   }, []);
   React.useEffect(() => {
      setSpinner(false);
      setPdfContainer(false);
      if(ftRecords?.length > 0 && ftRecords[currentFTIndex]){
         setTimeout(() => {
            setIsCurrentFtSigned(ftRecords[currentFTIndex].signed);
            setCurrentFtCompleted(ftRecords[currentFTIndex].completed);
            setPdfContainer(true);
         }, 500);
      }
   }, [currentFTIndex]);
   const setCurrentFTIndex = (value)=>{
      setCurrentFTIndexRef.current = value;
      setCurrentFTIndexState(value);
   }
   async function getFieldTicketToSign() {
      setSpinner(true);
      let reqUrl = `${fetchUrl}/getSignToFieldTickets`;
      if (fieldticketIds && fieldticketIds !== '' && fieldticketIds !== undefined) {
         reqUrl += '?fieldticketId=' + fieldticketIds;
      }
      if(canvasData?.orgUserkey){
         reqUrl += '&orgUserkey=' + canvasData?.orgUserkey;
      }
      await axios.get(reqUrl).then(async (res) => {
         if (res.status === 200) {
            setCredentials(res.data?.credential);
            setFtRecords(res.data?.ft_records);
            setCurrentFTIndex(0);
         }
         if (res.data?.error) {
            toast.error(res.data?.errorMessage);
         }
      }).catch((error) => {
         setSpinner(false);
         console.log("error >>>>>>>>>>>>", JSON.stringify(error));
         console.error(error);
      });
      setSpinner(false);
   }
   function handleNext(currentInd = currentFTIndex ) {
      if(ftRecords?.length > 1){
         let nextInd = currentInd + 1;
         if(nextInd  > (ftRecords?.length - 1) ){
            nextInd = 0;
         }
         //console.log('nextInd ::',nextInd);
         setCurrentFTIndex(nextInd);
      }
   }
   function handlePrevious(currentInd = currentFTIndex ) {
      if(ftRecords?.length > 1){
         let nextInd = currentInd - 1;
         if(nextInd < 0){
            nextInd = (ftRecords?.length - 1);
         }
         //console.log('nextInd ::',nextInd);
         setCurrentFTIndex(nextInd);
      }
   }
   function onFieldTicketAction(ftId , action , fileUrl) {
      if(ftRecords?.length > 0){
         const currentInd = ftRecords?.findIndex((rec) => rec.Id === ftId );
         //console.log('currentInd :: ',currentInd , 'currentFTIndex :: ',setCurrentFTIndexRef.current);
         if(currentInd > -1 && ftRecords[currentInd]){
            setFtRecords((preValue)=>{
               if(action === 'signed'){
                  preValue[currentInd].signed = true;
               }else if(action === 'completed'){
                  preValue[currentInd].completed = true;
               }else if(action === 'fileUrl'){
                  preValue[currentInd].fileUrl = fileUrl;
               }
               setIsCurrentFtSigned(preValue[setCurrentFTIndexRef.current].signed);
               setCurrentFtCompleted(preValue[setCurrentFTIndexRef.current].completed);
               return preValue;
            });
         }
      }
   }
   async function closeCanvasQuickAction() {
      setSpinner(true);
      let reqUrl = `${fetchUrl}/close_canvas/${canvasData?.fieldticketId}`;
      if(canvasData?.orgUserkey){
         reqUrl += '?orgUserkey=' + canvasData?.orgUserkey;
      }
      await axios.put(reqUrl).then(async (res) => {

      }).catch((error) => {
         setSpinner(false);
         console.log("error >>>>>>>>>>>>", JSON.stringify(error));
         console.error(error);
      });
      setSpinner(false);
   }
   return (
      <div>
         <ToastContainer />
         <div className="slds-modal__header builder_head" style={{ padding: '1rem' }}>
            <div className="builder_head_left">
               <div className="builder_head_left-container">
                  <>
                     {/*canvasData?.fieldticketId &&
                        <div onClick={() => {closeCanvasQuickAction();}}>
                           Close
                        </div>
                     */}
                     {(!canvasData || !canvasData?.action) &&
                        <div className="header_label" style={{ cursor: 'default', padding: '0' }}>
                           Field Ticket Sign
                        </div>
                     }
                  </>
               </div>
            </div>
            <div className="builder_head_center">
               {!(currentFTIndex > -1) &&
                  <>
                     <div className="header_label_dark">Field Ticket Sign</div>
                  </>
               }
               {currentFTIndex > -1 &&
                  <>
                     {ftRecords?.length > 1 &&
                        <div className="clickable custom_button" onClick={() => handlePrevious(currentFTIndex)}>
                           <img src={left_icon} alt="leftIcon" />
                        </div>
                     }
                     <div className="header_label_dark">{ftRecords[currentFTIndex]?.Name}</div>
                     {ftRecords?.length > 1 &&
                        <div className="clickable custom_button" onClick={() => handleNext(currentFTIndex)}>
                           <img src={right_icon} alt="leftIcon" />
                        </div>
                     }
                  </>
               }
            </div>
            <div className="builder_head_right">
            </div>
         </div>
         {/*<header className="slds-modal__header" style={{ fontSize: 'large' }}>
            <h1 className="slds-hyphenate">
               <span style={{ cursor: 'pointer' }} onClick={()=>handlePrevious(currentFTIndex)}>P</span>&nbsp;&nbsp;&nbsp;&nbsp;
               Field Ticket Sign
               &nbsp;&nbsp;&nbsp;&nbsp;<span style={{ cursor: 'pointer' }} onClick={()=>handleNext(currentFTIndex)}>N</span>
            </h1>
         </header>*/}
         <div id='container-sign-element' style={{ position: "relative", overflow: 'auto', height: (isCurrentFtSigned || ftCurrentCompleted ? '93vh' : '85vh') }}>
            {showSpinner && <Spinner />}
            {showPdfContainer && currentFTIndex > -1 &&
               <FieldTicketSign
                  canvasData={canvasData}
                  credentials={credentials}
                  ftRecords={ftRecords}
                  showSpinner={showSpinner}
                  setSpinner={setSpinner}
                  currentFTIndex={currentFTIndex}
                  handlePrevious={handlePrevious}
                  onFieldTicketAction={onFieldTicketAction}
                  declineButtonRef={declineButtonRef}
                  signButtonRef={signButtonRef}
               />
            }
         </div>
         {currentFTIndex > -1 && !isCurrentFtSigned && !ftCurrentCompleted &&
            <footer className="slds-modal__footer">
               <Button className="custom_button apply_button" style={{ marginRight: "5px" }} onClick={() => {
                  if (declineButtonRef) {
                     declineButtonRef.current.click();
                  }
               }} disabled={showSpinner || !showPdfContainer}>Decline To Sign</Button>
               <Button className="custom_button apply_button" onClick={() => {
                  if (signButtonRef) {
                     signButtonRef.current.click();
                  }
               }} disabled={showSpinner || !showPdfContainer}>Save</Button>
            </footer>
         }
      </div>
   );
}