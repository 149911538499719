import { useState, useEffect } from "react";
import CloseIcon from "assets/images/close.svg";
import { Button, Dialog, TextField, MenuItem, Select } from "@mui/material";
import Radio from '@mui/joy/Radio';
import Paper from '@mui/material/Paper';
import errorRed from "assets/images/error-red.svg";
import RadioGroup from '@mui/joy/RadioGroup';
const ChecklistTemplateDetail = (props) => {
    const { onAccept, onCancel, templateOptions, sfObj, currentStep, setCurrentStep, templateList, duplicatTemplate } = props;
    const [sfRecord, setSfRecord] = useState(sfObj ? JSON.parse(JSON.stringify(sfObj)) : null);
    const [selectedTemplateId, setSelectedTemplateId] = useState(duplicatTemplate ? sfObj?.Id : 'blank');
    const [selectedTemplate, setSelectedTemplate] = useState('blank');
    const labelStyle = {
        '&:hover': { backgroundColor: '#EAEAEA' }, height: "40px", paddingLeft: "20px",
        borderBottom: "1px solid #D8D8D8", margin: "0px", paddingTop: "10px"
    };
    const handleChange = (event) => {
        setSelectedTemplateId(event.target.value);
        if(event.target.value != "blank"){
        let filterTemplate = templateList.filter((tem)=> tem.Id == event.target.value);
        console.log("filterTemplate ::", filterTemplate[0]);
        let fff = sfRecord;
        fff.Description__c = filterTemplate[0]?.Description__c ? filterTemplate[0]?.Description__c : "";
        fff.Template_Category__c = filterTemplate[0]?.category
        setVariables(fff);
        setSelectedTemplate(filterTemplate[0]);
        }
    };
    useEffect(() => {
        //console.log('files use effect ::',userData?.projectId,_projectId);
        if (duplicatTemplate) {
            let fff = {} ; // Create a shallow copy to avoid modifying the original object// Remove the Id property
            fff.Name = sfRecord.Name + '(1)';
            fff.Template_Category__c = sfRecord.Template_Category__c;
            fff.Description__c = sfRecord?.Description__c ? sfRecord?.Description__c : "";
            setVariables(fff);
        }
    }, [duplicatTemplate]);
    const handleNextStep = (event) => {
        setCurrentStep((prev) => (prev + 1));
    };
    const handleBackStep = (event) => {
        setCurrentStep((prev) => (prev - 1));
    };
    function setVariables(_sfObj) {
        setSfRecord(JSON.parse(JSON.stringify(_sfObj)));
    }
    //console.log('templateOptions :: ', templateOptions);
    return (
        <Dialog open={true}>
            <Paper sx={{ width: currentStep === 1 ? "548px" : "400px" }}>
                <div className="template_steps">
                    <div className="template_steps_head">
                        <div className="template_steps_right">
                            {!sfObj?.Id &&
                                <div className="template_steps_count">
                                    step {currentStep} of 2
                                </div>
                            }
                            <div className="template_steps_right_title">
                                {!sfObj?.Id ? 'Create Checklist Template' : duplicatTemplate ? "Duplicate Template" : 'Edit Template Details'}
                            </div>
                        </div>
                        <div className="template_steps_left" onClick={onCancel}>
                            <img src={CloseIcon} alt="icons" />
                        </div>
                    </div>
                    <div className="template_steps_body" style={{ height: currentStep === 1 && "400px" }}>
                        {currentStep === 1 &&
                            <>
                                <div className="template_steps_body_text">
                                    You can start with a pre-designed template and customize it to suit your needs, or you can begin with a completely blank template.
                                </div>
                                <div className="template_steps_body_radio" style={{height: "325px", position: "relative", overflowY: "auto", width: "545px", overflowX: "hidden"}}>
                                    <RadioGroup defaultValue="outlined" name="radio-buttons-group" value={selectedTemplateId} onChange={handleChange}>
                                        <Radio sx={{ backgroundColor: selectedTemplateId == "blank" && "#E8EFFF", ...labelStyle }} value="blank" label={<span className="template_steps_body_radio_text" style={{ paddingLeft: '24px' }}>Blank Template</span>} variant="outlined" />
                                        {templateList && templateList.filter((template)=> template.type == "Checklist" && template.status == "Active").map(templates => (  
                                        <Radio sx={{ backgroundColor: selectedTemplateId == templates.Id && "#E8EFFF", ...labelStyle }} value={templates.Id} label={<span className="template_steps_body_radio_text" style={{ paddingLeft: '24px' }}>{templates.Name}</span>} variant="outlined" />
                                        ))}
                                    </RadioGroup>
                                </div>
                            </>
                        }
                        {currentStep === 2 &&
                            <>
                                {!sfObj?.Id &&
                                    <div className="template_steps_body_text">
                                        Kindly provide the essential details to continue
                                    </div>
                                }
                                <div className="template_steps_body_radio_1">
                                    <div style={{marginBottom: "15px"}}>
                                        <div className="issue_label_name" style={{ paddingTop: "0px" }}>Template Name</div>
                                        <div>
                                            <TextField sx={{ "& .MuiInputBase-root": { color: "#505050", height: "37px", padding: "4px", width: "356px", }, }}
                                                inputProps={{ maxLength: 80 }}
                                                id="outlined-basic"
                                                variant="outlined"
                                                className={!sfRecord.Name ? "Searchbar-field-red" : "Searchbar-field"}
                                                autoComplete="off"
                                                required
                                                value={sfRecord?.Name ? sfRecord?.Name : ""}
                                                onChange={(e) => {
                                                    let fff = sfRecord;
                                                    fff.Name = e?.target?.value;
                                                    setVariables(fff);
                                                }}
                                                onKeyDown={(event) => {
                                                    if (event.key.toLowerCase() != "tab") {
                                                        event.stopPropagation();
                                                    }
                                                }}
                                            />
                                        </div>
                                        {!sfRecord.Name &&
                                            <div style={{ fontSize: "12px", color: "red", paddingTop: "5px", fontWeight: 400}}>
                                                <img style={{ marginRight: "5px" }} src={errorRed} alt="error" /> This is required. Please input an Template Name.
                                            </div>
                                        }
                                    </div>
                                    <div style={{marginBottom: "15px"}}>
                                        <div className="issue_label_name" style={{ paddingTop: "0px" }}>Template Category</div>
                                        <div>
                                            <Select id="type" style={{ height: "37px", width: "356px", color: "#505050"}}
                                            sx={{"& .MuiOutlinedInput-notchedOutline": {borderColor: !sfRecord.Template_Category__c && "red"}}}
                                                value={sfRecord?.Template_Category__c ? sfRecord?.Template_Category__c : ""}
                                                onChange={(event) => {
                                                    let fff = sfRecord;
                                                    fff.Template_Category__c = event?.target?.value;
                                                    setVariables(fff);
                                                }}>
                                                {templateOptions?.checkListOptions?.Template_Category__c?.options &&
                                                    templateOptions?.checkListOptions?.Template_Category__c?.options.map(
                                                        (opt, index) => (
                                                            <MenuItem key={opt.value} value={opt.value} >
                                                                {opt.label}
                                                            </MenuItem>
                                                        )
                                                    )}
                                            </Select>
                                        </div>
                                        {!sfRecord.Template_Category__c &&
                                            <div style={{ fontSize: "12px", color: "red", paddingTop: "5px", fontWeight: 400 }}>
                                                <img style={{ marginRight: "5px" }} src={errorRed} alt="error" /> This is required. Please select an Template Category.
                                            </div>
                                        }
                                    </div>
                                    <div>
                                        <div className="issue_label_name" style={{ paddingTop: "0px" }}>Description</div>
                                        <div>
                                        <TextField sx={{
                                            "& .MuiInputBase-root": { color: "#505050", height: "82px", padding: "4px", width: "356px", }, "& .MuiOutlinedInput-input": {
                                                height: '80px !important',
                                                overflow: 'auto !important'
                                            },
                                        }}
                                            inputProps={{ maxLength: 255 }}
                                            id="outlined-basic"
                                            variant="outlined"
                                            className="Searchbar-field"
                                            autoComplete="off"
                                            multiline={true}
                                            value={sfRecord?.Description__c ? sfRecord?.Description__c : ""}
                                            onChange={(e) => {
                                                let fff = sfRecord;
                                                fff.Description__c = e?.target?.value;
                                                setVariables(fff);
                                            }}
                                            onKeyDown={(event) => {
                                                if (event.key.toLowerCase() != "tab") {
                                                    event.stopPropagation();
                                                }
                                            }}
                                        />
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                    <div className="template_steps_footer">
                        {currentStep === 1 &&
                            <div className="template_steps_footer_button">
                                <Button className="custom_button cancel_button" onClick={onCancel}>Cancel</Button>
                                <Button className="custom_button apply_button" onClick={handleNextStep}>Next</Button>
                            </div>
                        }
                        {currentStep === 2 &&
                            <div className="template_steps_footer_button">
                                {!sfObj?.Id && <Button className="custom_button cancel_button" onClick={handleBackStep}>Back</Button>}
                                {duplicatTemplate && <Button className="custom_button cancel_button"  onClick={onCancel}>Back</Button>}
                                {sfObj?.Id && !duplicatTemplate && <Button className="custom_button cancel_button"  onClick={onCancel}>Cancel</Button>}
                                {duplicatTemplate && <Button className="custom_button apply_button"  onClick={() => { onAccept(sfRecord, selectedTemplateId) }}>Duplicate Template</Button>}
                                {!duplicatTemplate &&
                                    <Button disabled={!sfRecord.Name || !sfRecord.Template_Category__c} className="custom_button apply_button" onClick={() => { onAccept(sfRecord, selectedTemplateId) }}>
                                        {!sfObj?.Id ? 'Create Checklist Template' : 'Save Changes'}
                                    </Button>
                                }
                            </div>
                        }
                    </div>
                </div>
            </Paper>
        </Dialog>
    );
}
export default ChecklistTemplateDetail;