import React, { useState, useEffect, useRef } from "react";
import PdfContainerCss from "./pdfContainer.style";
import { Folder } from "@mui/icons-material";
import { DataGrid } from "@mui/x-data-grid";
import usePdfViewerHook from "hooks/PdfViewer.hook";
import { Button, ButtonGroup } from "@mui/material";
import { styled } from "@mui/material/styles";
import SetsPopUp from "./SetsPopUp";
import useFolderTableHook from "hooks/FolderTable.hook";
import Loader from "components/Loader";

const Input = styled("input")({
  display: "none",
});

export default function PdfViewerComponent(props) {
  const [openSets, setOpensets] = useState(false);
  const {
    currentFolderName,
    handleUploadFile,
    selectedradio,
    setSelectedRadio,
    setUploadWithoutSet,
  } = props;
  const { columns, rows, preSelectedRowIds, setPreselectedRows } = usePdfViewerHook(props);
  const { buttonloading } = useFolderTableHook(props);
  const anchorRef = useRef(null);

  useEffect(() => {
    //console.log(preSelectedRowIds.length , "useEffect preSelectedRowIds.length");
    if (rows.length > 0) {
      handleRowSelection(preSelectedRowIds);
    }
  }, [rows]);

  const handleRowSelection = (ids) => {
    if (rows.length > 0 ) {
        const selectedRowData = rows.filter((row) =>
        ids.includes(row?.id)
      );
      //console.log(preSelectedRowIds, "defaultSelectedRows.");
      props?.setSelectedRowData(selectedRowData);
    }
    setPreselectedRows(ids);
  };
  const handleOpen = () => {
    setUploadWithoutSet(true);
    setSelectedRadio({ id: "", date: "", date_sf: "", name: "" });
    setOpensets(true);
  };
  return (
    <>
      <PdfContainerCss>
        {openSets && (
          <SetsPopUp
            credential={props?.credential}
            openSets={openSets}
            setOpensets={setOpensets}
            selectedradio={selectedradio}
            setSelectedRadio={setSelectedRadio}
            setUploadWithoutSet={setUploadWithoutSet}
          />
        )}
        <div>
          <div className="uploadTitle">
            <h3>Upload your file</h3>
            {selectedradio?.name !== "" &&
            selectedradio?.date !== "" &&
            openSets === false ? (
              <Button variant="contained" component="span" onClick={handleOpen}>
                {selectedradio?.name} {selectedradio?.date}{" "}
              </Button>
            ) : (
              <Button variant="contained" component="span" onClick={handleOpen}>
                Set Not Assigned
              </Button>
            )}
          </div>
          <div className="folderTitle_section">
            <div className="folder">
              <div className="icon">
                <Folder />
              </div>
              <div className="folderName">{currentFolderName?.name}</div>
            </div>
            {/*<div className="fileLoding_addFile">
              <div>file uploding</div>
              <ButtonGroup
                variant="contained"
                ref={anchorRef}
                aria-label="split button"
              >
                <label htmlFor="fileupload">
                  <Input
                    accept="application/pdf,image/x-png,image/gif,image/jpeg,video/mp4,video/x-m4v,video/*"
                    id="fileupload"
                    type="file"
                    onChange={(e) => handleUploadFile(e.target.files)}
                  />

                  <Button variant="contained" component="span">
                    Add File
                  </Button>
                </label>
              </ButtonGroup>
            </div>*/}
          </div>
          <div style={{ height: 300, width: "100%" }}>
            <DataGrid
              className="uploadTable"
              rows={rows}
              columns={columns}
              pageSize={12}
              rowsPerPageOptions={[4]}
              checkboxSelection
              disableColumnFilter={true}
              disableColumnSelector={true}
              disableRowSelectionOnClick={true}
              hideFooter={true}
              autoPageSize={true}
              onRowSelectionModelChange={(ids) => {
                handleRowSelection(ids);
                //console.log(ids, "hell");
                //const selectedIDs = new Set(ids);
                /*const selectedRowData = rows.filter((row) =>
                  ids.includes(row?.id)
                );
                //console.log(selectedRowData, "selectedRowData");
                props?.setSelectedRowData(selectedRowData);
                defaultSelectedRows = ids;*/
              }}
              columnVisibilityModel={{
                id: false,
              }}
              rowSelectionModel={preSelectedRowIds}
            />
          </div>
        </div>
      </PdfContainerCss>
    </>
  );
}
