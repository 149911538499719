import React, { useState } from "react";
import { Button, Box, DialogContent, CircularProgress , DialogContentText ,Dialog ,DialogTitle ,IconButton, DialogActions } from "@mui/material";
import StyledTreeItem from "components/FolderSection/StyledTreeItem";
import TreeView from "@mui/lab/TreeView";
import { find} from 'lodash';
import {
  MoreHoriz,
  ArrowDropDown,
  ArrowRight,
  Folder,
  Close
} from "@mui/icons-material";
import { getFolderFileApi } from "api";
import DescriptionIcon from "@mui/icons-material/Description";
import IssuesPdfViewDialog from "./pdfView";
import moment from "moment";
import { useEffect } from "react";
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';

import {TreeItem, treeItemClasses } from '@mui/x-tree-view';
import Typography from '@mui/material/Typography';

const SelectADocumentPopUp = (props) => {
  const {
    enableDocumentSelect,
    setDocumentSelect,
    setFileData,
    pdfopenfiledata,
    credential,
    pinType,
    annoEleInstantJson,
    openFromComputer,
    treeData: {
      treeFolderStructure,
      loadingProgress,
      filesData
    },
    calledFrom,
    handlePinDropSelctionDone
  } = props;

  const [treeWithFiles, settreeWithFiles] = useState([]);
  const [allFiles, setAllFiles] = useState([]);

  const [showPdf, setShowPdf] = useState(false);

  useEffect(() => {
    //console.log("treeFolderStructure :: ", treeFolderStructure?.length);
    settreeWithFiles(treeFolderStructure);
  }, [treeFolderStructure]);

  useEffect(() => {
    //console.log("treeFolderStructure :: ", treeFolderStructure?.length);
    setAllFiles(filesData);
  }, [filesData]);
  
  const handleClickMenu = (e,parms) => {
    let fileId=parms.split('_file')
    const findObject = allFiles.find((data) => data?.Id === fileId[0]);
    //console.log("datain", findObject);
     setFileData(findObject);
     if(calledFrom == 'IssueAttachmentSection'){
      setShowPdf(false);
      handleClose();
     }else{
      setShowPdf(true);
     }
    //setFileData();
  };
  const handleClose = () => {
    setDocumentSelect(false);
  };
  const handleCloseFile = () => {
    setShowPdf(false);
  };
  const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
    color: theme.palette.text.secondary,
    [`& .${treeItemClasses.content}`]: {
        color: theme.palette.text.secondary,
        borderTopRightRadius: theme.spacing(2),
        borderBottomRightRadius: theme.spacing(2),
        paddingRight: theme.spacing(1),
        fontWeight: theme.typography.fontWeightMedium,
        '&.Mui-expanded': {
            fontWeight: theme.typography.fontWeightRegular,
        },
        '&:hover': {
            backgroundColor: theme.palette.action.hover,
        },
        '&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused': {
            backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
            color: 'var(--tree-view-color)',
        },
        [`& .${treeItemClasses.label}`]: {
            fontWeight: 'inherit',
            color: 'inherit',
        },
    },
    [`& .${treeItemClasses.group}`]: {
        marginLeft: 0,
        // [`& .${treeItemClasses.content}`]: {
        //     paddingLeft: theme.spacing(4),
        // },
    },
}));


//default component
function StyledTreeItem(props) {
    const {
        bgColor,
        color,
        labelIcon: LabelIcon,
        labelInfo,
        labelText,
        ...other
    } = props;

    return (
        <StyledTreeItemRoot
            label={
                <Box sx={{ display: 'flex', alignItems: 'center', p: 0.5, pr: 0 }}>
                    <Box component={LabelIcon} color="inherit" sx={{ mr: 1 }} />
                    <Typography variant="body2" sx={{ fontWeight: 'inherit', flexGrow: 1 }}>
                        {labelText}
                    </Typography>
                    <Typography variant="caption" color="inherit">
                        {labelInfo}
                    </Typography>
                </Box>
            }
            style={{
                '--tree-view-color': color,
                '--tree-view-bg-color': bgColor,
            }}
            {...other}
        />
    );
}

StyledTreeItem.propTypes = {
    bgColor: PropTypes.string,
    color: PropTypes.string,
    labelIcon: PropTypes.elementType.isRequired,
    labelInfo: PropTypes.string,
    labelText: PropTypes.string.isRequired,
};
  const renderTree = (nodes, section = "", isUpdateed) => {
    return (
      <>
        <div className="main-wrapp-sidebar">
          <StyledTreeItem
            key={nodes?.value?.Id}
            nodeId={nodes?.value?.type ==="file" ? `${nodes?.value?.Id}_file` : nodes?.value?.Id }
            className={section}
            labelText={nodes?.value?.Name}
            color={nodes?.color ? nodes?.color : "#1a73e8"}
            bgColor="#e8f0fe"
            labelIcon={
              nodes?.value?.attributes?.type === "Folder__c"
                ? Folder
                : DescriptionIcon
            }
          >
            {Array.isArray(nodes.children) &&
            nodes?.value?.attributes?.type === "Folder__c"
              ? nodes.children.map((node) => renderTree(node, "children"))
              : null}
          </StyledTreeItem>
          {isUpdateed}
        </div>

      </>
    );
  };

  const findAndUpdate = (id, val, arr) => {
    const tgtObj = arr.find((data) => data?.value?.Id === id);
    if (tgtObj) {
      val.map((data) => {
        let newDta = {
          children: [],
          value: { Id: data?.Id, Name: data?.File_Name__c,type: "file" },
        };
        let foundInd = find(tgtObj["children"], newDta);
        if(!foundInd){
          tgtObj["children"].push(newDta);
        }
      });
    } else {
      arr.filter((el) => "children" in el).forEach(({ children }) => findAndUpdate(id, val, children));
    }
    return arr;
  };
  const [isUpdateed, setIsUpdated] = useState(false);

  function _getNodeChildren(data, id) {
    //console.log('Data Set _getNodeChildren :: ');
    let ClickChildData = allFiles?.filter((row) => {
      //console.log('row?.Folder__c ::', JSON.stringify(row) , ' id :: ',id );
      return row?.Folder__c === id;});
    const filtedData = findAndUpdate(id, ClickChildData, data);
    // console.log('isUpdateed',ClickChildData)
    setIsUpdated((prev) => !prev);
    settreeWithFiles(filtedData);
  }
  return (
    <>
    {/*<DialogComponent
        dialogProp={{
          opened: enableDocumentSelect,
          handleClose,
          titleContent: "Select a document",
          actionDisplay: true,
          actionChildren: <ActionChildren />,
          classFor: "SelectDocument_popup",
        }}
      >
      </DialogComponent>*/}
      <Dialog open={true} onClose={handleClose} fullWidth maxWidth="sm" aria-labelledby="select a document" aria-describedby="select a document"
        scroll="paper" >
        <DialogTitle id="select a document-title">
          Select a document
          <IconButton className="dialog-header-close" onClick={handleClose}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {!loadingProgress ? (
            <TreeView
              aria-label={`gmail ${isUpdateed}`}
              defaultExpanded={[pdfopenfiledata?.id]}
              //defaultSelected={[props?.currentFolderName?.id]}
              defaultCollapseIcon={<ArrowDropDown />}
              defaultExpandIcon={<ArrowRight />}
              defaultEndIcon={<div style={{ width: 24 }} />}
              onNodeSelect={(e, val) => {
                if(val?.includes('file')){
                  handleClickMenu(e, val)
                }else{
                  const DATA = _getNodeChildren(treeFolderStructure, val);

                }
              }}
            >
              {treeWithFiles?.length > 0 ? (
                treeWithFiles?.map((val, index) => renderTree(val, isUpdateed))
              ) : (
                <p>Folders Not Found</p>
              )}
            </TreeView>
          ) : (
            <CircularProgress sx={{ marginLeft: "106px", marginTop: "106px" }} />
          )}
        </DialogContent>
        {openFromComputer &&
          <DialogActions>
            <Button color="warning" variant="text" onClick={openFromComputer}>
              Select From System
            </Button>
          </DialogActions>
        }
      </Dialog>
      {showPdf && pdfopenfiledata && (
        <IssuesPdfViewDialog
          pdfopenfiledata={pdfopenfiledata}
          pinType={pinType}
          annoEleInstantJson={annoEleInstantJson}
          credential={credential}
          open={showPdf}
          handleCloseFile={handleCloseFile}
          handleDonePinDrop={handlePinDropSelctionDone}
        />
      )}
    </>
  );
};
export default SelectADocumentPopUp;
