import styled from "styled-components";

const Headerstyle = styled.div`
  /* .MuiPaper-elevation0 {
    max-width: 300px;
    width: 100%;
  }
  .profilemenu_main {
    display: flex;
    flex-wrap: wrap;
  }

  .profileMenu_pic {
    max-width: 100px;
    flex: 100%;
    width: 100%;
  }
  .profile_data {
    max-width: calc(100% - 100px);
    width: 100%;
    flex: 100%;
    span {
      font-size: 16px;
      display: block;
    }
  } */

  .MuiAutocomplete-hasPopupIcon{
.MuiInputBase-root.MuiOutlinedInput-root{
    background: #ffffff;
    border: 1px solid #fff;
    text-transform: uppercase;
    border-radius: 3px;
}
   .MuiInputBase-colorPrimary .MuiAutocomplete-input {
    padding: 0;
    font-weight: 500;
}
label#combo-box-demo-label[data-shrink="true"] {
  padding: 0 10px;
}
label#combo-box-demo-label {
    background: #fff;
   
}
.MuiToolbar-root.MuiToolbar-regular .MuiBox-root {
    margin-left: 0;
    margin-right: 10px;
}
}
`;
export default Headerstyle;
