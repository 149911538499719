
import React, { useState } from "react";
import { cloneDeep } from "lodash";
import axios from "axios";
import { fetchUrl} from "Urls";
import { useNavigate ,useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setCanvasData } from 'redux/features/canvasDataSlice';

function CanvasHome() {
  const params = useParams();
  const orgUserkey = params?.orgUserkey;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [credential, setCredential] = useState();

  React.useEffect(() => {
    console.log('orgUserkey',orgUserkey);
    getCanvasDetails();
  }, []);
  async function getCanvasDetails() {
     let reqUrl = `${fetchUrl}/canvas/details`;
     let orgKey;
     if(orgUserkey && orgUserkey!='undefined' && orgUserkey != ''){
      orgKey = orgUserkey;
      reqUrl +=`?orgUserkey=${orgUserkey}`
     }
     await axios.get(reqUrl).then(async (res) => {
        if (res.status === 200) {
          setCredential(res.data?.credential);
          if(res.data?.canvasData){
            let canvasData = res.data?.canvasData ? cloneDeep(res.data?.canvasData) : null;
            if(orgKey && canvasData){
              canvasData.orgUserkey = orgKey;
            }
            dispatch(setCanvasData(canvasData));
            if(canvasData.action === 'preview'){
              navigate('/preview');
            }else if(canvasData.action === 'signature'){
              navigate(`/fieldticket/sign/${canvasData.fieldticketId}`);
            }
          }
        }
     }).catch((error) => {
        console.log("error >>>>>>>>>>>>", JSON.stringify(error));
        console.error(error);
     });
  }

  return (
    <>Navigating</>
  );
}

export default CanvasHome;
