import React from "react";

import PreconfiguredResponseChoices from './PreconfiguredResponseChoices';
import CustomResponseChoiceBuilder from './CustomResponseChoiceBuilder';
const ConditionalLogicOptions = [{label:'No Action', value:'na'},
                                  {label:'Require an image' , value:'image'},
                                  {label:'Require an issue' , value:'issue'}]
const ResponseTypeBuilder = ({ handleChange , responseOptions , selectedType, ...props }) => {
  return (
    <>  
      {selectedType === 'Preconfigured' && 
        <PreconfiguredResponseChoices conditionLogicActions={ConditionalLogicOptions} conditionLogic={props.conditionLogic} handleChange={handleChange} responseOptions={responseOptions}/>
      }
      {(selectedType === 'Checkbox' || selectedType === 'Dropdown' || selectedType === 'Multiple Choice') && 
        <CustomResponseChoiceBuilder conditionLogicActions={ConditionalLogicOptions} conditionLogic={props.conditionLogic} responseType={selectedType} handleChange={handleChange} responseOptions={responseOptions}/>
      }
    </>
  );
};

export default ResponseTypeBuilder;