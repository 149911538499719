import React, { useState, useEffect } from "react";
import {ClickAwayListener } from "@mui/base";
import { Button, Select, MenuItem,TextField, Radio,Checkbox } from "@mui/material";
import CloseIcon from "assets/images/close.svg";
import { cloneDeep } from "lodash";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
const CustomResponseChoiceBuilder = ({ handleChange, responseOptions ,responseType , ...props}) => { 
  const {conditionLogicActions , conditionLogic} = props;
  const [answers, setAnswers] = useState([]);
  const [newOption, setNewOption] = useState('');
  const [selectedOption, setSelectedOption] = useState(null);
  const [isError, checkError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  useEffect(() => {
    if(responseOptions && responseOptions.length > 0){
      setAnswers(responseOptions);
    }
  }, []);
  const onClickHandleAddOption = () => {
    checkError(false);
    setErrorMessage(undefined);
    const tmpArr = cloneDeep(answers);
    if (!tmpArr) {
      tmpArr = [];
    }
    tmpArr.push({ label: '', value: '', key: '' , isNew : true});
    setAnswers(tmpArr);
    setTimeout(()=>{setSelectedOption(tmpArr?.length-1);},500);
  };
  const handleUpdateOption = (newOption) => {
    const tmpArr = cloneDeep(answers);
    let isError = false;
    let eMess;
    if (tmpArr && tmpArr.length > 0 && newOption) {
      let f_index = tmpArr?.findIndex(
        (data) => data.value == newOption
      );
      if (f_index > -1) {
        isError = true;
        eMess = 'Option already exits.';
      }
    }
    if (!newOption || newOption.trim() == '') {
      isError = true;
      eMess = 'Cannot add empty option.';
    }
    checkError(isError);
    setErrorMessage(eMess);
    if (!isError) {
      tmpArr[selectedOption]= { label: newOption, value: newOption, key: newOption };
      setAnswers(tmpArr);
      handlePush(tmpArr);
    }
    console.log('edit error :',eMess);
  };
  const handleUpdateOptionAction = (ind , action) => {
    const tmpArr = cloneDeep(answers);
    let isError = false;
    let eMess;
    if (tmpArr && tmpArr.length > 0 && tmpArr[ind]) {
      if(tmpArr[ind].value && tmpArr[ind].value.trim() != ''){
        tmpArr[ind].action = action;
        setAnswers(tmpArr);
        handlePush(tmpArr);
      }else{
        checkError(true);
        setErrorMessage('Enter value first');
      }
    }
  };
  const handleRemoveOption = (index) => {
    const tmpArr = cloneDeep(answers);
    if (tmpArr && tmpArr.length > 0) {
      let push = true;
      if(tmpArr.isNew){
        push = false;
      }
      tmpArr.splice(index, 1);
      setAnswers(tmpArr);
      if(push){
        handlePush(tmpArr);
      }
    }
  };
  const handlePush = (_answer = answers) => {
    const tmpArr = cloneDeep(_answer);
    let jsonTosend;
    if (tmpArr && tmpArr.length > 0) {
      jsonTosend = tmpArr;
    }
    //console.log('cloneDeep(jsonTosend) :: ' + JSON.stringify(jsonTosend));
    setSelectedOption(null);
    handleChange({ target: { name: 'responseOptions', value: jsonTosend } });
  };
  const handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const newAnswers = Array.from(answers);
    const [movedOption] = newAnswers.splice(result.source.index, 1);
    newAnswers.splice(result.destination.index, 0, movedOption);
    // Update the state with the new order
    setAnswers(newAnswers);
    handlePush(newAnswers);
  };
  return (
    <div>
      <ClickAwayListener onClickAway={() => {
        if (selectedOption != null) {
          if (answers && answers?.length > 0) {
            if (answers[selectedOption]?.isNew) {
              handleRemoveOption(selectedOption);
              return;
            }
          }
          setSelectedOption(null);
        }
      }}>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="options">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {answers?.map((opt, ind) => (
                  <Draggable key={opt.value + '-' + ind} draggableId={opt.value + '-' + ind} index={ind}>
                    {(provided) => (
                      <div ref={provided.innerRef} {...provided.draggableProps} className="list_option" >
                        <div style={{ width: '24px' }} {...provided.dragHandleProps}>
                          <span className="over_hover">
                            <img src="/drag_hori.svg" alt="dragIcon" />
                          </span>
                        </div>
                        <div style={{ display: 'flex', width: selectedOption === ind ? '91%' : conditionLogic ? "100%" : '150px', marginRight: conditionLogic && "20px" }}>
                          <div style={{ width: "25%", maxWidth: '30px' }}>
                            {responseType === 'Multiple Choice' && <Radio className="checkbox-style" style={{ padding: "0px" }} disabled />}
                            {responseType === 'Dropdown' && ind + 1 + "."}
                            {responseType === 'Checkbox' && <Checkbox className="checkbox-style" style={{ padding: "0px" }} disabled />}
                          </div>
                          {selectedOption !== ind &&
                            <div style={{ width: conditionLogic ? "46%" : "70%" }} onClick={(e) => { setSelectedOption(ind) }}>{opt.value}</div>
                          }
                          {selectedOption === ind &&
                            <TextField sx={{ "& .MuiInputBase-root": { color: "#505050", height: "25px", padding: "4px", } }}
                              inputProps={{ maxLength: 255 }}
                              variant="standard"
                              className="Searchbar-field"
                              autoComplete="off"
                              required
                              defaultValue={opt.value}
                              helperText={isError ? errorMessage : "Enter To Save Value."} error={isError}
                              onKeyPress={(e) => {
                                if (e.key === "Enter") {
                                  // Do code here
                                  e.preventDefault();
                                  handleUpdateOption(e.target.value);
                                }
                              }}
                              fullWidth />
                          }
                          <div>
                            <span
                              className="over_hover"
                              onClick={() => {
                                if (opt?.isNew || !selectedOption) {
                                  handleRemoveOption(ind);
                                } else {
                                  setSelectedOption(null);
                                }
                              }}
                            >
                              <img src={CloseIcon} alt="icons" />
                            </span>
                          </div>
                          {conditionLogic &&
                            <Select role="presentation"
                            style={{ height: "37px", width: "48%", color: "#505050", marginTop: "-5px", marginLeft: "12px",}}
                              id={`conditionAction-${opt.action}`}
                              value={opt.action}
                              onChange={(e)=>{
                                let value = e.target.value;
                                handleUpdateOptionAction(ind , value);
                              }}>
                              {conditionLogicActions?.map((r) => (
                                <MenuItem key={r.value} value={r.value}>
                                  {r.label}
                                </MenuItem>
                              ))}
                            </Select>
                          }
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </ClickAwayListener>
      <div>
        <Button className="add_button" onClick={() => onClickHandleAddOption()}><img src="/add_blue.svg" alt="" />
          Add Another Option
        </Button>
      </div>
    </div>
  );
};

export default CustomResponseChoiceBuilder;
