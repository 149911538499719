import React, { useEffect, useState } from "react";
import { Grid, Typography, Divider, Button, Dialog, DialogTitle } from "@mui/material";
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import AnswersRender from './AnswersRender';
import { Box } from "@mui/system";

const CheckListForm = ({ checklistFormDetails , dispatchObj ,checklistFormobj, ...props }) => {
  const [showEditInput, setShowEditInput] = useState(false);
  //console.log('********checklistFormDetails ', checklistFormDetails);
  if (checklistFormDetails.length > 0) {
    return (
      <Grid container className="checklist-form-render pad-8">
        {checklistFormDetails.map((section , sIndex) => {
          return (
            <Grid item xs={12}>
              <Typography variant="h6" className="section-heading" gutterBottom>
                <ChatBubbleOutlineIcon className="section-heading-icon" />
                {`${section.Numbering_Sequence__c}.  ${section.Name}`}
              </Typography>
              <Divider />
              <Grid container className="section-questions">
                {section.items.map((question, index) => (
                  <Grid item xs={12}>
                    <Typography variant="body2" className="question-heading" gutterBottom >
                      {index === 0 && <ChatBubbleOutlineIcon className="section-questions-icon" />}
                      {index !== 0 && <span className="section-questions-blank-icon" />}
                      {`${section.Numbering_Sequence__c}.${question.Numbering_Sequence__c} `}
                        { !question.Optional__c  == true && 
                          <span style={{color:'red' , fontWeight : '700'}}>*</span>
                        } 
                      {`${question.Question__c}`}
                    </Typography>
                    {/* response types render */}
                    <Grid container>
                      <AnswersRender formId={props?.formId} credential={props?.credential} question={question} name={question.Name} responseOptions={question.responseOptions || [] } 
                        sectionInd={sIndex} questionInd={index} dispatchObj={dispatchObj} ansObj={question.ans} defaultValue={question.ans.Response__c} checklistFormobj={checklistFormobj}/>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    );
  }
  return 'nothing';
};

export default CheckListForm;
