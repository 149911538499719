import React, { useState } from "react";
import { Box } from "@mui/material";
import TableTemplateList from "./TableTemplateList";
import { NoData } from "components/upload-logs/LogsComponents/CommonMethod";

function TemplateListTable(props) {
  const [tableColumnValue, setTableColumnValue] = useState([]);
  const [open, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState();
  const { data , columns } = props?.templateTablePorps;
  return (
    <Box component={"div"} sx={{ width: "100%" }}>
      <TableTemplateList
        columns={columns}
        data={data}
        handleOpen={props.openFormDetail}
        NoDataComponent={<NoData />}
      />
    </Box>
  );
}

export default TemplateListTable;
