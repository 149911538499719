import React from "react";

import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Box
} from "@mui/material";

const ShowVideoDialog = (props) => {
  const { filedata, open, handleClose } = props;

  return (
    <div>
      <Dialog
        open={open}
        fullScreen
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            Name: {filedata?.name} version: {filedata?.version}{" "}
            <Button
              style={{ right: "30px" }}
              onClick={() => {
                handleClose();
              }}
            >
              Close
            </Button>
          </Box>
        </DialogTitle>

        <DialogContent style={{ position: "relative" }}>
          <Grid container>
            <Grid item style={{ flexGrow: 1 }}>
              <div
                id="videoContainer"
                style={{ width: "100%", height: "100vh" }}
              >
                <video width="100%" height="auto" controls>
                  <source src={filedata?.url} type={`video/${filedata?.fileExt}`} />
                  Your browser does not support the video tag.
                </video>
              </div>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ShowVideoDialog;
