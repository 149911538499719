import React, { useState, useEffect } from "react";
import {TextField, Stack, Paper, Menu, MenuItem } from "@mui/material";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import ChecklistQuestionCard from "./ChecklistQuestionCard";
const ChecklistSectionCard = ({ sectionData, index, dispatchObj, totalSections, ...props }) => {
  const [secAnchorEl, setSecAnchorEl] = React.useState(null);
  const [isSignature, setSignature] = React.useState(sectionData?.Name === 'Signature');
  const openSecMenu = Boolean(secAnchorEl);
  //console.log("sectionData ::", sectionData);
  //console.log("sectionData.Name ::", sectionData.Name);
  const {selectedSection , setSelectedSection , currentEditableElement , setEditableElement , templatePublished , clickAwayPressed} = props
  const [editMode, setEditModeState] = useState((selectedSection === sectionData.identifier && !templatePublished));
  const setEditMode = (value)=>{
    if(!templatePublished){
      setEditModeState(value);
    }else{
      setEditModeState(false);
    }
  };
  const handleSectionValueChange = (_index, _dataObj, fieldKey, value) => {
    sectionData = JSON.parse(JSON.stringify(_dataObj));
    dispatchObj({ type: 'SECTION_VAL_CHANGE', fieldKey: fieldKey, value: value, sectionIndex: _index });
  };
  useEffect(() => {
    if(!isSignature){
      setEditMode(currentEditableElement === sectionData.identifier);
    }
  }, [currentEditableElement]);
  useEffect(() => {
    setEditMode(false);
  }, [clickAwayPressed]);
  const openSecMenuClick = (event) => {
    setSecAnchorEl(event.currentTarget);
  };
  const closeSecMenuClick = () => {
    setSecAnchorEl(null);
  };
  const onDuplicateSection = e => {
    //console.log('onDuplicateSection');
    dispatchObj({type: 'DUPLICATE_SECTION' , sectionIndex : index});
    closeSecMenuClick();
  };
  const moveUpSection = e => {
    //console.log('moveUpSection');
    dispatchObj({type: 'MOVE_SECTION' , from : index , to : (index - 1)});
    closeSecMenuClick();
  };
  const moveDownSection = e => {
    //console.log('moveDownSection');
    dispatchObj({type: 'MOVE_SECTION' , from : index , to : (index + 1) });
    closeSecMenuClick();
  };
  const onMoveQuestion_Drag = (result) => {
    if (!result.destination) {
      return;
    }
    dispatchObj({type: 'MOVE_QUESTION' , sectionIndex : index ,  from : result.source.index , to : result.destination.index});
  };
  const handleRemoveSection = (_index) => {
    //dispatchObj({type: 'REM_SECTION' , sectionIndex : _index});
    dispatchObj({type: 'REM_SECTION_WARNING' , sectionIndex : _index});
  };
  return (
    <div className={`section ${selectedSection == sectionData.identifier ? 'section_selected' : ''}`}>
      <div className="section_head">
        <div>
          {isSignature &&  <> {sectionData.Name} </> }
          {!isSignature &&  <> Section {sectionData.Numbering_Sequence__c} of {totalSections} </> }
        </div>
        <div className="section_head_right">
          <div>
            {`${sectionData.items?.length} `}
            {isSignature &&  <> Signee </> }
            {!isSignature &&  <> Question </> }
            {sectionData.items?.length > 1 ? 's' : ''}
          </div>
          <div className="action" style={{cursor: "pointer"}}>
            <img src="/collapse_all.svg" alt="" />
          </div>
          {!templatePublished &&
            <div className="action" style={{cursor: "pointer"}} onClick={(e) => {
                e.stopPropagation();
                openSecMenuClick(e);
              }}>
              <img src="/more sub nav_white.svg" alt="" />
            </div>
          }
        </div>
      </div>
      <div className={`section_body`}>
        {editMode === true &&
          <div>
            <TextField sx={{ "& .MuiInputBase-root": { color: "#505050", height: "37px", padding: "4px", marginBottom: "10px" } }}
              id="outlined-basic"
              variant="outlined"
              className="Searchbar-field"
              autoComplete="off"
              placeholder="Untitled Section"
              required
              value={sectionData.Name} onChange={(e) => {
                let fff = JSON.parse(JSON.stringify(sectionData));
                fff.Name = e?.target?.value;
                handleSectionValueChange(index, fff, 'Name', fff.Name);
              }}
              onKeyDown={(event) => {
                if (event.key.toLowerCase() != "tab") {
                  event.stopPropagation();
                }
              }}
              disabled={isSignature}
              fullWidth />
            <TextField sx={{ "& .MuiInputBase-root": { color: "#505050", height: "37px", padding: "4px" } }}
              id="outlined-basic"
              variant="outlined"
              className="Searchbar-field"
              autoComplete="off"
              placeholder="Description (optional)"
              value={sectionData.Description__c} onChange={(e) => {
                let fff = JSON.parse(JSON.stringify(sectionData));
                fff.Description__c = e?.target?.value;
                handleSectionValueChange(index, fff, 'Description__c', fff.Description__c);
              }}
              onKeyDown={(event) => {
                if (event.key.toLowerCase() != "tab") {
                  event.stopPropagation();
                }
              }}
              fullWidth />
          </div>
        }
        {!editMode &&
          <>
            {!isSignature &&
              <>
                <div className="header_label_dark" onClick={() => {
                  setSelectedSection(sectionData.identifier);
                  setTimeout(()=>{setEditableElement(sectionData.identifier);setEditMode(true);},100);
                  setEditMode(true);
                }}>
                  {sectionData.Name && sectionData.Name.trim() != '' ? sectionData.Name : 'Untitled Section'}
                </div>
                {sectionData.Description__c && sectionData.Description__c.trim() != '' &&
                  <div style={{ padding: "0px", marginTop: "5px" }} className="template_steps_body_text" onClick={() => {
                    setSelectedSection(sectionData.identifier);
                    setTimeout(() => { setEditableElement(sectionData.identifier); setEditMode(true); }, 100);
                    setEditMode(true);
                  }}>
                    {sectionData.Description__c && sectionData.Description__c.trim() != '' ? sectionData.Description__c : 'Description (optional)'}
                  </div>
                }
              </>
            }
          </>
        }
        <DragDropContext onDragEnd={onMoveQuestion_Drag}>
          <Droppable droppableId={`${sectionData.identifier}-que_list_drop_container`} isDropDisabled={templatePublished}>
            {(drop_source) => (
              <div {...drop_source.droppableProps} ref={drop_source.innerRef}>
                {sectionData.items.map((que, questionIndex) => (
                  <Draggable key={'drag-que-' + que.identifier} draggableId={'drag-que-' + que.identifier} index={questionIndex} isDragDisabled={templatePublished}>
                    {(drag_source) => (
                      <div ref={drag_source.innerRef} {...drag_source.draggableProps}>
                        <ChecklistQuestionCard
                          key={que.identifier}
                          drag_source={drag_source}
                          dispatchObj={dispatchObj}
                          templatePublished={templatePublished}
                          sectionIndex={index}
                          questionIndex={questionIndex}
                          section={sectionData}
                          isSignature={isSignature}
                          question={que}
                          sectionSortOrder={sectionData.Numbering_Sequence__c}
                          totalQuestions={sectionData.items.length}
                          responseOptions={props?.responseOptions} defaultResponse={props?.defaultResponse}
                          selectedSection={selectedSection}
                          setSelectedSection={setSelectedSection}
                          currentEditableElement={currentEditableElement}
                          setEditableElement={setEditableElement}
                          clickAwayPressed={clickAwayPressed}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      {openSecMenu && (
        <Stack direction="row">
          <Paper>
            <Menu id={sectionData?.identifier+"-basic-menu"} anchorEl={secAnchorEl} open={openSecMenu} onClose={closeSecMenuClick}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }}
              transformOrigin={{ vertical: 'top', horizontal: 'right', }} >
              { !isSignature && (!index == 0) &&
                <MenuItem key='mumenu' onClick={moveUpSection}>
                  Move Up
                </MenuItem>
              }
              { !isSignature && (!((totalSections - 1) == index)) &&
                <MenuItem key='mdpmenu' onClick={moveDownSection}>
                  Move Down
                </MenuItem>
              }
              { !isSignature && 
                <MenuItem key='dmenu' onClick={onDuplicateSection}>
                  Duplicate
                </MenuItem>
              }
              <MenuItem key='demenu' onClick={(e) => {handleRemoveSection(index);}}>
                Delete
              </MenuItem>
            </Menu>
          </Paper>
        </Stack>
      )}
    </div>
  );
}
export default ChecklistSectionCard;