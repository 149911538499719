import * as React from "react";
import PropTypes from "prop-types";
import { Dialog, DialogTitle, DialogContent, IconButton, DialogActions, Button } from "@mui/material";
import { Close } from "@mui/icons-material";

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function DialogComponent({ children, ...props }) {
  const {
    dialogProp: { opened, handleClose, titleContent, ...other },
  } = props;

  return (
    <Dialog
      onClose={(_, reason) => {
        if (reason == "backdropClick" || reason == "escapeKeyDown") {
          return false;
        }
      }}
      open={opened}
      fullScreen={other?.fullScreen}
      maxWidth="600"
      className={`main_dialog_${other?.classFor}_class`}
      classes={{
        paper: `dialog_paper_custom model_${other?.classFor}_paperClass`,
        root: `root_custom_dialog model_${other?.classFor}_rootClass`,
        rounded: `rounded_custom model_${other?.classFor}_roundClass`,
      }}

    >
      <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
        {titleContent}
      </BootstrapDialogTitle>
      <DialogContent dividers>{children}</DialogContent>
      {other?.displayAction &&
        <DialogActions>
          <Button onClick={handleClose}>cancel</Button>
          <Button onClick={() => other?.handleSubmit()} autoFocus className="submitionButton">
            Subscribe
          </Button>
        </DialogActions>
      }
    </Dialog>
  );
}
