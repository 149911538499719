import React, { useRef, useState, useEffect, useCallback } from "react";
import SignatureCanvas from "react-signature-canvas";
import {TextField, DialogActions, DialogContent, Button, DialogTitle, IconButton, Dialog } from "@mui/material";
import CloseIcon from "assets/images/close.svg";

const SignatureRender = ({ handleChange, req, ...props }) => {
  const { ansObj, isFormCompleted, formObj, userValidationMatch } = props;
  const [sobj, setSobj] = useState(JSON.parse(JSON.stringify(ansObj)));
  const [showCanvas, setShowCanvas] = useState(false);
  const [editSignature, setEditSignature] = useState(false);
  const padRef = useRef(null);
  useEffect(() => {
    setSobj(JSON.parse(JSON.stringify(ansObj)));
    if (ansObj?.Signature__c) {
      setTimeout(() => {
      padRef?.current?.fromDataURL(ansObj?.Signature__c);
      }, 1000);
    }
  }, [showCanvas, editSignature]);
  const clearSignatureCanvas = () => {
    padRef?.current?.clear();
    let image = sobj
    image.Signature__c = null
    setSobj(JSON.parse(JSON.stringify(image)));
  };
  const handleGetCanvas_onEnd = () => {
    const canvas = padRef?.current?.toDataURL();
    if (!isFormCompleted) {
      let image = sobj
      image.Signature__c = canvas
      const updates = {
        "Signature__c" : canvas ,
        "Print_Name__c": sobj?.Print_Name__c,
        "Company_Name__c": sobj?.Company_Name__c,
    };
      handleChange(updates);
      setSobj(image);
      setEditSignature(false);
      setShowCanvas(false);
    }
  };
  const onEndSignature = () => {
    const canvas = padRef?.current?.toDataURL();
    let image = sobj
    image.Signature__c = canvas
    setSobj(JSON.parse(JSON.stringify(image)));
  };
  return (
    <div>
      {!ansObj?.Signature__c && !props?.isFormCompleted && 
        <Button className="custom_button cancel_button" onClick={() => setShowCanvas(!showCanvas)} disabled={!userValidationMatch}>
          <img src="/signature.svg" alt="signaturIcon" style={{ marginRight: "5px" }} />
          Add Signature
        </Button>
      }
      {ansObj?.Signature__c &&
        <div style={{ display: "flex", flexDirection:"column", gap:"10px"}}>
          <div style={{ backgroundColor: "white", width: "255px", marginTop: "10px", border: "1px solid #D8D8D8" }}>
          <img src={ansObj?.Signature__c} alt="Base64" />
          </div>
          <div>Signature By: {ansObj?.Print_Name__c}</div>
          <div>Company: {ansObj?.Company_Name__c}</div>
          <div>Signed on: -</div>
          {!props?.isFormCompleted &&
            <Button className="custom_button cancel_button" disabled={!userValidationMatch} style={{width: "160px"}} onClick={() => {setShowCanvas(!showCanvas); setEditSignature(true)}}>
              <img src="/signature.svg" alt="signaturIcon" style={{ marginRight: "5px" }} />
              Edit Signature
            </Button>
          }
        </div>
      }
      {showCanvas &&
        <Dialog open={true} >

          <DialogTitle className="dialog_title">
           {editSignature ? "Edit Signature" : "Add Signature"}
            <IconButton
              onClick={() => {setShowCanvas(!showCanvas); setEditSignature(false);}}
              aria-label="close"
              sx={{
                position: "absolute",
                right: 12,
              }}
            >
              <img src={CloseIcon} alt="icons" />
            </IconButton>
          </DialogTitle>
          <DialogContent className="dialog_content">
            <div style={{ padding: "10px 0px", color: "#505050" }}>Draw your signature below</div>
            <div style={{ backgroundColor: "#F8F8F8", width: "400px", marginTop: "10px", border: "1px solid #D8D8D8" }}>
              <SignatureCanvas ref={padRef}  canvasProps={{ width: 400, height: 125 }} onEnd={onEndSignature}/>
              <div style={{ display: "flex", borderTop: "1px solid #D8D8D8", margin: "12px 16px", alignItems: "center", justifyContent: "flex-end", paddingTop: "10px" }}>
                <div style={{ marginRight: "30%" }}>Signature</div>
                <div style={{ color: sobj?.Signature__c ? "#346EF5" : "#BABABA", cursor: "pointer" }} onClick={clearSignatureCanvas}>Clear</div>
              </div>
            </div>
            <div style={{ display: "flex", gap: "10px", paddingTop: "20px" }}>
              <div>
                <div style={{ color: "#505050", paddingBottom: "5px" }} >Signature By</div>
                <div>
                  <TextField id="name" variant="outlined" placeholder="Enter Name" value={sobj?.Print_Name__c ? sobj?.Print_Name__c : ""}
                    sx={{ "& .MuiInputBase-root": { color: "#505050", height: "37px", padding: "4px", marginBottom: "10px", width: "195px" }, }}

                    onChange={(e) => {
                      if (!isFormCompleted) {
                        let fff = sobj; fff.Print_Name__c = e?.target?.value; 
                        setSobj(JSON.parse(JSON.stringify(fff)));
                      }
                    }}
                    onKeyDown={(event) => {
                      if (event.key.toLowerCase() != "tab") {
                        event.stopPropagation();
                      }
                    }} 
                    />
                </div>
              </div>
              <div>
                <div style={{ color: "#505050", paddingBottom: "5px" }}>Company</div>
                <div>
                  <TextField id="company" variant="outlined" placeholder="Company Name" value={sobj?.Company_Name__c ? sobj?.Company_Name__c : ""}
                    sx={{ "& .MuiInputBase-root": { color: "#505050", height: "37px", padding: "4px", marginBottom: "10px", width: "195px" }, }}
                    onChange={(e) => {
                      if (!isFormCompleted) {
                        let fff = sobj; fff.Company_Name__c = e?.target?.value; 
                        setSobj(JSON.parse(JSON.stringify(fff)));
                      }
                    }}
                    onKeyDown={(event) => {
                      if (event.key.toLowerCase() != "tab") {
                        event.stopPropagation();
                      }
                    }} />
                </div>
              </div>
            </div>
          </DialogContent>
          <DialogActions className="action_button footer">
            <Button className="custom_button cancel_button" onClick={() =>{ setShowCanvas(!showCanvas);  setEditSignature(false);}}>Cancel</Button>
            { !editSignature &&
            <Button className="custom_button apply_button" disabled={(!sobj?.Signature__c || !sobj?.Print_Name__c)} onClick={handleGetCanvas_onEnd}>Add Signature</Button>
            }
            { editSignature &&
            <Button className="custom_button apply_button" disabled={(!sobj?.Signature__c || !sobj?.Print_Name__c)} onClick={handleGetCanvas_onEnd}>Save Changes</Button>
            }
          </DialogActions>
        </Dialog>
      }
    </div>
  );
};

export default SignatureRender;
