import React, { useEffect, useState, useRef } from "react";
import moment from "moment";
import pspdfkit_Imp from "pspdfkit";
import { cloneDeep, filter, find, findIndex, constant, set } from "lodash";
//import filter1 from "./filter.svg"
import {
  Button,
  Dialog,
  DialogActions,
  Typography,
  DialogContent,
  DialogTitle,
  IconButton,
  Collapse,
  Grid,
  Paper,
  Divider,
  Checkbox,
  Tooltip,
  TextField,
  Autocomplete,
  Stack,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  OpenInFull,
  CloseFullscreen,
  MoreHoriz,
  ArrowBack,
  VisibilityOff,
  Close,
  Visibility,
  Bookmarks,
  Summarize,
  DragIndicator,
  UndoRounded,
  RedoRounded,
  Delete,
} from "@mui/icons-material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import ReportProblemRoundedIcon from "@mui/icons-material/ReportProblemRounded";
import { Box } from "@mui/system";
import axios from "axios";
import { fetchUrl } from "Urls";
import { toast } from "react-toastify";
import Spinner from "components/spinner";
import MenuComponent from "components/Shared/Manu";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ShowPdfDialogstyles from "./ShowPdfStyles";
import CompareFiles from "../FolderTable/CompareFiles";
import CloseIcon from "@mui/icons-material/Close";
import DialogContentText from "@mui/material/DialogContentText";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider, DesktopDatePicker } from "@mui/x-date-pickers";
//import MUIRichTextEditor from 'mui-rte';
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Draggable from "react-draggable";
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

var annoIdField = "Annotation_Id__c";
export const issueStatusToColorMap = {
  Draft: {
    colorBox: { r: 128, g: 128, b: 128 },
    pillColor: "#808080",
    textColor: "black",
  },
  Open: {
    colorBox: { r: 255, g: 186, b: 12 },
    pillColor: "#ffba0c",
    textColor: "white",
  },
  Answered: {
    colorBox: { r: 8, g: 124, b: 217 },
    pillColor: "#087cd9",
    textColor: "white",
  },
  Closed: {
    colorBox: { r: 192, g: 192, b: 192 },
    pillColor: "#c0c0c0",
    textColor: "black",
  },
};
export const rfiStageToColorMap = {
  Draft: {
    colorBox: { r: 128, g: 128, b: 128 },
    pillColor: "#808080",
    textColor: "black",
  },
  Pending: {
    colorBox: { r: 255, g: 186, b: 12 },
    pillColor: "#ffba0c",
    textColor: "white",
  },
  Returned: {
    colorBox: { r: 8, g: 124, b: 217 },
    pillColor: "#087cd9",
    textColor: "white",
  },
  Closed: {
    colorBox: { r: 192, g: 192, b: 192 },
    pillColor: "#c0c0c0",
    textColor: "black",
  },
};

export default function ShowPdfDialog({
  filUrl,
  credential,
  pdfopenfiledata,
  handleClose,
  handleClickOpen,
  open,
  setOpen,
  updateTableState,
  getInstantJSONBreakdown,
}) {
  //console.log("credential", pdfopenfiledata.customData);
  let instance = useRef(null);
  let runAutoSave = useRef(null);
  const [saveEnabled, enableSave] = React.useState(false);
  const [undoEnabled, canUndo] = React.useState(false);
  const [redoEnabled, canRedo] = React.useState(false);
  const [
    selectedFromSidePanelOrClosedFromDialog,
    setAnnotationSelectionSource,
  ] = React.useState(false);
  const [pspdfkitIns, setPspdfkitValue] = useState(null);
  const [instanceObject, setInstanceObject] = useState(null);
  const [showSpinner, setSpinner] = React.useState(false);
  const [showPanelSpinner, setSpinnerSide] = React.useState(false);
  const [expand, setExpand] = useState(false);
  const [openVersions, setOpenVersions] = useState(false);
  const [versionsList, setVersionsList] = useState([]);
  const [currentLoadedFile, setCurrentLoadedFile] = useState(null);
  const [compareActive, setCompareActive] = useState(false);
  const [openCompare, setOpenCompare] = useState(false);
  const [selectedMarkup, setSelectedMarkup] = useState(null);
  const [openSidePanel, setOpenSidePanel] = useState(false);
  const [openMarkupPanel, setOpenMarkupPanel] = useState(false);
  const [openRFIPanel, setOpenRFIPanel] = useState(false);
  const [openIssues, setOpenIssues] = useState(false);
  const [addNewPanel, enableAddNewPanel] = useState(false);
  const [openfileIns, setOpenfileIns] = useState(pdfopenfiledata);
  const [annotationHide, setAnnotationHide] = useState();
  const [isChecked, setIsChecked] = useState(false);
  const [isChecked2, setIsChecked2] = useState(false);
  const [isChecked3, setIsChecked3] = useState(false);
  const [isChecked4, setIsChecked4] = useState(false);
  var annChangeReason = "";
  var markupHide = false;
  var currentPageIndex = 0;
  let saveRunning = useRef(null);
  const [saveRunningView, setSaveRunningView] = useState(false);
  React.useEffect(() => {
    //console.log("currentLoadedFile",currentLoadedFile);
    if (currentLoadedFile) {
      loadpdf(currentLoadedFile.verAUrl);
    }
  }, [currentLoadedFile]);
  // only when mounted
  React.useEffect(() => {
    //console.log(' file data is ' + pdfopenfiledata);
    if (pdfopenfiledata) {
      firstSave.current = false;
      getVersions();
    }
  }, []);

  const containerRef1 = React.useRef(null);
  const firstSave = React.useRef(null);
  let pdfMarkupJSON;
  let pdfMarkup;
  if (pdfopenfiledata?.markupJSON) {
    pdfMarkupJSON = pdfopenfiledata?.markupJSON;
    //console.log("pdfMarkup >>>>>>>>>>>>", pdfopenfiledata.markupJSON);
    let annotationList = JSON.parse(pdfopenfiledata.markupJSON).annotations;
    pdfMarkup = {
      format: "https://pspdfkit.com/instant-json/v1",
      annotations: annotationList,
    };
    //console.log("pdfMarkup >>>>>>>>>>>>", JSON.stringify(annotationList));
  }else{
    pdfMarkupJSON = pdfopenfiledata?.markupJSON;
  }

  const [selectedIssueStamp, setSelectedIssueStamp] = React.useState(null);
  const [selectedSFIssueObj, setSelectedSFIssueObj] = React.useState(null);
  const [selectedRFIStamp, setSelectedRFIStamp] = React.useState(null);
  const [selectedSFRFIObj, setSelectedSFRFIObj] = React.useState(null);

  async function _printFile() {
    instanceObject.print();
  }
  function getCustomData() {
    return {
      userId: credential?.userId,
      userFullName: credential?.userFullName,
      source: "save_annotation",
      access: "private",
    };
  }
  function checkAnnoJSObjIfIssueStamp(annJs) {
    if (
      annJs.stampType == "Custom" &&
      annJs.subtitle == "Issue" &&
      annJs.title == "I"
    ) {
      return true;
    }
    return false;
  }
  function checkAnnoJSObjIfRFIStamp(annJs) {
    if (
      annJs.stampType == "Custom" &&
      annJs.subtitle == "RFI" &&
      annJs.title == "R"
    ) {
      return true;
    }
    return false;
  }
  async function onClickIssueSFSave(
    issueObj,
    sfIssueList = pdfopenfiledata.issueList
  ) {
    setSpinnerSide(true);
    issueObj["Project__c"] = credential?.projectId
    const formData = new FormData();
    //let issueObj = sfIssueObj;
    formData.append("issueObj", JSON.stringify(issueObj));
    await axios
      .post(
        `${fetchUrl}/saveIssue/${credential?.projectId}?token=${credential?.token}&instanceUrl=${credential?.instanceUrl}`,
        formData
      )
      .then(async (res) => {
        if (res.status === 200) {
          issueObj.Id = res.data.id;
          let foundInd = -1;
          if (!sfIssueList) {
            sfIssueList = [];
          }
          if (
            sfIssueList &&
            sfIssueList.length > 0 &&
            issueObj[annoIdField] != null
          ) {
            const temp = JSON.parse(JSON.stringify(sfIssueList));
            foundInd = findIndex(temp, {
              [annoIdField]: issueObj[annoIdField],
            });
          }
          if (foundInd > -1) {
            sfIssueList[foundInd] = issueObj;
          } else {
            issueObj.LastModifiedBy = {
              Id: credential?.userId,
              Name: credential?.userFullName,
            };
            issueObj.LastModifiedDate = new Date();
            sfIssueList.push(issueObj);
          }
          await handleIssueListChange(sfIssueList);
          toast.success("Issue Saved.");
        }
      })
      .catch((error) => {
        setSpinnerSide(false);
        console.log("error >>>>>>>>>>>>", JSON.stringify(error));
        console.error(error);
      });
    setSpinnerSide(false);
  }
  async function handleIssueListChange(issueLists, inObj = instanceObject) {
    let _row = JSON.parse(JSON.stringify(pdfopenfiledata));
    _row.issueList = issueLists;
    _row.issue = _row.issueList.length;
    pdfopenfiledata = _row;
    setOpenfileIns(pdfopenfiledata);
    setTimeout(async () => {
      await saveAnnotations(inObj, 'issue-rfi');
    }, 100);
  }
  async function insertIssueAnnotationStamp(inObj = instanceObject) {
    const annotation = getIssueAnnotationConstantStamp(inObj);
    /*
      annotation.set('pageIndex' , 0);new pspdfkit_Imp.Annotations.EllipseAnnotation({
        pageIndex: 0,
        boundingBox: new pspdfkit_Imp.Geometry.Rect({ left: Math.round(inObj.pageInfoForIndex(0).width/2), top: Math.round(inObj.pageInfoForIndex(0).height/2), width: 50, height: 50, }),
        fillColor : new pspdfkit_Imp.Color(issueStatusToColorMap['Draft'].colorBox),
        strokeColor: new pspdfkit_Imp.Color({r:255, g:255, b:255}),
        strokeWidth: 10,
        subject: 'issue'
      });
    */
    const [createdAnnotation] = await inObj.create(annotation);
    setSelectedMarkupFromIns(createdAnnotation.id, inObj);
  }
  async function insertRFIAnnotationStamp(inObj = instanceObject) {
    const annotation = getRFIAnnotationConstantStamp(inObj);
    const [createdAnnotation] = await inObj.create(annotation);
    setSelectedMarkupFromIns(createdAnnotation.id, inObj);
  }
  function getIssueAnnotationConstantStamp(inObj = instanceObject) {
    return new pspdfkit_Imp.Annotations.StampAnnotation({
      pageIndex: currentPageIndex,
      stampType: "Custom",
      title: "I",
      subtitle: "Issue",
      color: new pspdfkit_Imp.Color(issueStatusToColorMap["Draft"].colorBox),
      boundingBox: new pspdfkit_Imp.Geometry.Rect({
        left: Math.round(inObj.pageInfoForIndex(currentPageIndex).width / 2),
        top: Math.round(inObj.pageInfoForIndex(currentPageIndex).height / 2),
        width: 50,
        height: 50,
      }),
    });
  }
  function getRFIAnnotationConstantStamp(inObj = instanceObject) {
    return new pspdfkit_Imp.Annotations.StampAnnotation({
      pageIndex: currentPageIndex,
      stampType: "Custom",
      title: "R",
      subtitle: "RFI",
      color: new pspdfkit_Imp.Color(rfiStageToColorMap["Draft"].colorBox),
      boundingBox: new pspdfkit_Imp.Geometry.Rect({
        left: Math.round(inObj.pageInfoForIndex(currentPageIndex).width / 2),
        top: Math.round(inObj.pageInfoForIndex(currentPageIndex).height / 2),
        width: 50,
        height: 50,
      }),
    });
  }
  async function onClickRFISFSave(rfiObj, sfRFIList = pdfopenfiledata.rfiList) {
    setSpinnerSide(true);
    const formData = new FormData();
    //let rfiObj = sfRFIObj;
    formData.append("rfiObj", JSON.stringify(rfiObj));
    await axios
      .post(
        `${fetchUrl}/saveRFI/${credential?.projectId}?token=${credential?.token}&instanceUrl=${credential?.instanceUrl}`,
        formData
      )
      .then(async (res) => {
        if (res.status === 200) {
          rfiObj.Id = res.data.id;
          let foundInd = -1;
          if (!sfRFIList) {
            sfRFIList = [];
          }
          if (
            sfRFIList &&
            sfRFIList.length > 0 &&
            rfiObj[annoIdField] != null
          ) {
            const temp = JSON.parse(JSON.stringify(sfRFIList));
            foundInd = findIndex(temp, { [annoIdField]: rfiObj[annoIdField] });
          }
          if (foundInd > -1) {
            sfRFIList[foundInd] = rfiObj;
          } else {
            rfiObj.LastModifiedBy = {
              Id: credential?.userId,
              Name: credential?.userFullName,
            };
            rfiObj.LastModifiedDate = new Date();
            sfRFIList.push(rfiObj);
          }
          await handleRFIListChange(sfRFIList);
          toast.success("RFI Saved.");
        }
      })
      .catch((error) => {
        setSpinnerSide(false);
        console.log("error >>>>>>>>>>>>", JSON.stringify(error));
        console.error(error);
      });
    setSpinnerSide(false);
  }
  async function handleRFIListChange(rfiLists, inObj = instanceObject) {
    let _row = JSON.parse(JSON.stringify(pdfopenfiledata));
    _row.rfiList = rfiLists;
    _row.rfiCount = _row.rfiList.length;
    pdfopenfiledata = _row;
    setOpenfileIns(pdfopenfiledata);
    setTimeout(async () => {
      await saveAnnotations(inObj, 'issue-rfi');
    }, 100);
    //updateTableState(pdfopenfiledata);
  }
  const handleCloseIssueDialog = (inObj = instanceObject) => {
    console.log("handleCloseIssueDialog :: ");
    let selectedId = selectedIssueStamp.id;
    removeSelectedAnnoFromINS();
    setAnnotationSelectionSource(true);
    setSelectedMarkupFromIns(selectedId);
    setSelectedIssueStamp(null);
    console.log("close sadfads");
  };
  const handleCloseRFIDialog = (inObj = instanceObject) => {
    //console.log('Close RFIDialog :: '+JSON.stringify(sfRFIObj));
    let selectedId = selectedRFIStamp.id;
    removeSelectedAnnoFromINS();
    setAnnotationSelectionSource(true);
    setSelectedMarkupFromIns(selectedId);
    setSelectedRFIStamp(null);
  };

  function downloadPdf() {
    const a = document.createElement("a");
    a.href = pdfopenfiledata.url;
    a.style.display = "none";
    a.download = `${pdfopenfiledata?.name}`;
    a.setAttribute("download", pdfopenfiledata?.name);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
  const selectVersion = (v) => {
    if (!compareActive) {
      setCurrentLoadedFile(v);
    }
  };

  async function getVersions() {
    //console.log("folderId ::",pdfopenfiledata?.folderId ,"Id ::", pdfopenfiledata?.id);
    await axios
      .get(
        `${fetchUrl}/version/list/${pdfopenfiledata?.folderId}/${pdfopenfiledata?.id}`
      )
      .then((res) => {
        if (res?.status === 200) {
          //console.log("res.data.versionList; ::",res.data.versionList);
          let versionList = res.data.versionList;
          if (versionList && versionList.length > 0) {
            versionList.forEach((e, i) => {
              e.selected = i === 0 || i === 1 ? true : false;
              e.versionLabel = e.SF_Display_Version;
              e.lastUpdated = e.SF_CreatedDate;
              e.name = pdfopenfiledata?.name;
              e.folderId = pdfopenfiledata?.folderId;
              e.id = pdfopenfiledata?.id;
            });
            setVersionsList(versionList);
            const currentFile = find(versionList, {
              SF_Display_Version_Num: pdfopenfiledata?.version,
            });
            //console.log("currentFile ::",currentFile);
            if (currentFile) {
              setCurrentLoadedFile(currentFile);
            }
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function loadpdf(url) {
    runAutoSave.current = true;
    //console.log('*** loading ', url);
    try {
      if (pspdfkit_Imp) {
        await pspdfkit_Imp.unload("#pdfcontainer");
      }
    } catch (e) {
      console.error(e);
    }
    enableSave(false);
    canUndo(false);
    canRedo(false);
    instance = await pspdfkit_Imp
      .load({
        container: containerRef1.current,
        document: url,
        printMode: pspdfkit_Imp.PrintMode.DOM,
        baseUrl: `${window.location.protocol}//${window.location.host}/${process.env.PUBLIC_URL}`,
        initialViewState: new pspdfkit_Imp.ViewState({
          sidebarMode: pspdfkit_Imp.SidebarMode.TEXT,
          zoom: pspdfkit_Imp.ZoomMode.FIT_TO_WIDTH,
          interactionMode: "PAN",
        }),
        instantJSON: pdfMarkup,
        isEditableAnnotation: (annotation) =>
          annotation?.creatorName === credential?.userFullName ||
          annotation?.customData?.userId === credential?.userId,
        enableHistory: true,
      })
      .catch((error) => {
        console.log("error >>>>>>>>>>>>", JSON.stringify(error));
        console.error(error);
      });
    setPspdfkitValue(pspdfkit_Imp);
    if (instance) {
      instance?.setAnnotationCreatorName(credential?.userFullName);
      instance?.setAnnotationPresets((annotationPresets) => {
        let excludedKeys = [
          "stamp",
          "text-highlighter",
          "highlighter",
          "widget",
          "image",
        ];
        let state = annotationPresets;
        for (var key in state) {
          //console.log('state key :: '+key);
          if (state.hasOwnProperty(key) && !excludedKeys.includes(key)) {
            let redColor = new pspdfkit_Imp.Color({ r: 248, g: 36, b: 0 });
            if (state[key].hasOwnProperty("strokeColor")) {
              state[key].strokeColor = redColor;
            } else if (state[key].hasOwnProperty("fontColor")) {
              state[key].fontColor = redColor;
            }
          }
        }
        return state;
      });
      setInstanceObject(instance);
      const items = instance?.toolbarItems;
      let excludeButtons = [
        "sidebar-thumbnails",
        "sidebar-document-outline",
        "sidebar-annotations",
        "sidebar-bookmarks",
        "document-crop",
        "document-editor",
        "export-pdf",
        "pager",
        "print",
        "zoom-mode",
        "signature",
        "image",
      ];
      items.forEach((item, index) => {
        //console.log('item.type :: '+item.type);
        if (item.type === "zoom-in") {
          items.splice(index + 1, 0, { type: "undo" });
          items.splice(index + 2, 0, { type: "redo" });
        }else if(item.type === "text"){
          items.splice(index + 1, 0, { type: "cloudy-rectangle" });
        }/*else if(item.type === "text-highlighter"){
          items.splice(index + 1, 0, { type: "strikeout" });
          items.splice(index + 2, 0, { type: "underline" });
        }*/
      });
      /*let item = getCustomNodeButton("save_annotations", "Save");
      item["onPress"] = () => saveAnnotations(instance );*/
      /*let item2 = getCustomNodeButton("toggle_annotations", "Show/Hide");
      item2["onPress"] = () => showHideAnnotations(instance);*/
      let g1 = getCustomNodeButton("view_all", "View All");
      g1.dropdownGroup = "my-group";
      g1.selected = true;
      g1["onPress"] = () => filterAnnotationVisiblity(instance, g1.id);
      let g2 = getCustomNodeButton("hide_all", "Hide All");
      g2.dropdownGroup = "my-group";
      g2["onPress"] = () => filterAnnotationVisiblity(instance, g2.id);
      let g3 = getCustomNodeButton("hide_pub", "Public Markup");
      g3.dropdownGroup = "my-group";
      g3["onPress"] = () => filterAnnotationVisiblity(instance, g3.id);
      let g4 = getCustomNodeButton("hide_pri", "Private Markup");
      g4.dropdownGroup = "my-group";
      g4["onPress"] = () => filterAnnotationVisiblity(instance, g4.id);
      let g5 = getCustomNodeButton("show_issue", "Only Show Issue Pins");
      g5.dropdownGroup = "my-group";
      g5["onPress"] = () => filterAnnotationVisiblity(instance, g5.id);
      const customToolbar = document.createElement("div");
      customToolbar.innerHTML = `
            <select id="mySelect">
              <option value="view_all">default</option>
              <option value="hide_all">Hide All</option>
              <option value="hide_pub">Public Markup</option>
              <option value="hide_pri">Private Markup</option>
              <option value="show_issue">Only Show Issue Pins</option>
            </select>
          `;
      const ss = customToolbar.querySelector("#mySelect");
      ss.onchange = function (event) {
        filterAnnotationVisiblity(instance, event.target.value);
      };
      let item = { id: "fffff", type: "custom", node: customToolbar };
      let filter = {
        type: "custom",
        id: "my-custom-button",
        //title: "click me",
        icon: "/filter.svg",
        onPress: function () {
          setAnnotationHide(true);
        },
      };

      instance?.setToolbarItems(
        [...items, /*item,*/ /*item2*/ /*g1,g2,g3,g4,g5 ,*/ filter].filter(
          (itm) =>
            !(
              excludeButtons.includes(itm.type) ||
              excludeButtons.includes(itm.id)
            )
        )
      );
      instance.setStampAnnotationTemplates(function (stampAnnotationTemplates) {
        let newList = [
          getIssueAnnotationConstantStamp(instance),
          getRFIAnnotationConstantStamp(instance),
        ];
        return newList;
        /*
        let newList = [
          new pspdfkit_Imp.Annotations.StampAnnotation({ stampType: "Custom", title: "I", subtitle: "Issue", color: new pspdfkit_Imp.Color({ r: 64, g: 0, b: 0 }), boundingBox: new pspdfkit_Imp.Geometry.Rect({ left: 0, top: 0, width: 50, height: 50 }) }) ,
          new pspdfkit_Imp.Annotations.StampAnnotation({ stampType: "Custom", title: "R", subtitle: "RFI", color: new pspdfkit_Imp.Color({ r: 0, g: 64, b: 0 }), boundingBox: new pspdfkit_Imp.Geometry.Rect({ left: 0, top: 0, width: 50, height: 50 }) })
        ];
        stampAnnotationTemplates.push(
          new pspdfkit_Imp.Annotations.StampAnnotation({
            stampType: "Custom",
            title: "My custom text",
            subtitle: "my custom subtitle",
            color: new pspdfkit_Imp.Color({ r: 8, g: 124, b: 217 }),
            boundingBox: new pspdfkit_Imp.Geometry.Rect({
              left: 0,
              top: 0,
              width: 300,
              height: 100
            })
          })
        );
        return stampAnnotationTemplates;*/
      });
      runAutoSave.current = false;
      let markupJson_ = pdfMarkupJSON;
      let tt_ob = markupJson_ ? JSON.parse(markupJson_) : undefined;
      let updateInitialJSON = false;
      setOpenfileIns(pdfopenfiledata);
      updateTableState(pdfopenfiledata);
      for (let p = 0; p < instance.totalPageCount; p++) {
        const annotations = await instance.getAnnotations(p);
        for (let e = 0; e < annotations.size; e++) {
          let annObj = annotations.get(e).toJS();
          //console.log('annObj : '+JSON.stringify(annObj));
          if (annObj?.customData?.source == "save_annotation") {
            if (annObj.customData.access != "public") {
              //hiding different user's annotations
              if (annObj.customData.userId != credential?.userId) {
                await instance.update(annotations.get(e).set("noPrint", true)); // this works
                await instance.update(annotations.get(e).set("noView", true)); // this works
              }
            }
          } else {
            //console.log('annObj : delete');
            //deleting out of system annotations
            await instance.delete(annotations.get(e)); // this works
            if(tt_ob){
              if(tt_ob.annotations && tt_ob.annotations.length > 0 ){
                let tList = JSON.parse(JSON.stringify(tt_ob.annotations));
                const fInddd = findIndex(tList, { id: annObj.id });
                if(fInddd > -1){
                  tList.splice(fInddd, 1);
                  tt_ob.annotations =  JSON.parse(JSON.stringify(tList));
                  updateInitialJSON = true;
                }
              }
            }
          }
        }
      }
      if(firstSave.current != true && updateInitialJSON){
        let _row = JSON.parse(JSON.stringify(pdfopenfiledata));
        _row.markupJSON = tt_ob && tt_ob.annotations.length > 0 ? JSON.stringify(tt_ob) : null ;
        let markupBreakDown = getInstantJSONBreakdown(_row.markupJSON , credential) ;
        let annoList = markupBreakDown.annoList;
        let issueList = markupBreakDown.issueList;
        let rfiList = markupBreakDown.rfiList;
        //console.log("issueList", JSON.stringify(issueList));
        _row.Markup_Json__c = _row.markupJSON ;
        _row.attObj.Markup_Json__c = _row.markupJSON ;
  
        _row.markupCount = markupBreakDown.cUserAnnCount;
        _row.markupInstantList = annoList;
        _row.issueInstantList = issueList;
        _row.rfiInstantList = rfiList;
        pdfopenfiledata = _row;
        setOpenfileIns(pdfopenfiledata);
        updateTableState(pdfopenfiledata);
      }
      runAutoSave.current = true;
      instance.addEventListener(
        "annotations.create",
        async (createdAnnotations) => {
          //console.log('create called');
          for (let e = 0; e < createdAnnotations?.size; e++) {
            let annObj = createdAnnotations.get(e).toJS();
            let customData = annObj.customData;
            if (!customData) {
              // will run for new annotations only
              annObj.customData = getCustomData();
              //console.log("customData", annObj.customData);
              //console.log('create called customupdate');
              await instance.update(
                createdAnnotations.get(e).set("customData", annObj.customData)
              );
            }
          }
        }
      );
      instance.addEventListener(
        "viewState.currentPageIndex.change",
        (pageIndex) => {
          currentPageIndex = pageIndex;
        }
      );
      instance.addEventListener("annotations.willChange", (event) => {
        //console.log('reason :: '+event.reason);
        //console.log('willChange save Enabled :: '+saveEnabled);
        //setReason(event.reason);
        annChangeReason = event.reason;
        if (event.reason == "DELETE_START") {
          //console.log('reason set null :: '+event.reason);
          setSelectedIssueStamp(null);
          setSelectedRFIStamp(null);
        }
      });
      instance.addEventListener("annotations.delete", async (annotation) => {
        if (annotation) {
          let annObj = annotation.toJS()[0];
          //console.log("annotation delete :: "+JSON.stringify(annObj));
          if (annObj.pageIndex != null) {
            if (checkAnnoJSObjIfIssueStamp(annObj)) {
              if (annObj.customData) {
                //
                deleteIssueWithAnnId(annObj.id, instance);
              }
            } else if (checkAnnoJSObjIfRFIStamp(annObj)) {
              if (annObj.customData) {
                //
                deleteRFIWithAnnId(annObj.id, instance);
              }
            } else {
              enableSave(true);
              checkUndoRedo(instance);
            }
          }
        }
      });
      instance.addEventListener(
        "annotations.update",
        async (updatedAnnotations) => {
          //console.log('annotations.update called');
          let pushChange = false;
          //console.log("runAutoSave.current :: " + runAutoSave.current);
          if (runAutoSave.current == true) {
            for (let e = 0; e < updatedAnnotations?.size; e++) {
              let annObj = updatedAnnotations.get(e).toJS();
              if (
                !checkAnnoJSObjIfIssueStamp(annObj) &&
                !checkAnnoJSObjIfRFIStamp(annObj)
              ) {
                pushChange = true;
              }
              //console.log('annotations.update called customupdate',annObj.customData);
            }
            if (pushChange) {
              console.log("save called");
              await saveAnnotations(instance);
            } else {
              enableSave(true);
              checkUndoRedo(instance);
            }
          }
        }
      );
      instance.addEventListener(
        "annotationSelection.change",
        async (annotation) => {
          //console.log('annotationSelection.change called');
          setSelectedMarkup(null);
          setSelectedIssueStamp(null);
          setSelectedRFIStamp(null);
          if (annotation) {
            let annObj = annotation.toJS();
            //console.log('selected Annotation before :: '+JSON.stringify(annObj));
            if (annObj.pageIndex != null) {
              //console.log('selected Annotation :: '+JSON.stringify(annObj));
              let customData = annObj.customData;
              if (!customData) {
                // will run for new annotations only
                annObj.customData = getCustomData();
                //console.log('annotationSelection.change called customupdate');
                await instance.update(
                  annotation.set("customData", annObj.customData)
                );
              }
              let selectedFromSidePanelOrClosedFromDialog_;
              await setAnnotationSelectionSource((state) => {
                //console.log('state '+state);
                selectedFromSidePanelOrClosedFromDialog_ = state;
                return state;
              });
              setTimeout(async () => {
                let openSideNewPanel = false;
                if (checkAnnoJSObjIfIssueStamp(annObj)) {
                  if (annChangeReason != "DELETE_START") {
                    if (!selectedFromSidePanelOrClosedFromDialog_) {
                      setSelectedIssueStamp(annObj);
                      openSideNewPanel = true;
                    }
                  }
                } else if (checkAnnoJSObjIfRFIStamp(annObj)) {
                  if (annChangeReason != "DELETE_START") {
                    if (!selectedFromSidePanelOrClosedFromDialog_) {
                      setSelectedRFIStamp(annObj);
                      openSideNewPanel = true;
                    }
                  }
                } else {
                  setSelectedMarkup(annObj);
                }
                if (openSideNewPanel) {
                  handleOpenSidePanel("addNew");
                }
              }, 100);
            }
          }
          checkUndoRedo(instance);
          setAnnotationSelectionSource(false);
        }
      );
      instance.addEventListener("annotations.blur", async (event) => {
        //console.log(event.annotation, event.nativeEvent.type);
        let annotation = event.annotation;
        let annObj = annotation.toJS();
        if (annObj.pageIndex != null) {
          //console.log('blur Annotation :: '+JSON.stringify(annObj));
          let customData = annObj.customData;
          if (!customData) {
            // will run for new annotations only
            annObj.customData = getCustomData();
            //console.log('in update :: ');
            await instance.update(
              annotation.set("customData", annObj.customData)
            );
          }
        }
        checkUndoRedo(instance);
      });
      instance.setOnAnnotationResizeStart((event) => {
        let annotation = event.annotation;
        let annObj = annotation.toJS();
        if (annObj.pageIndex != null) {
          if (annObj.customData) {
            //
            if (
              checkAnnoJSObjIfIssueStamp(annObj) ||
              checkAnnoJSObjIfRFIStamp(annObj)
            ) {
              //console.log("issue resize :: "+JSON.stringify(annObj));
              return false; // it throws error as this is not accepted return type for setOnannotations resize
            }
          }
        }
      });
      instance.addEventListener("history.undo", function (undoEvent) {
        const {
          type, // "undo"
          before: previousAnnotation, // Previous state of the annotation, or null if it's being restored.
          after: annotation, // Resulting state of the annotation, or null if it's being deleted.
        } = undoEvent;
        //console.log('type :: '+'undo');
        //console.log('undo before :: '+JSON.stringify(previousAnnotation));
        //console.log('undo after :: '+JSON.stringify(annotation));
        let annObj = previousAnnotation.toJS();
        if (
          checkAnnoJSObjIfIssueStamp(annObj) ||
          checkAnnoJSObjIfRFIStamp(annObj)
        ) {
          //console.log('in :: ' + 'undo');
          if (instance.history.canRedo()) {
            //console.log('to :: ' + 'redo');
            instance.history.redo();
          }
        }
        checkUndoRedo(instance);
      });
      instance.addEventListener("history.redo", function (redoEvent) {
        const {
          type, // "redo"
          before: previousAnnotation, // Previous state of the annotation, or null if it's being restored.
          after: annotation, // Resulting state of the annotation, or null if it's being deleted.
        } = redoEvent;
        //console.log('redo before :: '+JSON.stringify(previousAnnotation));
        //console.log('redo after :: '+JSON.stringify(annotation));
        checkUndoRedo(instance);
        //console.log('type :: '+'redo');
        //console.log('before :: '+ JSON.stringify(previousAnnotation));
        //console.log('after :: '+JSON.stringify(annotation));
      });
      setInstanceObject(instance);
    } else {
      setInstanceObject(null);
    }
    //console.log(instance, "instance");
  }
  function checkUndoRedo(_instance = instanceObject) {
    canUndo(_instance.history.canUndo());
    canRedo(_instance.history.canRedo());
  }
  async function saveAnnotations(_instance = instanceObject , calledFor) {
    if(!saveRunning.current){
      if(calledFor == 'issue-rfi'){
        setSpinner(true);
      }
      //console.log('calledFor :: '+calledFor);
      saveRunning.current = true;
      setSaveRunningView(true);
      runAutoSave.current = false;
      // updating annotations with data
      let cusIds = [];
      let cusIdsstam = [];
      for(let p =0 ; p < _instance.totalPageCount ; p++) {
        const annotations = await _instance.getAnnotations(p);
        for(let e =0 ; e < annotations.size ; e++) {
          let annObj = annotations.get(e).toJS();
          let updateCustomData = false ;
          let customData = annObj.customData ;
          //console.log('saveAnnotations called customupdate',customData);
          if(!customData){ // will run for new annotations only
            customData = getCustomData();
            updateCustomData = true;
          }
          if(customData){
            cusIds.push(annObj.id);
          }
          if(checkAnnoJSObjIfIssueStamp(annObj) || checkAnnoJSObjIfRFIStamp(annObj)){
            if(customData.access != 'public'){
              customData.access = 'public' ;
              updateCustomData = true;
            }
            if(customData){
              cusIdsstam.push(annObj.id);
            }
          }
          //console.log('updateCustomData',updateCustomData);
          if(updateCustomData){
            await _instance.update(annotations.get(e).set("customData", customData));
          }
          if(checkAnnoJSObjIfIssueStamp(annObj)){
            let sfIs = getSFIssueObjByAnnonateId(annObj.id);
            if(sfIs){
              annObj.color = issueStatusToColorMap[sfIs?.Status__c] ? issueStatusToColorMap[sfIs?.Status__c].colorBox : issueStatusToColorMap['Draft'].colorBox ;
              await _instance.update(annotations.get(e).set("color",new pspdfkit_Imp.Color(annObj.color)));
            }else{
              await _instance.delete(annotations.get(e));
            }
          }else if(checkAnnoJSObjIfRFIStamp(annObj)){
            let sfIs = getSFRFIObjByAnnonateId(annObj.id);
            if(sfIs){
              annObj.color = rfiStageToColorMap[sfIs?.Stages__c] ? rfiStageToColorMap[sfIs?.Stages__c].colorBox : rfiStageToColorMap['Draft'].colorBox ;
              await _instance.update(annotations.get(e).set("color",new pspdfkit_Imp.Color(annObj.color)));
            }else{
              await _instance.delete(annotations.get(e));
            }
          }
        }
        //console.log('actual :: '+annotations.size);
      }
      runAutoSave.current = true;
      // fetching instant json
      let annotationJsonI = null;
      //let instance_ = _instance;
      await _instance.exportInstantJSON().then(function (instantJSON) {
        //console.log("instantJSON >>>>>>>>>>>>", JSON.stringify(instantJSON));
        if (instantJSON.annotations) {
          //console.log("instantJSON.annotations  ", JSON.stringify(instantJSON.annotations));
          for(let ann of instantJSON.annotations){
            ann.noPrint = false;
            ann.noView = false;
            if(cusIds.includes(ann.id)){
              if(!ann.customData){
                ann.customData = getCustomData();
                if(cusIdsstam.includes(ann.id)){
                  ann.customData.access = 'public';
                }
              }
            }
          }
          //console.log('instantJSON :: '+instantJSON.annotations.length );
          annotationJsonI = instantJSON;
        }
        //console.log("instantJSON "+ JSON.stringify(instantJSON));
      }).catch((error) => {
        console.log("error >>>>>>>>>>>>", JSON.stringify(error));
        console.error(error);
      });
      //console.log("instantJSON sendData"+ JSON.stringify(annotationJsonI));
      if(true){
        //console.log("annotationJsonI ::", annotationJsonI);
        await axios.put(`${fetchUrl}/markup/${pdfopenfiledata.id}`, annotationJsonI)
        .then((res) => {
          //console.log("resPOst", JSON.stringify(res));
          let _row = JSON.parse(JSON.stringify(pdfopenfiledata));
          if(annotationJsonI){
            _row.markupJSON = JSON.stringify(annotationJsonI) ;
          }else{
            _row.markupJSON = null ;
          }
          let markupBreakDown = getInstantJSONBreakdown(_row.markupJSON , credential) ;
          let annoList = markupBreakDown.annoList;
          let issueList = markupBreakDown.issueList;
          let rfiList = markupBreakDown.rfiList;
          //console.log("issueList", JSON.stringify(issueList));
          _row.Markup_Json__c = _row.markupJSON ;
          _row.attObj.Markup_Json__c = _row.markupJSON ;
  
          _row.markupCount = markupBreakDown.cUserAnnCount;
          _row.markupInstantList = annoList;
          _row.issueInstantList = issueList;
          _row.rfiInstantList = rfiList;
          pdfopenfiledata = _row;
          setOpenfileIns(pdfopenfiledata);
          updateTableState(pdfopenfiledata);
          firstSave.current = true;
          toast.success('Markup Saved.', {position: toast.POSITION.BOTTOM_RIGHT});
          if(calledFor == 'issue-rfi'){
            // refresh color for pin start
            setTimeout(async () => {
              let _sidePanel = openSidePanel ;
              let _openVersions = openVersions ;
              setOpenSidePanel(!_sidePanel);
              setOpenVersions(true);
              setTimeout(async () => {
                setOpenSidePanel(_sidePanel);
                setOpenVersions(_openVersions);
              }, 100);
            }, 200);
            // refresh color for pin end
          }
          enableSave(false);
          _instance.history.clear();
          checkUndoRedo(_instance);
          setSpinner(false);
        });
      }
      saveRunning.current = false;
      setSaveRunningView(false);
    }else{
      enableSave(true);
    }
    setSpinner(false);
  }
  async function filterAnnotationVisiblity(_instance = instanceObject, type) {
    let preHideSaveEnabled;
    runAutoSave.current = false;
    await enableSave((state) => {
      preHideSaveEnabled = state;
      return state;
    });
    for (let p = 0; p < _instance.totalPageCount; p++) {
      const annotations = await _instance.getAnnotations(p);
      for (let e = 0; e < annotations.size; e++) {
        let annObj = annotations.get(e).toJS();
        if (type == "view_all") {
          if (checkAnnotationChange(annObj)) {
            await _instance.update(annotations.get(e).set("noView", false));
            enableSave(preHideSaveEnabled);
          }
        } else if (type == "hide_all") {
          if (checkAnnotationChange(annObj)) {
            await _instance.update(annotations.get(e).set("noView", true));
            enableSave(preHideSaveEnabled);
          }
        } else if (type == "hide_pub") {
          if (
            checkAnnotationChange(annObj) &&
            annObj?.customData?.access != "private"
          ) {
            await _instance.update(annotations.get(e).set("noView", true));
            enableSave(preHideSaveEnabled);
          }
        } else if (type == "hide_pri") {
          if (
            checkAnnotationChange(annObj) &&
            annObj?.customData?.access != "public"
          ) {
            await _instance.update(annotations.get(e).set("noView", true));
            enableSave(preHideSaveEnabled);
          } else {
            await _instance.update(annotations.get(e).set("noView", false));
            enableSave(preHideSaveEnabled);
          }
        } else if (type == "show_issue") {
          if (checkAnnoJSObjIfIssueStamp(annObj)) {
            await _instance.update(annotations.get(e).set("noView", false));
            enableSave(preHideSaveEnabled);
          } else {
            await _instance.update(annotations.get(e).set("noView", true));
            enableSave(preHideSaveEnabled);
          }
        }
        //console.log('actual :: '+annotations.size);
      }
    }
    runAutoSave.current = true;
    function checkAnnotationChange(_ann) {
      if (
        _ann?.customData?.userId == credential?.userId ||
        _ann?.creatorName == credential?.userFullName ||
        _ann?.customData?.access == "public"
      ) {
        return true;
      }
      return false;
    }
  }

  const handleShowIssue = async () => {
    console.log("isChecked", isChecked4);
    let preHideSaveEnabled;
    runAutoSave.current = false;
    await enableSave((state) => {
      preHideSaveEnabled = state;
      return state;
    });
    if (isChecked4 === false) {
      for (let p = 0; p < instanceObject.totalPageCount; p++) {
        const annotations = await instanceObject.getAnnotations(p);
        for (let e = 0; e < annotations.size; e++) {
          let annObj = annotations.get(e).toJS();
          if (checkAnnoJSObjIfIssueStamp(annObj)) {
            await instanceObject.update(
              annotations.get(e).set("noView", false)
            );
            enableSave(preHideSaveEnabled);
          } else {
            await instanceObject.update(annotations.get(e).set("noView", true));
            enableSave(preHideSaveEnabled);
          }
        }
      }
      setIsChecked4(true);
    } else {
      for (let p = 0; p < instanceObject.totalPageCount; p++) {
        const annotations = await instanceObject.getAnnotations(p);
        for (let e = 0; e < annotations.size; e++) {
          let annObj = annotations.get(e).toJS();
          if (isChecked2 === true && isChecked3 === true) {
            if (checkAnnotationChange(annObj)) {
              await instanceObject.update(
                annotations.get(e).set("noView", true)
              );
              enableSave(preHideSaveEnabled);
            }
          } else if (isChecked2 === true) {
            if (
              checkAnnotationChange(annObj) &&
              annObj?.customData?.access === "private"
            ) {
              await instanceObject.update(
                annotations.get(e).set("noView", true)
              );
              enableSave(preHideSaveEnabled);
            }
            if (
              checkAnnotationChange(annObj) &&
              annObj?.customData?.access === "public"
            ) {
              await instanceObject.update(
                annotations.get(e).set("noView", false)
              );
              enableSave(preHideSaveEnabled);
            }
          } else if (isChecked3 === true) {
            if (
              checkAnnotationChange(annObj) &&
              annObj?.customData?.access === "private"
            ) {
              await instanceObject.update(
                annotations.get(e).set("noView", false)
              );
              enableSave(preHideSaveEnabled);
            }
            if (
              checkAnnotationChange(annObj) &&
              annObj?.customData?.access === "public"
            ) {
              await instanceObject.update(
                annotations.get(e).set("noView", true)
              );
              enableSave(preHideSaveEnabled);
            }
          } else if (isChecked === true) {
            if (checkAnnotationChange(annObj)) {
              await instanceObject.update(
                annotations.get(e).set("noView", true)
              );
              enableSave(preHideSaveEnabled);
            }
          } else {
            if (checkAnnotationChange(annObj)) {
              await instanceObject.update(
                annotations.get(e).set("noView", false)
              );
              enableSave(preHideSaveEnabled);
            }
          }
        }
      }
      setIsChecked4(false);
    }
    runAutoSave.current = true;
  };
  const handleHideAllAnnotation = async () => {
    console.log("isChecked", isChecked);
    let preHideSaveEnabled;
    runAutoSave.current = false;
    await enableSave((state) => {
      preHideSaveEnabled = state;
      return state;
    });
    if (isChecked === false) {
      for (let p = 0; p < instanceObject.totalPageCount; p++) {
        const annotations = await instanceObject.getAnnotations(p);
        for (let e = 0; e < annotations.size; e++) {
          let annObj = annotations.get(e).toJS();
          if (checkAnnotationChange(annObj)) {
            await instanceObject.update(annotations.get(e).set("noView", true));
            enableSave(preHideSaveEnabled);
          }
        }
      }
      setIsChecked(true);
    } else {
      for (let p = 0; p < instanceObject.totalPageCount; p++) {
        const annotations = await instanceObject.getAnnotations(p);
        for (let e = 0; e < annotations.size; e++) {
          let annObj = annotations.get(e).toJS();
          if (isChecked2 === true && isChecked3 === true) {
            if (checkAnnotationChange(annObj)) {
              await instanceObject.update(
                annotations.get(e).set("noView", true)
              );
              enableSave(preHideSaveEnabled);
            }
          } else if (isChecked2 === true) {
            if (
              checkAnnotationChange(annObj) &&
              annObj?.customData?.access === "private"
            ) {
              await instanceObject.update(
                annotations.get(e).set("noView", true)
              );
              enableSave(preHideSaveEnabled);
            }
            if (
              checkAnnotationChange(annObj) &&
              annObj?.customData?.access === "public"
            ) {
              await instanceObject.update(
                annotations.get(e).set("noView", false)
              );
              enableSave(preHideSaveEnabled);
            }
          } else if (isChecked3 === true) {
            if (
              checkAnnotationChange(annObj) &&
              annObj?.customData?.access === "private"
            ) {
              await instanceObject.update(
                annotations.get(e).set("noView", false)
              );
              enableSave(preHideSaveEnabled);
            }
            if (
              checkAnnotationChange(annObj) &&
              annObj?.customData?.access === "public"
            ) {
              await instanceObject.update(
                annotations.get(e).set("noView", true)
              );
              enableSave(preHideSaveEnabled);
            }
          } else {
            if (checkAnnotationChange(annObj)) {
              await instanceObject.update(
                annotations.get(e).set("noView", false)
              );
              enableSave(preHideSaveEnabled);
            }
          }
        }
      }
      setIsChecked(false);
    }
    runAutoSave.current = true;
  };
  const handleHidePrivateAnnotation = async () => {
    console.log("isChecked", isChecked2);
    let preHideSaveEnabled;
    runAutoSave.current = false;
    await enableSave((state) => {
      preHideSaveEnabled = state;
      return state;
    });
    if (isChecked2 === false) {
      for (let p = 0; p < instanceObject.totalPageCount; p++) {
        const annotations = await instanceObject.getAnnotations(p);
        for (let e = 0; e < annotations.size; e++) {
          let annObj = annotations.get(e).toJS();
          if (
            checkAnnotationChange(annObj) &&
            annObj?.customData?.access != "public"
          ) {
            await instanceObject.update(annotations.get(e).set("noView", true));
            enableSave(preHideSaveEnabled);
          }
        }
      }
      setIsChecked2(true);
    } else {
      for (let p = 0; p < instanceObject.totalPageCount; p++) {
        const annotations = await instanceObject.getAnnotations(p);
        for (let e = 0; e < annotations.size; e++) {
          let annObj = annotations.get(e).toJS();
          if (
            checkAnnotationChange(annObj) &&
            annObj?.customData?.access != "public"
          ) {
            await instanceObject.update(
              annotations.get(e).set("noView", false)
            );
            enableSave(preHideSaveEnabled);
          }
        }
      }
      setIsChecked2(false);
    }
    runAutoSave.current = true;
  };
  const handleHidePublicAnnotation = async () => {
    console.log("isChecked", isChecked3);
    let preHideSaveEnabled;
    runAutoSave.current = false;
    await enableSave((state) => {
      preHideSaveEnabled = state;
      return state;
    });
    if (isChecked3 === false) {
      for (let p = 0; p < instanceObject.totalPageCount; p++) {
        const annotations = await instanceObject.getAnnotations(p);
        for (let e = 0; e < annotations.size; e++) {
          let annObj = annotations.get(e).toJS();
          if (
            checkAnnotationChange(annObj) &&
            annObj?.customData?.access != "private"
          ) {
            await instanceObject.update(annotations.get(e).set("noView", true));
            enableSave(preHideSaveEnabled);
          }

        }
      }
      setIsChecked3(true);
    } else {
      for (let p = 0; p < instanceObject.totalPageCount; p++) {
        const annotations = await instanceObject.getAnnotations(p);
        for (let e = 0; e < annotations.size; e++) {
          let annObj = annotations.get(e).toJS();
          if (
            checkAnnotationChange(annObj) &&
            annObj?.customData?.access != "private"
          ) {
            await instanceObject.update(
              annotations.get(e).set("noView", false)
            );
            enableSave(preHideSaveEnabled);
          }
        }
      }
      setIsChecked3(false);
    }
    runAutoSave.current = true;
  };

  function checkAnnotationChange(_ann) {
    if (
      _ann?.customData?.userId == credential?.userId ||
      _ann?.creatorName == credential?.userFullName ||
      _ann?.customData?.access == "public"
    ) {
      return true;
    }
    return false;
  }
  /*async function showHideAnnotations(_instance = instanceObject) {
    let markupHide_ = !markupHide ;
    let preHideSaveEnabled ;
    await enableSave((state) => {
      preHideSaveEnabled = state;
      return state;
    });
    for(let p =0 ; p < _instance.totalPageCount ; p++) {
      const annotations = await _instance.getAnnotations(p);
      for(let e =0 ; e < annotations.size ; e++) {
        let annObj = annotations.get(e).toJS();
        if(annObj?.customData?.userId == credential?.userId || annObj?.creatorName == credential?.userFullName || annObj?.customData?.access == 'public'){
          await _instance.update(annotations.get(e).set("noView", markupHide_));
          enableSave(preHideSaveEnabled);
        }
      }
    }
    markupHide = markupHide_;
  }*/
  async function setMarukupAccess(
    markupId = selectedMarkup?.id,
    pageIndex = selectedMarkup?.pageIndex
  ) {
    if (markupId && pageIndex != null) {
      let _instance = instanceObject;
      const annotations = await _instance.getAnnotations(pageIndex);
      for (let e = 0; e < annotations.size; e++) {
        let annObj = annotations.get(e).toJS();
        if (annObj.id === markupId) {
          let customData = annObj.customData;
          if (customData) {
            // will run for new annotations only
            let value = customData.access == "private" ? "public" : "private";
            annObj.customData.access = value;
            await _instance.update(
              annotations.get(e).set("customData", annObj.customData)
            );
            await saveAnnotations(_instance);
            toast.success("Markup set to " + value.toUpperCase(), {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
            setSelectedMarkup(annObj);
            break;
          }
        }
      }
    }
  }
  async function deleteMarukup(
    markupId = selectedMarkup?.id,
    pageIndex = selectedMarkup?.pageIndex
  ) {
    if (markupId && pageIndex != null) {
      let _instance = instanceObject;
      const annotations = await _instance.getAnnotations(pageIndex);
      for (let e = 0; e < annotations.size; e++) {
        let annObj = annotations.get(e).toJS();
        if (annObj.id === markupId) {
          let customData = annObj.customData;
          if (customData) {
            // will run for new annotations only
            let value = customData.access == "private" ? "public" : "private";
            annObj.customData.access = value;
            await _instance.delete(annotations.get(e));
            await saveAnnotations(_instance);
            toast.success("Markup delete.", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
            setSelectedMarkup(null);
            break;
          }
        }
      }
    }
  }
  async function performUndoRedo(action) {
    let _instance = instanceObject;
    if (action == "undo") {
      _instance.history.undo();
    } else if (action == "redo") {
      _instance.history.redo();
    }
  }
  async function deleteIssueWithAnnId(annId, insObj = instanceObject) {
    if (
      pdfopenfiledata &&
      pdfopenfiledata?.issueList?.length > 0 &&
      annId != null
    ) {
      let tempList = JSON.parse(JSON.stringify(pdfopenfiledata?.issueList));
      const sfIssueIdx = findIndex(tempList, { [annoIdField]: annId });
      if (sfIssueIdx > -1 && tempList[sfIssueIdx]?.Id) {
        // setSpinner(true);
        await axios
          .delete(
            `${fetchUrl}/deleteIssue/${tempList[sfIssueIdx]?.Id}?token=${credential?.token}&instanceUrl=${credential?.instanceUrl}`
          )
          .then(async (res) => {
            if (res.status === 200) {
              tempList.splice(sfIssueIdx, 1);
              handleIssueListChange(tempList, insObj);
              setSelectedIssueStamp(null);
              toast.success("Issue Deleted.");
            }
          })
          .catch((error) => {
            setSpinner(false);
            console.log("error >>>>>>>>>>>>", JSON.stringify(error));
            console.error(error);
          });
        setSpinner(false);
      }
    }
  }
  async function deleteRFIWithAnnId(annId, insObj = instanceObject) {
    if (
      pdfopenfiledata &&
      pdfopenfiledata?.rfiList?.length > 0 &&
      annId != null
    ) {
      let tempList = JSON.parse(JSON.stringify(pdfopenfiledata?.rfiList));
      const sfIssueIdx = findIndex(tempList, { [annoIdField]: annId });
      if (sfIssueIdx > -1 && tempList[sfIssueIdx]?.Id) {
        // setSpinner(true);
        await axios
          .delete(
            `${fetchUrl}/deleteRFI/${tempList[sfIssueIdx]?.Id}?token=${credential?.token}&instanceUrl=${credential?.instanceUrl}`
          )
          .then(async (res) => {
            if (res.status === 200) {
              tempList.splice(sfIssueIdx, 1);
              handleRFIListChange(tempList, insObj);
              setSelectedRFIStamp(null);
              toast.success("RFI Deleted.");
            }
          })
          .catch((error) => {
            setSpinner(false);
            console.log("error >>>>>>>>>>>>", JSON.stringify(error));
            console.error(error);
          });
        setSpinner(false);
      }
    }
  }
  async function removeSelectedAnnoFromINS(insObj = instanceObject) {
    setSelectedMarkupFromIns(null, insObj);
  }
  async function setSelectedMarkupFromIns(annId, insObj = instanceObject) {
    if (insObj) {
      await insObj.setSelectedAnnotation(annId);
    }
  }
  async function deleteSelectedMarkup(annId, insObj = instanceObject) {
    if (insObj) {
      await insObj.delete(annId);
    }
  }
  function getSFIssueObjByAnnonateId(annId) {
    if (
      pdfopenfiledata &&
      pdfopenfiledata?.issueList?.length > 0 &&
      annId != null
    ) {
      let tempList = JSON.parse(JSON.stringify(pdfopenfiledata?.issueList));
      const sfIssueIdx = findIndex(tempList, { [annoIdField]: annId });
      if (sfIssueIdx > -1 && tempList[sfIssueIdx]?.Id) {
        return tempList[sfIssueIdx];
      }
    }
  }
  function getSFRFIObjByAnnonateId(annId) {
    if (
      pdfopenfiledata &&
      pdfopenfiledata?.rfiList?.length > 0 &&
      annId != null
    ) {
      let tempList = JSON.parse(JSON.stringify(pdfopenfiledata?.rfiList));
      const sfRFIIdx = findIndex(tempList, { [annoIdField]: annId });
      if (sfRFIIdx > -1 && tempList[sfRFIIdx]?.Id) {
        return tempList[sfRFIIdx];
      }
    }
  }
  async function clickOnClose() {
    try {
      if (pspdfkitIns && instanceObject) {
        pspdfkitIns.unload(instanceObject);
      }
    } catch (e) {
      console.error(e);
    }
    handleClose();
  }
  function getCustomNodeButton(_id, _label) {
    let className =
      "PSPDFKit-8ehcbhz241z1tfyhjztbe12ube PSPDFKit-5hqvpgcgpf1769cn35dvtg4ktz PSPDFKit-Toolbar-Button PSPDFKit-Tool-Button";
    const node = document.createElement("Button");
    node.innerText = _label;
    node.className = className;
    const icon = `<svg className="slds-icon slds-icon_xx-small" aria-hidden="true">
                    <use xlink:href="/slds/icons/utility-sprite/svg/symbols.svg#success"></use>
                  </svg>`;
    let item = { id: _id, type: "custom", node: node, icon: icon };

    return item;
  }
  function getCustomNodeButton(_id, _label) {
    let className =
      "PSPDFKit-8ehcbhz241z1tfyhjztbe12ube PSPDFKit-5hqvpgcgpf1769cn35dvtg4ktz PSPDFKit-Toolbar-Button PSPDFKit-Tool-Button";
    const node = document.createElement("Button");
    node.innerText = _label;
    node.className = className;
    const icon = `<svg className="slds-icon slds-icon_xx-small" aria-hidden="true">
                    <use xlink:href="/slds/icons/utility-sprite/svg/symbols.svg#success"></use>
                  </svg>`;
    let item = { id: _id, type: "custom", node: node, icon: icon };
    return item;
  }

  function _getSelectedOption(value) {
    if (value === "export") {
      downloadPdf();
    } else if (value === "print") {
      _printFile();
    } else {
      alert("hello");
    }
  }

  const handleFileChecked = (e, index) => {
    e.preventDefault();
    e.stopPropagation();
    const tmpVersions = cloneDeep(versionsList);
    tmpVersions[index].selected = e.target.checked;
    setVersionsList(tmpVersions);
  };

  const handleCompare = () => {
    _compareFiles();
  };

  // compare to second file
  const _compareFiles = async () => {
    try {
      setOpenCompare(true);
    } catch (error) {
      console.log("Error: compare files: ", error);
    }
  };

  const closeCompareDialog = () => {
    setOpenCompare(false);
    handleClose();
  };

  const selectedFilesToCompare = filter(versionsList, { selected: true });

  if (openCompare) {
    return (
      <CompareFiles
        open={openCompare}
        filesToCompare={selectedFilesToCompare}
        handleClose={closeCompareDialog}
      />
    );
  }
  const handleOpenSidePanel = (panelName) => {
    setOpenSidePanel(true);
    //console.log('panelName :: '+panelName);
    //console.log('openfileIns :: ',JSON.stringify(openfileIns));
    if (panelName == "versions") {
      setOpenVersions(true);
      setOpenIssues(false);
      setOpenMarkupPanel(false);
      setOpenRFIPanel(false);
      enableAddNewPanel(false);
    } else if (panelName == "issues") {
      setOpenIssues(true);
      setOpenVersions(false);
      setOpenMarkupPanel(false);
      setOpenRFIPanel(false);
      enableAddNewPanel(false);
    } else if (panelName == "markup") {
      setOpenMarkupPanel(true);
      setOpenIssues(false);
      setOpenVersions(false);
      setOpenRFIPanel(false);
      enableAddNewPanel(false);
    } else if (panelName == "rfi") {
      setOpenRFIPanel(true);
      setOpenMarkupPanel(false);
      setOpenIssues(false);
      setOpenVersions(false);
      enableAddNewPanel(false);
    } else if (panelName == "addNew") {
      enableAddNewPanel(true);
      setOpenRFIPanel(false);
      setOpenMarkupPanel(false);
      setOpenIssues(false);
      setOpenVersions(false);
    } else {
      setOpenSidePanel(false);
    }
  };
  const handleCloseSidePanel = (panelName) => {
    setOpenSidePanel(false);
    setOpenMarkupPanel(false);
    setOpenIssues(false);
    setOpenVersions(false);
    setOpenRFIPanel(false);
  };
  const handleCloseify = () => {
    let hdds = true;
    console.log("handleCloseMenu handleCloseMenu", hdds);
    setAnnotationHide(false);
    //setSelectedMenuRow(null);
  };
  return (
    <>
      <Dialog
        open={open}
        scroll="paper"
        fullScreen
        onClose={handleClose}
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <ShowPdfDialogstyles>
          <DialogTitle id="alert-dialog-title">
            <Grid container className="show-pdf-header">
              <Grid item xs={10}>
                <div className="main-wrap">
                  Name: {pdfopenfiledata?.name} version:{" "}
                  {currentLoadedFile?.versionLabel}
                  {currentLoadedFile && !currentLoadedFile?.isLatest && (
                    <span className="not-current">
                      <ReportProblemRoundedIcon />
                      Not current
                    </span>
                  )}
                  {saveRunningView && 
                    <span className="custom-alert custom-alert-info" style={{marginLeft : '3rem'}}>Markup save is in progress.</span>
                  }
                </div>
              </Grid>
              <Grid item xs={2}>
                <Grid
                  container
                  className="show-pdf-header-end"
                  style={{ float: "right", flexDirection: "row-reverse" }}
                >
                  <div style={{ width: "10%" }}>
                    <MenuComponent
                      className="class-drop"
                      onChange={(val) => _getSelectedOption(val)}
                      icon={<MoreVertIcon />}
                      label={null}
                      options={[
                        { label: "Export", value: "export" },
                        { label: "Print", value: "print" },
                        { label: "Download Source", value: "download" },
                      ]}
                    />
                  </div>
                  <Button
                    style={{
                      margin: "0px 2px 0px 2px",
                      color: "black",
                      backgroundColor: "white",
                    }}
                    onClick={() => {
                      clickOnClose();
                    }}
                  >
                    Close
                  </Button>
                  {/*<Button variant="contained" style={{margin : '0px 2px 0px 2px'}} onClick={() => { saveAnnotations(); }} disabled={!saveEnabled} >
                    Save
                  </Button>*/}
                </Grid>
              </Grid>
            </Grid>
            {(saveEnabled || undoEnabled || redoEnabled || selectedMarkup) && (
              <Draggable handle="#draggable-save-buttonn">
                <div className="pspdf-floating-container">
                  <div
                    id="draggable-save-button"
                    className="pspdf-floating-header-move"
                    title="move"
                  >
                    <DragIndicator color="success" />
                  </div>
                  <div className="pspdf-floating-button-container">
                    {saveEnabled && (
                      <div className="pspdf-floating-button-container-item">
                        <Button
                          variant="contained"
                          className="pspdf-floating-button-container-item"
                          onClick={() => {
                            saveAnnotations();
                          }}
                          disabled={!saveEnabled}
                        >
                          Save
                        </Button>
                      </div>
                    )}
                    {selectedMarkup && (
                      <>
                        {selectedMarkup?.customData?.userId ==
                          credential?.userId &&
                          selectedMarkup?.pageIndex != null && (
                            <div className="pspdf-floating-button-container-item">
                              <Tooltip
                                title={
                                  selectedMarkup.customData?.access?.toUpperCase() +
                                  " Annotation"
                                }
                                placement="bottom"
                                arrow
                              >
                                <IconButton
                                  className="deck-buttons"
                                  onClick={async () => {
                                    setMarukupAccess(
                                      selectedMarkup?.id,
                                      selectedMarkup?.pageIndex
                                    );
                                  }}
                                >
                                  {selectedMarkup.customData.access ==
                                  "private" ? (
                                    <VisibilityOff color="action" />
                                  ) : (
                                    <Visibility color="info" />
                                  )}
                                </IconButton>
                              </Tooltip>
                            </div>
                          )}
                        {selectedMarkup?.customData?.userId ==
                          credential?.userId &&
                          selectedMarkup?.pageIndex != null && (
                            <div className="pspdf-floating-button-container-item">
                              <Tooltip
                                title={"Delete Annotation"}
                                placement="bottom"
                                arrow
                              >
                                <IconButton
                                  className="deck-buttons"
                                  onClick={async () => {
                                    deleteMarukup(
                                      selectedMarkup?.id,
                                      selectedMarkup?.pageIndex
                                    );
                                  }}
                                >
                                  <Delete color="info" />
                                </IconButton>
                              </Tooltip>
                            </div>
                          )}
                      </>
                    )}
                    {(undoEnabled || redoEnabled) && (
                      <>
                        <div className="pspdf-floating-button-container-item">
                          <Tooltip title="undo" placement="bottom" arrow>
                            <IconButton
                              className="deck-buttons"
                              onClick={async () => {
                                if (undoEnabled) {
                                  performUndoRedo("undo");
                                }
                              }}
                            >
                              <UndoRounded
                                color={undoEnabled ? "info" : "action"}
                              />
                            </IconButton>
                          </Tooltip>
                        </div>
                        <div className="pspdf-floating-button-container-item">
                          <Tooltip title="redo" placement="bottom" arrow>
                            <IconButton
                              className="deck-buttons"
                              onClick={async () => {
                                if (redoEnabled) {
                                  performUndoRedo("redo");
                                }
                              }}
                            >
                              <RedoRounded
                                color={redoEnabled ? "info" : "action"}
                              />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </Draggable>
            )}
          </DialogTitle>
          <DialogContent
            className="showpdf-content-body"
            style={{ position: "relative" }}
          >
            {showSpinner && <Spinner />}
            <Grid container>
              <div className="view-pdf-sidebar">
                <IconButton
                  size="small"
                  onClick={() => handleOpenSidePanel("markup")}
                >
                  <Bookmarks color={openMarkupPanel ? "info" : "action"} />
                </IconButton>
                <IconButton
                  size="small"
                  onClick={() => handleOpenSidePanel("issues")}
                >
                  <WarningAmberRoundedIcon
                    color={openIssues ? "info" : "action"}
                  />
                </IconButton>
                <IconButton
                  size="small"
                  onClick={() => handleOpenSidePanel("rfi")}
                >
                  <Summarize color={openRFIPanel ? "info" : "action"} />
                </IconButton>
                <IconButton
                  size="small"
                  onClick={() => handleOpenSidePanel("versions")}
                >
                  <AccessTimeIcon color={openVersions ? "info" : "action"} />
                </IconButton>
              </div>
              {openSidePanel && (
                <>
                  {openMarkupPanel && (
                    <>
                      <Collapse orientation="horizontal" in={openMarkupPanel}>
                        <Grid item style={{ height: "100%" }}>
                          <div
                            className="versions-sidebar"
                            onClick={async () => {
                              removeSelectedAnnoFromINS();
                            }}
                          >
                            <div className="versions-sidebar-header">
                              <span className="versions-sidebar-header-title">
                                {openfileIns?.markupInstantList &&
                                  openfileIns?.markupInstantList?.length}{" "}
                                Annotations
                              </span>
                              <IconButton
                                size="small"
                                className="versions-sidebar-header-close"
                                onClick={() =>
                                  setOpenMarkupPanel(!openMarkupPanel)
                                }
                              >
                                <CloseRoundedIcon />
                              </IconButton>
                            </div>
                            <Divider variant="middle" />
                            {openfileIns?.markupInstantList?.map((f, index) => (
                              <Grid
                                key={f.id + " " + f.annoType}
                                container
                                className={`versions-sidebar-filecard ${
                                  selectedMarkup?.id === f?.id ? "active" : ""
                                }`}
                                onClick={async (event) => {
                                  event.stopPropagation();
                                }}
                              >
                                <Grid item xs={11}>
                                  <div
                                    role="button"
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      setSelectedMarkupFromIns(
                                        f.id,
                                        instanceObject
                                      );
                                    }}
                                  >
                                    <span className="datetime">
                                      {index + 1 + ". "}{" "}
                                      <span
                                        style={{ textTransform: "capitalize" }}
                                      >
                                        {f.annoType}
                                      </span>
                                    </span>
                                    <span>
                                      <span className="versions-sidebar-filecard-version">
                                        {f.creatorName}
                                        {", "}
                                        {moment(f.updatedAt).format("M/D/YYYY")}
                                      </span>
                                    </span>
                                  </div>
                                </Grid>
                                <Grid item xs={1}>
                                  <Tooltip
                                    title={
                                      f.customData?.access?.toUpperCase() +
                                      " Annotation"
                                    }
                                    placement="bottom"
                                    PopperProps={{
                                      className: "version-action-tooltip",
                                    }}
                                    arrow
                                  >
                                    <IconButton
                                      style={{ padding: "0.4rem" }}
                                      onClick={async (event) => {
                                        event.stopPropagation();
                                      }}
                                    >
                                      {f.customData?.access == "private" ? (
                                        <VisibilityOff color="action" />
                                      ) : (
                                        <Visibility color="info" />
                                      )}
                                    </IconButton>
                                  </Tooltip>
                                </Grid>
                              </Grid>
                            ))}
                            <Divider variant="middle" />
                          </div>
                        </Grid>
                      </Collapse>
                    </>
                  )}
                  {openIssues && (
                    <>
                      <Collapse orientation="horizontal" in={openIssues}>
                        <Grid item style={{ height: "100%" }}>
                          <div className="versions-sidebar">
                            <div className="versions-sidebar-header">
                              <span className="versions-sidebar-header-title">
                                {pdfopenfiledata?.issueList &&
                                  pdfopenfiledata?.issueList?.length}{" "}
                                Issues
                              </span>
                              <IconButton
                                size="small"
                                className="versions-sidebar-header-close"
                                onClick={() => setOpenIssues(!openIssues)}
                              >
                                <CloseRoundedIcon />
                              </IconButton>
                            </div>
                            <Divider variant="middle" />
                            <div className="versions-sidebar-body">
                              {!selectedSFIssueObj &&
                                pdfopenfiledata?.issueList?.map((f, index) => (
                                  <div
                                    key={f.Id + " " + f.Name}
                                    className={`versions-sidebar-filecard ${
                                      selectedIssueStamp?.id === f[annoIdField]
                                        ? "active"
                                        : ""
                                    }`}
                                    role="button"
                                    onClick={() => {
                                      setAnnotationSelectionSource(true);
                                      setSelectedMarkupFromIns(
                                        f[annoIdField],
                                        instanceObject
                                      );
                                      setSelectedSFIssueObj(
                                        JSON.parse(JSON.stringify(f))
                                      );
                                    }}
                                  >
                                    <span className="versions-sidebar-filecard-version">
                                      <span className="title">{f.Name}</span>{" "}
                                      <span
                                        className="status"
                                        style={{
                                          color:
                                            issueStatusToColorMap[f?.Status__c]
                                              .textColor,
                                          backgroundColor:
                                            issueStatusToColorMap[f?.Status__c]
                                              .pillColor,
                                        }}
                                      >
                                        {f.Status__c}
                                      </span>
                                    </span>
                                    <span className="versions-sidebar-filecard-detail">
                                      <span className="versions-sidebar-filecard-detail-row">
                                        Issue Type: {f?.Type__c}
                                        <br />
                                      </span>
                                      <span className="versions-sidebar-filecard-detail-row">
                                        Assigned To: {f?.Ball_in_Court__r?.Name}
                                        <br />
                                      </span>
                                      <span className="versions-sidebar-filecard-detail-row">
                                        Created By: {f?.CreatedBy?.Name}
                                        <br />
                                      </span>
                                    </span>
                                  </div>
                                ))}
                              {selectedSFIssueObj && (
                                <>
                                  <Divider variant="fullWidth" />
                                  <div
                                    style={{
                                      paddingTop: "0.5rem",
                                      paddingBottom: "0.5rem",
                                      background: "#ededed",
                                    }}
                                  >
                                    <IconButton
                                      size="medium"
                                      onClick={() => {
                                        setSelectedSFIssueObj(null);
                                        removeSelectedAnnoFromINS();
                                      }}
                                    >
                                      <ArrowBack />
                                    </IconButton>
                                  </div>
                                  <Divider variant="fullWidth" />
                                  <div
                                    style={{
                                      position: "relative",
                                      padding: "10px",
                                      paddingRight: "0px",
                                      marginTop: "0.5rem",
                                    }}
                                  >
                                    {showPanelSpinner && <Spinner />}
                                    {selectedSFIssueObj && (
                                      <IssueDetail
                                        sfObj={selectedSFIssueObj}
                                        credential={credential}
                                        saveCallback={onClickIssueSFSave}
                                        closeCallBack={() => {
                                          setSelectedSFIssueObj(null);
                                          removeSelectedAnnoFromINS();
                                        }}
                                        customStyle={{
                                          paddingBottom: "0.5rem",
                                          height: "70vh",
                                          overflow: "auto",
                                        }}
                                      />
                                    )}
                                  </div>
                                </>
                              )}
                            </div>
                            <Divider variant="middle" />
                            {!selectedSFIssueObj && (
                              <div
                                className="versions-sidebar-footer"
                                role="button"
                                onClick={() => insertIssueAnnotationStamp()}
                              >
                                Create Issue
                              </div>
                            )}
                          </div>
                        </Grid>
                      </Collapse>
                    </>
                  )}
                  {openRFIPanel && (
                    <>
                      <Collapse orientation="horizontal" in={openRFIPanel}>
                        <Grid item style={{ height: "100%" }}>
                          <div className="versions-sidebar">
                            <div className="versions-sidebar-header">
                              <span className="versions-sidebar-header-title">
                                {pdfopenfiledata?.rfiList &&
                                  pdfopenfiledata?.rfiList?.length}{" "}
                                RFIs
                              </span>
                              <IconButton
                                size="small"
                                className="versions-sidebar-header-close"
                                onClick={() => setOpenRFIPanel(!openRFIPanel)}
                              >
                                <CloseRoundedIcon />
                              </IconButton>
                            </div>
                            <Divider variant="middle" />
                            <div className="versions-sidebar-body">
                              {!selectedSFRFIObj &&
                                pdfopenfiledata?.rfiList?.map((f, index) => (
                                  <div
                                    key={f.Id + " " + f.Name}
                                    className={`versions-sidebar-filecard ${
                                      selectedRFIStamp?.id === f[annoIdField]
                                        ? "active"
                                        : ""
                                    }`}
                                    role="button"
                                    onClick={() => {
                                      setAnnotationSelectionSource(true);
                                      setSelectedMarkupFromIns(
                                        f[annoIdField],
                                        instanceObject
                                      );
                                      setSelectedSFRFIObj(
                                        JSON.parse(JSON.stringify(f))
                                      );
                                    }}
                                  >
                                    <span className="versions-sidebar-filecard-version">
                                      <span className="title">
                                        {f.Subject__c}
                                      </span>{" "}
                                      <span
                                        className="status"
                                        style={{
                                          color:
                                            rfiStageToColorMap[f?.Stages__c]
                                              .textColor,
                                          backgroundColor:
                                            rfiStageToColorMap[f?.Stages__c]
                                              .pillColor,
                                        }}
                                      >
                                        {f.Stages__c}
                                      </span>
                                    </span>
                                    <span className="versions-sidebar-filecard-detail">
                                      <span className="versions-sidebar-filecard-detail-row">
                                        RFI Type: {f?.Type__c}
                                        <br />
                                      </span>
                                      {/*<span className="versions-sidebar-filecard-detail-row">
                                        Assigned To: {f?.Ball_in_Court__r?.Name}<br/>
                                      </span>*/}
                                      <span className="versions-sidebar-filecard-detail-row">
                                        Created By: {f?.CreatedBy?.Name}
                                        <br />
                                      </span>
                                    </span>
                                  </div>
                                ))}
                              {selectedSFRFIObj && (
                                <>
                                  <Divider variant="fullWidth" />
                                  <div
                                    style={{
                                      paddingTop: "0.5rem",
                                      paddingBottom: "0.5rem",
                                      background: "#ededed",
                                    }}
                                  >
                                    <IconButton
                                      size="medium"
                                      onClick={() => {
                                        setSelectedSFRFIObj(null);
                                        removeSelectedAnnoFromINS();
                                      }}
                                    >
                                      <ArrowBack />
                                    </IconButton>
                                  </div>
                                  <Divider variant="fullWidth" />
                                  <div
                                    style={{
                                      position: "relative",
                                      padding: "10px",
                                      paddingRight: "0px",
                                      marginTop: "0.5rem",
                                    }}
                                  >
                                    {showPanelSpinner && <Spinner />}
                                    {selectedSFRFIObj && (
                                      <RFIDetail
                                        sfObj={selectedSFRFIObj}
                                        credential={credential}
                                        saveCallback={onClickRFISFSave}
                                        closeCallBack={() => {
                                          setSelectedSFRFIObj(null);
                                          removeSelectedAnnoFromINS();
                                        }}
                                        customStyle={{
                                          paddingBottom: "0.5rem",
                                          height: "70vh",
                                          overflow: "auto",
                                        }}
                                      />
                                    )}
                                  </div>
                                </>
                              )}
                            </div>
                            <Divider variant="middle" />
                            {!selectedSFRFIObj && (
                              <div
                                className="versions-sidebar-footer"
                                role="button"
                                onClick={() => insertRFIAnnotationStamp()}
                              >
                                Create RFI
                              </div>
                            )}
                          </div>
                        </Grid>
                      </Collapse>
                    </>
                  )}
                  {openVersions && (
                    <>
                      <Collapse orientation="horizontal" in={openVersions}>
                        <Grid item style={{ height: "100%" }}>
                          <div className="versions-sidebar">
                            <div className="versions-sidebar-header">
                              <span className="versions-sidebar-header-title">
                                History
                              </span>
                              <IconButton
                                size="small"
                                className="versions-sidebar-header-close"
                                onClick={() => setOpenVersions(!openVersions)}
                              >
                                <CloseRoundedIcon />
                              </IconButton>
                            </div>
                            <Divider variant="middle" />
                            <span className="versions-sidebar-filename">
                              File Name
                            </span>
                            {versionsList.map((f, index) => (
                              <div
                                key={f.SF_Display_Version_Num}
                                className={`versions-sidebar-filecard ${
                                  f.SF_Display_Version_Num ===
                                  currentLoadedFile?.SF_Display_Version_Num
                                    ? "active"
                                    : ""
                                }`}
                                role="button"
                                onClick={() => selectVersion(f)}
                              >
                                <span className="versions-sidebar-filecard-version">
                                  {compareActive && (
                                    <Checkbox
                                      className="versions-sidebar-checkbox"
                                      value={f.selected}
                                      checked={f.selected}
                                      onChange={(e) =>
                                        handleFileChecked(e, index)
                                      }
                                    />
                                  )}
                                  {f.SF_Display_Version}{" "}
                                  {f.isLatest && (
                                    <span className="current">CURRENT</span>
                                  )}
                                </span>
                                <span>
                                  Uploaded By{" "}
                                  <span className="datetime">
                                    {f.SF_CreatedBy}.{" "}
                                    {moment(f.SF_CreatedDate).format(
                                      "MMMM Do YYYY, h:mm a"
                                    )}
                                  </span>
                                </span>
                              </div>
                            ))}
                            <Divider variant="middle" />
                            {versionsList.length > 1 && (
                              <div className="versions-sidebar-compare-div">
                                {!compareActive && (
                                  <Button
                                    onClick={() => setCompareActive(true)}
                                    variant="outlined"
                                  >
                                    Compare versions
                                  </Button>
                                )}
                                {compareActive && (
                                  <DialogActions>
                                    <Button
                                      onClick={() => setCompareActive(false)}
                                      variant="text"
                                    >
                                      Cancel
                                    </Button>
                                    <Button
                                      variant="contained"
                                      onClick={handleCompare}
                                      disabled={
                                        selectedFilesToCompare.length !== 2
                                      }
                                    >
                                      Compare
                                    </Button>
                                  </DialogActions>
                                )}
                              </div>
                            )}
                          </div>
                        </Grid>
                      </Collapse>
                    </>
                  )}
                  {addNewPanel && (
                    <>
                      <Collapse orientation="horizontal" in={addNewPanel}>
                        <Grid item style={{ height: "100%" }}>
                          {selectedIssueStamp?.id && (
                            <IssueDialog
                              credential={credential}
                              sfIssueList={pdfopenfiledata.issueList}
                              annObj={selectedIssueStamp}
                              attachId={pdfopenfiledata?.attObj?.Id}
                              handleCloseDialog={handleCloseIssueDialog}
                              handleIssueChange={handleIssueListChange}
                            />
                          )}
                          {selectedRFIStamp?.id && (
                            <RFIDialog
                              credential={credential}
                              sfRFIList={pdfopenfiledata.rfiList}
                              annObj={selectedRFIStamp}
                              attachId={pdfopenfiledata?.attObj?.Id}
                              handleCloseDialog={handleCloseRFIDialog}
                              handleRFIChange={handleRFIListChange}
                            />
                          )}
                        </Grid>
                      </Collapse>
                    </>
                  )}
                </>
              )}
              <Grid item style={{ flexGrow: 1 }}>
                <div style={{ alignSelf: "right" }}>
                  <Dialog fullWidth scroll="paper" open={annotationHide}>
                    <DialogTitle>
                      <Typography>Markup Filter</Typography>
                      <IconButton
                        aria-label="close"
                        onClick={() => handleCloseify()}
                      >
                        <Close />
                      </IconButton>
                    </DialogTitle>
                    <DialogContent>
                      <Typography>
                        Hide All
                        <Checkbox
                          checked={isChecked === true}
                          onChange={handleHideAllAnnotation}
                        />
                      </Typography>
                      <Typography>
                        Hide Private Markup
                        <Checkbox
                          checked={isChecked2 === true}
                          onChange={handleHidePrivateAnnotation}
                        />
                      </Typography>
                      <Typography>
                        Hide public Markup
                        <Checkbox
                          checked={isChecked3 === true}
                          onChange={handleHidePublicAnnotation}
                        />
                      </Typography>
                      <Typography>
                        Only Show Issue Pin
                        <Checkbox
                          checked={isChecked4 === true}
                          onChange={handleShowIssue}
                        />
                      </Typography>
                    </DialogContent>
                  </Dialog>
                </div>
                <div
                  id="pdfcontainer"
                  ref={containerRef1}
                  style={{
                    width: "100%",
                    height: "calc(100vh - 80px)",
                    marginLeft: "10px",
                  }}
                ></div>
              </Grid>
            </Grid>
          </DialogContent>
        </ShowPdfDialogstyles>
      </Dialog>
    </>
  );
}
export function IssueDialog({
  credential,
  sfIssueList,
  annObj,
  attachId,
  handleCloseDialog,
  handleIssueChange,
}) {
  const [sfIssueObj, setSfIssueObj] = React.useState(null);
  const [showSpinner, setSpinner] = React.useState(false);
  React.useEffect(() => {
    let dsfIssue;
    if (sfIssueList && sfIssueList.length > 0 && annObj.id != null) {
      const temp = JSON.parse(JSON.stringify(sfIssueList));
      dsfIssue = find(temp, { [annoIdField]: annObj.id });
      //console.log('inn :: '+JSON.stringify(dsfIssue));
    }
    if (!dsfIssue) {
      dsfIssue = getDefaultSFIssueObj();
    }
    //sfIssueObj = dsfIssue;
    setVariables(dsfIssue);
    //console.log('useEffect IssueDialog :: '+JSON.stringify(sfIssueObj));
  }, [attachId, annObj]);
  function handleClose() {
    //console.log('Close IssueDialog :: '+JSON.stringify(sfIssueObj));
    handleCloseDialog();
  }
  function setVariables(_sfObj) {
    setSfIssueObj(_sfObj);
  }
  function getDefaultSFIssueObj() {
    return {
      Id: null,
      Linked_Document__c: attachId,
      [annoIdField]: annObj.id,
      Status__c: credential?.issueObjOptions?.Status__c?.default,
      Type__c: credential?.issueObjOptions?.Type__c?.default,
      Root_Cause__c: credential?.issueObjOptions?.Root_Cause__c?.default,
      OwnerId: credential?.userId,
      Owner: { Id: credential?.userId, Name: credential?.userFullName },
      Due_Date__c: moment(new Date()).format("YYYY-MM-DD"),
    };
  }
  async function onClickSave(issueObj) {
    setSpinner(true);
    issueObj["Project__c"] = credential?.projectId
    const formData = new FormData();
    //let issueObj = sfIssueObj;
    formData.append("issueObj", JSON.stringify(issueObj));
    await axios
      .post(
        `${fetchUrl}/saveIssue/${credential?.projectId}?token=${credential?.token}&instanceUrl=${credential?.instanceUrl}`,
        formData
      )
      .then(async (res) => {
        if (res.status === 200) {
          issueObj.Id = res.data.id;
          setVariables(issueObj);
          let foundInd = -1;
          if (!sfIssueList) {
            sfIssueList = [];
          }
          if (
            sfIssueList &&
            sfIssueList.length > 0 &&
            issueObj[annoIdField] != null
          ) {
            const temp = JSON.parse(JSON.stringify(sfIssueList));
            foundInd = findIndex(temp, {
              [annoIdField]: issueObj[annoIdField],
            });
          }
          if (foundInd > -1) {
            sfIssueList[foundInd] = issueObj;
          } else {
            issueObj.CreatedBy = {
              Id: credential?.userId,
              Name: credential?.userFullName,
            };
            issueObj.LastModifiedBy = issueObj.CreatedBy;
            issueObj.LastModifiedDate = new Date();
            sfIssueList.push(issueObj);
          }
          await handleIssueChange(sfIssueList);
          toast.success("Issue Saved.");
          //handleClose();
        }
      })
      .catch((error) => {
        setSpinner(false);
        console.log("error >>>>>>>>>>>>", JSON.stringify(error));
        console.error(error);
      });
    setSpinner(false);
  }
  // Render the UI for Issue Popup
  return (
    <div>
      <div className="versions-sidebar">
        <div className="versions-sidebar-header">
          <span className="versions-sidebar-header-title">
            {sfIssueObj?.Id != null ? "Edit" : "New"} Issue
          </span>
          <IconButton
            size="small"
            className="versions-sidebar-header-close"
            onClick={handleClose}
          >
            <CloseRoundedIcon />
          </IconButton>
        </div>
        <Divider variant="fullWidth" />
        <div
          style={{
            position: "relative",
            padding: "10px",
            paddingRight: "0px",
            marginTop: "0.5rem",
          }}
        >
          {showSpinner && <Spinner />}
          {sfIssueObj && (
            <IssueDetail
              sfObj={sfIssueObj}
              credential={credential}
              saveCallback={onClickSave}
              closeCallBack={handleClose}
              customStyle={{
                paddingBottom: "0.5rem",
                height: "70vh",
                overflow: "auto",
              }}
            />
          )}
        </div>
      </div>
      {/*<Dialog open={true} onClose={handleClose} fullWidth maxWidth="xs" aria-labelledby="draggable-issue-dialog-title" aria-describedby="scroll-dialog-description"
        scroll="paper" >
        <DialogTitle id="draggable-issue-dialog-title">
          {sfIssueObj?.Id != null ? "Edit" : "New"} Issue
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ position: "relative" , padding : '0px' ,paddingLeft : '0.5rem'}}>
          {showSpinner && <Spinner />}
          <DialogContentText>
             {sfIssueObj && <IssueDetail sfObj={sfIssueObj} credential={credential} saveCallback={onClickSave} closeCallBack={handleClose} customStyle={{ paddingBottom : '0.5rem' , height : '70vh' , overflow: 'auto' }}/> }
          </DialogContentText>
        </DialogContent>
      </Dialog>*/}
    </div>
  );
}
export function IssueDetail({ sfObj, credential, saveCallback, closeCallBack, 
          customStyle, docDetail, enableLinkDocSelection, openDocSelect }) {
  const [fieldContainerStyle, setFieldContainerStyle] = React.useState(customStyle);
  const [sfIssueObj, setSfIssueObj] = React.useState(sfObj);
  const [assignTo, setAssignTo] = React.useState(null);
  const [owner, setOwner] = React.useState(null);
  const [rootCause, setRootCause] = React.useState(null);
  const [type, setType] = React.useState(null);
  const [status, setStatus] = React.useState(null);
  const [location, setLocation] = React.useState(null);
  const [fileName, setFileName] = React.useState(null);
  //var sfIssueObj ;
  const [issueDueDate, setIssueDueDate] = React.useState(new Date());
  const [requiredMissing, setRequiredMissing] = React.useState(true);
  React.useEffect(() => {
    let dsfIssue = sfObj;
    setIssueDueDate(new Date(dsfIssue.Due_Date__c));
    setVariables(dsfIssue);
  }, []);
  React.useEffect(() => {
    if (enableLinkDocSelection == true) {
      let dsfIssue = sfIssueObj;
      let setVari = false;
      if (
        docDetail?.attachId != dsfIssue?.Linked_Document__c ||
        docDetail?.annoId != dsfIssue?.Annotation_Id__c
      ) {
        if (docDetail?.attachId != dsfIssue?.Linked_Document__c) {
          dsfIssue.Linked_Document__c = docDetail?.attachId;
          setVari = true;
        }
        if (docDetail?.annoId != dsfIssue?.Annotation_Id__c) {
          dsfIssue.Annotation_Id__c = docDetail?.annoId;
          setVari = true;
        }
      }
      if (docDetail?.attachLabel != fileName) {
        setFileName(docDetail?.attachLabel);
      }
      if (setVari) {
        console.log("setVari docDetail Called:: ");
        setVariables(dsfIssue);
      }
    }
  }, [docDetail]);
  function handleClose() {
    //console.log('Close IssueDialog :: '+JSON.stringify(sfIssueObj));
    closeCallBack();
  }
  function setVariables(_sfObj) {
    setAssignTo({
      label: _sfObj?.Ball_in_Court__c ? _sfObj?.Ball_in_Court__r?.Name : "",
      value: _sfObj?.Ball_in_Court__c,
    });
    setOwner({
      label: _sfObj?.OwnerId ? _sfObj?.Owner.Name : "",
      value: _sfObj?.OwnerId,
    });
    setRootCause({
      label: _sfObj?.Root_Cause__c,
      value: _sfObj?.Root_Cause__c,
    });
    setType({ label: _sfObj?.Type__c, value: _sfObj?.Type__c });
    setStatus({ label: _sfObj?.Status__c, value: _sfObj?.Status__c });
    if (_sfObj.Due_Date__c) {
      setIssueDueDate(new Date(_sfObj.Due_Date__c));
    } else {
      setIssueDueDate(null);
    }
    setRequiredMissing(!checkReadyToSave(_sfObj));
    setSfIssueObj(_sfObj);
  }
  function checkReadyToSave(_sfObj) {
    //console.log('checkReadyToSave');
    let reqfields = [
      "OwnerId",
      "Type__c",
      "Status__c",
      "Name",
      "Linked_Document__c",
      annoIdField,
    ];
    if (_sfObj) {
      for (let f of reqfields) {
        if (!_sfObj[f] || _sfObj[f] == "" || _sfObj[f] == null) {
          return false;
        }
      }
      if (
        _sfObj["Status__c"] == "Answered" ||
        _sfObj["Status__c"] == "Closed"
      ) {
        let f = "Response__c";
        if (!_sfObj[f] || _sfObj[f] == "" || _sfObj[f] == null) {
          return false;
        }
      }
    }
    return true;
  }
  async function onClickSave() {
    saveCallback(sfIssueObj);
  }
  // Render the UI for Issue Popup
  return (
    <>
      <div className="detail-paper" style={fieldContainerStyle}>
        <Grid
          container
          rowSpacing={2}
          style={{ width: "100%", paddingTop: "15px" }}
          className="sf-detail-container"
        >
          <Grid item xs={12}>
            <div className="selectedDiv d-flex">
              <Autocomplete
                className="common_class"
                disablePortal
                value={type}
                getOptionLabel={(option) =>
                  option?.label ? option?.label : ""
                }
                onChange={(event, newValue) => {
                  let fff = sfIssueObj;
                  fff.Type__c = newValue?.value;
                  setVariables(fff);
                }}
                options={credential?.issueObjOptions?.Type__c?.options}
                renderInput={(params) => (
                  <div>
                    <TextField
                      id="type"
                      variant="outlined"
                      label="Type"
                      required
                      {...params}
                      fullWidth
                    />
                  </div>
                )}
              />
            </div>
          </Grid>
          <Divider />
          <Grid item xs={12}>
            <div className="selectedDiv d-flex">
              <Autocomplete
                className="common_class"
                disablePortal
                value={status}
                getOptionLabel={(option) =>
                  option?.label ? option?.label : ""
                }
                onChange={(event, newValue) => {
                  let fff = sfIssueObj;
                  fff.Status__c = newValue?.value;
                  setVariables(fff);
                }}
                options={credential?.issueObjOptions?.Status__c?.options}
                renderInput={(params) => (
                  <div>
                    <TextField
                      id="status"
                      variant="outlined"
                      label="Status"
                      required
                      {...params}
                    />
                  </div>
                )}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="selectedDiv d-flex">
              <TextField
                className="common_class"
                id="title"
                variant="outlined"
                label="Title"
                required
                value={sfIssueObj?.Name ? sfIssueObj?.Name : undefined}
                onChange={(e) => {
                  let fff = sfIssueObj;
                  fff.Name = e?.target?.value;
                  setVariables(fff);
                }}
                onKeyDown={(event) => {
                  if (event.key.toLowerCase() != "tab") {
                    event.stopPropagation();
                  }
                }}
              />
            </div>
          </Grid>
          <Divider />
          <Grid item xs={12}>
            <div className="selectedDiv d-flex">
              <Autocomplete
                className="common_class"
                disablePortal
                value={assignTo}
                getOptionLabel={(option) =>
                  option?.label ? option?.label : ""
                }
                onChange={(event, newValue) => {
                  let fff = sfIssueObj;
                  fff.Ball_in_Court__c = newValue?.value;
                  fff.Ball_in_Court__r = {
                    Id: newValue?.value,
                    Name: newValue?.label,
                  };
                  setVariables(fff);
                }}
                options={[
                  ...[{ label: "None..", value: null }],
                  ...credential?.userList,
                ]}
                renderInput={(params) => (
                  <div>
                    <TextField
                      id="assignedTo"
                      variant="outlined"
                      label="Assigned To"
                      {...params}
                    />
                  </div>
                )}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="selectedDiv due-date d-flex">
              {/* dateinput / also need to formate date accordingly <InputLabel>Due Date</InputLabel>*/}
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  className="common_class"
                  disablePast={false}
                  placeholder="Due Date"
                  inputFormat="MM/dd/yyyy"
                  value={issueDueDate}
                  onChange={(newValue) => {
                    let fff = sfIssueObj;
                    if (newValue) {
                      fff.Due_Date__c = moment(newValue).format("YYYY-MM-DD");
                    } else {
                      fff.Due_Date__c = null;
                    }
                    setVariables(fff);
                  }}
                  renderInput={(params) => (
                    <div>
                      <TextField
                        id="dueDate"
                        variant="outlined"
                        label="Due Date"
                        {...params}
                      />
                    </div>
                  )}
                />
              </LocalizationProvider>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="selectedDiv d-flex">
              <TextField
                className="common_class"
                id="location"
                variant="outlined"
                label="Title"
                required
                value={sfIssueObj?.Location_Details__c ? sfIssueObj?.Location_Details__c : undefined}
                onChange={(e) => {
                  let fff = sfIssueObj;
                  fff.Location_Details__c = e?.target?.value;
                  setVariables(fff);
                }}
                onKeyDown={(event) => {
                  if (event.key.toLowerCase() != "tab") {
                    event.stopPropagation();
                  }
                }}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="selectedDiv d-flex">
              <TextField
                className="common_class"
                id="locationDetails"
                variant="outlined"
                label="Location Details"
                value={
                  sfIssueObj?.Location_Details__c
                    ? sfIssueObj?.Location_Details__c
                    : undefined
                }
                onChange={(e) => {
                  let fff = sfIssueObj;
                  fff.Location_Details__c = e?.target?.value;
                  setVariables(fff);
                }}
                onKeyDown={(event) => {
                  if (event.key.toLowerCase() != "tab") {
                    event.stopPropagation();
                  }
                }}
              />
            </div>
          </Grid>
          {enableLinkDocSelection && (
            <Grid item xs={12}>
              <Box className="selectedDiv d-flex">
                <Stack direction="row" spacing={2} className="linked-document">
                  <label>{fileName ? fileName : "No Linked Document"}</label>
                  <Button
                    onClick={() => {
                      openDocSelect();
                    }}
                  >
                    Add document
                  </Button>
                </Stack>
              </Box>
            </Grid>
          )}
          <Grid item xs={12}>
            <div className="selectedDiv d-flex">
              <Autocomplete
                className="common_class"
                disablePortal
                value={owner}
                getOptionLabel={(option) =>
                  option?.label ? option?.label : ""
                }
                onChange={(event, newValue) => {
                  let fff = sfIssueObj;
                  fff.OwnerId = newValue?.value;
                  fff.Owner = { Id: newValue?.value, Name: newValue?.label };
                  setVariables(fff);
                }}
                options={credential?.userList}
                renderInput={(params) => (
                  <div>
                    <TextField
                      id="owner"
                      variant="outlined"
                      label="Owner"
                      disabled
                      {...params}
                    />
                  </div>
                )}
                disabled
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="selectedDiv d-flex">
              <Autocomplete
                className="common_class"
                disablePortal
                value={rootCause}
                getOptionLabel={(option) =>
                  option?.label ? option?.label : ""
                }
                onChange={(event, newValue) => {
                  let fff = sfIssueObj;
                  fff.Root_Cause__c = newValue?.value;
                  setVariables(fff);
                }}
                options={credential?.issueObjOptions?.Root_Cause__c?.options}
                renderInput={(params) => (
                  <div>
                    <TextField
                      id="rootCause"
                      variant="outlined"
                      label="Root Cause"
                      {...params}
                    />
                  </div>
                )}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="textarea d-flex">
              <TextField
                className="common_class"
                id="Description"
                variant="outlined"
                label="Description"
                value={
                  sfIssueObj?.Description__c
                    ? sfIssueObj?.Description__c
                    : undefined
                }
                multiline
                rows={2}
                maxRows={3}
                onChange={(e) => {
                  let fff = sfIssueObj;
                  fff.Description__c = e?.target?.value;
                  setVariables(fff);
                }}
                onKeyDown={(event) => {
                  if (event.key.toLowerCase() != "tab") {
                    event.stopPropagation();
                  }
                }}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="textarea d-flex">
              <TextField
                className="common_class"
                id="response"
                variant="outlined"
                label="Response"
                value={
                  sfIssueObj?.Response__c ? sfIssueObj?.Response__c : undefined
                }
                multiline
                rows={2}
                maxRows={3}
                required={
                  sfIssueObj?.Status__c == "Closed" ||
                  sfIssueObj?.Status__c == "Answered"
                }
                onChange={(e) => {
                  let fff = sfIssueObj;
                  fff.Response__c = e?.target?.value;
                  setVariables(fff);
                }}
                onKeyDown={(event) => {
                  if (event.key.toLowerCase() != "tab") {
                    event.stopPropagation();
                  }
                }}
              />
            </div>
          </Grid>
        </Grid>
      </div>
      <DialogActions>
        <Button onClick={() => handleClose()} variant="outlined">
          Cancel
        </Button>
        <Button
          onClick={() => onClickSave()}
          variant="contained"
          disabled={requiredMissing}
          style={{ marginLeft: "5px" }}
        >
          Save
        </Button>
      </DialogActions>
    </>
  );
}
export function RFIDialog({
  credential,
  sfRFIList,
  annObj,
  attachId,
  handleCloseDialog,
  handleRFIChange,
}) {
  const [sfRFIObj, setSfRFIObj] = React.useState(null);
  const [showSpinner, setSpinner] = React.useState(false);
  React.useEffect(() => {
    let dsfRFI;
    if (sfRFIList && sfRFIList.length > 0 && annObj.id != null) {
      const temp = JSON.parse(JSON.stringify(sfRFIList));
      dsfRFI = find(temp, { [annoIdField]: annObj.id });
      //console.log('inn :: '+JSON.stringify(dsfRFI));
    }
    if (!dsfRFI) {
      dsfRFI = getDefaultSFRFIObj();
    }
    setVariables(dsfRFI);
    //console.log('useEffect RFIDialog :: '+JSON.stringify(sfRFIObj));
  }, [attachId, annObj]);
  function handleClose() {
    //console.log('Close RFIDialog :: '+JSON.stringify(sfRFIObj));
    handleCloseDialog();
  }
  function setVariables(_sfObj) {
    setSfRFIObj(_sfObj);
  }
  function getDefaultSFRFIObj() {
    return {
      Id: null,
      Linked_Document__c: attachId,
      [annoIdField]: annObj.id,
      Project__c: credential?.projectId,
      Type__c: credential?.rfiObjOptions?.Type__c?.default,
      Stages__c: "Draft",
      Status__c: "Draft",
      Impact_to_Progress__c:
        credential?.rfiObjOptions?.Impact_to_Progress__c?.default,
      Impact_to_Cost__c: credential?.rfiObjOptions?.Impact_to_Cost__c?.default,
      Return_By_Date__c: moment(new Date()).format("YYYY-MM-DD"),
    };
  }
  async function onClickSave(rfiObj) {
    setSpinner(true);
    const formData = new FormData();
    //let rfiObj = sfRFIObj;
    formData.append("rfiObj", JSON.stringify(rfiObj));
    await axios
      .post(
        `${fetchUrl}/saveRFI/${credential?.projectId}?token=${credential?.token}&instanceUrl=${credential?.instanceUrl}`,
        formData
      )
      .then(async (res) => {
        if (res.status === 200) {
          rfiObj.Id = res.data.id;
          setVariables(rfiObj);
          let foundInd = -1;
          if (!sfRFIList) {
            sfRFIList = [];
          }
          if (
            sfRFIList &&
            sfRFIList.length > 0 &&
            rfiObj[annoIdField] != null
          ) {
            const temp = JSON.parse(JSON.stringify(sfRFIList));
            foundInd = findIndex(temp, { [annoIdField]: rfiObj[annoIdField] });
          }
          if (foundInd > -1) {
            sfRFIList[foundInd] = rfiObj;
          } else {
            rfiObj.CreatedBy = {
              Id: credential?.userId,
              Name: credential?.userFullName,
            };
            rfiObj.LastModifiedBy = rfiObj.CreatedBy;
            rfiObj.LastModifiedDate = new Date();
            sfRFIList.push(rfiObj);
          }
          await handleRFIChange(sfRFIList);
          toast.success("RFI Saved.");
          //handleClose();
        }
      })
      .catch((error) => {
        setSpinner(false);
        console.log("error >>>>>>>>>>>>", JSON.stringify(error));
        console.error(error);
      });
    setSpinner(false);
  }
  // Render the UI for RFI Popup
  return (
    <>
      <div className="versions-sidebar">
        <div className="versions-sidebar-header">
          <span className="versions-sidebar-header-title">
            {sfRFIObj?.Id != null ? "Edit" : "New"} RFI
          </span>
          <IconButton
            size="small"
            className="versions-sidebar-header-close"
            onClick={handleClose}
          >
            <CloseRoundedIcon />
          </IconButton>
        </div>
        <Divider variant="fullWidth" />
        <div
          style={{
            position: "relative",
            padding: "10px",
            paddingRight: "0px",
            marginTop: "0.5rem",
          }}
        >
          {showSpinner && <Spinner />}
          {sfRFIObj && (
            <RFIDetail
              sfObj={sfRFIObj}
              credential={credential}
              saveCallback={onClickSave}
              closeCallBack={handleClose}
              customStyle={{
                paddingBottom: "0.5rem",
                height: "70vh",
                overflow: "auto",
              }}
            />
          )}
        </div>
      </div>
      {/*<Dialog open={true} onClose={handleClose} fullWidth maxWidth="xs" aria-labelledby="scroll-rfi-dialog-title" aria-describedby="scroll-dialog-description"
        scroll="paper" >
        <DialogTitle id="rfi-issue-dialog-title">
          {sfRFIObj?.Id != null ? "Edit" : "New"} RFI
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ position: "relative" , padding : '0px' ,paddingLeft : '0.5rem'}}>
          {showSpinner && <Spinner />}
          <DialogContentText>
            {sfRFIObj && <RFIDetail sfObj={sfRFIObj} credential={credential} saveCallback={onClickSave} closeCallBack={handleClose} customStyle={{ paddingBottom : '0.5rem' , height : '70vh' , overflow: 'auto' }}/> }
          </DialogContentText>
        </DialogContent>
      </Dialog>*/}
    </>
  );
}
export function RFIDetail({
  sfObj,
  credential,
  saveCallback,
  closeCallBack,
  customStyle,
}) {
  const [fieldContainerStyle, setFieldContainerStyle] =
    React.useState(customStyle);
  const [sfRFIObj, setSfRFIObj] = React.useState(null);
  const [assignTo, setAssignTo] = React.useState(null);
  const [owner, setOwner] = React.useState(null);
  const [rootCause, setImpactToProgress] = React.useState(null);
  const [type, setType] = React.useState(null);
  const [status, setStatus] = React.useState(null);
  const [impactToCost, setImpactToCost] = React.useState(null);
  //var sfRFIObj ;
  const [rfiReturnByDate, setRFIReturnByDate] = React.useState(new Date());
  const [requiredMissing, setRequiredMissing] = React.useState(true);
  React.useEffect(() => {
    let dsfRFI = sfObj;
    setRFIReturnByDate(new Date(dsfRFI.Due_Date__c));
    setVariables(dsfRFI);
  }, []);
  function handleClose() {
    //console.log('Close RFIDialog :: '+JSON.stringify(sfRFIObj));
    closeCallBack();
  }
  function setVariables(_sfObj) {
    /*setAssignTo({label : _sfObj?.Ball_in_Court__c ? _sfObj?.Ball_in_Court__r?.Name : '' , value : _sfObj?.Ball_in_Court__c});
    setOwner({label : (_sfObj?.OwnerId ? _sfObj?.Owner.Name : '') , value : _sfObj?.OwnerId});*/
    setImpactToProgress({
      label: _sfObj?.Impact_to_Progress__c,
      value: _sfObj?.Impact_to_Progress__c,
    });
    setType({ label: _sfObj?.Type__c, value: _sfObj?.Type__c });
    //setStatus({label : _sfObj?.Status__c , value : _sfObj?.Status__c});
    setImpactToCost({
      label: _sfObj?.Impact_to_Cost__c,
      value: _sfObj?.Impact_to_Cost__c,
    });
    if (_sfObj.Return_By_Date__c) {
      setRFIReturnByDate(new Date(_sfObj.Return_By_Date__c));
    } else {
      setRFIReturnByDate(null);
    }
    setRequiredMissing(!checkReadyToSave(_sfObj));
    setSfRFIObj(_sfObj);
  }
  function checkReadyToSave(_sfObj) {
    //console.log('checkReadyToSave');
    let reqfields = [
      "Type__c",
      "Subject__c",
      "Linked_Document__c",
      annoIdField,
      "Project__c",
      "Clarification_Requested_RT__c",
    ];
    if (_sfObj) {
      for (let f of reqfields) {
        if (!_sfObj[f] || _sfObj[f] == "" || _sfObj[f] == null) {
          console.log("checkReadyToSave :: " + f);
          return false;
        }
      }
    }
    return true;
  }
  async function onClickSave() {
    saveCallback(sfRFIObj);
  }
  // Render the UI for RFI Popup
  return (
    <>
      <div className="detail-paper" style={fieldContainerStyle}>
        <Grid
          container
          rowSpacing={2}
          style={{ width: "100%", paddingTop: "15px" }}
          className="sf-detail-container"
        >
          <Grid item xs={12}>
            <div>
              <TextField
                id="subject"
                variant="outlined"
                label="Subject"
                required
                value={sfRFIObj?.Subject__c ? sfRFIObj?.Subject__c : undefined}
                onChange={(e) => {
                  let fff = sfRFIObj;
                  fff.Subject__c = e?.target?.value;
                  setVariables(fff);
                }}
                onKeyDown={(event) => {
                  if (event.key.toLowerCase() != "tab") {
                    event.stopPropagation();
                  }
                }}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div>
              <Autocomplete
                disablePortal
                value={type}
                getOptionLabel={(option) =>
                  option?.label ? option?.label : ""
                }
                onChange={(event, newValue) => {
                  let fff = sfRFIObj;
                  fff.Type__c = newValue?.value;
                  setVariables(fff);
                }}
                options={credential?.rfiObjOptions?.Type__c?.options}
                renderInput={(params) => (
                  <div>
                    <TextField
                      id="type"
                      variant="outlined"
                      label="Type"
                      required
                      {...params}
                    />
                  </div>
                )}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  disablePast={false}
                  placeholder="Return By Date"
                  inputFormat="MM/dd/yyyy"
                  value={rfiReturnByDate}
                  onChange={(newValue) => {
                    let fff = sfRFIObj;
                    if (newValue) {
                      fff.Return_By_Date__c =
                        moment(newValue).format("YYYY-MM-DD");
                    } else {
                      fff.Return_By_Date__c = null;
                    }
                    setVariables(fff);
                  }}
                  renderInput={(params) => (
                    <TextField
                      id="returnByDate"
                      variant="outlined"
                      label="Return By Date"
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>
            </div>
          </Grid>
          {/*<Grid item xs={12}>
            <div>
              <Autocomplete
                disablePortal
                value={assignTo}
                getOptionLabel={(option) => option?.label ? option?.label : ''}
                onChange={(event, newValue) => {
                  let fff = sfRFIObj;
                  fff.Ball_in_Court__c = newValue?.value;
                  fff.Ball_in_Court__r = { Id: newValue?.value, Name: newValue?.label };
                  setVariables(fff);
                }}
                options={[...[{ label: 'None..', value: null }], ...credential?.userList]}
                renderInput={(params) => (
                  <TextField id="toAdd" variant="outlined" label="To Address" {...params} />
                )}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div>
              <Autocomplete
                disablePortal
                value={owner}
                getOptionLabel={(option) => option?.label ? option?.label : ''}
                onChange={(event, newValue) => {
                  let fff = sfRFIObj;
                  fff.OwnerId = newValue?.value;
                  fff.Owner = { Id: newValue?.value, Name: newValue?.label };
                  setVariables(fff);
                }}
                options={credential?.userList}
                renderInput={(params) => (
                  <TextField id="ccAdd" variant="outlined" label="CC Address" disabled {...params} />
                )}
                disabled
              />
            </div>
          </Grid>*/}
          <Grid item xs={12}>
            <div>
              {/*<MUIRichTextEditor id="clarificationRequested" label="Clarification Requested"
                  defaultValue={sfRFIObj?.Clarification_Requested_RT__c} />*/}
              <TextField
                id="clarificationRequested"
                variant="outlined"
                label="Clarification Requested"
                value={
                  sfRFIObj?.Clarification_Requested_RT__c
                    ? sfRFIObj?.Clarification_Requested_RT__c
                    : undefined
                }
                multiline
                rows={2}
                maxRows={3}
                required
                onChange={(e) => {
                  let fff = sfRFIObj;
                  fff.Clarification_Requested_RT__c = e?.target?.value;
                  setVariables(fff);
                }}
                onKeyDown={(event) => {
                  if (event.key.toLowerCase() != "tab") {
                    event.stopPropagation();
                  }
                }}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div>
              {/*<RichtextEditor value='<p>1.Hey this editor rocks</p><p>2. Hey this editor rocks</p>' />*/}
              <TextField
                id="recommendedSolutionRT"
                variant="outlined"
                label="Recommended Solution"
                value={
                  sfRFIObj?.Recommended_SolutionRT__c
                    ? sfRFIObj?.Recommended_SolutionRT__c
                    : undefined
                }
                multiline
                rows={2}
                maxRows={3}
                onChange={(e) => {
                  let fff = sfRFIObj;
                  fff.Recommended_SolutionRT__c = e?.target?.value;
                  setVariables(fff);
                }}
                onKeyDown={(event) => {
                  if (event.key.toLowerCase() != "tab") {
                    event.stopPropagation();
                  }
                }}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div>
              <Autocomplete
                disablePortal
                value={rootCause}
                getOptionLabel={(option) =>
                  option?.label ? option?.label : ""
                }
                onChange={(event, newValue) => {
                  let fff = sfRFIObj;
                  fff.Impact_to_Progress__c = newValue?.value;
                  setVariables(fff);
                }}
                options={
                  credential?.rfiObjOptions?.Impact_to_Progress__c?.options
                }
                renderInput={(params) => (
                  <div>
                    <TextField
                      id="impactToProgres"
                      variant="outlined"
                      label="Impact to Progress"
                      {...params}
                    />
                  </div>
                )}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div>
              <Autocomplete
                disablePortal
                value={impactToCost}
                getOptionLabel={(option) =>
                  option?.label ? option?.label : ""
                }
                onChange={(event, newValue) => {
                  let fff = sfRFIObj;
                  fff.Impact_to_Cost__c = newValue?.value;
                  setVariables(fff);
                }}
                options={credential?.rfiObjOptions?.Impact_to_Cost__c?.options}
                renderInput={(params) => (
                  <div>
                    <TextField
                      id="impactToCost"
                      variant="outlined"
                      label="Impact to Cost"
                      {...params}
                    />
                  </div>
                )}
              />
            </div>
          </Grid>
        </Grid>
      </div>
      <DialogActions>
        <Button onClick={() => handleClose()} variant="text">
          Cancel
        </Button>
        <Button
          onClick={() => onClickSave()}
          variant="contained"
          disabled={requiredMissing}
          style={{ marginLeft: "5px" }}
        >
          Save
        </Button>
      </DialogActions>
    </>
  );
}
function RichtextEditor({ value }) {
  const contentBlock = htmlToDraft(value);
  const contentState = ContentState.createFromBlockArray(
    contentBlock.contentBlocks
  );
  //const editorState = EditorState.createWithContent(contentState);
  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(contentState)
  );
  //const preEditorContent = ContentState.createFromBlockArray(convertFromHTML(value));
  //const [editorState2, setEditorState2] = useState(()=> EditorState.createEmpty()) ;
  function onEditorStateChange_(editorState_) {
    console.log(
      "editorState_.getCurrentContent() :: " + editorState_.getCurrentContent()
    );
    setEditorState(editorState_);
  }

  return (
    <div>
      <Editor
        editorState={editorState}
        //defaultEditorState={preEditorContent}
        wrapperClassName="demo-wrapper"
        editorClassName="demo-editor"
        onEditorStateChange={onEditorStateChange_}
      />
      <div>
        <textarea
          disabled
          value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
        />
      </div>
    </div>
  );
}
