import React, { useEffect, useState ,useRef } from "react";
import { DialogContent, IconButton, Box, Tabs, Tab, Button, DialogContentText, ButtonGroup } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Close, Delete ,MoreHoriz } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import axios from "axios";
import { fetchUrl } from "Urls";
import { saveMakrupToLinkedDocument } from "components/IssuesTabs/IssuesPopups/CreateIssuePopup";
import SelectADocumentPopUp from "components/IssuesTabs/IssuesPopups/SelectADocumentpopUp";
import IssuesPdfViewDialog from "components/IssuesTabs/IssuesPopups/pdfView";
import {prepareSobjectToIssueTableRowElement} from "hooks/IssueTab.hook";
const Input = styled("input")({
   display: "none",
});

const IssueAttachmentSection = (props) =>{
   const fileUploadInput = useRef(null);
   const { credential, selectedRowData, attachments, setAttachments,_onAddDocClick, updateStateTableDataWithRow, getFilesApi , ...others } = props;
   const [enableDocumentSelect, setDocumentSelect] = useState(false);
   const [showSpinnerM, setSpinnerM] = useState(false);
   const [anchorEl, setAnchorEl] = useState(null);
   const [selectedAttachId, setSelectedAttachId] = useState(null);
   const [attachTableFiles , setAttachTableData] = useState(null);
   const [preAnnotaionId , setPreAnnotaionId] = useState(null);


   const [pdfopenfiledata, setFileData] = useState();
   const [linkedPDFIssueInstantJson, setCurrentPDFIssueInstantJSON] = useState(null); 
   const [sfIssueObj, setSfIssueObj] = React.useState(selectedRowData.record);
   const [showSpinner, setSpinner] = React.useState(false);
   const [docDetail, setDocDetail] = React.useState(null);
   const [showPdf, setShowPdf] = React.useState(false);
   let listForS3Fields = ['Id','File_Name__c','File_URL__c','Markup_Json__c','S3_Version_ID__c','fileurl','url'];
   useEffect(() => {
      let dsfIssue = selectedRowData.record;
      setVariables(dsfIssue);
   }, []);

   useEffect(() => {
      prepareTableData();
   }, [selectedRowData.record.Linked_Document__c , attachments]);

   function setVariables(_sfObj , push) {
      
      console.log('setVariables called');
     setSfIssueObj(_sfObj);
     setPreAnnotaionId(_sfObj.Annotation_Id__c);
     let instantJSONTemp = null ;
     if(_sfObj.Annotation_Id__c && _sfObj.Linked_Document__c && _sfObj.Linked_Document__r.Markup_Json__c){
      let parseJson = JSON.parse(_sfObj.Linked_Document__r.Markup_Json__c);
      if(parseJson.annotations){
         const fInd = parseJson.annotations?.findIndex(
           (data) => data.id == _sfObj.Annotation_Id__c
         );
         if (fInd > -1) {
            instantJSONTemp = JSON.stringify(parseJson.annotations[fInd]);
         }
      }
     }
     setCurrentPDFIssueInstantJSON(instantJSONTemp);
     if(push){
         let _selectedRowData = JSON.parse(JSON.stringify(selectedRowData));
         _selectedRowData = prepareSobjectToIssueTableRowElement(_sfObj) ;
         updateStateTableDataWithRow(_selectedRowData);
     }
   }

   const prepareTableData= async function () {
      console.log('prepareTableData called');
      let tableData = [];
      if(selectedRowData.linkDocumentObj){
         let doc = JSON.parse(JSON.stringify(selectedRowData.linkDocumentObj));
         //console.log('selectedRowData.linkDocumentObj',selectedRowData.linkDocumentObj);
         tableData.push({customData : 'linked' , ...doc , id : doc.Id});
      }
      if(attachments && attachments.length > 0 ){
         for(let att of attachments){
            let doc = JSON.parse(JSON.stringify(att));
            tableData.push({customData : 'file' , ...doc , id : doc.Id});
         }
      }
      setAttachTableData(tableData);
   }
   const uploadMedia = async (options) => {
     //console.log("sdsdsds", options);
     if(options && options.length > 0){
       const videoUploaderOptions = {
         fileName: options[0].name,
         file: options[0],
       };
       setSpinnerM(true);
       const formData = new FormData();
       formData.append( "attachFile", videoUploaderOptions?.file, videoUploaderOptions?.file?.name );
       formData.append( "mimeType", videoUploaderOptions?.file.type );
       formData.append( "s3Key", `${selectedRowData.record.Project__c}/Issue/${selectedRowData.id}` );
       await axios.post(`${fetchUrl}/upload/attachment/Issue__c/${selectedRowData?.id}?token=${credential?.token}&instanceUrl=${credential?.instanceUrl}`,formData)
       .then(async (res) => {
         if(res.status == 200){
           let clonedList = JSON.parse(JSON.stringify(attachments)) ; 
           if(!clonedList){
             clonedList = [] ;
           }
           let cloned = JSON.parse(JSON.stringify(res.data.ret)) ;
           cloned.id = res.data.ret.Id ;
           cloned.customData = 'file' ;
           clonedList.push(cloned);
           setAttachments(clonedList);
         }
       }).catch((err) => {
         console.log("/upload/attachment err", err);
       });
       setSpinnerM(false);
     }
   };
   const handleClickMenu = (event ,attId) => {
     event.preventDefault();
     event.stopPropagation();
     setSelectedAttachId(attId);
     setAnchorEl(event.currentTarget);
   };
   const handleCloseMenu = () => {
    // console.log("handleCloseMenu handleCloseMenu");
     setSelectedAttachId(null);
     setAnchorEl(null);
     //setSelectedMenuRow(null);
   };
   const columns = [
     { field: "id", headerName: "Id", flex: 1, hideable: true },
     { field: "File_Name__c", headerName: "File Name", flex: 1,
     renderCell: (params) => {
       return (
           <Box className="hide-menu-dot name_main" style={{ cursor: "pointer", display: "flex" }} >
             <Box>{params.value}</Box>
             <IconButton
               onClick={(e) => handleClickMenu(e, params.row.id)}
               className="menu_open_button"
               size="small"
               sx={{ ml: 2 }}
               aria-controls={selectedAttachId ? "account-menu" : undefined}
               aria-haspopup="true"
               aria-expanded={selectedAttachId ? "true" : undefined}
             >
             <MoreHoriz />
             </IconButton>
           </Box>
       );
     }},
   ];
   const onRowClickFunc = async (parma) => {
      //console.log('onRowClickFunc', parma);
      if (parma) {
         if (parma?.row?.customData == "file") {
            downloadFile(parma?.row?.fileurl,parma?.row?.File_Name__c , parma?.row?.File_Extension__c);
         } else {
            setFileData(parma?.row);
            setShowPdf(true);
         }
      }
   };
   const downloadFile = async (url, fileName, fileExt, includeMarkup, instantJSONMarkup) => {
      if (!fileExt || fileExt === null) {
         fileExt = "pdf";
      }
      if(fileExt.includes('.')){
         let pos = fileExt.lastIndexOf(".");
         fileExt = fileExt.substring(pos + 1);
      }
      if (!fileName.toLowerCase().endsWith("." + fileExt.toLowerCase())) {
         fileName += "." + fileExt.toLowerCase();
      }
      //console.log('downloadFile url',url);
      if ( false && fileName.toLowerCase().endsWith(".pdf")) {
         let confi = {
            baseUrl: `${window.location.protocol}//${window.location.host}/${process.env.PUBLIC_URL}`,
            document: url,
            headless: true,
         };
         if (includeMarkup && instantJSONMarkup) {
            let listAA = JSON.parse(instantJSONMarkup).annotations;
            for (let ann of listAA) {
               if (ann.customData) {
                  ann.customData.source = "export";
               }
            }
            let annotationList = listAA;
            confi.instantJSON = {
               format: "https://pspdfkit.com/instant-json/v1",
               annotations: annotationList,
            };
         }
         let PSPDFKit = await import("pspdfkit");
         let instance = await PSPDFKit.load(confi);
         await instance.exportPDF().then((buffer) => {
            const blob = new Blob([buffer], { type: "application/pdf" });
            const fName = fileName;
            if (window.navigator.msSaveOrOpenBlob) {
               window.navigator.msSaveOrOpenBlob(blob, fName);
            } else {
               const objectUrl = URL.createObjectURL(blob);
               const a = document.createElement("a");
               a.href = objectUrl;
               a.style = "display: none";
               a.download = fName;
               document.body.appendChild(a);
               a.click();
               URL.revokeObjectURL(objectUrl);
               document.body.removeChild(a);
            }
         });
      } else {
         downloadMedia(url, fileName);
      }
   };
   const downloadMedia = (url, fileName) => {
     const a = document.createElement("a");
     a.href = url;
     a.style.display = "none";
     a.download = `${fileName}`;
     a.setAttribute("download", fileName);
     document.body.appendChild(a);
     a.click();
     document.body.removeChild(a);
   };
   const onAddDocClick = async function () {
      setDocumentSelect(true);
      _onAddDocClick();
   }
   function handleFileChange(selectedFile) {
      //console.log('handleFileChange selectedFile ',JSON.stringify(selectedFile.attObj));
      let olFile = pdfopenfiledata;
      let newlinkedPDFIssueInstantJson = linkedPDFIssueInstantJson;
      let annoation_id = JSON.parse(newlinkedPDFIssueInstantJson)?.id;
      let attObj = selectedFile.attObj;
      if (!attObj.id) {
         attObj.id = attObj.Id;
      }
      if (!attObj.fileurl && attObj.url) {
         attObj.fileurl = attObj.url;
      }
      if (olFile?.id != attObj?.id) {
         setCurrentPDFIssueInstantJSON(null);
         annoation_id = null;
      }
      changeDocDetailOnIssueDetailPop(attObj?.id, annoation_id, attObj.File_Name__c);
      setFileData(attObj);
      setShowPdf(true);
   }
   function changeDocDetailOnIssueDetailPop(attachId, annoId, attachLabel) {
      setDocDetail({ attachId: attachId, annoId: annoId, attachLabel: attachLabel });
   }
   const handleIssuePinDropSelctionDone = async (detail) => {
      let anno = JSON.parse(detail);
      setCurrentPDFIssueInstantJSON(detail);
      let annoation_id;
      if (anno?.id != null) {
         annoation_id = anno.id
      } else {
         annoation_id = null;
         setCurrentPDFIssueInstantJSON(null);
      }
      //console.log('anno :: '+JSON.stringify(anno));
      setDocumentSelect(false);
      let _sfObj = JSON.parse(JSON.stringify(selectedRowData.record));
      if (_sfObj.Linked_Document__c != pdfopenfiledata?.id) {
         if (_sfObj.Annotation_Id__c && _sfObj.Linked_Document__c && _sfObj.Linked_Document__r.Markup_Json__c) {
            let mjson = null;
            let parseJson = JSON.parse(_sfObj.Linked_Document__r.Markup_Json__c);
            if (parseJson.annotations) {
               const fInd = parseJson.annotations?.findIndex(
                  (data) => data.id == _sfObj.Annotation_Id__c
               );
               if (fInd > -1) {
                  parseJson.annotations.splice(fInd, 1);
               }
               if (parseJson.annotations?.length > 0) {
                  mjson = parseJson;
               }
            }
            if (annoation_id != null) {
               console.log('tchUrl}/mark 00');
               await axios.put(`${fetchUrl}/markup/${_sfObj.Linked_Document__c}`, mjson);
            }
         }
      }
      await saveMakrupToLinkedDocument(pdfopenfiledata, detail );
      setShowPdf(false);
   };
   const saveMakrupToLinkedDocument = async function (pdfopenfiledata, toLinkMarkupInstantJson) {
      let returnRow;
      let preRow = JSON.parse(JSON.stringify(pdfopenfiledata));
      let annoation_id = null;
      if(toLinkMarkupInstantJson){
         let toLinkMarkupInstantJson_ = toLinkMarkupInstantJson;
         //console.log("toLinkMarkupInstantJson_ >>>>>>>>>>>>", toLinkMarkupInstantJson_);
         annoation_id = JSON.parse(toLinkMarkupInstantJson_)?.id;
         //console.log("annoation_id >>>>>>>>>>>>", annoation_id);
         let annotationJsonI = {
            format: "https://pspdfkit.com/instant-json/v1",
            annotations: [JSON.parse(toLinkMarkupInstantJson_)]
         };
         if (preRow.Markup_Json__c) {
            annotationJsonI = JSON.parse(preRow.Markup_Json__c);
            //console.log("pdfMarkup >>>>>>>>>>>>", pdfopenfiledata.markupJSON);
            let annotationList = annotationJsonI?.annotations;
            let foundInd;
            if (annotationList) {
               foundInd = annotationList.findIndex(
                  (data) => data.id == annoation_id
               );
            }
            if (foundInd > -1) {
               annotationJsonI.annotations[foundInd] = JSON.parse(toLinkMarkupInstantJson_);
            } else {
               if (!annotationJsonI.annotations) {
                  annotationJsonI.annotations = [];
               }
               annotationJsonI.annotations.push(JSON.parse(toLinkMarkupInstantJson_));
            }
         }
         preRow.Markup_Json__c = JSON.stringify(annotationJsonI);
         //console.log("instantJSON sendData"+ JSON.stringify(annotationJsonI));
   
         if (annoation_id != null) {
            console.log('pdfopenfiledata innn');
            await axios.put(`${fetchUrl}/markup/${preRow.id}`, annotationJsonI);
            getFilesApi();
         }
      }
      const formData = new FormData();
      //let issueObj = sfIssueObj;
      formData.append("recObj", JSON.stringify({Id : selectedRowData.record.Id , Linked_Document__c : preRow.id , Annotation_Id__c : annoation_id }));
      await axios.post(`${fetchUrl}/recSave/Issue__c?token=${credential?.token}&instanceUrl=${credential?.instanceUrl}`,formData)
      .then((res) => {
        //console.log("count:::: " + JSON.stringify(res));
         if(res.status === 200){
            let _temp = JSON.parse(JSON.stringify(sfIssueObj));
            _temp.Linked_Document__c = preRow.id ;
            _temp.Linked_Document__r = preRow ;
            setVariables(_temp ,true);
         }
      }).catch((err) => {
        console.log(err);
      });

   }
   return (
      <>
         <Button variant="text" component="span" onClick={(e) => setDocumentSelect(true)}>
            Upload
         </Button>
         <label htmlFor="fileupload" style={{visibility:'hidden'}}>
            <Input accept="application/pdf" id="fileupload" type="file" onChange={(e) => uploadMedia(e.target.files)}
               onClick={(e) => (e.target.value = "")} ref={fileUploadInput} />
            <Button variant="contained" component="span">
               Upload
            </Button>
         </label>
         {attachTableFiles && attachTableFiles?.length > 0 ? (
            <>
               <div style={{ height: 400, width: "100%" }}>
                  <DataGrid
                     rows={attachTableFiles}
                     columns={columns}
                     loading={showSpinnerM}
                     disableColumnFilter={true}
                     disableColumnSelector={true}
                     disableRowSelectionOnClick={true}
                     onRowClick={(parma) => onRowClickFunc(parma)}
                     hideFooter={true}
                     autoPageSize={true}
                     showCellVerticalBorder={true}
                     columnVisibilityModel={{
                        id: false,
                     }}
                     rowHeight={100}
                  />
               </div>
            </>
            ):(
            <>
               No Data.
            </>
         )}
         { enableDocumentSelect && <SelectADocumentPopUp
             pinType="issue"
             annoEleInstantJson={linkedPDFIssueInstantJson}
             treeData={{ ...others }}
             credential={credential}
             enableDocumentSelect={enableDocumentSelect}
             setDocumentSelect={setDocumentSelect}
             setFileData={handleFileChange}
             pdfopenfiledata={pdfopenfiledata}
             calledFrom='IssueAttachmentSection'
             openFromComputer={()=>{
               fileUploadInput.current.click();
               setDocumentSelect(false);
             }}
           />
         }
         {showPdf && pdfopenfiledata && (
           <IssuesPdfViewDialog
             pdfopenfiledata={pdfopenfiledata}
             pinType="issue"
             annoEleInstantJson={linkedPDFIssueInstantJson}
             credential={credential}
             open={showPdf}
             handleCloseFile={()=> setShowPdf(false)}
             handleDonePinDrop={handleIssuePinDropSelctionDone}
           />
         )}
      </>
   );
}

export default IssueAttachmentSection;