import React from "react";
import { fetchUrl,inAppLoginUrl } from "Urls";

const Login = () => {
  React.useEffect(() => {
    window.location = `${inAppLoginUrl}`;
  }, []);
  return (
    <>
      Login To Salesforce
    </>
  );
};
export default Login;
