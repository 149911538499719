import React, { useState } from "react";
import Draggable from "react-draggable";
import {
  Box, Button, Checkbox, IconButton, InputLabel, MenuItem,
  Select, Dialog, FormControl, List, ListItem, ListItemText, Drawer, Tooltip
} from "@mui/material";

const ZoomButtons = ({ zoomLevel, onChangeZoom, secondViewer}) => {
  const [currenLevel, setCurrentLevel] = useState(0);
  React.useEffect(() => {
    let zl = (zoomLevel > 0 ? zoomLevel * 100 : 100);
    let cval = JSON.parse(JSON.stringify(zl.toFixed(0)));
    //console.log('zoomLevel :: ',zoomLevel , 'cval ::',cval);
    setCurrentLevel(cval);
  }, [zoomLevel]);
  const options = [
    { value: 10, label: '10%' },
    { value: 25, label: '25%' },
    { value: 50, label: '50%' },
    { value: 100, label: '100%' },
    { value: 125, label: '125%' },
    { value: 150, label: '150%' },
    { value: 200, label: '200%' },
    { value: 400, label: '400%' },
    { value: 800, label: '800%' },
    { value: 1600, label: '1600%' },
    { value: 6400, label: '6400%' }
  ];
  return (
    <Draggable>
      <div className={secondViewer ? "zoom-container-1" : "zoom-container"}>
        <div id="zoom-out-buttons" className="zoom-button">
          <Select
            variant="standard"
            value={currenLevel}
            disableUnderline={true}
            renderValue={() => { return `${currenLevel}%` }}
            onChange={(e)=> {
              onChangeZoom(e.target.value)
            }}
          >
            <MenuItem value={currenLevel} >
              <div>{`${currenLevel}%`}</div>
            </MenuItem>
            {options &&
              options.map((item) => (
                <MenuItem key={item.value+'_key'} value={item.value} >
                  <div>{item?.label}</div>
                </MenuItem>
              ))}
          </Select>
        </div>
        <div id={secondViewer ?  "zoom-out-button-1" : "zoom-out-button"}className="zoom-button">
          <img src="/remove.svg" style={{ maxWidth: "none" }} alt="zoomOut" />
        </div>
        <div id={secondViewer ? "zoom-in-button-1" : "zoom-in-button"} className="zoom-button">
          <img src="/add.svg" style={{ maxWidth: "none" }} alt="ZoomIn" />
        </div>
      </div>
    </Draggable>
  );
};
export default ZoomButtons;