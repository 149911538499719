import React from "react";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import {
  useTable,
  useBlockLayout,
  useResizeColumns,
  useSortBy,
  usePagination,
} from "react-table";
import { useSticky } from "react-table-sticky";
import { Styles } from "./IssueTable.style";
import { NoData } from "components/upload-logs/LogsComponents/CommonMethod";
import styled from "styled-components";

const getStyles = (props, align = "left") => [
  props,
  {
    style: {
      // justifyContent: align === "right" ? "flex-end" : "flex-start",
      justifyContent: "space-between",
      alignItems: "center",
      display: "flex",
    },
  },
];
const headerProps = (props, { column }) => getStyles(props, column.align);

const cellProps = (props, { cell }) => getStyles(props, cell.column.align);

function TableIssue({
  columns,
  data,
  handleOpen
}) {
  const {
    getTableProps,
    headerGroups,
    rows,
    prepareRow,
    getToggleHideAllColumnsProps,
    allColumns,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    { columns, data, initialState: { pageIndex: 2, hiddenColumns: "id" } },
    useBlockLayout,
    useResizeColumns,
    useSortBy,
    usePagination,
    useSticky
  );
  const handleOpenDetail = (row) => {
    handleOpen(row.original);
  };

  const Styles = styled.div`
  ${
    "" /* These styles are suggested for the table fill all available space in its containing element */
  }
  display: block;
  ${
    "" /* These styles are required for a horizontaly scrollable table overflow */
  }
  padding: 0;
  overflow-x: auto;
  overflow-y: auto;
  /* height: 500px; */
 // max-height: calc(100vh - 230px);

  .table {
    border-spacing: 0;
    background: #fff;
    width: 100%;
    background-color: #fff;
    position: relative;
    box-sizing: border-box;
    z-index: 0;
    div {
      color: #222;
      font-size: 13px;
    }
    .thead {
      overflow-y: auto;
      overflow-x: hidden;
      /* min-width: 1570px; */
      min-width: 100%;
      position: sticky;
      top: 0;
      z-index: 999;
      background: #f7f9fa;
    }

    .th,
    .td {
      &:nth-child(1),
      &:nth-child(2) {
        //   background: #f7f9fa;
        //   position: sticky !important;
        // z-index: 99;
        // left: 0;
      }
      &:nth-child(2) {
        // min-width: 200px;
        //  box-shadow: 3px 0px 3px 0 rgb(129 144 153 / 19%);
        //  box-sizing: border-box;
        //  left: 60px;
      }
    }
    .tbody {
      background: #fff;
      height: 75vh;
      overflow-x: auto;
      overflow-y: auto;
    }

    .tr {
      width: 100% !important;
      &:hover {
        background: rgba(237, 240, 242, 0.75);
      }
      :last-child {
        .td {
          border-bottom: 0;
        }
      }
      border-bottom: 1px solid #ddd;
    }
    .th {
      font-weight: 300;

      &:hover {
        .resizer {
          opacity: 1;
        }
      }
    }

    .th,
    .td {
      margin: 0;
      padding: 0.5rem;
      font-size: 13px;
      button {
        padding: 8px 20px;
        border-radius: 3px;
        text-transform: capitalize;
        letter-spacing: 1px;
        font-weight: 500;
      }
      .cancel_Class {
        background: #ededed;
      }
      .view_Class {
        background: var(--btn-blue);
        color: #fff;
        + div {
          right: 20px;
        }
        [role="cell"] .MuiBox-root {
          gap: 10px;
        }
      }
      .closeIconclass {
        color: #f00;
      }
      /* border-right: 1px solid #ddd; */
      overflow: hidden;
      .name_main {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      ${
        "" /* In this example we use an absolutely position resizer,
=======
      border-right: 1px solid black;

      ${"" /* In this example we use an absolutely position resizer,
>>>>>>> f71325a628771521b2a0110e52cf23d2dc6ef79b
     so this is required. */
      }
      position: relative;

      :last-child {
        border-right: 0;
      }
      &:first-child {
        width: 115px !important;
        text-align: center;
        box-shadow: 3px 0px 6px 0 rgb(129 144 153 / 19%);
        div[title="Toggle SortBy"] {
          text-transform: uppercase;
        }
        span.file_name {
          display: block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      &:nth-child(3) {
        width: 200px !important;
      }
    }

    .resizer {
      opacity: 0;
      right: 0;
      background: #717171;
      width: 3px;
      height: 100%;
      position: absolute;
      top: 0;
      z-index: 1;
      ${"" /* prevents from scrolling while dragging on touch devices */}
      touch-action :none;

      &.isResizing {
        background: red;
      }
    }
  }
  button.MuiButton-root.MuiButton-text.MuiButton-textPrimary {
    margin: 0;
    padding: 8px 10px;
  }
  div[title="Toggle SortBy"] {
    font-weight: 600;
  }
  .group-btn {
    background: none;
  }
  .icon_left {
    width: 6px;
    height: 40px;
    border-radius: 2px;
    background-color: #ffa600 !important;
    border: 1px solid #ffa600 !important;
    display: inline-block;
    margin-top: 5px;
  }
  .table_id {
    display: flex;
    .id_content {
      width: 30px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      transform: translateX(18px);
    }

    .id_content {
      margin-left: 9px;
      align-items: center;
    }
  }
  &.sticky {
    background: red;
    overflow: scroll;
    .header,
    .footer {
      position: sticky;
      z-index: 1;
      width: fit-content;
    }

    .header {
      top: 0;
      box-shadow: 0px 3px 3px #ccc;
    }

    .footer {
      bottom: 0;
      box-shadow: 0px -3px 3px #ccc;
    }

    .body {
      position: relative;
      z-index: 0;
    }

    [data-sticky-td] {
      position: sticky;
    }

    [data-sticky-last-left-td] {
      box-shadow: 2px 0px 3px #ccc;
    }

    [data-sticky-first-right-td] {
      box-shadow: -2px 0px 3px #ccc;
    }
  }
`;


  // Render the UI for your table
  return (
    <Styles>
      <div {...getTableProps()} className="table">
        <div className="thead">
          {headerGroups.map((headerGroup) => (
            <div {...headerGroup.getHeaderGroupProps({})} className="tr">
              {headerGroup.headers.map((column) => (
                <div {...column.getHeaderProps(headerProps)} className="th">
                  <div
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render("Header")}
                    {/* Add a sort direction indicator */}
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <KeyboardArrowDown />
                        ) : (
                          <KeyboardArrowUp />
                        )
                      ) : (
                        ""
                      )}
                    </span>
                  </div>
                  {column.canResize && (
                    <div
                      {...column.getResizerProps()}
                      className={`resizer ${
                        column.isResizing ? "isResizing" : ""
                      }`}
                    />
                  )}
                </div>
              ))}
            </div>
          ))}
        </div>
        <div className="tbody">
          {rows?.length === 0 && (
            <div className="no_data_found" style={{ textAlign: "center", marginTop: "200px" }} >
              <NoData/>
            </div>
          )}
          {rows.map((row) => {
            prepareRow(row);
            return (
              <>
                <div
                  onClick={() => handleOpenDetail(row)}
                  {...row.getRowProps()}
                  className="tr"
                >
                  {row.cells.map((cell, index) => {
                    return (
                      <div {...cell.getCellProps(cellProps)} className="td">
                        {cell.render("Cell")}
                      </div>
                    );
                  })}
                </div>
              </>
            );
          })}
        </div>
      </div>
      {/*
          <div className="pagination">
            <span>
              Showing{" "}
              <strong>
                {pageIndex + 1} - {pageOptions.length} of {pageOptions.length}
              </strong>{" "}
            </span>
            <div className="right_section_pagination">
              <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                {"<<"}
              </button>{" "}
              <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                {"<"}
              </button>{" "}
              <span>
                <strong>
                  {pageIndex + 1} of {pageOptions.length}
                </strong>{" "}
              </span>
              <button onClick={() => nextPage()} disabled={!canNextPage}>
                {">"}
              </button>{" "}
              <button
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                {">>"}
              </button>{" "}
            </div>
          </div>
        */}
    </Styles>
  );
}

export default TableIssue;
