
import Styled from "styled-components";
const Design = Styled.div`
h1 {
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
}
[role="tablist"] {
    gap: 24px;
      button {
      padding: 10px 0;
      font-family: var(--common-font); 
      font-size: 14px;
      color: var(--drgray);
      text-transform: capitalize;
  }
   
.Mui-selected{
  color: var(--drblue);font-weight: 500;
} 
}

.left_side{
   h2 {
    color: var(--drgray);
    font-size: 14px;
    line-height: 21px;
}
}
.tab_wrapper .MuiTabs-scroller{
span.MuiTabs-indicator {
        background: var(--drblue);
        height: 1px;
    }
} 
.publish-logs {
  align-items: flex-start; 
  display: inline-flex; 
  flex-direction:
  column; 
  gap: 8px;
  position: relative;
  }
  .text-and-kpi {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto; 
  gap: 6px;
  justify-content: center; 
  position: relative;
  }
  .label {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px; 
  position: relative; 
  width: fit-content;
  }
  .kpi {
  align-items: center;
  background-color: #F92828; 
  border-radius: 16px;
  display: inline-flex;
  flex: 0 auto;
  flex-direction: column;
  gap: 6px;
  justify-content: center;
  padding: 3px 6px; position: relative;
  }
  .x1 {
  letter-spacing: 0.1px;
  line-height: 10px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap; 
  width: fit-content;
  color: #ffff
  }
.table_head.main_table {
    padding: 10px 16px;
}
.buttons {
  align-items: center; border-radius: 2px; display: flex;
  height: 24px;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 24px;
  }
  .close {
    &:hover {
      background: #F8F8F8;
    }
  height: 16px;
  position: relative;
  width: 16px;
  }
  .clear {
    &:hover {
      background: #F8F8F8;
    }
  height: 16px;
  position: relative;
  width: 16px;
  margin-left: 10px
  }
.left_side {
  padding: 5px 0;
}
.box_hodler {
    max-width: 180px;
}
.right_side{
  
.FilterButton{
color: #505050;
text-transform: capitalize;
font-family: var(--common-font);
font-weight: 400;
font-size: 14px;
&:hover {
    background: #E8EFFF;
    svg{
      fill: #505050;
    }
   }
}
}
.filters {
  display: flex;
  gap:12px;
}
  button.mainButton {
    &:hover {
      background: #F8F8F8;
    }
    cursor: pointer;
    padding: 8px 12px; 
    background-color: #ffff;
    color: #505050;
    border: 1px solid #D8D8D8;
    border-radius: 2px;
    img {
    margin-left: 10px;
}}
button.mainButton-1 {
  &:hover {
    background: #F8F8F8;
  }
  cursor: pointer;
  padding: 8px 12px; 
  background-color: #E8EFFF;
  color: #505050;
  border: 1px solid #D8D8D8;
  border-radius: 2px;
  img {
  margin-left: 10px;
}}
.holder_input {
    position: relative;
    z-index: 1;
}
 
.filter-dropdown {
    top: 40px;
    left: 0px;
    width: 264px;
    border: none;
    border-radius: 2px; 
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.25);
    background-color: #fff;
    position: absolute;
    .react-datepicker {
    width: 264px;
    border: none;
     .react-datepicker__navigation {
        top: 10px !important;
        }
        .react-datepicker__month-container {
         .react-datepicker__header  {
         background-color: white;
         padding: 10px 16px 10px 16px;
         border: none !important;

         .react-datepicker__day-names {
           margin-top: 10px;
          .react-datepicker__day-name {
            color: var(--Charcoal, #505050);
            font-family: Poppins;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.12px;
          }
         }
         }
          .react-datepicker__month {
            .react-datepicker__week {
              .react-datepicker__day {
                  color: black;
                  font-family: Poppins;
                  font-size: 10px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: normal;
                  letter-spacing: 0.1px;
              }
                  .react-datepicker__day--in-range {
                   background-color: #346EF5 !important;
                   border-radius: 99px;
                   color: white;
                  }
            }
          }
        }
  }
  .filter-search {
    border: 1px solid #d3d3d3;
    padding: 7px 12px;
    border-radius: 2px;
    margin: 10px 10px 0;
      input {
      border: none;
      padding: 0;
      width: 100%;
      outline: none;
      font-size: 14px;
  } 
  }
  .filter-footer {
    border-top: 1px solid #EAEAEA; gap: 10px;  display: flex;
    justify-content: flex-end; padding: 10px;
  
  .Mui-disabled {
    background: #BABABA;
    color: white;
    border: 2px solid #BABABA;
  }
}   
  
 
.filter-options {
  height: 150px;
  overflow: auto;
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    grid-template-columns: repeat(1, 1fr);
    padding: 10px;
  label {
    height: 32px;gap:7px;padding: 0;margin: 0;
    span{
      font-size: 14px; 
      font-family: var(--commonfont);
      color: #505050;
      padding: 0
    } 
    .MuiTouchRipple-root{
      margin-left: 10px
    } 
    }
 }
 
}
.bottom_area {
    padding: 0px 16px;
}
span.MuiButtonBase-root  svg.MuiSvgIcon-root {
    color: var(--border-gray);
}
span.MuiButtonBase-root.Mui-checked svg.MuiSvgIcon-root {
    color: var(--drblue);
}
.filter-checkbox {
  &:hover {
    background: #F8F8F8;
  }
}
.grid-1 {
  &:hover {
    background: #F8F8F8;
  }
  width: 115px;
  align-items: center;
  background-color: #E8EFFF;
  border: 1px solid;
  border-color: #D8D8D8; 
  border-radius: 2px;
  display: inline-flex;
  gap: 8px;
  height: 37px;
  justify-content: center;
  overflow: hidden;
  padding: 8px 12px;
  position: relative;
  }
.grid {
  &:hover {
    background: #F8F8F8;
  }
  width: 115px;
  align-items: center;
  background-color: #FFFFFF;
  border: 1px solid;
  border-color: #D8D8D8; 
  border-radius: 2px;
  display: inline-flex;
  gap: 8px;
  height: 37px;
  justify-content: center;
  overflow: hidden;
  padding: 8px 12px;
  position: relative;
  }
  .grid-2 {
    &:hover {
      background: #F8F8F8;
    }
    width: 20px;
    align-items: center;
   // background-color: #FFFFFF;
    border-left: 1px solid;
    border-color: #D8D8D8; 
    border-radius: 2px;
   // display: inline-flex;
    height: 37px;
    justify-content: center;
   // overflow: hidden;
    padding: 8px 1px;
   // position: relative;
    }
  .grid-11 {
    &:hover {
      background: #F8F8F8;
    }
    width: 170px;
    align-items: center;
    background-color: #E8EFFF;
    border: 1px solid;
    border-color: #D8D8D8; 
    border-radius: 2px;
    display: inline-flex;
    gap: 8px;
    height: 37px;
    justify-content: center;
    overflow: hidden;
    padding: 8px 12px;
    position: relative;
    }
  .grid-111 {
    &:hover {
      background: #F8F8F8;
    }
    width: 240px;
    align-items: center;
    background-color: #FFFFFF;
    border: 1px solid;
    border-color: #D8D8D8; 
    border-radius: 2px;
    display: inline-flex;
    gap: 8px;
    height: 37px;
    justify-content: center;
    overflow: hidden;
    padding: 8px 12px;
    position: relative;
    }
  .filter2 {
  height: 16px;
  position: relative;
  width: 16px;
  }
  .label-11 {
  cursor: pointer;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px; 
  position: relative; 
  width: fit-content;
  color: #505050;
  }
  .tertiary {
    &:hover {
      background: #F8F8F8;
    }
    align-items: center;
    background-color: #FFFFFF;
    border: 1px solid;
    border-color: #D8D8D8; 
    border-radius: 2px;
    display: inline-flex; gap: 8px;
    height: 37px;
    justify-content: center;
    overflow: hidden;
    padding: 8px 12px;
    position: relative;
    }
    .reset {
    height: 16px;
    position: relative;
    width: 16px;
    }
    .filters {
    color: #505050;
    font-family: var(--font-family-poppins); 
    font-size: var(--font-size-m);
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px; 
    position: relative; 
    width: fit-content;
    }
    
`;
export default Design;