import React from "react";
import {
  Checkbox,
  TextField,
  InputLabel,
  Autocomplete,
  InputAdornment,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { Search } from "@mui/icons-material";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function GroupAutoComplete(props) {
  const { options, label, value, placeholder, onSelected } = props;
  return (
    <div className="grouped_autocomplete autocomplete_section">
      <InputLabel htmlFor="standard-adornment-amount">{label}</InputLabel>
      <Autocomplete
        multiple
        id="checkboxes-tags-group"
        options={options.sort((a, b) => -b.section.localeCompare(a.section))}
        disableCloseOnSelect
        groupBy={(option) => option.section}
        getOptionLabel={(option) => option.label}
        value={value}
        onChange={(event, newValue, reason) => onSelected(newValue)}
        noOptionsText="No labels"
        renderOption={(props, option, { selected }) => (
          <li key={option?.label} {...props}>
            <Checkbox
              className="child"
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.label}
          </li>
        )}
        style={{ width: "100%" }}
        renderInput={(params) => (
          <TextField
            ref={params.InputProps.ref}
            inputProps={params.inputProps}
            autoFocus
            placeholder={placeholder}
          />
        )}
      />
    </div>
  );
}

export default GroupAutoComplete;
