import { useState, useEffect, useRef } from "react";
import { Tooltip, Box, Button, IconButton, TextField, InputAdornment, Checkbox, Stack, Paper, Menu } from "@mui/material";
import { Tabs, Tab } from "@mui/material";
import ReactDataTable from "components/ReactDataTableComponent";
import moment from "moment";
import Spinner from "components/spinner";
import axios from "axios";
import { fetchUrl } from "Urls";
import { DisciplineSettingDialog, SetNameSettingDialog, ConfirmDialog } from "components/DrawerComponent/DisciplineSettingDialog";
import ToastComponent from "components/ToastComponent";
import { useSelector } from "react-redux";
const SettingsComponent = (props) => {
    const [activeTab, setActiveTabState] = useState(0);
    const [showSpinner, setSpinner] = useState(false);
    const activeTabRef = useRef(activeTab);
    const [rowsPerPage, setRowsPerPage] = useState([]);
    const [disciplineOptions, setDisciplineOptions] = useState([]);
    const { userData = {} } = useSelector((state) => state.userData);
    const [credential, setCredential] = useState(userData);
    const [_projectId, setProjectId] = useState();
    const [disciplineList, setDisciplineList] = useState([]);
    const [shopDrawingTypes, setShopDrawingTypes] = useState([]);
    const [selectedMenu, setSelectedMenu] = useState('Discipline Designators');
    const [selectedMenuList, setSelectedMenuList] = useState([]);
    const [toastMessage, setToastMessage] = useState();
    const [selectedRow, setSelectedRow] = useState();
    const [setOptions, setExistingSetOptions] = useState([]);
    const [dialogData, setDialogData] = useState(null);
    useEffect(() => {
        //console.log('files use effect ::',userData?.projectId,_projectId);
        if (_projectId !== userData?.projectId) {
            if (userData?.projectId) {
                //console.log("files use effect :: in ");
                setProjectId(userData?.projectId);
                setCredential(userData);
            }
        }
    }, [userData?.projectId]);
    useEffect(() => {
        getDisciplineOptions();
        getTypeOfShopDrawingOptions();
        getSetOptions();
    }, []);

    useEffect(() => {
        if (selectedMenu === 'Discipline Designators' && disciplineList.length > 0) {
            setSelectedMenuList(disciplineList);
        } else if (selectedMenu === 'Shop Drawing' && shopDrawingTypes.length > 0) {
            setSelectedMenuList(shopDrawingTypes);
        }
        else if (selectedMenu === 'Set Name' && setOptions.length > 0) {
            setSelectedMenuList(setOptions);
        }
        setSpinner(false);
    }, [selectedMenu, setOptions, disciplineList, shopDrawingTypes]);

    async function getDisciplineOptions(applySettings) {
        setSpinner(true);
        const { token, instanceUrl, projectId } = credential;
        let res;
        if (applySettings === true) {
            res = await axios.put(`${fetchUrl}/applyDiscipline/${projectId}?token=${token}&instanceUrl=${instanceUrl}`).catch((err) => {
                console.log(err);
                setSpinner(false);
            });;
        } else {
            res = await axios.get(`${fetchUrl}/disciplineOptions/${projectId}?token=${token}&instanceUrl=${instanceUrl}`).catch((err) => {
                console.log(err);
                setSpinner(false);
            });;
        }
        if (res?.status === 200) {
            let retData = res.data;
            console.log("retData :::", retData);
            setDisciplineOptions(retData?.options);
            let dis_records = [];
            let i = 0;
            for (let d of retData?.dis_records) {
                dis_records.push({ ...{ id: i }, ...d });
                i++;
            }
            setDisciplineList(dis_records);
            setSelectedMenuList(dis_records);
            setSpinner(false);
        }
    }

    async function getTypeOfShopDrawingOptions() {
        //setLoading(true);
        const instanceUrl = credential.instanceUrl;
        const token = credential.token;
        var req_body = { fields: ['Shop_Drawing_Type__c'] };
        var valuessss;
        await axios.post(`${fetchUrl}/picklist/valueObj/Amazon_S3_Attachment__c?token=${token}&instanceUrl=${instanceUrl}`, req_body)
            .then((res) => {
                if (res?.status === 200) {
                    let retData = res.data;
                    if (retData?.pickObj?.Shop_Drawing_Type__c?.valueList) {
                        valuessss = retData?.pickObj?.Shop_Drawing_Type__c?.valueList;
                    }
                }
            }).catch((err) => {
                console.log(err);
            });
        setShopDrawingTypes(valuessss);
    }

    async function getSetOptions() {
        //setLoading(true);
        const instanceUrl = credential.instanceUrl;
        const token = credential.token;
        const projectId = credential.projectId;
        await axios.get(`${fetchUrl}/sets/${projectId}?token=${token}&instanceUrl=${instanceUrl}`)
            .then((res) => {
                if (res?.status === 200) {
                    let roww = [];
                    res?.data?.records?.map((val, index) => {
                        if (val?.Issuance_Date__c != null) {
                            roww.push({ value: val?.Id, name: val?.Name, date: val?.Issuance_Date__c, Linked_Document__r: val.Amazon_S3_Attachments__r ? val.Amazon_S3_Attachments__r.totalSize : 0 });
                        }
                    });
                    setExistingSetOptions(roww);
                    setSpinner(false);
                   
                }
            }).catch((err) => {
                console.log(err);
                setSpinner(false);
            });
    }

    const DisciplineColumns = [
        {
            accessor: "Designator__c",
            Header: "Designator",
            overrideStyle: { width: "31.3%" },
        },
        {
            accessor: "Name",
            Header: "Name",
            flex: 1,
            overrideStyle: { width: "31.3%" },
        },
        {
            accessor: "Linked_Document__r",
            Header: "Designator",
            overrideStyle: { width: "31.3%" },
        },
        {
            accessor: "actions",
            Header: "Actions",
            flex: 1,
            overrideStyle: { width: "7.3%" },
            Cell: (params) => {
                return (
                    <div style={{ display: "flex", gap: "5px" }}>
                        <div style={{ cursor: "pointer" }} onClick={() => { onMenuAction("edit", selectedMenu, params.row.original); setSelectedRow(params.row.original); }}>
                            <img src="/Pencil.svg" alt="edit Icon" />
                        </div>
                        <div style={{ cursor: "pointer" }} onClick={() => { onMenuAction("delete", selectedMenu, params.row.original); setSelectedRow(params.row.original); }}>
                            <img src="/deleted.svg" alt="edit Icon" />
                        </div>
                    </div>
                );
            },
        },
    ];
    const ShopColumns = [
        {
            accessor: "name",
            Header: "Name",
            overrideStyle: { width: "45%" },

        },
        {
            accessor: "sheets",
            Header: "Sheets",
            flex: 1,
            overrideStyle: { width: "45%" },
        },

        {
            accessor: "actions",
            Header: "Actions",
            flex: 1,
            overrideStyle: { width: "10%" },
            Cell: (params) => {
                return (
                    <div style={{ display: "flex", gap: "5px" }}>
                        <div style={{ cursor: "pointer" }} onClick={() => { setDialogData("edit", selectedMenu, params.row.original); setSelectedRow(params.row.original); }}>
                            <img src="/Pencil.svg" alt="edit Icon" />
                        </div>
                        <div style={{ cursor: "pointer" }} onClick={() => { setDialogData("delete", selectedMenu, params.row.original); setSelectedRow(params.row.original); }}>
                            <img src="/deleted.svg" alt="edit Icon" />
                        </div>
                    </div>
                );
            },
        },
    ];
    const SetColumns = [
        {
            accessor: "name",
            Header: "Name",
            overrideStyle: { width: "31.3%" },

        },
        {
            accessor: "date",
            Header: "Issuance Date",
            flex: 1,
            overrideStyle: { width: "31.3%" },
            Cell: (params) => {
                return (
                    <div style={{ display: "flex", gap: "5px" }}>
                        {moment(params.row.original?.date).format('MM/DD/YYYY')}
                    </div>
                );
            },
        },
        {
            accessor: "Linked_Document__r",
            Header: "Sheets",
            overrideStyle: { width: "31.3%" },
        },
        {
            accessor: "actions",
            Header: "Actions",
            flex: 1,
            overrideStyle: { width: "7.3%" },
            Cell: (params) => {
                return (
                    <div style={{ display: "flex", gap: "5px" }}>
                        <div style={{ cursor: "pointer" }} onClick={() => { onMenuAction("edit", selectedMenu, params.row.original); setSelectedRow(params.row.original); }}>
                            <img src="/Pencil.svg" alt="edit Icon" />
                        </div>
                        <div style={{ cursor: "pointer" }} onClick={() => {
                            if (params.row.original.Linked_Document__r == 0) {
                                onMenuAction("delete", selectedMenu, params.row.original);
                                setSelectedRow(params.row.original);
                            }
                        }}>
                            <img src={params.row.original.Linked_Document__r > 0 ? "delete_disable.svg" : "/deleted.svg"} alt="edit Icon" />
                        </div>
                    </div>
                );
            },
        },
    ];
    function onActiveTabChange(tabValue) {
        setSpinner(true);
        setActiveTab_(tabValue);
        setSpinner(false);
    }
    async function onMenuAction(action, selectedMenuName, selectedRow) {
        console.log("selectedRow :", selectedRow);
        let dialogData = {};
        if (selectedMenuName == "Discipline Designators") {
            if (action == 'add') {
                dialogData.title = `Add Discipline`;
                dialogData.content1 = `Define designator and name below.`;
                dialogData.content2 = `Designator`;
                dialogData.content3 = `Name`;
                dialogData.action1 = "Cancel";
                dialogData.action2 = "Add Discipline";
                dialogData.action = action;
            } else if (action == 'edit') {
                dialogData.title = `Edit Designator`;
                dialogData.content1 = `Edit designator and name below.`;
                dialogData.content2 = `Designator`;
                dialogData.content3 = `Name`;
                dialogData.action1 = "Cancel";
                dialogData.action2 = "Save Changes";
                dialogData.action = action;
            } else {
                dialogData.title = 'Delete Discipline';
                dialogData.content = `Are you sure you want to delete ${selectedRow && selectedRow.Designator__c ? selectedRow.Designator__c : ""}-${selectedRow && selectedRow.Name ? selectedRow.Name : ""}? Once deleted, all sheets assigned with this discipline will be tagged as no discipline.`;
                dialogData.action1 = "Cancel";
                dialogData.action2 = "Delete";
                dialogData.action = action;
            }

        } else if (selectedMenuName == "Set Name") {
            if (action == 'add') {
                dialogData.title = `Add Set Name`;
                dialogData.content1 = `Define name of set name`;
                dialogData.content2 = `Name`;
                dialogData.content3 = `Issuance Date`;
                dialogData.action1 = "Cancel";
                dialogData.action2 = "Add Set Name";
                dialogData.action = action;
            } else if (action == 'edit') {
                dialogData.title = `Edit Set Name`;
                dialogData.content1 = `Define name of set name`;
                dialogData.content2 = `Name`;
                dialogData.content3 = `Issuance Date`;
                dialogData.action1 = "Cancel";
                dialogData.action2 = "Add Set Name";
                dialogData.action = action;
            } else {
                dialogData.title = 'Delete Set Name';
                dialogData.content = `Are you sure you want to delete ${selectedRow.name}? Once deleted, it cannot be undone.`;
                dialogData.action1 = "Cancel";
                dialogData.action2 = "Delete";
                dialogData.action = action;
            }

        }
        setDialogData(dialogData);
    }
    function setActiveTab_(tabValue) {
        activeTabRef.current = tabValue;
        setActiveTabState(tabValue);
    }
    return (
        <Box sx={{ display: "flex" }}>
            <Box component="main" sx={{ flexGrow: 1, width: { sm: "100%" }, height: `calc(100vh - 113px)`, overflow: "hidden", }} >
                <div className="wrapper_main">
                    <div className="table_head">
                        <div className="header_heading">
                            <h1>Settings</h1>
                        </div>
                    </div>
                    <div className="tab_wrapper" style={{ paddingLeft: "6px" }}>
                        <Tabs value={activeTab} textColor="primary" indicatorColor="primary" onChange={(event, newValue) => { onActiveTabChange(newValue); }} >
                            {/* <Tab label="Account" />
                            <Tab label="Notifications" />
                            <Tab label="Preferences" /> */}
                            <Tab label="Sheets" />
                            {/* <Tab label="Files" />
                            <Tab label="Review" />
                            <Tab label="Issues" />
                            <Tab label="Forms" />
                            <Tab label="Photos" /> */}
                        </Tabs>
                    </div>
                    <div className="page_body_settings">
                        <div className="page_body_Settings_left">
                            {/* <div className={selectedMenu == "General Settings" ? "page_body_Settings_left_menu_selected" : "page_body_Settings_left_menu"} onClick={() => setSelectedMenu("General Settings")}>General Settings</div> */}
                            <div className={selectedMenu == "Discipline Designators" ? "page_body_Settings_left_menu_selected" : "page_body_Settings_left_menu"} onClick={() => setSelectedMenu("Discipline Designators")}>Discipline Designators</div>
                            {/* <div className={selectedMenu == "Sheet Settings" ? "page_body_Settings_left_menu_selected" : "page_body_Settings_left_menu"} onClick={() => setSelectedMenu("Sheet Settings")}>Sheet Settings</div> */}
                            {/* <div className={selectedMenu == "Shop Drawing" ? "page_body_Settings_left_menu_selected" : "page_body_Settings_left_menu"} onClick={() => setSelectedMenu("Shop Drawing")}>Shop Drawing</div> */}
                            <div className={selectedMenu == "Set Name" ? "page_body_Settings_left_menu_selected" : "page_body_Settings_left_menu"} onClick={() => setSelectedMenu("Set Name")}>Set Name</div>
                        </div>
                        {activeTab === 0 && selectedMenu &&
                            <div className="page_body_Settings_center">
                                <div className="page_body_Settings_center_head">
                                    <div className="page_body_Settings_center_left">
                                        {selectedMenu} ({selectedMenuList.length})
                                    </div>
                                    <div className="page_body_Settings_center_right">
                                        <Button className="custom_button apply_button" onClick={() => onMenuAction("add", selectedMenu)}> {selectedMenu == "Discipline Designators" ? "Add Discipline" : selectedMenu == "Shop Drawing" ? "Add Shop Drawing" : "Add Set Name"}
                                        </Button>
                                    </div>
                                </div>
                                <div className="page_body_Settings_center_bottom">
                                    {selectedMenuList && selectedMenuList.length > 0 &&
                                        <Box style={{ height: "78vh" }} >
                                            {/*<Box style={{ height: filterOptionsVisible ? "calc(100vh - 230px)" : "calc(100vh - 180px)", }} >*/}
                                            {showSpinner && <Spinner />}
                                            <ReactDataTable
                                                pagination={false}
                                                setRowsPerPage={setRowsPerPage}
                                                columns_react_table={selectedMenu == "Discipline Designators" ? DisciplineColumns : selectedMenu == "Shop Drawing" ? ShopColumns : SetColumns}
                                                rowss={selectedMenuList}
                                                listCount={0}
                                                selectedIds={[]}
                                            />
                                        </Box>
                                    }
                                </div>
                            </div>
                        }
                    </div>
                    {dialogData && selectedMenu == "Discipline Designators" && (dialogData.action == "edit" || dialogData.action == "add") &&
                        <DisciplineSettingDialog
                            onCancel={() => {
                                setSelectedRow({});
                                setDialogData(null);
                            }}
                            onAccept={(record, action) => {
                                setSelectedRow({});
                                setDialogData(null);
                                setSpinner(true);
                                let formObj = { Name: record.Name, Designator__c: record.Designator__c, Project__c: credential.projectId };
                                if (action === "edit") {
                                    formObj.Id = record.Id;
                                }
                                const formData = new FormData();
                                formData.append('recObj', JSON.stringify(formObj));
                                axios.post(`${fetchUrl}/recSave/Discipline__c?token=${credential?.token}&instanceUrl=${credential?.instanceUrl}`, formData)
                                    .then(res => {
                                        if (res.data.status == 200) {
                                            let tm
                                            if (action == "edit") {
                                                tm = `Discipline designator has been added.`;
                                            } else {
                                                tm = `${record.Name} discipline has been added.`;
                                            }
                                            setToastMessage(tm);
                                            getDisciplineOptions(true);
                                        }
                                    }).catch(err => {
                                        console.log(`error /recSave/Form__c`, err);
                                    });
                            }}
                            dialogData={dialogData}
                            disciplineList={selectedMenuList}
                            selectedRow={selectedRow}
                        />
                    }
                    {dialogData && selectedMenu == "Set Name" && (dialogData.action == "edit" || dialogData.action == "add") &&
                        <SetNameSettingDialog
                            onCancel={() => {
                                setSelectedRow({});
                                setDialogData(null);
                            }}
                            onAccept={(record, action) => {
                                console.log("record ::", record);
                                setSelectedRow({});
                                setDialogData(null);
                                setSpinner(true);
                                let obj = {
                                    Id: record.value ? record.value : null,
                                    Name: record.name,
                                    Project__c: _projectId,
                                    Issuance_Date__c: record.date
                                };
                                console.log("obj :", obj);
                                const formData = new FormData();
                                //let issueObj = sfIssueObj;
                                formData.append("recObj", JSON.stringify(obj));
                                axios.post(`${fetchUrl}/recSave/Set__c?token=${credential?.token}&instanceUrl=${credential?.instanceUrl}`, formData)
                                    .then(async (res) => {
                                        
                                        if (res.data.status == 200) {
                                            getSetOptions();
                                           
                                            if (action == 'edit') {
                                                let tm = `${selectedRow.name} set name has been edited`;
                                                setToastMessage(tm);
                                            } else {
                                                let tm = `${record.name} set name has been added`;
                                                setToastMessage(tm);
                                            }

                                        }
                                    }).catch((err) => {
                                        console.log('recSave/Set__c', err);
                                        setSpinner(false);
                                    });
                            }}
                            dialogData={dialogData}
                            disciplineList={selectedMenuList}
                            selectedRow={selectedRow}
                        />
                    }
                    {dialogData && dialogData.action == "delete" && (
                        <ConfirmDialog
                            onCancel={() => {
                                setDialogData(null);
                                setSelectedRow({});
                            }}
                            onAccept={() => {
                                setDialogData(null);
                                setSelectedRow({});
                                setSpinner(true);
                                console.log("selectedRow ::", selectedRow);
                                if (selectedMenu == "Discipline Designators") {
                                    axios.delete(`${fetchUrl}/delete/Discipline__c/${selectedRow.Id}?token=${credential?.token}&instanceUrl=${credential?.instanceUrl}`)
                                        .then((res) => {
                                            let tm = `${selectedRow && selectedRow.Designator__c ? selectedRow.Designator__c : ""}-${selectedRow && selectedRow.Name ? selectedRow.Name : ""} discipline designator has been deleted`;
                                            setToastMessage(tm);
                                            setSelectedRow([]);
                                            getDisciplineOptions();
                                            setSpinner(false);
                                        }).catch((err) => {
                                            console.log(err);
                                            setSpinner(false);
                                        });
                                } else {
                                    axios.delete(`${fetchUrl}/delete/Set__c/${selectedRow.value}?token=${credential?.token}&instanceUrl=${credential?.instanceUrl}`)
                                        .then((res) => {
                                            setSelectedRow([]);
                                            getSetOptions();
                                            let tm = `${selectedRow?.name} set name has been deleted`;
                                            setToastMessage(tm);
                                        }).catch((err) => {
                                            console.log(err);
                                            setSpinner(false);
                                        });
                                }
                            }}
                            DialogData={dialogData}
                        />
                    )}
                    {toastMessage &&
                        <ToastComponent message={toastMessage} handleClose={() => setToastMessage(null)} />
                    }
                </div>
            </Box>
        </Box>
    );
};
export default SettingsComponent;