import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  canvasData: {},
};

export const canvasDataSlice = createSlice({
  name: 'canvasData',
  initialState,
  reducers: {
    setCanvasData: (state, action) => {
      state.canvasData = action.payload;
    },
    resetCanvasData: (state) => {
      state.canvasData = {};
    },
  }
});

export const { setCanvasData, resetCanvasData } = canvasDataSlice.actions;
export default canvasDataSlice.reducer;
