import styled from "styled-components";

const AddSheetComponentStyles = styled.div`
  height: 100%;
  ul {
    list-style: none;
    padding: 0;
  }
  li {
    padding: 12px;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.14px;
    color: var(--drgray);
    img {
      margin-right: 5px;
    }
    .file_name {
      display: flex;
      .file_name_incomplete {
        color: #929292;
      }
      .file_name_complete {
        color: #000000;
      }
    }
    .file_status {
      display: flex;
      gap: 16px;
      img {
        cursor: pointer;
      }
      .upload_inprogress {
        gap: 4px;
        display: flex;
        span.MuiLinearProgress-root.MuiLinearProgress-colorPrimary.MuiLinearProgress-determinate {
          margin-top: 6px;
        }
      }
      .upload_completed {
        color: #0aa110;
        display: flex;
        gap: 4px;
        width: 180px;
      }
      .upload_failed {
        color: #f92828;
        display: flex;
        gap: 4px;
      }
      .progress_value {
        color: var(--text-color);
      }
    }
  }
  aside {
    margin-top: 20px;
    width: 100%;
    max-width: 100%;
    h4 {
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0.14px;
      color: #000;
      padding: 12px;
      margin-bottom: 0;
    }
  }
  h4 {
    margin-bottom: 10px;
  }

  .hold_content {
    display:flex;
    height: 99%;
    padding: 24px;
    background: #fff;
    overflow: hidden;
    justify-content: center;
    .rsheet_area {
      width:50%;
      position: relative;
      padding-left: 45px;
      &:before {
        content: "";
        width: 1px;
        height: 100%;
        background: #eaeaea;
        position: absolute;
        left: 0;
        top: 80px;
      }
      h4 {
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.24px;
      }
      .upload_holder {
        width: 100%;
        h4 {
          font-size: 14px;
        }
        .upload_p {
          color: var(--text-color);
        }
        .drag_drop {
          color: #000000;
        }
      }
      .discipline {
        color: var(--text-color);
        text-transform: capitalize;
        font-family: var(--common-font);
        font-weight: 400;
        font-size: 14px;
        height: 37px;
        padding: 8px 12px 8px 12px;
        border-radius: 2px;
        right: 155px;
        top: 0px;
        right: 0px;
        position: absolute;
        border: 1px solid var(--border-gray);
        gap: 8px;
        &:hover {
          background: #F8F8F8;
          svg {
            fill: var(--text-color);
          }
        }
      }
      .wrapper_right {
        .holder_list {
          .add-sheet_extract-table{
              .MuiDataGrid-virtualScrollerRenderZone{
                  cursor: pointer;
              }
          }
          .selected_items {
            font-family: var(--common-font);
            font-size: 14px;
            color: #929292;
          }
          .sheet_button {
            &:hover {
              background: #F8F8F8;
            }
            color: #505050;
            background: #fff;
            border: 1px solid #D8D8D8;
            span.MuiCheckbox-root.MuiCheckbox-colorPrimary {
              padding: 0;
              color: red;
            }
          }
          .sheet_button_disable {
            color: #BABABA;
          }
          .hold_head_btn {
            justify-content: space-between;
            width: 100%;
            align-items: center;
            padding: 22px 0 10px;
          }
        }
        .actions.action_button {
          bottom: 0;
          right: 0;
        }
        .save_draft {
          color: var(--text-color);
          text-transform: capitalize;
          font-family: var(--common-font);
          font-weight: 400;
          font-size: 14px;
          height: 39px;
          padding: 8px 12px 8px 12px;
          border-radius: 2px;
          right: 0;
          border: 1px solid var(--border-gray);
          gap: 8px;
          &:hover {
            background: #F8F8F8;
            svg {
              fill: var(--text-color);
            }
          }
        }
      }
      .upload_body {
        width: 100%;
        margin-top: 50px;
        display: block;
        .sheet_button {
          /* color: #505050; */
          text-transform: capitalize;
          font-family: var(--common-font);
          font-weight: 400;
          font-size: 14px;
          height: 37px;
          padding: 8px 12px 8px 12px;
          border-radius: 2px;
          border: 1px solid var(--border-gray);
          gap: 8px;
          &:hover {
            background: #e8efff;
            svg {
              fill: var(--text-color);
            }
          }
        }
        .sheet_button .Mui-disabled {
          background: rgba(234, 234, 234, 1);
          color: rgba(186, 186, 186, 1);
        }
        .checkbox {
          .set_title {
            display: flex;
            justify-content: space-between;
          }
        }
        .checkbox-border {
          .set_title {
            display: flex;
            justify-content: space-between;
          }
        }
        h6 {
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: 0.2px;
          color: #000;
          font-family: var(--common-font);
          margin-bottom: 5px;
        }
        p {
          font-size: 14px;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 0.14px;
          font-family: var(--common-font);
        }
        .blue_upload {
          color: var(--drblue);
        }
        .actions {
          justify-content: flex-end;
          margin-top: 21px;
          .next_version {
            text-transform: none;
            background: var(--drblue);
            border: 1px solid var(--drblue);
            box-shadow: none;
            border-radius: 2px;
            font-family: var(--common-font);
            font-weight: 400;
            &:hover {
              background: #fff;
              color: var(--drblue);
            }
          }
          .Mui-disabled {
            background: #BABABA;
            color: white;
            border: 2px solid #BABABA;
          }
        }
      }
    }

    .add_sheet_steps {
      max-width: 220px;
      flex: auto;
    }
    .steps_heading {
      cursor: pointer;
      img {
        margin-right: 5px;
        cursor: pointer;
      }
      p {
        font-size: 14px;
        font-family: var(--common-font);
      }
    }
  }

  .steps_body {
    margin-top: 48px;
    .step_label_desc {
      color: rgba(146, 146, 146, 1);
      font-weight: 500;
    }
    .MuiStepLabel-label {
      font-size: 14px;
      font-weight: 600;
      line-height: normal;
      font-family: var(--common-font);
      letter-spacing: 0.14px;
    }
    span.MuiStepLabel-label.Mui-active {
      color: #000;
    }
    span.MuiStepLabel-label .Mui-disabled {
      color: var(--text-color);
    }
    .MuiStepConnector-root.MuiStepConnector-vertical.Mui-active span {
      border-left: 2px solid var(--border-gray);
    }
    .MuiStepConnector-vertical span.MuiStepConnector-line,
    .holderstep {
      border-left: 2px solid var(--border-gray);
    }
    .MuiStepConnector-vertical.Mui-completed span.MuiStepConnector-line {
      border-left-color: var(--drblue);
    }
    .MuiStepper-vertical {
      .MuiStep-vertical {
        padding: 0;
        height: 80px;
        position: relative;
        .MuiStepConnector-vertical {
          display: none;
        }
        .MuiStepLabel-vertical {
          display: flex;
          align-items: flex-start;
        }
        &::after {
          position: absolute;
          content: "";
          background: var(--drblue);
          width: 2px;
          height: 35px;
          left: 11px;
          bottom: 5px;
        }
        .Mui-disabled {
          &::after {
            position: absolute;
            content: "";
            background: rgba(146, 146, 146, 1);
            width: 2px;
            height: 35px;
            left: 11px;
            bottom: 5px;
            z-index: 1;
          }
        }
        .holderstep {
          border: 0;
        }
        &:last-child {
          &::after {
            display: none;
          }
        }
        &:last-child {
          .Mui-disabled {
            &::after {
              display: none;
            }
          }
        }
      }
      .Mui-completed {
        &::after {
          position: absolute;
          content: "";
          background: #0aa110;
          width: 2px;
          height: 35px;
          left: 11px;
          bottom: 5px;
        }
      }
      .MuiStepConnector-vertical {
        display: none;
      }
    }

    .holderstep {
      p {
        font-size: 10px;
        font-weight: 500;
        color: var(--drblue);
        font-family: var(--common-font);
      }
    }
    .Mui-disabled span.MuiStepLabel-iconContainer .MuiStepIcon-root {
      border: 1px solid #bababa;
      border-radius: 50%;
      circle {
        fill: #fff;
      }
      text {
        opacity: 0;
      }
    }
    span.MuiStepLabel-iconContainer .MuiStepIcon-root.Mui-active {
      border: 1px solid var(--btn-blue);
      border-radius: 50%;
      padding: 3px;
      color: #346EF5;
      circle: {
        fill: #346EF5;
      }
      text {
        opacity: 0;
      }
    }

    .Mui-completed {
      color: #000;
      /* svg {
        transform: translateY(-10px);
      }
      .MuiStepConnector-lineVertical {
        min-height: 40px;
        margin-top: -30px;
        margin-bottom: -20px;
        border-left: 2px solid #0aa110 !important;
      } */
      span.MuiStepLabel-iconContainer svg.MuiSvgIcon-root.Mui-completed {
        /* border: 1px solid #0aa110;
      padding: 2px; */
        path {
          fill: #0aa110;
        }
      }
    }
  }
  .checkbox-border {
    border: 2px solid #346EF5;
    border-radius: 16px;
    margin-top: 20px;
  }
  .checkbox {
    border: 2px solid #d8d8d8;
    border-radius: 16px;
    margin-top: 20px;
    /* margin-left: 2px; */
  }
  .action_button {
    justify-content: flex-end;
    margin-top: 21px;
    gap: 10px;
    .back_button {
      &:hover {
        background: #F8F8F8;
      }
      width: 100%;
      max-width: max-content;
      text-transform: none;
      height: 39px;
      padding: 9px 16px 9px 16px;
      border-radius: 2px;
      border: 1px solid var(--text-color);
      gap: 8px;
      background: rgba(255, 255, 255, 1);
      color: var(--text-color);
      box-shadow: none;
      font-weight: 400;
      font-family: var(--common-font);
    }
    .next_button {
      width: 100%;
      text-transform: none;
      max-width: max-content;
      height: 39px;
      padding: 9px 16px 9px 16px;
      border-radius: 2px;
      gap: 10px;
      background-color: #346EF5;
      box-shadow: none;
      font-weight: 400;
      font-family: var(--common-font);
    }
  }
  .holder_set:not(.set_full) {
    .checkbox {
      max-width: calc(100% / 2 - 8px);
      flex: auto;
      width: 100%;
    }
    .checkbox-border {
      max-width: calc(100% / 2 - 8px);
      flex: auto;
      width: 100%;
    }
  }
  .holder_set {
    justify-content: space-between;
    .checkbox {
      padding: 24px;
      h4 {
        font-size: 16px;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 0.16px;
        font-family: var(--common-font);
        margin-bottom: 0;
        color: #000;
      }
      .set_title {
        margin-bottom: 20px;
        button.MuiButtonBase-root {
          padding: 0;
        }
      }
      .MuiInputBase-colorPrimary {
        width: 100%;
      }
      input {
        line-height: normal;
        font-family: var(--common-font);
        font-size: 14px;
        color: var(--drgray);
        padding: 8px 12px;
        font-weight: 400;
      }
      .Mui-focused {
        .MuiOutlinedInput-notchedOutline {
          border: 1px solid var(--drblue);
        }
      }

      .form_group {
        .MuiOutlinedInput-input {
          color: #929292;
        }
        .MuiOutlinedInput-input:hover {
          border-color: #D8D8D8;
          background-color: #F8F8F8;
          color: #929292;
        }
        .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
          border-color: #D8D8D8;
        }
        .MuiOutlinedInput-notchedOutline {
          border-color: #D8D8D8;
        }
        label {
          font-family: var(--common-font);
          font-size: 14px;
          color: var(--text-color);
        }

        & > label {
          margin-bottom: 5px;
        }
        &:nth-child(1) {
          margin-bottom: 10px;
        }
        .MuiFormControl-root {
          label:not(.Mui-focused) {
            margin: -8px 0 0;
            /* top: -9px; */
          }
          label.MuiFormLabel-filled {
            margin: 0;
          }
        }
      }
      .MuiSelect-select {
        padding: 8px 12px;
        font-size: 14px;
       // background: #eaeaea;
        color: #bababa;
        border-color: #bababa;
        em {
          font-family: var(--common-font);
          font-style: normal;
        }
      }
      .select_cstm {
        border: 1px solid #bababa;
        .MuiOutlinedInput-notchedOutline {
          border: none;
        }
      }
      .Mui-focused.select_cstm {
        border: 1px solid var(--drblue);
      }
    }
    .grid_active {
      border: 2px solid #1976d2;
    }
    .holder_shop .form_group {
      margin-top: 10px;
      label {
        color: #bababa;
      }
    }
    .checkbox-border {
      padding: 24px;
      h4 {
        font-size: 16px;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 0.16px;
        font-family: var(--common-font);
        margin-bottom: 0;
        color: #000;
      }
      .set_title {
        margin-bottom: 20px;
        button.MuiButtonBase-root {
          padding: 0;
        }
      }
      .MuiInputBase-colorPrimary {
        width: 100%;
      }
      input {
        line-height: normal;
        font-family: var(--common-font);
        font-size: 14px;
        color: var(--drgray);
        padding: 8px 12px;
        font-weight: 400;
      }
      .Mui-focused {
        .MuiOutlinedInput-notchedOutline {
          border: 1px solid var(--drblue);
        }
      }
      
      .form_group {
        .MuiOutlinedInput-input {
          color: #505050;
        }
        .MuiOutlinedInput-input:hover {
          border-color: #D8D8D8;
          background-color: #F8F8F8;
          color: #505050;
        }
        .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
          border-color: #D8D8D8;
        }
        .MuiOutlinedInput-notchedOutline {
          border-color: #D8D8D8;
        }
        label {
          font-family: var(--common-font);
          font-size: 14px;
          color: var(--text-color);
        }

        & > label {
          margin-bottom: 5px;
        }
        &:nth-child(1) {
          margin-bottom: 10px;
        }
        .MuiFormControl-root {
          label:not(.Mui-focused) {
            margin: -8px 0 0;
            /* top: -9px; */
          }
          label.MuiFormLabel-filled {
            margin: 0;
          }
        }
      }
      .MuiSelect-select {
        padding: 8px 12px;
        font-size: 14px;
       // background: #eaeaea;
        color: #bababa;
        border-color: #bababa;
        em {
          font-family: var(--common-font);
          font-style: normal;
        }
      }
      .select_cstm {
        border: 1px solid #bababa;
        .MuiOutlinedInput-notchedOutline {
          border: none;
        }
      }
      .Mui-focused.select_cstm {
        border: 1px solid var(--drblue);
      }
    }
    .holder_shop .form_group {
      margin-top: 10px;
      label {
        color: #bababa;
      }
    }
  }
  img[alt="upoad_icon"] {
    margin-bottom: 6px;
  }
  button.MuiButtonBase-root.Mui-disabled.MuiIconButton-root.Mui-disabled {
    background: none;
  }
  
  .add-sheet_extract-table .MuiDataGrid-main {
    .MuiDataGrid-cellCheckbox {
        padding-left: 9px;
    } 
    border: none;
    .MuiDataGrid-columnHeaders {
      border-bottom: 1px solid #eaeaea;
    }
    .MuiDataGrid-cell:focus-within{
      outline:none
    }
    .MuiDataGrid-columnSeparator.MuiDataGrid-columnSeparator--sideRight {
      display: none;
    }
    .MuiDataGrid-columnHeader {
      padding: 0;
    }
   
    .MuiDataGrid-columnHeaderTitle {
      color: var(--text-color);
      line-height: normal;
    }
    .MuiDataGrid-columnHeadersInner{
     .MuiDataGrid-columnHeaderRow{
      width: 100%;
      }
        width: 100%;
    }
    .MuiDataGrid-cell:focus-within{
      outline: none
    }
    height: calc(100vh - 250px) !important;
  }
  .MuiCheckbox-root{
    color: #D8D8D8 !important;
  }
  .Mui-checked {
    color: #346EF5 !important;
  }
    span.MuiCheckbox-root.MuiCheckbox-colorPrimary {
      padding: 0;
      
    }
    svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
      //color: #bababa;
    }
    .MuiDataGrid-row {
      padding: 0 9px;
      &:hover {
        background: #F8F8F8;
      }
    }
    .file_area {
      color: var(--text-color);
      b {
        font-weight: 600;
        margin-bottom: 5px;
        display: block;
      }
      img {
        height: 80px;
        padding: 0;
        border: 1px solid var(--border-gray);
      }
    }
    .file_field {
      align-items: center;
      .MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input{
        width: 110px;
      }
      .MuiOutlinedInput-notchedOutline {
        border-color: #D8D8D8 !important;
      }
      textarea {
        min-height: 37px;
        border: 1px solid #D8D8D8;
        padding: 8px 12px;
        border-radius: 2px;
        outline: none;
        color: #505050;
      }
      img {
        border: none;
        padding: 0;
      }
    }
    
    .border-img {
      border: 1px solid var(--border-gray) !important;
      padding: 2px !important;
    }
    .file_field-error {
      align-items: center;
      .MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input{
        width: 110px;
      }
      .MuiOutlinedInput-notchedOutline {
        border-color: #D8D8D8 !important;
      }
      textarea {
        min-height: 37px;
        border: 1px solid red;
        padding: 8px 12px;
        border-radius: 2px;
        outline: none;
        color: #505050;
      }
      img {
        border: none;
        padding: 0;
      }
    }
  .file_field-errors {
      align-items: center;
      .MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input{
        width: 110px;
      }
        .MuiInputBase-root{
        border: 1px solid red;
        }
      .MuiOutlinedInput-notchedOutline {
        border-color: #D8D8D8 !important;
      }
        textarea {
        min-height: 35px;
        border-radius: 2px;
        padding: 0px 12px;
        margin-top: 10px;
        outline: none;
        color: #505050;
      }
      img {
        border: none;
        padding: 0;
      }
    }
 .file_fields {
      align-items: center;
      .MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input{
        width: 110px;
      }
      .MuiOutlinedInput-notchedOutline {
        border-color: #D8D8D8 !important;
      }
      img {
        border: none;
        padding: 0;
      }
        textarea {
        min-height: 35px;
        border-radius: 2px;
        padding: 0px 12px;
        outline: none;
        color: #505050;
      }
    }
    .MuiDataGrid-cell--withRenderer[data-field="delete"] img {
      padding: 0;
      border: none;
      /* filter: invert(38%) sepia(56%) saturate(6202%) hue-rotate(345deg)
        brightness(97%) contrast(101%); */
    }
    .MuiDataGrid-main {
      height: 500px;
    }
    .MuiDataGrid-cell:focus {
      outline: none;
    }
    .MuiDataGrid-root {
    border: none;
    }
  }
  
  .btn_sheetgroup {
    gap: 12px;
    img {
     // filter: invert(1);
    }
    svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
     // color: #bababa;
    }
  }
.hide-rows-per-page {
 .MuiTablePagination-root {
  .MuiTablePagination-toolbar {
   display: contents;
   .MuiTablePagination-selectLabel {
     display: none;
   }
   .MuiTablePagination-select { 
   display: none;
   }
  }
 }
}
`;
export const DisciplineDialogStyles = styled.div`
  h2.dialog_title.MuiTypography-root.MuiTypography-h6.MuiDialogTitle-root {
    background: #fff;
    font-weight: 600;
    border-bottom: 2px solid rgba(234, 234, 234, 1);
    font-family: var(--common-font);
  }
  .action_button.footer {
    justify-content: space-between;
    .checkbox {
      align-items: center;
    }
  }
  .action_button {
    justify-content: flex-end;
    margin-bottom: 10px;
    gap: 8px;
    border-top: 2px solid rgba(234, 234, 234, 1);
    button.cancel_button {
      &:hover {
        background: #F8F8F8;
      }
      width: 100%;
      max-width: max-content;
      text-transform: none;
      padding: 9px 16px 9px 16px;
      border-radius: 2px;
      border: 1px solid rgba(80, 80, 80, 1);
      gap: 8px;
      background: rgba(255, 255, 255, 1);
      color: rgba(80, 80, 80, 1);
      box-shadow: none;
      font-weight: 400;
      font-family: var(--common-font);
    }
    button.save_settings {
      text-transform: none;
      background: #346EF5;
     // padding: 9px 16px 9px 16px;
      border: 1px solid #346EF5;
      box-shadow: none;
      border-radius: 2px;
      color: rgba(255, 255, 255, 1);
      font-family: var(--common-font);
      font-weight: 400;
      
    }
    .Mui-disabled {
      background: rgba(255, 255, 255, 1);
      color: #50505070;
      border: 2px solid var(--border-gray);
    }
  }
  .MuiDialogContent-root {
    button.add_discpline {
      color: var(--text-color);
      text-transform: capitalize;
      font-family: var(--common-font);
      font-weight: 400;
      font-size: 14px;
      background: #fff;
      right: 10px;
      top: 79px;
      display: flex;
      position: absolute;
      border: none;
      gap: 8px;
      &:hover {
        background: #fff;
        svg {
          fill: var(--text-color);
        }
      }
    }
    h6 {
      font-weight: 600;
      font-size: 14px;
      margin-bottom: 20px;
      margin-top: 20px;
    }
  }
  .data_table {
    height: 400px;
    width: 100%;
    min-width: 480px;
  }
  .dialog_content {
    p {
      font-family: var(--common-font);
      font-size: 15px;
      color: rgba(80, 80, 80, 1);
    }
    .publish_log {
      font-weight: 600;
    }
  }
  .MuiDialogContent-root{
    padding: 0px;
  }
  .dropZone_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 16px;
    
  }
  .dropzone {
    text-align: center;
    cursor: pointer;
    transition: border 0.3s ease;
    width: 100%;
    min-height: 120px;
    display: grid;
    place-content: center;
    background: rgba(234, 234, 234, 1);
  }
  .dropzone:hover {
    border-color: #1890ff;
  }
`;
export default AddSheetComponentStyles;
