import React, { useState , useEffect} from "react";
import { Button , Select ,MenuItem } from "@mui/material";
import { cloneDeep } from "lodash";
import { answersPreArr } from 'components/FormTemplateTabComponent/ChecklistFormTemplate/constants';

const PreconfiguredResponseChoices = ({handleChange ,responseOptions , ...props}) => {
  const {conditionLogicActions , conditionLogic} = props;
  const [answers, setAnswers] = useState([]);
  const [selectedPairInd, setSelectedPairInd] = useState(-1);
  useEffect(() => {
    try {
      if(responseOptions && responseOptions.length > 0){
        setAnswers(responseOptions);
        for(let i = 0 ; i < answersPreArr.length ; i++){
          let currenRes = cloneDeep(answersPreArr[i]);
          let activeResponse = cloneDeep(responseOptions);
          let foundRes =0;
          for(let opt of activeResponse){
            const innnn = currenRes?.find((cr)=> cr.value === opt.value);
            if(innnn){
              foundRes++;
            }
          }
          if(foundRes === currenRes.length){
            setSelectedPairInd(i);
            break;
          }
        }
      }
    } catch (e) {
      console.log('responseOptions parse error :: '+e.message);
    }
  },[responseOptions]);
  const handleSelectOption = (index) => {
    setSelectedPairInd(index);
    setAnswers(answersPreArr[index]);
    handlePush(answersPreArr[index]);
  };
  const handleUpdateOptionAction = (ind , action) => {
    const tmpArr = cloneDeep(answers);
    if (tmpArr && tmpArr.length > 0 && tmpArr[ind]) {
      if(tmpArr[ind].value && tmpArr[ind].value.trim() != ''){
        tmpArr[ind].action = action;
        setAnswers(tmpArr);
        handlePush(tmpArr);
      }
    }
  };
  const handlePush = (_answer = answers) => {
    const tmpArr = cloneDeep(_answer);
    let jsonTosend ;
    if(tmpArr && tmpArr.length > 0 ){
      jsonTosend = tmpArr ;
    }
    handleChange({target : {name : 'responseOptions' , value : jsonTosend } });
  };
  return (
    <div>
      <div style={{ gap: "3px", display: "flex", marginLeft: "20px" }}>
        {answersPreArr.map((combo, ind) => (
          <Button key={ind + 1} name={ind} className="custom_button cancel_button" style={{ backgroundColor: selectedPairInd === ind && "#E8EFFF" }}
            onClick={e => handleSelectOption(ind)}>
            {combo.map((opti, oind) => (
              opti.label + (oind < (combo.length - 1) ? " / " : '')
            ))}
          </Button>
        ))}
      </div>
      {conditionLogic && <>
        <div style={{ 'paddingTop': '16px','marginTop': '16px','marginLeft':'20px','marginRight':'20px', borderTop: '1px solid #D8D8D8' }}>
          {answers?.map((opt, ind) => (
            <div key={opt.value + '-' + ind} className="list_option" >
              <div style={{ display: 'flex', width: "100%", marginRight: "20px", fontStyle: 'italic' }}>
                <div style={{ width: "25%", maxWidth: '30px', fontSize: "14px", fontWeight: 400, color: "#505050" }}>
                  if
                </div>
                <div style={{ width: "8%" }}><b>{opt.value}</b></div>
                <div style={{ width: "8%", color: "#505050", fontStyle: 'italic', fontSize: "14px", fontWeight: 400}}>then</div>
                <div>
                </div>
                <Select role="presentation"
                  style={{ height: "37px", width: "48%", color: "#505050", marginTop: "-5px", marginLeft: "12px", }}
                  id={`conditionAction-${opt.action}`}
                  value={opt.action}
                  onChange={(e) => {
                    let value = e.target.value;
                    handleUpdateOptionAction(ind, value);
                  }}>
                  {conditionLogicActions?.map((r) => (
                    <MenuItem key={r.value} value={r.value}>
                      {r.label}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </div>
          ))}
        </div>
      </>}
    </div>
  );
};

export default PreconfiguredResponseChoices;
