import { useState, useEffect, useRef } from "react";
import CloseIcon from "assets/images/close.svg";
import folderIcon from "assets/images/folder.svg";
import pdfRed from "assets/images/pdfRed.svg";
import axios from "axios";
import { fetchUrl } from "Urls";
import { Search } from "@mui/icons-material";
import { DataGrid } from '@mui/x-data-grid';
import Spinner from "components/spinner";
import { prepareAmazonRecordRow, prepareFolderRecordRow } from "components/SheetsComponent";
import { BreadcrubFolderView } from "components/FilesComponent/FolderFilesDataTable";
import useFormSectionHook from "hooks/FolderSection.hook";
import { cloneDeep } from "lodash";
import moment from "moment";
import {
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  TextField,
  InputAdornment,
  MenuItem,
  Box
} from "@mui/material";
export const AddPdfFile = ({ ...props }) => {
  const { onAccept, onCancel, credential, linkDocument, folderNameToIdMap } = props;
  const [allData, setAllDataSet] = useState([]);
  const [currentFolders, setCurrentFolders] = useState([]);
  const [currentFolderName, setCurrentFolderName] = useState({});
  const [selectedNode, setSelectedNode] = useState([]);
  const [selectedFile, setSelectedFile] = useState();
  const [selectedFileId, setSelectedFileId] = useState([]);
  const [folderFileList, setFolderFileList] = useState();
  const [showSpinner, setSpinner] = useState(false);
  const [currentFolderTree, setCurrentFolderTree] = useState(false);
  const [searchBoxFilter, setSearchBoxFilter] = useState(false);
  const [sheetsFiles, setSheetsFiles] = useState(null);
  const [sheetType, setSheetType] = useState("Contract Drawings");
  const [shopTypeSingle, setShopTypeSingle] = useState("All");
  const [setName, setSetName] = useState("All");
  const [shopDrawingTypes, setShopDrawingTypes] = useState([]);
  const [setOptions, setSetOptions] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [selectedPDFRowIds, setSetSelectedPDFRowIds] = useState([]);
  const [filterSheetList, setFilterSheetList] = useState([]);
  const currentSheetList = useRef([]);
  const { findFolderPath, _getNodeChildren, _getFolderApi } = useFormSectionHook({
    'projectId': credential?.projectId,
    'allData': allData,
    'setAllDataSet': setAllDataSet,
    'setCurrentFolders': setCurrentFolders,
    'setSelectedNode': setSelectedNode,
    'setCurrentFolderName': setCurrentFolderName,
    'currentFolderName': currentFolderName,
  });
  useEffect(() => {
    _getFolderApi();
    getTypeOfShopDrawingOptions()
  }, [credential?.projectId]);
  useEffect(() => {
    if(folderNameToIdMap){
    _getFileApi("Contract Drawings");
    }
  }, [folderNameToIdMap]);
  useEffect(() => {
    onSelectFolder({ Id: currentFolderName?.id });
  }, [currentFolderName]);
  async function _getFolderFileApi(id, calledFrom) {
    if (id) {
      await axios.get(`${fetchUrl}/folder/${id}`).then(async (res) => {
        const { files, sub_folders } = res?.data;
        let withfile = [];
        sub_folders?.map(async (fol) => {
          let folRow = await prepareFolderRecordRow(fol);
          withfile.push(folRow);
        });
        files?.map(async (file) => {
          let fileRow = await prepareAmazonRecordRow(file, credential);
          if (fileRow.File_Extension__c == "pdf") {
            withfile.push(fileRow);
          }
        })
        setFolderFileList(withfile);
        setSpinner(false);
      })
    }
  }
  async function _getFileApi(calledFrom) {
    searchListViewFunction("");
    setSearchText("");
    setSetName("All");
    setShopTypeSingle("All");
    setSpinner(true);
    await axios.get(`${fetchUrl}/folderfiles/${folderNameToIdMap[calledFrom].Id}`)
      .then(async (res) => {
        if (res?.status === 200) {
          const { files } = res?.data;
          //console.log("tree ::", tree);
          let withfile = [];
          //let withfile = [...data];
          if (files?.length > 0) {
            for (let file of files) {
              let fObj = await prepareAmazonRecordRow(file, credential);
              withfile.push(fObj);
            }
          }
          setSheetsFiles(withfile);
          currentSheetList.current = withfile;
          setSpinner(false);
        }
      }).catch((err) => {
        console.log(err);
        setSpinner(false);
      });
    setSpinner(false);
  }
  async function getTypeOfShopDrawingOptions() {
    //setLoading(true);
    const instanceUrl = credential.instanceUrl;
    const token = credential.token;
    var req_body = { fields: ['Shop_Drawing_Type__c'] };
    var valuessss;
    await axios.post(`${fetchUrl}/picklist/valueObj/Amazon_S3_Attachment__c?token=${token}&instanceUrl=${instanceUrl}`, req_body)
      .then((res) => {
        if (res?.status === 200) {
          let retData = res.data;
          if (retData?.pickObj?.Shop_Drawing_Type__c?.valueList) {
            valuessss = retData?.pickObj?.Shop_Drawing_Type__c?.valueList;
          }
        }
      }).catch((err) => {
        console.log(err);
      });
      var setValues = [];
      axios.get(`${fetchUrl}/sets/${credential?.projectId}?token=${token}&instanceUrl=${instanceUrl}`)
        .then((res) => {
          if (res?.status === 200) {
            res?.data?.records?.map((val, index) => {
              if (val?.Issuance_Date__c !== null) {
                setValues.push({ value: val?.Id, label: val?.Name, date: moment(val?.Issuance_Date__c).format("MMM DD YYYY") });
              }
            });
            console.log("setValues ::", setValues);
            setSetOptions(setValues);
          }
        }).catch((err) => {
          console.log(err);
          setSetOptions(setValues);
        });
    setShopDrawingTypes(valuessss);
  }
  const onSelection = (folder) => {
    if (folder.type == "file") {
      setSelectedFile(folder);
      setSelectedFileId(folder.id);
    } else {
      setSelectedFile([]);
      setSelectedFileId([]);
      onSelectFolder(folder);
    }
  };
  const handleSeletedSheet = (id) => {
    setSelectedFileId(id);
   let selectedFiles = sheetsFiles?.filter((tem) => tem.id == id);
   setSelectedFile(selectedFiles[0]);
  };
  const onSelectFolder = async (curFolder) => {
    setSpinner(true);
    const curFolderTree = _getNodeChildren(allData, curFolder.Id ? curFolder.Id : curFolder.id);
    setCurrentFolderTree(curFolderTree);
    const nodePath = findFolderPath(allData, curFolder.Id ? curFolder.Id : curFolder.id, [], true);
    setSelectedNode(nodePath);
    _getFolderFileApi(curFolder.Id ? curFolder.Id : curFolder.id);
  };
  async function searchListViewFunction(searchTerm) {
    setSearchBoxFilter(false);
    setSearchText(searchTerm);
    let orgData = filterSheetList.length > 0 ? cloneDeep(filterSheetList) : cloneDeep(currentSheetList.current);
    let searchFoundList = [];
    if (orgData?.length > 0) {
        if (searchTerm && searchTerm !== null && searchTerm !== "") {
            searchFoundList = [];
            for (let el of orgData) {
                if ((el.name && el.name.toLowerCase().includes(searchTerm))) {
                    searchFoundList.push(el);
                }
            }
            setSearchBoxFilter(true);
        } else {
            searchFoundList = filterSheetList.length > 0 ? cloneDeep(filterSheetList) : cloneDeep(currentSheetList.current);
            setSheetsFiles(searchFoundList);
        }
    }
    setSheetsFiles(searchFoundList);
}
  async function handleFilter(searchTerm) { 
    let allSheets = cloneDeep(currentSheetList.current);
    if(sheetType == "Contract Drawings"){
      if(searchTerm !="All"){ 
      let filterList = allSheets?.filter((tem) => tem.set == searchTerm);
      setSheetsFiles(filterList);
      setFilterSheetList(filterList);
      }else{
        setSheetsFiles(allSheets);
        setFilterSheetList(allSheets);
      }
  }else{
    if(searchTerm !="All"){ 
    let filterList = allSheets?.filter((tem) => tem.shopDrawing == searchTerm);
    setSheetsFiles(filterList);
    setFilterSheetList(filterList);
    }else{
      setSheetsFiles(allSheets);
      setFilterSheetList(allSheets);
    }
  }
  }
  function CustomNoRowsOverlay() {
    return (

      <div style={{ height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>{!searchBoxFilter ? "No Rows" : "None of your sheets matched this search"}</div>
    );
  }
  const columnsContract = [
    { field: 'name', headerName: 'Number', width: 265, },
    { field: 'set', headerName: 'Set Name', width: 260, },
  ];
  const columnsShop = [
    { field: 'name', headerName: 'Number', width: 265, },
    { field: 'shopDrawing', headerName: 'Type', width: 260, },
  ];
  return (
    <Dialog open={true} fullWidth>
      <DialogTitle
        className="dialog_title"
        style={{ padding: "16px 20px" }}
      >
        {linkDocument === "File" ? "Select File" : "Select Sheet"}
        <IconButton
          aria-label="close"
          onClick={onCancel}
          sx={{ position: "absolute", right: 12 }}
        >
          <img src={CloseIcon} alt="icons" />
        </IconButton>
      </DialogTitle>
      {linkDocument === "File" &&
        <DialogContent
          className="dialog_content"
          style={{ padding: "0px" }}
        >
          <div style={{ padding: '16px 20px' }}>
            <BreadcrubFolderView
              selectedNode={selectedNode}
              onSelection={onSelection}
            />
          </div>
          <ul className="children-list">
            {showSpinner && <Spinner />}
            {folderFileList && folderFileList.length > 0 && folderFileList.map((value, index) => {
              return <li className="child-item" style={{ backgroundColor: selectedFileId && selectedFileId.includes(value.id) && "#EAEAEA" }} onClick={() => onSelection(value)} key={index}>
                <img src={value.type == "folder" ? folderIcon : pdfRed} alt="folicon"></img>
                {value.name}
              </li>
            })}
          </ul>
        </DialogContent>
      }
      {linkDocument === "Sheet" &&
        <DialogContent
          className="dialog_content"
          style={{ padding: "16px 20px" }}
        >
          {showSpinner && <Spinner />}
          <div style={{ display: "flex", gap: "12px", paddingTop: "10px" }}>
            <div>
              <TextField sx={{ "& .MuiInputBase-root": { color: "#505050", height: "37px", padding: "4px", width: "290px", }, }}
                inputProps={{ maxLength: 2000 }}
                id="outlined-basic"
                variant="outlined"
                className="Searchbar-field"
                autoComplete="off"
                required
                placeholder="Search Sheets"
                value={searchText}
                onChange={(e) => {
                  //convert input text to lower case
                  var lowerCase = e.target.value.toLowerCase();
                  if (!lowerCase || lowerCase === null || lowerCase === "") {
                    searchListViewFunction(lowerCase);
                  }
                  setSearchText(lowerCase);
                }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    // Do code here
                    e.preventDefault();
                    var lowerCase = e.target.value.toLowerCase();
                    //console.log('searchText ',searchText , 'lowerCase',lowerCase);
                    searchListViewFunction(searchText);
                  }
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      {searchBoxFilter ? (
                        <img
                          style={{ width: "25px", cursor: "pointer" }}
                          src="/cancel.svg"
                          alt="close icon"
                          onClick={() => {
                            searchListViewFunction("");
                            setSearchText("");
                          }}
                        />
                      ) : (
                        <Search />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div>
            {sheetType == "Contract Drawings" ? (
              <Select id="type" style={{ height: "37px", width: "120px", color: "#505050" }} placeholder="Type"
              value={setName}
              onChange={(event) => {
                setSetName(event.target.value);
                handleFilter(event.target.value);
             }}
              >
                <MenuItem key={"All"} value="All">
                 All
                </MenuItem>

                {setOptions &&
                  setOptions.map((opt, index) => (
                    <MenuItem key={opt.label} value={opt.label}>{opt.label}</MenuItem>
                  ))
                }
              </Select>
              ) : (
                <Select id="type" style={{ height: "37px", width: "120px", color: "#505050" }} placeholder="Type"
              value={shopTypeSingle}
              onChange={(event) => {
                setShopTypeSingle(event.target.value);
                handleFilter(event.target.value);
             }}
              >
                <MenuItem key={"All"} value="All">
                 All
                </MenuItem>

                {shopDrawingTypes &&
                  shopDrawingTypes.map((opt, index) => (
                    <MenuItem key={opt} value={opt}>{opt}</MenuItem>
                  ))
                }
              </Select>
              )}
            </div>
            <div>
              <Select id="type" style={{ height: "37px", width: "120px", color: "#505050" }}
               value={sheetType}
               onChange={(event) => {
                setSheetType(event?.target?.value);
                _getFileApi(event?.target?.value)
              }}
              >
                <MenuItem key={'Contract Drawings'} value={'Contract Drawings'}>{'Contract Drawings'}</MenuItem>
                <MenuItem key={'Shop Drawings'} value={'Shop Drawings'}>{'Shop Drawings'}</MenuItem>
              </Select>
            </div>
          </div>
          <Box className="template_data_grid" style={{ height: "416px", width: "555px", paddingLeft: "10px" , paddingTop: "10px"}}>
            <DataGrid
              slots={{
                noRowsOverlay: CustomNoRowsOverlay,
              }}
              rows={sheetsFiles ? sheetsFiles : []}
              columns={sheetType == "Contract Drawings" ? columnsContract : columnsShop}
              hideFooter
              disableColumnMenu
              sortingMode
              rowSelectionModel={selectedPDFRowIds}
              onRowSelectionModelChange={(newSelectionIds) => {
                handleSeletedSheet(newSelectionIds[0]);
                setSetSelectedPDFRowIds(newSelectionIds);
              }}
            />
          </Box>
        </DialogContent>
      }
      <DialogActions
        className="action_button"
        style={{ padding: "16px 20px" }}
      >
        <Button
          className="custom_button cancel_button"
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          className="save_settings"
          style={{ height: "39px", width: "122px" }}
          disabled={selectedFileId < 1}
          onClick={() => onAccept(selectedFile)}
        >
          Select Sheet
        </Button>
      </DialogActions>
    </Dialog>
  );
};