import React, { useEffect, useState } from "react";
import { Dialog, DialogTitle, DialogContent, IconButton, TextField, Divider, Button, DialogActions } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Autocomplete from "@mui/material/Autocomplete";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import CropSquareIcon from "@mui/icons-material/CropSquare";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Folder } from "@mui/icons-material";
import ReviewStyle from "./Styles/ReviewStyle.style";
import axios from "axios";
import { fetchUrl } from "Urls";
import Spinner from "components/spinner";
import styled from "styled-components";
import ViewReviewDetails from "components/ReviewTab/ViewReviewDetails";

const ReviewModel = ({ open, handleClose, ...props }) => {
  const { credential, workflowTemplateOptions } = props;
  const [overView, setOverView] = useState(true);
  const [noteOpen, setNoteOpen] = useState(false);
  const [document, setDocument] = useState(true);
  const [selectedDocument, setSelectedDocument] = useState(props?.selectedFiles);

  const [newReview, setNewReview] = useState();
  const [name, setName] = useState(null);
  const [notes, setNotes] = useState(null);
  const [sfObj, setsfObj] = React.useState(null);
  const [workFlowTemp, setWorkFlowTemp] = useState({});
  const [spinner, setSpinner] = useState(false);
  const [stepName, setStepName] = useState();
  const [workFlow, setWorkFlow] = useState();
  const [stepTeamError, setStepTeamError] = useState(false);
  const [templateFetching, setTemplateFetching] = useState(false);
  useEffect(() => {
    setVariables({ Name: '', Notes__c: '', Project__c: credential.projectId, Stage__c: "Open" });
  }, []);
  const onClickSave = async () => {
    //console.log(" sfObj :: ",JSON.stringify(sfObj));
    //console.log(" onClickSave_ __sfObj  :: ",JSON.stringify(sectionCurrentListWithTeam));
    if (stepTeamError) {
      return;
    }
    let toSave = JSON.parse(JSON.stringify(sfObj)); let process = true;
    if (toSave.Workflow_Template__r) {
      delete toSave.Workflow_Template__r;
    }
    let documentIds = [];
    for (let doc of selectedDocument) {
      documentIds.push(doc.id);
    }
    process = documentIds.length > 0;
    console.log(" toSave :: ", JSON.stringify(toSave));
    //console.log("onClickSaveChecklist called process :: " + process);
    if (process && credential?.token) { //process
      setSpinner(true);
      const sendObj = { reviewObj: toSave, documentIds: documentIds, projectId: credential?.projectId };
      if (true) {
        await axios.post(`${fetchUrl}/submitDocForReview?token=${credential?.token}&instanceUrl=${credential?.instanceUrl}`, sendObj)
          .then(async (res) => {
            //console.log("count:::: " + JSON.stringify(res));
            if (res.data?.status === 200) {
              toSave.Id = res.data.reviewId;
              setVariables(toSave);
              setNewReview(res.data.reviewId);
              //handleClose(true);
            }
          }).catch((err) => {
            console.log(err);
          });
        setSpinner(false);
      }
    }
  }
  function setVariables(_sfObj) {
    // need to set it to variables
    setName(_sfObj.Name);
    setNotes(_sfObj.Notes__c);
    setWorkFlowTemp({
      label: _sfObj?.Workflow_Template__c ? _sfObj?.Workflow_Template__r?.Name : "",
      value: _sfObj?.Workflow_Template__c,
    });
    setsfObj(_sfObj);
  }

  const Spinner = styled.div`
  .loader {
    border: 2px solid #f3f3f3; /* Light grey */
    border-top: 2px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  `;

  return (
    <>
      {newReview ?
        <Dialog open={open}
          style={{ height: "1000px", marginTop: "115px" }}
          fullScreen
        >
          <ViewReviewDetails
            credential={credential}
            reviewId={newReview}
            userList={credential?.userList}
            handleClose={(refresh) => {
              handleClose(true);
            }}
          ></ViewReviewDetails>

        </Dialog>
        :
        <Dialog
          open={sfObj ? true : false}
          onClose={handleClose}
          aria-labelledby="share-dialog-title"
          aria-describedby="share-dialog-description"
          fullWidth
          maxWidth="md"
        >
          <ReviewStyle>
            <DialogTitle id="share-dialog-title">
              Submit for review
              <IconButton aria-label="close" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent dividers>
              <div className="review_main">
                <div style={{ display: "flex" }}>
                  <div style={{ width: "70%" }}>
                    <label>Approval workflow</label>
                    <Autocomplete
                      required
                      value={workFlowTemp}
                      onChange={(event, newValue) => {
                        if (newValue?.value) {
                          setTemplateFetching(true);
                          axios
                            .get(`${fetchUrl}/workflowDetail/${newValue?.value}?projectId=${credential?.projectId}&token=${credential?.token}&instanceUrl=${credential?.instanceUrl}`)
                            .then((res) => {
                              //console.log("count:::: " + JSON.stringify(res));
                              if (res.status === 200) {
                                console.log("res.data.workflowObj ::", res.data.steps);
                                //{formObj : cObj , docDetail : docReturn}
                                if (res.data?.workflowObj) {
                                  setWorkFlow(res.data?.workflowObj);
                                  if (res.data.steps) {
                                    setStepName(res.data.steps);
                                    let steps = res.data.steps;
                                    let stepTeamError = false;
                                    if (steps) {
                                      for (let step of steps) {
                                        if (!step.teams || step.teams.length == 0) {
                                          stepTeamError = true;
                                          break;
                                        }
                                      }
                                    }
                                    setStepTeamError(stepTeamError);
                                  }
                                }
                              }
                              setTemplateFetching(false);
                            }).catch((err) => {
                              setTemplateFetching(false);
                              console.log(err);
                            });
                        }else{
                          setStepTeamError(false);
                        }
                        let fff = sfObj;
                        fff.Workflow_Template__c = newValue?.value;
                        fff.Workflow_Template__r = {
                          Id: newValue?.value,
                          Name: newValue?.label
                        };
                        setVariables(fff);
                      }}
                      id="country-select-demo"
                      sx={{ width: 300 }}
                      options={workflowTemplateOptions}
                      autoHighlight
                      getOptionLabel={(option) => option.label || ""}
                      renderOption={(props, option) => (
                        <div {...props} className="share-menu-item">
                          <div className="share-menu-item-text">
                            {option.label}

                          </div>
                        </div>
                      )}
                      renderInput={(params) => (
                        <TextField
                          fullWidth
                          id="autocomplete"
                          error={stepTeamError}
                          helperText={stepTeamError ? 'One or more Workflow step dont have team.' : ''}
                          {...params}
                        />
                      )}
                    />
                  </div>

                  {workFlow && !templateFetching &&
                    <div style={{ marginLeft: "30px", marginTop: "20px" }}>
                      <h1>Discrption :-</h1>
                      <b>{workFlow.Description__c}</b>
                    </div>
                  }
                </div>
                {templateFetching &&
                  <Spinner>
                    <div class="loader"></div>
                  </Spinner>
                }
                {stepName && !templateFetching &&
                  <table style={{ width: "400px" }}>
                    {stepName.map(Step => (
                      <tr style={{ display: "flex" }}>
                        <tr>
                          <td>{Step.Name} - </td>
                        </tr>
                        {Step.teams.map(label => (
                          <tr style={{ marginLeft: "5px" }}>
                            <th> {label.label}</th>
                          </tr>
                        ))}
                      </tr>

                    ))}
                  </table>
                }

                {false/*value !== "" && value !== null*/ && (
                  <div>
                    <div
                      onClick={() => setOverView(!overView)}
                      className="dropdown"
                    >
                      Overview
                      {overView ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )}
                    </div>
                    {overView && (
                      <div className="drop_down">
                        <div className="drop_icons">
                          <RadioButtonUncheckedIcon />
                          <span className="arrow_right"></span>
                          <CropSquareIcon />
                          <span className="arrow_right"></span>
                          <RadioButtonUncheckedIcon />
                        </div>
                        <div>
                          <div className="Heading_de">Description</div>
                          <div>One Step approval </div>
                          <div className="Heading_de">Action upon Completion </div>
                          <div>Update Document Review Status</div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>

              <Divider />
              <div className="review_main">
                <div className="label_field">
                  <label>Review Name</label>
                  <TextField
                    id="title"
                    required
                    value={name}
                    fullWidth
                    onChange={(e) => {
                      let fff = sfObj;
                      fff.Name = e?.target?.value;
                      setVariables(fff);
                    }}
                    onKeyDown={(event) => {
                      if (event.key.toLowerCase() != "tab") {
                        event.stopPropagation();
                      }
                    }}
                  />
                </div>
                <div>
                  <label>Documents for Review</label>
                  {/*<Button>Add Documents</Button>*/}
                </div>
                <div>Total : {selectedDocument?.length} document</div>
                <div onClick={() => { setDocument(!document); }} >
                  <Folder /> {props?.folderName}
                  {document ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </div>

                {document && selectedDocument?.length > 0 && (
                  <>
                    {selectedDocument.map((f, index) => (
                      <div key={f.id}>
                        <img src={f.url} height="25px" width="25px" alt="document" />
                        {' ' + f.name} <HighlightOffIcon />
                        <div>V{f.version}</div>
                      </div>
                    ))}
                  </>
                )}
              </div>
              <div onClick={() => { setNoteOpen(!noteOpen); }} >
                <hr />
                Notes {noteOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </div>
              {noteOpen && (
                <div>
                  <TextField
                    className="common_class"
                    id="Description"
                    value={notes}
                    multiline
                    rows={1}
                    maxRows={3}
                    fullWidth
                    onChange={(e) => {
                      let fff = sfObj;
                      fff.Notes__c = e?.target?.value;
                      setVariables(fff);
                    }}
                    onKeyDown={(event) => {
                      if (event.key.toLowerCase() != "tab") {
                        event.stopPropagation();
                      }
                    }}
                  />
                </div>
              )}
            </DialogContent>
            <DialogActions>
              <Button
                variant="outlined"
                onClick={() => {
                  handleClose();
                }}
              >
                Cancel
              </Button>
              <Button disabled={spinner || templateFetching || stepTeamError} variant="contained" onClick={() => { onClickSave() }} >
                Submit
              </Button>
            </DialogActions>
          </ReviewStyle>
        </Dialog>
      }
    </>
  );
};

export default ReviewModel;
