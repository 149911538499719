import pspdfkit_Imp from 'pspdfkit';
import React, { useState ,useEffect ,useRef, useReducer } from "react";
import { cloneDeep } from 'lodash';
import axios from "axios";
import { fetchUrl } from "Urls";
import { toast } from "react-toastify";
import { Box ,ButtonGroup ,Button, TextField, IconButton,  Grid,Paper, Divider, Checkbox, Tooltip, Autocomplete ,
  Dialog, DialogTitle, DialogContent , DialogActions ,Typography } from "@mui/material";
import { Close , Delete } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import Spinner from "components/spinner";
import moment from 'moment';
import ChecklistItem from './ChecklistItem';

const checklist_row = {
  Date__c: moment(new Date()).format("YYYY-MM-DD")
};

function CreateMultipleChecklist( {credential, handleClose , ...props}) {
  const [formTemplate, setFormTemplate] = useState(null);
  const [templateOptions , setTemplateOption] = useState([]);
  const [showWarning, enableWarningModal] = useState(false);
  const [showDeleteWarnings, enableDeleteWarningModals] = useState(false);
  const [warningType, setWarningType] = useState(false);

  const [showSpinner, setSpinner] = useState(false);
  const [lineCount, setAddLineCount] = useState(1);

  const [checklistFormDetails, setChecklistFormDetails] = useState([]);
  const [toInsertChecklistRecords, dispatch] = useReducer(reducerF, []);

  var requiredMissing = useRef(null);
  
  useEffect(() => {
    let arr = [];
    //console.log('*********props.templateList ', props.templateList);
    if(props.templateList){
      for(let obj of props.templateList){
        let jObj = JSON.parse(JSON.stringify(obj)) ;
        if(jObj.formType === props.selectedFormType) {
          arr.push({label : jObj.title , value : jObj.id});
        }
      }
    }
    setTemplateOption(arr);
  },[props.templateList]);
  
  useEffect(() => {
    requiredMissing.current = true;
  },[]);
  function reducerF(state, action) {
    //console.log('action.type :: '+action.type);
    switch (action.type) {
      case 'HANDLE_SECTION_REPLACE':
        //replace current state with new state (action.sectionList)
        return action.sectionList;
      case 'HANDLE_REPLACE_ITEM': 
        const tmpSec_ans = cloneDeep(state);
        tmpSec_ans[action.sectionIndex] = action.value;
        return tmpSec_ans;
      default:
        return state;
    }
  }
  const onTemplateChange = async (newValue) => {
    let formTemplateId = newValue?.value ;
    let tmpSec_ans = cloneDeep(toInsertChecklistRecords);
    if(!tmpSec_ans || tmpSec_ans.length == 0){
      tmpSec_ans = [];
    }
    if(tmpSec_ans.length > 0){      
      let toChange = [];
      for(let i = 0 ; i < tmpSec_ans.length ; i++){
        let obj = JSON.parse(JSON.stringify( tmpSec_ans[i]));
        if(formTemplateId && formTemplateId != ''){
          obj.Form_Template__c = formTemplateId ;
          obj.Name = newValue?.label + ' '+(i+1);
        }else{
          obj.Form_Template__c = null;
          obj.Name = null;
        }
        toChange.push(obj);
      }
      dispatch({type: 'HANDLE_SECTION_REPLACE' , sectionList : toChange});
    }
  }
  function addLinesClick(count) {
    if(formTemplate && formTemplate.value && formTemplate.value != ''){
      let tmpSec_ans = cloneDeep(toInsertChecklistRecords);
      if(!tmpSec_ans || tmpSec_ans.length == 0){
        tmpSec_ans = [];
      }
      let k = (tmpSec_ans.length + 1);
      for(let i = 0 ; i < count ; i++){
        let obj = JSON.parse(JSON.stringify( checklist_row));
        obj.Name = formTemplate.label + ' '+k;
        obj.Project__c = credential.projectId;
        obj.Form_Template__c = formTemplate.value;
        tmpSec_ans.push(obj);
        k++;
      }
      dispatch({type: 'HANDLE_SECTION_REPLACE' , sectionList : tmpSec_ans});
    }
  }
  async function onSaveClick() {
    setSpinner(true);
    const formData = new FormData();
    //let issueObj = sfIssueObj;
    formData.append("recLists", JSON.stringify(toInsertChecklistRecords));
    await axios.post(`${fetchUrl}/recSave/list/Form__c?token=${credential?.token}&instanceUrl=${credential?.instanceUrl}`,formData)
    .then((res) => {
      //console.log("count:::: " + JSON.stringify(res));
      if(res.status === 200){
        if(toInsertChecklistRecords.length === 1){
          handleClose(true , res.data.firstInsertId);
        }else{
          handleClose(true);
        }
      }
    }).catch((err) => {
      console.log(err);
    });
    setSpinner(false);
  }
  return (
    <>
      <Dialog fullWidth maxWidth scroll="paper" open={true}>
        <DialogTitle>
          <Typography>
            Create Checklist
          </Typography>
          <IconButton aria-label="close" onClick={handleClose}>
            <Close/>
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ height: 500, padding: "20px", position: "relative" }}>
          {showSpinner && <Spinner />}
          <Grid container justifyContent="space-evenly" alignItems="center" spacing={2} >
            <Grid item xs={3}>
              {templateOptions && templateOptions.length > 0 &&
                <Autocomplete
                  disablePortal
                  className="inlineAutocomplete"
                  value={formTemplate}
                  getOptionLabel={(option) => option?.label ? option?.label : ''}
                  onChange={(event, newValue) => {
                    setFormTemplate(newValue);
                    onTemplateChange(newValue);
                  }}
                  label="Checklist Template"
                  options={templateOptions}
                  renderInput={(params) => (
                    <div>
                      <TextField id="formTemplate" variant="outlined" label="Checklist Template" required {...params} />
                    </div>
                  )}
                />
              }
            </Grid>
            <Grid item xs={3}>
              <TextField fullWidth name="lineCount" id="lineCount" type="number" label="Number of Checklist"
                variant="outlined" size="small" value={lineCount}
                onChange={(e) => {
                  setAddLineCount(e?.target?.value);
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <Button variant="contained" onClick={() => { addLinesClick(lineCount) }}>Add Lines</Button>
            </Grid>
          </Grid>
          <Grid container justifyContent="space-evenly" alignItems="center" style={{paddingTop:'15px'}} spacing={2}>
            <Grid item xs={2}></Grid>
            <Grid item xs={3}><strong style={{fontSize:'16px'}}>Name</strong></Grid>
            <Grid item xs={3}><strong style={{fontSize:'16px'}}>Assign To</strong></Grid>
            <Grid item xs={3}><strong style={{fontSize:'16px'}}>Due Date</strong></Grid>
            <Grid item xs={1}></Grid>
            {toInsertChecklistRecords?.length > 0 && (
              <>
                {toInsertChecklistRecords.map((q, index) => (
                  <ChecklistItem item={q} userList={props.userList} index={index} dispatchObj={dispatch}/>
                ))}
              </>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>Cancel</Button>
          <Button variant="contained" onClick={()=>{ onSaveClick() }}>Save</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default CreateMultipleChecklist;
