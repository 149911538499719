import React, { forwardRef } from "react";
import {
  Dialog,
  Button,
  DialogContent,
  DialogTitle,
  Box,
  Slide,
  TextField,
  IconButton,
  TextareaAutosize,
} from "@mui/material";
import { Info, Close } from "@mui/icons-material";
import ExportDialogStyle from "./ExportDialogstyle";
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ExportDialog = (props) => {
  const { title, open, handleClose } = props;

  const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
      <DialogTitle
        sx={{
          m: 0,
          p: 2,
          padding: "20px 25px",
          color: "#819099",
          background: "#f7f9fa",
        }}
        {...other}
      >
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  return (
    <Dialog TransitionComponent={Transition} open={open}>
      <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
        {title}
      </BootstrapDialogTitle>
      <ExportDialogStyle>
        <DialogContent className="model_content_export">
          <Box>
            <Box className="">
              <label>Report Title*</label>
              <Box>
                <TextField
                  id="outlined-basic"
                  defaultValue="Issue Report 2022-09-11"
                  variant="outlined"
                />
              </Box>
            </Box>
            <Box className="">
              <label>Message</label>
              <Box>
                <TextareaAutosize className="textArea_class" />
              </Box>
              <Box className="info-cstm">
                <Info color="info" />
                <span>
                  Your report will be emailed to you as soon as it is ready
                </span>
              </Box>
            </Box>
          </Box>
          <Box className="btn-group">
            <Button autoFocus onClick={handleClose}>
              Cancel
            </Button>
            <Button onClick={handleClose}>Create Report</Button>
          </Box>
        </DialogContent>
      </ExportDialogStyle>
    </Dialog>
  );
};
export default ExportDialog;
