import React, { useEffect, useState } from "react";
import _, { find, findIndex} from "lodash";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import moment from "moment";
import { DatePicker } from "antd";
import { Box, Button, IconButton, InputLabel, MenuItem, Select, 
  TextField, List, ListItem, ListItemText, Tooltip ,
  Drawer } from "@mui/material";
import axios from "axios";
import { fetchUrl } from "Urls";
import SearchIcon from "../../assets/images/search.svg";
import FilterIcon from "../../assets/images/filter.svg";
import SelectAllIcon from "../../assets/images/selectAll.svg";
import CloseIcon from "../../assets/images/close.svg";
import LeftArrowIcon from "../../assets/images/left.svg";
import ResetIcon from "../../assets/images/reset.svg";
import IssueDetailPanelDialog from "./IssueDetailPanel";
import { annoIdField } from "./ShowPdf";
export const save_as_draft_toast = 'Issue {id}has been saved as draft.' ;
export const publish_issue_toast = 'Issue {id}has been published.' ;
export const change_save_issue_toast = 'Changes to Issue has been saved.' ;
export const change_closed_issue_toast = 'Issue has been marked as closed.' ;
export const change_amend_issue_toast = 'Issue {id}status has been changed to Amend Resolution.' ;
export const change_void_issue_toast = 'Issue has been marked as void.' ;
function IssuePanelDialog({ sfIssueList ,...props }) {
  const { attachId , credential ,issueAnnotation, showListPanel , setListPanel, onClickAddNewIssue,setToastMessage,handleIssueChange, handleDeSelectAnn ,handleSelectAnn } = props;

  const [isPublic, setIsPublic] = useState(true);
  const [isSelected, setIsSelected] = useState(false);
  const [checkedItems, setCheckedItems] = useState([]);
  const [deleteMarkupsDialog, setDeleteMarkupsDialog] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [issueDetailPanel, setIssueDetailPanel] = useState(false);
  const [changeIncrement, setChangeIncrement] = useState(0);
  //const [showListPanel, setListPanel] = useState(false);
  const { RangePicker } = DatePicker;

  const [sfRecord, setSfIssueObj] = React.useState(null);
  const [showSpinner, setSpinner] = React.useState(false);

  React.useEffect(() => {
    async function fetchData() {
      let dsfIssue;
      let pushTolist;
      if(issueAnnotation?.Id){
        if (sfIssueList && sfIssueList.length > 0 && issueAnnotation?.Id != null) {
          const temp = JSON.parse(JSON.stringify(sfIssueList));
          dsfIssue = find(temp, { [annoIdField]: issueAnnotation?.Id });
          //console.log('inn :: '+JSON.stringify(dsfIssue));
        }
        if (!dsfIssue) {
          //setListPanel(false);
          if(!sfIssueList){
            sfIssueList = [];
          }
          dsfIssue = getDefaultSFIssueObj();
          if((!dsfIssue.Id || dsfIssue.Id === undefined ) && dsfIssue[annoIdField] && dsfIssue.Linked_Document__c && dsfIssue.Project__c){
            const reqBody = new FormData();
            reqBody.append("recObj", JSON.stringify(dsfIssue));
            await axios.post(`${fetchUrl}/recSave/Issue__c?token=${credential?.token}&instanceUrl=${credential?.instanceUrl}`, reqBody)
            .then(async (res) => {
              if (res?.data?.status == 200) {
                dsfIssue.Id = res.data.retObj.id;
                dsfIssue.Numbering_Sequence__c = await getIssueNumberId(dsfIssue.Id);
                pushTolist = true;
              }
            }).catch((err) => {
              console.log('Issue__c Draft Save ::', err);
            });
          }
          sfIssueList.push(dsfIssue);
        }else{
          //setListPanel(true);
        }
      }else{
        //setListPanel(true);
      }
      if(pushTolist){
        handleIssueChange(sfIssueList , true);
      }
      setVariables(dsfIssue);
      if(dsfIssue){
        setIssueDetailPanel(true);
      }else{
        setIssueDetailPanel(false);
      }
      //console.log('useEffect IssueDialog :: '+JSON.stringify(sfRecord));
    }
    fetchData();
  }, [attachId, issueAnnotation?.Id]);
  function setVariables(_sfObj) {
    //console.log('panel sfRecord :: ',JSON.stringify(sfRecord));
    //console.log('panel _sfObj :: ',JSON.stringify(_sfObj));
    setSfIssueObj(_sfObj);
  }
  function getDefaultSFIssueObj() {
    return {
      Id: null,
      Linked_Document__c: attachId,
      Project__c: credential?.projectId,
      [annoIdField]: issueAnnotation.Id,
      Status__c: credential?.issueObjOptions?.Status__c?.default,
      Type__c: credential?.issueObjOptions?.Type__c?.default,
      Root_Cause__c: credential?.issueObjOptions?.Root_Cause__c?.default,
      OwnerId: credential?.userId,
      Owner: { Id: credential?.userId, Name: credential?.userFullName },
      CreatedById: credential?.userId,
      CreatedBy: { Id: credential?.userId, Name: credential?.userFullName },
      Due_Date__c: moment(new Date()).format("YYYY-MM-DD"),
      CreatedDate: moment(new Date()).format("YYYY-MM-DD"),
      Ball_in_Court__c : credential?.userId,
      Ball_in_Court__r : {Id:credential?.userId ,Name:credential?.userFullName }
    };
  }
  
  const handleFilterMarkup = () => {
    setIsFiltered(!isFiltered);
  };
  const handleSearch = () => {
    setIsSearch(!isSearch);
    setSearchValue("");
  };

  const handlePublicPrivateToggle = () => {
    setIsPublic(!isPublic);
    setDeleteMarkupsDialog(true);
  };

  const handleSelectAll = () => {
    setIsSelected(false);
  };
  const handleCheckboxSelection = (value) => {
    if (checkedItems.includes(value)) {
      setCheckedItems(checkedItems.filter((item) => item !== value));
    } else {
      setCheckedItems([value]);
    }
  };
  const [filteredData, setFilteredData] = useState(sfIssueList);
  const handleSearchIssues = (event) => {
    const query = event.target.value;
    setSearchValue(query);

    if (sfIssueList && sfIssueList.length > 0 ) {
      const searchList = sfIssueList.filter((item) => {
        const IssueName =item.Name?.toLowerCase().indexOf(query.toLowerCase()) !== -1;
        const IssueType =item.Description__c?.toLowerCase().indexOf(query.toLowerCase()) !== -1;
        return IssueName || IssueType;
      });
      setFilteredData(searchList);
      return;
    }
    setFilteredData([]);

  };
  useEffect(() => {
    //setFilteredData(sfIssueList);
  }, [checkedItems]);
  useEffect(() => {
    //console.log(`useEffect sfIssueList :: ${JSON.stringify(sfIssueList)}`);
    setFilteredData(sfIssueList);
  }, [sfIssueList]);
  async function onClickSave(issueObj , info) {
    //setSpinner(true);
    issueObj["Project__c"] = credential?.projectId
    const formData = new FormData();
    //let issueObj = sfIssueObj;
    formData.append("issueObj", JSON.stringify(issueObj));
    await axios.post( `${fetchUrl}/saveIssue/${credential?.projectId}?token=${credential?.token}&instanceUrl=${credential?.instanceUrl}`, formData)
      .then(async (res) => {
        if (res.status === 200) {
          issueObj.Id = res.data.id;
          if(!issueObj.Numbering_Sequence__c){
            issueObj.Numbering_Sequence__c = await getIssueNumberId(issueObj.Id);
          }
          //console.log('issueObj ::',JSON.stringify(issueObj));
          setIssueDetailPanel(false);
          setVariables(issueObj);
          setIssueDetailPanel(true);
          let foundInd = -1;
          if (!sfIssueList) {
            sfIssueList = [];
          }
          if (  sfIssueList && sfIssueList.length > 0 && issueObj[annoIdField] != null ) {
            const temp = JSON.parse(JSON.stringify(sfIssueList));
            foundInd = findIndex(temp, {[annoIdField]: issueObj[annoIdField],});
          }
          if (foundInd > -1) {
            sfIssueList[foundInd] = issueObj;
          } else {
            issueObj.CreatedBy = {
              Id: credential?.userId,
              Name: credential?.userFullName,
            };
            issueObj.LastModifiedBy = issueObj.CreatedBy;
            issueObj.LastModifiedDate = new Date();
            issueObj.CreatedDate = new Date();
            sfIssueList.push(issueObj);
          }
          setChangeIncrement((pre)=>pre++);
          await handleIssueChange(sfIssueList , true);
          setVariables(issueObj);
          if(info?.saveAs){
            let tM ;
            if(info?.saveAs == 'Owner Draft'){
              tM = save_as_draft_toast ;
            }else if(info?.saveAs == 'Owner Publish'){
              tM = publish_issue_toast ;
            }else if(info?.saveAs == 'BIC RSubmit'){
              tM = "Resolution Submitted";
            }else if(info?.saveAs == 'Owner Save'){
              tM = change_save_issue_toast;
            }else if(info?.saveAs == 'Owner Closed Issue'){
              tM = change_closed_issue_toast;
            }else if(info?.saveAs == 'Owner Amend Resolution'){
              tM = change_amend_issue_toast;
            }else if(info?.saveAs == 'Owner Void'){
              tM = change_void_issue_toast;
            }
            if(tM){
              let idReplace = issueObj.Numbering_Sequence__c !=null ? `ID #${issueObj.Numbering_Sequence__c} ` : '';
              tM = tM.replace('{id}',idReplace);
              setToastMessage(tM);
            }
          }
        }
      })
      .catch((error) => {
        setSpinner(false);
        console.log("error >>>>>>>>>>>>", JSON.stringify(error));
        console.error(error);
      });
    setSpinner(false);
  }
  async function getIssueNumberId(recId){
    const reqBody = {fields : ['Numbering_Sequence__c'] , filter: `Id='${recId}'` };
    return await axios.post(`${fetchUrl}/query/Issue__c?token=${credential?.token}&instanceUrl=${credential?.instanceUrl}`, reqBody)
    .then(async (res) => {
      if (res.status === 200) {
        const re_data = res.data;
        if (re_data.status == 200) {
          return re_data.records[0].Numbering_Sequence__c;
        }
      }
      return undefined;
    }).catch(e => {
      console.log("error >>>>>>>>>>>>", JSON.stringify(e));
      console.error(e);
      return undefined;
    });
  }
  
  return<>
  {showListPanel &&
    <Drawer className={"drawer_Open"} anchor="left" open={true} 
      variant="persistent" ModalProps={{ BackdropProps: { invisible: true, } }}>
      {!isFiltered ? (
        <div>
          <div className="markups_head">
            <Box className="markup_title">Issues</Box>
            <IconButton sx={{ borderRadius: 0 }} onClick={() => setListPanel(false)}>
              <img src={CloseIcon} alt="close_icon" />
            </IconButton>
          </div>
          <div className={`markup_list`}>
            {isSearch ? (
              <TextField
                className="textfff Searchbar-field"
                sx={{
                  "& .MuiInputBase-root": {
                    color: "#505050",
                  },
                  marginLeft: "20px",
                  marginRight: "20px",
                  width: "360px",
                }}
                placeholder="Search"
                type="text"
                variant="outlined"
                fullWidth
                size="small"
                onChange={(e) => handleSearchIssues(e)}
                value={searchValue}
                InputProps={{
                  endAdornment: (
                    <IconButton onClick={() => handleSearch()}>
                      <img src={CloseIcon} alt="close_icon" />
                    </IconButton>
                  ),
                }}
              />
            ) : (
              <div className="markups_subhead">
                <Box>{filteredData?.length} Issues</Box>
                <div>
                  <Tooltip title="Search">
                    <IconButton onClick={handleSearch} sx={{ borderRadius: 0 }}>
                      <img src={SearchIcon} alt="search_icon" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Selection">
                    <IconButton sx={{ background: isSelected && "#E8EFFF", borderRadius: 0 }} onClick={handleSelectAll} >
                      <img src={SelectAllIcon} alt="select_all_icon" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Filter">
                    <IconButton onClick={handleFilterMarkup} sx={{ borderRadius: 0 }}>
                      <img src={FilterIcon} alt="filter_icon" />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
            )}
            <List className="issue_main_ul">
              {filteredData?.map((issueItem, index) => {
                return (
                  <div key={`${issueItem.Id} issue list ${index}`}>
                    {(issueItem.Status__c != 'Draft' || issueItem.CreatedById === credential?.userId) &&
                      <ListItem id={issueItem.Id}
                        className={`issue_list ${(issueItem[annoIdField] == issueAnnotation?.Id && issueAnnotation?.Id) ? "checkbox_selected" : ''}`}
                        onClick={() => {
                          console.log('Issue Click :: ' + issueItem[annoIdField], issueAnnotation?.Id);
                          handleCheckboxSelection(issueItem.Id);
                          if ((issueItem[annoIdField] != issueAnnotation?.Id || !issueDetailPanel) && issueItem[annoIdField]) {
                            if (issueItem[annoIdField] != issueAnnotation?.Id) {
                              handleSelectAnn(issueItem[annoIdField]);
                            } else {
                              setIssueDetailPanel(true);
                            }
                          } else if (issueItem[annoIdField]) {
                            handleDeSelectAnn(issueItem[annoIdField]);
                          }
                        }}
                      >
                        <div className="issue_status">
                          <span className={`status ${issueItem.Status__c}`}>
                            {issueItem.Status__c}
                          </span>
                        </div>
                        <ListItemText
                          primary={issueItem.Name}
                          secondary={issueItem.Description__c}
                        />
                        <Box className="assigned_date">
                          <Box>
                            <img
                              src="/Ellipse 2.svg"
                              alt="avatar"
                              height={16}
                              width={16}
                            />
                            <span className="username">{issueItem.CreatedBy?.Name}</span>
                          </Box>
                          <Box>
                            {issueItem.dateIcon !== null && (
                              <img
                                src="due date.svg"
                                alt="dateIcon"
                                height={12}
                                width={12}
                              />
                            )}
                            <span className="username">{moment(issueItem.CreatedDate).format("YYYY-MM-DD")}</span>
                          </Box>
                        </Box>
                      </ListItem>
                    }
                  </div>
                )
              })}
            </List>
          </div>
          <Box className="action_button issue_buttons">
            <Button className="custom_button apply_button" style={{width: "380px"}} onClick={onClickAddNewIssue}>Add Issue</Button>
          </Box>
        </div>
      ) : (
        <div>
          <div className="markups_head">
            <Box className="filter_title">
              <IconButton sx={{ borderRadius: 0 }} onClick={handleFilterMarkup}>
              <img style = {{height: "21px", width: "25px"}} src="/navigate previous.svg" alt="left_arrow_icon" />
              </IconButton>
              <Box className="markup_title" style={{paddingTop: "8px"}}>Filter Issues</Box>
            </Box>
            <Box className="filter_icons">
            <Tooltip title="Reset Filter" placement="bottom">
              <IconButton
                sx={{ borderRadius: 0 }}>
                <img src={ResetIcon} alt="icons" />
              </IconButton>
              </Tooltip>
              <IconButton sx={{borderRadius: 0}} onClick={() => setListPanel(false)}>
                <img src={CloseIcon} alt="close_icon" />
              </IconButton>
            </Box>
          </div>
          <div className="form_group">
            <InputLabel>Issue Type</InputLabel>
            <Select className="created_by" value={"select"}>
              <MenuItem value="select">
                <em>Select</em>
              </MenuItem>
              {credential?.issueObjOptions?.Type__c?.options  &&
                credential?.issueObjOptions?.Type__c?.options.map((opt, index) => (
                  <MenuItem key={opt.value} value={opt.value}>{opt.label}</MenuItem>
                ))
              }
            </Select>
          </div>
          <div className="form_group">
            <InputLabel>Status</InputLabel>
            <Select className="created_by" value={"select"}>
              <MenuItem value="select">
                <em>Select</em>
              </MenuItem>
              {credential?.issueObjOptions?.Status__c?.options  &&
                credential?.issueObjOptions?.Status__c?.options.map((opt, index) => (
                  <MenuItem key={opt.value} value={opt.value}>{opt.label}</MenuItem>
                ))
              }
            </Select>
          </div>
          <div className="form_group">
            <InputLabel>Assigned To</InputLabel>
            <Select className="created_by" value={"select"}>
              <MenuItem value="select">
                <em>Select</em>
              </MenuItem>
              {credential?.userList  &&
                credential?.userList.map((opt, index) => (
                  <MenuItem key={opt.value} value={opt.value} data-label={opt.label}>{opt.label}</MenuItem>
                ))
              }
            </Select>
          </div>
          <div className="form_group">
            <InputLabel>Due Date Range</InputLabel>
            <RangePicker size={"large"} />
          </div>
          <div className="form_group">
            <InputLabel>Root Cause</InputLabel>
            <Select className="created_by" value={"select"}>
              <MenuItem value="select">
                <em>Select</em>
              </MenuItem>
              {credential?.issueObjOptions?.Root_Cause__c?.options  &&
                credential?.issueObjOptions?.Root_Cause__c?.options.map((opt, index) => (
                  <MenuItem key={opt.value} value={opt.value}>{opt.label}</MenuItem>
                ))
              }
            </Select>
          </div>
          <div className="form_group">
            <InputLabel>Created By</InputLabel>
            <Select className="created_by" value={"select"}>
              <MenuItem value="select">
                <em>Select</em>
              </MenuItem>
              {credential?.userList  &&
                credential?.userList.map((opt, index) => (
                  <MenuItem key={opt.value} value={opt.value}>{opt.label}</MenuItem>
                ))
              }
            </Select>
          </div>
          <Box className="action_button issue_filter_buttons">
            <Button className="custom_button cancel_button" onClick={handleFilterMarkup}>
              Cancel
            </Button>
            <Button className="custom_button apply_button" onClick={handleFilterMarkup}>
              Apply Filters
            </Button>
          </Box>
        </div>
      )}
    </Drawer>
  }
  {issueAnnotation?.Id && sfRecord && issueDetailPanel && (
    <IssueDetailPanelDialog
      sfObj={sfRecord}
      credential={credential}
      saveCallback={(sf, info) => {
        //console.log('issue :: ', JSON.stringify(sf), JSON.stringify(info));
        onClickSave(sf, info);
      }}
      closeCallBack={(annId) => { setIssueDetailPanel(false); }}
      callFrom="IssuePanel"
    />
  )}
  </> 
}
export default IssuePanelDialog;
