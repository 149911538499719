/* eslint-disable jsx-a11y/img-redundant-alt */
import pspdfkit_Imp from "pspdfkit";
import React, { useState, useEffect, useRef, useReducer } from "react";
import { cloneDeep } from "lodash";
import axios from "axios";
import TextareaAutosize from "@mui/base/TextareaAutosize";
import { fetchUrl } from "Urls";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";
import {
  Box,
  ButtonGroup,
  Button,
  TextField,
  IconButton,
  Grid,
  Paper,
  Divider,
  Checkbox,
  Tooltip,
  Autocomplete,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from "@mui/material";
import { Close, Delete } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import Spinner from "components/spinner";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider, DesktopDatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import jsPDF from "jspdf";
import "jspdf-autotable";

import { FORM_TYPES } from "./constants";
import CheckListForm from "./CheckListForm";
import TemplatePdf from "./Template/TemplatePdf";

const Input = styled("input")({
  display: "none",
});

function CreateProjectForm({
  projectFormId,
  credential,
  handleClose,
  ...props
}) {
  let instance = useRef(null);

  const containerRef = useRef(null);
  //const [saveFile, setSaveFile] = useState(false);
  const [instanceObject, setInstanceObject] = useState(null);
  //const [uploadedFile, setUploadedFile] = useState(null);
  const [s3Url, setS3Url] = useState(null);
  const [statusOptions, setStatusOptions] = useState(props?.statusOptions);

  const [sfObj, setsfObj] = React.useState(defaultObj());
  const [formTemplate, setFormTemplate] = useState(null);
  const [status, setStatus] = useState(null);
  const [templateOptions, setTemplateOption] = useState([]);
  const [assignTo, setAssignTo] = React.useState(null);
  const [formDate, setFormDate] = React.useState(new Date());
  const [showWarning, enableWarningModal] = useState(false);
  const [showDeleteWarnings, enableDeleteWarningModals] = useState(false);
  const [warningType, setWarningType] = useState(false);
  const [addNote, setAddNote] = useState("");
  const [showSpinner, setSpinner] = useState(false);
  const [checklistFormDetails, setChecklistFormDetails] = useState([]);
  const [checklistFormobj, setChecklistFormobj] = useState();
  const [sectionCurrentListWithAns, dispatch] = useReducer(reducerF, []);
  const [formSection, setFormSection] = useState();
  const [formIds, SetFormIds] = useState();
  const [download, setDownload] = useState();

  const refPdf = useRef();
  const doc = new jsPDF(
    'p', 'mm', [750, 1000]
  );
  console.log("propsRaka", props);

  var isFormReadonly = useRef(null);
  var fileTitle = useRef(null);
  var existingFileName = useRef(null);
  var saveFile = useRef(null);
  var docTriggerChanges = useRef(null);
  var uploadedFile = useRef(null);
  var requiredMissing = useRef(null);
  var pdfLoaded = useRef(null);
  var __sfObj = useRef(null);

  const [showCheckSave, setCheckSave] = useState(false);

  useEffect(() => {
    let arr = [];
    console.log("*********props.templateList ", props.templateList);
    if (props.templateList) {
      for (let obj of props.templateList) {
        let jObj = JSON.parse(JSON.stringify(obj));
        if (jObj.formType === props.selectedFormType) {
          arr.push({ label: jObj.title, value: jObj.id });
        }
      }
    }
    setTemplateOption(arr);
  }, [props.templateList]);

  useEffect(() => {
    isFormReadonly.current = false;
    requiredMissing.current = true;
    if (projectFormId) {
      (async () => {
        setSpinner(true);
        await axios
          .get(
            `${fetchUrl}/getProjectFormDetail/${projectFormId}?token=${credential?.token}&instanceUrl=${credential?.instanceUrl}`
          )
          .then((res) => {
            //console.log("count:::: " + JSON.stringify(res));
            if (res.status === 200) {
              //{formObj : cObj , docDetail : docReturn}
              if (res.data?.formObj) {
                isFormReadonly.current =
                  res.data?.formObj.Status__c == "Completed";
                setVariables(res.data.formObj);
                if (res.data.docDetail) {
                  saveFile.current = false;
                  //setSaveFile(false);
                  existingFileName.current = res.data.docDetail.File_Name__c;
                  fileTitle.current = existingFileName.current;
                  //console.log("Form_Type__c:::: " , res.data.formObj.Form_Template__r.Form_Type__c);
                  if (
                    res.data.formObj.Form_Template__r.Form_Type__c ===
                    FORM_TYPES.pdfForm
                  ) {
                    setS3Url(res.data.docDetail.end_url);
                    initLoadPDF(res.data.docDetail.end_url);
                  } else if (
                    res.data.formObj.Form_Template__r.Form_Type__c ===
                    FORM_TYPES.checklistForm
                  ) {
                    console.log("******* checklist ", res.data.formObj);
                    setChecklistFormDetails(res.data.docDetail);
                    setChecklistFormobj(res.data.formObj);
                    dispatch({
                      type: "HANDLE_SECTION_REPLACE",
                      sectionList: res.data.docDetail,
                    });
                  }
                }
                return;
              }
              setVariables(defaultObj());
            }
          })
          .catch((err) => {
            console.log(err);
          });
        setSpinner(false);
      })();
    }
  }, []);
  function reducerF(state, action) {
    //console.log('action.type :: '+action.type);
    switch (action.type) {
      case "HANDLE_SECTION_REPLACE":
        console.log("replace called");
        //replace current state with new state (action.sectionList)
        checklistSaveCheck(action.sectionList);
        return action.sectionList;
      case "HANDLE_CHANGE_ANS_FIELD":
        const tmpSec_ans = cloneDeep(state);
        const qArr_ans = tmpSec_ans[action.sectionIndex].items;
        //console.log('action.value :: ' + action.value);
        //console.log('ans[action.fieldKey] :: ' + qArr_ans[action.questionIndex].ans[action.fieldKey]);
        let jsB = action.value;
        if (
          (!action.value && action.value != 0) ||
          action.value == null ||
          action.value == ""
        ) {
          jsB = null;
        }
        if (
          qArr_ans[action.questionIndex].ans[action.fieldKey] != jsB &&
          qArr_ans[action.questionIndex].ans[action.fieldKey] != action.value
        ) {
          //console.log('go');
          qArr_ans[action.questionIndex].ans[action.fieldKey] = jsB;
          if (!qArr_ans[action.questionIndex].ans.isSaving) {
            qArr_ans[action.questionIndex].ans.isSaving = true;
            //console.log('action.value 3:: ' , tmpSec_ans);
            saveSingleResponse(
              tmpSec_ans,
              action.sectionIndex,
              action.questionIndex
            );
            //console.log('action.value 2:: ' + action.value);
          }
          checklistSaveCheck(tmpSec_ans);
          return tmpSec_ans;
        }
        return tmpSec_ans;
      default:
        return state;
    }
  }

  const saveFormObjOnServer = async (formObj) => {
    const formData = new FormData();
    formData.append('recObj',JSON.stringify(formObj));
    await axios
        .post(
          `${fetchUrl}/recSave/Form__c?token=${credential?.token}&instanceUrl=${credential?.instanceUrl}`,
          formData
        )
    .then(res => {
      if (res.data.status == 200) {
      }
    }).catch(err => {
      console.log(`error /recSave/Form__c`,err);
    });
  };

  async function saveSingleResponse(state, sectionInd, questionInd) {
   // let cloneFormData = JSON.parse(JSON.stringify(state));
   
    if (projectFormId) {
    
      //setSpinner(true);
      const tmpSec_ans = cloneDeep(state);
      const qArr_ans = tmpSec_ans[sectionInd].items;
      qArr_ans[questionInd].ans.Form__c = projectFormId;
      const formData = new FormData();
      //let issueObj = sfIssueObj;
      formData.append("recObj", JSON.stringify(qArr_ans[questionInd].ans));
      await axios
        .post(
          `${fetchUrl}/recSave/Form_Response__c?token=${credential?.token}&instanceUrl=${credential?.instanceUrl}`,
          formData
        )
        .then((res) => {
          //console.log("count:::: " + JSON.stringify(res));
          if (res.data.status == 200) {
            console.log("praveen", tmpSec_ans);
            setFormSection(qArr_ans[questionInd].ans.Form_Section_Line_Item__c);
            SetFormIds(qArr_ans[questionInd].ans.Id);
            qArr_ans[questionInd].ans.Id = res.data.retObj.id;
            qArr_ans[questionInd].ans.isSaving = false;
            dispatch({
              type: "HANDLE_SECTION_REPLACE",
              sectionList: tmpSec_ans,
            });
            console.log("sectionCurrentListWithAns", tmpSec_ans);
          //  console.log("cloneFormData.formObj.Status__c",cloneFormData.formObj.Status__c);
            if (checklistFormobj.Status__c === 'Not Started') {
             if(qArr_ans[questionInd].Optional__c === false) {
                     saveFormObjOnServer({
                    Id: projectFormId,
                    Status__c: "In Progress",
                  });
                }
              }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    //  setSpinner(false);
    } else {
      // need to save project form first
      setTimeout(function () {
        console.log("setTimeout ffff:: ");
        onClickSaveChecklist();
        console.log("setTimeout 2:: ");
      }, 100);
    }
  }
  async function checklistSaveCheck(state) {
    let formFieldStatus = await breakDownChecklistAns(state);
    setCheckSave(!formFieldStatus.reqMissing);
    //requiredMissing.current = formFieldStatus.reqMissing ;
  }
  function defaultObj() {
    //console.log('credential :: '+credential?.userList?.length);
    return {
      Project__c: credential.projectId,
      Status__c: props?.defaultStatus,
      Date__c: moment(new Date()).format("YYYY-MM-DD"),
    };
  }

  //const [requiredMissing, setRequiredMissing] = useState(false);
  function setVariables(_sfObj) {
    setStatus({ label: _sfObj?.Status__c, value: _sfObj?.Status__c });
    setAssignTo({
      label: _sfObj?.Ball_in_Court__c ? _sfObj?.Ball_in_Court__r?.Name : "",
      value: _sfObj?.Ball_in_Court__c,
    });
    setFormTemplate({
      label: _sfObj?.Form_Template__r?.Name,
      value: _sfObj?.Form_Template__c,
    });
    if (_sfObj.Date__c) {
      setFormDate(new Date(_sfObj.Date__c));
    } else {
      setFormDate(null);
    }
    // need to set it to variables
    __sfObj.current = _sfObj;
    requiredMissing.current = !checkReadyToSave(__sfObj.current);
    //setRequiredMissing(!checkReadyToSave(__sfObj.current));
    //console.log('setVariables __sfObj:: '+JSON.stringify(__sfObj));
    setsfObj(_sfObj);
    // setsfObj((prv) => { return {...prv , ..._sfObj} } ); // dosent work
  }
  function checkReadyToSave(_sfObj) {
    //console.log('checkReadyToSave');
    let reqfields = ["Form_Template__c", "Project__c", "Date__c", "Name"];
    if (_sfObj) {
      for (let f of reqfields) {
        if (!_sfObj[f] || _sfObj[f] == "" || _sfObj[f] == null) {
          //console.log('checkReadyToSave :: '+f);
          return false;
        }
      }
    }
    return true;
  }

  const uploadMedia = async (newValue) => {
    let formTemplateId = newValue?.value;
    if (formTemplateId) {
      (async () => {
        setInstanceObject(null);
        setSpinner(true);
        await axios
          .get(
            `${fetchUrl}/getFormTemplate/${formTemplateId}?token=${credential?.token}&instanceUrl=${credential?.instanceUrl}&formId=${projectFormId}`
          )
          .then((res) => {
            //console.log("count:::: " + JSON.stringify(res));
            if (res.status === 200) {
              //{formObj : cObj , docDetail : docReturn}
              if (res.data?.formObj) {
                let fff = __sfObj.current;
                fff.Name = res.data?.formObj.Name;
                setVariables(fff);
                if (res.data.docDetail) {
                  saveFile.current = true;
                  fileTitle.current = res.data.docDetail.File_Name__c;
                  //setS3Url(res.data.docDetail.end_url) ;
                  if (res.data.formObj.Form_Type__c === FORM_TYPES.pdfForm) {
                    initLoadPDF(res.data.docDetail.end_url);
                  } else if (
                    res.data.formObj.Form_Type__c === FORM_TYPES.checklistForm
                  ) {
                    //console.log("******* checklist ", res.data);
                    setChecklistFormDetails(res.data.docDetail);
                    dispatch({
                      type: "HANDLE_SECTION_REPLACE",
                      sectionList: res.data.docDetail,
                    });
                  }
                }
                return;
              }
            }
          })
          .catch((err) => {
            saveFile.current = false;
            fileTitle.current = "";
            console.log(err);
          });
        setSpinner(false);
      })();
    }
    if (
      existingFileName &&
      existingFileName.current &&
      existingFileName.current != ""
    ) {
      fileTitle.current = existingFileName.current;
    }
  };
  const onClickSave = async (
    __instance = instanceObject,
    buttonType = "save"
  ) => {
    if (requiredMissing.current || !pdfLoaded.current) {
      toast.error("Required field/template missing.");
      return;
    }
    setWarningType(buttonType);
    let fileHaveChanges = docTriggerChanges.current; //_instance.hasUnsavedChanges();
    let _instance = __instance ? __instance : instanceObject;
    //console.log('fileHaveChanges :: '+fileHaveChanges);
    let _saveFile = saveFile.current;
    if ((!saveFile || saveFile.current == false) && fileHaveChanges) {
      _saveFile = true;
    }
    let toSave = __sfObj.current;
    /*console.log('fileTitle :: '+fileTitle.current);
    console.log(" onClickSave_ __sfObj  :: "+ JSON.stringify(__sfObj.current));
    console.log(" onClickSave_ sfObj :: "+ JSON.stringify(sfObj));
    console.log(" onClickSave_ toSave  :: "+ JSON.stringify(toSave));
    console.log('verdict amazon save :: '+_saveFile);      */
    let formFieldStatus = await getFormFieldBasedStatus(_instance);
    let status_V = formFieldStatus.cal_status;
    let req_missing = formFieldStatus.reqMissing;
    console.log("status_V 0 :: " + status_V);
    let process = true;
    if (buttonType == "save" || !buttonType) {
      if (status_V == "Completed") {
        status_V = "In Progress";
      }
    }
    console.log("status_V :: " + status_V);
    if (buttonType == "complete" && req_missing) {
      setWarningType("req_missing");
      enableWarningModal(true);
      process = false;
    }
    console.log("process :: " + process);
    if (process) {
      //
      setWarningType(null);
      enableWarningModal(false);
      setSpinner(true);
      await _instance.exportPDF().then(async (buffer) => {
        const blob = new Blob([buffer], { type: "application/pdf" });
        const fName = fileTitle.current;
        toSave.Status__c = status_V;
        const formData = new FormData();
        formData.append("templateFile", blob, fName);
        formData.append("fileTitle", fName);
        formData.append("putOnAmazon", _saveFile);
        formData.append("formRec", JSON.stringify(toSave));
        await axios.post(`${fetchUrl}/saveProjectForm/Form__c`,formData)
          .then((res) => {
            //console.log("count:::: " + JSON.stringify(res));
            if (res.status === 200) {
              handleClose(true);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      });
      setSpinner(false);
    }
  };
  const onClickSaveChecklist = async (buttonType = "save") => {
    if (requiredMissing.current) {
      toast.error("Required field missing.");
      return;
    }
    setWarningType(buttonType);
    let toSave = __sfObj.current;
    /*console.log('fileTitle :: '+fileTitle.current);
    console.log(" onClickSave __sfObj  :: "+ JSON.stringify(__sfObj.current));
    console.log(" onClickSave sfObj :: "+ JSON.stringify(sfObj));
    console.log(" onClickSave toSave  :: "+ JSON.stringify(toSave));*/
    let formFieldStatus = await getAnsBasedStatus();
    let status_V = formFieldStatus.cal_status;
    let req_missing = formFieldStatus.reqMissing;
    let ansList = formFieldStatus.ansList;
    console.log("status_V 0 :: " + status_V);
    let process = true;
    if (buttonType == "save" || !buttonType) {
      if (status_V == "Completed") {
        status_V = "In Progress";
      }
    }
    console.log("status_V :: " + status_V);
    if (buttonType == "complete" && req_missing) {
      setWarningType("req_missing");
      enableWarningModal(true);
      process = false;
    }
    console.log("onClickSaveChecklist called process :: " + process);
    if (process) {
      //
      setWarningType(null);
      enableWarningModal(false);
      setSpinner(true);
      toSave.Status__c = status_V;
      const sendObj = { checkObj: toSave, ansList: ansList };
      if (true) {
        const formData = new FormData();
        //let issueObj = sfIssueObj;
        formData.append("checklistData", JSON.stringify(sendObj));
        await axios
          .post(
            `${fetchUrl}/SaveCheckListForm?token=${credential?.token}&instanceUrl=${credential?.instanceUrl}`,
            formData
          )
          .then((res) => {
            //console.log("count:::: " + JSON.stringify(res));
            if (res.status === 200) {
              toSave.Id = res.data.formId;
              props.setFormId(toSave.Id);
              setVariables(toSave);
              if (buttonType == "save" || buttonType == "complete") {
                handleClose(true);
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
      setSpinner(false);
    }
  };
  async function getFormFieldBasedStatus(_instance = instanceObject) {
    let status_V = "Not Started";
    const formFields = await _instance.getFormFields();
    let pagesAnnotations;
    let totalFieldCount = formFields.size;
    let populatedCount = 0;
    let skipCount = 0;
    let reqCount = 0;
    let reqMissing = false;
    //console.log('totalFieldCount.size :: '+totalFieldCount);
    for (let p = 0; p < totalFieldCount; p++) {
      const cuField = formFields.get(p);
      const cuFieldJs = cuField.toJS();
      const required =
        cuFieldJs.required ||
        cuField instanceof pspdfkit_Imp.FormFields.SignatureFormField;
      let populated = false;
      let skipCheck = !required;
      let value;
      if (required) {
        reqCount++;
      }
      if (cuField instanceof pspdfkit_Imp.FormFields.TextFormField) {
        value = cuFieldJs["value"];
        if (value != null && value != "") {
          populated = true;
        }
      } else if (cuField instanceof pspdfkit_Imp.FormFields.ChoiceFormField) {
        value = cuFieldJs.values[0];
        if (value != null && value.toLowerCase() != "off") {
          populated = true;
        }
      } else if (cuField instanceof pspdfkit_Imp.FormFields.CheckBoxFormField) {
        value = cuFieldJs.values[0];
        if (value == cuFieldJs.options[0].value) {
          populated = true;
        }
      } else if (
        cuField instanceof pspdfkit_Imp.FormFields.RadioButtonFormField
      ) {
        value = cuFieldJs.value;
        if (value != null && value != "") {
          populated = true;
        }
      } else if (cuField instanceof pspdfkit_Imp.FormFields.ListBoxFormField) {
        value = cuFieldJs.values[0];
        if (value == cuFieldJs.options[0].value) {
          populated = true;
        }
      } else if (
        cuField instanceof pspdfkit_Imp.FormFields.SignatureFormField
      ) {
        if (!pagesAnnotations) {
          pagesAnnotations = await Promise.all(
            Array.from({ length: _instance.totalPageCount }).map(
              async (_, pageIndex) => _instance.getAnnotations(pageIndex)
            )
          );
        }
        //console.log('JSON cuFieldJs :: '+JSON.stringify(cuFieldJs));
        const signatureInkAnnotation =
          cuField &&
          (await getSignatureForSignatureField(pagesAnnotations, cuField));
        //console.log(signatureInkAnnotation && signatureInkAnnotation.toJS());
        if (signatureInkAnnotation) {
          populated = true;
        }
      }
      if (!reqMissing && !populated) {
        reqMissing = true;
      }
      //console.log('value :: '+value , 'required ' ,required , 'populated ' , populated);
      if (populated || skipCheck) {
        if (skipCheck) {
          skipCount++;
        }
        populatedCount++;
      }
    }
    //console.log('populatedCount :: '+populatedCount , 'skipCount ' ,skipCount , 'reqCount ' , reqCount);
    if (populatedCount == totalFieldCount) {
      status_V = "Completed";
    } else if (populatedCount == 0 || populatedCount - skipCount == 0) {
      status_V = "Not Started";
    } else if (populatedCount != 0) {
      status_V = "In Progress";
    }
    return { cal_status: status_V, reqMissing: reqMissing };
  }
  async function getAnsBasedStatus() {
    return breakDownChecklistAns(sectionCurrentListWithAns);
  }
  async function breakDownChecklistAns(
    _sectionCurrentListWithAns = sectionCurrentListWithAns
  ) {
    let status_V = "Not Started";
    let ansList = [];
    let populatedCount = 0;
    let skipCount = 0;
    let reqCount = 0;
    let reqMissing = false;
    //console.log('totalFieldCount.size :: '+totalFieldCount);
    for (let sec of _sectionCurrentListWithAns) {
      for (let item of sec.items) {
        const required = !item.Optional__c;
        let populated = false;
        let skipCheck = !required;
        let value;
        if (required) {
          reqCount++;
          value = item.ans.Response__c;
          if (!(value === undefined || value == "" || value == null)) {
            populated = true;
          }
          value = item.ans.Signature__c;
          if (
            populated == false &&
            !(value === undefined || value == "" || value == null)
          ) {
            populated = true;
          }
          if (!reqMissing && !populated) {
            reqMissing = true;
          }
        }
        if (item.ans) {
          ansList.push(item.ans);
        }
        //console.log('value :: '+value , 'required ' ,required , 'populated ' , populated);
        if (populated || skipCheck) {
          if (skipCheck) {
            skipCount++;
          }
          populatedCount++;
        }
      }
    }
    let totalFieldCount = ansList.length;
    if (populatedCount == 0 || populatedCount - skipCount == 0) {
      status_V = "Not Started";
    } else if (populatedCount == totalFieldCount) {
      status_V = "Completed";
    } else if (populatedCount != 0) {
      status_V = "In Progress";
    }
    //console.log(" ansList toSave  :: "+ JSON.stringify(ansList));
    return { cal_status: status_V, reqMissing: reqMissing, ansList: ansList };
  }
  async function getSignatureForSignatureField(pagesAnnotations, formField) {
    //console.log('getSignatureForSignatureField ff:: ');
    let signatureBoundingBox;
    const signaturePageAnnotations = pagesAnnotations?.filter(
      (pageAnnotations) => {
        const signatureWidget = pageAnnotations.find((annotation) => {
          return annotation.pdfObjectId == formField.annotationIds.first();
        });
        if (signatureWidget) {
          signatureBoundingBox = signatureWidget.boundingBox;
          return true;
        }
        return false;
      }
    );
    return (
      signaturePageAnnotations[0] &&
      signaturePageAnnotations[0].find((annotation) => {
        if (
          annotation instanceof pspdfkit_Imp.Annotations.InkAnnotation ||
          annotation instanceof pspdfkit_Imp.Annotations.ImageAnnotation ||
          annotation instanceof pspdfkit_Imp.Annotations.TextAnnotation
        ) {
          if (
            annotation.boundingBox.isRectOverlapping(signatureBoundingBox) &&
            annotation.isSignature
          ) {
            return true;
          }
        }
      })
    );
  }
  function getCustomNodeButton(_id, _label, _icon) {
    let className =
      "PSPDFKit-8ehcbhz241z1tfyhjztbe12ube PSPDFKit-5hqvpgcgpf1769cn35dvtg4ktz PSPDFKit-Toolbar-Button PSPDFKit-Tool-Button";
    const node = document.createElement("Button");
    node.innerText = _label;
    node.className = className;
    const icon = _icon
      ? _icon
      : `<svg className="slds-icon slds-icon_xx-small" aria-hidden="true">
                    <use xlink:href="/slds/icons/utility-sprite/svg/symbols.svg#success"></use>
                  </svg>`;
    let item = { id: _id, type: "custom", node: node, icon: icon };
    return item;
  }
  async function savePdf(_instance = instanceObject) {
    setSpinner(true);
    await _instance.exportPDF({ flatten: true }).then(async (buffer) => {
      const blob = new Blob([buffer], { type: "application/pdf" });
      const fName = fileTitle.current;
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, fName);
      } else {
        const objectUrl = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = objectUrl;
        a.style = "display: none";
        a.download = fName;
        document.body.appendChild(a);
        a.click();
        URL.revokeObjectURL(objectUrl);
        document.body.removeChild(a);
      }
    });
    setSpinner(false);
  }
  async function deleteRecord(recId) {
    setSpinner(true);
    await axios
      .delete(
        `${fetchUrl}/delete/Form__c/${recId}?token=${credential?.token}&instanceUrl=${credential?.instanceUrl}`
      )
      .then((res) => {
        //console.log("count:::: " + JSON.stringify(res));
        handleClose(true);
      })
      .catch((err) => {
        console.log(err);
      });
    setSpinner(false);
  }
  async function initLoadPDF(fileURI) {
    pdfLoaded.current = false;
    try {
      pspdfkit_Imp && containerRef && pspdfkit_Imp.unload("#pdfcontainer");
    } catch (e) {
      console.log("unload error");
    }
    docTriggerChanges.current = false;
    instance.current = await pspdfkit_Imp.load({
      container: containerRef.current,
      document: fileURI,
      baseUrl: `${window.location.protocol}//${window.location.host}/${process.env.PUBLIC_URL}`,
      initialViewState: new pspdfkit_Imp.ViewState({
        zoom: pspdfkit_Imp.ZoomMode.FIT_TO_WIDTH,
        formDesignMode: false,
        readOnly: isFormReadonly.current,
        interactionMode: pspdfkit_Imp.InteractionMode.PAN,
      }),
      toolbarItems: [
        { type: "pan" },
        { type: "zoom-out" },
        { type: "zoom-in" },
      ],
    });
    setInstanceObject(instance.current);
    if (instance.current) {
      pdfLoaded.current = true;
      let item = getCustomNodeButton(
        "download_pdf",
        "Downlod Document",
        "/download.svg"
      );
      item["onPress"] = () => savePdf(instance.current);
      let newToolbar = [...instance.current?.toolbarItems, item];
      newToolbar.push({ type: "spacer" });
      if (!isFormReadonly.current && projectFormId) {
        let item1 = getCustomNodeButton(
          "server_discard",
          "Discard",
          "/discard.svg"
        );
        item1["onPress"] = () => enableDeleteWarningModals(true);
        newToolbar.push(item1);
      }
      let item2 = getCustomNodeButton("server_save", "Save", "/save.svg");
      item2["onPress"] = () => onClickSave(instance.current);
      newToolbar.push(item2);
      instance.current?.setToolbarItems(newToolbar);
      //console.log('sdfsdf');
      const btn2 = document.getElementById("completeButton");
      if (btn2) {
        btn2.onclick = function (event) {
          setInstanceObject(instance.current);
          setWarningType("complete");
          enableWarningModal(true);
          //onClickSave(instance.current , 'complete');
        };
      }
      instance.current.addEventListener("annotations.change", (event) => {
        docTriggerChanges.current = true;
      });
      instance.current.addEventListener("annotations.delete", (event) => {
        docTriggerChanges.current = true;
      });
      instance.current.addEventListener("formFields.didSave", () => {
        //console.log('formFields.didSave');
        docTriggerChanges.current = true;
      });
      instance.current.addEventListener("formFields.change", () => {
        //console.log('formFields.change');
        docTriggerChanges.current = true;
      });
      setInstanceObject(instance.current);
    } else {
      pdfLoaded.current = false;
    }
  }

  const generateHeaderFooter = (doc) => {
    const pageCount = doc.internal.getNumberOfPages();
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      // Add header
      doc.setFontSize(18);
      doc.setTextColor(40);
      // doc.setFontStyle("normal");
      doc.text("Header", doc.internal.pageSize.getWidth() / 2, 30, {
        align: "center",
      });
      // Add footer
      doc.setFontSize(10);
      doc.setTextColor(150);
      //   doc.setFontStyle("normal");
      doc.text(
        `Page ${i} of ${pageCount}`,
        doc.internal.pageSize.getWidth() / 2,
        doc.internal.pageSize.getHeight() - 10,
        { align: "center" }
      );
    }
  };

  const handleGeneratePdf = () => {
    // Adding the fonts.
    doc.setFont("Inter-Regular", "normal");
    setDownload(true);
    doc.html(refPdf.current, {
      async callback(doc) {
        generateHeaderFooter(doc);
        setDownload(false);
        await doc.save("Check");
      },
      margin: [10, 0, 0, 10],
      html2canvas: { scale: 0.6 },
      viewportSize: { width: 1000, height: 842 },
    });

  };

  return (
    <>
      <div style={{ width: "100%", position: "relative" }}>
        {showSpinner && <Spinner />}
        <Grid
          container
          style={{ display: "flex" }}
          justifyContent="space-between"
        >
          <Grid item>
            <div
              className="versions-sidebar"
              style={{ paddingRight: 5, paddingLeft: 5 }}
            >
              <div className="versions-sidebar-header">
                <span className="versions-sidebar-header-title">
                  Form Details
                </span>
              </div>
              <Divider variant="middle" />
              <div
                className="versions-sidebar-body"
                style={{ height: "calc(97vh - 200px)" }}
              >
                <Grid
                  container
                  rowSpacing={2}
                  style={{ width: "100%", paddingTop: "15px" }}
                  className="sf-detail-container"
                >
                  <Grid item xs={12}>
                    <div style={{ paddingTop: "10px" }}>
                      {templateOptions && templateOptions.length > 0 && (
                        <Autocomplete
                          disablePortal
                          className="common_class inlineAutocomplete"
                          value={formTemplate}
                          getOptionLabel={(option) =>
                            option?.label ? option?.label : ""
                          }
                          onChange={(event, newValue) => {
                            let fff = sfObj;
                            fff.Form_Template__c = newValue?.value;
                            fff.Form_Template__r = {
                              Id: newValue?.value,
                              Name: newValue?.label,
                            };
                            setVariables(fff);
                            uploadMedia(newValue);
                          }}
                          options={templateOptions}
                          disabled={isFormReadonly.current}
                          renderInput={(params) => (
                            <div>
                              <TextField
                                id="formTemplate"
                                variant="outlined"
                                label="Template"
                                required
                                {...params}
                                disabled={isFormReadonly.current}
                                fullWidth
                              />
                            </div>
                          )}
                        />
                      )}
                    </div>
                    <div style={{ paddingTop: "10px" }}>
                      <TextField
                        id="title"
                        variant="outlined"
                        label="Form Title"
                        required
                        value={sfObj?.Name}
                        fullWidth
                        onChange={(e) => {
                          let fff = sfObj;
                          fff.Name = e?.target?.value;
                          setVariables(fff);
                        }}
                        onKeyDown={(event) => {
                          if (event.key.toLowerCase() != "tab") {
                            event.stopPropagation();
                          }
                        }}
                        onBlur={(e) => {
                          if(sfObj.Id && sfObj.Name){
                            saveFormObjOnServer({
                              Id: sfObj.Id,
                              Name: sfObj.Name
                            });
                          }
                        }}
                      />
                    </div>
                    <div style={{ paddingTop: "10px" }}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          className="common_class"
                          disablePast={false}
                          placeholder="Form Date"
                          inputFormat="MM/dd/yyyy"
                          value={formDate}
                          label="Form Date"
                          onChange={(newValue) => {
                            let fff = sfObj;
                            if (newValue) {
                              fff.Date__c =
                                moment(newValue).format("YYYY-MM-DD");
                            } else {
                              fff.Date__c = null;
                            }
                            setVariables(fff);
                            if(sfObj.Id && fff.Date__c){
                              saveFormObjOnServer({
                                Id: sfObj.Id,
                                Date__c: fff.Date__c
                              });
                            }
                          }}
                          renderInput={(params) => (
                            <div>
                              <TextField
                                id="formDate"
                                variant="outlined"
                                label="Form Date"
                                required
                                {...params}
                                fullWidth
                              />
                            </div>
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                    <div style={{ paddingTop: "10px" }}>
                      <Autocomplete
                        className="common_class inlineAutocomplete"
                        disablePortal
                        value={assignTo}
                        getOptionLabel={(option) =>
                          option?.label ? option?.label : ""
                        }
                        onChange={(event, newValue) => {
                          let fff = sfObj;
                          fff.Ball_in_Court__c = newValue?.value;
                          fff.Ball_in_Court__r = {
                            Id: newValue?.value,
                            Name: newValue?.label,
                          };
                          setVariables(fff);
                          if(sfObj.Id && newValue?.value){
                            saveFormObjOnServer({
                              Id: sfObj.Id,
                              Ball_in_Court__c: newValue?.value
                            });
                          }
                        }}
                        options={[
                          ...[{ label: "None..", value: null }],
                          ...props?.userList,
                        ]}
                        renderInput={(params) => (
                          <div>
                            <TextField
                              id="assignedTo"
                              variant="outlined"
                              label="Assigned To"
                              {...params}
                              fullWidth
                            />
                          </div>
                        )}
                      />
                    </div>
                    <div style={{ paddingTop: "10px" }}>
                      <TextField
                        className="common_class"
                        id="Description"
                        variant="outlined"
                        label="Description"
                        value={
                          sfObj?.Description__c
                            ? sfObj?.Description__c
                            : undefined
                        }
                        multiline
                        rows={1}
                        maxRows={3}
                        fullWidth
                        onChange={(e) => {
                          let fff = sfObj;
                          fff.Description__c = e?.target?.value;
                          setVariables(fff);
                        }}
                        onKeyDown={(event) => {
                          if (event.key.toLowerCase() != "tab") {
                            event.stopPropagation();
                          }
                        }}
                        onBlur={(e) => {
                          if(sfObj.Id && sfObj.Description__c){
                            saveFormObjOnServer({
                              Id: sfObj.Id,
                              Description__c: sfObj.Description__c
                            });
                          }
                        }}
                      />
                    </div>
                  </Grid>
                </Grid>
              </div>
              {/*<Divider variant="middle" />
              <div style={{ textAlign: "center" , paddingBottom : "0.5rem" , paddingTop : "0.5rem" , background:'#fefefe'}}>
                <ButtonGroup variant="outlined" className="table-btn-group" >
                  <Button variant="text" component="span" onClick={() => handleClose(false)}>
                    Cancel
                  </Button>
                  {props.selectedFormType === FORM_TYPES.checklistForm && (
                    <>
                      {!isFormReadonly.current &&
                        <Button style={{ backgroundColor: "Red", color: "black" }} id="checkListSaveButton" variant="outlined" component="span" disabled={requiredMissing.current}
                          onClick={() => {
                            setInstanceObject(instance.current);
                            enableDeleteWarningModals(true);
                            //onClickSave(instance.current , 'complete');
                          }}>
                          Delete
                        </Button>
                      }
                    </>
                  )}
                  {props.selectedFormType === FORM_TYPES.pdfForm && (
                    <>
                      {!isFormReadonly.current &&
                        <Button id="completeButton" variant="contained" component="span" disabled={(requiredMissing.current || !pdfLoaded.current)}>
                          Complete
                        </Button>
                      }
                    </>
                  )}
                  {props.selectedFormType === FORM_TYPES.checklistForm && (
                    <>
                      {!isFormReadonly.current &&
                        <Button variant="contained" component="span" disabled={(requiredMissing.current)} onClick={() => {
                          setWarningType('complete');
                          enableWarningModal(true);
                          //onClickSaveChecklist('complete');
                        }}>
                          Complete
                        </Button>
                      }
                    </>
                  )}
                </ButtonGroup>
              </div>*/}
            </div>
          </Grid>
          <Grid item style={{ flexGrow: 1, width: "70%" }}>
            <div style={{ float: "right", paddingRight: "1rem" }}>
              <ButtonGroup variant="outlined" className="table-btn-group">
                <Button
                  variant="text"
                  component="span"
                  onClick={() => handleGeneratePdf()}
                >
                  Download Pdf
                </Button>
              </ButtonGroup>
              <ButtonGroup variant="outlined" className="table-btn-group">
                <Button
                  variant="text"
                  component="span"
                  onClick={() => handleClose(true)}
                >
                  Cancel
                </Button>
                {props.selectedFormType === FORM_TYPES.checklistForm && (
                  <>
                    {!isFormReadonly.current && (
                      <>
                        {/*<Button id="checkListSaveButton" variant="contained" component="span" disabled={(requiredMissing.current)}
                          onClick={() => onClickSaveChecklist('save')}>
                          Save
                        </Button> */}
                        {projectFormId && (
                          <IconButton
                            aria-label="close"
                            onClick={() => {
                              setInstanceObject(instance.current);
                              enableDeleteWarningModals(true);
                              //onClickSave(instance.current , 'complete');
                            }}
                          >
                            <Delete />
                          </IconButton>
                        )}
                      </>
                    )}
                  </>
                )}
              </ButtonGroup>
            </div>
            {props.selectedFormType === FORM_TYPES.pdfForm && (
              <div
                id="pdfcontainer"
                ref={containerRef}
                style={{ height: "81vh" }}
              ></div>
            )}
            {props.selectedFormType === FORM_TYPES.checklistForm && (
              <CheckListForm
                formId={projectFormId}
                credential={credential}
                checklistFormDetails={sectionCurrentListWithAns}
                dispatchObj={dispatch}
                checklistFormobj={checklistFormobj}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <div
              style={{
                textAlign: "right",
                paddingBottom: "0.5rem",
                paddingTop: "0.5rem",
                paddingRight: "1rem",
                background: "#fefefe",
              }}
            >
              <ButtonGroup variant="outlined" className="table-btn-group">
                {props.selectedFormType === FORM_TYPES.pdfForm && (
                  <>
                    {!isFormReadonly.current && (
                      <Button
                        id="completeButton"
                        variant="contained"
                        component="span"
                        disabled={requiredMissing.current || !pdfLoaded.current}
                      >
                        Complete
                      </Button>
                    )}
                  </>
                )}
                {props.selectedFormType === FORM_TYPES.checklistForm && (
                  <>
                    {!isFormReadonly.current && (
                      <Button
                        variant="contained"
                        component="span"
                        disabled={requiredMissing.current || !showCheckSave}
                        onClick={() => {
                          setWarningType("complete");
                          enableWarningModal(true);
                          //onClickSaveChecklist('complete');
                        }}
                      >
                        Complete
                      </Button>
                    )}
                  </>
                )}
              </ButtonGroup>
            </div>
          </Grid>
        </Grid>
      </div>
      {showDeleteWarnings && (
        <Dialog fullWidth open={showDeleteWarnings} scroll="paper">
          <DialogTitle>
            <Typography>Delete Form?</Typography>
            <IconButton
              aria-label="close"
              onClick={() => {
                enableDeleteWarningModals(false);
              }}
            >
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Typography>
              Are you sure you want to delete {sfObj?.Name}?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              onClick={() => {
                enableDeleteWarningModals(false);
              }}
            >
              Close
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                deleteRecord(projectFormId);
              }}
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {showWarning && (
        <Dialog fullWidth open={showWarning} scroll="paper">
          <DialogTitle>
            <Typography>
              {props.selectedFormType === FORM_TYPES.pdfForm && (
                <>
                  {warningType == "complete"
                    ? "Complete Form"
                    : "Failed to Complete"}
                </>
              )}
              {props.selectedFormType === FORM_TYPES.checklistForm && (
                <>
                  {warningType == "complete"
                    ? " Complete Checklist?"
                    : "Failed to Complete"}
                </>
              )}
            </Typography>
            <IconButton
              aria-label="close"
              onClick={() => {
                enableWarningModal(false);
              }}
            >
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Typography>
              {props.selectedFormType === FORM_TYPES.pdfForm && (
                <>
                  {warningType == "complete"
                    ? "You will not be able to make any changes after completing this form"
                    : "Your form is missing a signature/required input. Please provide the required fields and select complete again"}
                </>
              )}
              {props.selectedFormType === FORM_TYPES.checklistForm && (
                <>
                  {warningType == "complete"
                    ? "You will not be able to make any changes after completing this checklist"
                    : "Your form is missing a required response. Please provide the required response and select complete again"}
                </>
              )}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              onClick={() => {
                enableWarningModal(false);
              }}
            >
              {warningType == "complete" ? "Cancel" : "Close"}
            </Button>
            {warningType == "complete" && (
              <>
                {props.selectedFormType === FORM_TYPES.pdfForm && (
                  <Button
                    variant="contained"
                    onClick={() => {
                      onClickSave(instanceObject, "complete");
                    }}
                  >
                    Complete
                  </Button>
                )}
                {props.selectedFormType === FORM_TYPES.checklistForm && (
                  <Button
                    variant="contained"
                    onClick={() => {
                      onClickSaveChecklist("complete");
                    }}
                  >
                    Complete
                  </Button>
                )}
              </>
            )}
          </DialogActions>
        </Dialog>
      )}
      {checklistFormobj &&(
        <div ref={refPdf} id="test_html_data">
          <div
            className="top-deaer"
            style={{ borderBottom: "1px solid #ddd", padding: "5px" }}
          >
            <p>Training Project</p>
          </div>
          <div id="pspdfkit-header">
            <table
              style={{
                width: "100%",
              }}
            >
              <tr>
                <td
                  style={{
                    padding: "5px",
                    verticalAlign: "top",
                  }}
                >
                  Checklist Detail
                  <p style={{ margin: 0 }}>

                    {checklistFormobj.Form_Template__r?.Name}


                  </p>
                </td>
                <td
                  style={{
                    padding: "5px",
                    textAlign: "right",
                  }}
                >

                </td>
              </tr>
            </table>
          </div>
          <div>

            <DownloadPdf cooss={checklistFormDetails} formobj={checklistFormobj} doc={doc} />
          </div>
        </div>
      )}
    </>
  );

}
export function DownloadPdf({
  cooss,
  formobj,
  doc
}) {
  console.log("cooss ::", cooss);
  if (cooss) {


    var section = [];
    var itemsnumber = [];
    var temp = [
      cooss.length

    ];
    section.push(temp);
    const doubledNumbers = cooss.map(num => num * 2);
    console.log("doubledNumbers", doubledNumbers);
    for (let i = 0; i < cooss.length; i++) {
      var items = [
        cooss[i].items.length
      ];
      itemsnumber.push(items);
    }

    return (
      <table>
        <thead>
        </thead>
        <tbody>
          <tr>
            <td>
              <table
                style={{
                  width: "100%",
                  borderTop: "1px solid #ddd",
                  borderBottom: "1px solid #ddd",
                }}
              >
                <tr>
                  <td
                    style={{
                      width: "30%",
                      padding: "5px",
                      fontWeight: "500",
                      color: " #838383",
                    }}
                  >
                    Status{" "}
                  </td>
                  <td style={{ width: " 70%", padding: "5px" }}>
                    <span
                      style={{
                        display: " inline-flex",
                        height: "13px",
                        width: "4px",
                        background: "#087cd9",
                        borderRadius: " 5px ",
                        marginRight: "10px",
                      }}
                    ></span>
                    {formobj.Status__c}
                  </td>
                </tr>
              </table>
              <table
                style={{
                  width: "100%",
                  borderTop: "1px solid #ddd",
                  borderBottom: "1px solid #ddd",
                }}
              >
                <tr>
                  <td
                    style={{
                      width: "30%",
                      padding: "5px",
                      fontWeight: "500",
                      color: " #838383",
                    }}
                  >
                    Form Title{" "}
                  </td>
                  <td style={{ width: " 70%", padding: "5px" }}>
                    <span
                      style={{
                        display: " inline-flex",
                        height: "13px",
                        width: "4px",
                        background: "#087cd9",
                        borderRadius: " 5px ",
                        marginRight: "10px",
                      }}
                    ></span>
                    {formobj.Name}
                  </td>
                </tr>
              </table>
              <table
                style={{
                  width: "100%",
                  borderTop: "1px solid #ddd",
                  borderBottom: "1px solid #ddd",
                }}
              >
                <tr>
                  <td
                    style={{
                      width: "30%",
                      padding: "5px",
                      fontWeight: "500",
                      color: " #838383",
                    }}
                  >
                    Form Date{" "}
                  </td>
                  <td style={{ width: " 70%", padding: "5px" }}>
                    <span
                      style={{
                        display: " inline-flex",
                        height: "13px",
                        width: "4px",
                        background: "#087cd9",
                        borderRadius: " 5px ",
                        marginRight: "10px",
                      }}
                    ></span>
                    {formobj.Date__c}
                  </td>
                </tr>
              </table>
              <table
                style={{
                  width: " 100%",
                  borderBottom: "1px solid #ddd",
                }}
              >
                <tr>
                  <td
                    style={{
                      width: "30%",
                      padding: "5px",
                      fontWeight: "500",
                      color: " #838383",
                    }}
                  >
                    Assigned to
                  </td>
                  <td style={{ width: "70%", padding: "5px" }}>
                    <p style={{ margin: 0 }}>{formobj.Ball_in_Court__r?.Name}</p>
                  </td>
                </tr>
              </table>
              <table
                style={{
                  width: "100%",
                  borderBottom: "1px solid #ddd",
                  tableLayout: "fixed",
                }}
              >
                <thead>
                  <tr>
                    <th
                      style={{
                        borderRight: "1px solid #ddd",
                        borderBottom: "1px solid #ddd",
                        padding: "5px",
                        textAlign: "center",
                      }}
                    >
                      Sections
                    </th>
                    <th
                      style={{
                        borderRight: "1px solid #ddd",
                        borderBottom: "1px solid #ddd",
                        padding: "5px",
                        textAlign: "center",
                      }}
                    >
                      Items
                    </th>
                    <th
                      style={{
                        borderRight: "1px solid #ddd",
                        borderBottom: "1px solid #ddd",
                        padding: "5px",
                        textAlign: "center",
                      }}
                    >
                      Issues
                    </th>
                    <th
                      style={{
                        borderRight: "1px solid #ddd",
                        borderBottom: "1px solid #ddd",
                        padding: "5px",
                        textAlign: "center",
                      }}
                    >
                      Conforming
                    </th>
                    <th
                      style={{
                        borderRight: "1px solid #ddd",
                        borderBottom: "1px solid #ddd",
                        padding: "5px",
                        textAlign: "center",
                      }}
                    >
                      Non-conforming
                    </th>
                    <th
                      style={{
                        borderRight: "1px solid #ddd",
                        borderBottom: "1px solid #ddd",
                        padding: "5px",
                        textAlign: "center",
                      }}
                    >
                      N/A
                    </th>
                    <th
                      style={{
                        borderBottom: "1px solid #ddd",
                        padding: "5px",
                        textAlign: "center",
                      }}
                    >
                      To be answered
                    </th>
                  </tr>
                </thead>

                <tbody>

                  <td
                    style={{
                      borderRight: "1px solid #ddd",
                      borderBottom: "1px solid #ddd",
                      padding: "5px",
                      textAlign: "center",
                    }}
                  >
                    {section}/{section}
                  </td>

                  <td
                    style={{
                      borderRight: "1px solid #ddd",
                      borderBottom: "1px solid #ddd",
                      padding: "5px",
                      textAlign: "center",
                    }}
                  >
                    {itemsnumber}
                  </td>
                  <td
                    style={{
                      borderRight: "1px solid #ddd",
                      borderBottom: "1px solid #ddd",
                      padding: "5px",
                      textAlign: "center",
                    }}
                  >
                    0
                  </td>
                  <td
                    style={{
                      borderRight: "1px solid #ddd",
                      borderBottom: "1px solid #ddd",
                      padding: "5px",
                      textAlign: "center",
                    }}
                  >
                    2
                  </td>
                  <td
                    style={{
                      borderRight: "1px solid #ddd",
                      borderBottom: "1px solid #ddd",
                      padding: "5px",
                      textAlign: "center",
                    }}
                  >
                    1
                  </td>
                  <td
                    style={{
                      borderRight: "1px solid #ddd",
                      borderBottom: "1px solid #ddd",
                      padding: "5px",
                      textAlign: "center",
                    }}
                  >
                    0
                  </td>
                  <td
                    style={{
                      borderBottom: "1px solid #ddd",
                      padding: "5px",
                      textAlign: "center",
                    }}
                  >
                    0
                  </td>
                </tbody>

              </table>
              {cooss.map(section => (
                <tr>
                  <td
                    style={{
                      padding: "5px",
                      borderBottom: "1px solid #ddd",
                    }}
                  >
                    <h4
                      style={{ fontSize: "16px", fontWeight: "900" }}
                    >
                      <span>{section.Numbering_Sequence__c}.</span> {section.Name}
                    </h4>
                    {section.items.map(itmes => (
                      <tr>
                        <td
                          style={{
                            width: "20%",
                            verticalAlign: "top",
                            padding: "5px",
                          }}
                        >
                          {section.Numbering_Sequence__c}.{itmes.Numbering_Sequence__c}

                        </td>
                        <tr>
                          <td
                            style={{
                              width: "800px",
                              borderLeft: "1px solid #ddd",
                              borderBottom: "1px solid #ddd",
                              borderTop: "1px solid #ddd",
                              verticalAlign: "top",
                              padding: "5px",
                              marginTop: "5px"
                            }}
                          >
                            {itmes.Question__c}
                          </td>

                        </tr>
                        <tr
                          style={{
                            width: "80%",
                            borderLeft: "1px solid #ddd",
                            verticalAlign: "top",
                            borderBottom: "1px solid #ddd",
                            padding: "5px",
                            marginBottom: "10px"
                          }}
                        >
                          {itmes.ans.Response__c}
                        </tr>
                        {itmes.ans.attachments &&
                          itmes.ans.attachments.map(attactment => (
                            <tr style={{
                              padding: "5px",
                              textAlign: "right",
                              display: "flex"
                            }}>
                              <td
                                style={{
                                  padding: "5px",
                                  textAlign: "right",
                                  display: "flow"
                                }}
                              >
                                <img
                                  src={attactment.fileurl}
                                  crossorigin="anonymous"
                                  alt="Header Image"
                                  style={{
                                    height: "60px",
                                    width: "100px",
                                    objectFit: "cover",
                                    display: "flow"
                                  }}
                                />
                              </td>
                            </tr>
                          ))
                        }

                      </tr>

                    ))}

                  </td>

                </tr>

              ))}
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
}
export default CreateProjectForm;
