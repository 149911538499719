import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import FilesComponentStyle from "./FilesComponentStyle";
import FolderFilesDataTable from "./FolderFilesDataTable";
import { FolderView } from "components/FolderSection";
import useFormSectionHook from "hooks/FolderSection.hook";
import { Tabs, Tab } from "@mui/material";
import expandImage from "assets/images/right.svg";
import collpasImage from "assets/images/left.svg";
import newFolder from "assets/images/newFolder.svg";
import file from "assets/images/file.svg";
import folder from "assets/images/folder.svg";
import {Tooltip, Box} from "@mui/material";
import { SplitButtonContainedAction } from "components/SplitButtonAction";
import { useNavigate, useLocation } from "react-router-dom";
var expandedFolder = false;
function FilesComponent(props) {
    const location = useLocation();
    const [state, setStates] = useState(location.state); 
    const stateRef = useRef(location.state);
    const setState=(value)=>{
      stateRef.current = value;
      setStates(value);
    };
    const { userData = {} } = useSelector((state) => state.userData);
    const [redirectId, setRedirectId] = useState();
    const [viewingIssueId, setViewingIssue] = useState();
    const [_projectId, setProjectId] = useState();
    const [_tokenInfo, setTokenInfo] = useState(userData);
    const [foldersExpand, setFoldersExpandState] = useState(expandedFolder);
    const [allData, setAllDataSet] = useState([]);
    const [currentFolders, setCurrentFolders] = useState([]);
    const [currentFolderName, setCurrentFolderName] = useState({});
    const [selectedNode, setSelectedNode] = useState([]);
    const [confirmDialog, setConfirmDialog] = useState(null);
    const [activeTab, setActiveTab] = useState(0);
    const inputRef = useRef(null);
    const inputDir = useRef(null);
    const { _setChildrenData, findFolderPath , _getNodeChildren, _getFolderApi, setFolderTreeOnView, folderLoader, expandedFolderIds ,allFileData} =
        useFormSectionHook({
            'projectId': userData?.projectId,
            'currentFolderName': currentFolderName,
            'setCurrentFolders': setCurrentFolders,
            'setSelectedNode': setSelectedNode,
            'setCurrentFolderName': setCurrentFolderName,
            'allData': allData,
            'setAllDataSet': setAllDataSet
        });
    //console.log("DrawerComponent currentFolderName", currentFolderName);
    useEffect(() => {
        //console.log('change state :: ',location.state);
        setState(location.state);
    }, [location?.state]);
    useEffect(() => {
        //console.log('files use effect ::',userData?.projectId,_projectId);
        if(_projectId !==  userData?.projectId){
            if(userData?.projectId){
                async function doInit(){
                    //console.log('variable state :: in ',state);
				    let ffff = stateRef.current;
                    //console.log('Sheets Component location?.state ::',JSON.stringify(ffff));
                    let preSelected;
                    if (ffff) {
                        if(ffff?.redirectId && ffff?.redirectId != null){
                            setRedirectId(ffff?.redirectId);
                            if(ffff?.issueId && ffff?.issueId != null){
                                setViewingIssue(ffff?.issueId);
                            }
                            if(ffff?.folderId && ffff?.folderId != null){
                                preSelected = {id : ffff?.folderId } ;
                            }
                        }
                        window.history.replaceState({}, document.title);
                        setState(null);
                    }
                    setProjectId(userData?.projectId);
                    setTokenInfo(userData);
                    console.log('preSelected ::',preSelected);
                    _getFolderApi(preSelected);
                }
                doInit();
            }
        }
    }, [userData?.projectId]);
    useEffect(() => {
        setTokenInfo(userData);
    }, [userData?.userList]);
    const dropdownItems = [
        { icon: newFolder, label: 'New Folder' },
        { icon: file, label: 'Upload File' },
        { icon: folder, label: 'Upload Folder' },
    ];
    const setFoldersExpand = (state) =>{
        setFoldersExpandState(state);
        expandedFolder =state;
    };
    return (
        <Box sx={{ display: "flex" }}>
            <Box component="main" sx={{ flexGrow: 1, width: { sm: "100%" }, height: `calc(100vh - 113px)`, overflow: "hidden", }} >
                {(_projectId || true) && (allData || true) && (currentFolderName || true) &&(
                    <FilesComponentStyle>
                        <div className="wrapper_main">
                            <div className="table_head" style={{paddingLeft: activeTab === 0 && "40px"}}>
                                <div className="header_heading">
                                    <h1 >Files</h1>
                                </div>
                                { activeTab === 0 &&
                                    <div className="right_side">
                                        <SplitButtonContainedAction
                                            label="New"
                                            items={dropdownItems}
                                            onAction={(label) => {
                                                if(label == "New Folder") {
                                                    setConfirmDialog(label);
                                                }else if(label === "Upload File"){
                                                    inputRef.current.click();
                                                    setConfirmDialog(null);
                                                }else if(label === "Upload Folder"){
                                                    inputDir.current.click();
                                                    setConfirmDialog(null);
                                                }
                                            }}
                                        />
                                    </div>
                                 }
                            </div>
                            <div className="tab_wrapper" style={{ marginLeft: activeTab === 0 && "25px" }}>
                                <Tabs
                                    value={activeTab}
                                    textColor="primary"
                                    indicatorColor="primary"
                                    onChange={(event, newValue) => {
                                        setFoldersExpand(false);
                                        setActiveTab(newValue);
                                    }}
                                >
                                    <Tab label="Folders" />
                                    <Tab label="Deleted Files" />
                                </Tabs>
                            </div>
                            <div className="page_body">
                                {foldersExpand && activeTab === 0 &&
                                    <FolderView
                                        setConfirmDialog={setConfirmDialog}
                                        findFolderPath={findFolderPath}
                                        projectId={props?.projectId}
                                        tokenInfo={props?.tokenInfo}
                                        setCurrentFolderName={setCurrentFolderName}
                                        currentFolderName={currentFolderName}
                                        sectionHook={{ _setChildrenData, data: allData, _getNodeChildren, _getFolderApi, folderLoader, expandedFolderIds }}
                                    />
                                }
                                {activeTab === 0 &&
                                    <Tooltip title={foldersExpand ? "collapse the folder structure pane": "expand the folder structure pane"}
                                        placement="left" PopperProps={{ className: "version-action-tooltip" }} >
                                        <div onClick={() => setFoldersExpand(!foldersExpand)} className="collpas_expander_folders">
                                            <img className="collpas_expander_folders_image" src={!foldersExpand ? expandImage : collpasImage} alt="collpas_expander" />
                                        </div>
                                    </Tooltip>
                                }
                                <FolderFilesDataTable
                                    allData={allData}
                                    inputRef={inputRef} inputDir={inputDir}
                                    activeTab={activeTab}
                                    projectId={props?.projectId}
                                    tokenInfo={_tokenInfo}
                                    currentFolders={currentFolders}
                                    currentFolderName={currentFolderName}
                                    setConfirmDialog={setConfirmDialog}
                                    confirmDialog={confirmDialog}
                                    selectedNode={selectedNode}
                                    setFolderTreeOnView={setFolderTreeOnView}
                                    _getFolderApi={_getFolderApi}
                                    foldersExpand={foldersExpand}
                                    redirectId={redirectId}
                                    setRedirectId={setRedirectId}
                                    viewingIssueId={viewingIssueId}
                                    setViewingIssue={setViewingIssue}
                                    sectionHook={{ allFolderDataTree: allData, allFileData ,_getNodeChildren , findFolderPath, _getFolderApi }}
                                />
                            </div>
                        </div>
                    </FilesComponentStyle>
                )}
            </Box>
        </Box>
    );
}
FilesComponent.propTypes = {
    window: PropTypes.func,
};

export default FilesComponent;