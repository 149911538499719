import { useState } from "react";
import { prepareAmazonRecordRow ,prepareFolderRecordRow } from "components/SheetsComponent";
import _ from "lodash";
import moment from "moment";
import axios from "axios";
import { fetchUrl } from "Urls";

const useFormSectionHook = (props) => {
  const { setCurrentFolders,  currentFolderName, setSelectedNode ,setCurrentFolderName, allData, setAllDataSet, projectId } = props;
  const [folderLoader, setFolderLoader] = useState(false);
  const [expandedFolderIds, setExpandedIds] = useState([]);
  const [resTreeData, setResTreeData] = useState([]);
  const [allFileData, setAllAttachmentData] = useState([]);

  const _setChildrenData = async (VALUES , stopSettingData) => {
    const DATA_CHILDREN = [];
    _.map(VALUES, async (row) => {
      DATA_CHILDREN.push(await prepareFolderRecordRow(row?.value));
    });
    if(stopSettingData === true){
      // when we dont need to set in current folder set
    }else{
      setCurrentFolders(DATA_CHILDREN);
    }
    return DATA_CHILDREN;
    //console.log("Set Children Called END",JSON.stringify(DATA_CHILDREN));
  };
  const getAllFoldersFromTree = (AllFolders, tree) => {
    if (!AllFolders) {
      AllFolders = [];
    }
    if (tree?.children && tree?.children.length > 0) {
      _.forEach(tree?.children, (row) => {
        AllFolders.push(row);
        if (row?.children && row?.children.length > 0) {
          getAllFoldersFromTree(AllFolders, row);
        }
      });
    }

  };
  async function _getFolderApi(preSelectedFolderObj) {
    setFolderLoader(true);
    let additionalQuery = `Folder_Type__c='Project File'` ;
    await axios
    .get(`${fetchUrl}/folder?projectId=${projectId}&filterQuery=${additionalQuery}`)
      .then((res) => {
        if (res?.status === 200) {
          const { tree } = res?.data;
          if (_.keys(tree)?.length > 0) {
            setResTreeData(tree);
          }
          const dataTre = tree.children.filter(folder => folder.value.Name === 'Project Files');
          if(dataTre){
            setSelectedNode([dataTre[0].value]);
            _getAllAttachmentForCurrentFolderApi(dataTre[0].value.Id);
            tree.children = dataTre
          }
          setFolderTreeOnView(preSelectedFolderObj, tree);
        }
      })
      .catch((err) => {
        setFolderLoader(false);
        setCurrentFolderName({});
        setResTreeData([]);
        console.log(err);
      });
    //console.log("expandedFolderIds :: "+expandedFolderIds.length);
  }
  async function setFolderTreeOnView(preSelectedFolderObj, tree = resTreeData) {
    setFolderLoader(true);
    let expandedFolderIds = [];
    if (_.keys(tree)?.length > 0) {
      let allFolders = [];
      getAllFoldersFromTree(allFolders, tree);
      let foundFolder;
      for (let row of allFolders) {
        if (row?.value?.Id === preSelectedFolderObj?.id) {
          foundFolder = row;
          break;
        }
      }
      const Data = [];
      _.forEach(tree?.children, (row) => {Data.push(row);});
      setAllDataSet(Data);
      if (!foundFolder) {
        foundFolder = tree?.children[0];
      }
      if (foundFolder) {
        _setChildrenData(foundFolder?.children);
        setCurrentFolderName({
          parentId: foundFolder?.value?.Parent_Folder__c,
          id: foundFolder?.value?.Id,
          name: foundFolder?.value?.Name,
          type: foundFolder?.value?.Folder_Type__c,
        });
        prepareExpandedFolderIds(expandedFolderIds, allFolders, foundFolder);
      } else {
        if (!currentFolderName) {
          foundFolder = tree?.children[0] ;
          _setChildrenData(foundFolder?.children);
          setCurrentFolderName({
            parentId: foundFolder?.value?.Parent_Folder__c,
            id: foundFolder?.value?.Id,
            name: foundFolder?.value?.Name,
            type: foundFolder?.value?.Folder_Type__c,
          });
          expandedFolderIds = [foundFolder?.value?.Id];
        }
      }
      if(foundFolder){
        findFolderPath(Data , foundFolder?.value?.Id);
      }
      //console.log("get Folder API END");
    } else {
      setResTreeData([]);
      setCurrentFolderName({});
      setAllDataSet([]);
    }
    //console.log("expandedFolderIds :: "+expandedFolderIds.length);
    setExpandedIds(expandedFolderIds);
    setTimeout(() => {
      setFolderLoader(false);
    }, 10);
  }
  const findFolderPath = (treeData, folderId, path = [] , stopSettingPath) => {
    for (const node of treeData) {
      path.push(node?.value);
      if (node.value.Id === folderId) {
        if(stopSettingPath === true){

        }else{
          setSelectedNode(path);
        }
        return path;
      }
      if (node.children) {
        const foundPath = findFolderPath(node.children, folderId, [...path] , stopSettingPath);
        if (foundPath) {
          return foundPath;
        }
      }
      path.pop();
    }
    return null;
  };
  function prepareExpandedFolderIds(expandedFolders, allFolder, endFolder) {
    if (!expandedFolders) {
      expandedFolders = [];
    }
    if (!expandedFolders.includes(endFolder?.value?.Id)) {
      expandedFolders.push(endFolder?.value?.Id);
    }
    for (let current of allFolder) {
      if (current?.value?.Id == endFolder?.value?.Parent_Folder__c) {
        prepareExpandedFolderIds(expandedFolders, allFolder, current);
        break;
      }
    }
  }
  function _getNodeChildren(data, id) {
    let node;
    data.some(function (n) {
      if (n?.value?.Id === id) {
        return (node = n);
      }
      if (n.children) {
        return (node = _getNodeChildren(n.children, id));
      }
    });
    return node || null;
  }
  async function _getAllAttachmentForCurrentFolderApi(id) {
    await axios.get(`${fetchUrl}/folderfiles/${id}`).then(async (res) => {
      if (res?.status === 200) {
          const { files } = res?.data;
          let withfile = [];
          files?.map(async (file) => {
              withfile.push(await prepareAmazonRecordRow(file));
          });
        setAllAttachmentData(withfile);
      }
    }).catch((err) => {
      console.log(err);
    });
  }

  return {
    _setChildrenData,
    data: allData,
    _getNodeChildren,
    _getFolderApi,
    folderLoader,
    expandedFolderIds,
    setFolderLoader,
    setFolderTreeOnView,
    resTreeData,
    findFolderPath,
    allFileData,
  };
};

export default useFormSectionHook;
