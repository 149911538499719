import styled from "styled-components";

const CompareFilesStyles = styled.div`

  height: 94%;

 .sheet-file-view {
  background-color: #FFFFFF;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #EAEAEA;
  height: 56px;
  left: 0;
  display: flex;
  position: relative;
  justify-content: space-between;
  top: 0;
  width: 100%;
}

.sheet-name-and {
  display: inline-flex;
  gap: 12px;
  left: 20px;
  padding: 3px 0px;
  position: relative;
  top: 19px;

}
.zoom-container{
  right: 15px;
  bottom: 15px;
  float: right;
  display: inline-flex;
  align-items: flex-start;
  gap: -1px;
}
.zoom-button{
  max-width: none !important;
  display: flex;
  height: 37px;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border: 1px solid var(--neutrals-light-grey-3, #D8D8D8);
  background: var(--neutrals-white, #FFF);
}
.sync{
  &:hover {
    background: #F8F8F8;
  }
  width: 70px;
  height: 20px;
}
.sync-select{
  &:hover {
    background: #F8F8F8;
    color: #505050;
  }
  color: #346EF5;
  background: #E8EFFF;
  width: 70px;
  height: 20px;
}
.sheet-name-and-2 {
  display: inline-flex;
  gap: 12px;
  left: 20px;
  padding: 3px 0px;
  position: relative;
}
.text-wrapper-2 {
  color: #000000;
  font-family: "Poppins-SemiBold", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.14px;
  line-height: normal;
  margin-top: -1px;
  position: relative;
}

.p {
  color: #505050;
  font-family: "Poppins-Regular", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.14px;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.menu {
  right: 135px;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  position: relative;
  top: 16px;
}

.tool-selection-select {
  &:hover {
    background: #F8F8F8;
  }
  align-items: center;
  background-color: #E8EFFF;
  border-radius: 2px;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  height: 25px;
  justify-content: space-around;
  padding: 2px 20px;
  position: relative;
}

.tool-selection {
  &:hover {
    background: #F8F8F8;
  }
  align-items: center;
  background-color: #FFFFFF;
  border-radius: 2px;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  height: 25px;
  justify-content: space-around;
  padding: 2px 20px;
  position: relative;
}

.text-wrapper {
  color: var(--neutralsdark-grey);
  font-family: var(--common-font), Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.text-wrapper-select {
  &:hover {
    color: var(--neutralsdark-grey);
  }
  color: #346EF5;
  font-family: var(--common-font), Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.text-wrapper-3 {
  &:hover {
    color: var(--neutralsdark-grey);
  }
  color: var(--neutralsdark-grey);
  font-family: var(--common-font), Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.text-wrapper-3-select {
  &:hover {
    color: var(--neutralsdark-grey);
  }
  color: #346EF5;
  font-family: var(--common-font), Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.div-wrapper-select {
  &:hover {
    background: #F8F8F8;
  }
  align-items: center;
  background-color: #E8EFFF;
  border-radius: 2px;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  height: 25px;
  justify-content: space-around;
  padding: 2px 20px;
  position: relative;
}
.div-wrapper {
  &:hover {
    background: #F8F8F8;
  }
  align-items: center;
  background-color: #FFFFFF;
  border-radius: 2px;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  height: 25px;
  justify-content: space-around;
  padding: 2px 20px;
  position: relative;
}

.label-wrapper {
  cursor: pointer;
  align-items: center;
  background-color: #346EF5;
  border-radius: 2px;
  display: inline-flex;
  gap: 10px;
  justify-content: center;
  justify-content: flex-end;
  overflow: hidden;
  padding: 9px 16px;
  position: relative;
  top: 8px;
  margin-right: 20px;
  height: 35px;
}

.label {
  color: #FFFFFF;
  font-family: var(--common-font), Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}
.side-info {
  background-color: var(--neutralswhite);
  border-radius: 2px;
  box-shadow: var(--dropdown);
  height: 255px;
  left: 0;
  position: absolute;
  top: 0;
  width: 320px;
}

.content {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  position: relative;
}

.side-header {
  align-items: flex-start;
  background-color: var(--neutralswhite);
  border: 1px solid;
  border-color: var(--neutralslight-grey-2);
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  padding: 12px 16px;
  position: relative;
}

.title-and-close {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 288px;
}

.markups {
  color: #000000;
  font-family: var(--body-emphasize-font-family);
  font-size: var(--body-emphasize-font-size);
  font-style: var(--body-emphasize-font-style);
  font-weight: var(--body-emphasize-font-weight);
  letter-spacing: var(--body-emphasize-letter-spacing);
  line-height: var(--body-emphasize-line-height);
  position: relative;
  width: fit-content;
}

.close-wrapper {
  &:hover {
    background: #F8F8F8;
  }
  align-items: center;
  border-radius: 2px;
  display: flex;
  height: 24px;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 24px;
}

.markup-toolbar {
  align-items: flex-start;
  background-color: var(--neutralswhite);
  border: 1px solid;
  border-color: var(--neutralslight-grey-2);
  display: flex;
  gap: 10px;
  height: 40px;
  justify-content: space-around;
  padding: 8px 16px;
  position: relative;
  width: 320px;
}

.markup-toolbar-2 {
  align-items: center;
  display: flex;
  flex: 1;
  flex-grow: 1;
  justify-content: space-between;
  position: relative;
}

.text-wrapper-4 {
  color: var(--neutralsdark-grey);
  font-family: var(--body-small-font-family);
  font-size: var(--body-small-font-size);
  font-style: var(--body-small-font-style);
  font-weight: var(--body-small-font-weight);
  letter-spacing: var(--body-small-letter-spacing);
  line-height: var(--body-small-line-height);
  position: relative;
  width: fit-content;
}

.actions {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  position: relative;
}

.drag-wrapper {
  align-items: center;
  background-color: var(--brandlight-blue);
  border-radius: 2px;
  display: flex;
  height: 24px;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 24px;
}

.sheets {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
}

.item {
  align-items: flex-start;
  background-color: var(--brandlight-blue);
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  padding: 12px 16px;
  position: relative;
}

.content-2 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 286px;
}

.icon-and-details {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 12px;
  position: relative;
}

.rectangle {
  background-color: var(--statusred);
  height: 58px;
  position: relative;
  width: 6px;
}

.tool-and-created {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 2px;
  position: relative;
}

.text-wrapper-5 {
  color: var(--neutralsblack);
  font-family: var(--common-font), Helvetica;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.12px;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.content-wrapper {
  align-items: flex-start;
  background-color: var(--neutralswhite);
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  padding: 12px 16px;
  position: relative;
}

.rectangle-2 {
  background-color: var(--brandblue);
  height: 58px;
  position: relative;
  width: 6px;
}
.img {
  &:hover {
    background: #F8F8F8;
  }
  height: 16px;
  position: relative;
  width: 16px;
}
`;
export default CompareFilesStyles;