import React from "react";
import MenuItem from '@mui/material/MenuItem';
import { FormControl, FormHelperText, Select } from "@mui/material";

const DropdownRender = ({ handleChange , req , ...props}) => {
  const { responseOptions,defaultValue, checklistFormobj } = props;

  return (
    <FormControl className="margin-top-8" error={!(defaultValue && defaultValue !== '') && req}>
             { checklistFormobj.Status__c !== "Completed" &&(
      <Select labelId="demo-simple-select-label" id="demo-simple-select"
        value={defaultValue} onChange={handleChange} required={req} name="Response__c" >
        {responseOptions?.map((r) => (
          <MenuItem value={r.value} key={r.key}>
            {r.label}
          </MenuItem>
        ))}
      </Select>
             )}
               { checklistFormobj.Status__c === "Completed" &&(
                <Select labelId="demo-simple-select-label" id="demo-simple-select"
                value={defaultValue}  required={req} name="Response__c" >
                {responseOptions?.map((r) => (
                  <MenuItem value={r.value} key={r.key}>
                    {r.label}
                  </MenuItem>
                ))}
              </Select>
               )}
      <FormHelperText>{(!req || (defaultValue && defaultValue !== '')) ? undefined : "Select an option."}</FormHelperText>
    </FormControl>
  );
};

export default DropdownRender;
