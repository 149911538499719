import React, { useEffect } from "react";
import { Box, LinearProgress } from "@mui/material";

export default function ProgressBar({ progress }) {


  return (
    <Box sx={{ width: "30%" }} className="progress_bar">
      <LinearProgress variant="determinate" value={progress} />
      <div>Processing....</div>
    </Box>
  );
}
