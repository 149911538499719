import styled from "styled-components";

const ViewPdfDialogstyles = styled.div`
  h2#alert-dialog-title > div {
    justify-content: space-between;
  }
  .main-wrap_buttons > button.MuiButton-root {
    margin-right: 20px;
  }
  button.MuiButton-root.MuiButton-text.MuiButton-textPrimary.Mui-disabled {
      background: #ccc;
      color: #000;
      margin-top: unset;
  }
`;
export default ViewPdfDialogstyles;
