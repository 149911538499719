import styled from "styled-components";

const IssueDetailPopupStyle = styled.div`
  h2.MuiTypography-root.MuiTypography-h6.MuiDialogTitle-root
    button.MuiButtonBase-root.MuiIconButton-root {
    right: -50px;
    top: 0;
  }

  .top_head {
    width: 100%;
    justify-content: flex-end;
    color: #fff;
    gap: 5px;
    align-items: center;
    /* button.left_icon {
      right: -50px !important;
      top: 50% !important;
      color: #fff;
      transform: translateY(-50%);
      svg {
        fill: #fff;
      }
    } */

    .button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium {
      right: -50px;
      top: 0;
      position: absolute;
    }
    h2.MuiTypography-root.MuiTypography-h6.MuiDialogTitle-root
      button.MuiButtonBase-root.MuiIconButton-root {
      top: 0;
    }
    p {
      font-size: 13px;
      text-transform: uppercase;
    }
    & > svg.MuiSvgIcon-root {
      font-size: 20px;
    }
    .issueDetailTitle {
      flex-wrap: wrap;
    }
  }
  .bottom_head {
    width: 100%;
    flex: 100%;
  }
  .issueDetailTitle,
  .bottom_head {
    flex-wrap: wrap;
  }
  h3.subHeading {
    width: 100%;
  }
  h6.heading {
    font-size: 30px;
    color: #fff;
  }
  .MuiGrid-root .MuiBox-root .MuiTypography-root {
    font-size: 15px;
    font-weight: 500;
    display: block;
    width: 100%;
    font-family: inherit;
  }
  .MuiGrid-root .MuiBox-root {
    flex-wrap: wrap;
    & > .MuiBox-root {
      flex-wrap: wrap;
    }
  }
  [class*="box_"] {
    padding: 20px;
    .left_side_grid,
    .right_side_grid {
      width: 100%;
      max-width: calc(100% / 2 - 10px);
    }
    .MuiTypography-body2 {
      font-size: 13px !important;
      font-weight: 300 !important;
    }
  }
  .box_1.MuiBox-root.css-0,
  .box_3.MuiBox-root.css-0,
  .box_5.MuiBox-root.css-0 {
    padding: 20px 30px;
    .MuiTypography-root {
      margin-bottom: 14px;
    }
  }
  .box_3.MuiBox-root.css-0 {
    border-bottom: 10px solid #edf0f2;
  }
  .MuiTabPanel-root {
    padding: 0 !important;
  }
  .box_5.MuiBox-root.css-0,
  .MuiGrid-root .box_2 {
    border-top: 10px solid #edf0f2;
  }

  .inner_content_box {
    padding: 10px;
  }
`;

export default IssueDetailPopupStyle;
