import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  navState: ""
}

export const navStateSlice = createSlice({
  name: "navState", 
  initialState,
  reducers: {
    setNavState: (state, action) => {
      state.navState = action.payload
    }
  }
})

export const {  setNavState } = navStateSlice.actions

export default navStateSlice.reducer
