
import LeftComponent from "components/LeftComponent";
import Spinner from "components/spinner";
import WebViewer from '@pdftron/webviewer';
import styled from "styled-components";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer} from "react-toastify";
import React, { useState,useRef } from "react";
import axios from "axios";
import { fetchUrl, inAppLoginUrl, SF_PROJECTID } from "Urls";
import { GetToken } from "api";
import { useLocation,useNavigate } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { Box, Toolbar } from "@mui/material";
import colorConfigs from "../../configs/colorConfigs";
import sizeConfigs from "../../configs/sizeConfigs";
import global from "globaled.js";
import { useSelector, useDispatch } from "react-redux";
import { setUserData } from 'redux/features/userDataSlice';
import { APRYSE_L_KEY } from "api";

const ADD_SHEETS = "/add_sheets";
const MainCss = styled.div`
    .container-center-horizontal {
      display: flex;
      flex-direction: row;
      justify-content: center;
      pointer-events: none;
      width: 100%;
    }
    .sheets-landing-page screen {
      align-items: flex-start;
      background-color: var(--neutralswhite);
      border: 1px solid;
      width: 1440px;
      display: flex;
      height: 1024px;
      position: relative;
    }
    .closed_sidebar_main > nav.MuiBox-root {
      max-width: 86px;
      width: 100% !important;
    }

    .closed_sidebar_main > div.MuiBox-root {
      width: calc(100% - 80px);
      max-width: 100%;
    }
    .open_sidebar_main {
      height: 100vh;
      background: #ffff;
      justify-content: center;
    }
    .main_sidebar_main {
      justify-content: center;
      position: relative;
    }
    .closed_sidebar_main {
      height: 100vh;
      background: #ffff;
    }
  `;
function Home() {
  const navigate = useNavigate();
  const location = useLocation();
  const usePathname = () => {
    return location.pathname;
  };
  const path = usePathname();
  const getProjSite = () => {
    let queryObj = queryStringToObject(location.search);
    if(queryObj?.projectId && queryObj.projectId.trim() != ''){
      return {Id : queryObj?.projectId , Name : ''}
    }
    if(SF_PROJECTID != ''){
      return {Id : SF_PROJECTID , Name : ''}
    }
    return {};
  };
  const prjSite = getProjSite();
  const dispatch = useDispatch();
  const [currentSite, setCurrentSite] = useState(prjSite);
  const [settingProject, setProjectInProgress] = useState(false);
  const [sideBar, setSideBar] = useState(false);
  const [projectOptions, setProjectOptions] = useState([]);
  const [creditials, setCredentials] = useState(null);
  const [current_user_data, setCurrentUser] = React.useState();
  const [activeTabValue, setActiveTab] = React.useState(0);
  const [activeFormTabValue, setActiveFormTab] = React.useState(0);
  const [selectedOption, setSelectedOption] = useState(currentSite?.Id);
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [webInstance, setInstance] = useState(null);
  const instanceRef = useRef(null);
  const pdfContainerRef = useRef(null);
  function setInstance_(values) {
    instanceRef.current = values;
    setInstance(values);
  }

  React.useEffect(() => {
    //console.log('Home current_folder_options');
    //console.log('prjSite :: ',JSON.stringify(prjSite));
    //console.log('currentSite :: ',JSON.stringify(currentSite));
    current_folder_options();
    if (!instanceRef.current) {
      setTimeout(() => {
        loadSampleDocument();
      }, 500);
    }
  }, []);
  async function current_folder_options() {
    const data = await getCreditials();
    const instanceUrl = data?.data?.instanceUrl;
    const token = data?.data?.token;
    //console.log("current_folder_options token", token);
    //console.log("current_folder_options token", instanceUrl);
    if (instanceUrl && token) {
      //console.log('current_folder_options token',token);
      let cred = JSON.parse(JSON.stringify(data?.data));
      const defaultProject = data?.data?.currentSite ? data?.data?.currentSite : currentSite;
      cred.projectId = defaultProject?.Id;
      cred.projectName = defaultProject?.Name;
      await axios.get(`${fetchUrl}/get_all_project?token=${token}&instanceUrl=${instanceUrl}&defaultProject=${defaultProject?.Id}`)
        .then(async (res) => {
          if (res?.status === 200) {
            let ProjectOptions = [];
            let cccc;
            res?.data?.records?.map(async (val, index) => {
              ProjectOptions.push({ Id: val?.Id, Name: val?.Project_Number__c + " - " + val?.Name, });
              if (defaultProject && val?.Id) {
                if (defaultProject?.Id == val?.Id) {
                  cccc = { Id: val?.Id, Name: val?.Project_Number__c + " - " + val?.Name, };
                  cred.projectName = val?.Name;
                }
              }
            });
            if(res?.data?.userList){
              cred = {...cred};
              cred.userList = res?.data?.userList;
            }
            if (cccc?.Id && cccc?.Name) {
              await setCurrentProjectEnvironment(cccc, cred);
            }
            const sortedPojectValues = ProjectOptions.sort((a, b) => (
              a.Name > b.Name ? 1 : -1
            ));
            setProjectOptions(sortedPojectValues);
          }
        })
      .catch((err) => {
        console.error(err);
      });
      setCredentials(cred);
      dispatch(setUserData(cred));
      redirectToPath(cred);
      if (!current_user_data) {
        current_user(cred);
      }
    } else {
      if (!window.location.href.includes("localhost:3002")) {
        let logurl = inAppLoginUrl;
        if(location.search && location.search.trim() != ''){
          logurl += `&${location.search}`
        }
        console.log('logurl :: ',logurl);
        window.location = logurl;
        //navigate("/login");
      }
    }
  }
  async function getCreditials() {
    const data = await GetToken();
    setCredentials(data?.data);
    return data;
  }
  async function current_user(_creditials = creditials) {
    //console.log("_creditials :::", _creditials);
    await axios.get(`${fetchUrl}/get_current_user`)
      .then((res) => {
        if (res?.status === 200) {
          if (currentSite.Id !== res?.data?.projectId && res?.data?.projectId) {
            setCurrentProjectEnvironment( { Id: res?.data?.projectId  ,Name: res?.data?.projectname ? res?.data?.projectname : ""},
              _creditials
            );
          }
          setCurrentUser(res?.data);
        }
      })
    .catch((err) => {
      console.error(err);
      console.log("get_current_user :: " + JSON.stringify(err));
    });
  }
  function queryStringToObject(queryString) {
    var obj;
    if(queryString && queryString.trim() != ''){
      obj ={};
      if (queryString.charAt(0) === '?') {
        queryString = queryString.substring(1);
      }
      var pairs = queryString.split('&');
      
      for (var i = 0; i < pairs.length; i++) {
        // Split the pair into key and value
        var pair = pairs[i].split('=');
        var key = decodeURIComponent(pair[0]);
        var value = decodeURIComponent(pair[1] || '');
        obj[key] = value;
      }
    }
    return obj;
  }
  const setCurrentProjectEnvironment= async (values, _cred = creditials)=>{
  if (values?.Id && currentSite?.Id !== values?.Id) {
      setProjectInProgress(true);
      await axios.post(`${fetchUrl}/setcurrentproject/${values?.Id}`)
      .then(async (res) => {
        if (res?.status === 200) {
          let toset = { ..._cred, projectId: values?.Id, projectName: values?.Name };
          if(res.data.userList){
            toset.userList = res.data.userList;
          }
          setCredentials(toset);
          dispatch(setUserData(toset));
          redirectToPath(toset);
        }
      })
      .catch((err) => {
        console.error(err);
      });
    }
    //console.log("values?.Id ::", values?.Id);
    global.projectId = values?.Id;
    //console.log("creditials ::", _cred);
    global.cred = _cred;
    setCurrentSite(values);
    setSelectedOption(values?.Id);
    setProjectInProgress(false);
  }
  async function loadSampleDocument() {
    //console.log('pdfContainerRef.current :: ',pdfContainerRef.current);
    if (pdfContainerRef.current && !instanceRef.current) {
      await WebViewer({
        fullAPI: true,
        path: '/webviewer/public',
        licenseKey: APRYSE_L_KEY 
      }, pdfContainerRef.current).then(async (instance) => {
        setInstance_(instance);
        const { PDFNet } = instance.Core;
        await PDFNet.initialize();
        console.log('home page instance :: ', instance);
      });
    }
  };
  const redirectToPath = (cred) =>{
    let navPath = path ;
    let opt = { replace: true, state : {} };
    let queryObj = queryStringToObject(location.search);
    if(cred && cred.projectId && queryObj?.redirectTo && queryObj.redirectTo.trim() !== '' && queryObj?.redirectId && queryObj?.redirectId.trim() !== ''){
      navPath = `/${queryObj.redirectTo}`;
      opt.state = { redirectId: queryObj?.redirectId };
      if(queryObj?.folderId?.trim()?.length > 14){
        opt.state.folderId = queryObj?.folderId;
      }
      if(queryObj?.issueId?.trim()?.length > 14){
        opt.state.issueId = queryObj?.issueId;
      }
    }
    //console.log('navPath ::',navPath);
    console.log('opt ::',JSON.stringify(opt.state));
    navigate(navPath , opt);
  };

  return (
    <MainCss>
      <div ref={pdfContainerRef} style={{ display: 'none' }}></div>
      <Box sx={{ display: "flex" }} className={sidebarOpen ? "open_sidebar_main" : "closed_sidebar_main"}>
        {path !== ADD_SHEETS && (
          <Box component="nav" sx={{ width: sizeConfigs.sidebar.width, flexShrink: 0, }} >
            <LeftComponent
              current_user_data={current_user_data}
              settingProject={settingProject}
              creditials={creditials}
              projectOptions={projectOptions}
              setSelectedOption={setSelectedOption}
              selectedOption={selectedOption}
              currentSite={currentSite}
              setCurrentProjectEnvironment={setCurrentProjectEnvironment}
              sidebarOpen={sidebarOpen}
              setSidebarOpen={setSidebarOpen}
            />
          </Box>
        )}
          <Box className="main_sidebar_main" sx={{ width: `calc(100% - ${sizeConfigs.sidebar.width})` }}>
            <Outlet />
          </Box>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          rtl={false}
          pauseOnHover={false}
        />
      </Box>
    </MainCss>
  );

}

export default Home;
