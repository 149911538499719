import React, { useState } from "react"
const ToastComponent = (props) => {
   const { message, handleClose, variant, action } = props;
   const [vrnt , setVariant] = useState(variant);
   const [msg , setMessage] = useState('');
   //console.log("vrnt ::", vrnt);
   React.useEffect(() => {
      if(typeof message === 'object'){
        if(message.message){
          setMessage(message.message);
        }
        if(message.variant){
          setVariant(message.variant);
        }
      }else{
        setMessage(message);
      }
      setTimeout(function () {
        handleClose(false);
      }, 5000);
   }, []);
   const getBackgroundColor = () => {
      switch (vrnt) {
        case 'Information':
          return '#E8EFFF';
        case 'Warning':
          return '#FFF6EA';
        case 'Error':
          return '#FEE';
        default:
          return '#EBFFEC';
      }
    };
  
    const getTextBorderColor = () => {
      switch (vrnt) {
        case 'Information':
          return '#346EF5';
        case 'Warning':
          return '#D88100';
        case 'Error':
          return '#F92828';
        default:
          return '#0AA110';
      }
   };
   return (
      <div className="toast-message-container">
        <div className="toast-message" style={{ display : 'flex' , backgroundColor: getBackgroundColor(), borderColor: getTextBorderColor(), color: getTextBorderColor()}}>
          <div style={{ width: "20px" }}>
            <img src={vrnt == "Information" ? "/info.svg" : vrnt == "Warning" ? "/error_orange.svg" : vrnt == "Error" ? "/error-red.svg" : "/check-green.svg"} alt="checkIcon" />
          </div>
          <div title={msg}>{msg}</div>
          <div style={{ marginLeft: "auto", display: "flex", gap: "20px" }}>
            {action &&
                <div >{action}</div>
            }
            <img src={vrnt == "Information" ? "/close_blue.svg" : vrnt == "Warning" ? "/close_orange.svg" : vrnt == "Error" ? "/close_red.svg" : "/closegreen.svg"} alt="" onClick={() => handleClose(false)} style={{ cursor: 'pointer' }} />
          </div>
        </div>
      </div>
   );
};

export default ToastComponent;
