import React from "react";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { useTable, useBlockLayout, useResizeColumns, useSortBy, usePagination } from "react-table";
import { useSticky } from "react-table-sticky";
import { Styles } from "./ProjectFormTable.style";
import styled from "styled-components";

const getStyles = (props, align = "left") => [
  props,
  {
    style: {
      // justifyContent: align === "right" ? "flex-end" : "flex-start",
      justifyContent: "space-between",
      alignItems: "center",
      display: "flex",
    },
  },
];
const headerProps = (props, { column }) => getStyles(props, column.align);

const cellProps = (props, { cell }) => getStyles(props, cell.column.align);

function TableProjectFormList({ columns, data, NoDataComponent, handleOpen, setSelectedRowData }) {
  const { getTableProps, headerGroups, rows, prepareRow, getToggleHideAllColumnsProps, allColumns,
        canPreviousPage, canNextPage, pageOptions, pageCount, gotoPage, nextPage, previousPage, setPageSize,
        state: { pageIndex, pageSize }, } = useTable( { columns, data, initialState: { pageIndex: 2 } }, useBlockLayout, useResizeColumns, useSortBy, usePagination, useSticky );
  const handleOpenDetail = (row) => {
    handleOpen(row.original);
  };

  

  // Render the UI for your table
  return (
    <Styles>
      <div {...getTableProps()} className="table">
        <div className="thead">
          {headerGroups.map((headerGroup) => (
            <div {...headerGroup.getHeaderGroupProps({})} className="tr">
              {headerGroup.headers.map((column) => (
                <div {...column.getHeaderProps(headerProps)} className="th">
                  <div
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render("Header")}
                    {/* Add a sort direction indicator */}
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <KeyboardArrowDown />
                        ) : (
                          <KeyboardArrowUp />
                        )
                      ) : (
                        ""
                      )}
                    </span>
                  </div>
                  {column.canResize && (
                    <div
                      {...column.getResizerProps()}
                      className={`resizer ${
                        column.isResizing ? "isResizing" : ""
                      }`}
                    />
                  )}
                </div>
              ))}
            </div>
          ))}
        </div>
        <div className="tbody">
          {rows?.length === 0 && (
            <div
              className="no_data_found"
              style={{ textAlign: "center", marginTop: "200px" }}
            >
              {NoDataComponent}
            </div>
          )}
          {rows.map((row) => {
            prepareRow(row);
            return (
              <>
                <div
                  onClick={() => handleOpenDetail(row)}
                  {...row.getRowProps()}
                  className="tr"
                >
                  {row.cells.map((cell, index) => {
                    return (
                      <div {...cell.getCellProps(cellProps)} className="td">
                        {cell.render("Cell")}
                      </div>
                    );
                  })}
                </div>
              </>
            );
          })}
        </div>
      </div>
      {/*
        <div className="pagination">
          <span>
            Showing{" "}
            <strong>
              {pageIndex + 1} - {pageOptions.length} of {pageOptions.length}
            </strong>{" "}
          </span>
          <div className="right_section_pagination">
            <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
              {"<<"}
            </button>{" "}
            <button onClick={() => previousPage()} disabled={!canPreviousPage}>
              {"<"}
            </button>{" "}
            <span>
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>{" "}
            </span>
            <button onClick={() => nextPage()} disabled={!canNextPage}>
              {">"}
            </button>{" "}
            <button
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              {">>"}
            </button>{" "}
          </div>
        </div>
      */}
    </Styles>
  );
}

export default TableProjectFormList;
