import MainComponent from "components/MainComponent";
import { Header } from "../../components/Layout";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import React ,{ useState } from "react";
import axios from "axios";
import { fetchUrl ,SF_PROJECTID } from "Urls";
import { GetToken } from "api";
import { useNavigate } from 'react-router-dom';

function Redirect(props) {
  const navigate = useNavigate();
  const { projectId, tabNumber} = props;
  const [currentSite, setCurrentSite] = useState({ Id: props.projectId, Name: '' }); //{ Id: SF_PROJECTID, Name: "Testing new eproject" }
  const [settingProject, setProjectInProgress] = useState(false);
  const [projectOptions, setProjectOptions] = useState([]);
  const [creditials, setCredentials] = useState([]);
  const [current_user_data, setCurrentUser] = React.useState();
  const [activeTabValue, setActiveTab] = React.useState(1);
  const [activeFormTabValue, setActiveFormTab] = React.useState(1);
  console.log("props.projectId", props.projectId);
  React.useEffect(() => {
    //console.log('Home current_folder_options');
    current_folder_options();
  }, []);
  async function current_folder_options() {
    const data = await getCreditials();
    const instanceUrl = data?.data?.instanceUrl;
    const token = data?.data?.token;
    console.log('current_folder_options instanceUrl',instanceUrl);
    if(instanceUrl && token){
      //console.log('current_folder_options token',token);
      let cred = data?.data;
      const defaultProject = data?.data?.projectId ? data?.data?.projectId : currentSite?.Id ;
      cred.projectId = defaultProject ;
      setCredentials(cred);
      await axios.get( `${fetchUrl}/get_all_project?token=${token}&instanceUrl=${instanceUrl}&defaultProject=${defaultProject}`).then(async (res) => {
        if (res?.status === 200) {
          let ProjectOptions = [];
          let cccc ;
          res?.data?.records?.map(async (val, index) => {
            ProjectOptions.push({
              Id: val?.Id,
              Name: val?.Project_Number__c +' - '+val?.Name,
            });
            if (defaultProject && val?.Id) {
              if(defaultProject == val?.Id ){
                cccc = { Id: defaultProject, Name: val?.Project_Number__c +' - '+val?.Name } ;
              }
            }
          });
          if(cccc?.Id && cccc?.Name ){
            await setCurrentProjectEnvironment(cccc , data?.data);
          }
          setProjectOptions(ProjectOptions);
        }
      }).catch((err) => {
        console.error(err);
      });
      if(!current_user_data){
        await current_user(cred);
      }
    }else{
      if(!fetchUrl.includes('localhost')){
        navigate('/login');
      }
    }
  }
  async function getCreditials() {
    const data = await GetToken();
    setCredentials(data?.data);
    return data;
  }
  async function current_user(_creditials = creditials) {
    await axios.get(`${fetchUrl}/get_current_user?token=${_creditials?.token}&instanceUrl=${_creditials?.instanceUrl}`).then((res) => {
      if (res?.status === 200) {
        //console.log("current_user get_current_user :: ", JSON.stringify(res?.data));
        //console.log("current_user res?.data?.projectId", res?.data?.projectId);
        if(currentSite.Id !== res?.data?.projectId && res?.data?.projectId){
          setCurrentProjectEnvironment({
            Id: res?.data?.projectId ? res?.data?.projectId : '',/*SF_PROJECTID */
            Name: res?.data?.projectname ? res?.data?.projectname : '',/*Testing new eproject */
          } , _creditials);
        }
        setCurrentUser(res?.data);
      }
    }).catch((err) => {
      console.error(err);
      console.log('get_current_user :: '+JSON.stringify(err));
    });
  }

  async function setCurrentProjectEnvironment(values , _cred = creditials) {
    if(currentSite.Id !== values?.Id){
      setProjectInProgress(true);
      await axios.post(`${fetchUrl}/setcurrentproject/${projectId}`).then(async (res) => {
        if (res?.status === 200) {
          setCredentials({..._cred , projectId : values?.Id });
        }
      }).catch((err) => {
        console.error(err);
      });
    }
    setCurrentSite(values);
    setProjectInProgress(false);
  }
  function _setCurrentSiteFunction(values) {
    if (values?.Name && values?.Id && values?.Id != '') {
      return <MainComponent value={activeTabValue} setValue={setActiveTab} formTabvalue={activeFormTabValue} setFormTabValue={setActiveFormTab} projectId={values?.Id} credentials={creditials} tabNumber={tabNumber}/>;
    }
  }

  return (
    <div className="App">
      {creditials && 
        <>
          <header className="App-header">
            <Header current_user_data={current_user_data} creditials={creditials} projectOptions={projectOptions} currentSite={currentSite} setCurrentProjectEnvironment={setCurrentProjectEnvironment} />
          </header>
        </>
      }
      {!settingProject && _setCurrentSiteFunction(currentSite)}
      {/* <MainComponent /> */}
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        rtl={false}
        pauseOnHover={false}
      // pauseOnFocusLoss
      />
      {/* Same as */}
      <ToastContainer />
    </div>
  );
}

export default Redirect;
