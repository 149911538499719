import styled from "styled-components";

const ShowPdfDialogstyles = styled.div`
  h2#alert-dialog-title > div {
    justify-content: space-between;
  }
  .main-wrap_buttons > button.MuiButton-root {
    margin-right: 20px;
  }
`;
export default ShowPdfDialogstyles;
