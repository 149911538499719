import React from "react";
import PropTypes from "prop-types";

import Grid from '@mui/material/Grid';
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import LayersIcon from "@mui/icons-material/Layers";
import Button from '@mui/material/Button';
import Spinner from "components/spinner";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { toast } from "react-toastify";

import axios from "axios";
import { fetchUrl } from "Urls";

const SetCurrentVersion = (props) => {
  const { currentVersion , refreshList } = props;
  const [open, setOpen] = React.useState(false);
  const [showSpinner, setSpinner] = React.useState(false);

  const handleClickOpen = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleUpdate = async () => {
    setSpinner(true);
    await axios
      .put(`${fetchUrl}/version/update/${currentVersion.awsAttId}/${currentVersion.version}`)
      .then((res) => {
        if (res?.status === 200) {
          //let currentVersion = res.data.currentVersion;
          //console.log("res?.status :: "+res?.status);
          //console.log("res :: "+JSON.stringify(res));
          refreshList();
          toast.success("Version Restored");
          handleClose();
        }
      })
      .catch((err) => {
        console.log(err);
      });
      setSpinner(false);
  };

  return (
    <>
      <Tooltip
        title="Make current"
        placement="top"
        PopperProps={{ className: "version-action-tooltip" }}
        arrow
      >
        <IconButton
          aria-label="make-current"
          size="small"
          className="file-icon-btn"
          onClick={handleClickOpen}
        >
          <LayersIcon />
        </IconButton>
      </Tooltip>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="set-current-dialog-title"
        aria-describedby="set-current-description"
        fullWidth
        maxWidth="md"
      >
        <DialogTitle id="set-current-title">Make current version?</DialogTitle>
        <DialogContent dividers style={{position : 'relative'}}>
          <DialogContentText id="set-current-description" tabIndex={-1}>
            <Typography
              variant="subtitle1"
              className="set-current-text"
              gutterBottom
              component="div"
            >
              {showSpinner && ( <Spinner /> ) }
              A copy of {currentVersion.versionLabel} will be restored as the current version.
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Grid container className="set-current-actions">
            <Grid
              item
              xs={6}
              container
              justifyContent="start"
              alignItems="center"
            >
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox />}
                  label="Don't show me again"
                  className="set-current-check"
                />
              </FormGroup>
            </Grid>
            <Grid item xs={6} container justifyContent="end">
              <Button onClick={handleClose} variant="outlined"
              style={{height: '40px', width : '145px', marginRight : '10px'}}>
                Cancel
              </Button>
              <Button onClick={handleUpdate} variant="contained" disabled={showSpinner}
              style={{height: '40px', width : '145px'}}>
                Make Current
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};

SetCurrentVersion.propTypes = {};

export default SetCurrentVersion;
